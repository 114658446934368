
import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BellIcon, ShoppingCartIcon, MenuIcon, UserCircleIcon } from '@heroicons/react/outline'
// import { Button } from 'react-bootstrap';
// import EditVitals from './EditVitals';
import VitalChart from '../VitalChart';
import Temperature from "../../../../../Assets/Images/Temperature.png";
import { FiMoreVertical } from 'react-icons/fi';
import Vector from "../../../../../Assets/Images/Vector.png";
import { Dropdown, Modal, Space } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { addPatientvitals } from '../../../../../Redux/Action/userprofileVitalActions';
import { ToastContainer, toast } from 'react-toastify';




function PatientprofileTempraturecard(props) {


    const history = useHistory();
    const location = useLocation();

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    const [showviewall, setshowviewall] = useState(false);
    const [showentervitals, setshowentervitals] = useState(false);
    const [showremovevitals, setshowremovevitals] = useState(false);
    const [data, setData] = useState();
    const [graphPop, setGraphPop] = useState(false);
    const [temperature, setTemperature] = useState();
    const dispatch = useDispatch();

    console.log(props.data)
    const viewDetails = (event) => {
        event.preventDefault();
        setshowviewall(true)
        setshowentervitals(false)
        setshowremovevitals(false)
    }

    const savevitals = () => {
        if (temperature) {
            let newVitals = { ...props.data, temperature };
            newVitals.createdDate = moment().format("yyyy-MM-DD HH:mm:ss");
            newVitals.updatedDate = moment().format("yyyy-MM-DD HH:mm:ss");
            newVitals.givenDate = moment().format("yyyy-MM-DD HH:mm:ss");
            newVitals.patientCode = props?.data?.patientCode;
            newVitals.createdBy = props?.data?.createdBy;
            newVitals.updatedBy = props?.data?.updatedBy;
            dispatch(addPatientvitals(newVitals)).then(res => {
                saveSuccess();
                props.fetchAllVitals(props.activePatient)
                setTemperature('');
                setTimeout(() => {
                    toast('Vitals updated successfully');

                }, 300);
            }).catch(err => {
                console.log("Error");
            })
        }
    }


    const addvitals = (event) => {
        event.preventDefault();
        setshowviewall(false)
        setshowremovevitals(false)
        setshowentervitals(true)


    }


    const removevitals = (event) => {
        event.preventDefault();
        setshowviewall(false)
        setshowentervitals(false)
        setshowremovevitals(true)

    }

    const saveSuccess = (e) => {
        console.log("save success");
        setshowentervitals(false);
        props.saveCallback(e);
    };

    const items = [
        {
            label: <p className='relative' onClick={addvitals}>Update</p>,
            key: 0,
        }]


    return (

        <>
            {
                // props?.data?.slice(0, 1).map((user, i) => (
                <>
                    <div className="w-full bg-White p-5 rounded-md">
                    <ToastContainer />
                        <div className="flex gap-5">
                            <div className="">
                                <img src={Temperature} alt="images" />
                            </div>
                            <div className="w-full ">
                                <div className="w-full flex justify-between items-center">
                                    <h5 className="text-primaryColor text-lg font-medium">
                                        Temperature
                                    </h5>
                                    <div className="flex gap-3 items-center">
                                        <div
                                            onClick={() => setGraphPop(true)}
                                            className="flex cursor-pointer"
                                        >
                                            <img src={Vector} alt="images" />
                                        </div>
                                        <div class="relative inline-block group">
                                            <Dropdown
                                                menu={{
                                                    items,
                                                }}
                                                trigger={['click']}
                                                placement='bottomRight'
                                            >
                                                <Space>
                                                    <FiMoreVertical />
                                                </Space>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-5 mt-4">
                                    <p className="text-textGray font-light text-xs ">
                                        <span className="text-lg text-secondaryColor font-medium">
                                            {props.data.temperature}
                                        </span>{" "}
                                        degree
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        showentervitals ?
                            //  <EditVitals title={'Temperature'} data={props.data[0]} closePopup={saveSuccess}/>
                            <div className='absolute'>
                                <div className='absolute inset-0'>
                                    <Modal
                                        centered
                                        open={showentervitals}
                                        width={"25%"}
                                        footer={[]}
                                        onCancel={saveSuccess}
                                    >
                                        <div>
                                            <p className='font-semibold text-xl'>Temperature</p>
                                            <div className="h-[5rem] pt-6">
                                                <div className="flex justify-between">
                                                    <input
                                                        autoComplete="off"
                                                        id="temperature"
                                                        name="temperature"
                                                        type="number"
                                                        value={temperature ?? ""}
                                                        className=" text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                        placeholder="Temperature"
                                                        onChange={(e) => setTemperature(e.target.value)}
                                                    />

                                                    <label
                                                        htmlFor="email"
                                                        className=" text-xs font-normal text-gray-secondary"
                                                    >
                                                        °F
                                                    </label>
                                                </div>
                                                <hr className="bg-gray-primary" />
                                                <div className="flex justify-end my-3">
                                                    <button
                                                        onClick={savevitals}
                                                        className="bg-primaryColor  text-White py-1 px-4 font-medium rounded-xl mt-4"
                                                    >
                                                        Update{" "}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                            : null

                    }

                    {
                        graphPop ? <VitalChart title={'Temperature Graph'} data={[props.data]} type="temperature" closePopup={() => setGraphPop(false)} /> : null
                    }
                </>
                // ))
            }
        </>
    );
}

export default PatientprofileTempraturecard;