// import React, { useState } from 'react';
// import SmartVitalCheckerHeroIcon from '../../../Assets/Images/smartVitals/smart-vitals-checker-hero-icon.png';
// import { Form, Input, Select, Button, Row, Col, Typography } from 'antd';
// import Header from "../../Shared/Header";

// const { Text } = Typography;
// const { Option } = Select;

// const FormComponent = ({ onFinish }) => {
//   const [form] = Form.useForm();

//   const [heightUnit, setHeightUnit] = useState('cm');
//   const [weightUnit, setWeightUnit] = useState('kg');

//   const handleFinish = (values) => {
//     onFinish({ ...values, heightUnit, weightUnit });
//   };

//   return (
//     <><Header />

//     <div className='flex flex-col'>
//     <div className="bg-primaryColor text-white py-4 rounded-lg shadow-md w-full mt-24 px-4">
//       <p className="font-semibold text-lg ml-3 text-White">
//         Create Facial Key:
//         <span className="font-normal ml-2 text-base text-White">
//           Create a map of your face. You can delete the map at any time.
//         </span>
//       </p>
//     </div>
//     <div className="min-h-screen flex flex-col md:flex-row items-center gap-6 px-4 py-6 md:px-12 md:py-8 ">

//       <div className="relative w-full md:w-1/2 lg:w-1/2 mb-6">
//         <img
//           src={SmartVitalCheckerHeroIcon}
//           className="w-full rounded-lg object-cover h-[25rem] sm:h-[30rem] lg:h-[35rem]"
//           alt="Smart Vitals Checker" />
//         <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white">
//           <Text className="text-3xl font-bold md:text-4xl text-White">SmartVitals</Text>
//           <Text className="text-lg md:text-xl mt-2 text-White">Monitor Your Health Safely And Easily</Text>
//         </div>
//       </div>

//       <div className="bg-white rounded-lg shadow-lg p-6 md:p-8 w-full md:w-1/2 lg:w-1/2">
//         <Form
//           form={form}
//           name="healthForm"
//           layout="vertical"
//           onFinish={handleFinish}
//           requiredMark={false}
//         >
//           <Row gutter={16} className="flex flex-col md:flex-wrap">
//             <Col xs={24} className="mb-4">
//               <Form.Item
//                 label={<span className="text-lg">Diabetes <span className="text-danger">*</span></span>}
//                 name="diabetes"
//                 rules={[{ required: true, message: 'Please select an option' }]}
//               >
//                 <Select size="large" placeholder="Select">
//                   <Option value="true">Yes</Option>
//                   <Option value="false">No</Option>
//                 </Select>
//               </Form.Item>
//             </Col>
//             <Col xs={24} className="mb-4">
//             <Form.Item
//                 label={<span className="text-lg">Smoke <span className="text-danger">*</span></span>}
//                 name="smoke"
//                 rules={[{ required: true, message: 'Please select an option' }]}
//               >
//                 <Select size="large" placeholder="Select">
//                   <Option value="true">Yes</Option>
//                   <Option value="false">No</Option>
//                 </Select>
//               </Form.Item>
//             </Col>
//             <Col xs={24} className="mb-4">
//               <Form.Item
//                 label={<span className="text-lg">Height <span className="text-danger">*</span></span>}
//                 name="height"
//                 rules={[{ required: true, message: 'Please enter your height' }]}
//               >
//                 <Input
//                   size="large"
//                   type="number"
//                   placeholder="Enter"
//                   addonAfter={<span className="text-gray-500">{heightUnit}</span>} />
//               </Form.Item>
//             </Col>
//             <Col xs={24} className="mb-4">
//               <Form.Item
//                 label={<span className="text-lg">Weight <span className="text-danger">*</span></span>}
//                 name="weight"
//                 rules={[{ required: true, message: 'Please enter your weight' }]}
//               >
//                 <Input
//                   size="large"
//                   type="number"
//                   placeholder="Enter"
//                   addonAfter={<span className="text-gray-500">{weightUnit}</span>} />
//               </Form.Item>
//             </Col>
//           </Row>
//           <Form.Item>
//             <Button type="primary" htmlType="submit" className="w-full bg-blue-900 hover:bg-blue-800 text-white text-lg">
//               Next
//             </Button>
//           </Form.Item>
//         </Form>
//       </div>
//     </div>
//     </div>
    
//     </>
//   );
// };

// export default FormComponent;

import React, { useState } from 'react';
import SmartVitalCheckerHeroIcon from '../../../Assets/Images/smartVitals/smart-vitals-checker-hero-icon.png';
import { Form, Input, Select, Radio, Button, Row, Col, Typography } from 'antd';
import Header from "../../Shared/Header";


const { Title, Text } = Typography;
const { Option } = Select;

const FormComponent = ({ onFinish }) => {
  const [form] = Form.useForm();

  const [heightUnit, setHeightUnit] = useState('cm'); // State for height unit
  const [weightUnit, setWeightUnit] = useState('kg'); // State for weight unit

  const selectAfterWeight = (
    <Select defaultValue="kg" onChange={(value) => setWeightUnit(value)}>
      <Option value="kg">kg</Option>
      <Option value="lbs">lbs</Option>
    </Select>
  );

  const selectAfterHeight = (
    <Select defaultValue="cm" onChange={(value) => setHeightUnit(value)}>
      <Option value="cm">cm</Option>
      <Option value="inch">inch</Option>
    </Select>
  );

  const handleFinish = (values) => {
    // Include the selected units in the form data
    onFinish({ ...values, heightUnit, weightUnit });
  };

  return (
    <><Header /><div className="min-h-screen flex flex-col gap-10 px-6 py-10 md:px-32 md:py-20">
      <div className="relative w-full">
        <img
          src={SmartVitalCheckerHeroIcon}
          className="w-full rounded-lg  sm:h-[450px] h-auto object-cover"
          alt="Smart Vitals Checker" />
        <div className="absolute sm:top-14 top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-White">
          <Text className="text-White text-xl font-bold md:text-3xl">
            SmartVitals
          </Text>
          <br />
          <Text className="text-White text-base md:text-xl mt-2 sm:mt-16">
            Monitor Your Health Safely And Easily
          </Text>
        </div>
      </div>

      <div className="bg-White rounded-lg shadow-lg p-4 md:p-8 w-full">
        <Form
          form={form}
          name="healthForm"
          layout="vertical"
          onFinish={handleFinish}
          requiredMark={false}
          style={{ margin: '0 auto' }}
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                label={<span>Full name <span className="text-danger">*</span></span>}
                name="name"
                rules={[{ required: true, message: 'Please enter your name' }]}
              >
                <Input size="large" placeholder="Please enter your name" />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={<span>Gender <span className="text-danger">*</span></span>}
                name="gender"
                rules={[{ required: true, message: 'Please select your gender' }]}
              >
                <Select size="large" placeholder="Select Gender">
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Item
                label={<span>Age <span className="text-danger">*</span></span>}
                name="age"
                rules={[{ required: true, message: 'Please enter your age' }]}
              >
                <Input
                  size="large"
                  type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12} sm={6}>
              <Form.Item
                label={<span>Height <span className="text-danger">*</span></span>}
                name="height"
                rules={[{ required: true, message: 'Please enter your height' }]}
              >
                <Input
                  size="large"
                  type="number"
                  placeholder="Height"
                  addonAfter={selectAfterHeight} />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Item
                label={<span>Weight <span className="text-danger">*</span></span>}
                name="weight"
                rules={[{ required: true, message: 'Please enter your weight' }]}
              >
                <Input
                  size="large"
                  type="number"
                  placeholder="Weight"
                  addonAfter={selectAfterWeight} />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Item
                label={<span>Do you smoke? <span className="text-danger">*</span></span>}
                name="smoke"
                rules={[{ required: true, message: 'Please select an option' }]}
              >
                <Radio.Group>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Item
                label={<span>Any history of diabetes? <span className="text-danger">*</span></span>}
                name="diabetes"
                rules={[{ required: true, message: 'Please select an option' }]}
              >
                <Radio.Group>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="w-full bg-blue-900 hover:bg-blue-800 text-white">
              Next
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div></>
  
        
    )
}

export default FormComponent