import React, { useEffect } from "react";
import steth from "../../../Assets/Images/steth.svg";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { memberColor } from "../../../config/constant";
import { useSelector } from "react-redux";
import ReactPixel from "react-facebook-pixel";
import Layouts from "../../Layouts";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Skeleton } from "antd";

function AppoinmentConfirmation() {
  const location = useLocation();
  const history = useHistory();
  const { state , userName , salutation } = location;
  const { isLoading, success, errMsg } = useSelector(
    (state) => state.doctorAppointment
  );

  // useEffect(() =>{
  //   if(success){
  //     ReactPixel.track("book_appointment", {
  //       value: state.amount,
  //       currency: "INR",
  //       ...state
  //     });
  //   }
  // },[success])
  console.log(state, "PatientDetails");
  return (
    <Layouts>
      <section className="bg-lightGray h-screen">
        <div className="py-8 text-primaryColor">
          <p>
            <Link to="/">Home </Link>&gt;
            <Link to="/find-doctors"> Doctors </Link>&gt;
            <a>{`Payment ${
              isLoading && !success
                ? "Pending"
                : !isLoading && success
                ? "Successfull"
                : "Unsuccessfull"
            }`}</a>
          </p>
        </div>
        {isLoading ? (
          <div className="flex flex-col items-center ">
            <Skeleton.Button
              className="mb-2"
              active
              size="large"
              shape="square"
              block={false}
            />
            <Skeleton.Node
              active
              className="w-96 h-36"
              style={{ width: 400 }}
            />
            <p className="text-md text-green-600 font-medium">
              Please wait while we confirm your payment.
            </p>
          </div>
        ) : (
          ""
        )}

        {success ? (
          <div>
            <div className="flex flex-col justify-center items-center">
              <h2 className="text-secondaryColor text-3xl font-normal text-center  ">
                <b>Appointment confirmed</b>{" "}
              </h2>
            </div>
            <div className="flex items-center justify-center ">
              <div className=" py-4 px-8  bg-White shadow-lg rounded-lg my-3">
                <div className="flex h-full ">
                  <img src={steth} alt="stethescope" className="w-15 mt-2" />
                  <div>
                    <div className="flex justify-between ">
                      <p className="text-md pl-2 pt-2">
                        {salutation + " " + userName}
                      </p>
                      <p className="text-sm pl-16 pt-3 text-secondaryColor">
                        {" "}
                        {moment() ===
                        moment(state.whenAppointment, "MM/DD/yyyyy")
                          ? "Today"
                          : moment(state.whenAppointment).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <div className="flex justify-between mt-2 pl-2">
                      <div
                        className={`h-4 px-2 bg-brand-${
                          memberColor[state.relation]
                        } rounded-2xl flex`}
                      >
                        <p className="text-xs text-White">{state.relation} </p>
                      </div>
                      {/* <p className="text-sm mr-14">{state?.consultationsType === "V" ? 'Online' : 'InPerson'}</p> */}
                      <p className="text-sm font-medium">
                        {state?.fromTime
                          ? moment(state.fromTime, "hh:mm").format("HH:mm A")
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex  mb-4 justify-center items-center">
              <button
                onClick={() =>
                  history.push({ pathname: "/", state: { fromPayment: true } })
                }
                className="bg-primaryColor  text-xl m-8 font-medium text-White p-2 rounded-xl mr-2"
              >
                {" "}
                Go to Home
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        {errMsg ? (
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-danger text-3xl font-normal text-center  ">
              <b>
                Sorry!
                <br />
                Your Appointment has not been confirmed.Please contact Admin
              </b>{" "}
            </h2>
            <div className="flex  mb-8 justify-center items-center">
              <button
                onClick={() =>
                  history.push({
                    pathname: "/",
                    search: "?some=search-string",
                    state: { fromPayment: true },
                  })
                }
                className="bg-primaryColor  text-xl my-4 font-medium text-White p-2 rounded-xl mr-2"
              >
                {" "}
                Go to Home
              </button>
              {/* <Link to="#" className="border border-brand-primary text-sm text-brand-primary rounded-xl p-2 mr-2"><a href="" className="text-xl m-8 font-medium text-indigo-500">Go to PHR</a></Link> */}
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </Layouts>
  );
}
export default AppoinmentConfirmation;
