import React, { useEffect, useRef, useState, startTransition } from "react";
import Layouts from "../../Layouts";
import ePrescription from "../../../Assets/Images/Icons/action1.svg";
import savedIcon from "../../../Assets/Images/Icons/action2.svg";
import repeatIcon from "../../../Assets/Images/Icons/action3.svg";
import Delivery from "../../../Assets/Images/Icons/Delivery.svg";
import Truck from "../../../Assets/Images/Icons/truck.svg";
import Price from "../../../Assets/Images/Icons/Price.svg";
import Location from "../../../Assets/Images/Icons/Location.svg";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import axios from "axios";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import CalenderSvg from "../../../Assets/Images/Calender.svg";
import ContactList from "../../../Assets/Images/contactList.svg";
import Doctor from "../../../Assets/Images/Doctor.svg";
import LocateIcon from "../../../Assets/Images/locate.svg";
import Drug from "../../../Assets/Images/Drug.svg";
import prescriptionIcon from "../../../Assets/Images/PrescriptionIcon.svg";
import prescriptionNote from "../../../Assets/Images/PrescriptionNote.svg";
import DoctorPrescription from "../../../Assets/Images/DoctorPrescription.jpg";
import { FaFileUpload } from "react-icons/fa";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import http from "../../../Redux/Services/http-common";
import { getAllProductByIds } from "../../../Redux/Action/pharmacyAction";
import Search from "antd/es/input/Search";
import {
  setAddressString,
  setLatLong,
} from "../../../Redux/Action/userActions";
import { secureStorage } from "../../../Redux/Reducer/authReducer";
import { Drawer, Modal, Segmented, Upload } from "antd";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CartDocImg from "../../../Assets/Images/CarDocImg.svg";
import moment from "moment";
// import CurebayButton from "../../"

import {
  AddtoCart,
  getCartDetails,
} from "../../../Redux/Action/cartPlaceOrderAction";
import SideBar from "./UploadPrescription";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { debounce } from "lodash";
import NodataImage from "../../../Assets/Images/Icons/nodata.svg";
import Loader from "../../Shared/loader";
import { EnglishText } from "../../PageRouting/EnglishText";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  trackAddToCartEvent,
  trackPageEvent,
} from "../../utils/analytics/events";
import SearchBar from "../../Shared/SearchBar";
import MedicineCard from "../../Shared/medicine-card";
import { InputText } from "primereact/inputtext";
import seachIcon from "../../../Assets/Images/search.svg";
import { useApiData } from "../../../Context/ApidtaContext";
import Alert from "@mui/material/Alert";
import defaultMed from "../../../Assets/pharamacyImages/Medicines.jpg";
import CurebayButton from "../../Shared/Button/PrimaryButton";
function Medicine() {
  const { medicinePage } = useApiData();

  let whyCurebay = medicinePage?.attributes?.headerComp[0]?.image_url;
  const userData = useSelector((state) => state.authReducer.patientData);
  const [topratedMedicine, setTopratedMedicine] = useState([]);
  const [medicineSearched, setmedicineSearched] = useState([]);
  const storedData = JSON.parse(localStorage.getItem("patientInfo"));
  console.log(storedData?.data, "ProfileDataaa");
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [searchedMedicine, setSearchedMedicine] = useState("");
  const [isAvailable, setIsAvailable] = useState(true);
  const [pastMedOrders, setpastMedOrders] = useState(null);
  const isMobileView = window.innerWidth <= 768;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { customPinCode: pincode } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (searchedMedicine.length) {
      fetchMedicines();
    }
  }, [searchedMedicine]);

  useEffect(() => {
    trackPageEvent("medicine_page_view");
    dispatch(getCartDetails(userData.code));
  }, []);

  useEffect(() =>{
    checkAvailablity()
  },[pincode])

  const checkAvailablity = async () =>{
    const data = await http.get(
      `${process.env.REACT_APP_BASEURL}ServiceAvailability/checkService?pinCode=${pincode}`
    );
    if (data.data.status !== 200) {
      setIsAvailable(false)
    }else{
      setIsAvailable(true)
    }
  }

  const fetchMedicines = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}medicine?freeTextSearch=${searchedMedicine}&pageNo=1&pageSize=20`
      );
      window.scrollTo({
        top: 480,
        behavior: "smooth",
      });
      setmedicineSearched(res.data);
    } catch (error) {}
  };

  const ToggleSideBar = () => {
    if (userData?.sessionid) {
      // (e) => redirectTo(e, APP_ROUTES.UPLOADED_PRESCRIPTION)
      setIsSidebarOpen(!isSidebarOpen);
    } else {
      history.push({
        pathname: APP_ROUTES.LOGIN,
        state: { background: location, login: true },
      });
    }
  };
  console.log(moment().format("YYYY-MM-DD HH:mm:ss"), "currentdate");

  const handleUploadPrescriptionClick = async (file, radiobutton) => {
    let data = {};
    for (let i = 0; i < file.length; i++) {
      data = {
        patientCode: userData.code,
        document: file[i].b64File,
        documentType: file[i].type,
        status: 1,
        createdBy: userData.code,
        modifiedBy: userData.code,
        conversionNeeded: radiobutton[i] == "original" ? false : true,
        documentReceivedSource: "B2C",
        givenDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      await http.post(`${process.env.REACT_APP_BASEURL}PatientDocument/`, data);
    }
  };

  const onChange = (item) => {
    setValue(item);
  };

  const handleModal = () => {
    setIsModalOpen(false);
  };
  const { cartList } = useSelector((state) => state.cartReducer);
  console.log(cartList);

  const handleClick = (action) => {
    if (action === "bookAnAppointment") {
      if (Object.keys(userData).length === 0) {
        history.push("/", { isLoginModalOpen: true });
      } else {
        history.push("/services/book-an-appointment");
      }
    } else if (action === "uploadPrescription") {
      if (Object.keys(userData).length === 0) {
        history.push("/", { isLoginModalOpen: true });
      } else {
        // setIsDrawerOpen(true);
        setIsSidebarOpen(true);
      }
    } else if (action === "viewSamplePrescription") {
      if (Object.keys(userData).length === 0) {
        history.push("/", { isLoginModalOpen: true });
      } else {
        setIsModalOpen(true);
      }
    }
  };

  const PrescriptionModal = () => {
    return (
      <Modal
        centered
        width={"30%"}
        open={isModalOpen}
        onOk={handleModal}
        onCancel={handleModal}
        footer={[]}
      >
        <div className="flex justify-center items-center">
          <img src={DoctorPrescription} alt="prescription" />
        </div>
      </Modal>
    );
  };

  const handleScroll = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    handleScroll();
  }, []);

  const handleNavigation = (path) => {
    history.push(path);
    window.scrollTo(0, 0);
  };

  



  async function gettopMedicineData(medicineCode, type) {
    var raw = JSON.stringify(medicineCode);

    dispatch(getAllProductByIds(raw))
      .then((res) => {
        // if (type == "recentMedicine") {
        setTopratedMedicine(res);
        console.log(res, "result");
        // } else if (type == "orderMedicines") {
        //   setRecentlyOrderMedicine(res);
        // }
      })
      .catch((err) => {});
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Handle the Enter keypress here
      setIsOpen(false);
      window.scrollTo({
        top: 480,
        behavior: "smooth",
      });
      // You can perform any action you want here, such as submitting a form, fetching data, etc.
    }
  };

  const Tablets = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    http.get(`${process.env.REACT_APP_BASEURL}Drug/commonDrug`).then((res) => {
      console.log(res.data.data, "sdvihdsouvosduhvsdvdsv");
      if (res.data.data.length) {
        let data = res.data.data.slice(0, 8);
        gettopMedicineData(data, "orderMedicines");
      }
    });
  }, []);
  const [showTopSearch, setShowTopSearch] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const homeBanner = document.querySelector(".home-banner");
      const topSearch = document.querySelector(".topsearch");

      if (homeBanner.getBoundingClientRect().top <= 0) {
        setShowTopSearch(true);
      } else {
        setShowTopSearch(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClicks = (e) => {
    console.log(e, "sdvidshovudsguodsvdsvds");
  };

  const handleViewAll = (e) => {
    history.push(APP_ROUTES.PAST_ORDERS);
  };

  const fetchPastOrders = async () => {
    try {
      let patient_id = storedData?.data?.code;
      if (!patient_id) {
        console.error("Patient ID is not defined.");
        return;
      }

      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}MedicineOrder/getDistinctMedicines?patientId=${patient_id}&orderBy=ORDER_DATE_AESC`
      );

      if (res?.data?.data) {
        setpastMedOrders(res.data.data);
      } else {
        console.warn("No data received from the API.");
      }
    } catch (error) {
      console.error("Error fetching past medicine orders:", error.message);
    }
  };

  useEffect(() => {
    fetchPastOrders();
  }, []);

  const getProductsIDs = () => {
    let ids = [];
    if (cartList) {
      if (cartList?.patientMedicineOrder?.length) {
        cartList?.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(
          (res) => {
            ids.push(res.drugCode);
          }
        );
      }
    }
    return ids;
  };
  const drugIDsInCart = getProductsIDs();

  console.log(drugIDsInCart,"DrugID")

  const redirectToMedicinePage = (item) => {
    // history.push('/medicine');
    history.push(`/medicine/${item}`);
  };

  const handleAddToCart = async (item) => {
    let payload = {
      patientId: item?.patientId,
      drugCode: item?.drugCode,
      drugName: item?.drugName,
      drugCategory: item?.drugCategory,
      medicineTypeOfSell: item?.drugsInfoTypeofSell,
      medicineType: "",
      unitPrice: Number(item?.mdmDrugDetails?.medicineRate),
      quantity: 1,
      prescriptionRequired: item?.mdmDrugDetails.prescriptionRequired,
      vendorId:item?.vendorId
    };
    console.log(payload, "Payload");
    fetch(`${process.env.REACT_APP_BASEURL}newPatientMedicine/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res, "vghsdvhcvdsg");
        trackAddToCartEvent(item?.mdmDrugDetails?.medicineRate, item?.drugName);
        // setIsLoading(false);
        dispatch(getCartDetails(item?.patientId));
      })
      .catch((err) => {
        console.error("Error adding to cart:", err);
        // setIsLoading(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{"Medicines | Curebay"}</title>
        <meta
          name="description"
          content={EnglishText.PHARMACY_META_DESCRIPTION}
        />
        <meta name="title" content={"Medicines | Curebay"} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div onClick={() => handleKeyPress("Enter")}>
        <PrescriptionModal />
        <Layouts>
          <SearchBar searchedText={(text) => setSearchedMedicine(text)} />
          {!isAvailable && (
            <Alert
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                color: "#ff0000",
              }}
              severity="warning"
              color="warning"
            >
              We do not service your area yet. Coming soon!
            </Alert>
          )}
          <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
            <div className="container mx-auto md:pt-[70px]">
              <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
              <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
                <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-primaryColor">
                  <h1 className="text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                    One stop destination for all your{" "}
                    <span className="text-secondaryColor">
                      {" "}
                      prescribed needs
                    </span>
                    !
                  </h1>
                  <p className="text-White font-light text-sm md:text-lg mb-5">
                    Genuine Medicines & Essentials at the Best Price.
                  </p>
                  {/* <div className="relative w-full max-w-md">
                    <input
                      type="text"
                      value={valueSearch}
                      onChange={(e) => setValueSearch(e.target.value)}
                      placeholder="Search by name, specialty or symptom"
                      className="w-full pl-14 pr-4 py-3 text-sm rounded-full border border-transparent bg-white shadow-lg placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <span className="absolute inset-y-0 left-4 flex items-center text-gray-600">
                      <img
                        src={seachIcon}
                        alt="Search Icon"
                        className="h-6 w-6"
                      />
                    </span>
                  </div> */}
                </div>

                <div className="relative z-1 flex justify-end mt-5 md:mt-0">
                  <div className="w-full md:w-[90%] md:h-[400px] rounded">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Medicine.webp"
                      alt="images"
                      className="w-full h-full image-boxshadow rounded-xl"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {searchedMedicine.length && medicineSearched.length !== 0 ? (
            <section className="bg-lightGray">
              <div className="container mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 py-10">
                  {medicineSearched.length !== 0 &&
                    medicineSearched.map((item, idx) => (
                      <MedicineCard key={idx} index={idx} cardItem={item} />
                    ))}
                </div>
              </div>
            </section>
          ) : medicineSearched.length === 0 ? (
            <h1></h1>
          ) : (
            ""
          )}
         <section className="bg-lightBlue py-10">
            <div className="container mx-auto px-4">
              <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-5">
                Personalised <span className="text-secondaryColor">Recommendations</span>
              </h2>
              <p className="text-center text-textGray text-base mb-8">
                Based on your previously purchased medicines
              </p>
              <div className="flex flex-col md:flex-row md:justify-between items-center bg-White rounded-md p-5 mb-8">
                <h1 className="text-primaryColor text-lg md:text-xl font-semibold mb-3 md:mb-0">
                  Past Orders
                </h1>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-8">
                {pastMedOrders?.length > 0 &&
                  pastMedOrders.slice(0, isMobileView ? 2 : 4).map((item, idx) => (
                    <div key={idx} className="bg-White rounded-lg p-5 shadow-md flex flex-col justify-between cursor-pointer">
                       <img src={item?.mdmDrugDetails?.medicineImages && item?.mdmDrugDetails?.medicineImages?.length > 0 ? item?.mdmDrugDetails?.medicineImages[0] : defaultMed} className="w-full h-32 object-cover rounded-md mb-5" onClick={()=>redirectToMedicinePage(item?.mdmDrugDetails?.seoNameId)}/>
                      <h3 className="text-lg font-semibold text-primaryColor mb-2">
                        {item?.drugName}
                      </h3>
                      {item?.mdmDrugDetails?.medicineRate && (
                        <p className="text-secondaryColor text-base font-semibold mb-2">
                          Rs. {item?.mdmDrugDetails?.medicineRate}  
                        </p>
                      )}
                      {item?.drugsInfoTypeofSell && (
                        <p className="text-gray-500 text-sm mb-4 truncate">
                          {item?.drugsInfoTypeofSell}
                        </p>
                      )}
                      <button
                      className={`px-6 py-3 rounded-md font-semibold transition h-12 ${
                        drugIDsInCart.includes(item.drugCode)
                          ? "bg-secondaryColor text-White cursor-not-allowed opacity-50"
                          : "bg-primaryColor text-White hover:bg-secondaryColor"
                      }`}
                        onClick={() => handleAddToCart(item)}
                        // className={`w-full border border-primaryColor text-primaryColor px-6 py-3 rounded-md font-semibold hover:bg-primaryColor hover:text-White transition`}
                        disabled={drugIDsInCart.includes(item.drugCode)}
                      >
                        {drugIDsInCart.includes(item.drugCode)
                        ? "Added"
                        : "Add to cart"}
                      </button>
                  </div>
                  ))}
              </div>
              {pastMedOrders?.length > (isMobileView ? 2 : 4) && (
                <div className="flex justify-center">
                  <button
                    type="primary"
                    onClick={handleViewAll}
                    className="bg-primaryColor text-White px-6 py-3 rounded-md font-semibold hover:bg-secondaryColor transition"
                  >
                    View All
                  </button>
                </div>
              )}
            </div>
          </section>

          <section className="bg-White">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              {Object.keys(userData).length !== 0 && (
                <>
                  <div>
                    <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[50px]">
                      Quick <span className="text-secondaryColor">Actions</span>
                    </h2>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                    <div
                      onClick={() => handleNavigation("/e-prescription")}
                      className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow hoverWhite cursor-pointer"
                    >
                      <div className="flex items-center gap-5">
                        <div className="w-[20%]">
                          <img
                            src={ePrescription}
                            alt="images"
                            className="w-full "
                          />
                        </div>
                        <div className="w-[75%]">
                          <h5 className="text-primaryColor text-lg font-semibold ">
                            CureBay e-Prescription
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => handleNavigation("/saved-prescriptions")}
                      className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow hoverWhite cursor-pointer"
                    >
                      <div className="flex items-center gap-5">
                        <div className="w-[20%]">
                          <img
                            src={savedIcon}
                            alt="images"
                            className="w-full "
                          />
                        </div>
                        <div className="w-[75%]">
                          <h5 className="text-primaryColor text-lg font-semibold ">
                            Saved Prescriptions
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => handleNavigation("/medicineorder")}
                      className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow hoverWhite cursor-pointer"
                    >
                      <div className="flex items-center gap-5">
                        <div className="w-[20%]">
                          <img
                            src={repeatIcon}
                            alt="images"
                            className="w-full "
                          />
                        </div>
                        <div className="w-[75%]">
                          <h5 className="text-primaryColor text-lg font-semibold ">
                            Repeat your past order
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
          <section className="bg-primaryColor">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-semibold text-White leading-normal">
                Upload your{" "}
                <span className="text-secondaryColor"> Prescription</span>
              </h2>
              <div className="w-full md:flex gap-5 mt-16">
                <div className="bg-[#00345a] rounded-md md:w-[45%]">
                  <h3 className="text-White text-lg md:text-2xl font-medium text-center pt-8 mb-3">
                    What is a valid{" "}
                    <span className="text-secondaryColor">Prescription</span>?
                  </h3>
                  <p className="text-White text-sm md:text-base font-light text-center">
                    A prescription contains
                  </p>
                  <div className="my-3">
                    <div class="flex">
                      <div class=" w-2/4 py-5 border-r border-textGray border-b">
                        <div class="flex justify-center">
                          <img
                            src={CalenderSvg}
                            className="w-14"
                            alt="calender"
                          />
                        </div>
                        <p className="text-White text-center text-sm md:text-base font-normal mt-3">
                          Prescription Date
                        </p>
                      </div>
                      <div class="  w-2/4 py-5 border-textGray border-b">
                        <div class="flex justify-center">
                          <img src={Doctor} className="w-14 " alt="doctor" />
                        </div>
                        <p className="text-White text-center text-sm md:text-base font-normal mt-3">
                          {" "}
                          Doctor Details
                        </p>
                      </div>
                    </div>
                    <div class="flex">
                      <div class=" flex-col w-2/4 py-5 border-r border-textGray">
                        <div class="flex justify-center">
                          <img
                            src={ContactList}
                            className="w-14 text-center"
                            alt="contactlist"
                          />
                        </div>
                        <p className="text-White text-center text-sm md:text-base font-normal mt-3">
                          Patient Details
                        </p>
                      </div>
                      <div class="flex-col w-2/4 py-5">
                        <div class="flex justify-center">
                          <img src={Drug} className="w-14  " alt="drug" />
                        </div>
                        <p className="text-White text-center text-sm md:text-base font-normal mt-3">
                          Medicine Details
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <button
                      className="w-[90%] md:w-[60%] brightnessWhite flex items-center justify-center gap-2 bg-lightBlue group hover:bg-secondaryColor hover:text-White text-primaryColor rounded-md py-3 mt-3 mb-7 "
                      onClick={() => handleClick("viewSamplePrescription")}
                    >
                      <img
                        src={prescriptionIcon}
                        alt="presc"
                        className="text-White "
                      />
                      <span className="font-medium ">
                        View sample prescription
                      </span>
                    </button>
                  </div>
                </div>
                <div className="md:w-[55%]">
                  <div className="bg-White p-7 rounded-md">
                    <div className="md:flex gap-5 items-center">
                      <div className="flex justify-center w-full md:w-auto">
                        <img
                          src={prescriptionNote}
                          alt="medicineimg"
                          className="w-[15rem] h-[15rem]"
                        />
                      </div>
                      <div>
                        <div>
                          <h3 className="text-primaryColor mb-3 text-lg md:text-xl font-semibold">
                            Not able to understand your prescription?
                          </h3>
                          <p className="text-textGray text-sm md:text-base font-normal">
                            We are here to help you
                          </p>
                        </div>

                        <div className="flex justify-center mt-8">
                          <button
                            className="w-full flex items-center justify-center gap-2 bg-primaryColor text-White rounded-md py-3 "
                            onClick={() => handleClick("uploadPrescription")}
                          >
                            <FaFileUpload />
                            <span className="font-medium ">
                              Upload Prescription{" "}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#00345a] mt-5 rounded-md md:flex justify-between ">
                    <div className="px-7 py-5">
                      <p className="font-medium text-xl mb-2 text-White">
                        Don't have a prescription
                      </p>
                      <p className="text-White font-light text-sm ">
                        please call us or book an appointment with our doctors
                      </p>
                      <div className="md:flex w-full mt-6 gap-5">
                        <button
                          className="w-fit bg-White mb-3 md:mb-0 py-2 px-5 rounded-md text-primaryColor text-sm font-medium"
                          onClick={() => window.open("tel:+918335000999")}
                        >
                          +91-8335 000 967
                        </button>
                        <button
                          className="w-fit bg-White  py-2 px-5 rounded-md text-primaryColor text-sm font-medium"
                          onClick={() => handleClick("bookAnAppointment")}
                        >
                          Book an appointment
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <img
                        src={CartDocImg}
                        alt="img"
                        className="w-full h-full hidden md:block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="bg-primaryColor">
            <div className="container mx-auto py-[50px] md:py-[100px]">
              <h2 className="text-center text-2xl md:text-4xl font-semibold text-White leading-normal mb-[50px]">
                Top Selling{" "}
                <span className="text-secondaryColor"> Medicine</span>
              </h2>
              <div className="">
                <Slider {...Tablets} className="w-full">
                  {topratedMedicine &&
                    topratedMedicine.map((item, idx) => (
                      <MedicineCard key={idx} index={idx} cardItem={item} />
                    ))}
                </Slider>
              </div>
            </div>
          </section> */}
          {/* <section className="bg-lightGray">
            <div className="container mx-auto py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-[50px]">
                Why choose CureBay for <br />
                <span className="text-secondaryColor"> your Medicines</span>?
              </h2>
              <div className="md:w-[70%] mx-auto grid md:grid-cols-2 gap-5">
                <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow  hoverWhite">
                  <div className="mb-5">
                    <img src={Truck} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Rapid 3-Hour Delivery
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Swiftly at your doorstep for your health needs.
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                  <div className="mb-5">
                    <img src={Price} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Assured Best Prices for All
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Best price guarantee & savings on every medicine.
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                  <div className="mb-5">
                    <img src={Delivery} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Free Doorstep Delivery
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Zero-Cost doorstep delivery, medicines delivered free,
                    directly to you.
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                  <div className="mb-5">
                    <img src={Location} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Real-Time Order Tracking
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Live order tracking & watch your medicine's journey to your
                    door in real-time.
                  </p>
                </div>
              </div>
            </div>
          </section> */}
          <section className="bg-lightBlue py-16">
            <div className="container mx-auto px-4 text-center">
              <h2 className="text-primaryColor text-2xl md:text-4xl font-semibold mb-8">
                Why choose CureBay for{" "}
                <span className="text-secondaryColor">Medicine order</span>?
              </h2>

              {/* Image Section */}
              <div className="flex justify-center">
                <img
                  src={whyCurebay} // Use the parameter containing the image URL for the "Why CureBay" section
                  alt="Why Choose CureBay for Medicine Order"
                  className="max-w-full h-auto"
                />
              </div>
            </div>
          </section>
        </Layouts>
        <SideBar
          isSidebarOpen={isSidebarOpen}
          handleUploadPrescriptionClick={handleUploadPrescriptionClick}
          ToggleSideBar={ToggleSideBar}
        />
      </div>
    </>
  );
}

export default Medicine;
