import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const Cystoscopy = ({data}) =>{
    console.log(data, "sdvkjdsiuvdsbgvdsvds")
    if(!data?.sixthComp?.points?.length){
        return null
    }
    return(
        <>
        <div>
                <h2 className="text-xl md:text-2xl font-semibold text-center mb-4 my-20" style={{color:"#004171", fontSize: 36, fontWeight: 600}} >{data?.sixthComp?.points[0].mainTitle}</h2>
            <div className="grid md:grid-cols-2 px-2 md:px-12" >
            {
                data?.sixthComp?.points[0].sub_points.map((res,index) => (
                    <div style={{borderBottom: "dashed"}} >
                        <div className="flex items-start relative" > 
                            <img className="absolute top-8" src= {CheckIcon} height={20} width={20} />
                    <p className="p-8 font-medium text-lg" style={{color:"#676C80"}} >{res.title}</p>
                        </div>
                    </div>
                ))
            }
                </div>
        </div>
              {data?.seventhComp ? (
                <div className="bg-primaryColor text-white py-10 px-4 md:px-16 mt-12">
                  <div className="container mx-auto">
                    <div className="flex flex-col md:flex-row md:items-start">
                      <div className="md:w-1/2 mb-6 md:mb-0">
                        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-White">
                          {data?.seventhComp.title}
                        </h2>
                      </div>
        
                      <div className="md:w-1/2">
                        <p className="text-base md:text-lg text-White mb-4">
                        {data?.seventhComp.description}
                        </p>
                        {data?.seventhComp.points &&
                          <ul style={{listStyleType: "disc",color:"#ffff"}} >
                          {data?.seventhComp.points.map(res =>{
                            return <li className="text-base font-light mb-4" ><p className="text-base font-semibold" style={{color:"#ffff"}}><span className="font-semibold" >{res.title}</span>{res.description}</p></li>
                          })}
                        </ul>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p></p>
              )}
              {
                data.eightComp?.points?.length ? <div className="md:px-16" style={{backgroundColor:"#EBF8FF"}} >
                    {
                        data.eightComp?.points.map(res =>{
                            return(
                                <div className="grid md:grid-cols-2 py-4 md:py-8" style={{borderBottom: "dashed"}} >
                                    <div> <p className="p-8 text-xl md:text-2xl font-semibold" style={{color:"#004171"}} >{res.title}</p> </div>
                                    <div>
                                    {res.description && <p className="p-8 text-md" style={{color:"#004171"}} >{res.description}</p>}
                    <ul className="ml-6 md:ml-12" style={{listStyleType: "disc",color:"#004171"}} >
                          {res?.sub_points?.map(res =>{
                            return <li className="mb-2" ><p className="text-md font-medium" style={{color:"#004171"}}>{res.description}</p></li>
                          })}
                        </ul>
                         </div>
                   
                    </div>
                            )
                        })
                    }
                </div> : null
              }
              {
                data.ninthComp?.points?.length ? <div className="py-12" >
                    <h4 style={{color:"#004171" }} className="text-center mb-4 text-xl md:text-2xl font-semibold" >{data.ninthComp.title}</h4>
                    <h4 style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} className="text-center mb-4" >{data.ninthComp.description}</h4>
                    <div className="grid md:grid-cols-2" >
                    {
                        data.ninthComp?.points.map((res , index) =>{
                            return( <div className="p-8"> 
                             <div className="flex items-center relative" > 
                            <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                            <p className="text-xl md:text-2xl font-semibold" style={{color:"#005D8D"}} >{res.title}</p>
                        </div>
                                    <p className="ml-10 text-base mt-2" style={{color:"#676C80",}} >{res.description}</p>
                            </div>
                   
                            )
                        })
                    }
                    </div>
                </div> : null
              }
              </>
    )
}

export default Cystoscopy