import React from "react";
import Layouts from "../Layouts";

function MedicineDeliveryPolicy() {
  return (
    <Layouts>
      <section className="home-banner md:min-h-[60vh] py-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                Medicine Delivery Policy
              </h1>
              <p className="text-primaryColor md:text-White font-extralight text-sm md:text-lg">
                CureBay provides an easy refund policy, allowing returns within
                a specified period and quick refunds through the original
                payment method or another choice. Customers have access to clear
                support for refund inquiries.
              </p>
            </div>
            <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%] md:h-[400px] rounded ">
                <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Return%20%26%20Refund%20Policy.webp" alt="images" className="w-full h-full object-cover image-boxshadow rounded-xl" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto py-[50px] md:py-[50px]">
        <h5 className=" text-primaryColor text-lg font-semibold mb-3">
          IMPORTANT INSTRUCTIONS
          </h5>

          <ul className=" pl-5 mb-5">
            <li className="text-textGray">
            ❖ Valid prescription from a Doctor is mandatory for all medicine orders         
            </li>
            <li className="text-textGray">
            ❖ Scanned copy of a valid prescription from an authorized Doctor must be sent to us mandatorily
            </li>
            <li className="text-textGray">
            ❖ We will not be able to process the orders without a valid prescription 
           </li>
          
          </ul>
          <h5 className=" text-primaryColor text-lg font-semibold mb-3">
          PAYMENT OPTIONS
          </h5>

          <p className="text-textGray pb-3">
          We accept payment through Debit Cards, Credit Cards, Net banking & UPI.
          </p>

          <h5 className=" text-primaryColor text-lg font-semibold mb-3">
          AUTHENTICITY OF THE MEDICINES
          </h5>

          <p className="text-textGray pb-3">
          We procure the medicines directly from licensed pharmacists and manufacturers. We dedicate reasonable
time and resource to check the quality and authentic the medicines before delivery and ensure that the
medicines are well within the expiry date.
          </p>
       

          <h5 className=" text-primaryColor text-lg font-semibold mb-3">
          TERMS OF DELIVERY:
          </h5>
          <h1 style={{ fontWeight: 'bold' }} className="text-textGray mt-3">Standard Delivery Time</h1>
      <p className="text-textGray">
        We generally deliver orders within 24-48 hours, but could take up to 7 days depending on your geographical location and the items ordered. You are advised to check the estimated delivery date displayed at the time of placing your order.
      </p>

      <h6 style={{ fontWeight: 'bold' }} className="text-textGray mt-3">Pricing Information</h6>
      <p className="text-textGray">
        We undertake all reasonable efforts to provide accurate product and pricing information, yet we do not guarantee the pricing until an order has been placed. You are advised to check the final price displayed while placing an order.
      </p>

      <h6 style={{ fontWeight: 'bold'  }} className="text-textGray mt-3">Cancellation by Curebay</h6>
      <p className="text-textGray">
        It is being informed that there may be certain circumstances where we are unable to accept an order and must cancel the same. We reserve the right, at our sole discretion, to refuse or cancel any order for any reason. Such circumstances that may result in your order being cancelled may include but are not limited to inadequate quantities available for purchase, inaccuracies or errors in medicine or pricing information, problems identified by our payment gateway. We will contact you if all or any portion of your order is cancelled or if additional information is required to accept your order. If your order is cancelled after your order has been placed successfully and money has been debited from your account, the said amount will be reversed back in your original source of payment.
      </p>

      <h6 style={{ fontWeight: 'bold' }} className="text-textGray mt-3">Cancellations by the Customer</h6>
      <p className="text-textGray">
        In case we receive a notification for cancellation and the order has not been shipped by us, we shall cancel the order and refund the entire amount. The details of the return/cancellation and refund are provided under the “Return and Refund Policy”.
      </p>

      <h6 style={{ fontWeight: 'bold' }} className="text-textGray mt-3">Information By Customer:</h6>
      <p className="text-textGray">
        We may also require additional verifications or information before accepting any order. You understand and agree that Curebay may contact you to gather further information about your order before confirming your order.
      </p>

      <p className="text-textGray mt-3" >
        If you have any questions about our Medicine Delivery Policy or your expected refunds, please contact us at our customer support numbers/email mentioned on the website.
      </p>
        </div>
      </section>
    </Layouts>
  );
}

export default MedicineDeliveryPolicy;
