import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const GallStone = ({data}) =>{
    return(
        <>

<div className="my-8 md:my-16" >
                <h2 className="text-center mb-4" style={{color:"#004171", fontSize: 36, fontWeight: 600}} >{data?.sixthComp?.points[0].mainTitle}</h2>
                <h4 style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} className="text-center mb-4" >{data.sixthComp.points[0].mainSubTitle}</h4>

            <div className="grid md:grid-cols-2 px-2 md:px-12" >
            {
                data?.sixthComp?.points.map((res,index) => (
                    <div className="p-8" style={{borderBottom: "dashed"}} >
                        <div className="flex items-start relative" > 
                            <img className="absolute top-2" src= {CheckIcon} height={20} width={20} />
                    <p className="pl-8 text-xl md:text-2xl font-semibold" style={{color:"#005D8D"}} >{res.title}</p>
                    
                        </div>
                        <p className="pl-8 text-base" style={{color:"#676C80"}} >{res.description}</p>
                    </div>
                ))
            }
                </div>
        </div>

        {data?.seventhComp ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#EBF8FF" }}
        >
          <div className="container mx-auto">
            <h2
              className="text-2xl md:text-3xl font-bold mb-8 text-center"
              style={{ color: "rgb(0, 93, 141)" }}
            >
              {/* {renderHeadingWithHighlightedText(
                data?.seventhComp?.heading,
                data?.surgeryName
              )} */}
              {data?.seventhComp?.title}
            </h2>
            <h6 className=" mb-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}} >{data?.seventhComp?.description}</h6>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8">
              {data?.seventhComp?.points.map(
                (symptom, index) => (
                  <div
                    className="p-4 rounded-lg"
                    key={index}
                    style={{backgroundColor:"#ffff"}}
                  >
                    <img
                      src={symptom?.icon_url}
                      // alt={symptom?.description}
                      className="mx-auto mb-4"
                    />
                    <p
                      className="text-sm md:text-base font-medium break-words"
                      style={{ color: "rgba(0, 65, 113, 1)" }}
                    >
                      {symptom.title ? symptom.title + symptom.description : symptom.description}
                    </p>
                  </div>
                  
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <p>No symptoms data available</p>
      )}

{data.eightComp?.points?.length ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div
            className="container mx-auto"
            style={{ color: "rgb(0, 93, 141)" }}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-8 text-center">
              {/* {renderHeadingWithHighlightedText(
                data.eightComp?.heading,
                data.surgeryName
              )} */}
              {data.eightComp.points[0].mainTitle}
            </h2>
            {data.eightComp.points[0]?.mainSubTitle && <h6 className=" mb-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}} >{data.eightComp.points[0]?.mainSubTitle}</h6>}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-0 md:mx-16">
              {data.eightComp?.points.map((type, index) => {
                return (<div className="rounded-xl py-4 px-8" style={{border: "0.5px solid #E3E3E3"}} >
                <p className="text-xl font-semibold mb-2" style={{color:"#005D8D"}} >{type.title}</p>
                <p className="text-base font-medium" style={{color:"#676C80"}}>{type.description}</p>
                <ul style={{listStyleType: "disc"}} >
                {type?.sub_points?.length ? type.sub_points.map(res =>{
                  return <li className="text-base font-medium" style={{color:"#676C80"}}>{res?.title} {res.description}</li>
                }) : null}
              </ul>
                </div>)
              })}
            </div>
          </div>
          <h4 className=" my-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}}>{data.eightComp?.points[0]?.mainnotes}</h4>
        </div>
      ) : (
        <p></p>
      )}
                       {
                data.tenthcomp?.length ? <div className="py-12" >
                    <h4 style={{color:"#004171" }} className="text-center mb-4 text-xl md:text-2xl font-semibold" >{data.tenthcomp[0].title}</h4>
                    <h4 style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} className="text-center mb-4 px-4" >{data.tenthcomp[0].description}</h4>
                    <div className="grid md:grid-cols-2" >
                    {
                        data.tenthcomp[0]?.points[0].subpoints?.map((res , index) =>{
                            return( <div className="p-8"> 
                             <div className="flex items-start relative" > 
                            <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                            <p className="" style={{color:"#005D8D", fontSize: 18, fontWeight: 600}} >{res.title}</p>
                        </div>
                        <p className="md:ml-10" style={{color:"#676C80", fontSize: 16, fontWeight: 400}} >{res.description}</p>
                            </div>
                   
                            )
                        })
                    }
                    </div>
                </div> : null
              }
              </>
    )
}

export default GallStone