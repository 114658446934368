import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const VaricoseVeins = ({data}) =>{
    return(
        <>
         <div className="my-8 md:my-16 px-4 md:px-8" >
                <h2 className="text-center mb-4 text-xl md:text-2xl font-semibold" style={{color:"#004171"}} >{data?.sixthComp?.points[0].mainTitle}</h2>
                <h4 style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} className="text-center mb-4" >{data.sixthComp.points[0].maindescription}</h4>

            <div className="grid md:grid-cols-2 px-2 md:px-12" >
            {
                data?.sixthComp?.points.map((res,index) => (
                    <div className="p-8" style={{borderBottom: "dashed"}} >
                        <div className="flex items-start relative" > 
                            <img className="absolute top-2" src= {CheckIcon} height={20} width={20} />
                    <p className="pl-8 mb-2" style={{color:"#004171", fontSize: 18, fontWeight: 600}} >{res.title}</p>
                    
                        </div>
                        <p className="pl-8" style={{color:"#676C80", fontSize: 16, fontWeight: 400}} >{res.description}</p>
                    </div>
                ))
            }
                </div>
        </div>

        {data?.seventhComp ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#EBF8FF" }}
        >
          <div className="container mx-auto">
            <h2
              className="text-2xl md:text-3xl font-bold mb-8 text-center"
              style={{ color: "rgb(0, 93, 141)" }}
            >
              {/* {renderHeadingWithHighlightedText(
                data?.seventhComp?.heading,
                data?.surgeryName
              )} */}
              {data?.seventhComp?.title}
            </h2>
            <h6 className=" mb-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}} >{data?.seventhComp?.description}</h6>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8">
              {data?.seventhComp?.points.map(
                (symptom, index) => (
                  <div
                    className="p-4 rounded-lg"
                    key={index}
                    style={{backgroundColor:"#ffff"}}
                  >
                    <img
                      src={symptom?.icon_url}
                      // alt={symptom?.description}
                      className="mx-auto mb-4"
                    />
                    <p
                      className="text-sm md:text-base font-medium"
                      style={{ color: "rgba(0, 65, 113, 1)" }}
                    >
                      {symptom.title ? symptom.title + symptom.description : symptom.description}
                    </p>
                  </div>
                  
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <p>No symptoms data available</p>
      )}

              {
                data.eightComp?.points?.length ? <div className="mt-8 md:mt-16 px-4 md:px-8" style={{backgroundColor:"#004171"}} >
                    {
                        data.eightComp?.points.map(res =>{
                            return(
                                <div className="grid md:grid-cols-2 py-4 md:py-8">
                                    <div> <p className="p-8 text-xl md:text-2xl font-semibold" style={{color:"#ffff"}} >{res.mainTitle}</p> </div>
                                    <div>
                                    {res.description && <p className="p-8" style={{color:"#ffff", fontSize: 20, fontWeight: 300}} >{res.maindescription}</p>}
                                    {res.description && <p className="p-8" style={{color:"#ffff", fontSize: 20, fontWeight: 300}} >{res.description}</p>}
                        <h4 className="text-sm font-regular" style={{color: "#ffff"}} >{data.eightComp?.points[0].mainnotes}</h4>
                         </div>
                          
                    </div>
                            )
                        })
                    }
                </div> : null
              }

{
                data.tenthcomp?.length ? <div style={{backgroundColor:"#EBF8FF"}} >
                    {
                        data.tenthcomp?.map(res =>{
                            return(
                                <div className="grid md:grid-cols-2 py-4 md:py-8" style={{borderBottom: "dashed"}} >
                                    <div> <p className="p-8 text-xl md:text-2xl font-semibold" style={{color:"#004171"}} >{res.title}</p> </div>
                                    <div>
                                    {res.description && <p className="p-8 text-lg font-medium" style={{color:"#004171",}} >{res.description}</p>}
                    <ul className="ml-6 md:ml-12" style={{listStyleType: "disc",color:"#004171"}} >
                          {res?.points?.map(res =>{
                            if(res?.title)
                            return <li className="mb-2" ><p className="text-lg font-medium" style={{color:"#004171"}}>{res?.title}</p></li>
                          })}
                        </ul>
                         </div>
                   
                    </div>
                            )
                        })
                    }
                </div> : null
              }
              
                   {
                data.ninthComp?.points?.length ? <div className="py-12" >
                    <h4 style={{color:"#004171"}} className="text-center mb-4 text-xl md:text-2xl font-semibold" >{data.ninthComp.title}</h4>
                    <h4 style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} className="text-center mb-4" >{data.ninthComp.description}</h4>
                    <div className="grid md:grid-cols-2" >
                    {
                        data.ninthComp?.points?.map((res , index) =>{
                            return( <div className="p-8"> 
                             <div className="flex items-start relative" > 
                            <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                        <p className="" style={{color:"#676C80", fontSize: 18, fontWeight: 400}} >{res.description}</p>
                        </div>

                            </div>
                   
                            )
                        })
                    }
                    </div>
                    <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.ninthComp?.points[0].mainnotes}</h4>
                </div> : null
              }
              </>
    )
}

export default VaricoseVeins