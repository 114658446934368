import React, { useEffect, useRef, useState } from 'react';
// import close from '../../Assets/Images/closeee.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { DatePicker, message } from "antd";
import { ToastContainer, toast } from 'react-toastify';

import { patientaddsocialhistory, getsocialhistory, getPatientfamilymembers as getpatientfamilymemberslist } from "../../../../../Redux/Action/UserprofileActions";
import { Form, Input } from 'antd';

function Addsocialhistory(props) {

    const goBack = () => {
        props.closePopup();
    }

    const [memberList, setMemberList] = useState([]);
    const [FamilymemberName, setFamilymemberName] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    const [givenDate, setGivenDate] = useState("");
    const formRef = useRef();

    const [memberCode, setMemberCode] = useState("");

    const location = useLocation();
    const handleChange = (e) => {
        setaddsocialhistory({
            ...addsocialhistory,
            [e.target.name]: e.target.value,
        });
    };

    const changeDate = (e) => {
        setaddsocialhistory({
            ...addsocialhistory,
            givenDate: moment(e).format("yyyy-MM-DD HH:mm:ss"),
        });
    };
    const handleChangeFamilymem = (e) => {
        setFamilymemberName(e)
    }

    const patientCode = useSelector(state => state.authReducer.patientCode);
    // useEffect(() => {
    //     dispatch(getpatientfamilymemberslist(props.patient ? props.patient : patientCode))
    //         .then((result) => {
    //             console.log(result);
    //             setMemberList(result);
    //         })
    //         .catch((error) => {
    //             // setLoader(false)
    //             // redirectTo();
    //         });
    // }, []);

    const patientdata = localStorage.getItem("patientprofile")
    console.log("patient", patientdata)



    const [addsocialhistory, setaddsocialhistory] = useState({});

    const savesocialhistorys = (e) => {
        e.preventDefault();
        const formValues = formRef.current.getFieldsValue();
        const { description } = formValues;
        console.log('Form values:', formValues);
        if (!givenDate) {
            toast("Please select a date.")
        } else if (!description) {
            toast("Please enter description.")
        } else {
            if (FamilymemberName === "" || FamilymemberName === undefined) {
                // let patient = localStorage.getItem("patientprofile")
                const givenDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
                const data = {
                    "patientId":  props.patCode,
                    "patientCode": props.patCode,
                    "givenDate": givenDate || addsocialhistory?.givenDate,
                    "status": 1,
                    "createdBy": props.patCode,
                    "modifiedBy": props.patCode,
                    // "recordFor": addsocialhistory.recordFor,
                    "description": description || addsocialhistory.description
                    //-- -> P means patient and U means User
                }
                dispatch(patientaddsocialhistory(data)).then((result) => {
                    dispatch(getsocialhistory(props.patCode));
                    props.loadData();
                    formRef.current.resetFields();
                    message.success("Added Successfully");
                    setaddsocialhistory({})
                    props.closePopup()
                }).catch((error) => {
                    message.error("Error occurred");
                })
            }
            else {
                let patient = localStorage.getItem("patientprofile")
                const givenDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
                const data = {
                    "patientId": props.patCode,
                    "patientCode": props.patCode,
                    "givenDate": givenDate,
                    "status": 1,
                    "createdBy": props.patCode,
                    "modifiedBy": props.patCode,
                    // "recordFor": addsocialhistory.recordFor,
                    "description": description || addsocialhistory.description
                    //-- -> P means patient and U means User
                }
                dispatch(patientaddsocialhistory(data)).then((result) => {
                    dispatch(getsocialhistory(props.patCode));
                    props.loadData();
                    formRef.current.resetFields();
                    message.success("Added Successfully");
                    setaddsocialhistory({})
                    props.closePopup()
                }).catch((error) => {
                    message.error("Error occurred");
                })
                // }
            }
        }
    };
    const handleDateChange = (date, dateString) => {
        const Currdate = new Date();
        const hours = String(Currdate.getHours()).padStart(2, '0');
        const minutes = String(Currdate.getMinutes()).padStart(2, '0');
        const seconds = String(Currdate.getSeconds()).padStart(2, '0');
        const constructedDate = dateString + ` ${hours}:${minutes}:${seconds}`;
        console.log(constructedDate)
        const formattedDate = moment(constructedDate).format('YYYY-MM-DD HH:mm:ss');
        console.log(formattedDate);
        setGivenDate(formattedDate);
    };

    const disableFutureDates = (current) => {
        return current && current > moment().endOf('day'); 
      };


    return (
        <>
            <Form ref={formRef} className="CommonForm" layout="vertical" autoComplete="off">
                <Form.Item label="Record on" name="date">
                    <div className="flex ">
                        <DatePicker className="w-full h-[50px]" onChange={handleDateChange}  disabledDate={disableFutureDates}/>
                    </div>
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <Input placeholder="Enter Description" />
                </Form.Item>
                <Form.Item>
                    <button
                        onClick={savesocialhistorys}
                        className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
                        type="primary"
                        htmlType="submit"
                    >
                        Save details
                    </button>
                </Form.Item>
            </Form>
        </>
    );
}

export default Addsocialhistory;
