export const doc = {
    REQUEST_DOCLIST: "REQUEST_DOCLIST",
    SUCCESS_DOCLIST: "SUCCESS_DOCLIST",
    FAILED_DOCLIST: "FAILED_DOCLIST",
 
    REQUEST_PARTICULARDOC: "REQUEST_PARTICULARDOC",
    SUCCESS_PARTICULARDOC: "SUCCESS_PARTICULARDOC",
    FAILED_PARTICULARDOC: "FAILED_PARTICULARDOC",

    REQUEST_DOCSLOTS: "REQUEST_DOCSLOTS",
    SUCCESS_DOCSLOTS: "SUCCESS_DOCSLOTS",
    FAILED_DOCSLOTS: "FAILED_DOCSLOTS",

    REQUEST_DOCSLOTSFORDATETODATE: "REQUEST_DOCSLOTSFORDATETODATE",
    SUCCESS_DOCSLOTSFORDATETODATE: "SUCCESS_DOCSLOTSFORDATETODATE",
    FAILED_DOCSLOTSFORDATETODATE: "FAILED_DOCSLOTSFORDATETODATE",

    REQUEST_DOCSPECIAITYLIST: "REQUEST_DOCSPECIAITYLIST",
    SUCCESS_DOCSPECIAITYLIST: "SUCCESS_DOCSPECIAITYLIST",
    FAILED_DOCSPECIAITYLIST: "FAILED_DOCSPECIAITYLIST",

    REQUEST_DOCAPPOINTMENT: "REQUEST_DOCAPPOINTMENT",
    SUCCESS_DOCAPPOINTMENT: "SUCCESS_DOCAPPOINTMENT",
    FAILED_DOCAPPOINTMENT: "FAILED_DOCAPPOINTMENT",

    REQUEST_DOCAPPOINTMENTLIST: "REQUEST_DOCAPPOINTMENTLIST",
    SUCCESS_DOCAPPOINTMENTLIST: "SUCCESS_DOCAPPOINTMENTLIST",
    FAILED_DOCAPPOINTMENTLIST: "FAILED_DOCAPPOINTMENTLIST",

    REQUEST_NEARBY_SEARCH: "REQUEST_NEARBY_SEARCH",
    SUCCESS_NEARBY_SEARCH: "SUCCESS_NEARBY_SEARCH",
    FAILED_NEARBY_SEARCH: "FAILED_NEARBY_SEARCH",


    REQUEST_POST_CONSULT: "REQUEST_POST_CONSULT",
    SUCCESS_POST_CONSULT: "SUCCESS_POST_CONSULT",
    FAILED_POST_CONSULT: "FAILED_POST_CONSULT",

    REQUEST_REMOVE: 'REQUEST_REMOVE',
    REMOVE_DOCLIST: 'REMOVE_DOCLIST',

    REQUEST_HOSPITALCLINICLIST: "REQUEST_HOSPITALCLINICLIST",
    SUCCESS_HOSPITALCLINICLIST: "SUCCESS_HOSPITALCLINICLIST",
    FAILED_HOSPITALCLINICLIST: "FAILED_HOSPITALCLINICLIST",

    REMOVE_UPDATEAPPOINTMENT: "REMOVE_UPDATEAPPOINTMENT",

    REQUEST_ALL_DOCLIST: "REQUEST_ALL_DOCLIST",
    SUCCESS_ALL_DOCLIST: "SUCCESS_ALL_DOCLIST",
    FAILED_ALL_DOCLIST: "FAILED_ALL_DOCLIST",
    
    ALL_DOC : "ALL_DOC"

}