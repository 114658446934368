import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const Hydrocele = ({data}) =>{
    console.log(data, "sdvkjdsiuvdsbgvdsvds")
    if(!data?.sixthComp?.points?.length){
        return null
    }
    return(
        <>
         {
                data.sixthComp?.points?.length ? <div className="py-12" >
                    <h4 style={{color:"#004171"}} className="text-xl md:text-2xl font-semibold text-center mb-4" >{data.sixthComp.points[0].mainTitle}</h4>
                    <h4 style={{color:"#676C80" ,}} className="text-center mb-4 px-4" >{data.sixthComp.points[0].maindescription}</h4>
                    <div className="grid md:grid-cols-2" >
                    {
                        data.sixthComp?.points[0].sub_points.map((res , index) =>{
                            return( <div className="p-8"> 
                             <div className="flex items-center relative" > 
                            <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                            <p className="text-xl md:text-2xl font-semibold" style={{color:"#005D8D"}} >{res.title}</p>
                        </div>
                                    <p cclassName="ml-10 text-base mt-2" style={{color:"#676C80",}} >{res.description}</p>
                            </div>
                   
                            )
                        })
                    }
                    </div>
                    <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.sixthComp?.points[0].mainnotes}</h4>
                </div> : null
              }
             {
                data.eightComp?.points?.length ? <div className="py-12" >
                    <h4 style={{color:"#004171" }} className="text-xl md:text-2xl font-semibold text-center mb-4" >{data.eightComp.points[0].mainTitle}</h4>
                    <h4 style={{color:"#676C80"}} className="my-4 text-center mb-8" >{data.eightComp.points[0].mainSubTitle}</h4>
                    <div className="grid md:grid-cols-2" >
                    {
                        data.eightComp?.points.map((res , index) =>{
                            return( <div className="p-8"> 
                             <div className="flex items-center relative" > 
                            <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                            <p className="text-xl md:text-2xl font-semibold" style={{color:"#005D8D"}} >{res.title}</p>
                        </div>
                                    <p className="ml-10 text-base mt-2" style={{color:"#676C80",}} >{res.description}</p>
                            </div>
                   
                            )
                        })
                    }
                    </div>
                    <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.eightComp?.points[0].mainnotes}</h4>
                </div> : null
              }
              </>
    )
}

export default Hydrocele