import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const Phimosis = ({data}) =>{

    if(!data?.sixthComp?.points?.length){
        return null
    }
    return(
        <>
        <div>
                <h2 className="text-center my-12" style={{color:"#004171", fontSize: 36, fontWeight: 600}} >{data?.sixthComp?.points[0].mainTitle}</h2>
                <h6 className="my-4 text-center" style={{color:"#676C80", fontWeight: 500, fontSize: 16}} >{data?.sixthComp?.points[0].maindescription}</h6>
            <div className="grid md:grid-cols-2 px-2 md:px-12" >
            {
                data?.sixthComp?.points[0]?.sub_points?.map((res,index) => (
                    <div style={{borderBottom: "dashed"}} >
                        <div className="flex items-start relative" > 
                            <img className="absolute top-8" src= {CheckIcon} height={20} width={20} />
                    <p className="p-8" style={{color:"#676C80", fontSize: 18, fontWeight: 400}} >{res.title}</p>
                        </div>
                    </div>
                ))
            }
                </div>
                <div className="flex justify-center" >
                <img src= {data?.sixthComp?.points[0].icon_url}  />
                </div>
                <h4 className="my-12 text-center" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.sixthComp?.points[0].mainnotes}</h4>
        </div>
              {data?.seventhComp ? (
                       <div style={{backgroundColor: "#EBF8FF"}} className="grid md:grid-cols-2 md:px-16 justify-between items-start my-4 p-8">
                       <div className="text-xl md:text-2xl font-semibold" style={{color: "#004171"}} >
                           {data?.seventhComp?.title}
                       </div>
                       <div  >
                       {data?.seventhComp?.description}
                       <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-8" >
                       {
                           data?.seventhComp?.points.map(res => (
                               <div className="p-4" style={{backgroundColor:"#ffff", borderRadius: 8}}>
                                <img src= {res.icon_url} />
                               <p className="mt-4" style={{color:"#004171", fontSize: 12, fontWeight: 400}} >{res.title}</p>
                               </div>
                           ))
                       }
                       </div>
                           </div>
                   </div>
              ) : (
                <p></p>
              )}
               {
                data.eightComp?.points?.length ? <div className="py-12" >
                    <h4 style={{color:"#004171"}} className="text-center mb-4 text-xl md:text-3xl font-semibold" >{data.eightComp.points[0].mainTitle}</h4>
                    <h4 style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} className="text-center mb-4 text-lg md:text-xl font-semibold px-2" >{data.eightComp.points[0].mainSubTitle}</h4>
                    <div className="grid md:grid-cols-2" >
                    {
                        data.eightComp?.points.map((res , index) =>{
                            return( <div className="p-8"> 
                             <div className="flex items-center relative" > 
                            <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                            <p className="text-xl md:text-2xl font-semibold" style={{color:"#005D8D"}} >{res.title}</p>
                        </div>
                                    <p className="ml-10 text-base mt-2" style={{color:"#676C80",}} >{res.description}</p>
                            </div>
                   
                            )
                        })
                    }
                    </div>
                    <h4 className="my-4 text-center mb-8 px-2" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.eightComp?.points[0].mainnotes}</h4>
                </div> : null
              }
               {data.ninthComp ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#EBF8FF" }}
        >
          <div className="container mx-auto">
            <h2
              className="text-2xl md:text-3xl font-bold mb-8 text-center"
              style={{ color: "rgb(0, 93, 141)" }}
            >
              {data.ninthComp?.title}
            </h2>
            <h6 className=" mb-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}} >{data.ninthComp?.description}</h6>
            <div className="grid md:grid-cols-2 mt-8 gap-4 md:gap-8">
              {data.ninthComp?.points.map(
                (symptom, index) => (
                  <div
                    className="p-4 rounded-lg"
                    key={index}
                    style={{backgroundColor:"#ffff"}}
                  >
                    <h4 style={{color:"#005D8D", fontSize: 20, fontWeight: 600}} >{symptom.title}</h4>
                    <p
                      className="text-sm md:text-base font-medium"
                      style={{ color: "#676C80" }}
                    >
                      {symptom.description}
                    </p>
                  </div>
                  
                )
              )}
            </div>
            <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.ninthComp?.note}</h4>
          </div>
        </div>
      ) : (
        <p>No symptoms data available</p>
      )}
              </>
    )
}

export default Phimosis