import React from "react";
import Layouts from "../Layouts";

function RefundPolicy() {
  return (
    <Layouts>
      <section className="home-banner md:min-h-[60vh] py-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
              RETURN AND REFUND POLICY
              </h1>
              <p className="text-primaryColor md:text-White font-extralight text-sm md:text-lg">
                CureBay provides an easy refund policy, allowing returns within
                a specified period and quick refunds through the original
                payment method or another choice. Customers have access to clear
                support for refund inquiries.
              </p>
            </div>
            <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%] md:h-[400px] rounded ">
                <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Return%20%26%20Refund%20Policy.webp" alt="images" className="w-full h-full object-cover image-boxshadow rounded-xl" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto py-[50px] md:py-[50px]">
          <p className="text-textGray pb-3">
            Our Policy for the return and refund shall be as follows:
          </p>

          <ul className="list-decimal pl-5 mb-5">
            <li className="text-textGray">
              We strive to deliver correct medicines/items in the
              right/undamaged condition every time the customer places an order.
              We strongly recommend that you check the condition of the
              delivered products at the time of delivery and get back to us if
              there are any discrepancy.
            </li>
            <li className="text-textGray">
              If your order is eligible for a return, you can raise a return
              request within 48 hours from the time of delivery subject to
              review and verification by CureBay team. No return of damaged
              products is possible once part or whole of the product has been
              used.
            </li>
            <li className="text-textGray">
              To raise the return request and return an item, you can call our
              help desk at customer support numbers and email ids mentioned in
              the website.
            </li>
            <li className="text-textGray">
              Items are eligible for a return exclusively under the following
              circumstances:
              <ul className=" list-disc pl-5">
                <li className="text-textGray">
                  Products are delayed beyond 48 hours of the estimated date of
                  delivery communicated to the customer at the time of placement
                  of the order.
                </li>
                <li className="text-textGray">
                  Product(s) delivered do not match the order: This would
                  include items that are different from what the customer
                  ordered.
                </li>
                <li className="text-textGray">
                  Product(s) were delivered in damaged/non-working condition.
                </li>
                <li className="text-textGray">
                  Product(s) have missing parts or accessories or different from
                  their description on the product page.
                </li>
                <li className="text-textGray">
                  Product(s) are returned in original packaging i.e. with
                  labels, barcode, price tags, original serial no. etc.
                </li>
                <li className="text-textGray">
                  Batch number of the product(s) being returned matches with the
                  one(s) mentioned in the invoice.
                </li>
                <li className="text-textGray">
                  Product(s)/medicines(s)/bottle(s) are unused. Opened medicine
                  strip(s)/bottle(s) are not eligible for returns.
                </li>
              </ul>
            </li>
            <li className="text-textGray">
              Refunds and timelines:
              <ul className="list-disc pl-5 ">
                <li className="text-textGray">
                  Refunds for all eligible returns or cancellations are issued
                  through the payment method used at the time of purchase,
                  except for cash payments made under the Pay on Delivery mode
                  of payment.
                </li>
                <li className="text-textGray">
                  Refunds may be processed within 15 working days from the
                  receipt of a request from you.
                </li>
                <li className="text-textGray">
                  The time frame for different payment modes is typically 5-7
                  business days post the return has been received and verified
                  by CureBay
                </li>
                <li className="text-textGray">
                  Refund timelines depend on bank turnaround times and RBI
                  guidelines. This may change from time to time. Business days
                  shall mean the working days on which CureBay corporate office
                  operates.
                </li>
                <li className="text-textGray">
                  For orders placed using Pay on Delivery as the payment method,
                  refunds can be processed to your bank account via National
                  Electronic Funds Transfer (NEFT). There will be no cash
                  refund.
                </li>
                <li className="text-textGray">
                To receive refunds in NEFT form, you will need to update the following information to enable
us to process a refund to your account:
                </li>
                <li className="text-textGray">The Bank Account Number</li>
                <li className="text-textGray">IFSC Code</li>
                <li className="text-textGray">Account Holder's Name</li>
              </ul>
            </li>
          </ul>
          <h5 className=" text-primaryColor text-lg font-semibold mb-3">
            Cancellation and Refund Policy
          </h5>

          <p className="text-textGray pb-3">
          Our Policy for the cancellation and refund shall be as follows:
          </p>
          <ul className="list-decimal pl-5 mb-5">
            <li className="text-textGray">
            Customers can cancel orders/services already booked and paid prior to 48 hours of the
expected delivery of goods of services. In such a case the customer can get full refund
unless cancellation charges are deducted by the Healthcare Provider (applicable in case of
healthcare services).
            </li>
            <li className="text-textGray">
            In case where the user, does not show up for the appointment booked with a Healthcare
Provider, without cancelling the appointment beforehand, there will not be any refunds.
            </li>
           
            <p className="text-textGray mt-3">If you have any questions about our Return and Refund Policy or your expected refunds, please
contact us at our customer support numbers/email mentioned in the website.</p>
          </ul>

          {/* <h5 className=" text-primaryColor text-lg font-semibold mb-3">
            Shipping Policy
          </h5>
          <ul className="list-disc pl-5 mb-5">
            <li className="text-textGray">
              The shipping of goods will be limited to Pharmacy products. The
              timelines of the shipping will be as per timelines mentioned
              during time of placing the order. The expected timelines will be 4
              hours to 96 hours depending on the location of delivery.
            </li>
          </ul>

          <h5 className=" text-primaryColor text-lg font-semibold mb-3">
            Pricing Notes
          </h5>

          <ul className="list-disc pl-5 mb-5">
            <li className="text-textGray">
              The Pricing for every individual service will be available in the
              ecommerce portal. The prices will vary for service to service and
              transaction to transaction.
            </li>
          </ul> */}
        </div>
      </section>
    </Layouts>
  );
}

export default RefundPolicy;
