import React, {useState, useEffect} from 'react'
import http from '../../../Redux/Services/http-common'

const LabTestList = () =>{
    const [labtestList, setLabTestList] = useState([])
    useEffect(() => {
        fetchLabTestList();
      }, []);

      const fetchLabTestList = () =>{
        http.get(`${process.env.REACT_APP_BASEURL}PatientLabTestsOrderDetails/mostCommonLabtest?labType=Pathology`)
        .then(res =>{

        })
      }

    return(
        <></>
    )
}

export default LabTestList