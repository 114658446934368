import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const Lasik = ({data}) =>{
    console.log(data, "sdvkjdsiuvdsbgvdsvds")
    if(!data?.sixthComp?.points?.length){
        return null
    }
    return(
        <>
         {
                data.sixthComp?.points?.length ? <div style={{backgroundColor:"#EBF8FF"}} >
                    {
                        data.sixthComp?.points.map(res =>{
                            return(
                                <div className="grid md:grid-cols-2 py-4 md:py-8 px-4 md:px-8" style={{borderBottom: "dashed"}} >
                                    <div> <p className="text-xl md:text-2xl font-semibold p-8" style={{color:"#004171"}} >{res.title}</p> </div>
                                    <div>
                                    {res.description && <p className="p-8 text-md font-medium" style={{color:"#004171"}} >{res.description}</p>}
                    <ul className="ml-6 md:ml-12" style={{listStyleType: "disc",color:"#004171"}} >
                          {res?.sub_points?.map(res =>{
                            return <li className="mb-2" ><p className="text-md font-medium" style={{color:"#004171"}}>{res.description}</p></li>
                          })}
                        </ul>
                         </div>
                   
                    </div>
                            )
                        })
                    }
                </div> : null
              }
               {
                data.eightComp?.points?.length ? <div className="py-12" >
                    <h4 style={{color:"#004171" }} className="text-center mb-4 text-xl md:text-2xl font-semibold" >{data.eightComp.points[0].mainTitle}</h4>
                    <h4 style={{color:"#676C80"}} className="text-center mb-4 px-4 md:px-8" >{data.eightComp.points[0].mainSubTitle}</h4>
                    <div className="" >
                    {
                        data.eightComp?.points.map((res , index) =>{
                            return(
                                <div>
                                {<p style={{color:"#004171"}} className="text-center mb-4 text-xl md:text-2xl font-semibold" >{res.title}</p>}
                                <div className="grid md:grid-cols-2">
                                {res.sub_points.map((res , index) => {
                                return( <div className="p-8"> 
                                    <div className="flex items-center relative" > 
                                   <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                                   <p className="text-xl md:text-2xl font-semibold" style={{color:"#005D8D"}} >{res.title}</p>
                               </div>
                                           <p className="ml-10 text-base" style={{color:"#676C80"}} >{res.description}</p>
                                   </div>
                          
                                   )
                            })}
                            </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.eightComp?.points[0].mainnotes}</h4>
                </div> : null
              }
              </>
    )
}

export default Lasik