import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { DatePicker, message } from "antd";
import { patientaddmedicalhistory } from "../../../../../Redux/Action/UserprofileActions";
import { getmedicalhistory } from '../../../../../Redux/Action/UserprofileActions';
import { getPatientfamilymembers as getpatientfamilymemberslist } from "../../../../../Redux/Action/UserprofileActions";
import { Form, Input } from 'antd';

function AddMedicalHistory(props) {
    const goBack = () => {
        props.closePopup();
    }
    const dispatch = useDispatch();

    const [memberCode, setMemberCode] = useState("");
    const [addsurgicalhistory, setaddsurgicalhistory] = useState({});
    const [memberList, setMemberList] = useState([]);
    const [FamilymemberName, setFamilymemberName] = useState("");
    const formRef = useRef();
    const [givenDate, setGivenDate] = useState("")

    const handleChange = (e) => {
        setaddsurgicalhistory({
            ...addsurgicalhistory,
            [e.target.name]: e.target.value,
        });
    };

    const changeDate = (e) => {
        setaddsurgicalhistory({
            ...addsurgicalhistory,
            givenDate: moment(e).format("yyyy-MM-DD HH:mm:ss"),
        });
    };
    
    const handleDateChange = (date, dateString) => {
        const Currdate = new Date();
        const hours = String(Currdate.getHours()).padStart(2, '0');
        const minutes = String(Currdate.getMinutes()).padStart(2, '0');
        const seconds = String(Currdate.getSeconds()).padStart(2, '0');
        const constructedDate = dateString + ` ${hours}:${minutes}:${seconds}`;
        const formattedDate = moment(constructedDate).format('YYYY-MM-DD HH:mm:ss');
        setGivenDate(formattedDate);
    };

    const patientCode = useSelector(state => state.authReducer.patientCode);

    const savesurgicalhistorys = (e) => {
        e.preventDefault();
        formRef.current.validateFields()
            .then(() => {
                let data;
                if (FamilymemberName === "" || FamilymemberName === undefined) {
                    data = {
                        "createdBy": props.patCode,
                        "description": addsurgicalhistory.description,
                        "fromDate": "",
                        "givenDate": givenDate || addsurgicalhistory.givenDate,
                        "modifiedBy": props.patCode,
                        "modifiedDate": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        "patientBloodGroup": "",
                        "patientDob": "",
                        "patientEmail": "",
                        "patientId": props.patCode,
                        "patientName": "",
                        "status": 1,
                        "toDate": ""
                    };
                } else {
                    data = {
                        "createdBy": props.patCode,
                        "description": addsurgicalhistory.description,
                        "fromDate": "",
                        "givenDate": givenDate || addsurgicalhistory.givenDate,
                        "modifiedBy": props.patCode,
                        "modifiedDate": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        "patientBloodGroup": "",
                        "patientDob": "",
                        "patientEmail": "",
                        "patientId": FamilymemberName,
                        "patientName": "",
                        "status": 1,
                        "toDate": ""
                    };
                }
                dispatch(patientaddmedicalhistory(data)).then((result) => {
                    dispatch(getmedicalhistory(props.patCode));
                    props.loadData();
                    formRef.current.resetFields();
                    message.success("Added Successfully");
                    setaddsurgicalhistory({});
                    props.closePopup();
                });
            })
            .catch(error => {
                message.error("Please fill in the required fields.");
            });
    };

    const disableFutureDates = (current) => {
        return current && current > moment().endOf('day'); // Disables dates after today
    };


    return (
        <>
            {/* <ToastContainer /> */}
            <Form ref={formRef} className="CommonForm" layout="vertical" autoComplete="off">
                <Form.Item
                    label="Record on"
                    name="date"
                    rules={[{ required: true, message: "Please select a date." }]} // Validation rule for the date
                >
                    <DatePicker
                        className="w-full h-[50px]"
                        onChange={handleDateChange}
                        disabledDate={disableFutureDates}
                    />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true, message: "Please enter a description." }]} // Validation rule for the description
                >
                    <Input placeholder="Enter Description" />
                </Form.Item>

                <Form.Item>
                    <button
                        onClick={savesurgicalhistorys}
                        className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center"
                        type="primary"
                        htmlType="submit"
                    >
                        Save details
                    </button>
                </Form.Item>
            </Form>
        </>
    );
}

export default AddMedicalHistory;
