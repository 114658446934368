// // import React, { useState, useEffect } from "react";
// // import { useHistory } from "react-router-dom";
// // import { useDispatch, useSelector } from "react-redux";
// // import Layouts from "../../../Layouts";
// // import {
// //   Col,
// //   DatePicker,
// //   Divider,
// //   Form,
// //   Input,
// //   Modal,
// //   Row,
// //   Select,
// // } from "antd";
// // import plus from "../../../../Assets/Images/plus.png";
// // import calender from "../../../../Assets/Images/calender.png";
// // // import { useHistory } from "react-router-dom/cjs/react-router-dom";
// // import {
// //   getmedicalhistory,
// //   getPatientallergylist,
// //   getPatientmedicationlist,
// //   getsurgicalhistory,
// //   getfamilyhistory,
// //   getsocialhistory,
// //   getchronicconditions,
// //   getpatientchronicconditions,
// // } from "../../../../Redux/Action/UserprofileActions";
// // import { USERPROFILE_ROUTES } from "../../../PageRouting/UserProfileRoutes";
// // import userprofileservice from "../../../../Redux/Services/userprofileservice";
// // import moment from "moment";

// // function MedicalHistory() {
// //   const dispatch = useDispatch();
// //   const history = useHistory();
// //   const userData = useSelector((state) => state.authReducer.patientData);
// //   const patientallergylist = useSelector((state) => state.allergylist);
// //   const { allergyData } = patientallergylist;
// //   const [check, setCheck] = useState(true);
// //   const medicationlist = useSelector((state) => state.allmedicationlist);
// //   const { medicationData } = medicationlist;
// //   const medicalhitorylist = useSelector((state) => state.allmedicalhistorylist);
// //   const { medicalhistoryData } = medicalhitorylist;
// //   const surgicalhistorylist = useSelector(
// //     (state) => state.allsurgicalhistorylist
// //   );
// //   const { surgicalhistoryDataList } = surgicalhistorylist;
// //   const familyhistorylist = useSelector((state) => state.allfamilyHistoryList);
// //   const { familyhistoryDataList } = familyhistorylist;
// //   const socialhistoryData = useSelector((state) => state.allsocialHistoryList);
// //   const { socialhistoryDataList } = socialhistoryData;
// //   const chronicconditionsData = useSelector(
// //     (state) => state.allchronicConditionsList
// //   );
// //   const { chronicconditionsDataList } = chronicconditionsData;
// //   const [saveChronicConditionsLoading, setsaveChronicConditionsLoading] =
// //     useState(false);
// //   const [activePatient, setActivePatient] = useState();
// //   const [selectedChronicConditions, setSelectedChronicConditions] = useState(
// //     []
// //   );
// //   const [selectedChronics, setSelectedChronics] = useState([]);

// //   const [isModalOpen, setIsModalOpen] = useState(false);

// //   useEffect(() => {
// //     getMedicalHistoryFunc();
// //   }, []);
// //   const getMedicalHistoryFunc = () => {
// //     let patient = userData?.code;
// //     dispatch(getPatientallergylist(patient));
// //     dispatch(getPatientmedicationlist(patient));
// //     dispatch(getmedicalhistory(patient));
// //     dispatch(getsurgicalhistory(patient));
// //     dispatch(getfamilyhistory(patient));
// //     dispatch(getsocialhistory(patient));
// //     dispatch(getchronicconditions());
// //     loadPatientChronicConditions(patient);
// //   };
// //   const loadPatientChronicConditions = (patient) => {
// //     userprofileservice.getpatientchronicconditionslist(patient).then(
// //       (res) => {
// //         if (!res.message) {
// //           console.log(res.data);
// //           let array = [];
// //           res.data.forEach((element) => {
// //             if (element.status == 1) {
// //               array.push(element);
// //             }
// //           });
// //           setSelectedChronicConditions([...array]);
// //           console.log(selectedChronicConditions);
// //         }
// //       },
// //       (err) => {
// //         console.log(err);
// //       }
// //     );
// //   };
// //   const showModal = () => {
// //     setIsModalOpen(true);
// //   };
// //   const handleOk = () => {
// //     setIsModalOpen(false);
// //   };
// //   const handleCancel = () => {
// //     setIsModalOpen(false);
// //   };
// //   return (
// //     <Layouts>
// //       <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
// //         <div className="w-[80%] mx-auto ">
// //           <div className="w-full bg-primaryColor rounded-md px-8 py-5">
// //             <div className=" flex justify-between items-center ">
// //               <div className="font-medium md:text-2xl text-White">
// //                 Medical History
// //               </div>
// //               <div>
// //                 <Select
// //                   placeholder="Get prescription for"
// //                   className="h-12 w-96 text-base"
// //                 >
// //                   <option value="medication">Medication</option>
// //                   <option value="condition">Condition</option>
// //                   <option value="prescriptionType">Prescription Type</option>
// //                 </Select>
// //               </div>
// //             </div>
// //           </div>
// //           <Row gutter={24}>
// //             {allergyData.map((user, i) => (
// //               <Col span={8}>
// //                 <div className="mt-5 bg-White rounded-md common-shadow h-72">
// //                   <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
// //                     <h3 className="text-lg text-secondaryColor font-medium">
// //                       Allergy History
// //                     </h3>
// //                     <div
// //                       onClick={showModal}
// //                       className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
// //                     >
// //                       <img
// //                         src={plus}
// //                         alt="plus"
// //                         className="w-3 h-3 cursor-pointer"
// //                       />
// //                     </div>
// //                   </div>
// //                   <div className="h-36 overflow-scroll scrollWidth p-6">
// //                     <div className="grid grid-cols-2 gap-5 w-full">
// //                       <div className="text-base text-textGray">
// //                         <label className=" font-light">Drug Allergy</label>
// //                         <p className="font-semibold">{user.drugAllergy}</p>
// //                       </div>
// //                       <div className="text-base text-textGray">
// //                         <label className=" font-ligh">Food Allergy</label>
// //                         <p className="font-semibold">{user.foodAllergy}</p>
// //                       </div>
// //                       <div className="text-base text-textGray">
// //                         <label className=" font-ligh">Chemical Allergy</label>
// //                         <p className="font-semibold">{user.chemicalAllergy}</p>
// //                       </div>
// //                     </div>
// //                   </div>
// //                   <div className="w-full border text-lightBlue my-3"></div>
// //                   <div className="flex justify-between gap-4 items-center px-6">
// //                     <h3 className="text-primaryColor text-sm font-medium">
// //                       Check Up Date
// //                     </h3>
// //                     <div className="text-primaryColor flex gap-4 text-base h-10 items-center  px-4 rounded-full bg-lightBlue">
// //                       <img src={calender} alt="image" />
// //                       <span>{moment(user.givenDate).format("MM/DD/YYYY")}</span>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </Col>
// //             ))}
// //             {medicalhistoryData.map((user, i) => (
// //               <Col span={8}>
// //                 <div className="mt-5 bg-White rounded-md common-shadow h-72">
// //                   <div className="flex justify-between h-14 bg-[#D9F1E7] rounded-md">
// //                     <h3 className="text-lg text-[#42BA85] font-sans mt-3 ml-8">
// //                       Medical History
// //                     </h3>
// //                     {/* <span
// //                     className="mt-4 ml-[8rem] text-base underline cursor-pointer"
// //                     onClick={handleClick1}
// //                   >
// //                     See all
// //                   </span> */}
// //                     <span className="w-8 h-8 mt-3 rounded-full bg-White mr-5 flex justify-center items-center p-2">
// //                       <img
// //                         // onClick={medicalhistory}
// //                         src={plus}
// //                         alt="plus"
// //                         className="w-3 h-3 cursor-pointer"
// //                       />
// //                     </span>
// //                   </div>
// //                   <div className="h-36 overflow-scroll scrollWidth">
// //                     <div className="mt-4 ml-5">
// //                       <h3 className="text-lg">{user.description}</h3>
// //                     </div>
// //                   </div>

// //                   <div className="w-full border text-lightBlue text-[1px] mt-3"></div>
// //                   <div className="h-16">
// //                     <div className="flex gap-4 items-center m-2 ">
// //                       <h3 className="text-primaryColor text-lg p-3 ml-3  font-medium">
// //                         Check Up Date
// //                       </h3>
// //                       <span className="text-primaryColor flex gap-4 text-base h-10 items-center  p-2 rounded-full w-56 bg-lightBlue">
// //                         <span>
// //                           <img src={calender} alt="image" className="ml-3" />
// //                         </span>
// //                         <span>
// //                           {moment(user.givenDate).format("MM/DD/YYYY")}
// //                         </span>
// //                       </span>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </Col>
// //             ))}
// //             {surgicalhistoryDataList.map((user, i) => (
// //               <Col span={8}>
// //                 <div className="mt-5 bg-White rounded-md common-shadow h-72">
// //                   <div className="flex justify-between h-14 bg-[#D9F1E7] rounded-md">
// //                     <h3 className="text-lg text-[#42BA85] font-sans mt-3 ml-8">
// //                       Surgical History
// //                     </h3>
// //                     {/* <span
// //                     className="mt-4 ml-[8rem] text-base underline cursor-pointer"
// //                     onClick={handleClick2}
// //                   >
// //                     See all
// //                   </span> */}
// //                     <span className="w-8 h-8 mt-3 rounded-full bg-White mr-5 flex justify-center items-center p-2">
// //                       <img
// //                         src={plus}
// //                         alt="plus"
// //                         className="w-3 h-3 cursor-pointer"
// //                       />
// //                     </span>
// //                   </div>
// //                   <div className="h-36 overflow-scroll scrollWidth">
// //                     <div className="mt-4 ml-5">
// //                       <h3 className="text-lg">{user.description}</h3>
// //                     </div>
// //                   </div>

// //                   <div className="w-full border text-lightBlue text-[1px] mt-3"></div>
// //                   <div className="h-16">
// //                     <div className="flex gap-4 items-center m-2 ">
// //                       <h3 className="text-primaryColor text-lg p-3 ml-3  font-medium">
// //                         Check Up Date
// //                       </h3>
// //                       <span className="text-primaryColor flex gap-4 text-base h-10 items-center  p-2 rounded-full w-56 bg-lightBlue">
// //                         <span>
// //                           <img src={calender} alt="image" className="ml-3" />
// //                         </span>
// //                         <span>
// //                           {moment(user.givenDate).format("MM/DD/YYYY")}
// //                         </span>
// //                       </span>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </Col>
// //             ))}
// //             {familyhistoryDataList.map((user, i) => (
// //               <Col span={8}>
// //                 <div className="mt-5 bg-White rounded-md common-shadow h-72">
// //                   <div className="flex justify-between h-14 bg-[#D9F1E7] rounded-md">
// //                     <h3 className="text-lg text-[#42BA85] font-sans mt-3 ml-8">
// //                       Family History
// //                     </h3>
// //                     {/* <span
// //                     className="mt-4 ml-[8rem] text-base underline cursor-pointer"
// //                     onClick={handleClick3}
// //                   >
// //                     See all
// //                   </span> */}
// //                     <span className="w-8 h-8 mt-3 rounded-full bg-White mr-5 flex justify-center items-center p-2">
// //                       <img
// //                         src={plus}
// //                         alt="plus"
// //                         className="w-3 h-3 cursor-pointer"
// //                       />
// //                     </span>
// //                   </div>
// //                   <div className="h-36 overflow-scroll scrollWidth">
// //                     <div className="mt-4 ml-5">
// //                       <h3 className="text-lg">{user.description}</h3>
// //                     </div>
// //                   </div>
// //                   <div className="w-full border text-lightBlue text-[1px] mt-3"></div>
// //                   <div className="h-16">
// //                     <div className="flex gap-4 items-center m-2 ">
// //                       <h3 className="text-primaryColor text-lg p-3 ml-3  font-medium">
// //                         Check Up Date
// //                       </h3>
// //                       <span className="text-primaryColor flex gap-4 text-base h-10 items-center  p-2 rounded-full w-56 bg-lightBlue">
// //                         <span>
// //                           <img src={calender} alt="image" className="ml-3" />
// //                         </span>
// //                         <span>
// //                           {moment(user.givenDate).format("MM/DD/YYYY")}
// //                         </span>
// //                       </span>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </Col>
// //             ))}
// //             {socialhistoryDataList.map((user, i) => (
// //               <Col span={8}>
// //                 <div className="mt-5 bg-White rounded-md common-shadow h-72">
// //                   <div className="flex justify-between h-14 bg-[#D9F1E7] rounded-md">
// //                     <h3 className="text-lg text-[#42BA85] font-sans mt-3 ml-8">
// //                       Social History
// //                     </h3>
// //                     {/* <span
// //                     className="mt-4 ml-[8rem] text-base underline cursor-pointer"
// //                     onClick={handleClick4}
// //                   >
// //                     See all
// //                   </span> */}
// //                     <span className="w-8 h-8 mt-3 rounded-full bg-White mr-5 flex justify-center items-center p-2">
// //                       <img
// //                         src={plus}
// //                         alt="plus"
// //                         className="w-3 h-3 cursor-pointer"
// //                       />
// //                     </span>
// //                   </div>
// //                   <div className="h-36 overflow-scroll scrollWidth">
// //                     <div className="mt-4 ml-5">
// //                       <h3 className="text-lg">{user.description}</h3>
// //                     </div>
// //                   </div>
// //                   <div className="w-full border text-lightBlue text-[1px] mt-3"></div>
// //                   <div className="h-16">
// //                     <div className="flex gap-4 items-center m-2 ">
// //                       <h3 className="text-primaryColor text-lg p-3 ml-3  font-medium">
// //                         Check Up Date
// //                       </h3>
// //                       <span className="text-primaryColor flex gap-4 text-base h-10 items-center  p-2 rounded-full w-56 bg-lightBlue">
// //                         <span>
// //                           <img src={calender} alt="image" className="ml-3" />
// //                         </span>
// //                         <span>
// //                           {moment(user.givenDate).format("MM/DD/YYYY")}
// //                         </span>
// //                       </span>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </Col>
// //             ))}
// //             {medicationData.map((user, i) => (
// //               <Col span={8}>
// //                 <div className="mt-5 bg-White rounded-md common-shadow">
// //                   <div className="flex justify-between h-14 bg-[#D9F1E7] rounded-md">
// //                     <h3 className="text-lg text-[#42BA85] font-sans mt-3 ml-8">
// //                       Medication
// //                     </h3>
// //                     {/* <span
// //                     className="mt-4 ml-[8rem] text-base underline cursor-pointer"
// //                     onClick={handleClick5}
// //                   >
// //                     See all
// //                   </span> */}
// //                     <span className="w-8 h-8 mt-3 rounded-full bg-White mr-5 flex justify-center items-center p-2">
// //                       <img
// //                         src={plus}
// //                         alt="plus"
// //                         className="w-3 h-3 cursor-pointer"
// //                       />
// //                     </span>
// //                   </div>
// //                   <div className="h-36 overflow-scroll scrollWidth">
// //                     <div className="mt-4 ml-5">
// //                       <h3 className="text-lg">{user.drugName}</h3>
// //                       <span className="text-lg font-bold">Dosage Level:</span>
// //                       <span className="text-lg">
// //                         {user.dosage ? user.dosage : `Morning & Noon`}
// //                       </span>
// //                     </div>
// //                   </div>
// //                   <div className="w-full border text-lightBlue text-[1px] mt-3"></div>
// //                   <div className="h-16">
// //                     <div className="flex gap-4 items-center m-2 ">
// //                       <h3 className="text-primaryColor text-lg p-3 ml-3  font-medium">
// //                         Check Up Date
// //                       </h3>
// //                       <span className="text-primaryColor flex gap-4 text-base h-10 items-center  p-2 rounded-full w-56 bg-lightBlue">
// //                         <span>
// //                           <img src={calender} alt="image" className="ml-3" />
// //                         </span>
// //                         <span>
// //                           {moment(user.startCreatedTime).format("MM/DD/YYYY")}
// //                         </span>
// //                       </span>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </Col>
// //             ))}
// //             {selectedChronicConditions.map((user, i) => (
// //               <Col span={8}>
// //                 <div className="mt-5 bg-White rounded-md common-shadow">
// //                   <div className="flex justify-between h-14 bg-[#D9F1E7] rounded-md">
// //                     <h3 className="text-lg text-[#42BA85] font-sans mt-3 ml-8">
// //                       Chronic Conditions
// //                     </h3>
// //                     {/* <span className="mt-4 ml-[8rem] text-base underline cursor-pointer">
// //                     See all
// //                   </span> */}
// //                     <span className="w-8 h-8 mt-3 rounded-full bg-White mr-5 flex justify-center items-center p-2">
// //                       <img
// //                         src={plus}
// //                         alt="plus"
// //                         className="w-3 h-3 cursor-pointer"
// //                       />
// //                     </span>
// //                   </div>
// //                   <div className="h-36 overflow-scroll scrollWidth">
// //                     <div className="mt-4 ml-5">
// //                       <h3 className="text-lg">COPD</h3>
// //                     </div>
// //                   </div>
// //                   <div className="w-full border text-lightBlue text-[1px] mt-3"></div>
// //                   <div className="h-16">
// //                     <div className="flex gap-4 items-center m-2 ">
// //                       <h3 className="text-primaryColor text-lg p-3 ml-3  font-medium">
// //                         Check Up Date
// //                       </h3>
// //                       <span className="text-primaryColor flex gap-4 text-base h-10 items-center  p-2 rounded-full w-56 bg-lightBlue">
// //                         <span>
// //                           <img src={calender} alt="image" className="ml-3" />
// //                         </span>
// //                         <span>22nd February’2024</span>
// //                       </span>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </Col>
// //             ))}
// //           </Row>
// //         </div>
// //       </section>
// //       <Modal
// //         title={
// //           <p className="font-semibold text-primaryColor text-2xl">
// //             Add Allergy Details
// //           </p>
// //         }
// //         open={isModalOpen}
// //         onOk={handleOk}
// //         onCancel={handleCancel}
// //         footer={[]}
// //       >
// //         <Form className="CommonForm" layout="vertical" autoComplete="off">
// //           <Form.Item label="Record on" name="date">
// //             <DatePicker
// //               style={{ width: "100%", height: "50px" }}
// //               placeholder="Select date"
// //             />
// //           </Form.Item>
// //           <Form.Item label="Drug Allergies" name="email">
// //             <Input placeholder="Enter Drug Allergies" />
// //           </Form.Item>

// //           <Form.Item label="Food Allergies" name="phone">
// //             <Input placeholder="Enter Food Allergies " />
// //           </Form.Item>
// //           <Form.Item label="Chemical Allergies" name="subject">
// //             <Input placeholder="Enter Chemical Allergies" />
// //           </Form.Item>

// //           <Form.Item>
// //             <button
// //               onClick={handleCancel}
// //               className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
// //               type="primary"
// //               htmlType="submit"
// //             >
// //               Save details
// //             </button>
// //           </Form.Item>
// //         </Form>
// //       </Modal>
// //     </Layouts>
// //   );
// // }

// // export default MedicalHistory;

// import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import Layouts from "../../../Layouts";
// import {
//   Col,
//   DatePicker,
//   Divider,
//   Form,
//   Input,
//   Modal,
//   Row,
//   Select,
// } from "antd";
// import plus from "../../../../Assets/Images/plus.png";
// import calender from "../../../../Assets/Images/calender.png";

// import {
//   getmedicalhistory,
//   getPatientallergylist,
//   getPatientmedicationlist,
//   getsurgicalhistory,
//   getfamilyhistory,
//   getsocialhistory,
//   getchronicconditions,
//   getpatientchronicconditions,
// } from "../../../../Redux/Action/UserprofileActions";
// import { USERPROFILE_ROUTES } from "../../../PageRouting/UserProfileRoutes";
// import userprofileservice from "../../../../Redux/Services/userprofileservice";
// import moment from "moment";

// function MedicalHistory() {
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const userData = useSelector((state) => state.authReducer.patientData);
//   const patientallergylist = useSelector((state) => state.allergylist);
//   const { allergyData } = patientallergylist;
//   const [check, setCheck] = useState(true);
//   const medicationlist = useSelector((state) => state.allmedicationlist);
//   const { medicationData } = medicationlist;
//   const medicalhitorylist = useSelector((state) => state.allmedicalhistorylist);
//   const { medicalhistoryData } = medicalhitorylist;
//   const surgicalhistorylist = useSelector(
//     (state) => state.allsurgicalhistorylist
//   );
//   const { surgicalhistoryDataList } = surgicalhistorylist;
//   const familyhistorylist = useSelector((state) => state.allfamilyHistoryList);
//   const { familyhistoryDataList } = familyhistorylist;
//   const socialhistoryData = useSelector((state) => state.allsocialHistoryList);
//   const { socialhistoryDataList } = socialhistoryData;
//   const chronicconditionsData = useSelector(
//     (state) => state.allchronicConditionsList
//   );
//   const { chronicconditionsDataList } = chronicconditionsData;
//   const [saveChronicConditionsLoading, setsaveChronicConditionsLoading] =
//     useState(false);
//   const [activePatient, setActivePatient] = useState();
//   const [selectedChronicConditions, setSelectedChronicConditions] = useState(
//     []
//   );
//   const [selectedChronics, setSelectedChronics] = useState([]);

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     getMedicalHistoryFunc();
//   }, []);
//   const getMedicalHistoryFunc = () => {
//     let patient = userData?.code;
//     dispatch(getPatientallergylist(patient));
//     dispatch(getPatientmedicationlist(patient));
//     dispatch(getmedicalhistory(patient));
//     dispatch(getsurgicalhistory(patient));
//     dispatch(getfamilyhistory(patient));
//     dispatch(getsocialhistory(patient));
//     dispatch(getchronicconditions());
//     loadPatientChronicConditions(patient);
//   };
//   const loadPatientChronicConditions = (patient) => {
//     userprofileservice.getpatientchronicconditionslist(patient).then(
//       (res) => {
//         if (!res.message) {
//           console.log(res.data);
//           let array = [];
//           res.data.forEach((element) => {
//             if (element.status == 1) {
//               array.push(element);
//             }
//           });
//           setSelectedChronicConditions([...array]);
//           console.log(selectedChronicConditions);
//         }
//       },
//       (err) => {
//         console.log(err);
//       }
//     );
//   };
//   const showModal = () => {
//     setIsModalOpen(true);
//   };
//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };
//   return (
//     <Layouts>
//       <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
//         <div className="md:w-[80%] w-[90%] mx-auto ">
//           <div className="md:w-full bg-primaryColor rounded-md md:px-8 px-6 py-3 md:py-5">
//             <div className=" flex justify-between items-center ">
//               <div className="font-medium  md:text-2xl text-lg text-White">
//                 Medical History
//               </div>
//               <div className="hidden md:block">
//                 <Select
//                   placeholder="Get prescription for"
//                   className="md:h-12 md:w-96 w-70 text-base"
//                 >
//                   <option value="medication">Medication</option>
//                   <option value="condition">Condition</option>
//                   <option value="prescriptionType">Prescription Type</option>
//                 </Select>
//               </div>
//             </div>
//           </div>
//           <div className="block md:hidden">
//             <Select
//               placeholder="Get prescription for"
//               className="mt-3 h-12 w-full border border-gray rounded-md"
//             >
//               <option value="medication">Medication</option>
//               <option value="condition">Condition</option>
//               <option value="prescriptionType">Prescription Type</option>
//             </Select>
//           </div>
//           <Row gutter={24}>
//             <Col xs={24} sm={12} md={8}>
//               <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow ">
//                 <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
//                   <h3 className="text-base md:text-lg text-secondaryColor font-medium">
//                     Allergy History
//                   </h3>
//                   <div
//                     onClick={showModal}
//                     className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
//                   >
//                     <img
//                       src={plus}
//                       alt="plus"
//                       className="w-3 h-3 cursor-pointer"
//                     />
//                   </div>
//                 </div>
//                 <div className="h-56 overflow-auto scrollWidth p-5">
//                   {allergyData.map((user, i) => (
//                     <div>
//                       <div className="grid grid-cols-2 gap-3 w-full">
//                         <div className="text-sm text-textGray">
//                           <label className=" font-light">Drug Allergy</label>
//                           <p className="font-semibold">{user.drugAllergy}</p>
//                         </div>
//                         <div className="text-sm text-textGray">
//                           <label className=" font-ligh">Food Allergy</label>
//                           <p className="font-semibold">{user.foodAllergy}</p>
//                         </div>
//                         <div className="text-sm text-textGray">
//                           <label className=" font-ligh">Chemical Allergy</label>
//                           <p className="font-semibold">
//                             {user.chemicalAllergy}
//                           </p>
//                         </div>
//                       </div>
//                       <div className="flex w-full items-center gap-3 mt-5">
//                         <h3 className="text-primaryColor text-sm font-medium">
//                           Check Up Date
//                         </h3>
//                         <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
//                           <img src={calender} alt="image" className="w-3" />
//                           <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
//                         </div>
//                       </div>
//                       <div className="w-full border text-lightBlue my-3"></div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </Col>

//             <Col xs={24} sm={12} md={8}>
//               <div className="mt-5 bg-White rounded-md common-shadow">
//                 <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
//                   <h3 className="text-base md:text-lg text-secondaryColor font-medium">
//                     Medical History
//                   </h3>
//                   <div
//                     onClick={showModal}
//                     className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
//                   >
//                     <img
//                       src={plus}
//                       alt="plus"
//                       className="w-3 h-3 cursor-pointer"
//                     />
//                   </div>
//                 </div>

//                 <div className="h-56 overflow-scroll scrollWidth p-5">
//                   {medicalhistoryData.map((user, i) => (
//                     <div>
//                       <h3 className="text-sm text-textGray font-medium">
//                         {user.description}
//                       </h3>

//                       <div className="flex w-full items-center gap-3 mt-5">
//                         <h3 className="text-primaryColor text-sm font-medium">
//                           Check Up Date
//                         </h3>
//                         <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
//                           <img src={calender} alt="image" className="w-3" />
//                           <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
//                         </div>
//                       </div>
//                       <div className="w-full border text-lightBlue my-3"></div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </Col>

//             <Col xs={24} sm={12} md={8}>
//               <div className="mt-5 bg-White rounded-md common-shadow">
//                 <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
//                   <h3 className="text-base md:text-lg text-secondaryColor font-medium">
//                     Surgical History
//                   </h3>
//                   <div
//                     onClick={showModal}
//                     className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
//                   >
//                     <img
//                       src={plus}
//                       alt="plus"
//                       className="w-3 h-3 cursor-pointer"
//                     />
//                   </div>
//                 </div>

//                 <div className="h-56 overflow-scroll scrollWidth p-5">
//                   {surgicalhistoryDataList.map((user, i) => (
//                     <div>
//                       <h3 className="text-sm text-textGray font-medium">
//                         {user.description}
//                       </h3>

//                       <div className="flex w-full items-center gap-3 mt-5">
//                         <h3 className="text-primaryColor text-sm font-medium">
//                           Check Up Date
//                         </h3>
//                         <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
//                           <img src={calender} alt="image" className="w-3" />
//                           <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
//                         </div>
//                       </div>
//                       <div className="w-full border text-lightBlue my-3"></div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </Col>

//             <Col xs={24} sm={12} md={8}>
//               <div className="mt-5 bg-White rounded-md common-shadow">
//                 <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
//                   <h3 className="text-base md:text-lg text-secondaryColor font-medium">
//                     Family History
//                   </h3>
//                   <div
//                     onClick={showModal}
//                     className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
//                   >
//                     <img
//                       src={plus}
//                       alt="plus"
//                       className="w-3 h-3 cursor-pointer"
//                     />
//                   </div>
//                 </div>

//                 <div className="h-56 overflow-scroll scrollWidth p-5">
//                   {familyhistoryDataList.map((user, i) => (
//                     <div>
//                       <h3 className="text-sm text-textGray font-medium">
//                         {user.description}
//                       </h3>

//                       <div className="flex w-full items-center gap-3 mt-5">
//                         <h3 className="text-primaryColor text-sm font-medium">
//                           Check Up Date
//                         </h3>
//                         <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
//                           <img src={calender} alt="image" className="w-3" />
//                           <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
//                         </div>
//                       </div>
//                       <div className="w-full border text-lightBlue my-3"></div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </Col>

//             <Col xs={24} sm={12} md={8}>
//               <div className="mt-5 bg-White rounded-md common-shadow">
//                 <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
//                   <h3 className="text-base md:text-lg text-secondaryColor font-medium">
//                     Social History
//                   </h3>
//                   <div
//                     onClick={showModal}
//                     className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
//                   >
//                     <img
//                       src={plus}
//                       alt="plus"
//                       className="w-3 h-3 cursor-pointer"
//                     />
//                   </div>
//                 </div>

//                 <div className="h-56 overflow-scroll scrollWidth p-5">
//                   {socialhistoryDataList.map((user, i) => (
//                     <div>
//                       <h3 className="text-sm text-textGray font-medium">
//                         {user.description}
//                       </h3>
//                       <div className="flex w-full items-center gap-3 mt-5">
//                         <h3 className="text-primaryColor text-sm font-medium">
//                           Check Up Date
//                         </h3>
//                         <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
//                           <img src={calender} alt="image" className="w-3" />
//                           <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
//                         </div>
//                       </div>
//                       <div className="w-full border text-lightBlue my-3"></div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </Col>

//             <Col xs={24} sm={12} md={8}>
//               <div className="mt-5 bg-White rounded-md common-shadow">
//                 <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
//                   <h3 className="text-base md:text-lg text-secondaryColor font-medium">
//                     Medication
//                   </h3>
//                   <div
//                     onClick={showModal}
//                     className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
//                   >
//                     <img
//                       src={plus}
//                       alt="plus"
//                       className="w-3 h-3 cursor-pointer"
//                     />
//                   </div>
//                 </div>

//                 <div className="h-56 overflow-scroll scrollWidth p-5">
//                   {medicationData.map((user, i) => (
//                     <div>
//                       <div className="flex justify-between gap-5 w-full">
//                         <h3 className="text-sm text-textGray font-medium">
//                           {user.drugName}
//                         </h3>
//                         <div className="flex gap-2">
//                           <span className="text-sm text-textGray font-medium">
//                             Dosage Level:
//                           </span>
//                           <span className="text-sm text-textGray font-light">
//                             {user.dosage ? user.dosage : `Morning & Noon`}
//                           </span>
//                         </div>
//                       </div>

//                       <div className="flex w-full items-center gap-3 mt-5">
//                         <h3 className="text-primaryColor text-sm font-medium">
//                           Check Up Date
//                         </h3>
//                         <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
//                           <img src={calender} alt="image" className="w-3" />
//                           <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
//                         </div>
//                       </div>
//                       <div className="w-full border text-lightBlue my-3"></div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </Col>

//             <Col xs={24} sm={12} md={8}>
//               <div className="mt-5 bg-White rounded-md common-shadow">
//                 <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
//                   <h3 className="text-base md:text-lg text-secondaryColor font-medium">
//                     Chronic Conditions
//                   </h3>
//                   <div
//                     onClick={showModal}
//                     className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
//                   >
//                     <img
//                       src={plus}
//                       alt="plus"
//                       className="w-3 h-3 cursor-pointer"
//                     />
//                   </div>
//                 </div>

//                 <div className="h-56 overflow-scroll scrollWidth p-5">
//                   {selectedChronicConditions.map((user, i) => (
//                     <div>
//                       <h3 className="text-sm text-textGray font-medium">
//                         COPD
//                       </h3>

//                       <div className="flex w-full items-center gap-3 mt-5">
//                         <h3 className="text-primaryColor text-sm font-medium">
//                           Check Up Date
//                         </h3>
//                         <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
//                           <img src={calender} alt="image" className="w-3" />
//                           <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
//                         </div>
//                       </div>
//                       <div className="w-full border text-lightBlue my-3"></div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </div>
//       </section>
//       <Modal
//         title={
//           <p className="font-semibold text-primaryColor text-2xl">
//             Add Allergy Details
//           </p>
//         }
//         open={isModalOpen}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         footer={[]}
//       >
//         <Form className="CommonForm" layout="vertical" autoComplete="off">
//           <Form.Item label="Record on" name="date">
//             <DatePicker
//               style={{ width: "100%", height: "50px" }}
//               placeholder="Select date"
//             />
//           </Form.Item>
//           <Form.Item label="Drug Allergies" name="email">
//             <Input placeholder="Enter Drug Allergies" />
//           </Form.Item>

//           <Form.Item label="Food Allergies" name="phone">
//             <Input placeholder="Enter Food Allergies " />
//           </Form.Item>
//           <Form.Item label="Chemical Allergies" name="subject">
//             <Input placeholder="Enter Chemical Allergies" />
//           </Form.Item>

//           <Form.Item>
//             <button
//               onClick={handleCancel}
//               className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
//               type="primary"
//               htmlType="submit"
//             >
//               Save details
//             </button>
//           </Form.Item>
//         </Form>
//       </Modal>
//     </Layouts>
//   );
// }

// export default MedicalHistory;


import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layouts from "../../../Layouts";
import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from "antd";
import plus from "../../../../Assets/Images/plus.png";
import calender from "../../../../Assets/Images/calender.png";
import FamilyDropdown from "./FamilyDropDown";
import {
  getmedicalhistory,
  getPatientallergylist,
  getPatientmedicationlist,
  getsurgicalhistory,
  getfamilyhistory,
  getsocialhistory,
  getchronicconditions,
  getpatientchronicconditions,
} from "../../../../Redux/Action/UserprofileActions";
import { patientaddallergy, patientEditallergy } from "../../../../Redux/Action/UserprofileActions";
import { USERPROFILE_ROUTES } from "../../../PageRouting/UserProfileRoutes";
import userprofileservice from "../../../../Redux/Services/userprofileservice";
import moment from "moment";
import HealthRecordService from "../../../../Redux/Services/HealthRecordService";
import { ToastContainer, toast } from "react-toastify";
import { getLocalTime } from "../../../Helper/LocalTimeFormat";
import AddAllergy from "./MedicalHistoryForms/AddAllergy"
import AddSurgery from "./MedicalHistoryForms/AddSurgery"
import AddMedicalHistory from "./MedicalHistoryForms/AddMedicalHistory"
import AddMedication from "./MedicalHistoryForms/AddMedication"
import AddSocialHistory from "./MedicalHistoryForms/AddSocialHistory"
import Addfamilyhistory from "./MedicalHistoryForms/AddFamilyHistory";
import AddChronicHistory from "./MedicalHistoryForms/AddChronicHistory"
import NodataImage from "../../../../Assets/Images/Icons/nodata.svg";



function MedicalHistory() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.authReducer.patientData);
  const patientallergylist = useSelector((state) => state.allergylist);
  const { allergyData } = patientallergylist;
  const [check, setCheck] = useState(true);
  const medicationlist = useSelector((state) => state.allmedicationlist);
  const { medicationData } = medicationlist;
  const medicalhitorylist = useSelector((state) => state.allmedicalhistorylist);
  const { medicalhistoryData } = medicalhitorylist;
  const surgicalhistorylist = useSelector(
    (state) => state.allsurgicalhistorylist
  );
  const { surgicalhistoryDataList } = surgicalhistorylist;
  const familyhistorylist = useSelector((state) => state.allfamilyHistoryList);
  const { familyhistoryDataList } = familyhistorylist;
  const socialhistoryData = useSelector((state) => state.allsocialHistoryList);
  const { socialhistoryDataList } = socialhistoryData;
  const chronicconditionsData = useSelector((state) => state.allchronicConditionsList);
  const { chronicconditionsDataList } = chronicconditionsData;
  const [saveChronicConditionsLoading, setsaveChronicConditionsLoading] = useState(false);
  const [activePatient, setActivePatient] = useState();
  const [selectedChronicConditions, setSelectedChronicConditions] = useState([]);
  const [selectedChronics, setSelectedChronics] = useState([]);
  const patientCode = useSelector(state => state.authReducer.patientCode);
  const patientDetails = useSelector((state) => state.particularpatientdetails);
  const { Option } = Select;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const [isModalOpen6, setIsModalOpen6] = useState(false);
  const [givenDateShow, setGivenDateShow] = useState("");
  const [addallergies, setaddallergy] = useState([]);
  const [givenDate, setGivenDate] = useState("");
  const [editallergy, seteditallergy] = useState();
  const formRef = useRef()
  const [allergyDataArr, setAllergyDataArr] = useState([]);
  const [chronicList, setChronicList] = useState();

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/', {isLoginModalOpen : true});
    }
  }, [])


  useEffect(() => {
    console.log(givenDate, givenDateShow)
  }, [givenDate]);

  useEffect(() => { console.log(selectedChronicConditions) }, [selectedChronicConditions])

  const changeDate = (e) => {
    setGivenDate(moment(e).format("YYYY-MM-DD hh:mm:ss"))
    setGivenDateShow(moment(e).format("MM/DD/yyyy"))
    console.log(givenDate)
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(givenDate);
    // You can perform further actions with the form values here
  };

  const saveallergy = (e) => {
    e.preventDefault();
    const formValues = formRef.current.getFieldsValue();
    const { chemicalAllergy, drugAllergy, foodAllergy } = formValues;
    if (editallergy) {
      let patient = localStorage.getItem("patientprofile");
      const data = [{
        "chemicalAllergy": chemicalAllergy,
        "createdBy": userData.code ? userData.code : patientCode,
        "createdDate": "",
        "drugAllergy": drugAllergy,
        "foodAllergy": foodAllergy,
        "fromDate": "",
        "givenDate": givenDate,
        "id": editallergy?.id,
        "modifiedBy": userData.code ? userData.code : patientCode,
        "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "patientCode": userData.code ? userData.code : patientCode,
        "status": 1,
        "toDate": ""
      }]

      dispatch(patientEditallergy(data)).then((result) => {
        dispatch(getPatientallergylist(patientCode));

      }, [patientCode, dispatch]);
    }
    else {
      let patient = localStorage.getItem("patientprofile");
      const data = [{
        "chemicalAllergy": chemicalAllergy,
        "createdBy": userData.code ? userData.code : patientCode,
        "createdDate": "",
        "drugAllergy": drugAllergy,
        "foodAllergy": foodAllergy,
        "fromDate": "",
        "givenDate": givenDate,
        "modifiedBy": userData.code ? userData.code : patientCode,
        "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "patientCode": userData.code ? userData.code : patientCode,
        "status": 1,
        "toDate": ""
      }]
      console.log("lol3", data)
      dispatch(patientaddallergy(data)).then((result) => {
        dispatch(getPatientallergylist(userData.code ? userData.code : patientCode));
        handleCancel();
      })
    }
  };





  // useEffect(() => {
  //     //getMedicalHistoryFunc();
  //     // loadSocialHistory();
  // }, [patientDetails.memberCode, localStorage.getItem("patientprofile")]);




  const refresh = () => {
    //getMedicalHistoryFunc();
    if (activePatient) {
      loadMedicalHistoryFor(activePatient)
    } else {
      getMedicalHistoryFunc();
    }
  }

  const getMedicalHistoryFunc = async () => {
    let patient = activePatient;
    dispatch(getPatientallergylist(patient));
    dispatch(getPatientmedicationlist(patient));
    dispatch(getmedicalhistory(patient));
    dispatch(getsurgicalhistory(patient));
    dispatch(getfamilyhistory(patient));
    dispatch(getsocialhistory(patient));
    dispatch(getchronicconditions());
    loadPatientChronicConditions(patient);
    // const res = await userprofileservice.getpatientchronicconditionslist(patient);
    // console.log(res);
  };


  const loadMedicalHistoryFor = (patient) => {
    setActivePatient(patient);
    dispatch(getPatientallergylist(patient));
    dispatch(getPatientmedicationlist(patient));
    dispatch(getmedicalhistory(patient));
    dispatch(getsurgicalhistory(patient));
    dispatch(getfamilyhistory(patient));
    dispatch(getsocialhistory(patient));
    dispatch(getchronicconditions());
    loadPatientChronicConditions(patient);
  }

  const loadPatientChronicConditions = (patient) => {
    userprofileservice.getpatientchronicconditionslist(patient).then((res) => {
      if (!res.message) {
        console.log(res.data);
        let array = [];
        res.data.forEach(element => {
          if (element.status == 1) {
            array.push(element);
          }
        });
        setSelectedChronicConditions([...array]);
        console.log(selectedChronicConditions);
      }
    }, (err) => {
      console.log(err);
    })
  }

  // const loadPatientChronicConditions = (patient) => {
  //   userprofileservice.getpatientchronicconditionslist(patient).then((res) => {
  //     if (!res.message) {
  //       console.log(res.data);
  //       let array = [];
  //       res.data.forEach(element => {
  //         if (element.status == 1) {
  //           array.push(element);
  //         }
  //       });
  //       setSelectedChronicConditions([...array]);
  //       console.log(selectedChronicConditions);
  //     }
  //   }, (err) => {
  //     console.log(err);
  //   })
  // }

  useEffect(() => { console.log(selectedChronicConditions) }, [selectedChronicConditions])
  useEffect(() => {
    console.log(chronicList)
  }, [chronicList])

  useEffect(() => {
    setActivePatient(patientCode);
  }, [dispatch]);

  // useEffect(() => {
  //     //getMedicalHistoryFunc();
  //     // loadSocialHistory();
  // }, [patientDetails.memberCode, localStorage.getItem("patientprofile")]);

  useEffect(() => {
    getMedicalHistoryFunc();
  }, []);

  // const saveChronicConditions = () => {
  //   setsaveChronicConditionsLoading(true);
  //   let payload = selectedChronicConditions;
  //   console.log(JSON.stringify(payload));
  //   HealthRecordService.addpatientchronicconditionslist(payload).then((res) => {
  //     setsaveChronicConditionsLoading(false)
  //     if (res.data == 1) {
  //       toast("Chronic Conditions Added Successfully");
  //     }
  //   }, (err) => {
  //     setsaveChronicConditionsLoading(false)
  //     console.log(err);
  //   })
  // }

  const changePatient = (code) => {
    //loadPatientChronicConditions
  }

  const gotoHistory = (e) => {
    e.preventDefault();
    history.push(USERPROFILE_ROUTES.MYDETAILS)
  }
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  }

  const showModal3 = () => {
    setIsModalOpen3(true);
  };
  const handleOk3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };


  const showModal4 = () => {
    setIsModalOpen4(true);
  };
  const handleOk4 = () => {
    setIsModalOpen4(false);
  };
  const handleCancel4 = () => {
    setIsModalOpen4(false);
  };


  const showModal5 = () => {
    setIsModalOpen5(true);
  };
  const handleOk5 = () => {
    setIsModalOpen5(false);
  };
  const handleCancel5 = () => {
    setIsModalOpen5(false);
  };

  const showModal6 = () => {
    setIsModalOpen6(true);
  };
  const handleOk6 = () => {
    setIsModalOpen6(false);
  };
  const handleCancel6 = () => {
    setIsModalOpen6(false);
  };
  return (
    <Layouts>
      <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto ">
          <div className="md:w-full bg-primaryColor rounded-md md:px-8 px-6 py-3 md:py-5">
            <div className=" flex justify-between items-center ">
              <div className="font-medium  md:text-2xl text-lg text-White">
                Medical History
              </div>
              <div className="hidden md:flex items-center">
                <FamilyDropdown title={"Get Medical History For"} onSelect={loadMedicalHistoryFor} hide={true} />
              </div>
            </div>
          </div>
          <div className="block md:hidden my-4">
            {/* <Select
              placeholder="Get medical history for"
              className="mt-3 h-12 w-full border border-gray rounded-md"
            > */}
            <FamilyDropdown title={"Get Medical History For"} onSelect={loadMedicalHistoryFor} hide={true} />
            {/* </Select> */}
          </div>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={8}>
              <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
                <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
                  <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                    Allergy History
                  </h3>
                  <div
                    onClick={showModal}
                    className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
                  >
                    <img
                      src={plus}
                      alt="plus"
                      className="w-3 h-3 cursor-pointer"
                    />
                  </div>
                </div>
                <div className="h-56 overflow-auto scrollWidth p-5">
                  {allergyData && allergyData.length === 0 ? 
                  (
                    <div class=" w-full h-[inherit] flex gap-5 flex-col justify-center my-auto items-center text-center ">
                      <div>
                        <img src={NodataImage} alt="emptyData" className="w-[60px]" />
                      </div>
                      <p className="text-center font-light item-center text-base text-textGray">
                        No data available!
                      </p>
                    </div>

                  )
                  :allergyData.slice().reverse().map((user, i) => (
                    <div>
                      <div className="grid grid-cols-2 gap-3 w-full">
                        <div className="text-sm text-textGray">
                          <label className=" font-light">Drug Allergy</label>
                          <p className="font-semibold">{user.drugAllergy}</p>
                        </div>
                        <div className="text-sm text-textGray">
                          <label className=" font-ligh">Food Allergy</label>
                          <p className="font-semibold">{user.foodAllergy}</p>
                        </div>
                        <div className="text-sm text-textGray">
                          <label className=" font-ligh">Chemical Allergy</label>
                          <p className="font-semibold">
                            {user.chemicalAllergy}
                          </p>
                        </div>
                      </div>
                      <div className="flex w-full items-center gap-3 mt-5">
                        <h3 className="text-primaryColor text-sm font-medium">
                          Check Up Date
                        </h3>
                        <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                          <img src={calender} alt="image" className="w-3" />
                          <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                        </div>
                      </div>
                      <div className="w-full border text-lightBlue my-3"></div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
                <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
                  <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                    Medical History
                  </h3>
                  <div
                    onClick={showModal1}
                    className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
                  >
                    <img
                      src={plus}
                      alt="plus"
                      className="w-3 h-3 cursor-pointer"
                    />
                  </div>
                </div>

                <div className="h-56 overflow-scroll scrollWidth p-5">
                  {medicalhistoryData && medicalhistoryData.length === 0 ? 
                  (
                    <div class=" w-full h-[inherit] flex gap-5 flex-col justify-center my-auto items-center text-center ">
                      <div>
                        <img src={NodataImage} alt="emptyData" className="w-[60px]" />
                      </div>
                      <p className="text-center font-light item-center text-base text-textGray">
                        No data available!
                      </p>
                    </div>

                  )
                  :medicalhistoryData.map((user, i) => (
                    <div>
                      <h3 className="text-sm text-textGray font-medium">
                        {user.description}
                      </h3>

                      <div className="flex w-full items-center gap-3 mt-5">
                        <h3 className="text-primaryColor text-sm font-medium">
                          Check Up Date
                        </h3>
                        <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                          <img src={calender} alt="image" className="w-3" />
                          <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                        </div>
                      </div>
                      <div className="w-full border text-lightBlue my-3"></div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
                <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
                  <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                    Surgical History
                  </h3>
                  <div
                    onClick={showModal2}
                    className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
                  >
                    <img
                      src={plus}
                      alt="plus"
                      className="w-3 h-3 cursor-pointer"
                    />
                  </div>
                </div>

                <div className="h-56 overflow-scroll scrollWidth p-5">
                  {surgicalhistoryDataList && surgicalhistoryDataList.length === 0 ? 
                  (
                    <div class=" w-full h-[inherit] flex gap-5 flex-col justify-center my-auto items-center text-center ">
                      <div>
                        <img src={NodataImage} alt="emptyData" className="w-[60px]" />
                      </div>
                      <p className="text-center font-light item-center text-base text-textGray">
                        No data available!
                      </p>
                    </div>

                  )
                  :surgicalhistoryDataList.map((user, i) => (
                    <div>
                      <h3 className="text-sm text-textGray font-medium">
                        {user.description}
                      </h3>

                      <div className="flex w-full items-center gap-3 mt-5">
                        <h3 className="text-primaryColor text-sm font-medium">
                          Check Up Date
                        </h3>
                        <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                          <img src={calender} alt="image" className="w-3" />
                          <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                        </div>
                      </div>
                      <div className="w-full border text-lightBlue my-3"></div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
                <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
                  <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                    Family History
                  </h3>
                  <div
                    onClick={showModal3}
                    className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
                  >
                    <img
                      src={plus}
                      alt="plus"
                      className="w-3 h-3 cursor-pointer"
                    />
                  </div>
                </div>

                <div className="h-56 overflow-scroll scrollWidth p-5">
                  {familyhistoryDataList &&  familyhistoryDataList.length === 0 ?
                  (
                    <div class=" w-full h-[inherit] flex gap-5 flex-col justify-center my-auto items-center text-center ">
                      <div>
                        <img src={NodataImage} alt="emptyData" className="w-[60px]" />
                      </div>
                      <p className="text-center font-light item-center text-base text-textGray">
                        No data available!
                      </p>
                    </div>

                  )
                  :
                  familyhistoryDataList.map((user, i) => (
                    <div>
                      <h3 className="text-sm text-textGray font-medium">
                        {user.description}
                      </h3>

                      <div className="flex w-full items-center gap-3 mt-5">
                        <h3 className="text-primaryColor text-sm font-medium">
                          Check Up Date
                        </h3>
                        <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                          <img src={calender} alt="image" className="w-3" />
                          <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                        </div>
                      </div>
                      <div className="w-full border text-lightBlue my-3"></div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
                <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
                  <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                    Social History
                  </h3>
                  <div
                    onClick={showModal4}
                    className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
                  >
                    <img
                      src={plus}
                      alt="plus"
                      className="w-3 h-3 cursor-pointer"
                    />
                  </div>
                </div>

                <div className="h-56 overflow-scroll scrollWidth p-5">
                  {socialhistoryDataList && socialhistoryDataList.length === 0 ? 
                  (
                    <div class=" w-full h-[inherit] flex gap-5 flex-col justify-center my-auto items-center text-center ">
                      <div>
                        <img src={NodataImage} alt="emptyData" className="w-[60px]" />
                      </div>
                      <p className="text-center font-light item-center text-base text-textGray">
                        No data available!
                      </p>
                    </div>

                  ):
                  
                  socialhistoryDataList.map((user, i) => (
                    <div>
                      <h3 className="text-sm text-textGray font-medium">
                        {user.description}
                      </h3>
                      <div className="flex w-full items-center gap-3 mt-5">
                        <h3 className="text-primaryColor text-sm font-medium">
                          Check Up Date
                        </h3>
                        <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                          <img src={calender} alt="image" className="w-3" />
                          <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                        </div>
                      </div>
                      <div className="w-full border text-lightBlue my-3"></div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
                <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
                  <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                    Medication
                  </h3>
                  <div
                    onClick={showModal5}
                    className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
                  >
                    <img
                      src={plus}
                      alt="plus"
                      className="w-3 h-3 cursor-pointer"
                    />
                  </div>
                </div>

                <div className="h-56 overflow-scroll scrollWidth p-5">
                  {medicationData && medicationData.length === 0 ? 
                  (
                    <div class=" w-full h-[inherit] flex gap-5 flex-col justify-center my-auto items-center text-center ">
                      <div>
                        <img src={NodataImage} alt="emptyData" className="w-[60px]" />
                      </div>
                      <p className="text-center font-light item-center text-base text-textGray">
                        No data available!
                      </p>
                    </div>

                  ):
                  medicationData.map((user, i) => (
                    <div>
                      <div className="flex justify-between gap-5 w-full">
                        <h3 className="text-sm text-textGray font-medium">
                          {user.drugName}
                        </h3>
                        <div className="flex gap-2">
                          <span className="text-sm text-textGray font-medium">
                            Dosage Level:
                          </span>
                          <span className="text-sm text-textGray font-light">
                            {user.dosage ? user.dosage : `Morning & Noon`}
                          </span>
                        </div>
                      </div>

                      <div className="flex w-full items-center gap-3 mt-5">
                        <h3 className="text-primaryColor text-sm font-medium">
                          Check Up Date
                        </h3>
                        <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                          <img src={calender} alt="image" className="w-3" />
                          <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                        </div>
                      </div>
                      <div className="w-full border text-lightBlue my-3"></div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
                <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
                  <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                    Chronic Conditions
                  </h3>
                  <div
                    onClick={showModal6}
                    className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
                  >
                    <img
                      src={plus}
                      alt="plus"
                      className="w-3 h-3 cursor-pointer"
                    />
                  </div>
                </div>

                <div className="h-56 overflow-scroll scrollWidth p-5">
                  {selectedChronicConditions && selectedChronicConditions.length === 0 ?
                    (
                      <div class=" w-full h-[inherit] flex gap-5 flex-col justify-center my-auto items-center text-center ">
                        <div>
                          <img src={NodataImage} alt="emptyData" className="w-[60px]" />
                        </div>
                        <p className="text-center font-light item-center text-base text-textGray">
                          No data available!
                        </p>
                      </div>

                    ) :
                    selectedChronicConditions.map((user, i) => (
                      <div>
                        <h3 className="text-sm text-textGray font-medium">
                          {user.chronicDescription}
                        </h3>

                        <div className="flex w-full items-center gap-3 mt-5">
                          <h3 className="text-primaryColor text-sm font-medium">
                            Check Up Date
                          </h3>
                          <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                            <img src={calender} alt="calender" className="w-3" />
                            <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                          </div>
                        </div>
                        <div className="w-full border text-lightBlue my-3"></div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </Col>

          </Row>
        </div>
      </section>

      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Allergy Details
          </p>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        onFinish={saveallergy}
      >
        {/* <Form
          ref={formRef}
          className="CommonForm"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item label="Record on" name="givenDate">
            <DatePicker style={{ width: '100%', height: '50px' }}
              placeholder="Select date"
            />
          </Form.Item>
          <Form.Item label="Drug Allergies" name="drugAllergy">
            <Input placeholder="Enter Drug Allergies" />
          </Form.Item>
          <Form.Item label="Food Allergies" name="foodAllergy">
            <Input placeholder="Enter Food Allergies " />
          </Form.Item>
          <Form.Item label="Chemical Allergies" name="chemicalAllergy">
            <Input placeholder="Enter Chemical Allergies" />
          </Form.Item>
          <Form.Item>
            <button
              onClick={saveallergy}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center"
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <AddAllergy closePopup={handleCancel} patCode={activePatient} loadData ={() => { refresh() }} />
      </Modal>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Medical History
          </p>
        }
        open={isModalOpen1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        footer={[]}
      >
        {/* <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Record on" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="Select date"
            />
          </Form.Item>
          <Form.Item label="Description" name="email">
            <Input placeholder="Enter Description" />
          </Form.Item>
          <Form.Item>
            <button
              onClick={saveallergy}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <AddMedicalHistory closePopup={handleCancel1} patCode={activePatient} loadData ={() => { refresh() }}/>
      </Modal>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Surgical History
          </p>
        }
        open={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={[]}
      >
        {/* <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Record on" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="Select date"
            />
          </Form.Item>
          <Form.Item label="Description" name="email">
            <Input placeholder="Enter Description" />
          </Form.Item>
          <Form.Item>
            <button
              onClick={handleCancel2}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <AddSurgery closePopup={handleCancel2} patCode={activePatient} loadData ={() => { refresh() }}/>
      </Modal>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Family History
          </p>
        }
        open={isModalOpen3}
        onOk={handleOk3}
        onCancel={handleCancel3}
        footer={[]}
      >
        {/* <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Record on" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="Select date"
            />
          </Form.Item>
          <Form.Item label="Description" name="email">
            <Input placeholder="Enter Description" />
          </Form.Item>
          <Form.Item>
            <button
              onClick={handleCancel3}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <Addfamilyhistory closePopup={handleCancel3} patCode={activePatient} loadData ={() => { refresh() }}/>
      </Modal>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Social History
          </p>
        }
        open={isModalOpen4}
        onOk={handleOk4}
        onCancel={handleCancel4}
        footer={[]}
      >
        {/* <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Record on" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="Select date"
            />
          </Form.Item>
          <Form.Item label="Description" name="email">
            <Input placeholder="Enter Description" />
          </Form.Item>
          <Form.Item>
            <button
              onClick={handleCancel4}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <AddSocialHistory closePopup={handleCancel4} patCode={activePatient} loadData ={() => { refresh() }}/>
      </Modal>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Medications
          </p>
        }
        open={isModalOpen5}
        onOk={handleOk5}
        onCancel={handleCancel5}
        footer={[]}
      >
        {/* <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Drug Name" name="Drug name">
            <Input placeholder="Enter Drug Name" />
          </Form.Item>
          <Form.Item label="Dosage" name="dosage">
            <Input placeholder="Enter Dosage" />
          </Form.Item>
          <Form.Item label="From Date" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="From Date"
            />
          </Form.Item>
          <Form.Item label="To Date" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="To Date"
            />
          </Form.Item>
          <Form.Item>
            <button
              onClick={handleCancel5}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <AddMedication closePopup={handleCancel5} patCode={activePatient} loadData ={() => { refresh() }}/>
      </Modal>
      <Modal
        title={<p className="font-semibold text-primaryColor text-2xl">Add Chronic Condition</p>}
        visible={isModalOpen6}
        onOk={handleOk6}
        onCancel={handleCancel6}
        footer={[]}
      >
        {/* <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Chronic Condition" name="chronicCondition">
            <Select
              placeholder="Select Chronic Condition"
              style={{ width: "100%", height: "50px" }}
            >
              <Option value="CC01">COPD</Option>
              <Option value="CC02">Asthma</Option>
              <Option value="CC03">Diabetes Mellitus</Option>
              <Option value="CC04">Hypertension</Option>
              <Option value="CC05">Congestive Heart Failure</Option>
              <Option value="CC06">Cardiac Disease</Option>
              <Option value="CC07">Renal Disease</Option>
              <Option value="CC08">Chronic Renal Failure</Option>
              <Option value="CC09">Rheumatoid Arthritis</Option>
              <Option value="CC10">Organ Transplant</Option>
              <Option value="CC11">High Cholesterol</Option>
              <Option value="CC12">Psychiatric Disorder</Option>
              <Option value="CC13">Neurological Disorder</Option>
              <Option value="CC14">Oncological Disorders</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <button
              onClick={handleCancel6}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center"
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <AddChronicHistory closePopup={handleCancel6}  patient={activePatient} loadData ={() => { refresh() }} />
      </Modal>
    </Layouts>
  );
}

export default MedicalHistory;

