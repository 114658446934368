import { createStore, applyMiddleware, compose } from "redux";
import asyncReducer from "../Reducer/allReducer";
import logger from 'redux-logger';
import thunk from "redux-thunk"; // Correctly import thunk

// Middleware to check for missing action type, with handling for thunks
const actionValidatorMiddleware = store => next => action => {
  if (typeof action === 'function') {
    // Allow thunks (functions) to pass through
    return next(action);
  }

  if (!action || !action.type) {
    console.error('Action without type detected:', action);
    return;
  }

  return next(action);
};

// Compose enhancers to use Redux DevTools and apply middleware
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  asyncReducer,
  composeEnhancer(applyMiddleware(actionValidatorMiddleware, thunk, logger))
);

export default store;
