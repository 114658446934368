export const spe = {
    REQUEST_SPELIST: "REQUEST_SPELIST",
    SUCCESS_SPELIST: "SUCCESS_SPELIST",
    FAILED_SPELIST: "FAILED_SPELIST",


    REQUEST_REPORT_DETAILS: "REQUEST_REPORT_DETAILS",
    SUCCESS_REPORT_DETAILS: "SUCCESS_REPORT_DETAILS",
    FAILED_REPORT_DETAILS: "FAILED_REPORT_DETAILS",


    
}