import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import CancelAppointmentPopup from "./CancelAppointmentPopup";
import { deleteAppointment } from "../../../Redux/Action/doctorAction";
import { getLocalTime } from "../../Helper/LocalTimeFormat";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function AppointmentDetailspopup(props) {
  const dispatch = useDispatch();
  const [isErrorMsg, setErrorMsg] = useState("");
  const userData = useSelector((state) => state.authReducer.patientData);

  const history = useHistory();

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/', {isLoginModalOpen : true});
    }
  }, [])
  const {
    updatedAppointment,
    isError,
    isLoading: updating,
    msg,
    errMsg,
    success,
  } = useSelector((state) => state.doctorAppointment);
  const patientCode = useSelector((state) => state.authReducer.patientCode);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const redirectTo = (event) => {
    event.preventDefault();
    props.closePopup();
  };

  const confirmCancelDialog = (e) => {
    e.preventDefault();
    setShowCancelDialog(true);
  };

  const confirmCancelAppointment = (reason) => {
    if (reason == "") {
      setErrorMsg("Please select reason");
      return;
    }
    dispatch(deleteAppointment(props?.appointmentdetails?.id, reason))
      .then((res) => {
        setShowCancelDialog(false);
        setIsLoading(false);
      })
      .catch((err) => setErrorMsg(err?.response?.data?.details[0]));
  };

  return (
    <>
      <div className="flex justify-center fixed lg:none items-center  overflow-x-scroll hide-scrollbar overflow-y-auto inset-0 z-50 outline-none focus:outline-none">
        <div className=" w-auto my-6 mx-auto max-w-3xl h-64">
          <div className="border-0 rounded-lg shadow-lg relative sm:-top-10 flex flex-col md:w-11/12 bg-White outline-none focus:outline-none">
            <div className="relative p-6 flex-auto overflow-y-auto">
              <div className="flex justify-between my-2">
                <p className="text-medium font-medium text-2xl  text-primaryColor">
                  Appointments Details
                </p>

                <XIcon onClick={redirectTo} className="h-5 cursor-pointer" />
              </div>
              <hr classname="border-dash text-black w-10 h-20 "></hr>
              <div>
                <div className="my-2">
                  <div className="md:flex justify-between items-center py-2 mt-4 space-y-3 md:spacey-0 md:space-x-10 md:pr-8">
                    <div className="relative md:w-1/2">
                      <div className="flex ">
                        <input
                          autocomplete="off"
                          id="email"
                          name="email"
                          type="text"
                          editable='false'
                          className="peer w-full  h-10 text-xs  text-gray-900 focus:outline-none focus:borer-rose-600"
                          placeholder="Enter Patient Name"
                          value={props.appointmentdetails.patientName}
                          readOnly
                        />
                      </div>
                      <label
                        for="password"
                        className="absolute left-0 -top-3.5 text-gray-600 text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Patient Name{" "}
                      </label>
                    </div>

                    <div className="relative md:w-1/2">
                      <div className="flex ">
                        <input
                          autocomplete="off"
                          id="email"
                          name="email"
                          type="text"
                          className="peer w-full text-xs h-10   text-gray-900 focus:outline-none focus:borer-rose-600"
                          placeholder="Enter Consultation Type"
                          value={
                            props.appointmentdetails.consultationsType == "V"
                              ? "Video"
                              : props.appointmentdetails.consultationsType ==
                                "A"
                              ? "Audio"
                              : props.appointmentdetails.consultationsType ==
                                "I"
                              ? "Inperson"
                              : "Quick"
                          }
                        />
                      </div>
                      <label
                        for="password"
                        className="absolute left-0 -top-3.5 text-gray-600 text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Consultation Type{" "}
                      </label>
                    </div>
                  </div>
                  <div className="md:flex justify-between items-center py-2 space-y-3 md:spacey-0 md:space-x-10 md:pr-8">
                    <div className="relative md:w-1/2">
                      <div className="flex ">
                        <input
                          autocomplete="off"
                          id="email"
                          name="email"
                          type="text"
                          className="peer  w-56 h-10  text-xs text-gray-900 focus:outline-none focus:borer-rose-600"
                          placeholder="Enter Date Of Consultattion"
                          value={getLocalTime(
                            props.appointmentdetails.whenAppointment
                          )}
                        />
                      </div>
                      <label
                        for="password"
                        className="absolute left-0 -top-3.5 text-gray-600 text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Date Of Consultattion{" "}
                      </label>
                    </div>

                    <div className="relative md:w-1/2">
                      <div className="flex ">
                        <input
                          autocomplete="off"
                          id="email"
                          name="email"
                          type="text"
                          className="peer w-56 md:-ml-5 h-10 text-xs  text-gray-900 focus:outline-none focus:borer-rose-600"
                          placeholder="EnterTime of Consultation"
                          value={props.appointmentdetails.fromTime}
                        />
                      </div>
                      <label
                        for="password"
                        className="absolute left-0 md:-left-5 -top-3.5 text-gray-600 text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Time of Consultation{" "}
                      </label>
                    </div>
                  </div>

                  <div className="md:flex justify-between items-center py-2 space-y-3 md:spacey-0 md:space-x-10 md:pr-8">
                    <div className="relative md:w-1/2">
                      <div className="flex ">
                        <input
                          autocomplete="off"
                          id="email"
                          name="email"
                          type="text"
                          className="peer w-full  h-10 text-xs  text-gray-900 focus:outline-none focus:borer-rose-600"
                          placeholder="Enter Doctor Name"
                          value={props.appointmentdetails.userName}
                        />
                      </div>
                      <label
                        for="password"
                        className="absolute left-0 -top-3.5 text-gray-600 text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Doctor Name{" "}
                      </label>
                    </div>

                    <div className="relative md:w-1/2">
                      <div className="flex ">
                        <input
                          autocomplete="off"
                          id="email"
                          name="email"
                          type="text"
                          className="peer w-full  h-10 text-xs  text-gray-900 focus:outline-none focus:borer-rose-600"
                          placeholder="Enter Description"
                          value={props.appointmentdetails.consultationsReason}
                        />
                      </div>
                      <label
                        for="password"
                        className="absolute left-0 -top-3.5 text-gray-600 text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Description{" "}
                      </label>
                    </div>
                  </div>

                  <div className="md:flex justify-between items-center py-2 space-y-3 md:spacey-0 md:space-x-10 md:pr-8">
                    <div className="relative md:w-1/2">
                      <div className="flex ">
                        <input
                          autocomplete="off"
                          id="email"
                          name="email"
                          type="text"
                          className="peer w-full  h-10 text-xs  text-gray-900 focus:outline-none focus:borer-rose-600"
                          placeholder="Enter Status"
                          value={
                            props.appointmentdetails.status === 1 ||
                            props.appointmentdetails.status === 14 ||
                            props.appointmentdetails.status === 15
                              ? "Pending"
                              : props.appointmentdetails.status === 3 ||
                                props.appointmentdetails.status === 16
                              ? "Cancelled"
                              : props.appointmentdetails.status === 9
                              ? "Expired"
                              : props.appointmentdetails.status === 2
                              ? "Completed"
                              : null
                          }
                        />
                      </div>
                      <label
                        for="password"
                        className="absolute left-0 -top-3.5 text-gray-600 text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Status{" "}
                      </label>
                    </div>
                  </div>

                  <div className="flex justify-end">
                    {props.appointmentdetails.status === 1 &&
                    props.isVisible &&
                    props.appointmentdetails?.isConfirm !== "1" ? (
                      <button
                        onClick={confirmCancelDialog}
                        className="bg-brand-secondaryColor bg-White font-medium  mb-8 mr-2 p-2 rounded-xl text-White"
                      >
                        Cancel This Appointment
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  {(props.appointmentdetails.status === 2 ||
                    props.appointmentdetails.status === 3) && (
                    <p className="text-secondaryColor">
                      Your appointment has been{" "}
                      {props.appointmentdetails.status === 3
                        ? "Cancelled"
                        : "Completed"}{" "}
                      Successfully.
                    </p>
                  )}
                  {isError && <p className="text-red-600">{errMsg}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCancelDialog && (
        <CancelAppointmentPopup
          isErrorMsg={isErrorMsg}
          onCancel={confirmCancelAppointment}
          isLoading={isLoading}
          close={() => {
            setShowCancelDialog(false);
            setErrorMsg("");
          }}
          ஃ
        />
      )}
    </>
  );
}

export default AppointmentDetailspopup;
