const MobDrugTable = ({ items }) => {
    return (
      <div className="w-full mx-auto space-y-4">
        {items.map((drug, idx) => (
          <div
            key={idx}
            className="flex justify-between items-center p-5 bg-gradient-to-r from-lightBlue to-White rounded-xl transform hover:scale-105 transition-transform duration-200 ease-in-out"
          >
          
            <div className="flex flex-col">
              <span className="text-sm font-semibold text-gray-800">
                {drug?.drugName || "Medicine Name"}
              </span>
              <span className="text-sm text-[#313131] mt-1">
               <span className="font-medium text-[#313131]">{drug?.quantity || 0}</span> 
                &nbsp; x &nbsp; ₹{drug?.unitPrice || 0}
              </span>
            </div>
  
           
            <div className="flex flex-col items-end space-x-4">
            
              {drug?.discountAmount  ? (
                <span className="text-[#313131] line-through text-base">
                  ₹{drug?.unitPrice *  drug?.quantity }
                </span>
              ) : null}
           
              <span className="text-base font-semibold text-secondaryColor">
                ₹{drug?.totalAmount || 0}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default MobDrugTable;
  