import React, { useRef, useEffect, useState } from "react";
import videoIcon from "../../../Assets/Images/Home/video-call.png";
import doctorImage from "../../../Assets/Images/doctorImage.svg";
import { Input, Modal, message } from "antd";
import { useHistory } from "react-router-dom";
import Calendar1 from "../../../Assets/Images/Icons/calendar1.svg";
import ClockImage from "../../../Assets/Images/Icons/clock.svg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getToken } from "../../../Redux/Action/userActions";
import {
  getPatientLabTestsList,
  getPatientfamilymembers,
  onPatientfamilyMembersDelete,
} from "../../../Redux/Action/UserprofileActions";
import patientService from "../../../Redux/Services/patientService";

import {
  deleteAppointment,
  removeUpdateSuccess,
} from "../../../Redux/Action/doctorAction";
import { getLocalTime } from "../../Helper/LocalTimeFormat";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import CancelAppointmentPopup from "../PostLogin/CancelAppointmentPopup";
import RescheduleModal from "../PostLogin/RescheduleModal";
import {getPatientmedicinedeliveryListOnFilter } from "../../../Redux/Action/patientAction";
import moment from "moment";
import { AddtoCart } from "../../../Redux/Action/cartPlaceOrderAction";
import UploadPrescriptionHelper from "../../Helper/uploadPrescription";
import { getAllProductByIds } from "../../../Redux/Action/pharmacyAction";
import EprescriptionsView from "../Services/EprescriptionsView";

import NodataImage from "../../../Assets/Images/Icons/nodata.svg";
import "react-toastify/dist/ReactToastify.css";
import { trackAddToCartEvent } from "../../utils/analytics/events";
import axios from "axios";
const Appointments = () =>{
    const dispatch = useDispatch();
    const location = useLocation();
    const [isLoading, setLoading] = useState(false);
  const [isHide, setHide] = useState(true);
    const [appointmentdetails, setappointmentdetails] = useState();
    const [appointmentlistData, setAppointmentlistData] = useState([]);
    const doctorAppointment = useSelector((state) => state.doctorAppointment);
    const { success, isError } = doctorAppointment;
    const patientCode = useSelector((state) => state.authReducer.patientCode);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [error, setError] = useState("");
    const [isErrorMsg, setErrorMsg] = useState("");
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const userData = useSelector((state) => state.authReducer.patientData);
    const [medicineList, setmedicineList] = useState([]);
    const [list, setList] = useState([]);
    const Need = location?.state?.from || "";
    const [modal1, setModal1] = useState(false);
    const [prescriptionDialog, setPrescriptionDialog] = useState(false);
    const [prescriptionDoc, setPrescriptionDoc] = useState([]);
    const [reorderData, setReorderData] = useState([]);
    const [isDialogOpen, setDialog] = useState(false);
    const [cartData, setCartData] = useState({});
    const [addToCartLoading, setAddToCartLoading] = useState(false);
    const { cartList } = useSelector((state) => state.cartReducer);
    const [prescriptionView, setPrescriptionView] = useState(false);
    const [userID, setUserId] = useState(null);
  
    const medicinedeliverylist = useSelector(
      (state) => state.patientmedicinedeliverylist
    );
    const { PatientmedicinedeliveryData } = medicinedeliverylist;
    const familymemberinfo = useSelector((state) => state.familymembers);
    const [Editmember, setEditmember] = useState();
    const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
    const [currSelectedOrder, setCurrSelectedOrder] = useState();
    const [homePageSections, setHomePageSections] = useState([])
  
  
    console.log(location,"apidataaaa", isModalOpen)
  
  
    const showModal1 = (medicineOrder) => {
      setModal1(true);
      setCurrSelectedOrder(medicineOrder);
    };
  
    useEffect(() =>{
      fetchHomeContent()
    },[])
  
    useEffect(() => {
      if(userData?.code){
        const payload = {
          patientId: userData.code,
          photoRequired: "Y",
          status: 1,
        };
        dispatch(getPatientmedicinedeliveryListOnFilter(payload, 1));
      }
  
    }, [userData.code]);
  
    useEffect(() => {
      if (PatientmedicinedeliveryData?.data?.length) {
        setmedicineList(PatientmedicinedeliveryData?.data);
      }
    }, [PatientmedicinedeliveryData?.data?.length]);
  
    useEffect(() => {
      if(userData?.code){
        dispatch(getPatientLabTestsList(userData.code))
        .then((res) => {
          let data = res?.filter((item) => item.procedureStatus !== -1);
          setList(data);
        })
        .catch((err) => {
          setList([]);
        });
        dispatch(getPatientfamilymembers(userData.code));
        getPatientAppointment(userData?.code);
      }
  
    }, [userData.code]);
  
    const handlePrescription = (userId) => {
      setPrescriptionView(true);
      setUserId(userId);
    };
  
    const fetchHomeContent = async () =>{
      try {
        let result = await axios.get(`${process.env.REACT_APP_CMS_BASEURL}home-page-v1s/?populate=sections,sections.headerComp,sections.headerComp.button,sections.firstComp,sections.firstComp.button,sections.secondComp,sections.secondComp.button,sections.static,sections.thirdComp,sections.thirdComp.button,sections.forthComp,sections.image_url,sections.static,sections.fifthComp,sections.comp,sections.scomps,sections.sixthComp,sections.sixthComp.button,sections.seventh,sections.seventh.button,sections.eighthComp,sections.eighthComp.eightsubcomp,sections.NinthComp,sections.static,sections.tenthcomp,sections.static,sections.elevencomp`)
        console.log(result, "sdvsdgougvsdouvsdvsd")
        if(result.data.data.length){
          setHomePageSections(result.data.data[0].attributes.sections)
        }
      } catch (error) {
        
      }
    }
  
    const editmembers = (e, user) => {
      e.preventDefault();
      setshowaddmemberpopup(true);
      setEditmember(user);
    };
  
    const handleScroll = () =>{
      if(window.innerWidth < 768){
          document.body.scrollTop= 4400;
        document.documentElement.scrollTop = 4400;
      }
      else{
        document.body.scrollTop= 3500;
        document.documentElement.scrollTop = 3500;
      }
      
    }
  
    const deleteMembers = (e, user) => {
      e.preventDefault();
      dispatch(onPatientfamilyMembersDelete(user?.code)).then((res) => {
        dispatch(getPatientfamilymembers(userData.code));
      });
    };
  
    const refreshFamilyMembers = () => {
      dispatch(getPatientfamilymembers(userData.code));
    };
  
    const addtoCart = (e, user) => {
      e.preventDefault();
      // setIsAdding(true);
  
     
  
      let prescriptionRequired = "N";
      let ePrescriptionRequired = "N";
  
      if (user?.prescriptionDoc) {
        prescriptionRequired = "Y";
        ePrescriptionRequired = "Y";
      } else {
        prescriptionRequired = "N";
        ePrescriptionRequired = "N";
      }
      let dataObj = {};
  
      let isAdded = false;
      setAddToCartLoading(true);
      let productDetail = reorderData.map((res) => {
        return {
          drugCode: res?.id,
          drugName: res?.drugName,
          unitPrice: res?.medicineRate,
          drugCategory: res?.drugCategory,
          discountAmount: res?.drugsInfoDiscountedRate
            ? res?.drugsInfoDiscountedRate
            : 0.0,
          totalAmount: res?.medicineRate * res.quantity,
          quantity: res.quantity,
          cartId: cartList && cartList.id ? cartList.cartId : "",
          createdBy: userData.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: userData.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          orderId: null,
          patientId: userData.code,
          prescriptionRequired: res.prescriptionRequired == "Yes" ? "Y" : "N",
          ePrescriptionRequired: res.ePrescriptionRequired == "Yes" ? "Y" : "N",
          medicineTypeOfSell: res?.medicineTypeOfSell,
          membershipCode: null,
          membershipName: null,
          membershipCard: null,
          membershipDiscountPercentage: 0.0,
        };
      });
      if (cartList && cartList.patientMedicineOrder) {
        cartList.patientMedicineOrder.forEach((element) => {
          productDetail.map((res) => {
            element.patientMedicineOrderDetailsList.push(res);
          });
        });
        dataObj = cartList;
      } else {
        dataObj = {
          cartId: cartList && cartList.id ? cartList.cartId : "",
          createdBy: userData.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: userData.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          orderId: null,
          patientId: userData.code,
          status: 1,
          labOrdersYN: false,
          drugsOrdersYN: true,
          totalAmount: 0,
          patientMedicineOrder: [
            {
              orderId: "",
              patientId: userData.code,
              prescriptionId: null,
              cartId: cartList && cartList.id ? cartList.cartId : "",
              txnId: "",
              totalAmount: reorderData[0]?.medicineRate,
              address1: null,
              address2: null,
              address3: null,
              city: null,
              state: null,
              country: null,
              pincode: null,
              deliveryAddress1: null,
              deliveryAddress2: null,
              deliveryAddress3: null,
              deliveryCity: null,
              deliveryState: null,
              deliveryCountry: null,
              deliveryZipcode: null,
              createdBy: userData.code,
              createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              modifiedBy: userData.code,
              modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              status: 1,
              procedureStatus: -1,
              payMode: "E",
              collectionStatus: null,
              paymentLinkForPatient: "N",
              discountCouponCode: null,
              patientName: userData.firstName,
              patientGender: userData?.gender,
              patientMobile: userData?.mobile,
              docName: user?.prescriptionDoc,
              patientMedicineOrderDetailsList: productDetail,
            },
          ],
        };
      }
  
      if (
        dataObj?.patientMedicineOrder?.length &&
        dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.length > 0
      ) {
        let id =
          dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(
            (item) => item.drugCode
          );
        id.map((item, index, self) => {
          if (self.indexOf(item) === index) {
          } else {
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
              self.indexOf(item)
            ].quantity +=
              dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
                index
              ].quantity;
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
              self.indexOf(item)
            ].totalAmount =
              dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
                self.indexOf(item)
              ].quantity *
              dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
                self.indexOf(item)
              ].unitPrice;
          }
        });
  
        let res =
          dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.filter(
            (item, i, self) => {
              if (id.indexOf(item.drugCode) == i) {
                return item;
              }
            }
          );
  
        dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList = res;
      }
      dispatch(AddtoCart(dataObj)).then(async (res) => {
        message.success("Added to cart");
        trackAddToCartEvent(0, "MultipleMedicineAdded")
        if (user?.prescriptionDocArray?.length) {
          await UploadPrescriptionHelper(res, user, "key");
        }
        setAddToCartLoading(false);
        setDialog(false);
      });
    };
    
    const onPrescriptionOpenClick = (user) => {
      setPrescriptionDialog(true);
      setPrescriptionDoc(user.prescriptionDocArray);
    };
    const handleReorderClick = async (user) => {
      let productCode = [];
      productCode = user?.orderDetails.map((res) => {
        if (res.orderItems.length > 0) {
          return res.orderItems.map((code) => {
            return code.drugId;
          });
        }
  
        if (res.refundableAmountForItems.length > 0) {
          return res.refundableAmountForItems.map((code) => {
            return code.drugId;
          });
        }
      });
  
      let productStringfy = JSON.stringify(...productCode);
      let reorderItem = [];
      await dispatch(getAllProductByIds(productStringfy))
        .then((res) => {
          if (user?.orderStatus == "Refunded") {
            user?.orderDetails[0].refundableAmountForItems.forEach((item) => {
              let data = res.find((item1) => item1.id == item.drugId);
              data.quantity = item.quantity;
              data.drugName = item.drugName;
              reorderItem.push(data);
            });
          } else {
            user?.orderDetails[0].orderItems.forEach((item) => {
              let data = res.find((item1) => item1.id == item.drugId);
              data.quantity = item.quantity;
              data.drugName = item.drugName;
              reorderItem.push(data);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setReorderData(reorderItem);
      setDialog(true);
      setCartData(user);
    };
  
    useEffect(() => {
      dispatch(getToken());
      if (location?.state?.isLoginModalOpen) {
        setIsModalOpen(true);
      }else{
        setIsModalOpen(false);
      }
    }, [location?.state?.isLoginModalOpen]);
    const { Search } = Input;
    const onSearch = (value) => console.log(value);
    const history = useHistory();
  
    const handleNavigation = (path) => {
      history.push(path);
      window.scrollTo(0, 0);
    };
  
    const showModal = () => {
      setIsModalOpen1(true);
    };
    const handleOk = () => {
      setIsModalOpen1(false);
      setPrescriptionView(false);
    };
    const handleCancel = () => {
      setIsModalOpen1(false);
      setPrescriptionView(false);
      setUserId(null);
    };
  
    useEffect(() => {
      if (success && showCancelDialog) {
        setShowCancelDialog(false);
      }
      if (isError && showCancelDialog) {
        setError("Something went wrong");
      }
     
    }, [success, isError]);
  
    const redirectToVideo = (event, location, data) => {
      event.preventDefault();
      history.push({ pathname: location, state: data });
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        window.scrollTo(0, 0);
      }else {
        window.scrollTo(0, 0);
      }
    };
  
    const getPatientAppointment = (code) => {
      let payload = {
        patientId: code,
        photoRequired: "Y",
      };
      setLoading(true);
      patientService
        .getpatientappointmentlistFilter(payload)
        .then((res) => {
          if (res.data) {
            setAppointmentlistData(res.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    };
  
    const onRescheduleModelClose = () => {
      setHide(true);
      // getPatientAppointment();
      dispatch(removeUpdateSuccess());
    };
  
    const confirmCancelDialog = (e, val) => {
      e.preventDefault();
      setappointmentdetails(val);
      setShowCancelDialog(true);
    };
  
    const confirmCancelAppointment = (reason) => {
      if (reason == "") {
        setErrorMsg("Please select reason");
        return;
      }
      dispatch(deleteAppointment(appointmentdetails?.id, reason))
        .then((res) => {
          setShowCancelDialog(false);
        })
        .catch((err) => setErrorMsg(err?.response?.data?.details[0]));
    };
  
    const openModal = (val) => {
      setappointmentdetails(val);
      setHide(false);
    };
  
    const isTimeExceed = (data) => {
      let appointmentDate = new Date(
        `${data.whenAppointment} " " ${data.fromTime}`
      );
      let isAllowedToCancel = Math.floor(
        (Date.now() - appointmentDate.getTime()) / 1000 / 60
      );
      if (isAllowedToCancel >= -4) {
        return false;
      } else {
        return true;
      }
    };
  
    // useEffect(() => {
    //   if (initialRender && Need != "Need") {
    //     setInitialRender(false);
    //     firstContentRef.current.scrollIntoView({ behavior: "smooth" });
  
    //     return;
    //   }
    //   if (!initialRender || Need == "Need") {
    //     secondContentRef.current.scrollIntoView({ behavior: "smooth" });
    //   }
    // }, []);
    const scrollToMembershipCart = () => {
      const membershipCartSection = document.getElementById("membershipCart");
      if (membershipCartSection) {
        membershipCartSection.scrollIntoView({ behavior: "smooth" });
      }
    };
  
    const handleCheckout = () => {
      handleNavigation("/services/membership");
      setTimeout(scrollToMembershipCart, 500); // Scroll after navigating to ensure the section is loaded
    };
  
    const handleImgError = (e) => {
      e.target.src = doctorImage;
    };
  
    const mockCmsData = {
      sections: [
          {type: "HeroContent" , order: 1, data: null },
          { type: "Quantum" , order: 2, data: { features: ["Feature 1", "Feature 2", "Feature 3"] } },
          { type: "Testimonials", order: 2, data: { testimonials: ["Testimonial 1", "Testimonial 2"] } },
          { type: "CallToAction", order: 3, data: { buttonText: "Sign Up Now", link: "/signup" } }
      ]
  };
  
    const cmsData = mockCmsData;
  

    return(
<>
{Object.keys(userData).length !== 0 && (
          <section className="bg-White ">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
                Your <span className="text-secondaryColor">Appointments</span>
              </h2>
              <div className="w-full md:flex justify-evenly items-center gap-3">
                <div className="md:w-[33%]">
                  <div className="bg-White p-5 rounded-lg common-shadow min-h-[14rem]">
                    <div className="flex justify-between items-center mb-5">
                      <h4 className="text-lg text-primaryColor font-medium">
                        Doctor Appointments
                      </h4>
                    </div>
                    <div className="overflow-y-scroll h-[14rem] scrollWidth">
                      {appointmentlistData &&
                        appointmentlistData.map((user, i) => (
                          <div className="bg-lightBlue rounded-lg p-4 my-2">
                            <div className="">
                              <div className="flex gap-3 items-center justify-between">
                                <div className="flex gap-3 items-center">
                                  <img
                                    src={
                                      user.userPhotoName
                                        ? `${process.env.REACT_APP_IMG_BASEURL}${user.userPhotoName}`
                                        : doctorImage
                                    }
                                    alt={user.userName}
                                    className="rounded-full w-[45px]"
                                    onError={handleImgError}
                                    style={{
                                      clipPath: "circle(50% at 50% 50%)",
                                    }}
                                  />
                                  <div className="">
                                    <h5 className="text-primaryColor text-base font-medium">
                                      {user.userName}
                                    </h5>
                                    <h6 className="text-secondaryColor text-sm font-medium">
                                      {user.patientName}
                                    </h6>
                                    <div>
                                      {user.status == 8 ? (
                                        ""
                                      ) : user.status == 2 ? (
                                        ""
                                      ) : user.status == 3 ? (
                                        ""
                                      ) : user.status == 4 ? (
                                        ""
                                      ) : user.status == 5 ? (
                                        ""
                                      ) : user.status == 1 ? (
                                        <span className="flex gap-1  ">
                                          {user.consultationsType === "V" && (
                                            <div>
                                              <img
                                                onClick={(e) =>
                                                  redirectToVideo(
                                                    e,
                                                    APP_ROUTES.VIDEO_CALL,
                                                    user
                                                  )
                                                }
                                                src={videoIcon}
                                                alt="img"
                                                className="h-9 w-9 cursor-pointer"
                                              />
                                              <span className="text-primaryColor cursor-pointer text-sm">
                                                Join
                                              </span>
                                            </div>
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex justify-end items-end flex-col">
                                  <div className="flex gap-2">
                                    <img
                                      src={Calendar1}
                                      alt="images"
                                      className="w-[12px]"
                                    />
                                    <p className="text-textGray text-xs ">
                                      {getLocalTime(user.whenAppointment)}
                                    </p>
                                  </div>
                                  <div className="flex gap-2 mt-3">
                                    <img
                                      src={ClockImage}
                                      alt="images"
                                      className="w-[15px]"
                                    />
                                    <p className="text-textGray text-xs ">
                                      {user.fromTime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex justify-between items-center mt-2">
                                {/* {user.status == 8 ? (
                             ""
                           ) : user.status == 2 ? (
                             ""
                           ) : user.status == 3 ? (
                             ""
                           ) : user.status == 4 ? (
                             ""
                           ) : user.status == 5 ? (
                             ""
                           ) : user.status == 1 ? (
                             <div>
                               {user.consultationsType === "V" && (
                                 <div
                                   onClick={(e) =>
                                     redirectToVideo(
                                       e,
                                       APP_ROUTES.VIDEO_CALL,
                                       user
                                     )
                                   }
                                   className="text-[#005D8D] underline text-sm cursor-pointer"
                                 >
                                   <p className="text-primaryColor mr-4 underline cursor-pointer text-sm">
                                     Join Call
                                   </p>
                                 </div>
                               )}
                             </div>
                           ) : (
                             ""
                           )} */}
                                {user?.isConfirm !== "1" &&
                                  user.status === 1 && (
                                    <button
                                      onClick={() => openModal(user)}
                                      disabled={user.status !== 1}
                                      className="w-auto text-left md:w-1/3 md:mr-2 disabled:opacity-50 text-brand-secondary hover:text-brand-secondary"
                                    >
                                      <p className="text-textGray underline cursor-pointer text-sm">
                                        Reschedule
                                      </p>
                                    </button>
                                  )}
                                <div className="text-[#005D8D] underline text-sm cursor-pointer">
                                  {user.status === 2 && user.status !== 1 && (
                                    <button
                                      key={user.id}
                                      onClick={() =>
                                        // history.push({
                                        //   pathname: APP_ROUTES.POST_CONSULTATION,
                                        //   search: `?id=${user.id}`,
                                        // })
                                        handlePrescription(user.id)
                                      }
                                      disabled={user.status !== 2}
                                      className="w-auto text-left md:w-1/3 md:mr-2 disabled:opacity-50 text-brand-secondary hover:text-brand-secondary"
                                    >
                                      Prescription
                                    </button>
                                  )}
                                </div>
                                {user?.isConfirm !== "1" &&
                                  user.status === 1 && (
                                    <button
                                      onClick={(e) =>
                                        confirmCancelDialog(e, user)
                                      }
                                      className="w-auto text-left md:w-1/3 md:mr-2 text-brand-secondary disabled:opacity-50 hover:text-brand-secondary"
                                      disabled={
                                        user.status !== 1 || !isTimeExceed(user)
                                      }
                                      style={{ width: "max-content" }}
                                    >
                                      <p
                                        style={{ width: "max-content" }}
                                        className="text-danger underline cursor-pointer text-sm"
                                      >
                                        Cancel Appointment
                                      </p>
                                    </button>
                                  )}
                              </div>
                            </div>
                          </div>
                        ))}
                      {appointmentlistData.length === 0 ? (
                        <div class=" w-full flex gap-5 flex-col justify-center min-h-[30vh] items-center text-center ">
                          <div className="">
                            <img
                              src={NodataImage}
                              alt="image"
                              className="w-[40px]"
                            />
                          </div>
                          <p className="text-center font-light item-center text-sm text-textGray">
                            No Appointments
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="md:w-[33%]">
                  <div className="bg-White p-5 rounded-lg common-shadow min-h-[14rem]">
                    <div className="flex justify-between items-center mb-3">
                      <h4 className="text-lg text-primaryColor font-medium mb-2">
                        Medicine Orders
                      </h4>
                    </div>
                    <div className="h-[14rem] overflow-y-scroll scrollWidth">
                      {medicineList?.length > 0 &&
                        medicineList
                          .sort((a, b) => {
                            return (
                              moment(b.createdOn, "yyyy-MM-DD HH:mm:ss") -
                              moment(a.createdOn, "yyyy-MM-DD HH:mm:ss")
                            );
                          })
                          .map((user, i) => (
                            <div className="w-full rounded-md p-4 my-2 bg-lightBlue">
                              <div className="flex justify-between gap-3">
                                {/* <div className=" text-right text-primaryColor text-sm md:text-base font-medium mt-1">
                         Number of items :{" "}
                         <span className="text-secondaryColor font-medium">
                           {user.totalOrderItems}
                         </span>
                       </div> */}
                                {/* <div className=" text-right text-primaryColor text-sm md:text-base font-medium mt-1">
                         <span className="text-secondaryColor font-medium">
                           Rs. {user.orderAmount.toFixed(2)}
                         </span>
                       </div> */}
                              </div>
                              <div className="flex justify-between text-textGray w-full gap-3 mt-4">
                                <div className="text-textGray text-xs md:text-sm">
                                  <label className="font-light">Order ID</label>
                                  <p className="font-medium mt-1">
                                    {user.patientOrderId}
                                  </p>
                                </div>
                                <div className="text-textGray text-xs md:text-sm">
                                  <label className="font-light">
                                    Placed On
                                  </label>
                                  <p
                                    style={{ width: "max-content" }}
                                    className="font-medium mt-1"
                                  >
                                    {user?.paymentDateTime?.substring(0, 10)}
                                  </p>
                                </div>
                                <div className="text-textGray text-xs md:text-sm">
                                  <label className="font-light">
                                    Schedule Delivery
                                  </label>
                                  <p className="font-medium mt-1">
                                    {user?.scheduledDelivery?.substring(0, 10)}
                                  </p>
                                </div>
                              </div>
                              <div className="flex gap-3">
                                <div className="flex gap-8 items-center font-medium mt-4">
                                  {user.prescriptionDocArray &&
                                    user.prescriptionDocArray.length > 0 &&
                                    user.prescriptionDocArray[0] !== "" && (
                                      <div
                                        onClick={() =>
                                          onPrescriptionOpenClick(user)
                                        }
                                        className="text-textGray underline text-xs md:text-sm cursor-pointer"
                                      >
                                        View Prescription
                                      </div>
                                    )}
                                  <div
                                    onClick={() => showModal1(user)}
                                    className="text-[#005D8D] underline text-xs md:text-sm cursor-pointer"
                                  >
                                    View Details
                                  </div>
                                  <div
                                    onClick={(e) => handleReorderClick(user)}
                                    className="text-secondaryColor underline text-xs md:text-sm cursor-pointer"
                                  >
                                    Reorder
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      {medicineList?.length == 0 && (
                        <div class=" w-full flex gap-5 flex-col justify-center min-h-[30vh] items-center text-center ">
                          <div className="">
                            <img
                              src={NodataImage}
                              alt="image"
                              className="w-[40px]"
                            />
                          </div>
                          <p className="text-center font-light item-center text-sm text-textGray">
                            No Medicine Orders
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="md:w-[33%]">
                  <div className="bg-White p-5 rounded-lg common-shadow min-h-[14rem]">
                    <div className="flex justify-between items-center mb-3">
                      <h4 className="text-lg text-primaryColor font-medium mb-2">
                        Diagnostics Appointments
                      </h4>
                    </div>
                    <div className="h-[14rem] overflow-y-scroll scrollWidth">
                      {list?.length > 0 &&
                        list
                          .sort((a, b) => {
                            return (
                              moment(b.createdOn, "yyyy-MM-DD HH:mm:ss") -
                              moment(a.createdOn, "yyyy-MM-DD HH:mm:ss")
                            );
                          })
                          .map((user, i) => (
                            <div className="w-full rounded-md p-4 my-2 bg-lightBlue mb-5">
                              <div className=" flex gap-5">
                                {/* <div className="w-[20%] h-full hidden md:block">
                         <img
                           src={
                             user.hospitalPhoto
                               ? process.env.REACT_APP_IMG_BASEURL +
                                 user.hospitalPhoto
                               : LabImage
                           }
                           alt="img"
                           className="h-[100px] w-full rounded-sm shadow-lg"
                         />
                       </div> */}
                                <div className="w-full">
                                  <div className="flex justify-between items-center">
                                    <div className="text-primaryColor font-semibold text-base md:text-lg w-full">
                                      {
                                        user?.patientLabTestsOrderDetailsList[0]
                                          ?.hospitalName
                                      }
                                    </div>
                                    <div className="text-secondaryColor bg-lightGreen px-3 py-1.5 rounded-full text-xs cursor-pointer">
                                      {user.procedureStatusName}
                                    </div>
                                  </div>
                                  <div className="flex text-textGray w-full justify-between gap-5 mt-4">
                                    <div className="text-textGray text-xs md:text-sm">
                                      <label className="font-light">
                                        Order ID
                                      </label>
                                      <p className="font-medium mt-1">
                                        {user.orderId}
                                      </p>
                                    </div>
                                    <div className="text-textGray text-xs md:text-sm">
                                      <label className="font-light">
                                        Placed On
                                      </label>
                                      <p className="font-medium mt-1">
                                        {moment(user?.paymentDateTime).format(
                                          "MM/DD/YYYY"
                                        )}
                                      </p>
                                    </div>
                                    <div className="text-textGray text-xs md:text-sm">
                                      <label className="font-light">
                                        Preferred Date
                                      </label>
                                      <p className="font-medium mt-1">
                                        {moment(user?.preferDateTime).format(
                                          "MM/DD/YYYY"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {user.patientLabTestsOrderDetailsList.map(
                                (user1, i) => (
                                  <div className="flex justify-between gap-5 mt-3">
                                    <div className="md:w-[75%] text-textGray text-xs md:text-sm font-medium mt-1">
                                      <span>{user1?.labTestName}</span>
                                    </div>
                                    <div className="w-[40%] md:w-[25%] text-right text-textGray text-sm font-light mt-1">
                                      <span className="text-secondaryColor font-medium text-base md:text-lg">
                                        ₹{" "}
                                        {parseFloat(user1?.discountAmount)
                                          ? user1?.discountAmount
                                          : user1?.amount}
                                      </span>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          ))}
                      {list?.length == 0 && (
                        <div class=" w-full flex gap-5 flex-col justify-center min-h-[30vh] items-center text-center ">
                          <div className="">
                            <img
                              src={NodataImage}
                              alt="image"
                              className="w-[40px]"
                            />
                          </div>
                          <p className="text-center font-light item-center text-sm text-textGray">
                            No Diagnosics Appointments
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

      {!isHide && (
        <RescheduleModal
          data={appointmentdetails}
          onClose={() => onRescheduleModelClose()}
        />
      )}

      {showCancelDialog ? (
        <CancelAppointmentPopup
          isErrorMsg={isErrorMsg}
          onCancel={confirmCancelAppointment}
          isLoading={isLoading}
          close={() => {
            setShowCancelDialog(false);
            setErrorMsg("");
          }}
        />
      ) : null}

      <Modal
        title="View Order details"
        className="commonModal"
        centered="true"
        open={modal1}
        onCancel={() => setModal1(false)}
        width="60%"
        footer={[]}
      >
        <div className="flex justify-between items-center mb-2 md:mb-3">
          <div>
            <p class="text-sm md:text-base font-medium mb-1 text-primaryColor">
              Number of items :{" "}
              <span className="text-secondaryColor">
                {currSelectedOrder?.totalOrderItems}
              </span>
            </p>
          </div>
          <div>
            <p class="text-sm md:text-base font-medium mb-1 text-primaryColor hidden md:block">
              Amount Paid :{" "}
              <span className="text-secondaryColor">
                ₹ {currSelectedOrder?.amount}
              </span>
            </p>
            <p class="text-sm md:text-base font-medium mb-1 text-primaryColor block md:hidden">
              <span className="text-secondaryColor">
                ₹ {currSelectedOrder?.amount}
              </span>
            </p>
          </div>
          <div className="hidden md:block">
            <div className="">
              <p className="text-sm font-medium bg-lightGreen px-5 py-1 rounded-full text-secondaryColor">
                <span>{currSelectedOrder?.orderStatus}</span>
              </p>
            </div>
            <div>
              {/* <p className="text-sm font-medium mb-4  text-gray-500">
                      Order ID: <span>REF637UND153851</span>
                    </p> */}
            </div>
          </div>
        </div>
        <div className="block md:hidden mb-3">
          <p className="text-sm font-medium bg-lightGreen px-5 py-1 rounded-full text-secondaryColor w-fit">
            <span>{currSelectedOrder?.orderStatus}</span>
          </p>
        </div>
        <div className="grid grid-cols-2 md:flex gap-3 justify-between ">
          <div className="mb-3">
            <p class="text-xs font-light mb-1 text-textGray">Order ID</p>
            <p class="text-xs md:text-sm font-medium text-textGray">
              {currSelectedOrder?.patientOrderId}
            </p>
          </div>
          <div className="mb-3">
            <p class="text-xs font-light mb-1 text-textGray">Placed On </p>
            <p class="text-xs md:text-sm font-medium text-textGray">
              {currSelectedOrder?.paymentDateTime}
            </p>
          </div>
          <div className="mb-3 hidden md:block">
            <p class="text-xs font-light mb-1 text-textGray">
              Scheduled Delivery
            </p>
            <p class="text-xs md:text-sm font-medium text-textGray">
              {currSelectedOrder?.scheduledDelivery}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 md:flex justify-between md:items-center">
          <div className="mb-3 md:hidden block">
            <p class="text-xs font-light mb-1 text-textGray">
              Scheduled Delivery
            </p>
            <p class="text-xs md:text-sm font-medium text-textGray">
              {currSelectedOrder?.scheduledDelivery}
            </p>
          </div>
          <div className="hidden md:block">
            <p class="text-xs font-light mb-1 text-textGray">
              Delivery Address
            </p>
            <p class="text-xs md:text-sm  mb-2 font-medium text-textGray">
              {currSelectedOrder?.deliveryAddress1}{" "}
              {currSelectedOrder?.deliveryAddress2},{" "}
              {currSelectedOrder?.deliveryCity},{" "}
              {currSelectedOrder?.deliveryState} -{" "}
              {currSelectedOrder?.deliveryPinCode}
            </p>
          </div>
          {/* <div className="flex justify-end md:block">
            <button class="text-White text-sm font-medium h-8 px-3 rounded-md bg-primaryColor">
              Reorder
            </button>
          </div> */}
        </div>
        <div className="md:hidden block">
          <p class="text-xs font-light mb-1 text-textGray">Delivery Address</p>
          <p class="text-xs md:text-sm  mb-2 font-medium text-textGray">
            {currSelectedOrder?.deliveryAddress1}{" "}
            {currSelectedOrder?.deliveryAddress2},{" "}
            {currSelectedOrder?.deliveryCity},{" "}
            {currSelectedOrder?.deliveryState} -{" "}
            {currSelectedOrder?.deliveryPinCode}
          </p>
        </div>
        <div className="mt-5 overflow-x-auto">
          <table className=" border-collapse border border-gray w-full h-full mx-auto ">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-400 p-2">ITEM NAME</th>
                <th className="border border-gray-400 p-2">QTY</th>
                <th className="border border-gray-400 p-2">UNIT PRICE</th>
                <th className="border border-gray-400 p-2">
                  UNIT DISCOUNTED PRICE
                </th>
                <th className="border border-gray-400 p-2">TOTAL AMOUNT</th>
                <th className="border border-gray-400 p-2">
                       STATUS
                      </th>
              </tr>
            </thead>
            <tbody>
              {currSelectedOrder?.orderDetails[0]?.refundableAmountForItems?.map(
                (drug, idx) => (
                  <tr key={idx}>
                    <td className="border border-gray-400 p-2">
                      {drug?.drugName}
                    </td>
                    <td className="border border-gray-400 p-2">
                      {drug?.quantity}
                    </td>
                    <td className="border border-gray-400 p-2">
                      ₹ {drug?.unitPrice}
                    </td>
                    <td className="border border-gray-400 p-2">
                      ₹ {drug?.discountAmount}
                    </td>
                    <td className="border border-gray-400 p-2">
                      ₹ {drug?.totalAmount}
                    </td>
                    <td className="border border-gray-400 p-2 bg-lightGreen text-secondaryColor">
                            {drug?.consignmentstatusname ? drug?.consignmentstatusname : "Yet to Create"}
                          </td>
                  </tr>
                )
              )}
              {currSelectedOrder?.orderDetails[0]?.orderItems?.map(
                (drug, idx) => (
                  <tr key={idx}>
                    <td className="border border-gray-400 p-2">
                      {drug?.drugName}
                    </td>
                    <td className="border border-gray-400 p-2">
                      {drug?.quantity}
                    </td>
                    <td className="border border-gray-400 p-2">
                      ₹ {drug?.unitPrice}
                    </td>
                    <td className="border border-gray-400 p-2">
                      ₹ {drug?.discountAmount}
                    </td>
                    <td className="border border-gray-400 p-2">
                      ₹ {drug?.totalAmount}
                    </td>
                    <td className="border border-gray-400 p-2 bg-lightGreen text-secondaryColor">
                            {drug?.consignmentstatusname ? drug?.consignmentstatusname : "Yet to Create"}
                          </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </Modal>
      <Modal
        title="Order Details"
        className="commonModal"
        centered="true"
        open={isDialogOpen}
        onCancel={() => setDialog(false)}
        footer={[]}
      >
        <div className="overflow-x-auto">
          <table className="border-collapse border border-gray w-full h-full mx-auto ">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-400 p-2">ITEM NAME</th>
                <th className="border border-gray-400 p-2">QTY</th>
                <th className="border border-gray-400 p-2">UNIT PRICE</th>
                {/* <th className="border border-gray-400 p-2">
                        UNIT DISCOUNTED PRICE
                      </th>
                      <th className="border border-gray-400 p-2">
                        TOTAL AMOUNT
                      </th> */}
              </tr>
            </thead>
            {reorderData.map((item, i) => {
              return (
                <>
                  <tbody>
                    <tr>
                      <td className="border border-gray-400 p-2">
                        {item.drugName}
                      </td>
                      <td className="border border-gray-400 p-2">
                        {item.quantity}
                      </td>
                      <td className="border border-gray-400 p-2">
                        ₹ {parseFloat(item.medicineRate).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </>
              );
            })}
          </table>
        </div>
        <div>
          <button
            disabled={addToCartLoading}
            onClick={(e) => addtoCart(e, cartData)}
            class="text-White text-sm font-semibold px-8 w-full mt-5 h-10 rounded-md bg-secondaryColor"
          >
            Add to cart
          </button>
        </div>
      </Modal>
      <Modal
        title={
          <p className="font-bold text-primaryColor text-xl">Prescription</p>
        }
        open={prescriptionDialog}
        onCancel={() => setPrescriptionDialog(false)}
        footer={[]}
        width="60%"
      >
        <div className="flex flex-col text-center justify-center">
          {prescriptionDoc?.length &&
            prescriptionDoc?.map((res) => {
              return (
                <div className="w-auto">
                  {res.split(".")[1].toUpperCase() == "PDF" ? (
                    <iframe
                      style={{ width: "100%", height: "500px" }}
                      src={`${process.env.REACT_APP_IMG_BASEURL}${res}`}
                      alt="profile image"
                    />
                  ) : (
                    <img
                      style={{ width: "100%", height: "500px" }}
                      src={`${process.env.REACT_APP_IMG_BASEURL}${res}`}
                      alt="profile image"
                    />
                  )}
                </div>
              );
            })}
        </div>
      </Modal>

      <Modal
         open={prescriptionView}
        width={900}
        footer={false}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <EprescriptionsView userID={userID} />
      </Modal>
</>
    )
}

export default Appointments