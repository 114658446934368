/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPatientfamilymembers } from "../../../../Redux/Action/UserprofileActions";
import {
  setPatientCode,
  setDropDownPatientCode,
} from "../../../../Redux/Action/userActions";
import { Select } from "antd";

function FamilyDropdown(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  const patientCode = useSelector((state) => state.authReducer.patientCode);
  const familymemberinfo = useSelector((state) => state.familymembers);
  const [currMemberCode, setCurrMemberCode] = useState(patientCode);

  const memberCode = useSelector((state) => state.particularpatientdetails);
  console.log(memberCode);

  const { FamilymembersData } = familymemberinfo;
  const [memberList, setMemberList] = useState([]);

  const addSelf = () => {
    let data = {};
    data.name = userData.firstName;
    data.code = userData.code;
    let members = FamilymembersData;
    console.log("sdisdhfiosdhf", JSON.stringify(members));
    console.log("sdisdhfiosdhuserDataf", JSON.stringify(userData));
    members.unshift(data);
    let uniqueObjArray = [
      ...new Map(members.map((item) => [item["firstName"], item])).values(),
    ];
    setMemberList(uniqueObjArray);
  };

  useEffect(() => {
    if (FamilymembersData) {
      addSelf();
    }
  }, [FamilymembersData]);

  const onChange = (code) => {
    console.log(code);
    if(  props.onSelect(code)){
    props.onSelect(code);}
    setCurrMemberCode(code);
    console.log(props?.target, "ePrescriptionname");
    if (props?.target !== "ePrescription") {
      dispatch(setDropDownPatientCode(code));
    }

    const selectedMember = memberList.find(member => member.code === code);
    if (selectedMember) {
      const dob = selectedMember.dob;
      const email = selectedMember.email;
      const mobile = selectedMember.mobile;
      const address1 = selectedMember.address1;
      const address2 = selectedMember.address2;
      const city = selectedMember.city;
      const state = selectedMember.state;
      console.log('selectedMember', selectedMember)
      if (props.onSelect) {
        props.onSelect(code, dob, email, mobile, address1, address2, state, city,selectedMember );
      }
    }
  };

  useEffect(() => {
    let code = userData.code
    onChange(code)
  }, []);

  useEffect(() => {
    console.log(patientCode, "sdfisdhiodsh");
    dispatch(getPatientfamilymembers(userData.code));
  }, []);

  // const gotoHistory = (e) => {
  //     e.preventDefault();
  //     history.push(USERPROFILE_ROUTES.MYDETAILS)
  // }

  return (
    <div className="w-full">
      <Select
        defaultValue={userData.firstName}
        placeholder={props.title}
        onChange={onChange}
        options={memberList.map((res, i) => ({
          value: res.code,
          label: res.name,
        }))}
        className={`${
          props.height ? props.height : "h-12"
        } w-full md:w-[200px] text-base`}
      >
        {/* {memberList.map((res, i) => (
                                <option key={i} className="py-1 px-2 rounded-lg text-sm text-secondaryColor" selected={i===0} value={res.code}>{res.name}</option>
                            ))} */}
      </Select>
    </div>
  );
}
export default FamilyDropdown;
