import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import moment from "moment";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import Order1Icon from '../../../../Assets/Images/Order section icons/Grey icons/Yet to Pack.svg';
import Order2Icon from '../../../../Assets/Images/Order section icons/Grey icons/Packed.svg';
import Order3Icon from '../../../../Assets/Images/Order section icons/Grey icons/Shipped.svg';
import Order4Icon from '../../../../Assets/Images/Order section icons/Grey icons/Order on the Bus.svg';
import Order5Icon from '../../../../Assets/Images/Order section icons/Grey icons/Out for Delivery.svg';
import Order6Icon from '../../../../Assets/Images/Order section icons/Grey icons/Delivered.svg';


import Order1IconGreen from '../../../../Assets/Images/Order section icons/Green icon/Yet to Pack.svg';
import Order2IconGreen from '../../../../Assets/Images/Order section icons/Green icon/Packed.svg';
import Order3IconGreen from '../../../../Assets/Images/Order section icons/Green icon/Shipped.svg';
import Order4IconGreen from '../../../../Assets/Images/Order section icons/Green icon/Order on the Bus.svg';
import Order5IconGreen from '../../../../Assets/Images/Order section icons/Green icon/Out for Delivery.svg';
import Order6IconGreen from '../../../../Assets/Images/Order section icons/Green icon/Delivered.svg';

// const QontoConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 10,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)',
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     borderColor: '#eaeaf0',
//     borderTopWidth: 3,
//     borderRadius: 1,
//     ...theme.applyStyles('dark', {
//       borderColor: theme.palette.grey[800],
//     }),
//   },
// }));

const QontoStepIconRoot = styled('div')(({ theme }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  '& .QontoStepIcon-square': {
    width: 8,
    height: 8,
    borderRadius: '8px',
    backgroundColor: 'currentColor',
  },
  ...theme.applyStyles('dark', {
    color: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        color: '#784af4',
      },
    },
  ],
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-square" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#59d897"
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#59d897"
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));
// #59d897
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.completed ? "" : '', 
  zIndex: 1,
  color: ownerState.completed ? "#00654D" : '#a1a1a1', 
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: ownerState.completed ? '0 4px 10px 0 rgb(213,245,229,.25)' : 'none', // Add shadow when completed
  ...theme.applyStyles('dark', {
    backgroundColor: ownerState.completed ? theme.palette.success.light : theme.palette.grey[700],
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  const icons = {
    1: <img src={ Order1IconGreen} alt="Order Step 1" style={{ width: 35, height: 35 }} />,
    2: <img src={(completed || active) ? Order2IconGreen : Order2Icon} alt="Order Step 2" style={{ width: 35, height: 35 }} />,
    3: <img src={(completed || active) ? Order3IconGreen : Order3Icon} alt="Order Step 3" style={{ width: 35, height: 35 }} />,
    4: <img src={(completed || active) ? Order4IconGreen : Order4Icon} alt="Order Step 4" style={{ width: 35, height: 35 }} />,
    5: <img src={(completed || active) ? Order5IconGreen : Order5Icon} alt="Order Step 5" style={{ width: 35, height: 35 }} />,
    6: <img src={(completed || active) ? Order6IconGreen : Order6Icon} alt="Order Step 6" style={{ width: 35, height: 35 }} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const intitalSteps = ["Yet to Pack", "Packed", "Shipped", "Order on Bus",  "Out for Delivery", "Delivered"]

export default function OrderTracking({trackingStatus}) {

useEffect(() =>{

},[])

if(trackingStatus == null){
return(
    <Stack sx={{ width: '100%' }} spacing={4} >
      <Stepper alternativeLabel activeStep={0} connector={<ColorlibConnector />}>
        {intitalSteps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
)
}

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={trackingStatus[trackingStatus.length - 1].consignmentStatus} connector={<ColorlibConnector />}>
        {intitalSteps.map((label , index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}><div>
                <div>{label}</div>
                {trackingStatus[index - 1]?.createdDate ? <div>{moment(trackingStatus[index]?.createdDate).format("hh:mm A")}</div> : null}
                </div></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
