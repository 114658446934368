import http from "../../Redux/Services/http-common";
const getconsentversion = async (code) => {
    let urls = [
      `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=PATIENT_B2C`,
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/list/filter?patientId=${code}&status=1&sourceChannel=B2C`
    ]
    let requests = urls.map(url => http.get(url));
    let consentVersion = {}
    let istcAccepted = false
    let isprivacyAccepted = false
    try {
        let responses = await Promise.allSettled(requests)
        console.log(responses, "sdviosdvisdgvisdvs");
        if(responses[1].status == 'fulfilled'){
            if((responses[1].value.data.data[0].privacyVersionNumber == responses[0].value.data.data[0].versionNumber) &&  responses[1].value.data.data[0].privacyPolicyConsent == 1){
                isprivacyAccepted = true
            }
            if(responses[1].value.data.data[0].tcVersionNumber == responses[0].value.data.data[1].versionNumber &&  responses[1].value.data.data[0].termsAndConditionConsent == 1){
                istcAccepted = true
            }
           
          }
           consentVersion = {
            privacyPdf: responses[0].value.data.data[0].consentFile,
            privacyVersion: responses[0].value.data.data[0].versionNumber,
            tcPdf: responses[0].value.data.data[1].consentFile,
            tcVersion: responses[0].value.data.data[1].versionNumber
      
          }
    } catch (error) {
        consentVersion = {}
    }

    return [consentVersion, istcAccepted, isprivacyAccepted]
    
//   .then(responses => {
//     let info = {
//       privacyPdf: responses[0].value.data.data[0].consentFile,
//       privacyVersion: responses[0].value.data.data[0].versionNumber,
//       tcPdf: responses[0].value.data.data[1].consentFile,
//       tcVersion: responses[0].value.data.data[1].versionNumber 
//     }
//     return info
//   }).catch(err =>{
//     // console.log(err, "sdvhsdouvdsuovds")
//     if(err?.status == 404){
//         return err
//     }
//   })
//   return result
};
export default getconsentversion;
