import React, { useState } from "react";
import Layouts from "../../../Layouts";
import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { useSelector } from "react-redux";
import SummaryPrescription from "./SummaryPrescription";
import SummaryMedicineOrder from "./SummaryMedicineOrder";
import SummaryLabReports from "./SummaryLabReports";
import SummaryReports from "./SummaryReports";
import SummaryMembership from "./SummaryMembership";
import SummaryVitals from "./SummaryVitals";
import SummaryMedicalHistory from "./SummaryMedicalHistory";
import FamilyDropdown from "./FamilyDropDown";

function MedicalSummary() {
  const userData = useSelector((state) => state.authReducer.patientData);
  const [selectPat, setSelectedPat] = useState(userData.code)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onSelect = (code) => {
    setSelectedPat(code)
  }

  return (
    <Layouts>
      <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="w-[90%] md:w-[80%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md px-5 md:px-8 py-3 md:py-5">
            <div className=" md:flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-White">
                Medical Summary
              </div>
              <div className="flex gap-4">
                <FamilyDropdown title={' Get Summary for'} onSelect={onSelect} height={"3rem"} />
              </div>
            </div>
          </div>
          <Row gutter={24} className="mt-5">
            <Col xs={24} sm={24} md={24} className="mb-5">
              <SummaryPrescription patCode={selectPat} />
            </Col>
            <Col xs={24} sm={24} md={12} className="mb-5">
              <SummaryLabReports patCode={selectPat} />
            </Col>
            <Col xs={24} sm={24} md={12} className="mb-5">
              <SummaryReports patCode={selectPat} />
            </Col>
            <Col xs={24} sm={24} md={12} className="mb-5">
              <SummaryMedicineOrder patCode={selectPat} />
            </Col>
            <Col xs={24} sm={24} md={12} className="mb-5">
              <SummaryMembership patCode={selectPat} />
            </Col>
            <Col xs={24} sm={24} md={24} className="mb-5">
              <div className="bg-White rounded-md">
                <div className="bg-[#ffe1e1] px-5 py-3 rounded-md flex justify-between items-center">
                  <h5 className=" text-lg font-medium">Medical History</h5>
                </div>
                <Row gutter={24} className="p-5">
                  <SummaryMedicalHistory patCode={selectPat} />
                </Row>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} className="mb-5">
              <SummaryVitals patCode={selectPat} />
            </Col>
          </Row>
        </div>
      </section>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Allergy Details
          </p>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Record on" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="Select date"
            />
          </Form.Item>
          <Form.Item label="Drug Allergies" name="email">
            <Input placeholder="Enter Drug Allergies" />
          </Form.Item>

          <Form.Item label="Food Allergies" name="phone">
            <Input placeholder="Enter Food Allergies " />
          </Form.Item>
          <Form.Item label="Chemical Allergies" name="subject">
            <Input placeholder="Enter Chemical Allergies" />
          </Form.Item>

          <Form.Item>
            <button
              onClick={handleCancel}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form>
      </Modal>
    </Layouts>
  );
}

export default MedicalSummary;
