export const pat = {
   
    REQUEST_PATIENTAPPOINTMENT: "REQUEST_PATIENTAPPOINTMENT",
    SUCCESS_PATIENTAPPOINTMENT: "SUCCESS_PATIENTAPPOINTMENT",
    FAILED_PATIENTAPPOINTMENT: "FAILED_PATIENTAPPOINTMENT",

    REQUEST_PATIENTINFO: "REQUEST_PATIENTINFO",
    SUCCESS_PATIENTINFO: "SUCCESS_PATIENTINFO",
    FAILED_PATIENTINFO: "FAILED_PATIENTINFO",
    
    REQUEST_PARTICULARPATIENTDETAILSBYNUMBER: "REQUEST_PARTICULARPATIENTDETAILSBYNUMBER",
    SUCCESS_PARTICULARPATIENTDETAILSBYNUMBER: "SUCCESS_PARTICULARPATIENTDETAILSBYNUMBER",
    FAILED_PARTICULARPATIENTDETAILSBYNUMBER: "FAILED_PARTICULARPATIENTDETAILSBYNUMBER",
    
    REQUEST_LOGGINCHEPATIENTDETAILS: "REQUEST_LOGGINCHEPATIENTDETAILS",
    SUCCESS_LOGGINCHEPATIENTDETAILS: "SUCCESS_LOGGINCHEPATIENTDETAILS",
    FAILED_LOGGINCHEPATIENTDETAILS: "FAILED_LOGGINCHEPATIENTDETAILS",

    REQUEST_PATIENTVITALSDETAILS: "REQUEST_PATIENTVITALSDETAILS",
    SUCCESS_PATIENTVITALSDETAILS: "SUCCESS_PATIENTVITALSDETAILS",
    FAILED_PATIENTVITALSDETAILS: "FAILED_PATIENTVITALSDETAILS",

    REQUEST_PATIENTAPPOINTMENTLIST: "REQUEST_PATIENTAPPOINTMENTLIST",
    SUCCESS_PATIENTAPPOINTMENTLIST: "SUCCESS_PATIENTAPPOINTMENTLIST",
    FAILED_PATIENTAPPOINTMENTLIST: "FAILED_PATIENTAPPOINTMENTLIST",

    
    REQUEST_PATIENTMEDICINEDELIVERYLIST: "REQUEST_PATIENTMEDICINEDELIVERYLIST",
    SUCCESS_PATIENTMEDICINEDELIVERYLIST: "SUCCESS_PATIENTMEDICINEDELIVERYLIST",
    FAILED_PATIENTMEDICINEDELIVERYLIST: "FAILED_PATIENTMEDICINEDELIVERYLIST",

    REQUEST_PATIENTDIAGNOSTICSAPPOINTMENTLIST: "REQUEST_PATIENTDIAGNOSTICSAPPOINTMENTLIST",
    SUCCESS_PATIENTDIAGNOSTICSAPPOINTMENTLIST: "SUCCESS_PATIENTDIAGNOSTICSAPPOINTMENTLIST",
    FAILED_PATIENTDIAGNOSTICSAPPOINTMENTLIST: "FAILED_PATIENTDIAGNOSTICSAPPOINTMENTLIST",



    REQUEST_APPOINTMENTLIST: "REQUEST_APPOINTMENTLIST",
    SUCCESS_APPOINTMENTLIST: "SUCCESS_APPOINTMENTLIST",
    FAILED_APPOINTMENTLIST: "FAILED_APPOINTMENTLIST",

    REQUEST_UPCOMINGAPPOINTMENTLIST: "REQUEST_UPCOMINGAPPOINTMENTLIST",
    SUCCESS_UPCOMINGAPPOINTMENTLIST: "SUCCESS_UPCOMINGAPPOINTMENTLIST",
    FAILED_UPCOMINGAPPOINTMENTLIST: "FAILED_UPCOMINGAPPOINTMENTLIST",

    REQUEST_HOSPITALCLINICLIST: "REQUEST_HOSPITALCLINICLIST",
    SUCCESS_HOSPITALCLINICLIST: "SUCCESS_HOSPITALCLINICLIST",
    FAILED_HOSPITALCLINICLIST: "FAILED_HOSPITALCLINICLIST",

    REQUEST_CHELOCATIONLIST: "REQUEST_CHELOCATIONLIST",
    SUCCESS_CHELOCATIONLIST: "SUCCESS_CHELOCATIONLIST",
    FAILED_CHELOCATIONLIST: "FAILED_CHELOCATIONLIST",

    REQUEST_PATIENTCHELOCATIONMAPPING: "REQUEST_PATIENTCHELOCATIONMAPPING",
    SUCCESS_PATIENTCHELOCATIONMAPPING: "SUCCESS_PATIENTCHELOCATIONMAPPING",
    FAILED_PATIENTCHELOCATIONMAPPING: "FAILED_PATIENTCHELOCATIONMAPPING",

    REQUEST_GETSIMPLECRM: "REQUEST_GETSIMPLECRM",
    SUCCESS_GETSIMPLECRM: "SUCCESS_GETSIMPLECRM",
    FAILED_GETSIMPLECRM: "FAILED_GETSIMPLECRM",

    REQUEST_PARTICULARPATIENTDETAILS: "REQUEST_PARTICULARPATIENTDETAILS",
    SUCCESS_PARTICULARPATIENTDETAILS: "SUCCESS_PARTICULARPATIENTDETAILS",
    FAILED_PARTICULARPATIENTDETAILS: "FAILED_PARTICULARPATIENTDETAILS",

    REQUEST_ALLREGISTEREDPATIENTLIST: "REQUEST_ALLREGISTEREDPATIENTLIST",
    SUCCESS_ALLREGISTEREDPATIENTLIST: "SUCCESS_ALLREGISTEREDPATIENTLIST",
    FAILED_ALLREGISTEREDPATIENTLIST: "FAILED_ALLREGISTEREDPATIENTLIST",
    
    REQUEST_PATIENTENQUIREDETAILS: "REQUEST_PATIENTENQUIREDETAILS",
    SUCCESS_PATIENTENQUIREDETAILS: "SUCCESS_PATIENTENQUIREDETAILS",
    FAILED_PATIENTENQUIREDETAILS: "FAILED_PATIENTENQUIREDETAILS",

    CODE: "CODE"
}