import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const Gynecomastia = ({data}) =>{
    console.log(data, "sdvkjdsiuvdsbgvdsvds")
    return(
        <>
          {data?.seventhComp ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#F0F7FF" }}
        >
          <div className="container mx-auto text-center">
            <h2
              className="text-2xl md:text-3xl font-bold mb-8"
              style={{ color: "rgb(0, 93, 141)" }}
            >
              {data?.seventhComp?.title}
            </h2>
            <h6 className=" mb-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}} >{data?.seventhComp.description}</h6>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4 md:gap-8">
              {data?.seventhComp?.points.map(
                (cause, index) => (
                  <div
                    className="p-4 rounded-lg"
                    key={index}
                    style={{backgroundColor: "#ffff"}}
                  >
                    <img
                      src={cause.icon_url} // You can dynamically change the icon if icon_url is available
                      // alt={cause.description}
                      className="mx-auto mb-4"
                    />
                    <p
                      className="text-sm md:text-base font-medium"
                      style={{ color: "rgba(0, 65, 113, 1)" }}
                    >
                      {cause.description}
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}

{data?.sixthComp ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div
            className="container mx-auto"
            style={{ color: "rgb(0, 93, 141)" }}
          >
            <h2 className="text-2xl md:text-2xl font-bold mb-8 text-center">
              {/* {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.thirdComp?.heading,
                surgeryData?.attributes?.surgeryName
              )} */}
              {data?.sixthComp?.points[0]?.mainTitle}
            </h2>
            {data?.sixthComp?.points[0]?.maindescription && <h6 className=" mb-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}} >{data?.sixthComp?.points[0]?.maindescription}</h6>}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-0 md:mx-16">
              {data?.sixthComp?.points.map((type, index) => {
                return (<div className="rounded-xl py-4 px-8" style={{border: "0.5px solid #E3E3E3"}} >
                <p className="text-xl font-semibold mb-2" style={{color:"#005D8D"}} >{type.title}</p>
                <p className="text-base font-medium" style={{color:"#676C80"}}>{type.description}</p>
                <ul style={{listStyleType: "disc"}} >
                {type?.sub_points?.length ? type.sub_points.map(res =>{
                  return <li className="text-base font-medium" style={{color:"#676C80"}}> {res.description}</li>
                }) : null}
              </ul>
                </div>)
              })}
            </div>
          </div>
          <h4 className=" my-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}}>{data?.sixthComp?.points[0]?.mainnotes}</h4>
        </div>
      ) : (
        <p></p>
      )}
             {
                data.eightComp?.points?.length ? <div className="mt-8 md:mt-16 px-4 md:px-8" style={{backgroundColor:"#004171"}} >
                    {
                        data.eightComp?.points.map(res =>{
                            return(
                                <div className="grid md:grid-cols-2 py-4 md:py-8">
                                    <div> <p className="p-8 text-xl md:text-2xl font-semibold" style={{color:"#ffff"}} >{res.title}</p> </div>
                                    <div>
                                    {res.description && <p className="p-8 text-md" style={{color:"#ffff"}} >{res.description}</p>}
                    <ul className="ml-6 md:ml-12" style={{listStyleType: "disc",color:"#ffff"}} >
                          {res?.sub_points?.map(res =>{
                            return <li className="mb-4" ><p className="text-md font-medium" style={{color:"#ffff"}}>{res.description}</p></li>
                          })}
                        </ul>
                        <h4 className="text-sm font-regular my-2" style={{color: "#ffff"}} >{data.eightComp?.points[0].mainnotes}</h4>
                         </div>
                          
                    </div>
                            )
                        })
                    }
                </div> : null
              }
               {
                data.tenthcomp?.length ? <div style={{backgroundColor:"#EBF8FF"}} >
                    {
                        data.tenthcomp?.map(res =>{
                            return(
                                <div className="grid md:grid-cols-2 py-4 md:py-8" style={{borderBottom: "dashed"}} >
                                    <div> <p className="p-8 text-xl md:text-2xl font-medium" style={{color:"#004171"}} >{res.title}</p> </div>
                                    <div>
                                    {res.description && <p className="p-8" style={{color:"#004171", fontSize: 20, fontWeight: 400}} >{res.description}</p>}
                    <ul className="ml-6 md:ml-12" style={{listStyleType: "disc",color:"#004171"}} >
                          {res?.points?.map(res =>{
                            if(res?.title)
                            return <li className="mb-2" ><p className="text-md font-medium" style={{color:"#004171"}}>{res?.title}</p></li>
                          })}
                        </ul>
                         </div>
                   
                    </div>
                            )
                        })
                    }
                </div> : null
              }
                 {
                data.ninthComp?.points?.length ? <div className="py-12 px-4 md:px-8" >
                    <h4 style={{color:"#004171" }} className="text-center mb-4 text-xl md:text-2xl font-semibold" >{data.ninthComp.title}</h4>
                    <h4 style={{color:"#676C80" }} className="text-center mb-4 text-base md:text-lg font-medium" >{data.ninthComp.description}</h4>
                    <div className="grid md:grid-cols-2" >
                    {
                        data.ninthComp?.points?.map((res , index) =>{
                            return( <div className="p-8"> 
                             <div className="flex items-start relative" > 
                            <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                            <p className="text-lg font-medium" style={{color:"#676C80"}} >{res.description}</p>
                        </div>

                            </div>
                   
                            )
                        })
                    }
                    </div>
                    <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.ninthComp?.points[0].mainnotes}</h4>
                </div> : null
              }
              </>
    )
}

export default Gynecomastia