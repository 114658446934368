import React, { useEffect, useState } from "react";
import Layouts from "../../../Layouts";
import { DatePicker, Form, Input, Modal, Select } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import FilterIcon from "../../../../Assets/Images/ReportsFilter.svg";
import { Col, Row } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useSelector } from "react-redux";
import LabServices from "../../../../Redux/Services/labServices";
import { getLocalTime } from "../../../Helper/LocalTimeFormat";
import FamilyDropdown from "./FamilyDropDown";
import { DICOM_URL } from "../../../../config/constant";
import NodataImage from "../../../../Assets/Images/Icons/nodata.svg";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import doctorService from "../../../../Redux/Services/doctorService";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Document, Page } from "react-pdf";
import { CheckCircleOutlined } from '@ant-design/icons';
import { CheckOutlined } from '@ant-design/icons';
import moment from "moment";
function MyLabReports() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [labReportList, setLabReport] = useState([]);
  const patientCode = useSelector((state) => state.authReducer.patientCode);
  const [filterPopup, setFilterPopup] = useState(false);
  const [diagnositcDs, setdiagnositcDs] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [filePopup, setfilePopup] = useState(false);
  const [dicomToken, setDicomToken] = useState("");
  const [dicomURL, setdicomURL] = useState("");
  const [dicomPopup, setdicomPopup] = useState(false);
  const [selectedLabs, setSelectedLabs] = useState([]);
  const [fromDate, setFromDate] = useState(null);
const [toDate, setToDate] = useState(null);

  const [selectedLabReportForAi, setSelectedLabReportForAi] = useState({
    reportUrl: "",
    reportExtType: "",
    orderId: "",
  });
  const userData = useSelector((state) => state.authReducer.patientData);

  const history = useHistory();
  const location = useLocation(); // Get the location object from the router
  const queryParams = new URLSearchParams(location.search); // Create a URLSearchParams object with the query string

  // Extracting individual query parameters
  const referrer = queryParams.get("referrer");
  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push("/", {isLoginModalOpen : true});
    }
  }, []);

  useEffect(() => {
    const payload = {
      patientId: patientCode, 
      docmentRequired: "Y",
      ...(fromDate && { fromDate: moment(fromDate).format('YYYY-MM-DD') }), 
    ...(toDate && { toDate: moment(toDate).format('YYYY-MM-DD') }), 
      // reportType: "Lab"
    };
    loadLabOrders(payload);
    loadDiagnosticCenter();
    //   getDicomToken();
  }, [fromDate,toDate]);

  console.log(fromDate,toDate,"edrtghuj")

  const loadLabOrders = (payload) => {
    LabServices.getLabReportDetails(payload).then(
      (res) => {
        console.log(res);
        setFilterPopup(false);
        if (res.data) {
          setLabReport(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const loadDiagnosticCenter = () => {
    const payload = { status: 1, type: "D" };
    LabServices.getDiagnosticCenter(payload).then(
      (res) => {
        console.log(res);
        if (res.data) {
          setdiagnositcDs(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const loadLabReportFor = (patient) => {
    const payload = {
      patientId: patient, //localStorage.getItem("patientprofile"),
      docmentRequired: "Y",
      // reportType: "Lab"
    };
    loadLabOrders(payload);
  };

  const viewDicomFile = (payload) => {
    const requestOptions = {
      method: "POST",
      headers: { Authorization: "Bearer " + dicomToken },
      // body: JSON.stringify({
      //   username: 'integration',
      //   password: 'integration'
      // })
    };
    fetch(DICOM_URL + "viewer/" + payload, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setdicomURL(data.details);
        setdicomPopup(true);
        // setDicomToken(data.token);
      });
  };

  const viewOrder = (e, lab) => {
    e.stopPropagation();
    setSelectedRow(lab);
    let type =
      lab.type.toLowerCase() === "pdf"
        ? "application/pdf"
        : lab.type.toLowerCase() === "jpg"
        ? "image/jpeg"
        : lab.type.toLowerCase() === "jpeg"
        ? "image/jpeg"
        : lab.type.toLowerCase() === "png"
        ? "image/png"
        : lab.type;
    if (lab.type.toLowerCase() == "dicom") {
      viewDicomFile(lab.dicomId);
    } else {
      window
        .open(process.env.REACT_APP_IMG_BASEURL + lab.photoName, "_blank")
        .focus();
      // setfilePopup(true);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal2 = () => {
    setModal2Open(true);
  };

  const handleCancel2 = () => {
    setModal2Open(false);
  };

  const showModal3 = () => {
    setModal3Open(true);
  };
  const handleCancel3 = () => {
    setModal3Open(false);
  };

  const handleFilter = () => {
    const payload = {
      patientId: patientCode, // Assuming `patientCode` is already defined
      docmentRequired: "Y",
      ...(fromDate ? { fromDate } : {}), // Include fromDate if it exists
      ...(toDate ? { toDate } : {}),    // Include toDate if it exists
    };
  
    console.log('Filter Payload:', payload); // Debugging log
    loadLabOrders(payload);
    setModal3Open(false);
  };

  const onDownloadDocument = async (name) => {
    let url = selectedRow.photoName
      ? `${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
      : "";
    let filename = selectedRow.type;

    doctorService.imagetoData(selectedRow.photoName).then((res) => {
      var base64;
      if (filename === "application/pdf") {
        base64 = "data:application/pdf;base64,";
      } else {
        base64 = "data:image/png;base64,";
      }
      //alert(JSON.stringify(res.data.fileData))
      var FileSaver = require("file-saver");
      FileSaver.saveAs(base64 + res.data.fileData, selectedRow.photoName);
    });
    console.log(selectedRow.photoName, "selectedRow.photoName");
    setfilePopup(false);
  };

  const onCancelDocument = () => {
    setfilePopup(false);
  };

  const renderDocumentFooter = (name) => {
    return (
      <div>
        <button
          onClick={() => onDownloadDocument(name)}
          className="bg-primaryColor  text-sm text-White font-normal rounded-md py-3 px-3 mr-2"
        >
          Download
        </button>

        <button
          onClick={() => onCancelDocument(name)}
          className="bg-primaryColor  text-sm text-White font-normal rounded-md py-3 px-3 mr-2"
        >
          Cancel
        </button>
      </div>
    );
  };

  const handleLabContainerClick = (e, labDetails) => {
    console.log(labDetails, e, "sadvdsuudsvgiusdgvisudvds");
    e.stopPropagation();
    if (referrer == "care-sathi") {
      if (
        labDetails.type.toLowerCase() !== "pdf" &&
        labDetails.type.toLowerCase() !== "jpg" &&
        labDetails.type.toLowerCase() !== "jpeg" &&
        labDetails.type.toLowerCase() !== "png"
      ) {
      } else {
        let type =
          labDetails.type.toLowerCase() === "pdf"
            ? "application/pdf"
            : labDetails.type.toLowerCase() === "jpg"
            ? "image/jpeg"
            : labDetails.type.toLowerCase() === "jpeg"
            ? "image/jpeg"
            : labDetails.type.toLowerCase() === "png"
            ? "image/png"
            : labDetails.type;
        const query = new URLSearchParams({
          reportUrl: process.env.REACT_APP_IMG_BASEURL + labDetails.photoName,
          reportExtType: type,
          orderId: labDetails.orderId,
          lab_report_analyser: true,
          popup: false,
        }).toString();
        history.push(`/ask-care-sathi?${query}`);
      }
    }
  };

  console.log(selectedLabReportForAi, "labReportList");

 
  const handleCheckboxChange = (e, labDetails) => {
    const isChecked = e.target.checked;
   
    const newSelectedLabs = isChecked
      ? [...selectedLabs, labDetails]
      : selectedLabs.filter(item => item.orderId !== labDetails.orderId);
    setSelectedLabs(newSelectedLabs);
   
    if (isChecked && referrer === "care-sathi") {
    
      setTimeout(() => {
        if (
          labDetails.type.toLowerCase() === "pdf" ||
          labDetails.type.toLowerCase() === "jpg" ||
          labDetails.type.toLowerCase() === "jpeg" ||
          labDetails.type.toLowerCase() === "png"
        ) {
          let type =
            labDetails.type.toLowerCase() === "pdf"
              ? "application/pdf"
              : labDetails.type.toLowerCase() === "jpg"
              ? "image/jpeg"
              : labDetails.type.toLowerCase() === "jpeg"
              ? "image/jpeg"
              : labDetails.type.toLowerCase() === "png"
              ? "image/png"
              : labDetails.type;
          const query = new URLSearchParams({
            reportUrl: process.env.REACT_APP_IMG_BASEURL + labDetails.photoName,
            reportExtType: type,
            orderId: labDetails.orderId,
            lab_report_analyser: true,
            popup: false,
          }).toString();
          history.push(`/ask-care-sathi?${query}`);
        }
      }, 100); 
    }
  };

  return (
    <Layouts>
      {/* {referrer === "care-sathi" && (
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Alert severity="info">Please Select a Lab Report to Analyze</Alert>
        </Stack>
      )} */}
      <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="w-[80%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
            <div className=" md:flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-White">
                My Lab Reports
              </div>
              <div className="hidden md:flex gap-2">
                <div
                  className="bg-lightBlue rounded-md md:w-12 w-4 md:h-12 h-8  flex items-center cursor-pointer"
                  onClick={showModal3}
                >
                  <img src={FilterIcon} alt="Filter Icon" className="mx-auto" />
                </div>
                <FamilyDropdown
                  title={"Get lab reports for"}
                  onSelect={loadLabReportFor}
                />
              </div>
            </div>
          </div>

          <div className="flex gap-2 md:hidden mt-5">
            {/* <Select
              placeholder="Get reports for"
              className="h-12 w-full border border-gray rounded-md"
            ></Select> */}
            <FamilyDropdown
              title={"Get lab reports for"}
              onSelect={loadLabReportFor}
            />
            <div
              className="bg-White rounded-md w-12 h-12 flex items-center cursor-pointer"
              onClick={showModal3}
            >
              <img src={FilterIcon} alt="Filter Icon" className="mx-auto" />
            </div>
          </div>
          {referrer === "care-sathi" && (
        <Stack sx={{ width: "100%",marginTop:'10px' }} spacing={4}>
          <Alert severity="info">Please click on the checkbox to analyze the report</Alert>
        </Stack>
      )}
  
<Row gutter={24} className="mt-5">
      {labReportList.length > 0 &&
        labReportList
          .filter((res) => res.orderId.length > 0)
          .map((lab, idx) => (
            <Col xs={24} sm={24} md={8} key={idx}>
              <div className=" group ">
                {/* Checkbox - Shown only if referrer === "care-sathi" */}
               
                <button
                  onClick={(e) => handleLabContainerClick(e, lab)}
                  className="relative w-full rounded-md p-4 my-2 bg-White relative shadow-md hover:border-primaryColor hover:border"
                >
                  {referrer === "care-sathi" && (
                  <label className="absolute top-2 right-2 z-10 cursor-pointer pt-2">
                    <input
                      type="checkbox"
                      className="hidden"
                      onChange={(e) => handleCheckboxChange(e, lab)}
                      checked={selectedLabs.some(item => item.orderId === lab.orderId)}
                    />
                    <span className="w-5 h-5 inline-block border-2 border-gray rounded-md bg-White">
                      {selectedLabs.some(item => item.orderId === lab.orderId) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 text-secondaryColor font-bold"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M5 12l5 5L20 7" />
                        </svg>
                      )}
                    </span>
                  </label>
                )}
                  <div className="text-primaryColor font-semibold text-lg w-full">
                    {lab.labTestName}
                  </div>
                  <div className="flex text-textGray w-full justify-between gap-3 mt-4">
                    <div className="text-textGray text-xs">
                      <label className="font-light">Order ID</label>
                      <p className="font-medium mt-1">{lab.orderId}</p>
                    </div>
                    <div className="text-textGray text-xs">
                      <label className="font-light">Date</label>
                      <p className="font-medium mt-1">
                        {getLocalTime(lab.createdDate)}
                      </p>
                    </div>
                    <div className="text-textGray text-xs">
                      <label className="font-light">Location</label>
                      <p className="font-medium mt-1">{lab.locationName}</p>
                    </div>
                  </div>
                  <div className="flex justify-between items-end mt-4">
                    <div className="text-textGray text-xs">
                      <label className="font-light">Diagnostic</label>
                      <p className="font-medium mt-1">{lab.hospitalName}</p>
                    </div>
                    <div
                      className="text-secondaryColor underline text-sm cursor-pointer"
                      onClick={(e) => viewOrder(e, lab)}
                    >
                      View Document
                    </div>
                  </div>
                </button>
              </div>
            </Col>
          ))}
      {labReportList && labReportList.length === 0 && (
        <div className="w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center">
          <div className="">
            <img src={NodataImage} alt="No data" className="w-[60px]" />
          </div>
          <p className="text-center font-light item-center text-base text-textGray">
            No lab reports available!
          </p>
        </div>
      )}
    </Row>
  
        </div>
      </section>
      <Modal
        title="Edit Lab Report"
        className="commonModal"
        centered={true}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="50%"
      >
        <Form
          className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item label="Date" name="date">
            <Input placeholder="Record on" />
          </Form.Item>
          <Form.Item label="Title *" name="email">
            <Input placeholder="Input placeholder" />
          </Form.Item>

          <Form.Item label="Document Type *" name="phone">
            <Input placeholder="Choose document type" />
          </Form.Item>
          <Form.Item label="Notes" name="subject">
            <Input placeholder="Enter Notes" />
          </Form.Item>
          <Form.Item>
            <Dragger className="h-5">
              <p className="ant-upload-drag-icon ">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">Upload Files </p>
              <p className="ant-upload-text">
                Click to add or drag & drop files
              </p>
            </Dragger>
          </Form.Item>

          <Form.Item>
            <button
              onClick={handleCancel}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Submit
            </button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={
          <p className="font-semibold text-primaryColor text-xl">
            View Lab Reports
          </p>
        }
        open={modal2Open}
        onOk={showModal2}
        onCancel={handleCancel2}
        style={{ top: 20 }}
        footer={[]}
      >
        <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Date" name="date">
            <Input placeholder="Record on" />
          </Form.Item>
          <Form.Item label="Title *" name="email">
            <Input placeholder="Input placeholder" />
          </Form.Item>

          <Form.Item label="Document Type *" name="phone">
            <Input placeholder="Choose document type" />
          </Form.Item>
          <Form.Item label="Notes" name="subject">
            <Input placeholder="Enter Notes" />
          </Form.Item>
          <Form.Item>
            <Dragger className="h-5">
              <p className="ant-upload-drag-icon ">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">Upload Files </p>
              <p className="ant-upload-text">
                Click to add or drag & drop files
              </p>
            </Dragger>
          </Form.Item>

          <Form.Item>
            <button
              onClick={handleCancel2}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Submit
            </button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Filter Lab Reports"
        className="commonModal"
        centered={true}
        footer={false}
        open={modal3Open}
        onOk={showModal3}
        onCancel={handleCancel3}
        width="50%"
      >
        <Form
          className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
          layout="vertical"
          autoComplete="off"
        >
        <Form.Item
      className="mb-0"
      label="From Date"
      name="fromDate"
      rules={[
        { required: true, message: 'Please select From Date' },
      ]}
    >
      <DatePicker
        placeholder="Select From Date"
        style={{ width: '100%' }}
        onChange={(date, dateString) => setFromDate(dateString)} 
      />
    </Form.Item>

    <Form.Item
      className="mb-0"
      label="To Date"
      name="toDate"
      rules={[
        { required: true, message: 'Please select To Date' },
      ]}
    >
      <DatePicker
        placeholder="Select To Date"
        style={{ width: '100%' }}
        onChange={(date, dateString) => setToDate(dateString)} 
      />
    </Form.Item>
  </Form>
        <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
          <button
            onClick={handleCancel3}
            className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
          >
            Cancel
          </button>
          <button
            onClick={handleFilter}
            className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
          >
            Filter
          </button>
        </div>
      </Modal>

      <Modal
        title={
          <p className="font-bold text-primaryColor text-xl">Lab Report</p>
        }
        visible={filePopup}
        modal={false}
        width="60%"
        onCancel={() => setfilePopup(false)}
        // style={{ width: "98vw", height: "100vh" }}

        footer={renderDocumentFooter("displayModal")}
        // onHide={() => onCancelDocument("displayModal")}
      >
        <p className="p-m-0">
          <div className="lg:flex pt-2 ">
            <div className="w-full">
              <div className="lg:flex lg:pt-1 g:space-x-10 ">
                {selectedRow?.type !== "application/pdf" ? (
                  <img
                    style={{ width: "100%", height: "500px" }}
                    className="box target object-contain"
                    src={
                      selectedRow.photoName
                        ? `${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
                        : ""
                    }
                    title="Dicom Viewer"
                    type={selectedRow.type}
                  />
                ) : (
                  <iframe
                    id="abc"
                    className="box target w-full h-auto"
                    style={{ width: "100%", height: "500px" }}
                    src={
                      selectedRow.photoName
                        ? `${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
                        : ""
                    }
                    title="Dicom Viewer"
                    type={selectedRow.type}

                    // style={iframeStyleLab}
                  />
                )}
              </div>
            </div>
          </div>
        </p>
      </Modal>
    </Layouts>
  );
}

export default MyLabReports;
