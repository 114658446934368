import { useDispatch, useSelector } from 'react-redux';
// import more from '../../Assets/Images/More .svg';
// import hearts from '../../Assets/Images/heart.svg';
// import analytics from '../../Assets/Images/analytics.png';
// import oxy from '../../Assets/Images/oxygenn.svg';
import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
import VitalChart from '../VitalChart';
// import Lungs from "../../Assets/Images/lungs.png";
import PulseOx from "../../../../../Assets/Images/PulseOx.png";
import Vector from "../../../../../Assets/Images/Vector.png";
import { FiMoreVertical } from 'react-icons/fi';
// import EditVitals from './EditVitals';
import { Dropdown, Modal, Space } from 'antd';
import { addPatientvitals } from '../../../../../Redux/Action/userprofileVitalActions';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';



function Resporatoryratecard(props) {


    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    const [showviewall, setshowviewall] = useState(false);
    const [showentervitals, setshowentervitals] = useState(false);
    const [showremovevitals, setshowremovevitals] = useState(false);
    const [spo2Rate, setSpo2Rate] = useState();
    const [data, setData] = useState();
    const [graphPop, setGraphPop] = useState(false);

    const viewDetails = (event, det) => {
        event.preventDefault();
        console.log(det)
        setData(det)
        setshowviewall(true)
        setshowentervitals(false)
        setshowremovevitals(false)
    }

    const savevitals = () => {
        console.log(spo2Rate);
        if (spo2Rate) {
            let newVitals = { ...props.data,spo2:spo2Rate };
            newVitals.createdDate = moment().format("yyyy-MM-DD HH:mm:ss");
            newVitals.updatedDate = moment().format("yyyy-MM-DD HH:mm:ss");
            newVitals.givenDate = moment().format("yyyy-MM-DD HH:mm:ss");
            newVitals.patientCode = props?.data?.patientCode;
            newVitals.createdBy = props?.data?.createdBy;
            newVitals.updatedBy = props?.data?.updatedBy;
            dispatch(addPatientvitals(newVitals)).then(res => {
                saveSuccess();
                props.fetchAllVitals(props.activePatient)
                setSpo2Rate('');
                setTimeout(() => {
                    toast('Vitals updated successfully');

                }, 300);
            }).catch(err => {
                console.log("Error");
            })
        }
    }


    const addvitals = (event, det) => {
        event.preventDefault();
        console.log(det)
        setData(det)
        setshowviewall(false)
        setshowremovevitals(false)
        setshowentervitals(true)


    }


    const removevitals = (event, det) => {
        event.preventDefault();
        console.log(det)
        setData(det)
        setshowviewall(false)
        setshowentervitals(false)
        setshowremovevitals(true)

    }

    const saveSuccess = (e) => {
        console.log("save success");
        setshowentervitals(false);
        props.saveCallback(e);
    };

    const items = [
        {
            label: <p className='relative' onClick={addvitals}>Update</p>,
            key: 0,
        }]


    return (
        <>
            {
                // props?.data?.slice(0, 1).map((user, i) => (
                <>
                    <div className="w-full bg-White p-5 rounded-md">
                    <ToastContainer />
                        <div className="flex gap-5">
                            <div className="">
                                <img src={PulseOx} alt="images" />
                            </div>
                            <div className="w-full ">
                                <div className="w-full flex justify-between items-center">
                                    <h5 className="text-primaryColor text-lg font-medium">
                                        Pulse Ox.
                                    </h5>
                                    <div className="flex gap-3 items-center">
                                        <div
                                            onClick={() => setGraphPop(true)}
                                            className="flex cursor-pointer"
                                        >
                                            <img src={Vector} alt="images" />
                                        </div>
                                        <div className="relative inline-block group">
                                            <Dropdown
                                                menu={{
                                                    items,
                                                }}
                                                trigger={['click']}
                                                placement='bottomRight'
                                            >
                                                <Space>
                                                    <FiMoreVertical />
                                                </Space>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-5 mt-4">
                                    <p className="text-textGray font-light text-xs ">
                                        SpO2{" "}
                                        <span className="text-lg text-secondaryColor font-medium">
                                            {props.data.spo2}%
                                        </span>{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        showentervitals ?
                            //  <EditVitals data={props.data[0]} title={'Resporatory Rate'} closePopup={saveSuccess} />
                            <div className='absolute inset-0'>
                                <Modal
                                    centered
                                    open={showentervitals}
                                    width={"25%"}
                                    footer={[]}
                                    onCancel={saveSuccess}
                                >
                                    <div>
                                        <p className="font-semibold text-xl">Rate</p>
                                        <div className="h-[5rem] pt-6">
                                            <div className="flex justify-between">
                                                <input
                                                    autoComplete="off"
                                                    id="spo2"
                                                    name="spo2"
                                                    type="number"
                                                    value={spo2Rate ?? ""}
                                                    class=" text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    placeholder="Rate"
                                                    onChange={(e) => setSpo2Rate(e.target.value)}
                                                />

                                                <label
                                                    htmlFor="email"
                                                    className=" text-xs font-normal text-gray-secondary"
                                                >
                                                    %
                                                </label>
                                            </div>
                                            <hr className="bg-gray-primary" />
                                            <div className="flex justify-end my-3">
                                                <button
                                                    onClick={savevitals}
                                                    className="bg-primaryColor  text-White py-1 px-4 font-medium rounded-xl mt-4"
                                                >
                                                    Update{" "}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                            : null
                    }
                    {
                        graphPop ? <VitalChart title={'Respiratory Rate Graph'} data={[props.data]} type="respirationRate" closePopup={() => setGraphPop(false)} /> : null
                    }
                </>
                // ))
            }
        </>
    );
}

export default Resporatoryratecard;