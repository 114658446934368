import CancelIcon from "@mui/icons-material/Cancel";
import ePrescription from "../../../Assets/Images/Cart/eprescription.svg";
import uploadPrescription from "../../../Assets/Images/Cart/upload.svg";
import savedPrescription from "../../../Assets/Images/Cart/saved.svg";
import CartDocImg from "../../../Assets/Images/realdoctor.svg";
import { useHistory } from "react-router-dom";
import http from "../../../Redux/Services/http-common";
import moment from "moment";
import { useState } from "react";
import { Modal } from "antd";
import slotShouldForwardProp from "@mui/material/styles/slotShouldForwardProp";
import CurebayButton from "../../Shared/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { bookDoctorAppointment } from "../../../Redux/Action/doctorAction";
import { setSessionStorageWithExpiry } from "../../Helper/sessionStorageWithExpiry";
import { ToastContainer, toast } from "react-toastify";
// import "./medicine.scss";
const UploadPrescription = (props) => {
  const {
    checkPrescriptionRequired,
    uploadedPrescriptionList,
    ePrescriptionList,
    ToggleSideBar,
    handleEPrescriptionModelToggleClick,
    savedPrescriptionList,
    handleSavePrescriptionClick,
    patientMedicineOrder,
    ViewPrescription,
    handlePrescriptionDelete,
    handleAppointmentClick,
  } = props;
  console.log(uploadedPrescriptionList, "divosdhvoidsivdss");
  const history = useHistory();
  const [slotData, setSlotData] = useState({})
  const [openSlotModal, setOpenSlotModal] = useState(false)
  const [selectedSlotData,setSelectedSlotData] = useState({})
  const userData = useSelector((state) => state.authReducer.patientData);
  const [loading,setLoading] = useState(false)
  const dispatch = useDispatch()
  const handleNavigation = (path) => {
    history.push(path);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const handleBookAppointment = () =>{
    let date = moment().format('YYYY-MM-DD')
    http.get(`${process.env.REACT_APP_DOCTOR_BASEURL}SlotAvailability/list?date=${date}`).then(res =>{
      let noData = true
      {Object.keys(res.data).forEach(doctorId => {
        const slotsInfo = res.data[doctorId];
        if (slotsInfo.slots && slotsInfo.slots.length > 0) {
          setSlotData(res.data)
          setOpenSlotModal(true)
          noData = false
        }
    })}
    if(noData){
      toast("Sorry! No Doctor available at the moment.")
    }
    }).catch(err =>{

    })
  }

  const handleSlotClick = (doctorId, slotTime, hospitalId, locationId) => {
    const slotDate = slotTime.split(' ')[0]; // Extract date part
    const startTime = slotTime.split(' ')[1].split('-')[0].trim();
    const endTime = slotTime.split(' ')[3]
    const formattedDate = moment(slotDate).format('MM/DD/YYYY');
    let payload = {
      slotTime : startTime,
      slotDate: slotDate,
      endTime: endTime,
      doctorId: doctorId,
      locationId: locationId,
      hospitalId: hospitalId
    }
    setSelectedSlotData(payload)
};

const confirmAppointment = () =>{
  // let patient = JSON.parse(localStorage.getItem("patient"));
  // console.log(patient , "sdvhsdougvudsgvousdgvsdvd")
  let firstName = userData.firstName.split(/\s/).join("");
  let payload = {

  userId: selectedSlotData.doctorId,
  consultationsReason: "Get Prescription",
  consultationsType: "V",//STATIC
  doctorType: "I",
  createdBy: userData.code,
  fromTime: selectedSlotData.slotTime,
  hospitalId: selectedSlotData.hospitalId,
  locationId: selectedSlotData.locationId,
  modifiedBy: userData.code,
  patientEmail: userData.email,
  patientId: userData.code,
  patientMobileNo: userData.mobile,
  patientName: firstName,
  toTime: selectedSlotData.endTime,
  whenAppointment: moment(selectedSlotData.slotDate).format("MM/DD/YYYY"),
  amount: 100,
  txnId: "",
  relation: "Self",
  totalAmount: 0.00,
  payMode: "FULL DISCOUNT",
  status: 1,
medicineOrderId: patientMedicineOrder[0].orderId,
sourceChannel:"B2C",
}
setLoading(true)
dispatch(bookDoctorAppointment(payload)).then((res) => {
  setLoading(false);
  setSessionStorageWithExpiry("doctorAppointentForPrescription", "true", 30)
  toast("Consultation booked, Continue without prescription.")
  setOpenSlotModal(false)
}).catch(err =>{
  setLoading(false)
})

console.log(payload, "sdvisdhvosdhousfhgbsbsf")
  }

const handleRequestCallback = () =>{
  setLoading(true);
  http.put(`${process.env.REACT_APP_DOCTOR_BASEURL}SlotAvailability/callBackRequest` , {
    callBackPrescriptionFlag: "Y",
    code: userData.code
  }).then(res =>{
    if(res){
      setSessionStorageWithExpiry("doctorAppointentForPrescription", "true", 30)
      toast("Continue without prescription.")
    }
  }).catch(err =>{
    setLoading(false);
    toast("Oops, try again.")
  })
 
}  


console.log(selectedSlotData, "selectedSlotData")
  return (
    (uploadedPrescriptionList.prescriptionDoc?.length !== 0 ||
      checkPrescriptionRequired()) && (
      <div className="">
        {checkPrescriptionRequired() ? (
          <div className="text-center font-semibold text-xl text-primaryColor">
            Upload Prescriptions{" "}
          </div>
        ) : null}
        {checkPrescriptionRequired() ? (
          <div>
            <div className="grid  grid-cols-3 gap-3 md:gap-5  mt-5">
              <div
                className="flex flex-col md:flex-row items-center gap-3 md:gap-5 p-2 md:p-4 md:my-4 uploaddiv1 cursor-pointer bg-[#dfecff] common-shadow border  border-[#c3dcff] rounded-md"
                onClick={ToggleSideBar}
              >
                <div className="bg-[#88baff] rounded-full w-[50px] h-[50px] p-3 flex justify-center ">
                  <img
                    src={uploadPrescription}
                    alt="medicine_icon1"
                    className="h-6"
                  />
                </div>

                <div className="flex ">
                  <p className="text-xs md:text-sm text-white sm:w-auto text-center md:text-left">
                    <span className="font-semibold">Browse </span>
                    <br />
                    <span className="text-textGray">Prescription</span>
                  </p>
                </div>
              </div>

              {ePrescriptionList?.length ? (
                <div
                  onClick={() => handleEPrescriptionModelToggleClick()}
                  className="flex flex-col md:flex-row items-center md:my-4 gap-3 md:gap-5 p-2 md:p-4 uploaddiv1 cursor-pointer bg-[#E4D0FF] common-shadow border  border-[#cfacff] rounded-md"
                >
                  <div className="bg-[#ba87ff] rounded-full w-[50px] h-[50px] p-3 flex justify-center ">
                    <img
                      src={ePrescription}
                      alt="medicine_icon2"
                      className=" h-6"
                    />
                  </div>

                  <div className="flex">
                    <p className="text-xs md:text-sm text-white sm:w-auto text-center md:text-left">
                      <span className="font-semibold">CureBay </span>
                      <br />
                      <span className="text-textGray">ePrescription</span>
                    </p>
                  </div>
                </div>
              ) : null}
              {savedPrescriptionList?.length ? (
                <div
                  onClick={() => {
                    handleSavePrescriptionClick();
                  }}
                  className="flex flex-col md:flex-row items-center gap-3 md:gap-5 p-2 md:p-4 md:my-4 uploaddiv1 cursor-pointer bg-[#ffecdb] common-shadow border  border-[#edb37c] rounded-md"
                >
                  <div className="bg-[#f1aa66] rounded-full w-[50px] h-[50px] p-3 flex justify-center ">
                    <img
                      src={savedPrescription}
                      alt="medicine_icon3"
                      className="h-6"
                    />
                  </div>

                  <div className="flex">
                    <p className="text-xs md:text-sm  text-white sm:w-auto text-center md:text-left">
                      <span className="font-semibold">Saved </span>
                      <br />
                      <span className="text-textGray">Prescription</span>
                    </p>
                    <div></div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {uploadedPrescriptionList.prescriptionUploaded &&
        patientMedicineOrder &&
        patientMedicineOrder?.length > 0 ? (
          <div style={{ width: "fit-content" }} className="mt-4 relative">
            <div>
              <div className="grid grid-cols-3 gap-4 sm:flex flex-wrap ">
                {uploadedPrescriptionList.prescriptionDoc.map((res) => {
                  let type = res.split(".")[1];
                  if (type == "pdf") {
                    return (
                      <div
                        className="relative cursor-pointer relative rounded shadow-md  border-grey-900"
                        style={{ width: "68px" }}
                        onClick={() => ViewPrescription(res)}
                      >
                        <CancelIcon
                          onClick={(e) => handlePrescriptionDelete(e, res)}
                          style={{ color: "black" }}
                          className="z-10 absolute -right-2 -top-2 cursor-pointer"
                        />

                        <iframe
                          height="95px"
                          width="68px"
                          className="border rounded cursor-pointer mr-4"
                          src={`${process.env.REACT_APP_IMG_BASEURL}${res}`}
                        />
                        <div
                          className="absolute top-0"
                          style={{ height: "95px", width: "68px" }}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <>
                        <div
                          className="relative cursor-pointer relative rounded shadow-md  border-grey-900"
                          style={{ width: "68px" }}
                          onClick={() => ViewPrescription(res)}
                        >
                          <CancelIcon
                            onClick={(e) => handlePrescriptionDelete(e, res)}
                            style={{ color: "black" }}
                            className="z-10 absolute -right-2 -top-2 cursor-pointer"
                          />
                          <img
                            style={{ height: "95px", width: "68px" }}
                            className="border rounded cursor-pointer mr-4"
                            src={`${process.env.REACT_APP_IMG_BASEURL}${res}`}
                          />
                          <div
                            className="absolute top-0"
                            style={{ height: "95px", width: "68px" }}
                          />
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        ) : null}
        <div className="bg-lightBlue mt-5 rounded-md md:flex justify-between relative">
          <div className="px-3 md:px-7 py-5">
            <p className="font-medium text-base mb-2 text-primaryColor">
              Don’t have Prescription?
            </p>
            <p className="text-primaryColor font-light text-sm ">
              Please call us or book an appointment with our doctors
            </p>
            <div className="grid grid-cols-2 md:grid-cols-3 w-full mt-6 gap-5">
              <button
                className="w-full md:w-fit bg-primaryColor mb-3 md:mb-0 py-2 px-2 md:px-5 rounded-md text-White text-xs md:font-medium"
                onClick={() => window.open("tel:+918335000999")}
              >
                +91-8335 000 999
              </button>
              <button
                className="w-full md:w-fit bg-primaryColor mb-3 md:mb-0 py-2 px-2 md:px-5 rounded-md text-White text-xs md:font-medium"
                onClick={() =>
                 handleBookAppointment()
                }
              >
                Book Appointment
              </button>
              {false && <button
                className="w-full md:w-fit bg-primaryColor mb-3 md:mb-0 py-2 px-2 md:px-5 rounded-md text-White text-xs md:font-medium"
                onClick={() =>
                handleRequestCallback()
                }
                disabled = {loading}
                
              >
                Request a callback
              </button>}
            </div>
          </div>
          <div className="md:absolute bottom-0 right-0">
            <img src={CartDocImg} alt="img" className="w-full h-[160px]" />
          </div>
        </div>
        <Modal
          title="Select Slots"
          centered={true}
          footer={false}
          className="commonModal"
          open={openSlotModal}
          width="50%"
          // onOk={handleHideClick}
          onCancel={() => setOpenSlotModal(false)}

        >
          <div>
          {Object.keys(slotData).map(doctorId => {
           
                const slotsInfo = slotData[doctorId];

                if (typeof slotsInfo === 'string') {
                    // return <p key={doctorId}>{doctorId}: {slotsInfo}</p>;
                } else if (slotsInfo.slots && slotsInfo.slots.length > 0) {
                  console.log(slotsInfo,"njdsnjdkv")
                    return (
                      <div key={doctorId} style={{ marginBottom: '20px' }}>
                      <div className="grid grid-cols-3 md:grid-cols-6 gap-4 text-center" >
                          {slotsInfo.slots.map((slot, index) => {
                              if (slot.status === "Available") {
                                  // Extract and format the start time
                                  const startTime = slot.slotTime.split(' ')[1].split('-')[0].trim();
                                  const formattedTime = moment(startTime, 'HH:mm').format('hh:mm A');

                                  const isDisabled = slot?.slotAvailability === 'N';
                                  return (
                                      <div
                                          key={index}
                                          style={{
                                            cursor: isDisabled ? 'not-allowed' : 'pointer',
                                            color: isDisabled
                                            ? '#B0B0B0' // Grey out text if disabled
                                            : selectedSlotData.slotTime === startTime
                                            ? "#ffff"
                                            : "#134171",
                                            backgroundColor: selectedSlotData.slotTime === startTime && !isDisabled 
                                            ? "#134171"
                                            : "#ffff",
                                              // color: `${selectedSlotData.slotTime == startTime ? "#ffff" : "#134171"}`,
                                              // backgroundColor: `${selectedSlotData.slotTime == startTime ? "#134171" : "#ffff"}`,
                                              marginBottom: '8px',
                                              opacity: isDisabled ? 0.6 : 1,
                                            
                                          }}
                                          className="border rounded shadow"
                                          onClick={() => handleSlotClick(doctorId, slot.slotTime, slotsInfo.hospitalId, slotsInfo.locationId)}
                                      >
                                          {formattedTime} 
                                      </div>
                                  );
                              }
                              return null;
                          })}
                      </div>
                  </div>
                    );
                } else {
                    return null;
                }
            })}
            <div className="flex justify-end" >
            <CurebayButton
            onClick={confirmAppointment}
            loading = {loading}
            >Book</CurebayButton>

            </div>
          </div>
        </Modal>
      </div>
      
    )
  );
};

export default UploadPrescription;
