import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import Slider from "react-slick";
const Testimonials = ({data}) =>{

    const bottomOne = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        prevArrow: <img src={PrevArrow} alt="icons" />,
        nextArrow: <img src={NextArrow} alt="icons" />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    return(
        <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[50px]">
            Happy <span className="text-secondaryColor">Patients</span> Speak
          </h2>
          <div className="mb-[90px] md:mb-0 ">
            <Slider {...bottomOne}>
              {data?.elevencomp?.map(res => (
 <div className="px-3">
 <div className="bg-White rounded-2xl  p-5">
   <div className="mb-5">
     <img
       src={res?.image_url}
       alt="images"
       className="w-full"
     />
   </div>
   <p className="text-sm text-textGray h-[120px] overflow-y-auto">
    {res?.description}
   </p>
   <div className="text-center mt-5">
     <p className="text-lg text-primaryColor font-semibold mb-0">
       {res?.name}
     </p>
     <p className="text-base text-textGray">{res?.location}</p>
   </div>
 </div>
</div>
              ))}
            </Slider>
          </div>
          {/* <div className="md:mt-8 mt-14 mb-[40px] md:mb-0">
            <Slider {...bottomTwo} className="splSlider">
              <div className="px-3">
                <div className="bg-White rounded-2xl p-3 md:p-5">
                  <video
                    className="w-full md:rounded-[20px] "
                    // autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    controls
                    poster={happyPatientAkshayathumb}
                  >
                    <source
                    src={happyPatientAkshaya}
                      // src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Akshya%20Nayak.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="px-3">
                <div className="bg-White rounded-2xl p-3 md:p-5">
                  <video
                    className="w-full md:rounded-[20px] "
                    // autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    controls
                    poster={happyPatientAnitathumb}
                  >
                    <source
                    src={happyPatientAnita}
                      // src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Anita%20Dehuri.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="px-3">
                <div className="bg-White rounded-2xl p-3 md:p-5">
                  <video
                    className="w-full md:rounded-[20px] "
                    // autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    controls
                    poster={happyPatientBasantithumb}
                  >
                    <source
                    src={happyPatientBasanti}
                      // src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Basanti%20Ratha.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="px-3">
                <div className="bg-White rounded-2xl p-3 md:p-5">
                  <video
                    className="w-full md:rounded-[20px] "
                    // autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    controls
                    poster={happyPatientRanjanthumb}
                  >
                    <source
                    src={happyPatientRanjan}
                      // src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Dipti%20Ranjan%20Parida_subtitle.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="px-3">
                <div className="bg-White rounded-2xl p-3 md:p-5">
                  <video
                    className="w-full md:rounded-[20px] "
                    // autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    controls
                    poster={happyPatientKumudithumb}
                  >
                    <source
                      src={happyPatientKumudi}
                      // src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Kumuduni%20Parida.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="px-3">
                <div className="bg-White rounded-2xl p-3 md:p-5">
                  <video
                    className="w-full md:rounded-[20px] "
                    // autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    controls
                    poster={happyPatientManmohanthumb}
                  >
                    <source
                    src={happyPatientManmohan}
                      // src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Manmohan%20Biswal_subtitle.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="px-3">
                <div className="bg-White rounded-2xl p-3 md:p-5">
                  <video
                    className="w-full md:rounded-[20px] "
                    // autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    controls
                    poster={happyPatientMitalithumb}
                  >
                    <source
                    src={happyPatientMitali}
                      // src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Mitali%20Sahoo_subtitle.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="px-3">
                <div className="bg-White rounded-2xl p-3 md:p-5">
                  <video
                    className="w-full md:rounded-[20px] "
                    // autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    controls
                    poster={happyPatientPrasannathumb}
                  >
                    <source
                    src={happyPatientPrasanna}
                      // src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Prasanna%20Behera_subtitle.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="px-3">
                <div className="bg-White rounded-2xl p-3 md:p-5">
                  <video
                    className="w-full md:rounded-[20px] "
                    // autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    controls
                    poster={happyPatientRabithumb}
                  >
                    <source
                    src={happyPatientRabi}
                      // src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Rabi%20Behera.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="px-3">
                <div className="bg-White rounded-2xl p-3 md:p-5">
                  <video
                    className="w-full md:rounded-[20px] "
                    // autoPlay
                    loop
                    muted
                    preload="auto"
                    playsInline
                    controls
                    poster={happyPatientSanatanthumb}
                  >
                    <source
                    src={happyPatientSanatan}
                      // src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Videos%201/Videos/Sanatan%20Kar_subtitle.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </Slider>
          </div> */}
        </div>
      </section>
    )
}

export default Testimonials