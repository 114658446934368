// import React, { useState, useEffect, useRef } from "react";
// import { useHistory } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import Layouts from "../../../Layouts";
// import Hospital from "../../../../Assets/Images/HospitalB2C.webp";
// import FilterIcon from "../../../../Assets/Images/ReportsFilter.svg";
// import {
//   getPatientmedicinedeliveryList,
// } from "../../../../Redux/Action/patientAction";
// import moment from "moment";
// import { getLocalDateWithTime, } from "../../../Helper/LocalTimeFormat";
// import { getAllProductByIds } from "../../../../Redux/Action/pharmacyAction";
// import {
//   AddtoCart,
// } from "../../../../Redux/Action/cartPlaceOrderAction";
// import UploadPrescriptionHelper from "../../../Helper/uploadPrescription";
// import { DatePicker, Form, Input, Modal, Select, message, Col } from "antd";
// // import { Col, Form, Input, Modal, Row, Select } from "antd";
// import calender from "../../../../Assets/Images/calender.png";
// import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
// import Dragger from "antd/es/upload/Dragger";
// import { Upload, Button, Checkbox } from "antd";
// import { encodeBase64File } from "../../../Helper/filebase64";
// import {
//   uploadReport,
//   updateReport,
//   getReport,
// } from "./../../../../Redux/Action/reportAction";
// function SummaryMedicineOrder(props) {
//   const userData = useSelector((state) => state.authReducer.patientData);

//   const dispatch = useDispatch();
//   const [modal1, setModal1] = useState(false);
//   const [modal4Open, setModal4Open] = useState(false);
//   const [list, setList] = useState([]);
//   const [medicineList, setmedicineList] = useState([]);
//   const [selectedDataSource, setSelectedDataSource] = useState([]);
//   const [prescriptionDialog, setPrescriptionDialog] = useState(false);
//   const [prescriptionDoc, setPrescriptionDoc] = useState();
//   const [currSelectedOrder, setCurrSelectedOrder] = useState();
//   const [uploadBill, setUploadBill] = useState();
//   const [uploadBill64, setUploadBill64] = useState();
//   const [uploadPrescription, setUploadPrescription] = useState();
//   const [uploadPrescription64, setUploadPrescription64] = useState();
//   const [billType, setbillType] = useState('');
//   const [prescType, setprescType] = useState('');
//   const [bill, setBill] = useState(false);

//   const formRef = useRef();

//   const { cartList } = useSelector((state) => state.cartReducer);

//   const showModal1 = (medicineOrder) => {
//     setModal1(true);
//     setCurrSelectedOrder(medicineOrder);
//   };
//   const handleCancel4 = () => {
//     setModal4Open(false);
//   };
//   const medicinedeliverylist = useSelector(
//     (state) => state.patientmedicinedeliverylist
//   );
//   const { PatientmedicinedeliveryData, isLoading } = medicinedeliverylist;

//   useEffect(() => {
//     getMedicine()
//   }, [props.patCode]);
//   const getMedicine = () => {
//     const payload = {
//       patientCode: props.patCode,
//       photoRequired: "Y",
//       reportType: 'Medicine',
//       // status: 1,
//     };
//     // dispatch(getPatientmedicinedeliveryList(payload, 1)).then((res) => {
//     //   console.log(res.data)
//     //   setmedicineList(res.data)
//     // });
//     dispatch(getPatientmedicinedeliveryList(payload))
//       .then((res) => {
//         setmedicineList(res);
//       })
//       .catch((err) => {
//         console.log("error");
//       });
//   }
//   const onPrescriptionOpenClick = (user, e) => {
//     if (e == 'bill') {
//       setBill(true)
//     } else {
//       setBill(false)
//     }
//     setPrescriptionDialog(true);
//     setPrescriptionDoc(user);
//   };

//   const handleImageChange = async (e, type) => {
//     if (type === 'bill') {
//       setUploadBill(e.target.files[0]);
//       if (e.target.files[0]) {
//         const res = await encodeBase64File(e.target.files[0]);
//         console.log(res);
//         setUploadBill64(res);
//         let x = []
//         x = e.target.files[0].type
//         let y = x.split('/')

//         setbillType(y[1])
//         console.log(y[1])
//       }
//     }
//     else {
//       setUploadPrescription(e.target.files[0]);
//       if (e.target.files[0]) {
//         const res = await encodeBase64File(e.target.files[0]);
//         console.log(res);
//         setUploadPrescription64(res);
//         let x = []
//         x = e.target.files[0].type
//         let y = x.split('/')
//         setprescType(y[1])
//         console.log(y[1])

//       }
//     }

//   };

//   const handleFinish = (values) => {
//     console.log("values", values);
//     const payload = {
//       patientCode: props.patCode,
//       title: values.title,
//       notes: values.notes,
//       ePrescriptionGen: -1,
//       ePrescriptionStatus: -1,
//       status: 1,
//       orderId: values.orderId,
//       documentUploadByVisit: 0,
//       documentRequired: "N",
//       createdBy: userData.code,
//       modifiedBy: userData.code,
//       enteredBy: userData.code,
//       createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
//       givenDate: moment(values.date, "DD/MM/YYYY").format(
//         "YYYY-MM-DD hh:mm:ss"
//       ),
//       patientName: userData.firstName,
//       patientMobile: userData.mobile,
//       visitId: 0,
//     };
//     payload.document = uploadPrescription64
//     payload.documentType = prescType
//     payload.uploadMedicineBill = uploadBill64;
//     payload.uploadMedicineBillType = billType;
//     payload.documentReceivedSource = 'Self'
//     payload.reportType = 'Medicine';
//     dispatch(uploadReport(payload)).then((res) => {
//       getMedicine();
//       message.success("Medicine order added");
//       setModal4Open(false);
//     });
//   };

//   const showModal4 = () => {
//     setModal4Open(true);
//   };

//   return (
//     <div className="bg-White rounded-md ">
//       <div className="bg-[#ffe9be] px-5 py-3 rounded-md flex justify-between items-center">
//         <h5 className="text-lg font-medium">Medicine Orders</h5>
//         <p className="text-sm underline cursor-pointer" onClick={showModal4}>Add Medicine</p>
//       </div>
//       <div className="p-5 h-[300px] overflow-auto">
//         {medicineList?.length > 0 &&
//           medicineList
//             .sort((a, b) => {
//               return (
//                 moment(b.createdOn, "yyyy-MM-DD HH:mm:ss") -
//                 moment(a.createdOn, "yyyy-MM-DD HH:mm:ss")
//               );
//             })
//             .map((user, i) => (
//               <div className="bg-White common-shadow p-5 mb-5" key={i}>
//                 <div className="flex flex-wrap justify-between gap-3">
//                   {/* <div className="text-right text-primaryColor text-base font-medium mt-1">
//                     Number of items :{" "}
//                     <span className="text-secondaryColor font-medium">
//                       {user.totalOrderItems}
//                     </span>
//                   </div> */}
//                   {/* <div className="text-right text-primaryColor text-base font-medium mt-1">
//                     <span className="text-secondaryColor font-medium">
//                       ₹{user.orderAmount.toFixed(2)}
//                     </span>
//                   </div> */}
//                 </div>
//                 <div className="flex flex-wrap justify-between text-textGray w-full gap-3 mt-2">
//                   <div className="text-textGray text-sm">
//                     <label className="font-light">Order ID</label>
//                     <p className="font-medium mt-1">{user.orderId}</p>
//                   </div>
//                   <div className="text-textGray text-sm">
//                     <label className="font-light">Date</label>
//                     <p className="font-medium mt-1">
//                       {getLocalDateWithTime(user.createdDate)}
//                     </p>
//                   </div>
//                   <div className="text-textGray text-sm">
//                     <p className="text-base font-normal text-secondaryColor">
//                       {user.documentReceivedSource === 'Self' ? 'My Ref.' : 'Curebay'}
//                     </p>
//                   </div>
//                   {/* <div className="text-textGray text-sm">
//                     <label className="font-light">Schedule Delivery</label>
//                     <p className="font-medium mt-1">
//                       {getLocalDateWithTime(user.scheduledDelivery)}
//                     </p>
//                   </div> */}
//                 </div>
//                 <div className="flex justify-between mt-3">
//                   {/* {user.prescriptionDocArray &&
//                     user.prescriptionDocArray.length > 0 &&
//                     user.prescriptionDocArray[0] !== "" && ( */}
//                   <div
//                     onClick={() => onPrescriptionOpenClick(user, 'pres')}
//                     className="text-textGray underline text-xs md:text-sm cursor-pointer"
//                   >
//                     View Prescription
//                   </div>
//                   {/* )} */}
//                   <div
//                     onClick={() => onPrescriptionOpenClick(user, 'bill')}
//                     className="text-[#005D8D] underline text-xs md:text-sm cursor-pointer"
//                   >
//                     View Bill
//                   </div>
//                   {/* <div
//                     onClick={(e) => handleReorderClick(order)}
//                     className="text-secondaryColor underline text-xs md:text-sm cursor-pointer"
//                   >
//                     Reorder
//                   </div> */}
//                 </div>

//               </div>
//             ))}
//       </div>
//       <Modal
//         title="Add Medicine Order"
//         className="commonModal"
//         centered={true}
//         footer={false}
//         open={modal4Open}
//         onOk={showModal4}
//         onCancel={handleCancel4}
//         width="50%"
//       >
//         <Form
//           ref={formRef}
//           onFinish={handleFinish}
//           layout="vertical"
//           autoComplete="off"
//           className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
//         >
//           <Form.Item className="mb-0" label="Date" name="date">
//             <DatePicker placeholder="Record on " className="w-full h-12" />
//           </Form.Item>

//           <Form.Item className="mb-0" label="Order Id *" name="orderId">
//             <Input placeholder="Enter OrderId " />
//           </Form.Item>
//           <Form.Item className="mb-0" label="Upload Prescription *" name="uploadPrescription" initialValue="order">
//             <input
//               className="h-[50px] border border-gray w-full rounded-md px-3 py-2 flex items-center cursor-pointer relative z-[5]"
//               placeholder="Upload Prescription"
//               type="file"
//               onChange={(e) => handleImageChange(e, 'prescription')}
//             />
//             {!uploadPrescription && (
//               <div className="absolute bg-White inset-0 flex justify-center items-center cursor-pointer">
//                 <div className="text-textGray text-sm ">
//                   {" "}
//                   <CloudUploadOutlined className="pr-3" />
//                 </div>
//               </div>
//             )}
//             {uploadPrescription && (
//               <div className="absolute flex justify-between top-[23%] left-[3%] w-[90%]">
//                 <p>{uploadPrescription.name}</p>
//                 <DeleteOutlined className="text-danger" />
//               </div>
//             )}
//           </Form.Item>
//           <Form.Item className="mb-0" label="Upload Bill *" name="uploadBill">
//             <input
//               className="h-[50px] border border-gray w-full rounded-md px-3 py-2 flex items-center cursor-pointer relative z-[5]"
//               placeholder="Upload Prescription"
//               type="file"
//               onChange={(e) => handleImageChange(e, 'bill')}
//             />
//             {!uploadBill && (
//               <div className="absolute bg-White inset-0 flex justify-center items-center cursor-pointer">
//                 <div className="text-textGray text-sm ">
//                   {" "}
//                   <CloudUploadOutlined className="pr-3" />
//                 </div>
//               </div>
//             )}
//             {uploadBill && (
//               <div className="absolute flex justify-between top-[23%] left-[3%] w-[90%]">
//                 <p>{uploadBill.name}</p>
//                 <DeleteOutlined className="text-danger" />
//               </div>
//             )}
//           </Form.Item>
//           <Form.Item></Form.Item>
//           <Form.Item></Form.Item>
//           <div className="grid grid-cols-2 items-center justify-end gap-5 mt-10 mb-5">
//             <button
//               onClick={handleCancel4}
//               className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
//             >
//               Cancel
//             </button>
//             <button
//               // onClick={handleSave}
//               type="submit"
//               className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
//             >
//               Save
//             </button>
//           </div>
//         </Form>
//       </Modal>

//       <Modal
//         title="View Order details"
//         className="commonModal"
//         centered="true"
//         open={modal1}
//         onCancel={() => setModal1(false)}
//         width="60%"
//         footer={[]}
//       >
//         <div className="flex justify-between items-center mb-2 md:mb-3">
//           <div>
//             <p class="text-sm md:text-base font-medium mb-1 text-primaryColor">
//               Number of items :{" "}
//               <span className="text-secondaryColor">
//                 {currSelectedOrder?.totalOrderItems}
//               </span>
//             </p>
//           </div>
//           <div>
//             <p class="text-sm md:text-base font-medium mb-1 text-primaryColor hidden md:block">
//               Amount Paid :{" "}
//               <span className="text-secondaryColor">
//                 ₹ {currSelectedOrder?.amount}
//               </span>
//             </p>
//             <p class="text-sm md:text-base font-medium mb-1 text-primaryColor block md:hidden">
//               <span className="text-secondaryColor">
//                 ₹ {currSelectedOrder?.amount}
//               </span>
//             </p>
//           </div>
//           <div className="hidden md:block">
//             <div className="">
//               <p className="text-sm font-medium bg-lightGreen px-5 py-1 rounded-full text-secondaryColor">
//                 <span>{currSelectedOrder?.orderStatus}</span>
//               </p>
//             </div>
//             <div>
//               {/* <p className="text-sm font-medium mb-4  text-gray-500">
//                       Order ID: <span>REF637UND153851</span>
//                     </p> */}
//             </div>
//           </div>
//         </div>
//         <div className="block md:hidden mb-3">
//           <p className="text-sm font-medium bg-lightGreen px-5 py-1 rounded-full text-secondaryColor w-fit">
//             <span>{currSelectedOrder?.orderStatus}</span>
//           </p>
//         </div>
//         <div className="grid grid-cols-2 md:flex gap-3 justify-between ">
//           <div className="mb-3">
//             <p class="text-xs font-light mb-1 text-textGray">Order ID</p>
//             <p class="text-xs md:text-sm font-medium text-textGray">
//               {currSelectedOrder?.patientOrderId}
//             </p>
//           </div>
//           <div className="mb-3">
//             <p class="text-xs font-light mb-1 text-textGray">
//               Placed On{" "}
//             </p>
//             <p class="text-xs md:text-sm font-medium text-textGray">
//               {currSelectedOrder?.paymentDateTime}
//             </p>
//           </div>
//           <div className="mb-3 hidden md:block">
//             <p class="text-xs font-light mb-1 text-textGray">
//               Scheduled Delivery
//             </p>
//             <p class="text-xs md:text-sm font-medium text-textGray">
//               {currSelectedOrder?.scheduledDelivery}
//             </p>
//           </div>
//         </div>
//         <div className="grid grid-cols-2 md:flex justify-between md:items-center">
//           <div className="mb-3 md:hidden block">
//             <p class="text-xs font-light mb-1 text-textGray">
//               Scheduled Delivery
//             </p>
//             <p class="text-xs md:text-sm font-medium text-textGray">
//               {currSelectedOrder?.scheduledDelivery}
//             </p>
//           </div>
//           <div className="hidden md:block">
//             <p class="text-xs font-light mb-1 text-textGray">
//               Delivery Address
//             </p>
//             <p class="text-xs md:text-sm  mb-2 font-medium text-textGray">
//               {currSelectedOrder?.deliveryAddress1}{" "}
//               {currSelectedOrder?.deliveryAddress2},{" "}
//               {currSelectedOrder?.deliveryCity},{" "}
//               {currSelectedOrder?.deliveryState} -{" "}
//               {currSelectedOrder?.deliveryPinCode}
//             </p>
//           </div>
//           <div className="flex justify-end md:block">
//             <button class="text-White text-sm font-medium h-8 px-3 rounded-md bg-primaryColor">
//               Reorder
//             </button>
//           </div>
//         </div>
//         <div className="md:hidden block">
//           <p class="text-xs font-light mb-1 text-textGray">
//             Delivery Address
//           </p>
//           <p class="text-xs md:text-sm  mb-2 font-medium text-textGray">
//             {currSelectedOrder?.deliveryAddress1}{" "}
//             {currSelectedOrder?.deliveryAddress2},{" "}
//             {currSelectedOrder?.deliveryCity},{" "}
//             {currSelectedOrder?.deliveryState} -{" "}
//             {currSelectedOrder?.deliveryPinCode}
//           </p>
//         </div>
//         <div className="mt-5 overflow-x-auto">
//           <table className=" border-collapse border border-gray w-full h-full mx-auto ">
//             <thead>
//               <tr className="bg-gray-200">
//                 <th className="border border-gray-400 p-2">ITEM NAME</th>
//                 <th className="border border-gray-400 p-2">QTY</th>
//                 <th className="border border-gray-400 p-2">UNIT PRICE</th>
//                 <th className="border border-gray-400 p-2">
//                   UNIT DISCOUNTED PRICE
//                 </th>
//                 <th className="border border-gray-400 p-2">
//                   TOTAL AMOUNT
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {currSelectedOrder?.orderDetails[0]?.refundableAmountForItems?.map(
//                 (drug, idx) => (
//                   <tr key={idx}>
//                     <td className="border border-gray-400 p-2">
//                       {drug?.drugName}
//                     </td>
//                     <td className="border border-gray-400 p-2">
//                       {drug?.quantity}
//                     </td>
//                     <td className="border border-gray-400 p-2">
//                       ₹ {drug?.unitPrice}
//                     </td>
//                     <td className="border border-gray-400 p-2">
//                       ₹ {drug?.discountAmount}
//                     </td>
//                     <td className="border border-gray-400 p-2">
//                       ₹ {drug?.totalAmount}
//                     </td>
//                   </tr>
//                 )
//               )}
//             </tbody>
//           </table>
//         </div>
//       </Modal>

//       <Modal
//         title={
//           <p className="font-bold text-primaryColor text-xl">
//             {bill == false ? 'Prescription' : "Bill"}
//           </p>
//         }
//         open={prescriptionDialog}
//         onCancel={() => setPrescriptionDialog(false)}
//         footer={[]}
//         width="60%"
//       >
//         <div className="flex flex-col text-center justify-center">
//           {bill ?
//             <div className="w-auto">
//               {prescriptionDoc?.uploadMedicineBillType === "pdf" ? (
//                 <iframe
//                   style={{ width: "100%", height: "500px" }}
//                   src={`${process.env.REACT_APP_IMG_BASEURL}${prescriptionDoc?.uploadMedicineBillName}`}
//                   alt="profile image"
//                 />
//               ) : (
//                 <img
//                   style={{ width: "100%", height: "500px" }}
//                   src={`${process.env.REACT_APP_IMG_BASEURL}${prescriptionDoc?.uploadMedicineBillName}`}
//                   alt="profile image"
//                 />
//               )}
//             </div>
//             :
//             <div className="w-auto">
//               {prescriptionDoc?.documentType === "pdf" ? (
//                 <iframe
//                   style={{ width: "100%", height: "500px" }}
//                   src={`${process.env.REACT_APP_IMG_BASEURL}${prescriptionDoc?.docName}`}
//                   alt="profile image"
//                 />
//               ) : (
//                 <img
//                   style={{ width: "100%", height: "500px" }}
//                   src={`${process.env.REACT_APP_IMG_BASEURL}${prescriptionDoc?.docName}`}
//                   alt="profile image"
//                 />
//               )}
//             </div>
//           }
//         </div>
//       </Modal>
//     </div>
//   );
// }

// export default SummaryMedicineOrder;

import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layouts from "../../../Layouts";
import Hospital from "../../../../Assets/Images/HospitalB2C.webp";
import FilterIcon from "../../../../Assets/Images/ReportsFilter.svg";
import { getPatientmedicinedeliveryList } from "../../../../Redux/Action/patientAction";
import moment from "moment";
import { getLocalDateWithTime } from "../../../Helper/LocalTimeFormat";
import { getAllProductByIds } from "../../../../Redux/Action/pharmacyAction";
import { AddtoCart } from "../../../../Redux/Action/cartPlaceOrderAction";
import UploadPrescriptionHelper from "../../../Helper/uploadPrescription";
import { DatePicker, Form, Input, Modal, Select, message, Col } from "antd";
// import { Col, Form, Input, Modal, Row, Select } from "antd";
import calender from "../../../../Assets/Images/calender.png";
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { Upload, Button, Checkbox } from "antd";
import { encodeBase64File } from "../../../Helper/filebase64";
import {
  uploadReport,
  updateReport,
  getReport,
} from "./../../../../Redux/Action/reportAction";
import { ToastContainer, toast } from "react-toastify";
function SummaryMedicineOrder(props) {
  const userData = useSelector((state) => state.authReducer.patientData);

  const dispatch = useDispatch();
  const [modal1, setModal1] = useState(false);
  const [modal4Open, setModal4Open] = useState(false);
  const [list, setList] = useState([]);
  const [medicineList, setmedicineList] = useState([]);
  const [selectedDataSource, setSelectedDataSource] = useState([]);
  const [prescriptionDialog, setPrescriptionDialog] = useState(false);
  const [prescriptionDoc, setPrescriptionDoc] = useState();
  const [currSelectedOrder, setCurrSelectedOrder] = useState();
  const [uploadBill, setUploadBill] = useState();
  const [uploadBill64, setUploadBill64] = useState();
  const [uploadPrescription, setUploadPrescription] = useState();
  const [uploadPrescription64, setUploadPrescription64] = useState();
  const [billType, setbillType] = useState("");
  const [prescType, setprescType] = useState("");
  const [bill, setBill] = useState(false);

  const formRef = useRef();

  const { cartList } = useSelector((state) => state.cartReducer);

  const showModal1 = (medicineOrder) => {
    setModal1(true);
    setCurrSelectedOrder(medicineOrder);
  };
  const handleCancel4 = () => {
    setModal4Open(false);
  };
  const medicinedeliverylist = useSelector(
    (state) => state.patientmedicinedeliverylist
  );
  const { PatientmedicinedeliveryData, isLoading } = medicinedeliverylist;

  useEffect(() => {
    getMedicine();
  }, [props.patCode]);
  const getMedicine = () => {
    const payload = {
      patientCode: props.patCode,
      photoRequired: "Y",
      reportType: "Medicine",
      // status: 1,
    };
    // dispatch(getPatientmedicinedeliveryList(payload, 1)).then((res) => {
    //   console.log(res.data)
    //   setmedicineList(res.data)
    // });
    dispatch(getPatientmedicinedeliveryList(payload))
      .then((res) => {
        setmedicineList(res);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const onPrescriptionOpenClick = (user, e) => {
    if (e == "bill") {
      setBill(true);
    } else {
      setBill(false);
    }
    setPrescriptionDialog(true);
    setPrescriptionDoc(user);
  };

  const handleImageChange = async (e, type) => {
    if (type === "bill") {
      setUploadBill(e.target.files[0]);
      if (e.target.files[0]) {
        const res = await encodeBase64File(e.target.files[0]);
        console.log(res);
        setUploadBill64(res);
        let x = [];
        x = e.target.files[0].type;
        let y = x.split("/");

        setbillType(y[1]);
        console.log(y[1]);
      }
    } else {
      setUploadPrescription(e.target.files[0]);
      if (e.target.files[0]) {
        const res = await encodeBase64File(e.target.files[0]);
        console.log(res);
        setUploadPrescription64(res);
        let x = [];
        x = e.target.files[0].type;
        let y = x.split("/");
        setprescType(y[1]);
        console.log(y[1]);
      }
    }
  };

  const handleFinish = (values) => {
    console.log("values", values);
    if (!uploadPrescription64) {
      toast("Please Upload the Prescription");
    } else if (!uploadBill64) {
      toast("Please Upload the Bill");
    } else {
      const payload = {
        patientCode: props.patCode,
        title: values.title,
        notes: values.notes,
        ePrescriptionGen: -1,
        ePrescriptionStatus: -1,
        status: 1,
        orderId: values.orderId,
        documentUploadByVisit: 0,
        documentRequired: "N",
        createdBy: userData.code,
        modifiedBy: userData.code,
        enteredBy: userData.code,
        createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        givenDate: moment(values.date).format("YYYY-MM-DD hh:mm:ss"),
        patientName: userData.firstName,
        patientMobile: userData.mobile,
        visitId: 0,
      };
      payload.document = uploadPrescription64;
      payload.documentType = prescType;
      payload.uploadMedicineBill = uploadBill64;
      payload.uploadMedicineBillType = billType;
      payload.documentReceivedSource = "Self";
      payload.reportType = "Medicine";
      dispatch(uploadReport(payload)).then((res) => {
        getMedicine();
        // message.success("Medicine order added");
        setTimeout(() => {
          toast("Medicine added Successfully");
          setUploadBill64("");
          setUploadPrescription64("");
        }, 300);
        setModal4Open(false);
      });
    }
  };

  const showModal4 = () => {
   

    setUploadPrescription(null);
    setUploadPrescription64(null);
    setUploadBill(null);
    setUploadBill64(null);
    setprescType("");
    setbillType("");
    setModal4Open(true);
  };

  return (
    <>
      <ToastContainer />
      <div className="bg-White rounded-md ">
        <div className="bg-[#ffe9be] px-5 py-3 rounded-md flex justify-between items-center">
          <h5 className="text-lg font-medium">Medicine Orders</h5>
          <p className="text-sm underline cursor-pointer" onClick={showModal4}>
            Add Medicine
          </p>
        </div>
        <div className="p-5 h-[300px] overflow-auto">
          {medicineList?.length > 0 &&
            medicineList
              .sort((a, b) => {
                return (
                  moment(b.createdOn, "yyyy-MM-DD HH:mm:ss") -
                  moment(a.createdOn, "yyyy-MM-DD HH:mm:ss")
                );
              })
              .map((user, i) => (
                <div className="bg-White common-shadow p-5 mb-5" key={i}>
                  <div className="flex flex-wrap justify-between gap-3">
                    {/* <div className="text-right text-primaryColor text-base font-medium mt-1">
                    Number of items :{" "}
                    <span className="text-secondaryColor font-medium">
                      {user.totalOrderItems}
                    </span>
                  </div> */}
                    {/* <div className="text-right text-primaryColor text-base font-medium mt-1">
                    <span className="text-secondaryColor font-medium">
                      ₹{user.orderAmount.toFixed(2)}
                    </span>
                  </div> */}
                  </div>
                  <div className="flex flex-wrap justify-between text-textGray w-full gap-3 mt-2">
                    <div className="text-textGray text-sm">
                      <label className="font-light">Order ID</label>
                      <p className="font-medium mt-1">{user.orderId}</p>
                    </div>
                    <div className="text-textGray text-sm">
                      <label className="font-light">Date</label>
                      <p className="font-medium mt-1">
                        {getLocalDateWithTime(user.createdDate)}
                      </p>
                    </div>
                    <div className="text-textGray text-sm">
                      <p className="text-base font-normal text-secondaryColor">
                        {user.documentReceivedSource === "Self"
                          ? "My Ref."
                          : "Curebay"}
                      </p>
                    </div>
                    {/* <div className="text-textGray text-sm">
                    <label className="font-light">Schedule Delivery</label>
                    <p className="font-medium mt-1">
                      {getLocalDateWithTime(user.scheduledDelivery)}
                    </p>
                  </div> */}
                  </div>
                  <div className="flex justify-between mt-3">
                    {/* {user.prescriptionDocArray &&
                    user.prescriptionDocArray.length > 0 &&
                    user.prescriptionDocArray[0] !== "" && ( */}
                    <div
                      onClick={() => onPrescriptionOpenClick(user, "pres")}
                      className="text-textGray underline text-xs md:text-sm cursor-pointer"
                    >
                      View Prescription
                    </div>
                    {/* )} */}
                    <div
                      onClick={() => onPrescriptionOpenClick(user, "bill")}
                      className="text-[#005D8D] underline text-xs md:text-sm cursor-pointer"
                    >
                      View Bill
                    </div>
                    {/* <div
                    onClick={(e) => handleReorderClick(order)}
                    className="text-secondaryColor underline text-xs md:text-sm cursor-pointer"
                  >
                    Reorder
                  </div> */}
                  </div>
                </div>
              ))}
        </div>
        <Modal
          title="Add Medicine Order"
          className="commonModal"
          centered={true}
          footer={false}
          open={modal4Open}
          onOk={showModal4}
          onCancel={handleCancel4}
          width="40%"
        >
          <Form
            ref={formRef}
            onFinish={handleFinish}
            layout="vertical"
            autoComplete="off"
            className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
          >
            <Form.Item className="mb-0" label="Date" name="date">
              <DatePicker placeholder="Record on " className="w-full h-12" />
            </Form.Item>

            <Form.Item
              className="mb-0"
              label={<span>Order Id</span>}
              name="orderId"
              rules={[{ required: true, message: "Please Enter Order ID" }]}
            >
              <Input placeholder="Enter OrderId" />
            </Form.Item>

            <Form.Item
              className="mb-0"
              label={<span>Upload Prescription</span>}
              name="uploadPrescription"
              initialValue="order"
              rules={[
                { required: true, message: "Please upload the prescription" },
              ]}
            >
              <div className="flex items-center space-x-2">
                {" "}
                {/* Container for input and delete icon */}
                <div className="relative w-full">
                  <input
                    className="h-[50px] border border-gray w-full rounded-md px-3 py-2 flex items-center cursor-pointer relative z-[5]"
                    placeholder="Upload Prescription"
                    type="file"
                    onChange={(e) => handleImageChange(e, "prescription")}
                  />
                  {!uploadPrescription && (
                    <div className="absolute bg-White inset-0 flex justify-center items-center cursor-pointer">
                      <div className="text-textGray text-sm">
                        <CloudUploadOutlined className="pr-3" />
                      </div>
                    </div>
                  )}
                  {uploadPrescription && (
                    <div className="absolute flex justify-between top-[23%] left-[3%] w-[90%]">
                      <p>{uploadPrescription.name}</p>
                    </div>
                  )}
                </div>
                {/* Delete icon outside of the input field */}
                {uploadPrescription && (
                  <DeleteOutlined
                    className="text-danger cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default behavior
                      e.stopPropagation(); // Prevent triggering the file input
                      setUploadPrescription(null); // Clear the uploaded file
                    }}
                  />
                )}
              </div>
            </Form.Item>

            <Form.Item
              className="mb-0"
              label={
                <span>
                  Upload Bill <span style={{ color: "red" }}>*</span>
                </span>
              }
              name="uploadBill"
            >
              <div className="flex items-center space-x-2">
                {" "}
                {/* Container for input and delete icon */}
                <div className="relative w-full">
                  <input
                    className="h-[50px] border border-gray w-full rounded-md px-3 py-2 flex items-center cursor-pointer relative z-[5]"
                    placeholder="Upload Bill"
                    type="file"
                    onChange={(e) => handleImageChange(e, "bill")}
                  />
                  {!uploadBill && (
                    <div className="absolute bg-White inset-0 flex justify-center items-center cursor-pointer">
                      <div className="text-textGray text-sm">
                        <CloudUploadOutlined className="pr-3" />
                      </div>
                    </div>
                  )}
                  {uploadBill && (
                    <div className="absolute flex justify-between top-[23%] left-[3%] w-[90%]">
                      <p>{uploadBill.name}</p>
                    </div>
                  )}
                </div>
                {/* Delete icon outside of the input field */}
                {uploadBill && (
                  <DeleteOutlined
                    className="text-danger cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default behavior
                      e.stopPropagation(); // Prevent triggering the file input
                      setUploadBill(null); // Clear the uploaded file
                    }}
                  />
                )}
              </div>
            </Form.Item>

            <Form.Item></Form.Item>
            <div className="grid grid-cols-2 items-center justify-end gap-5 mt-2 mb-2">
              <button
                onClick={handleCancel4}
                className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
              >
                Cancel
              </button>
              <button
                // onClick={handleSave}
                type="submit"
                className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
              >
                Save
              </button>
            </div>
          </Form>
        </Modal>

        <Modal
          title="View Order details"
          className="commonModal"
          centered="true"
          open={modal1}
          onCancel={() => setModal1(false)}
          width="60%"
          footer={[]}
        >
          <div className="flex justify-between items-center mb-2 md:mb-3">
            <div>
              <p class="text-sm md:text-base font-medium mb-1 text-primaryColor">
                Number of items :{" "}
                <span className="text-secondaryColor">
                  {currSelectedOrder?.totalOrderItems}
                </span>
              </p>
            </div>
            <div>
              <p class="text-sm md:text-base font-medium mb-1 text-primaryColor hidden md:block">
                Amount Paid :{" "}
                <span className="text-secondaryColor">
                  ₹ {currSelectedOrder?.amount}
                </span>
              </p>
              <p class="text-sm md:text-base font-medium mb-1 text-primaryColor block md:hidden">
                <span className="text-secondaryColor">
                  ₹ {currSelectedOrder?.amount}
                </span>
              </p>
            </div>
            <div className="hidden md:block">
              <div className="">
                <p className="text-sm font-medium bg-lightGreen px-5 py-1 rounded-full text-secondaryColor">
                  <span>{currSelectedOrder?.orderStatus}</span>
                </p>
              </div>
              <div>
                {/* <p className="text-sm font-medium mb-4  text-gray-500">
                      Order ID: <span>REF637UND153851</span>
                    </p> */}
              </div>
            </div>
          </div>
          <div className="block md:hidden mb-3">
            <p className="text-sm font-medium bg-lightGreen px-5 py-1 rounded-full text-secondaryColor w-fit">
              <span>{currSelectedOrder?.orderStatus}</span>
            </p>
          </div>
          <div className="grid grid-cols-2 md:flex gap-3 justify-between ">
            <div className="mb-3">
              <p class="text-xs font-light mb-1 text-textGray">Order ID</p>
              <p class="text-xs md:text-sm font-medium text-textGray">
                {currSelectedOrder?.patientOrderId}
              </p>
            </div>
            <div className="mb-3">
              <p class="text-xs font-light mb-1 text-textGray">Placed On </p>
              <p class="text-xs md:text-sm font-medium text-textGray">
                {currSelectedOrder?.paymentDateTime}
              </p>
            </div>
            <div className="mb-3 hidden md:block">
              <p class="text-xs font-light mb-1 text-textGray">
                Scheduled Delivery
              </p>
              <p class="text-xs md:text-sm font-medium text-textGray">
                {currSelectedOrder?.scheduledDelivery}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 md:flex justify-between md:items-center">
            <div className="mb-3 md:hidden block">
              <p class="text-xs font-light mb-1 text-textGray">
                Scheduled Delivery
              </p>
              <p class="text-xs md:text-sm font-medium text-textGray">
                {currSelectedOrder?.scheduledDelivery}
              </p>
            </div>
            <div className="hidden md:block">
              <p class="text-xs font-light mb-1 text-textGray">
                Delivery Address
              </p>
              <p class="text-xs md:text-sm  mb-2 font-medium text-textGray">
                {currSelectedOrder?.deliveryAddress1}{" "}
                {currSelectedOrder?.deliveryAddress2},{" "}
                {currSelectedOrder?.deliveryCity},{" "}
                {currSelectedOrder?.deliveryState} -{" "}
                {currSelectedOrder?.deliveryPinCode}
              </p>
            </div>
            <div className="flex justify-end md:block">
              <button class="text-White text-sm font-medium h-8 px-3 rounded-md bg-primaryColor">
                Reorder
              </button>
            </div>
          </div>
          <div className="md:hidden block">
            <p class="text-xs font-light mb-1 text-textGray">
              Delivery Address
            </p>
            <p class="text-xs md:text-sm  mb-2 font-medium text-textGray">
              {currSelectedOrder?.deliveryAddress1}{" "}
              {currSelectedOrder?.deliveryAddress2},{" "}
              {currSelectedOrder?.deliveryCity},{" "}
              {currSelectedOrder?.deliveryState} -{" "}
              {currSelectedOrder?.deliveryPinCode}
            </p>
          </div>
          <div className="mt-5 overflow-x-auto">
            <table className=" border-collapse border border-gray w-full h-full mx-auto ">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-400 p-2">ITEM NAME</th>
                  <th className="border border-gray-400 p-2">QTY</th>
                  <th className="border border-gray-400 p-2">UNIT PRICE</th>
                  <th className="border border-gray-400 p-2">
                    UNIT DISCOUNTED PRICE
                  </th>
                  <th className="border border-gray-400 p-2">TOTAL AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {currSelectedOrder?.orderDetails[0]?.refundableAmountForItems?.map(
                  (drug, idx) => (
                    <tr key={idx}>
                      <td className="border border-gray-400 p-2">
                        {drug?.drugName}
                      </td>
                      <td className="border border-gray-400 p-2">
                        {drug?.quantity}
                      </td>
                      <td className="border border-gray-400 p-2">
                        ₹ {drug?.unitPrice}
                      </td>
                      <td className="border border-gray-400 p-2">
                        ₹ {drug?.discountAmount}
                      </td>
                      <td className="border border-gray-400 p-2">
                        ₹ {drug?.totalAmount}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </Modal>

        <Modal
          title={
            <p className="font-bold text-primaryColor text-xl">
              {bill == false ? "Prescription" : "Bill"}
            </p>
          }
          open={prescriptionDialog}
          onCancel={() => setPrescriptionDialog(false)}
          footer={[]}
          width="60%"
        >
          <div className="flex flex-col text-center justify-center">
            {bill ? (
              <div className="w-auto">
                {prescriptionDoc?.uploadMedicineBillType === "pdf" ? (
                  <iframe
                    style={{ width: "100%", height: "500px" }}
                    src={`${process.env.REACT_APP_IMG_BASEURL}${prescriptionDoc?.uploadMedicineBillName}`}
                    alt="profile image"
                  />
                ) : (
                  <img
                    style={{ width: "100%", height: "500px" }}
                    src={`${process.env.REACT_APP_IMG_BASEURL}${prescriptionDoc?.uploadMedicineBillName}`}
                    alt="profile image"
                  />
                )}
              </div>
            ) : (
              <div className="w-auto">
                {prescriptionDoc?.documentType === "pdf" ? (
                  <iframe
                    style={{ width: "100%", height: "500px" }}
                    src={`${process.env.REACT_APP_IMG_BASEURL}${prescriptionDoc?.docName}`}
                    title="profile image"
                  />
                ) : (
                  <img
                    style={{ width: "100%", height: "500px" }}
                    src={`${process.env.REACT_APP_IMG_BASEURL}${prescriptionDoc?.docName}`}
                    alt="profile"
                  />
                )}
              </div>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SummaryMedicineOrder;
