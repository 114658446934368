import React, { useEffect, useState } from "react";
import Cardiology from "../../../Assets/Images/Icons/Speciality/Cardiology.svg";
import Dermatology from "../../../Assets/Images/Icons/Speciality/Dermatology.svg";
import Neurology from "../../../Assets/Images/Icons/Speciality/Neurology.svg";
import GeneralPhysican from "../../../Assets/Images/Icons/Speciality/GeneralPhysician.svg";
import Ent from "../../../Assets/Images/Icons/Speciality/ENT.svg";
import Obstetrics from "../../../Assets/Images/Icons/Speciality/Obstetrics&Gynaecology.svg";
import Orthopedics from "../../../Assets/Images/Icons/Speciality/Orthopaedics.svg";
import Paediatric from "../../../Assets/Images/Icons/Speciality/Paediatrics.svg";
import Pulmonology from "../../../Assets/Images/Icons/Speciality/pulmology.png";
import Sexologist from "../../../Assets/Images/Icons/Speciality/sexologist.svg";
import SportsMedicine from "../../../Assets/Images/Icons/Speciality/sports-medicine.svg";
import Gastrologist from "../../../Assets/Images/Icons/Speciality/Gastro.svg";
import Urology from "../../../Assets/Images/Icons/Speciality/urology.svg";
import Psychologist from "../../../Assets/Images/Icons/Speciality/Psychiatry.svg";
import Oncology from "../../../Assets/Images/Icons/Speciality/oncology.svg";
import Optometrist from "../../../Assets/Images/Icons/Speciality/optometrist.svg";
// import Pulmonology from "../../../Assets/Images/Icons/Speciality";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getDoctorspecialitylist } from "../../../Redux/Action/doctorAction";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";

function DoctorSpeciality() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [speciality, setSpeciality] = useState();

  const handleClick = (type) => {
    console.log(type);
    history.push(`/find-doctors`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const fetchSpecialityList = async () => {
      try {
        const res = await dispatch(getDoctorspecialitylist());
        console.log("imageData", res);
        const data = res.map((search, i) => {
          let img;
          switch (search.code) {
            case "S02":
              img = Cardiology;
              break;
            case "S04":
              img = Dermatology;
              break;
            case "S19":
              img = Neurology;
              break;
            case "S08":
              img = Ent;
              break;
            case "S21":
              img = Obstetrics;
              break;
            case "S25":
              img = Paediatric;
              break;
            case "S37":
              img = Orthopedics;
              break;
            case "S36":
              img = GeneralPhysican;
              break;
            case "S28":
              img = Pulmonology;
              break;
            case "S29":
              img = Sexologist;
              break;
            case "S30":
              img = SportsMedicine;
              break;
            case "S09":
              img = Gastrologist;
              break;
            case "S31":
              img = Urology;
              break;
            case "SC38":
              img = Psychologist;
              break;
            case "S22":
              img = Oncology;
              break;
            case "S40":
              img = Optometrist;
              break;
            // Add cases for other specialities as needed
            default:
              img = null; // Default image if no match
          }

          return {
            code: search.code,
            speciality: search.speciality,
            img: img,
            symptoms: search.symptoms, // Assuming symptoms is a property in search
          };
        });
        setSpeciality(data);
      } catch (error) {
        console.error("Error fetching doctor speciality list:", error);
        // Handle the error here (e.g., show a message to the user)
      }
    };
    fetchSpecialityList();
  }, [dispatch]);

  return (
    <div>
      <div className=" mt-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          {speciality &&
            speciality.length > 0 &&
            speciality.map((data, i) => (
              <React.Fragment key={i}>
                {data.img && (
                  <div
                    className="flex gap-3 cursor-pointer bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow hoverWhite"
                    onClick={() => {
                      history.push({
                        pathname: APP_ROUTES.FINDDOCTORS,
                        speciality: data.speciality,
                      });
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    <div className="w-[20%]">
                      <img
                        src={data.img}
                        alt={data?.speciality}
                        className="w-[70px] mb-4"
                      />
                    </div>
                    <div className="w-[80%]">
                      <h5 className="text-primaryColor md:text-lg text-base font-semibold mb-2">
                        {data?.speciality}
                      </h5>
                      <p className="text-textGray text-sm md:text-sm">
                        {data?.symptoms}
                      </p>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>

        {/* <div className="mt-5 md:mt-10 cursor-pointer underline text-secondaryColor text-lg md:text-xl font-semibold text-center">
          View All
        </div> */}
      </div>
    </div>
  );
}

export default DoctorSpeciality;
