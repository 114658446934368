import React, { useEffect, useRef, useState } from "react";
import Layouts from "../../Layouts";
import ExtensiveCoverage from "../../../Assets/Images/Icons/ExtensiveCoverage.svg";
import StrongFootprint from "../../../Assets/Images/Icons/StrongFootprint.svg";
import EnhancePatientCare from "../../../Assets/Images/Icons/EnhancePatientCare.svg";
import ReachUnderserved from "../../../Assets/Images/Icons/ReachUnderserved.svg";
import CollaborativeApproach from "../../../Assets/Images/Icons/CollaborativeApproach.svg";
import { Form, Input, InputNumber, Modal, Select, message } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import http from "../../../Redux/Services/http-common";
import { getAllStates } from "../../../Redux/Action/hospitalpageActions";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import SubHeader from "../../Shared/SearchBar";
import { useSelector } from "react-redux";
function DoctorsService() {
  const history = useHistory();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState();
/////////////////////////////
const [oldProfile, setOldProfile] = useState();
    const patientinfo = useSelector((state) => state.patientinfo);
    const userData = useSelector((state) => state.authReducer.patientData);
    const [profile, setProfile] = useState(userData);
    const { patientinfoData, isLoading, isSuccess } = patientinfo;
  useEffect(() => {
    async function fetchStates() {
      const res = await dispatch(getAllStates());
      setStates(res);
    }
    fetchStates();
  }, []);

  const handleChange = (value) => {
    setSelectedState(value);
  };

  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      if (/^[A-Za-z]$/.test(e.key)) {
        message.error("Alphabetic characters are not allowed.");
      }
      e.preventDefault();
    }
  };

  const handleSubmit = async () => {
    const formValues = formRef.current.getFieldsValue();
    const {name,hospitalName,contact,email,phone,pincode,state,city,speciality,yearsOfExperience} = formValues;

    if(speciality === undefined || speciality === ""){
      message.error("Please fill in all fields");
        return;
    }

    if(yearsOfExperience === undefined || yearsOfExperience === ""){
      message.error("Please fill in all fields");
        return;
    }
    else if(Number(yearsOfExperience) >= 100){
      message.error("Please enter a valid experience");
        return;
    }

    if (profile.firstName === undefined || profile.firstName === "" || profile.firstName === null) {
      if (name === undefined || name === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.pinCode === undefined || profile.pinCode === "" || profile.pinCode === null) {
      if (pincode === undefined || pincode === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.state === undefined || profile.state === "" || profile.state === null) {
      if (selectedState === undefined || selectedState === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.city === undefined || profile.city === "" || profile.city === null) {
      if (city === undefined || city === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    let payload;

    if(Object.keys(userData).length === 0){
      if(email === undefined || email === ""){
        message.error("Please fill in all fields");
        return;
      }
      if(phone === undefined || phone === ""){
        message.error("Please fill in all fields");
        return;
      }
      payload = {
        speciality,
        contactPerson:name?name:profile.firstName,
        yearsOfExperience,
        email,
        phoneNumber:phone,
        pincode:pincode?pincode.toString():profile.pinCode,
        state:selectedState?selectedState:profile.state === "AP"?"Andhra Pradesh":
        profile.state === "AS"?"Assam":
        profile.state === "BR"?"Bihar":
        profile.state === "CT"?"Chhattisgarh":
        profile.state === "DL"?"Delhi":
        profile.state === "GA"?"Goa":
        profile.state === "GJ"?"Gujarat":
        profile.state === "HR"?"Haryana":
        profile.state === "HP"?"Himachal Pradesh":
        profile.state === "JK"?"Jammu and Kashmir":
        profile.state === "JH"?"Jharkhand":
        profile.state === "KA"?"Karnataka":
        profile.state === "KL"?"Kerala":
        profile.state === "MP"?"Madhya Pradesh":
        profile.state === "MH"?"Maharashtra":
        profile.state === "MN"?"Manipur":
        profile.state === "ML"?"Meghalaya":
        profile.state === "MZ"?"Mizoram":
        profile.state === "NL"?"Nagaland":
        profile.state === "OD"?"Odisha":
        profile.state === "PY"?"Puducherry":
        profile.state === "PB"?"Punjab":
        profile.state === "PJ"?"Rajasthan":
        profile.state === "TN"?"Tamil Nadu":
        profile.state === "TS"?"Telangana":
        profile.state === "TR"?"Tripura":
        profile.state === "UP"?"Uttar Pradesh":
        profile.state === "UK"?"Uttarakhand":
        profile.state === "WB"?"West Bengal":
        profile.state,
        city:city?city:profile.city,
        type: "Doctor",
      };
    }
    else{
      payload = {
        speciality,
        contactPerson:name?name:profile.firstName,
        yearsOfExperience,
        email:profile.email,
        phoneNumber: profile.mobile,
        pincode:pincode?pincode.toString():profile.pinCode,
        state:selectedState?selectedState:profile.state === "AP"?"Andhra Pradesh":
        profile.state === "AS"?"Assam":
        profile.state === "BR"?"Bihar":
        profile.state === "CT"?"Chhattisgarh":
        profile.state === "DL"?"Delhi":
        profile.state === "GA"?"Goa":
        profile.state === "GJ"?"Gujarat":
        profile.state === "HR"?"Haryana":
        profile.state === "HP"?"Himachal Pradesh":
        profile.state === "JK"?"Jammu and Kashmir":
        profile.state === "JH"?"Jharkhand":
        profile.state === "KA"?"Karnataka":
        profile.state === "KL"?"Kerala":
        profile.state === "MP"?"Madhya Pradesh":
        profile.state === "MH"?"Maharashtra":
        profile.state === "MN"?"Manipur":
        profile.state === "ML"?"Meghalaya":
        profile.state === "MZ"?"Mizoram":
        profile.state === "NL"?"Nagaland":
        profile.state === "OD"?"Odisha":
        profile.state === "PY"?"Puducherry":
        profile.state === "PB"?"Punjab":
        profile.state === "PJ"?"Rajasthan":
        profile.state === "TN"?"Tamil Nadu":
        profile.state === "TS"?"Telangana":
        profile.state === "TR"?"Tripura":
        profile.state === "UP"?"Uttar Pradesh":
        profile.state === "UK"?"Uttarakhand":
        profile.state === "WB"?"West Bengal":
        profile.state,
        city:city?city:profile.city,
        type: "Doctor",
      };

    }

    

    try {
      const res = await http.post("partnerEnquiryForm/partner",payload);
      console.log(res);
      setSelectedState('');
      formRef.current.resetFields();
      message.success("Thank You! Our support team will contact you shortly.");
    } catch (error) {
      console.log(error);
      message.error("Error Occurred");
    }
  };



  const filterOption = (input, option) =>
    option?.label?.toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <ToastContainer />
      <Layouts>
        <SubHeader />
        <section className="home-banner md:min-h-[60vh] py-8 ">
          <div className="container mx-auto">
            <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
            <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
              <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
                <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                  Join us as a{" "}
                  <span className="text-secondaryColor">Doctor Partner</span>
                </h1>
                <p className="text-primaryColor md:text-White  font-light text-sm md:text-lg">
                We invite esteemed doctors to partner with us in transforming healthcare. Leverage your expertise with our innovative eClinics, reaching remote and underserved communities. Together, let's make quality healthcare accessible and affordable for all. Be a part of our mission to create a healthier world. Join us today!
                </p>
              </div>
              <div className="relative z-1 flex justify-end mt-5 md:mt-0">
                <div className="w-full md:w-[90%] md:h-[400px] rounded ">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Doctor%20Partner.webp"
                    alt="images"
                    className="w-full h-full image-boxshadow rounded-xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-lightGray">
          <div className="container mx-auto py-[90px]">
            <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-[50px]">
              Why Partner with{" "}
              <span className="text-secondaryColor">CureBay</span>?
            </h2>
            <div className="md:flex gap-5 justify-center flex-wrap">
              <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow  mb-5 md:mb-0 md:w-[32%] hoverWhite">
                <div className="mb-5">
                  <img src={ExtensiveCoverage} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Extensive Coverage
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  Gain access to a vast network of 100+ eClinics, extending your
                  services to remote areas.
                </p>
              </div>
              <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 md:w-[32%] hoverWhite">
                <div className="mb-5">
                  <img src={ReachUnderserved} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Reach Underserved Communities
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  Enhance healthcare access in underserved areas.
                </p>
              </div>
              <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 md:w-[32%] hoverWhite">
                <div className="mb-5">
                  <img src={StrongFootprint} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Strong Footprint
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  Partner with a provider rooted in remote areas, ensuring your
                  services reach the most in need.
                </p>
              </div>
              <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 md:w-[32%] hoverWhite">
                <div className="mb-5">
                  <img src={EnhancePatientCare} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Enhance Patient Care
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  Leverage CureBay's tech and infrastructure to boost patient
                  care and healthcare outcomes.
                </p>
              </div>
              <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 md:w-[32%] hoverWhite">
                <div className="mb-5">
                  <img src={CollaborativeApproach} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Collaborative Approach
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  Fostering patient-centric care through joint initiatives.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-White md:bg-lightGray">
          <div className="container mx-auto py-[50px] md:pt-0  pb-[90px]">
            <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-[50px]">
              <span className="text-secondaryColor">Join</span> us
            </h2>
            <div className="bg-White rounded-xl md:w-[90%] mx-auto md:p-10 md:common-shadow">
              <Form
                ref={formRef}
                layout="vertical"
                autoComplete="off"
                className="CommonForm grid md:grid-cols-2 md:gap-5"
              >
                <Form.Item
                  name="name"
                  label={
                    <p>
                      Name <span className="text-danger">*</span>
                    </p>
                  }
                >
                  <Input placeholder="Enter your name" defaultValue={profile.firstName}/>
                </Form.Item>
                <Form.Item
                  name="speciality"
                  label={
                    <p>
                      Speciality <span className="text-danger">*</span>
                    </p>
                  }
                >
                  <Input placeholder="Enter speciality"  defaultValue={profile.Speciality}/>
                </Form.Item>
                <Form.Item
                  name="yearsOfExperience"
                  label={
                    <p>
                      Years of Experience <span className="text-danger">*</span>
                    </p>
                  }
                >
                  <Input placeholder="Enter years of experience"  defaultValue={profile.yearsOfExperience} onKeyPress={handleKeyPress} />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label={
                    <p>
                      Phone Number <span className="text-danger">*</span>
                    </p>
                  }
                >
                  <InputNumber  disabled={Object.keys(userData).length === 0?false:true} placeholder="Enter number" defaultValue={profile.mobile}  onKeyPress={handleKeyPress} maxLength={10} />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={
                    <p>
                      Email Address <span className="text-danger">*</span>
                    </p>
                  }
                >
                  <Input  disabled={Object.keys(userData).length === 0?false:true} placeholder="Enter email" defaultValue={profile.email}/>
                </Form.Item>
                <Form.Item
                  name="city"
                  label={
                    <p>
                      City <span className="text-danger">*</span>
                    </p>
                  }
                >
                  <Input placeholder="Enter city  "   defaultValue={profile.city} />
                </Form.Item>
                <Form.Item
                  name="state"
                  label={
                    <p>
                      State <span className="text-danger">*</span>
                    </p>
                  }
                >
                  <Select
                    showSearch
                    onChange={handleChange}
                    filterOption={filterOption}
                    defaultValue={profile.state === "AP"?"Andhra Pradesh":
                      profile.state === "AS"?"Assam":
                      profile.state === "BR"?"Bihar":
                      profile.state === "CT"?"Chhattisgarh":
                      profile.state === "DL"?"Delhi":
                      profile.state === "GA"?"Goa":
                      profile.state === "GJ"?"Gujarat":
                      profile.state === "HR"?"Haryana":
                      profile.state === "HP"?"Himachal Pradesh":
                      profile.state === "JK"?"Jammu and Kashmir":
                      profile.state === "JH"?"Jharkhand":
                      profile.state === "KA"?"Karnataka":
                      profile.state === "KL"?"Kerala":
                      profile.state === "MP"?"Madhya Pradesh":
                      profile.state === "MH"?"Maharashtra":
                      profile.state === "MN"?"Manipur":
                      profile.state === "ML"?"Meghalaya":
                      profile.state === "MZ"?"Mizoram":
                      profile.state === "NL"?"Nagaland":
                      profile.state === "OD"?"Odisha":
                      profile.state === "PY"?"Puducherry":
                      profile.state === "PB"?"Punjab":
                      profile.state === "PJ"?"Rajasthan":
                      profile.state === "TN"?"Tamil Nadu":
                      profile.state === "TS"?"Telangana":
                      profile.state === "TR"?"Tripura":
                      profile.state === "UP"?"Uttar Pradesh":
                      profile.state === "UK"?"Uttarakhand":
                      profile.state === "WB"?"West Bengal":
                      profile.state
                    }
                    placeholder="Select your state"
                    optionFilterProp="children"
                    options={states.map((state) => {
                      return {
                        label: state.description,
                        value: state.description,
                      };
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name="pincode"
                  label={
                    <p>
                      PIN Code <span className="text-danger">*</span>
                    </p>
                  }
                >
                  <Input placeholder="Enter pincode"   defaultValue={profile.pinCode} />
                </Form.Item>
                         
              </Form>
              <button
                className="primary_btn !w-full mt-8 !h-[50px] !text-lg"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </section>
      </Layouts>
    </>
  );
}

export default DoctorsService;
