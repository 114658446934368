export const REPORT = {
    REQUEST_REPORTLIST : "REQUEST_REPORTLIST",
    SUCCESS_REPORTLIST: "SUCCESS_REPORTLIST",
    FAILED_REPORTLIST: "FAILED_REPORTLIST",

    REQUEST_UPLOADREPORT : "REQUEST_UPLOADREPORT",
    SUCCESS_UPLOADREPORT: "SUCCESS_UPLOADREPORT",
    FAILED_UPLOADREPORT: "FAILED_UPLOADREPORT",

    REQUEST_UPDATEREPORT : "REQUEST_UPDATEREPORT",
    SUCCESS_UPDATEREPORT: "SUCCESS_UPDATEREPORT",
    FAILED_UPDATEREPORT: "FAILED_UPDATEREPORT",

}