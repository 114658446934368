// import React, { useEffect, useState } from "react";
// import moment from "moment";
// import { DatePicker, TimePicker } from "antd";

// const PreferedDateAndTime = (props) => {
//   const {
//     preferDate,
//     preferTime,
//     changeDate,
//     handleChange,
//     limitTimeForLab,
//     labStartTime,
//     labEndTime,
//     labType
//   } = props;
//   const handleDateChange = (date, dateString) => {
//     changeDate(dateString)
//   };

//   const [preferredLabTime, setPreferredLabTime] = useState([
//     { label: "06 AM - 07 AM", value: "06 AM - 07 AM" },
//   { label: "07 AM - 08 AM", value: "07 AM - 08 AM" },
//   { label: "08 AM - 09 AM", value: "08 AM - 09 AM" },
//   { label: "09 AM - 10 AM", value: "09 AM - 10 AM" },
//   { label: "10 AM - 11 AM", value: "10 AM - 11 AM" },
//   { label: "11 AM - 12 PM", value: "11 AM - 12 PM" },
//   { label: "12 PM - 01 PM", value: "12 PM - 01 PM" },
//   { label: "01 PM - 02 PM", value: "01 PM - 02 PM" },
//   { label: "02 PM - 03 PM", value: "02 PM - 03 PM" },
//   { label: "03 PM - 04 PM", value: "03 PM - 04 PM" },
//   { label: "04 PM - 05 PM", value: "04 PM - 05 PM" },
//   { label: "05 PM - 06 PM", value: "05 PM - 06 PM" },
//   ])

//   useEffect(() =>{
//     if(labType == "Radiology"){
//       function generateTimeSlots(labStartTime, labEndTime) {
//         const timeSlots = [];
        
//         // Convert labStartTime and labEndTime to moment objects for easy manipulation
//         let startTime = moment(labStartTime, "HH:mm");
//         const endTime = moment(labEndTime, "HH:mm");
      
//         // Loop until start time reaches or exceeds end time
//         while (startTime.isBefore(endTime)) {
//           // Format the start and end of the current slot in "hh A" format
//           const slotStart = startTime.format("hh A");
//           const slotEnd = startTime.clone().add(1, 'hour').format("hh A");
      
//           // Push the formatted slot to the array
//           const timeSlot = {
//             label: `${slotStart} - ${slotEnd}`,
//             value: `${slotStart} - ${slotEnd}`
//           };
//           timeSlots.push(timeSlot);
      
//           // Move to the next hour
//           startTime.add(1, 'hour');
//         }
      
//         return timeSlots;
//       }

//       const availableTimeSlots = generateTimeSlots(labStartTime, labEndTime)
//       setPreferredLabTime(availableTimeSlots)
      
//     }
//   },[labType, labStartTime, labEndTime])

//   console.log(labType, labStartTime, labEndTime, "sdvihsdouvsgudvsdhvdsv")
//   const [type, setType] = useState("time");
//   return (
//     <div>
//       <div className="flex gap-3">
//         <DatePicker
//           placeholder="Select Date"
//           format="DD-MM-YYYY"
//           disabledDate={(current) => current && current < moment().startOf('day')}
//           // value={moment(preferDate).format("DD-MM-YYYY")}
//           onChange={handleDateChange}
//           className="w-full" />
//         <div className="w-full">
//           <select
//             class="border border-gray rounded-md px-2 text-xs h-10 bg-white border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 w-full"
//             id="procedureStatus"
//             name="procedureStatus"
//             value={preferTime}
//             onChange={handleChange}
//           >
//             <option value="">Select time </option>
//             {preferredLabTime.map((res, i) => (
//               <option
//                 key={i}
//                 className="py-1 text-sm text-green-600 font-semibold"
//                 value={res.code}
//                 disabled={limitTimeForLab(res)}
//               >
//                 {res.label}
//               </option>
//             ))}
//           </select>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PreferedDateAndTime;


import React, { useEffect, useState } from "react";
import moment from "moment";

const PreferedDateAndTime = (props) => {
  const {
    preferTime,
    changeDate,
    handleChange,
    limitTimeForLab,
    labStartTime,
    labEndTime,
    labType,
  } = props;

      const [preferredLabTime, setPreferredLabTime] = useState([
        { label: "06 AM - 07 AM", value: "06 AM - 07 AM" },
      { label: "07 AM - 08 AM", value: "07 AM - 08 AM" },
      { label: "08 AM - 09 AM", value: "08 AM - 09 AM" },
      { label: "09 AM - 10 AM", value: "09 AM - 10 AM" },
      { label: "10 AM - 11 AM", value: "10 AM - 11 AM" },
      { label: "11 AM - 12 PM", value: "11 AM - 12 PM" },
      { label: "12 PM - 01 PM", value: "12 PM - 01 PM" },
      { label: "01 PM - 02 PM", value: "01 PM - 02 PM" },
      { label: "02 PM - 03 PM", value: "02 PM - 03 PM" },
      { label: "03 PM - 04 PM", value: "03 PM - 04 PM" },
      { label: "04 PM - 05 PM", value: "04 PM - 05 PM" },
      { label: "05 PM - 06 PM", value: "05 PM - 06 PM" },
      { label: "06 PM - 07 PM", value: "06 PM - 07 PM" },
      ]);
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  console.log(labStartTime, labEndTime, "dsvsdvusdgvdsvd")
  // Generate time slots dynamically based on lab timings and lab type
  useEffect(() => {
    if (labType === "Radiology") {
      function generateTimeSlots(labStartTime, labEndTime) {
        const timeSlots = [];
        let startTime = moment(labStartTime, "HH:mm");
        const endTime = moment(labEndTime, "HH:mm");

        while (startTime.isBefore(endTime)) {
          const slotStartLabel = startTime.format("hh:mm A");
          const slotEndLabel = startTime.clone().add(1, "hour").format("hh:mm A");
          const slotStartValue = startTime.format("hh A");
          const slotEndValue = startTime.clone().add(1, "hour").format("hh A");

          timeSlots.push({
            label: `${slotStartLabel} - ${slotEndLabel}`,
            value: `${slotStartValue} - ${slotEndValue}`,
          });

          startTime.add(1, "hour");
        }
        return timeSlots;
      }

      const availableTimeSlots = generateTimeSlots(labStartTime, labEndTime);
      setPreferredLabTime(availableTimeSlots);
    }
  }, [labType, labStartTime, labEndTime, selectedDate]);

  // Handle date selection
  const handleDateClick = (date) => {
    setSelectedDate(date.format("YYYY-MM-DD"));
    changeDate(date.format("DD-MM-YYYY"));
  };

  // Generate dates for horizontal scrolling
  const generateDates = () => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = moment().add(i, "days");
      dates.push({
        label: i === 0 ? "Today" : i === 1 ? "Tomorrow" : date.format("dddd"),
        value: date,
      });
    }
    return dates;
  };

  return (
    <div>
      {/* Horizontal scrolling date selector */}
      <div className="flex overflow-x-auto gap-4 py-2">
        {generateDates().map((date, index) => (
          <div
            key={index}
            className={`px-4 py-2 rounded-lg cursor-pointer ${
              selectedDate === date.value.format("YYYY-MM-DD") ? "bg-[#004171] text-White" : "bg-[#F5FAFE] text-[#004171]"
            }`}
            onClick={() => handleDateClick(date.value)}
          >
            <div className="text-center text-xs font-normal ">{date.label}</div>
            <div className="text-center text-sm w-32">{date.value.format("DD MMM")}</div>
          </div>
        ))}
      </div>

      {/* Time slot container */}
      <div className="grid grid-cols-3 md:grid-cols-4 gap-4 mt-4">
        {preferredLabTime.map((slot, index) => {
          const isDisabled = limitTimeForLab(slot);
          console.log(isDisabled, "djvbsduvsduvs", slot.value)
          return (
            <div
              key={index}
              className={` rounded-lg p-2 text-xs text-center cursor-pointer ${
                isDisabled
                  ? "bg-[#EBECED] text-[#949494] cursor-not-allowed"
                  : preferTime === slot.value
                  ? "bg-primaryColor text-White border border-[#004171]"
                  : "bg-White border border-[#004171]"
              }`}
              onClick={() => {
                if (!isDisabled) handleChange({ target: { value: slot.value } });
              }}
            >
              {slot.label.split("-")[0]}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PreferedDateAndTime;
