import React, { useState } from "react";
import Layouts from "../Layouts";
import Clockimage from "../../Assets/Images/Icons/clock.svg";
import Calendarimage from "../../Assets/Images/Icons/calendar.svg";
import Backarrow from "../../Assets/Images/Icons/backarrow.svg";
import HoverBackarrow from "../../Assets/Images/Icons/backarrow-white.svg";
import { useHistory } from "react-router-dom";

function BlogDetails() {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  const handleNavigation = (path) => {
    history.push(path);
  };
  return (
    <Layouts>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px] md:w-[80%]">
          <div
            className="text-primaryColor hover:bg-primaryColor hover:text-White py-2 px-5 w-fit rounded-full mx-auto text-lg font-medium cursor-pointer flex gap-3 justify-center items-center mb-10"
            onClick={() => handleNavigation("/blogs")} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
          >
            <img src={isHovered ? HoverBackarrow : Backarrow} alt="images" />
            Back to blogs
          </div>
          <div>
            <h2 className="md:w-[60%] mx-auto text-center text-2xl md:text-4xl font-bold text-primaryColor !leading-normal mb-5">
              <span className="text-secondaryColor">Beat the Heat:</span> 10
              Tips for Staying Cool and Hydrated This Summer
            </h2>
            <p className="md:w-[50%] mx-auto text-center text-textGray ">
              As temperatures rise and the sun shines brighter, it's essential to prioritize staying cool and
              hydrated to beat the summer heat.
            </p>
            <div className="flex gap-4 justify-center mt-5">
              <div className="flex items-center gap-2">
                <img src={Clockimage} alt="images" />
                <p className="text-textGray text-sm ">6 minute read</p>
              </div>
              <div className="flex items-center gap-2">
                <img src={Calendarimage} alt="images" />
                <p className="text-textGray text-sm ">10th February, 2024</p>
              </div>
            </div>
          </div>
          <div className="w-full md:h-[500px] my-5 md:my-10">
            <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog1.jpg" alt="images" className="w-full h-full object-cover rounded-lg" />
          </div>
          <div className="bg-White rounded-xl p-5 md:p-10">
            <h6 className=" text-primaryColor text-xl font-semibold my-5">
              Introduction:
            </h6>
            <p className="text-textGray mb-4 md:leading-loose">
              As temperatures rise and the sun shines brighter, it's essential to prioritize staying cool and
              hydrated to beat the summer heat. Dehydration and heat-related illnesses can pose serious risks
              during hot weather, but with the right strategies, you can keep yourself refreshed and comfortable
              all summer long. Here are 10 valuable tips to help you stay cool and hydrated during the hottest
              months of the year.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Drink Plenty of Water:
            </h6>
            <p className="text-textGray md:leading-loose  mb-4">
              Staying hydrated is crucial in hot weather to replace fluids lost through sweat. Aim to drink at
              least 8 glasses of water a day, and increase your intake if you're spending time outdoors or
              engaging in physical activity.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Avoid Sugary and Alcoholic Beverages:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              While it may be tempting to reach for sugary sodas or alcoholic drinks to quench your thirst,
              these beverages can actually contribute to dehydration. Opt for water, herbal teas, or
              electrolyte-rich drinks to stay properly hydrated.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Eat Water-Rich Foods:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Incorporate hydrating foods into your diet, such as fruits and vegetables with high water content.
              Watermelon, cucumbers, strawberries, and celery are excellent choices that can help keep you
              hydrated while providing essential nutrients.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Wear Lightweight, Breathable Clothing:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Choose loose-fitting, light-colored clothing made from breathable fabrics like cotton or linen.
              These materials allow air to circulate around your body, helping to keep you cool and comfortable.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Seek Shade and Cool Environments:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              When spending time outdoors, seek shade whenever possible to avoid direct exposure to the sun's
              rays. Stay indoors during the hottest parts of the day, and use fans or air conditioning to
              maintain a comfortable temperature indoors.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Take Cool Showers or Baths:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Cool off quickly by taking a refreshing shower or bath. The cold water can help lower your body
              temperature and provide immediate relief from the heat.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Use Sun Protection:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Protect your skin from sunburn and heat-related illnesses by applying sunscreen with a high SPF,
              wearing a wide-brimmed hat, and using sunglasses to shield your eyes from harmful UV rays.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Limit Outdoor Activities During Peak Hours:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Avoid strenuous outdoor activities during the hottest parts of the day, typically between 10 a.m.
              and 4 p.m. If you must be outside, take frequent breaks in the shade and drink plenty of water.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Stay Cool at Night:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Keep your bedroom cool and comfortable for a restful night's sleep. Use lightweight bedding,
              open windows to allow for airflow, or use fans to circulate air and maintain a comfortable
              sleeping environment.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Listen to Your Body:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Pay attention to signs of heat-related illness, such as excessive sweating, dizziness, nausea, or
              headache. If you experience any of these symptoms, move to a cooler environment, drink water, and
              seek medical attention if necessary.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Conclusion:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              By following these 10 tips, you can stay cool, hydrated, and healthy throughout the summer months.
              Remember to prioritize hydration, seek shade and cool environments, and take precautions to protect
              yourself from the sun's harmful rays. With a little extra care and attention, you can beat the
              heat and enjoy all that summer has to offer safely and comfortably.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-White hidden md:block">
        <div className="container mx-auto py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
            <span className="text-secondaryColor">Recommended</span> Reads
          </h2>
          <div className="grid md:grid-cols-2 gap-5">
            <div
              className="bg-White common-shadow rounded-xl p-5 cursor-pointer w-[80%] mx-auto"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div className="w-full mb-3">
                <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog2.jpg" alt="images" className="w-full" />
              </div>
              <h6 className="text-primaryColor font-semibold text-lg mb-2">
                Summer Skincare Essentials: Protecting Your Skin from Sun Damage
              </h6>
              <p className="text-textGray text-sm mb-4">
                With the arrival of summer comes longer days, warmer weather, and plenty of outdoor activities.
                While soaking up the sun can be enjoyable, it's essential to prioritize skin protection to prevent
                sun damage and maintain healthy, radiant skin.
              </p>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
            <div
              className="bg-White common-shadow rounded-xl p-5 cursor-pointer w-[80%] mx-auto"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div className="w-full mb-3">
                <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog3.jpg" alt="images" className="w-full" />
              </div>
              <h6 className="text-primaryColor font-semibold text-lg mb-2">
                10 Tips for Managing Chronic Conditions with Telehealth
              </h6>
              <p className="text-textGray text-sm mb-4">
                In recent years, telehealth has emerged as a groundbreaking solution for managing chronic health
                conditions, offering convenience, accessibility, and personalized care from the comfort of one's
                home.
              </p>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default BlogDetails;
