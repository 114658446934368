import http from '../../../../Redux/Services/http-common'

export const saveUserAddress = async (data) =>{
    // var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    let response = await http.post(`AddressBook/`, data);
    try {
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
}