import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const KneeReplacement = ({data}) =>{
    return(
        <>
         <div>
                <h2 className="text-center mb-4" style={{color:"#004171", fontSize: 36, fontWeight: 600}} >{data?.sixthComp?.points[0].mainTitle}</h2>
                <h4 style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} className="text-center mb-4" >{data.sixthComp.points[0].maindescription}</h4>

            <div className="grid md:grid-cols-2 px-2 md:px-12" >
            {
                data?.sixthComp?.points.map((res,index) => (
                    <div className="p-8" style={{borderBottom: "dashed"}} >
                        <div className="flex items-start relative" > 
                            <img className="absolute top-2" src= {CheckIcon} height={20} width={20} />
                    <p className="pl-8" style={{color:"#676C80", fontSize: 18, fontWeight: 400}} >{res.title}</p>
                    
                        </div>
                        <p className="pl-8" style={{color:"#676C80", fontSize: 18, fontWeight: 400}} >{res.description}</p>
                    </div>
                ))
            }
                </div>
        </div>



        {
                data.tenthcomp?.length ? <div style={{backgroundColor:"#EBF8FF"}} >
                    {
                        data.tenthcomp?.map(res =>{
                            return(
                                <div className="grid md:grid-cols-2 py-4 md:py-8" style={{borderBottom: "dashed"}} >
                                    <div> <p className="p-8" style={{color:"#004171", fontSize: 36, fontWeight: 600}} >{res.title}</p> </div>
                                    <div>
                                    {res.description && <p className="p-8" style={{color:"#004171", fontSize: 20, fontWeight: 400}} >{res.description}</p>}
                    <ul className="ml-6 md:ml-12" style={{listStyleType: "disc",color:"#004171"}} >
                          {res?.points?.map(res =>{
                            if(res?.title)
                            return <li className="mb-2" ><p className="text-md font-semibold" style={{color:"#004171"}}>{res?.title}</p></li>
                          })}
                        </ul>
                         </div>
                   
                    </div>
                            )
                        })
                    }
                       {data?.tenthcomp?.length ? <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.tenthcomp[0]?.note}</h4> : null}
                </div> : null
                
              }
               {
                data.ninthComp?.points?.length ? <div className="py-12" >
                    <h4 style={{color:"#004171" , fontSize: 36, fontWeight: 600}} className="text-center mb-4" >{data.ninthComp.title}</h4>
                    <h4 style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} className="text-center mb-4" >{data.ninthComp.description}</h4>
                    <div className="grid md:grid-cols-2" >
                    {
                        data.ninthComp?.points?.map((res , index) =>{
                            return( <div className="p-8"> 
                             <div className="flex items-start relative" > 
                            <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                            <p className="" style={{color:"#005D8D", fontSize: 18, fontWeight: 600}} >{res.title}</p>
                        </div>
                        <p className="md:ml-10" style={{color:"#676C80", fontSize: 18, fontWeight: 400}} >{res.description}</p>
                            </div>
                   
                            )
                        })
                    }
                    </div>
                    <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.ninthComp?.points[0].mainnotes}</h4>
                </div> : null
              }
              </>
    )
}

export default KneeReplacement