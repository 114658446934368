// import React, { useEffect, useRef, useState } from "react";
// import CalendarIcon from "../../../../Assets/Images/Icons/calendar1.svg";
// import LabServices from "../../../../Redux/Services/labServices";
// import { DICOM_URL } from "../../../../config/constant";
// import { useDispatch, useSelector } from "react-redux";
// import { getLocalTime } from "../../../Helper/LocalTimeFormat";
// import doctorService from "../../../../Redux/Services/doctorService";
// import { Button, Checkbox, DatePicker, Form, Input, Modal, Upload, message } from "antd";
// import moment from "moment";
// import { getReport, uploadReport } from "../../../../Redux/Action/reportAction";
// import Dragger from "antd/es/upload/Dragger";
// import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
// import { encodeBase64File } from "../../../Helper/filebase64";

// function SummaryLabReports(props) {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [modal2Open, setModal2Open] = useState(false);
//   const [modal3Open, setModal3Open] = useState(false);
//   const [labReportList, setLabReport] = useState([]);
//   const patientCode = useSelector((state) => state.authReducer.patientCode);
//   const [filterPopup, setFilterPopup] = useState(false);
//   const [diagnositcDs, setdiagnositcDs] = useState([]);
//   const [selectedRow, setSelectedRow] = useState({});
//   const [filePopup, setfilePopup] = useState(false);
//   const [dicomToken, setDicomToken] = useState("");
//   const [dicomURL, setdicomURL] = useState("");
//   const [dicomPopup, setdicomPopup] = useState(false);
//   const [modalOpen, setModalOpen] = useState(false);
//   const userData = useSelector((state) => state.authReducer.patientData);
//   const [patient, setPatient] = useState(userData);
//   const dispatch = useDispatch();
//   const [fileType, setFileType] = useState("");
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [isLoading, setLoading] = useState(false);
//   const [selectedDataSource, setSelectedDataSource] = useState([]);
//   const [documentType, setDocumentType] = useState("");
//   const reportList = useSelector((state) => state.reportReducer.reportList);
//   const [mode, setMode] = useState("");
//   const [selectedReportId, setSelectedReportId] = useState("");
//   const [isChanged, setIsChanged] = useState(false);
//   const [pickedDate, setPickedDate] = useState(moment().format("DD/MM/YYYY"));
//   const [title, setTitle] = useState("");
//   const [documentTypeNew, setDocumentTypeNew] = useState("");
//   const [notes, setNotes] = useState("");
//   const [docName, setdocName] = useState("");
//   const [imageData, setImageData] = useState("");
//   const [imageType, setImageType] = useState("");
//   const [image, setImage] = useState(null);
//   const [date, setDate] = useState();
//   const formRef = useRef();
//   const [imageb64, setImageb64] = useState();

//   const handleImageChange = async (e) => {
//     setImage(e.target.files[0]);
//     if (e.target.files[0]) {
//       const res = await encodeBase64File(e.target.files[0]);
//       console.log(res);
//       formRef.current = res;
//       setImageb64(res);
//     }
//   };

//   const [fromDate, setFromDate] = useState(
//     moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
//   );
//   const [toDate, setToDate] = useState(
//     moment(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
//   );

//   const fetchAll = () => {
//     const payload = {
//       patientCode: props.patCode, //localStorage.getItem("patientprofile"),
//       docmentRequired: "Y",
//       reportType: "Lab"
//     };
//     LabServices.getLabOrderDetails1(payload).then(
//       (res) => {
//         setFilterPopup(false);
//         setLabReport(res.data);
//         console.log(res.data, 'details');
//       },
//       (err) => {
//         console.log(err);
//       }
//     );
//     loadDiagnosticCenter();
//   }

//   useEffect(() => {
//     fetchAll();
//     //   getDicomToken();
//   }, [props.patCode]);

//   const changeDate = (date, dateString) => {
//     setDate(dateString);
//   };

//   const getReportList = () => {
//     let patient = userData;
//     const payload = {
//       patientCode: props.patCode,
//       fromDate: fromDate,
//       toDate: toDate,

//     };

//     dispatch(getReport(payload));
//     // setopenNewEntryPopup(false);
//   };

//   const loadDiagnosticCenter = () => {
//     const payload = { status: 1, type: "D" };
//     LabServices.getDiagnosticCenter(payload).then(
//       (res) => {
//         console.log(res);
//         if (res.data) {
//           setdiagnositcDs(res.data);
//         }
//       },
//       (err) => {
//         console.log(err);
//       }
//     );
//   };

//   const viewDicomFile = (payload) => {
//     console.log("dicom file");
//     const requestOptions = {
//       method: "POST",
//       headers: { Authorization: "Bearer " + dicomToken },
//       // body: JSON.stringify({
//       //   username: 'integration',
//       //   password: 'integration'
//       // })
//     };
//     fetch(DICOM_URL + "viewer/" + payload, requestOptions)
//       .then((response) => response.json())
//       .then((data) => {
//         console.log(data);
//         setdicomURL(data.details);
//         setdicomPopup(true);
//         // setDicomToken(data.token);
//       });
//   };

//   const viewOrder = (e) => {
//     console.log("View Order", e);
//     setSelectedRow(e);
//     e.type =
//       e.type.toLowerCase() === "pdf"
//         ? "application/pdf"
//         : e.type.toLowerCase() === "jpg"
//           ? "image/jpeg"
//           : e.type.toLowerCase() === "jpeg"
//             ? "image/jpeg"
//             : e.type.toLowerCase() === "png"
//               ? "image/png"
//               : e.type;
//     if (e.type.toLowerCase() == "dicom") {
//       viewDicomFile(e.dicomId);
//     } else {
//       setfilePopup(true);
//     }
//   };

//   const showModal = () => {
//     setModalOpen(true);
//   };

//   const handleCancel = () => {
//     setModalOpen(false);
//   };

//   const showModal2 = (data) => {
//     setMode("updatereport");
//     setSelectedReportId(data.id);
//     setIsChanged(false);
//     setPickedDate(moment(data.createdDate).format("DD/MM/YYYY"));
//     if (data.title) {
//       setTitle(data.title);
//     }
//     if (data.reportType) {
//       setDocumentType(data.reportType);
//     }

//     if (data.documentType) {
//       setDocumentTypeNew(data.documentType);
//     }
//     if (data.notes) {
//       setNotes(data.notes);
//     }
//     if (data.docName) {
//       setdocName(data.docName);
//     }
//     setModal2Open(true);
//   };

//   const handleCancel2 = () => {
//     setModal2Open(false);
//   };

//   const onDownloadDocument = async (name) => {
//     let url = selectedRow.photoName
//       ? `${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
//       : "";
//     let filename = selectedRow.type;

//     doctorService.imagetoData(selectedRow.photoName).then((res) => {
//       var base64;
//       if (filename === "application/pdf") {
//         base64 = "data:application/pdf;base64,";
//       } else {
//         base64 = "data:image/png;base64,";
//       }
//       //alert(JSON.stringify(res.data.fileData))
//       var FileSaver = require("file-saver");
//       FileSaver.saveAs(base64 + res.data.fileData, selectedRow.photoName);
//     });
//     console.log(selectedRow.photoName, "selectedRow.photoName");
//     setfilePopup(false);
//   };

//   const onCancelDocument = () => {
//     setfilePopup(false);
//   };

//   const renderDocumentFooter = (name) => {
//     return (
//       <div>
//         <button
//           onClick={() => onDownloadDocument(name)}
//           className="bg-primaryColor  text-sm text-White font-normal rounded-md py-3 px-3 mr-2"
//         >
//           Download
//         </button>

//         <button
//           onClick={() => onCancelDocument(name)}
//           className="bg-primaryColor  text-sm text-White font-normal rounded-md py-3 px-3 mr-2"
//         >
//           Cancel
//         </button>
//       </div>
//     );
//   };

//   const handleFileChange = async (info) => {
//     const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
//     if (!allowedFileTypes.includes(info.file.type)) {
//       message.error('You can only upload JPG/PNG images or PDF files!');
//       return;
//     } else {
//       const base64File = info.file.originFileObj;
//       let b64File = await encodeBase64File(base64File);
//       setSelectedFile(b64File)
//       let orgType = info.file.type == 'image/jpeg' ? "jpeg" : info.file.type == 'image/png' ? "png" : info.file.type == 'application/pdf' ? "pdf" : "svg"
//       setFileType(orgType)
//     }
//   };

//   const handleFinish = (values) => {
//     console.log("values", values);
//     console.log(formRef.current);
//     const payload = {
//       patientCode: props.patCode,
//       diagnosticName: values.diagnosticName,
//       labTestName: values.labTestName,
//       document: selectedFile,
//       ePrescriptionGen: -1,
//       ePrescriptionStatus: -1,
//       status: 1,
//       documentUploadByVisit: 0,
//       documentRequired: "N",
//       reportType: "Lab",
//       documentType: fileType,
//       documentReceivedSource: "self",
//       createdBy: userData.code,
//       modifiedBy: userData.code,
//       enteredBy: userData.code,
//       createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
//       givenDate: moment(date).format("YYYY-MM-DD hh:mm:ss"),
//       patientName: userData.firstName,
//       patientMobile: userData.mobile,
//       visitId: 0,
//     };
//     dispatch(uploadReport(payload)).then((res) => {
//       setLoading(false);
//       getReportList();
//       fetchAll();
//       message.success("Lab report added");
//       formRef.current.resetFields();
//       setImage('');
//       setModalOpen(false);
//     });

//     console.log(payload);
//   };

//   const handleFileUpload = (file) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onloadend = () => {
//       let imageArray = [];
//       imageArray = reader.result.split(",");
//       const imageDataUrl = imageArray[1];
//       const imageType = file.type;
//       // Extract file extension from imageType
//       const fileExtension = imageType.split("/")[1];
//       setImageData(imageDataUrl);
//       setImageType(fileExtension);
//     };
//   };

//   const handleRemoveFile = () => {
//     setImageData("");
//   };

//   const props1 = {
//     customRequest: ({ file, onSuccess }) => {
//       handleFileUpload(file);
//       message.success(`${file.name} uploaded successfully.`);
//       onSuccess("ok");
//     },
//   };

//   return (
//     <div>
//       <div className="bg-White rounded-md">
//         <div className="bg-[#d2ffeb] px-5 py-3 rounded-md flex justify-between items-center">
//           <h5 className=" text-lg font-medium">My Lab Reports</h5>
//           {/* <p className=" text-sm underline cursor-pointer">Add Report</p> */}
//           <div
//             className=" text-sm underline cursor-pointer"
//             onClick={showModal}
//           >
//             Add Report
//           </div>
//         </div>
//         {/* <div className="p-5 h-[300px] overflow-auto">
//           {labReportList.length > 0 &&
//             labReportList.map((lab, idx) => (
//               <div className="bg-White common-shadow p-5 mb-5">
//                 <h5 className="text-primaryColor text-lg font-semibold mb-1">
//                   {lab.labTestName}
//                 </h5>
//                 <p className="text-textGray text-sm font-light">
//                   Diagnostics : <span>{lab.diagnosticName}</span>
//                 </p>
//                 <div className="flex justify-between items-center mt-3">
//                   <div className="flex gap-2 text-textGray text-sm">
//                     <img src={CalendarIcon} alt="images" className="w-[13px]" />
//                     {getLocalTime(lab.createdDate)}
//                   </div>
//                   <div
//                     onClick={() => viewOrder(lab)}
//                     className="text-secondaryColor underline cursor-pointer font-medium"
//                   >
//                     View Report
//                   </div>
//                 </div>
//               </div>
//             ))}
//         </div> */}
//         <div className="p-5 h-[300px] overflow-auto">
//           {labReportList.map((user, i) => (
//             <div className="bg-White common-shadow p-5 mb-5" key={i}>
//               {/* <div className="text-primaryColor text-lg font-semibold mb-1">
//                 {user.title}
//               </div> */}
//                <div className="text-textGray text-sm flex justify-end my-1">
//                   <p className="text-base font-normal text-secondaryColor">
//                     {user.documentReceivedSource !== 'self' ? 'CureBay' : 'MyRef'}
//                   </p>
//                 </div>
//               <div className="flex flex-wrap text-textGray w-full justify-between gap-3">
//                 <div className="text-textGray text-sm">
//                   <label className="font-light">Date</label>
//                   <p className="font-medium mt-1">
//                     {moment(user.givenDate).format("MM/DD/YYYY")}
//                   </p>
//                 </div>
//                 <div className="text-textGray text-sm">
//                   <label className="font-light">Diagnostics</label>
//                   <p className="font-medium mt-1">
//                     {user.diagnosticName}
//                   </p>
//                 </div>
//                 <div className="text-textGray text-sm">
//                   <label className="font-light">Report Type</label>
//                   <p className="font-medium mt-1">{user.documentType}</p>
//                 </div>
//                 <div className="text-textGray text-sm">
//                   <label className="font-light">Test</label>
//                   <p className="font-medium mt-1">{user.labTestName}</p>
//                 </div>
//               </div>

//               <div className="flex gap-5 justify-end items-center font-medium mt-2">
//                 <div
//                   onClick={() => showModal2(user)}
//                   className="text-secondaryColor underline cursor-pointer"
//                 >
//                   View Report
//                 </div>
//               </div>
//             </div>
//           ))}

//         </div>
//       </div>

//       <Modal
//         title="Add Lab Reports"
//         className="commonModal"
//         centered={true}
//         footer={false}
//         open={modalOpen}
//         onOk={showModal}
//         onCancel={handleCancel}
//         width="50%"
//       >
//         <Form
//           ref={formRef}
//           onFinish={handleFinish}
//           layout="vertical"
//           autoComplete="off"
//           className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
//         >
//           <Form.Item className="mb-0" label="Date" name="date">
//             <DatePicker placeholder="Date " className="w-full h-12" onChange={changeDate} />
//           </Form.Item>
//           {/*
//           <Form.Item className="mb-0" label="Title *" name="title">
//             <Input placeholder="Input placeholder" />
//           </Form.Item> */}

//           <Form.Item className="mb-0" label="Diagnostics *" name="diagnosticName">
//             <Input placeholder="Diagnostics" />
//           </Form.Item>

//           <Form.Item className="mb-0" label="Lab Tests *" name="labTestName">
//             <Input placeholder="Lab Tests" />
//           </Form.Item>

//           {/* <Form.Item
//             className="mb-0"
//             label="Report Type *"
//             name="documentType"
//             initialValue="Report" // Set the default value here
//           >
//             <Input placeholder="Choose Report type" />
//           </Form.Item>
//           <Form.Item
//             className="mb-0"
//             label="Data Source*"
//             name="dataSource
//             "
//             initialValue={[]}
//           >
//             <Checkbox.Group
//               options={[
//                 { label: 'Self', value: 'self' },
//                 { label: 'Curebay', value: 'curebay' },
//               ]}
//               onChange={(values) => {
//                 const newValue = values.length > 0 ? [values[values.length - 1]] : [];
//                 setSelectedDataSource(newValue);
//               }}
//               value={selectedDataSource}
//             />
//           </Form.Item> */}
//           {/* <Form.Item name="upload" label="Upload Test Report *">
//             <input
//               className="h-[50px] border border-gray w-full rounded-md px-3 py-2 flex items-center cursor-pointer relative z-[5]"
//               placeholder="Upload Prescription"
//               type="file"
//               onChange={handleImageChange}
//             />
//             {!image && (
//               <div className="absolute bg-White inset-0 flex justify-center items-center cursor-pointer">
//                 <div className="text-textGray text-sm ">
//                   {" "}
//                   <CloudUploadOutlined className="pr-3" />
//                 </div>
//               </div>
//             )}
//             {image && (
//               <div className="absolute flex justify-between top-[23%] left-[3%] w-[90%]">
//                 <p>{image.name}</p>
//                 <DeleteOutlined className="text-danger" />
//               </div>
//             )}
//           </Form.Item> */}
//           <Form.Item className="mb-0" label="Upload Prescription" name="uploadTestReport">
//             <Dragger className="h-[50px] p-0" multiple={false} onChange={handleFileChange}>
//               <CloudUploadOutlined className="p-0 m-0" />
//             </Dragger>
//           </Form.Item>
//           <Form.Item></Form.Item>
//           <Form.Item></Form.Item>
//           <Form.Item></Form.Item>
//           <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
//             <button
//               onClick={handleCancel}
//               className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
//             >
//               Cancel
//             </button>
//             <button
//               // onClick={handleSave}
//               type="submit"
//               className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
//             >
//               Save
//             </button>
//           </div>
//         </Form>
//       </Modal>

//       <Modal
//         title={
//           <p className="font-semibold text-primaryColor text-xl">
//             View Reports
//           </p>
//         }
//         width={1000}
//         open={modal2Open}
//         onCancel={handleCancel2}
//         style={{ top: 20 }}
//         footer={[]}
//       >
//         <div>
//           <div className="mb-3">
//             <label className="font-semibold">Date:</label>
//             <p>{moment(pickedDate).format("MM/DD/YYYY")}</p>
//           </div>
//           <div className="mb-3">
//             <label className="font-semibold">Document Type:</label>
//             <p>{documentType}</p>
//           </div>
//           {/* <div className="mb-3">
//             <label className="font-semibold">Notes:</label>
//             <p>{notes}</p>
//           </div> */}
//           <div className="mb-3">
//             <label className="font-semibold">Uploaded Document:</label>
//             {documentTypeNew === "png" || documentTypeNew === "jpg" ? (
//               <img
//                 src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
//                 alt=""
//                 className="rounded-lg w-[50px] h-[50px]"
//               />
//               // <div>
//               // </div>
//             ) : documentTypeNew === "pdf" ? (
//               <iframe
//                 title="PDF Viewer"
//                 width="100%"
//                 height="500px"
//                 src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
//               ></iframe>
//             ) : (
//               <img
//                 src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
//                 alt=""
//                 className="w-full"
//               />
//             )}
//           </div>
//         </div>
//       </Modal>

//       {/* <Modal
//         title={
//           <p className="font-bold text-primaryColor text-xl">Lab Report</p>
//         }
//         visible={filePopup}
//         modal={false}
//         width="60%"
//         onCancel={() => setfilePopup(false)}
//         // style={{ width: "98vw", height: "100vh" }}

//         footer={renderDocumentFooter("displayModal")}
//         // onHide={() => onCancelDocument("displayModal")}
//       >
//         <p className="p-m-0">
//           <div className="lg:flex pt-2 ">
//             <div className="w-full">
//               <div className="lg:flex lg:pt-1 g:space-x-10 ">
//                 {selectedRow?.type !== "application/pdf" ? (
//                   <img
//                     style={{ width: "100%", height: "500px" }}
//                     className="box target object-contain"
//                     src={
//                       selectedRow.photoName
//                         ? `${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
//                         : ""
//                     }
//                     title="Dicom Viewer"
//                     type={selectedRow.type}
//                   />
//                 ) : (
//                   <iframe
//                     id="abc"
//                     className="box target w-full h-auto"
//                     style={{ width: "100%", height: "500px" }}
//                     src={
//                       selectedRow.photoName
//                         ? `${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
//                         : ""
//                     }
//                     title="Dicom Viewer"
//                     type={selectedRow.type}

//                     // style={iframeStyleLab}
//                   />
//                 )}
//               </div>
//             </div>
//           </div>
//         </p>
//       </Modal> */}
//     </div>
//   );
// }

// export default SummaryLabReports;

import React, { useEffect, useRef, useState } from "react";
import CalendarIcon from "../../../../Assets/Images/Icons/calendar1.svg";
import LabServices from "../../../../Redux/Services/labServices";
import { DICOM_URL } from "../../../../config/constant";
import { useDispatch, useSelector } from "react-redux";
import { getLocalTime } from "../../../Helper/LocalTimeFormat";
import doctorService from "../../../../Redux/Services/doctorService";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Upload,
  message,
} from "antd";
import moment from "moment";
import { getReport, uploadReport } from "../../../../Redux/Action/reportAction";
import Dragger from "antd/es/upload/Dragger";
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { encodeBase64File } from "../../../Helper/filebase64";
import { ToastContainer, toast } from "react-toastify";

function SummaryLabReports(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [labReportList, setLabReport] = useState([]);
  const patientCode = useSelector((state) => state.authReducer.patientCode);
  const [filterPopup, setFilterPopup] = useState(false);
  const [diagnositcDs, setdiagnositcDs] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [filePopup, setfilePopup] = useState(false);
  const [dicomToken, setDicomToken] = useState("");
  const [dicomURL, setdicomURL] = useState("");
  const [dicomPopup, setdicomPopup] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const userData = useSelector((state) => state.authReducer.patientData);
  const [patient, setPatient] = useState(userData);
  const dispatch = useDispatch();
  const [fileType, setFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const reportList = useSelector((state) => state.reportReducer.reportList);
  const [mode, setMode] = useState("");
  const [selectedReportId, setSelectedReportId] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [pickedDate, setPickedDate] = useState(moment().format("DD/MM/YYYY"));
  const [title, setTitle] = useState("");
  const [documentTypeNew, setDocumentTypeNew] = useState("");
  const [notes, setNotes] = useState("");
  const [docName, setdocName] = useState("");
  const [imageData, setImageData] = useState("");
  const [imageType, setImageType] = useState("");
  const [image, setImage] = useState(null);
  const [date, setDate] = useState();
  const formRef = useRef();
  const [imageb64, setImageb64] = useState();

  const handleImageChange = async (e) => {
    setImage(e.target.files[0]);
    if (e.target.files[0]) {
      const res = await encodeBase64File(e.target.files[0]);
      console.log(res);
      formRef.current = res;
      setImageb64(res);
    }
  };

  const [fromDate, setFromDate] = useState(
    moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
  );
  const [toDate, setToDate] = useState(
    moment(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
  );

  const fetchAll = () => {
    const payload = {
      patientCode: props.patCode, //localStorage.getItem("patientprofile"),
      docmentRequired: "Y",
      reportType: "Lab",
    };
    LabServices.getLabOrderDetails1(payload).then(
      (res) => {
        setFilterPopup(false);
        setLabReport(res.data);
        console.log(res.data, "details");
      },
      (err) => {
        console.log(err);
      }
    );
    loadDiagnosticCenter();
  };

  useEffect(() => {
    fetchAll();
    //   getDicomToken();
  }, [props.patCode]);

  const changeDate = (date, dateString) => {
    setDate(dateString);
  };

  const getReportList = () => {
    let patient = userData;
    const payload = {
      patientCode: props.patCode,
      fromDate: fromDate,
      toDate: toDate,
    };

    dispatch(getReport(payload));
    // setopenNewEntryPopup(false);
  };

  const loadDiagnosticCenter = () => {
    const payload = { status: 1, type: "D" };
    LabServices.getDiagnosticCenter(payload).then(
      (res) => {
        console.log(res);
        if (res.data) {
          setdiagnositcDs(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const viewDicomFile = (payload) => {
    console.log("dicom file");
    const requestOptions = {
      method: "POST",
      headers: { Authorization: "Bearer " + dicomToken },
      // body: JSON.stringify({
      //   username: 'integration',
      //   password: 'integration'
      // })
    };
    fetch(DICOM_URL + "viewer/" + payload, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setdicomURL(data.details);
        setdicomPopup(true);
        // setDicomToken(data.token);
      });
  };

  const viewOrder = (e) => {
    console.log("View Order", e);
    setSelectedRow(e);
    e.type =
      e.type.toLowerCase() === "pdf"
        ? "application/pdf"
        : e.type.toLowerCase() === "jpg"
        ? "image/jpeg"
        : e.type.toLowerCase() === "jpeg"
        ? "image/jpeg"
        : e.type.toLowerCase() === "png"
        ? "image/png"
        : e.type;
    if (e.type.toLowerCase() == "dicom") {
      viewDicomFile(e.dicomId);
    } else {
      setfilePopup(true);
    }
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const showModal2 = (data) => {
    setMode("updatereport");
    setSelectedReportId(data.id);
    setIsChanged(false);
    setPickedDate(moment(data.createdDate).format("MM/DD/YYYY"));
    if (data.title) {
      setTitle(data.title);
    }
    if (data.reportType) {
      setDocumentType(data.reportType);
    }

    if (data.documentType) {
      setDocumentTypeNew(data.documentType);
    }
    if (data.notes) {
      setNotes(data.notes);
    }
    if (data.docName) {
      setdocName(data.docName);
    }
    setModal2Open(true);
  };

  const handleCancel2 = () => {
    setModal2Open(false);
  };

  const onDownloadDocument = async (name) => {
    let url = selectedRow.photoName
      ? ` ${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
      : "";
    let filename = selectedRow.type;

    doctorService.imagetoData(selectedRow.photoName).then((res) => {
      var base64;
      if (filename === "application/pdf") {
        base64 = "data:application/pdf;base64,";
      } else {
        base64 = "data:image/png;base64,";
      }
      //alert(JSON.stringify(res.data.fileData))
      var FileSaver = require("file-saver");
      FileSaver.saveAs(base64 + res.data.fileData, selectedRow.photoName);
    });
    console.log(selectedRow.photoName, "selectedRow.photoName");
    setfilePopup(false);
  };

  const onCancelDocument = () => {
    setfilePopup(false);
  };

  const renderDocumentFooter = (name) => {
    return (
      <div>
        <button
          onClick={() => onDownloadDocument(name)}
          className="bg-primaryColor  text-sm text-White font-normal rounded-md py-3 px-3 mr-2"
        >
          Download
        </button>

        <button
          onClick={() => onCancelDocument(name)}
          className="bg-primaryColor  text-sm text-White font-normal rounded-md py-3 px-3 mr-2"
        >
          Cancel
        </button>
      </div>
    );
  };

  const handleFileChange = async (info) => {
    const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];
    if (!allowedFileTypes.includes(info.file.type)) {
      message.error("You can only upload JPG/PNG images or PDF files!");
      return;
    } else {
      const base64File = info.file.originFileObj;
      let b64File = await encodeBase64File(base64File);
      setSelectedFile(b64File);
      let orgType =
        info.file.type == "image/jpeg"
          ? "jpeg"
          : info.file.type == "image/png"
          ? "png"
          : info.file.type == "application/pdf"
          ? "pdf"
          : "svg";
      setFileType(orgType);
    }
  };

  const handleFinish = (values) => {
    console.log("values", values);
    console.log(formRef.current);
    const payload = {
      patientCode: props.patCode,
      diagnosticName: values.diagnosticName,
      labTestName: values.labTestName,
      document: selectedFile,
      ePrescriptionGen: -1,
      ePrescriptionStatus: -1,
      status: 1,
      documentUploadByVisit: 0,
      documentRequired: "N",
      reportType: "Lab",
      documentType: fileType,
      documentReceivedSource: "self",
      createdBy: userData.code,
      modifiedBy: userData.code,
      enteredBy: userData.code,
      createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      givenDate: moment(date).format("YYYY-MM-DD hh:mm:ss"),
      patientName: userData.firstName,
      patientMobile: userData.mobile,
      visitId: 0,
    };
    dispatch(uploadReport(payload)).then((res) => {
      setLoading(false);
      getReportList();
      fetchAll();
      // message.success("Lab report added");
      setTimeout(() => {
        toast("Lab report uploaded Successfully");
      }, 500);
      formRef.current.resetFields();
      setImage("");
      setModalOpen(false);
    });

    console.log(payload);
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      let imageArray = [];
      imageArray = reader.result.split(",");
      const imageDataUrl = imageArray[1];
      const imageType = file.type;
      // Extract file extension from imageType
      const fileExtension = imageType.split("/")[1];
      setImageData(imageDataUrl);
      setImageType(fileExtension);
    };
  };

  const handleRemoveFile = () => {
    setImageData("");
  };

  const props1 = {
    customRequest: ({ file, onSuccess }) => {
      handleFileUpload(file);
      message.success(`${file.name} uploaded successfully.`);
      onSuccess("ok");
    },
  };
  const disableFutureDates = (current) => {
    return current && current > moment().endOf('day'); 
  };

  return (
    <>
      <ToastContainer />
      <div>
        <div className="bg-White rounded-md">
          <div className="bg-[#d2ffeb] px-5 py-3 rounded-md flex justify-between items-center">
            <h5 className=" text-lg font-medium">My Lab Reports</h5>
            {/* <p className=" text-sm underline cursor-pointer">Add Report</p> */}
            <div
              className=" text-sm underline cursor-pointer"
              onClick={showModal}
            >
              Add Reports
            </div>
          </div>
          {/* <div className="p-5 h-[300px] overflow-auto">
          {labReportList.length > 0 &&
            labReportList.map((lab, idx) => (
              <div className="bg-White common-shadow p-5 mb-5">
                <h5 className="text-primaryColor text-lg font-semibold mb-1">
                  {lab.labTestName}
                </h5>
                <p className="text-textGray text-sm font-light">
                  Diagnostics : <span>{lab.diagnosticName}</span>
                </p>
                <div className="flex justify-between items-center mt-3">
                  <div className="flex gap-2 text-textGray text-sm">
                    <img src={CalendarIcon} alt="images" className="w-[13px]" />
                    {getLocalTime(lab.createdDate)}
                  </div>
                  <div
                    onClick={() => viewOrder(lab)}
                    className="text-secondaryColor underline cursor-pointer font-medium"
                  >
                    View Report
                  </div>
                </div>
              </div>
            ))}
        </div> */}
          <div className="p-5 h-[300px] overflow-auto">
            {labReportList.map((user, i) => (
              <div className="bg-White common-shadow p-5 mb-5" key={i}>
                {/* <div className="text-primaryColor text-lg font-semibold mb-1">
                {user.title}
              </div> */}
                <div className="text-textGray text-sm flex justify-start my-1">
                  <p className="text-base font-normal text-secondaryColor">
                    {user.documentReceivedSource !== "self"
                      ? "CureBay"
                      : "MyRef"}
                  </p>
                </div>
                <br />
                <div className="flex flex-col flex-wrap text-textGray w-full justify-between gap-3">
                  <div className="text-textGray text-sm flex justify-between">
                    <div className="w-1/2">
                      <label className="font-light">Date</label>
                      <p className="font-medium mt-1">
                        {moment(user.createdDate).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <label className="font-light">Diagnostics</label>
                      <p className="font-medium mt-1">{user.diagnosticName}</p>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="text-textGray text-sm w-1/2">
                      <label className="font-light">Report Type</label>
                      <p className="font-medium mt-1">{user.documentType}</p>
                    </div>
                    <div className="text-textGray text-sm w-1/2">
                      <label className="font-light">Test</label>
                      <p className="font-medium mt-1">{user.labTestName}</p>
                    </div>
                  </div>
                </div>

                <div className="flex gap-5 justify-end items-center font-medium mt-2">
                  <div
                    onClick={() => showModal2(user)}
                    className="text-secondaryColor underline cursor-pointer"
                  >
                    View Report
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

      <Modal
        title="Add Lab Reports"
        className="commonModal"
        centered={true}
        footer={false}
        open={modalOpen}
        onOk={showModal}
        onCancel={handleCancel}
        width="40%"
      >
        <Form
          ref={formRef}
          onFinish={handleFinish}
          layout="vertical"
          autoComplete="off"
          className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
        >
          <Form.Item className="mb-0" label="Date" name="date">
            <DatePicker
              placeholder="Date "
              className="w-full h-12"
              onChange={changeDate}
              disabledDate={disableFutureDates}
            />
          </Form.Item>
          {/* 
          <Form.Item className="mb-0" label="Title *" name="title">
            <Input placeholder="Input placeholder" />
          </Form.Item> */}

            <Form.Item
              className="mb-0"
              label={<span>Diagnostics</span>}
              name="diagnosticName"
              rules={[
                { required: true, message: "Please enter diagnostic name" },
              ]}
            >
              <Input placeholder="Diagnostics" />
            </Form.Item>

            <Form.Item
              className="mb-0"
              label={<span>Lab Tests</span>}
              name="labTestName"
              rules={[
                { required: true, message: "Please enter lab test name" },
              ]}
            >
              <Input placeholder="Lab Tests" />
            </Form.Item>

            {/* <Form.Item
            className="mb-0"
            label="Report Type *"
            name="documentType"
            initialValue="Report" // Set the default value here
          >
            <Input placeholder="Choose Report type" />
          </Form.Item>
          <Form.Item
            className="mb-0"
            label="Data Source*"
            name="dataSource
            "
            initialValue={[]}
          >
            <Checkbox.Group
              options={[
                { label: 'Self', value: 'self' },
                { label: 'Curebay', value: 'curebay' },
              ]}
              onChange={(values) => {
                const newValue = values.length > 0 ? [values[values.length - 1]] : [];
                setSelectedDataSource(newValue);
              }}
              value={selectedDataSource}
            />
          </Form.Item> */}
            {/* <Form.Item name="upload" label="Upload Test Report *">
            <input
              className="h-[50px] border border-gray w-full rounded-md px-3 py-2 flex items-center cursor-pointer relative z-[5]"
              placeholder="Upload Prescription"
              type="file"
              onChange={handleImageChange}
            />
            {!image && (
              <div className="absolute bg-White inset-0 flex justify-center items-center cursor-pointer">
                <div className="text-textGray text-sm ">
                  {" "}
                  <CloudUploadOutlined className="pr-3" />
                </div>
              </div>
            )}
            {image && (
              <div className="absolute flex justify-between top-[23%] left-[3%] w-[90%]">
                <p>{image.name}</p>
                <DeleteOutlined className="text-danger" />
              </div>
            )}
          </Form.Item> */}
            <Form.Item
              className="mb-0"
              label="Upload Report"
              name="uploadTestReport"
              rules={[
                { required: true, message: "Please upload a test report" },
              ]}
            >
              <Dragger
                className="h-[50px] p-0"
                multiple={false}
                onChange={handleFileChange}
              >
                <CloudUploadOutlined className="p-0 m-0" />
              </Dragger>
            </Form.Item>
            <Form.Item></Form.Item>
            <div className="grid grid-cols-2 items-center gap-5 mt-4 mb-5">
              <button
                onClick={handleCancel}
                className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
              >
                Cancel
              </button>
              <button
                // onClick={handleSave}
                type="submit"
                className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
              >
                Save
              </button>
            </div>
          </Form>
        </Modal>

        <Modal
          title={
            <p className="font-semibold text-primaryColor text-xl">
              View Reports
            </p>
          }
          width={1000}
          open={modal2Open}
          onCancel={handleCancel2}
          style={{ top: 20 }}
          footer={[]}
        >
          <div>
            <div className="mb-3">
              <label className="font-semibold">Date:</label>
              <p>{moment(pickedDate).format("MM/DD/YYYY")}</p>
            </div>
            <div className="mb-3">
              <label className="font-semibold">Document Type:</label>
              <p>{documentType}</p>
            </div>
            {/* <div className="mb-3">
            <label className="font-semibold">Notes:</label>
            <p>{notes}</p>
          </div> */}
            <div className="mb-3">
              <label className="font-semibold">Uploaded Document:</label>
              {documentTypeNew === "png" || documentTypeNew === "jpg" ? (
                <img
                  src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
                  alt=""
                  className="rounded-lg "
                />
              ) : // <div>
              // </div>
              documentTypeNew === "pdf" ? (
                <iframe
                  title="PDF Viewer"
                  width="100%"
                  height="500px"
                  src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
                ></iframe>
              ) : (
                <img
                  src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
                  alt=""
                  className="w-full"
                />
              )}
            </div>
          </div>
        </Modal>

        {/* <Modal
        title={
          <p className="font-bold text-primaryColor text-xl">Lab Report</p>
        }
        visible={filePopup}
        modal={false}
        width="60%"
        onCancel={() => setfilePopup(false)}
        // style={{ width: "98vw", height: "100vh" }}

        footer={renderDocumentFooter("displayModal")}
        // onHide={() => onCancelDocument("displayModal")}
      >
        <p className="p-m-0">
          <div className="lg:flex pt-2 ">
            <div className="w-full">
              <div className="lg:flex lg:pt-1 g:space-x-10 ">
                {selectedRow?.type !== "application/pdf" ? (
                  <img
                    style={{ width: "100%", height: "500px" }}
                    className="box target object-contain"
                    src={
                      selectedRow.photoName
                        ? ${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}
                        : ""
                    }
                    title="Dicom Viewer"
                    type={selectedRow.type}
                  />
                ) : (
                  <iframe
                    id="abc"
                    className="box target w-full h-auto"
                    style={{ width: "100%", height: "500px" }}
                    src={
                      selectedRow.photoName
                        ? ${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}
                        : ""
                    }
                    title="Dicom Viewer"
                    type={selectedRow.type}

                    // style={iframeStyleLab}
                  />
                )}
              </div>
            </div>
          </div>
        </p>
      </Modal> */}
      </div>
    </>
  );
}

export default SummaryLabReports;
