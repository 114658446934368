import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const Piles = ({data}) =>{
    console.log(data, "sdvkjdsiuvdsbgvdsvds")
    return(
        <>
        {
                data.sixthComp?.points?.length ? <div className="py-12" >
                    <h4 style={{color:"#004171" , fontSize: 36, fontWeight: 600}} className="text-center mb-4 text-xl md:text-2xl" >{data.sixthComp.points[0].mainTitle}</h4>
                    <h4 style={{color:"#004171" , fontSize: 26, fontWeight: 600}} className="text-center mb-4 text-lg md:text-2xl" >{data.sixthComp.points[0].title}</h4>
                    <h4 style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} className="text-center mb-4 text-base md:text-2xl" >{data.sixthComp.points[0].maindescription}</h4>
                    <div  >
                    {
                        data.sixthComp?.points.map((res , index) =>{
                            return(
                                <div>
                                {index!== 0 ? <p style={{color:"#004171" , fontSize: 26, fontWeight: 600}} className="text-center mb-4" >{res.title}</p> : null}
                                <div className="grid md:grid-cols-2">
                                {res.sub_points.map((res , index) => {
                                return( <div className="p-8"> 
                                    <div className="flex items-center relative" > 
                                   <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                                   <p className="text-xl md:text-2xl font-semibold" style={{color:"#005D8D"}} >{res.title}</p>
                               </div>
                                           <p className="ml-10 text-base" style={{color:"#676C80", fontSize: 18, fontWeight: 400}} >{res.description}</p>
                                   </div>
                          
                                   )
                            })}
                            </div>
                                </div>
                            )
                            
                          
                          
                        })
                    }
                    </div>
                    <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.sixthComp?.points[0].mainnotes}</h4>
                </div> : null
              }
              </>
    )
}

export default Piles