import React, {useEffect, useState} from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Popover, Spin, message , Modal } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import {
    setAddressString,
    setLatLong,
    actioncustomPinCode,
    setRadius,
  } from "../../../Redux/Action/userActions";
import LocateIcon from "../../../Assets/Images/locate.svg";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import { encryptData, secureStorage } from "../../../Redux/Reducer/authReducer";
import { getPatientDetails, patientaddresslists } from "../../../Redux/Action/UserprofileActions";
import axios from "axios";
import http from "../../../Redux/Services/http-common";
import { LoadingOutlined } from '@ant-design/icons';
import CurebayButton from "../Button/PrimaryButton";
const LocationPicker = () => {
    const [error, setError] = useState({
        isMMILoading: false,
        locationError: "",
      });
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const patientinfo = useSelector((state) => state.patientinfo);
    const { patientinfoData, isLoading, isSuccess } = patientinfo;
    const userData = useSelector((state) => state.authReducer.patientData);
    const [showPopup, setShowPopup] = useState(false);
    const pincodelocation = useSelector(
      (state) => state.authReducer.pincodelocation
    );
    const [open, setOpen] = useState(false);
  const {customPinCode} = useSelector((state) => state.authReducer)
    let effectExcuted = false

  useEffect(() => {
    if (userData?.code) {
      console.log("in UseEffect 2", userData);
      dispatch(getPatientDetails(userData.code)).then((res) => {
        let coords = {};
        let pinCode = "751009";
        if (res?.pinCode && pincodelocation != "map") {
          let city = res?.city;
          pinCode = res.pinCode;
          dispatch(setAddressString(city));
        }
        const pincodePackage = require("pincode-lat-long");
        coords = pincodePackage.getlatlong(pinCode);
        if (coords == undefined) {
          setError({
            locationError: "Please use valid pincode in profile",
          });
          return;
        }
        console.log(pinCode, coords, "AObuosdcsduvsdvdsvd");
        if (!customPinCode) {
          dispatch(
            actioncustomPinCode({
              pincode: pinCode,
              location: "current",
            })
          );
      checkAvalability(pinCode)
          dispatch(setLatLong(coords));
          secureStorage.setItem("customPinCode", pinCode);
          secureStorage.setItem("coords", JSON.stringify(coords));
        }
      });

      dispatch(patientaddresslists(userData.code));
    } else {
      if (!customPinCode && !effectExcuted) {
        effectExcuted = true
      console.log("1234")
      getLocation()
      }
    }
  }, [userData.code]);

  console.log(customPinCode, "vsdbvidsgisdvsdvds")

  // useEffect(() =>{
  //   if(customPinCode){
  //     checkAvalability()
  //   }
  // },[customPinCode])

  const checkAvalability = async (pincode) =>{
    const serviceAvailabilityResponse = await http.get(
      `${process.env.REACT_APP_BASEURL}ServiceAvailability/checkDiagnoServiceAvailabilityCircle?pinCode=${pincode}`
    );

    const circle = serviceAvailabilityResponse.data;
    if(circle){
      secureStorage.setItem("circle", JSON.stringify(circle));
    }
    if(!circle){
      secureStorage.removeItem("circle");
      // warning();
    }
  }

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Diagnostics service not available at your location.',
    });
  };


    const saveAddress = (pincode, city = "") =>{
        const pincodePackage = require("pincode-lat-long");
        let coords = pincodePackage.getlatlong(pincode);
        if (coords == undefined) {
            setError({
              locationError: "Please use valid pincode",
            });
            return;
          }
          let coordsObj = {
            lat: coords.lat,
            long: coords.long,
          };
          // dispatch(setLatLong(coordsObj));
          if(city.length){
            dispatch(setAddressString(city));
          }
          dispatch(
            actioncustomPinCode({
              pincode: pincode,
              location: "profileaddress",
            })
          );
      checkAvalability(pincode)

          dispatch(setLatLong(coordsObj))
          secureStorage.setItem("customPinCode" , pincode)
          secureStorage.setItem("coords" , JSON.stringify(coordsObj))
          setOpen(false);
    }

    const handleSavedAddressClick = () => {
        setError({
          locationError: "",
        });
    
        if (patientinfoData?.pinCode) {
          let city = patientinfoData?.city;
          let pinCode = patientinfoData?.pinCode;
          saveAddress(pinCode, city)
          // setLocation();
        }
      };

      const handleRadiusChange =(e) =>{
        dispatch(setRadius(e.target.value))
        setOpen(false);
      }

      function getLocation() {
        setError({
          isMMILoading: true,
          locationError: "",
        });
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
          console.log(result.state, "sdvsdhgvousdgouvgdsvds")
          if (result.state === "granted") {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(showPosition);
            } else {
            }
          } else if (result.state === "prompt") {
            // showButtonToEnableMap();
            setError({
              isMMILoading: false,
              locationError: "Please allow browser to detect location",
            });
            navigator.geolocation.getCurrentPosition(showPosition ,showError);
            
          } else if (result.state === "denied") {
            setError({
              locationError: "Please allow browser to detect location",
              isMMILoading: false,
            });
            const pincodePackage = require("pincode-lat-long");
              const coords = pincodePackage.getlatlong("751009");
              dispatch(
                actioncustomPinCode({
                  pincode: "751009",
                  location: "current",
                })
              );
            checkAvalability("751009")
              dispatch(setLatLong(coords));
              secureStorage.setItem("customPinCode", "751009");
              secureStorage.setItem("coords", JSON.stringify(coords));
              messageApi.open({
                type: 'warning',
                content: 'Location access off. Enable in settings for personalized, accurate services.',
              });
            // navigator.geolocation.getCurrentPosition(showPosition);


          }
          // Don't do anything if the permission was denied.
        });
      }

      const showError = (err) => {
        const pincodePackage = require("pincode-lat-long");
        const coords = pincodePackage.getlatlong("751009");
        dispatch(
          actioncustomPinCode({
            pincode: "751009",
            location: "current",
          })
        );
      checkAvalability("751009")
        dispatch(setLatLong(coords));
        secureStorage.setItem("customPinCode", "751009");
        secureStorage.setItem("coords", JSON.stringify(coords));
      navigator.geolocation.getCurrentPosition(showPosition);
      };
    
      async function showPosition(position) {
        console.log(position, "sdvsdhgvousdgouvgdsvds")
        const { coords } = position;
        var coordsObj = {
          lat: coords.latitude,
          long: coords.longitude,
        };
        var apiUrl = "MMI/accesstoken";
        let s_token = secureStorage.getItem('token');
        let jsonData = {
          userCode: process.env.REACT_APP_USER_CODE,
          passWord: process.env.REACT_APP_USER_PASSWORD,
          channel: 'M'
        };
        if(!s_token){
          let encryptedPayload = encryptData(jsonData); // Encrypt the payload
          let result = await axios.post(process.env.REACT_APP_BASEURL + process.env.REACT_APP_TOKEN, encryptedPayload)
          console.log(result, "sdvsidhvuosdgoudsv")
          if(result?.data?.access_token){
                         secureStorage.setItem('token' , result?.data?.access_token)
             secureStorage.setItem('refreshToken' , result?.data?.refresh_token)
          }
        //   .then((res) =>{
        //      secureStorage.setItem('token' , res?.data?.access_token)
        //      secureStorage.setItem('refreshToken' , res?.data?.refresh_token)
        // })
        }
        http.get(apiUrl).then((response) => {
          let data = response.data;
          axios
            .get(
              `https://apis.mapmyindia.com/advancedmaps/v1/${data.access_token}/rev_geocode?lat=${coords.latitude}&lng=${coords.longitude}`
            )
            .then((res) => {
              if (!res.data.results[0].pincode) {
                setError({
                  isMMILoading: false,
                  locationError: "Not able to detect your location.",
                });
                setOpen(false);
              } else {
                dispatch(
                  actioncustomPinCode({
                    pincode: res.data.results[0].pincode,
                    location: "current",
                  })
                );
                checkAvalability(res.data.results[0].pincode)
                dispatch(setLatLong(coordsObj));
                secureStorage.setItem(
                  "customPinCode",
                  res.data.results[0].pincode
                );
                secureStorage.setItem("coords", JSON.stringify(coordsObj));
                setError({
                  isMMILoading: false,
                });
                setOpen(false);
              }
            })
            .catch((err) => {
              setError({
                locationError: "Error occured in MMI",
                isMMILoading: false,
              });
            });
        });
      }

    const popoverContent = () =>{
        return(
            <div>
            <div className="p-2">
              <div>
                <h1 className="text-primaryColor text-base font-medium">
                  Where do you want the delivery?
                </h1>
                <span className="text-textGray text-sm font-light">
                  Get access to your Addresses and Orders
                </span>
                <div
                  className={`${
                    patientinfoData?.address1 === null ||
                    patientinfoData?.address2 === null ||
                    patientinfoData?.address1 === undefined ||
                    patientinfoData?.address2 === undefined ||
                    patientinfoData?.city === undefined ||
                    patientinfoData?.pinCode === undefined
                      ? "hidden"
                      : "block"
                  }`}
                >
                  <div
                    className="cursor-pointer  w-full p-4 mt-2 bg-lightBlue border border-[#bee0ff] rounded  mb-1"
                    onClick={handleSavedAddressClick}
                  >
                    <p className="text-primaryColor  text-sm font-normal ">
                      {patientinfoData?.address1 +
                        ", " +
                        patientinfoData?.address2 +
                        ", " +
                        patientinfoData?.city +
                        ", " +
                        patientinfoData?.pinCode}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-1 py-1">
              <div className="">
                <h1 className="text-primaryColor text-base font-medium">
                  Enter Pincode or Radius
                </h1>
                <span className="text-textGray text-sm font-light">
                  Select pincode or Radius to see product availability.
                </span>
                <div className="flex  gap-2">
                  <div>
                    <input
                      className="border border-gray h-[50px] text-sm w-full py-1 pl-2 my-3 rounded"
                      onChange={(e) => e.target.value.length == 6 && saveAddress(e.target.value)}
                      maxLength={6}
                      placeholder="Enter Pincode"
                      type="number"
                    />
                  </div>
                  {/* <div  >
                    <select
                      name="radius"
                      id="radius"
                      onChange={handleRadiusChange}
                     className="border border-gray h-[50px]  text-sm  py-1 pl-2 my-3 rounded sm:w-[180px] w-[120px] text-textGray"
                    >
                       <option className=" text-sm font-light" >Select Services within</option>
                      <option value="5" >5km</option>
                      <option value="10" >10km</option>
                      <option value="15" >15km</option>
                      <option value="25" >25km</option>
                      <option value="50" >50km</option>
                      <option value="100" >100km</option>
                    </select>
                  </div> */}
                </div>

                <button
                  className="flex gap-1 items-center cursor-pointer content-center mt-2"
                  onClick={getLocation}
                  role="button"
                  disabled = {error.isMMILoading}
                >
                  <img
                    src={LocateIcon}
                    alt=""
                    draggable={false}
                    className="h-8 w-8"
                  />
                  <p className="text-secondaryColor  flex items-center text-base font-medium">
                    Detect my location{" "}
                    {error.isMMILoading && (
                      <div className="ml-2" ><Spin indicator={<LoadingOutlined spin />}  /></div>
                    )}
                  </p>
                </button>
                {error.locationError && (
                  <div className="text-xs font-semibold " style={{color: "red"}} >
                    {" "}
                    {error.locationError}{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
    }

    const hide = () => {
        setOpen(false);
      };
      const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
      };

    return(
      <>
      {contextHolder}
        <Popover
        content={popoverContent}
        title=""
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
            <button onClick={(e) => {e.stopPropagation();}} className="flex items-center justify-center px-4 ml-2 sm:ml-0" style={{borderRadius:"20px 0px 0px 20px", backgroundColor: "white", height: 44}} >
          <LocationMarkerIcon
            style={{
              height: "21px",
              color: "#5dbb63",
              marginRight: "10px",
            }}
          />
          <p className="text-sm  font-semibold text-black-700">
            {customPinCode}
          </p>
      </button>
      </Popover>
      {/* <Modal
        open={showPopup}
        onCancel={() =>{ setShowPopup(!showPopup) }}
        width={"50%"}
        footer={[]}
        className="loginmodal"
      >
        <div>
        <h3>Enable Location</h3>
          <p>
          Location access off. Enable in settings for personalized, accurate services.
          </p>
          <CurebayButton onClick={() => setShowPopup(false)} >Ok</CurebayButton>
        </div>
      </Modal> */}
      </>
    )
}


export default LocationPicker;