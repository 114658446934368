import { Modal } from "antd";
import { PrescriptionTempate } from "./prescriptionTemplate";
const SavedPrescriptionListModel = (props) => {
  const {
    isOpen,
    handleSelectPrescriptionClick,
    data,
    handlePrescriptionSelectClick,
    handleClose,
    handleOriginalPrescriptionSelectClick,
  } = props;

  const compareDatesDescending = (a, b) => {
    const dateA = new Date(a[0].createdDate);
    const dateB = new Date(b[0].createdDate);
    return dateB - dateA; 
  };


  const sortedData = [...data].sort(compareDatesDescending);
  console.log(sortedData,"allprescriptiondata123")
  return (
    <Modal
      title="Saved Prescription"
      centered={true}
      footer={false}
      className="commonModal"
      open={isOpen}
      width="50%"
      // onOk={handleHideClick}
      onCancel={handleClose}
    >
      <div className="flex relative flex-col justify-center">
        {sortedData.map((res, i) => {
          if (res[0].conversionNeeded) {
            return (
              <PrescriptionTempate
                index={i}
                cart={true}
                handlePrescriptionSelectClick={handlePrescriptionSelectClick}
                prescriptionType="saved"
                selectedPrescription={res}
                prescribedList={[]}
              />
            );
          } else {
            return (
              <PrescriptionTempate
                index={i}
                cart={true}
                handlePrescriptionSelectClick={
                  handleOriginalPrescriptionSelectClick
                }
                originalPrescription={res[0].docName}
                prescriptionType="saved"
                selectedPrescription={res}
                prescribedList={[]}
              />
            );
          }
        })}
        <button
          className="sticky bottom-0 bg-secondaryColor text-center text-White py-3 cursor-pointer rounded-md "
          onClick={() => handleSelectPrescriptionClick("savePrescription")}
        >
          <p className="text-md font-semibold text-grey-700">
            Upload Prescription
          </p>
        </button>
      </div>
    </Modal>
  );
};

export default SavedPrescriptionListModel;
