import { Modal, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";

const SavedAddressModel = (props) => {
  const userData = useSelector((state) => state.authReducer.patientData);

  const {
    isOpen,
    data,
    handleHideClick,
    handleAddNewAddressClick,
    selectedAddress,
    handleSelectAddressForSavedAddress,
    handleEditClick,
    handleDeleteClick,
  } = props;
  console.log("lollsss", data)
  return (
    <Modal
      title="Saved Address"
      centered={true}
      footer={false}
      className="commonModal"
      open={isOpen}
      width="30%"
      // onOk={handleHideClick}
      onCancel={() => handleHideClick()}
    >
      {data.map((res) => {
        return (
          <div className="py-2 text-xs font-semibold">
            <div className="flex items-baseline">
              {/* <RadioButton
                onChange={(e) => handleSelectAddressForSavedAddress(e.value)}
                inputId={res.key}
                value={res}
                checked={res.id == selectedAddress.id}
              /> */}
              <Radio
                onChange={(e) => handleSelectAddressForSavedAddress(res)}
                inputId={res.key}
                checked={res.id == selectedAddress.id}></Radio>
              <div className="mx-2 w-full">
                <div className="flex justify-between w-full items-center">
                  <p className="text-primaryColor font-semibold text-base">
                    {userData?.firstName}{" "}
                    {/* <span className="text-textGray text-xs font-light">
                      (Default)
                    </span> */}
                  </p>
                  <div className="text-secondaryColor uppercase font-semibold border border-secondaryColor rounded-full pb-0 text-[9px] h-[19px] px-2">
                    {res.type ? res.type : "Other"}
                  </div>
                </div>
                <p className="text-textGray font-light text-sm">
                  {res.address1 + res.address2}, <br />
                  {res.city}, {res.state}{" "}-{" "}{res.pinCode}
                </p>
                <div className="mt-2 flex items-center justify-end">
                  {/* <buttom
                    className="text-sm font-bold mr-1 cursor-pointer"
                    style={{ color: "#66b889" }}
                    onClick = {() => handleEditClick() }
                  >
                    Edit
                  </buttom>{" "}
                  |{" "} */}
                  {/* <button onClick={() => handleDeleteClick(res.id) } className="text-sm font-semibold ml-1 cursor-pointer border rounded-md px-2" style={{ color: "#66b889" }}>Delete</button> */}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <button
        className="text-sm hover:bg-secondaryColor bg-transparent hover:text-White text-secondaryColor border border-secondaryColor py-2.5 px-3 w-full rounded font-medium mt-5"
        onClick={handleAddNewAddressClick}
      >
        + Add new address
      </button>
    </Modal>
    // <Dialog
    //   header="SAVED ADDRESS"
    //   visible={isOpen}
    //   modal={true}
    //   className=" w-4/5 sm:w-1/4  "
    //   // style={{width: "400px"}}
    //   onHide={() => handleHideClick()}
    // >
    //   <button
    //     className="text-sm font-bold cursor-pointer text-right mb-1"
    //     style={{ color: "#66b889" }}
    //     onClick={handleAddNewAddressClick}
    //   >
    //     +Add new address
    //   </button>
    //   {data.map((res) => {
    //     return (
    //       <div className="border-t-2 py-2 text-xs font-semibold">
    //         <div className="flex justify-between">
    //           {res.isDefault ? (
    //             <i className="pi pi-home"></i>
    //           ) : (
    //             <i className="pi pi-book"></i>
    //           )}
    //           <div className="mx-2">
    //             <p className="text-sm">{res.type ? res.type : "Other"}</p>
    //             <p className="text-left">
    //               {res.address1 + res.address2}, {res.city}, {res.state},{" "}
    //               {res.pinCode}
    //             </p>
    //             <div className="mt-2 flex items-center justify-end">
    //               {/* <buttom
    //                 className="text-sm font-bold mr-1 cursor-pointer"
    //                 style={{ color: "#66b889" }}
    //                 onClick = {() => handleEditClick() }
    //               >
    //                 Edit
    //               </buttom>{" "}
    //               |{" "} */}
    //               {/* <button onClick={() => handleDeleteClick(res.id) } className="text-sm font-semibold ml-1 cursor-pointer border rounded-md px-2" style={{ color: "#66b889" }}>Delete</button> */}
    //             </div>
    //           </div>

    //           <RadioButton
    //             onChange={(e) => handleSelectAddressForSavedAddress(e.value)}
    //             inputId={res.key}
    //             value={res}
    //             checked={res.id == selectedAddress.id}
    //           />
    //         </div>
    //       </div>
    //     );
    //   })}
    // </Dialog>
  );
};

export default SavedAddressModel;
