import React, { useState } from "react";
import Layouts from "../Layouts";
import Clockimage from "../../Assets/Images/Icons/clock.svg";
import Calendarimage from "../../Assets/Images/Icons/calendar.svg";
import Backarrow from "../../Assets/Images/Icons/backarrow.svg";
import HoverBackarrow from "../../Assets/Images/Icons/backarrow-white.svg";
import { useHistory } from "react-router-dom";

function BlogDetails3() {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  const handleNavigation = (path) => {
    history.push(path);
  };
  return (
    <Layouts>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px] md:w-[80%]">
          <div
            className="text-primaryColor hover:bg-primaryColor hover:text-White py-2 px-5 w-fit rounded-full mx-auto text-lg font-medium cursor-pointer flex gap-3 justify-center items-center mb-10"
            onClick={() => handleNavigation("/blogs")}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img src={isHovered ? HoverBackarrow : Backarrow} alt="images" />
            Back to blogs
          </div>
          <div>
            <h2 className="md:w-[60%] mx-auto text-center text-2xl md:text-4xl font-bold text-primaryColor !leading-normal mb-5">
              <span className="text-secondaryColor">10 Tips : </span>
              For Managing Chronic Conditions with Telehealth
            </h2>
            <p className="md:w-[50%] mx-auto text-center text-textGray ">
              In recent years, telehealth has emerged as a groundbreaking solution for managing chronic health
              conditions, offering convenience, accessibility, and personalized care from the comfort of one's
              home.
            </p>
            <div className="flex gap-4 justify-center mt-5">
              <div className="flex items-center gap-2">
                <img src={Clockimage} alt="images" />
                <p className="text-textGray text-sm ">6 minute read</p>
              </div>
              <div className="flex items-center gap-2">
                <img src={Calendarimage} alt="images" />
                <p className="text-textGray text-sm ">10th February, 2024</p>
              </div>
            </div>
          </div>
          <div className="w-full md:h-[500px] my-5 md:my-10">
            <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog3.jpg" alt="images" className="w-full h-full object-cover rounded-lg" />
          </div>
          <div className="bg-White rounded-xl p-5 md:p-10">
            <h6 className=" text-primaryColor text-xl font-semibold my-5">
              Introduction:
            </h6>
            <p className="text-textGray mb-4 md:leading-loose">
              In recent years, telehealth has emerged as a groundbreaking solution for managing chronic health
              conditions, offering convenience, accessibility, and personalized care from the comfort of one's
              home. Whether you're living with diabetes, hypertension, or any other chronic condition, telehealth
              presents numerous opportunities for effective management. Here are 10 valuable tips to help you
              make the most of telehealth in managing your chronic health condition.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Choose the Right Telehealth Platform:
            </h6>
            <p className="text-textGray md:leading-loose  mb-4">
              Select a telehealth platform that caters to your specific chronic condition and offers comprehensive
              services tailored to your needs. Look for features such as video consultations, secure messaging,
              and integration with health monitoring devices.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Establish a Regular Schedule:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Consistency is key to effectively managing chronic conditions. Set up regular telehealth
              appointments with your healthcare provider to monitor your condition, discuss any concerns, and
              adjust your treatment plan as needed.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Keep Track of Your Symptoms:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Use telehealth tools to record and track your symptoms regularly. Documenting changes in your
              condition over time can provide valuable insights for your healthcare provider and help guide
              treatment decisions.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Monitor Vital Signs at Home:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Invest in home monitoring devices such as blood pressure monitors, glucometers, or pulse oximeters
              to track key vital signs between telehealth appointments. Sharing this data with your healthcare
              provider can facilitate more informed decisions about your care.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Stay Educated About Your Condition:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Take advantage of telehealth resources to educate yourself about your chronic condition. Attend
              virtual educational sessions, participate in online support groups, and access reliable information
              to empower yourself in managing your health.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Communicate Openly with Your Provider:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Effective communication is essential for successful telehealth management. Be proactive in sharing
              any changes in your condition, medication side effects, or concerns with your healthcare provider
              during telehealth consultations.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Follow Treatment Recommendations:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Adhere to your healthcare provider's recommendations regarding medication, lifestyle modifications,
              and self-care practices. Consistently following your treatment plan is crucial for managing chronic
              conditions and minimizing complications.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Prioritize Self-Care:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Incorporate self-care practices into your daily routine to support your overall health and
              well-being. This may include regular exercise, healthy eating, stress management techniques,
              adequate sleep, and mindfulness activities.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Stay Connected with Your Healthcare Team:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Maintain regular communication with your healthcare team through telehealth channels. Utilize
              secure messaging or virtual check-ins to address any questions or concerns between appointments
              and ensure continuity of care.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Be Patient and Persistent:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Managing chronic conditions is often a journey filled with ups and downs. Stay patient and
              persistent in your efforts to maintain optimal health, and trust in the support of your healthcare
              team through telehealth services.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Conclusion:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Telehealth has transformed the landscape of chronic disease management, offering a convenient and
              effective approach to care delivery for individuals living with long-term health conditions. By
              following these 10 tips, you can harness the power of telehealth to take control of your health,
              achieve better outcomes, and lead a fulfilling life despite chronic illness.
            </p>
            <div className="flex gap-4">
              <div className="flex items-center gap-2">
                <img src={Clockimage} alt="images" />
                <p className="text-textGray text-sm ">By CureBay</p>
              </div>
              <div className="flex items-center gap-2">
                <img src={Calendarimage} alt="images" />
                <p className="text-textGray text-sm ">Dt. 13th Feb 2024</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
            <span className="text-secondaryColor">Recommended</span> Reads
          </h2>
          <div className="grid md:grid-cols-2 gap-5">
            <div
              className="bg-White common-shadow rounded-xl p-5 cursor-pointer w-[80%] mx-auto"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div className="w-full mb-3">
                <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog1.jpg" alt="images" className="w-full" />
              </div>
              <h6 className="text-primaryColor font-semibold text-lg mb-2">
                Beat the Heat: 10 Tips for Staying Cool and Hydrated This Summer
              </h6>
              <p className="text-textGray text-sm mb-4">
                As temperatures rise and the sun shines brighter, it's essential to prioritize staying cool and
                hydrated to beat the summer heat.
              </p>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
            <div
              className="bg-White common-shadow rounded-xl p-5 cursor-pointer w-[80%] mx-auto"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div className="w-full mb-3">
                <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog2.jpg" alt="images" className="w-full" />
              </div>
              <h6 className="text-primaryColor font-semibold text-lg mb-2">
                Summer Skincare Essentials: Protecting Your Skin from Sun Damage
              </h6>
              <p className="text-textGray text-sm mb-4">
                With the arrival of summer comes longer days, warmer weather, and plenty of outdoor activities.
                While soaking up the sun can be enjoyable, it's essential to prioritize skin protection to prevent
                sun damage and maintain healthy, radiant skin.
              </p>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default BlogDetails3;