import Locationservices from "../Services/Mapmyind";

export const fetchCities = async () => {
    const res = await Locationservices.getCities();
    return res;
};
export const fetchLocation = async (requestDatas) => {
    const res = await Locationservices.getLocation(requestDatas);
    return res;
};

export const fetchEClinics = async () => {
    const res = await Locationservices.getEclinicsDetails();
    return res;
};

export const fetchEclinicsByPincode = async (payload) => {
    const res = await Locationservices.getEclinicsByPincode(payload);
    return res;
};

export const fetchEclinicsBypincode = async (payload) => {
    const res = await Locationservices.getEclinicsBypincode(payload);
    return res;
};

export const fetchMapMyIndiaToken = async () => {
    const res = await Locationservices.fetchMapMyIndiaToken();
    return res;
};