import React from "react";
import { useState, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import http from "../../Redux/Services/http-common";
import { useSelector, useDispatch } from "react-redux";
import { Logout } from "../../Redux/Action/userActions";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Modal } from "antd";
import { pdfjs } from "react-pdf";
import { Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const Consent = ({
  openConsent,
  closeConsent,
  checkBoxPresent = false,
  selectCheckbox = () => {},
}) => {
  const patientData = useSelector((state) => state.authReducer.patientData);
  const userData = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const { patientSession } = userData;
  const [isVisible, setIsVisible] = useState(false);
  const [numPagestcPDf, setNumPagestcPDf] = useState();
  const [numPagesprivacy, setNumPagesPrivacy] = useState();
  const [scale, setScale] = useState(100);
  const [consentInfo, setConsentInfo] = useState({
    privacyPdf: "",
    privacyVersion: "",
    tcPdf: "",
    tcVersion: "",
  });
  const [width, setWidth] = useState(window.innerWidth);
  const [vh, setVh] = useState(window.innerHeight * 0.01);
  useEffect(() => {
    const updateVh = () => {
      setVh(window.innerHeight * 0.01);
    };

    // Initial update
    updateVh();

    // Update on resize
    window.addEventListener('resize', updateVh);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', updateVh);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let modalWidth;
  if (width <= 767) {
    modalWidth = '100%'; // Mobile devices
  } else if (width >= 768 && width <= 1023) {
    modalWidth = '500px'; // Tablets and small laptops
  } else {
    modalWidth = '600px'; // Desktops and large laptops
  }

  useEffect(() => {
    if (patientData?.code) {
      getVersion();
    }
  }, [openConsent, patientData?.code]);

  useEffect(() => {
    if (window.innerWidth <= 390) {
      setScale(40);
    } else if (window.innerWidth > 390 && window.innerWidth <= 600) {
      setScale(50);
    } else if (window.innerWidth > 600 && window.innerWidth <= 900) {
      setScale(60);
    } else {
      setScale(70);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (patientData?.code) {
      getVersion();
    }
  }, []);

  function getVersion() {
    let urls = [
      `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=PATIENT_B2C`,
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/list/filter?patientId=${patientData.code}&status=1&sourceChannel=B2C`,
    ];
    let requests = urls.map((url) => http.get(url));
    
    Promise.allSettled(requests)
      .then((responses) => {
        console.log(responses, "sdvsdguvgsdvds");
  
        if (responses[1].status == "fulfilled") {
          if (openConsent) {
            setIsVisible(true);
          }
        } else if (responses[1].status == "rejected" && responses[1]?.reason?.response?.status == 404) {
          setIsVisible(true);
        }
  
        let info = {
          privacyPdf: responses[0].value.data.data[0].consentFile,
          privacyVersion: responses[0].value.data.data[0].versionNumber,
          tcPdf: responses[0].value.data.data[1].consentFile,
          tcVersion: responses[0].value.data.data[1].versionNumber,
        };
        setConsentInfo(info);
      })
      .catch((err) => {
        console.log(err, "sdvhsdouvdsuovds");
        if (err?.status == 404) {
          setIsVisible(true);
        }
      });
  }
  

  //   "createdBy": "CUREBAYADMIN",
  //     "privacyPolicyConsent": 1,
  //     "sourceChannel": "B2C",
  //     "status": 1,
  //     "termsAndConditionConsent": 1,
  //     "type": "P",
  //     "patientId": "nigZZMo11260p",
  //     "privacyVersionNumber": "V4",
  //     "tcVersionNumber": "V5"

  const agree = async () => {
    if (checkBoxPresent) {
      selectCheckbox(true);
      setIsVisible(false);
      return;
    }
    const payload = {
      createdBy: "CUREBAYADMIN",
      privacyPolicyConsent: 1,
      sourceChannel: "B2C",
      status: 1,
      termsAndConditionConsent: 1,
      type: "P",
      patientId: patientData.code,
      privacyVersionNumber: consentInfo.privacyVersion,
      tcVersionNumber: consentInfo.tcVersion,
    };

    console.log("Payload:", payload);
    const postResponse = await http.post(
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
      payload
    );

    console.log("POST Request Response for test", postResponse);
    if (postResponse.status === 200) {
      if (openConsent) {
        closeConsent();
      }
      setIsVisible(false);
    }
  };

  const logout = (e) => {
    localStorage.clear();
    let dataObj = {
      sessionId: patientSession?.sessionid,
      userCode: patientSession?.code,
    };
    dispatch(Logout(dataObj));
    history.push(APP_ROUTES.DASHBOARD);
  };

  const diagree = async () => {
    if (checkBoxPresent) {
      selectCheckbox(false);
      setIsVisible(false);
      return;
    }
    const payload = {
      createdBy: "CUREBAYADMIN",
      privacyPolicyConsent: 0,
      sourceChannel: "B2C",
      status: 1,
      termsAndConditionConsent: 0,
      type: "P",
      patientId: patientData.code,
      privacyVersionNumber: consentInfo.privacyVersion,
      tcVersionNumber: consentInfo.tcVersion,
    };

    console.log("Payload:", payload);
    const postResponse = await http.post(
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
      payload
    );

    console.log("POST Request Response for test", postResponse);
    if (postResponse.status === 200) {
      if (openConsent) {
        closeConsent();
      }
      setIsVisible(false);
      logout();
    }
  };

  function onDocumentLoadSuccesstcPdf({ numPages }) {
    setNumPagestcPDf(numPages);
  }

  function onDocumentLoadSuccessprivacy({numPages}) {
    setNumPagesPrivacy(numPages);
  }


  return (
    <Modal
      open={isVisible}
      onCancel={() => {}}
      centered
      styles={{
        content: {
            height: `${vh * 98}px`,
            width: modalWidth,
            // overflow:"scroll"
        },
        footer:{
          alignContent: "center",
          paddingRight: 10,
          height: "10%"
        },
        body:{
          height: "90%" 
        }
      }}
      footer={[
          <button
            onClick={diagree}
            className=" text-white  text-sm font-semibold px-2 py-2 rounded cursor-pointer lg:px-18 mr-4"
            style={{ backgroundColor: "red", color: "#fff" }}
          >
            Disagree
          </button>,
          <button
          onClick={agree}
          className=" text-white  text-sm font-semibold px-2 py-2 rounded cursor-pointer lg:px-18"
          style={{ backgroundColor: "#66B889", color: "#fff" }}
        >
          Agree
        </button>
      ]}
      className="w-full sm:w-8/12 md:w-10/12 consentModal"
    >
      <div className="consentBodyContainer" >
          <TabView className="p-0" panelContainerClassName="p-0 overflow-scroll" panelContainerStyle={{height: "90%"}}>
            <TabPanel
              header="Terms and condition"
              className="text-xs sm:text-md lg:text-lg p-0"
            >
              {/* <iframe
                // height="100vh"
                // width="68px"
                className="border rounded cursor-pointer w-full"
                style={{ height: "50rem", maxHeight: "50rem" }}
                src={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.tcPdf}#zoom=${scale}`}
              /> */}
               <Document file={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.tcPdf}`} onLoadSuccess={onDocumentLoadSuccesstcPdf}>
               {numPagestcPDf &&
          Array.from({ length: numPagestcPDf }, (_, index) => index + 1).map(
            (pageNumber) => <Page  width={600} pageNumber={pageNumber} renderTextLayer = {true} renderAnnotationLayer = {false}  />
          )}      
      </Document>
              {/* <Document
                file={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.tcPdf}`}
                onLoadSuccess={onDocumentLoadSuccess}
                // options={pdf}
                
              >
                <Page pageNumber={pageNumber} renderTextLayer = {true} onRenderTextLayerSuccess = {(item) => console.log(item, "Sdvisdhviudsgvudsv")} />
              </Document> */}
            </TabPanel>
            <TabPanel
              header="Privacy Policy"
              className="text-xs sm:text-md lg:text-lg"
            >
              {/* <iframe
                // height="95px"
                // width="68px"
                className="border rounded cursor-pointer h-96 w-full"
                style={{ height: "50rem", maxHeight: "50rem" }}
                src={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.privacyPdf}#zoom=${scale}`}
              /> */}
              <Document file={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.privacyPdf}`} onLoadSuccess={onDocumentLoadSuccessprivacy} >
               {numPagesprivacy &&
          Array.from({ length: numPagesprivacy }, (_, index) => index + 1).map(
            (pageNumber) => <Page  width={600} pageNumber={pageNumber} renderTextLayer = {true} renderAnnotationLayer = {false} />
          )}      
      </Document>
              {/* <Document
                file={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.privacyPdf}`}
                onLoadSuccess={onDocumentLoadSuccess}
                // options={pdf}
              >
                 <Page pageNumber={pageNumber} />
              </Document> */}
            </TabPanel>
          </TabView>
          </div>
    </Modal>
  );
};
export default Consent;
