import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import { useHistory,useLocation } from "react-router-dom";
import http from "../../../Redux/Services/http-common"
import { PrescriptionTempate, getbase64 } from "../../Helper/prescriptionTemplate";
import { encryptData, secureStorage } from "../../../Redux/Reducer/authReducer";
import axios from "axios";
function PrescriptionLink() {
    const [savedPrescription, setSavedPrescription] = useState([]);
    const search = useLocation().search;
    const visitidParam = new URLSearchParams(search).get("visitId");
    useEffect(() => {
        loadPrescriptions();
    }, [visitidParam]);

    async function loadPrescriptions() {
        try {
            let token = await secureStorage.getItem('token')
            if(!token){
                let jsonData = {
                    userCode: process.env.REACT_APP_USER_CODE,
                    passWord: process.env.REACT_APP_USER_PASSWORD,
                    channel: 'M'
                  };
                
                  let encryptedPayload = encryptData(jsonData); // Encrypt the payload
                  await axios.post(process.env.REACT_APP_BASEURL + process.env.REACT_APP_TOKEN, encryptedPayload).then(result => {
                    token = result?.data?.access_token
                    secureStorage.setItem("token", result?.data?.access_token);
                });
            }
                const res = await http.get(`${process.env.REACT_APP_BASEURL}Visit/list?id=${visitidParam}&visitSummary=Y`, {
                    headers:{
                        Authorization: `Bearer ${token}`
                }});
                setSavedPrescription(res.data);

        } catch (error) {
            console.log(error)
        }

    }



    return (
        <Layouts>
           
            <section className="bg-lightGray">
          <PrescriptionTempate prescriptionType = "saved" originalPrescription = {""} selectedPrescription = {savedPrescription} />
                
            </section>
        </Layouts>
    );
}

export default PrescriptionLink;