import {NEED} from '../Constants/needHelpConstant'
const initialState = {
    needHelp: false, // Initial state
  };
  
  const needheplReducer = (state = initialState, action) => {
    switch (action.type) {
      case NEED.TOGGLE_BOOLEAN:
        return {
          ...state,
          needHelp:!state.needHelp,
        };
      default:
        return state;
    }
  };
  export default needheplReducer

  