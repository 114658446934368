// import React, { useEffect, useState, useRef } from "react";
// import { Spin } from "antd";
// import axios from "axios";
// import { useHistory } from "react-router-dom";
// import Click from "../../../Assets/Images/click.svg";
// import Header from "../../Shared/Header";
// import { secureStorage } from "../../../Redux/Reducer/authReducer";

// const VideoCaptureComponent = ({ formData }) => {
//   const [countdown, setCountdown] = useState(5);
//   const [isRecording, setIsRecording] = useState(false);
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const [videoBlob, setVideoBlob] = useState(null);
//   const [videoURL, setVideoURL] = useState(null);
//   const [isMp4Supported, setIsMp4Supported] = useState(false);
//   const [hasCameraAccess, setHasCameraAccess] = useState(false);
//   const [permissionStatus, setPermissionStatus] = useState("unknown");
//   const [isRecordingCounter, setIsRecordingCounter] = useState(30);
//   const videoRef = useRef(null);
//   const mediaStream = useRef(null);
//   const history = useHistory();
//   const s_token = secureStorage.getItem("token");
//   const storedData = JSON.parse(localStorage.getItem("patientInfo"));
//   const [isPreparingDataLoading, setIsPreparingDataLoading] = useState(false);

//   useEffect(() => {
//     // checkMp4Support();
//     checkCameraPermission();
//   }, []);

//   const checkMp4Support = () => {
//     // const types = [
//     //   "video/webm",
//     //   "audio/webm",
//     //   "video/webm;codecs=vp8",
//     //   "video/webm;codecs=daala",
//     //   "video/webm;codecs=h264",
//     //   "audio/webm;codecs=opus",
//     //   "video/mp4",
//     // ];
   
//     // const mimeTypes =["video/webm",
//     // "audio/webm",
//     // "video/webm;codecs=vp8",
//     // "video/webm;codecs=daala",
//     // "video/webm;codecs=h264",
//     // "audio/webm;codecs=opus",
//     // "video/mp4"]
//     // for (const mimeType of mimeTypes) {
//     //   console.log(
//     //     `Is ${mimeTypes} supported? ${
//     //       MediaRecorder.isTypeSupported(mimeType) ? "Maybe!" : "Nope :("
//     //     }`
//     //   );
//     // }
//     const supported = MediaRecorder.isTypeSupported("video/mp4");
//     console.log(supported,"supportChck")
//     // setIsMp4Supported(supported);
//     // if (!supported) {
//     //   alert(
//     //     "Your browser does not support MP4 video recording. Please use a compatible browser like Safari."
//     //   );
//     // }
//     return supported;

//   };
  

//   const checkCameraPermission = async () => {
//     try {
//       const permissionResult = await navigator.permissions.query({
//         name: "camera",
//       });
//       setPermissionStatus(permissionResult.state);

//       if (permissionResult.state === "granted") {
//         setHasCameraAccess(true);
//         await requestCameraPermission();
//       } else if (permissionResult.state === "prompt") {
//         await requestCameraPermission();
//       } else {
//         setHasCameraAccess(false);
//         alert("Camera access is required to record a video.");
//       }

//       permissionResult.onchange = async () => {
//         setPermissionStatus(permissionResult.state);
//         if (permissionResult.state === "granted") {
//           setHasCameraAccess(true);
//           await requestCameraPermission();
//         } else {
//           setHasCameraAccess(false);
//         }
//       };
//     } catch (error) {
//       console.error(
//         "Permission API not supported or error accessing camera:",
//         error
//       );
//     }
//   };

//   const requestCameraPermission = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: true,
//         audio: true,
//       });
//       mediaStream.current = stream;

//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//         videoRef.current.play();
//       }
//       startCountdown();
//     } catch (error) {
//       console.error("Error accessing camera:", error);
//       alert("Could not access the camera. Please check browser permissions.");
//     }
//   };

//   const startCountdown = () => {
//     const countdownInterval = setInterval(() => {
//       setCountdown((prev) => {
//         if (prev <= 1) {
//           clearInterval(countdownInterval);
//           startRecording();
//           return 0;
//         }
//         return prev - 1;
//       });
//     }, 1000);
//   };

//   const startRecording = () => {
//     if (!checkMp4Support()) {

//       alert("MP4 recording is not supported in your browser.");
//       return;
//     }

//     try {
//       const stream = mediaStream.current;
//       const options = { mimeType: "video/mp4" };
//       const recorder = new MediaRecorder(stream, options);
//       setMediaRecorder(recorder);

//       const chunks = [];
//       recorder.ondataavailable = (event) => {
//         if (event.data.size > 0) chunks.push(event.data);
//       };

//       recorder.onstop = () => {
//         const blob = new Blob(chunks, { type: "video/mp4" });
//         setVideoBlob(blob);
//         setVideoURL(URL.createObjectURL(blob));
//       };

//       recorder.start();
//       setIsRecording(true);

//       const recordingInterval = setInterval(() => {
//         setIsRecordingCounter((prev) => {
//           if (prev <= 1) {
//             clearInterval(recordingInterval);
//             stopRecording();
//             return 0;
//           }
//           return prev - 1;
//         });
//       }, 1000);
//     } catch (error) {
//       console.error("Error starting recording:", error);
//       alert("Could not start video recording.");
//     }
//   };

//   const stopRecording = () => {
//     if (mediaRecorder && mediaRecorder.state !== "inactive") {
//       mediaRecorder.stop();
//       setIsRecording(false);
//     }
//   };

//   const onSubmit = async () => {
//     if (!videoBlob) {
//       alert("Please record a video before submitting.");
//       return;
//     }

//     const formDataPayload = new FormData();
//     formDataPayload.append(
//       "name",
//       `${storedData?.data?.firstName} ${storedData?.data?.lastName}`
//     );
//     formDataPayload.append("age", calculateAge(storedData?.data?.dob));
//     formDataPayload.append("gender", storedData?.data?.gender);
//     formDataPayload.append("video", videoBlob, "recorded-video.mp4");

//     try {
//       setIsPreparingDataLoading(true);
//       const response = await axios.post(
//         "https://contactlessvitals-stage.curebay.in/process-video/",
//         formDataPayload,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${s_token}`,
//           },
//         }
//       );
//       setIsPreparingDataLoading(false);

//       if (response.status === 200) {
//         alert("Form submitted successfully!");
//         setVideoBlob(null);
//         setVideoURL(null);
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       setIsPreparingDataLoading(false);
//     }
//   };

//   const calculateAge = (dob) => {
//     const birthDate = new Date(dob);
//     const today = new Date();
//     let age = today.getFullYear() - birthDate.getFullYear();
//     if (
//       today.getMonth() < birthDate.getMonth() ||
//       (today.getMonth() === birthDate.getMonth() &&
//         today.getDate() < birthDate.getDate())
//     ) {
//       age--;
//     }
//     return age;
//   };

//   if (!hasCameraAccess || permissionStatus === "denied") {
//     return (
//       <div
//         className="flex items-center justify-center"
//         style={{ height: "90vh" }}
//       >
//         <p>
//           Camera access is denied. Please enable camera access in your browser
//           settings.
//         </p>
//       </div>
//     );
//   }

//   return (
//     <>
//       <Header />
//       <div style={{ textAlign: "center", padding: "20px" }}>
//         {!isRecording && countdown > 0 ? (
//           <div className="countdown">
//             <p>Recording starts in {countdown} seconds</p>
//           </div>
//         ) : isRecording ? (
//           <div className="recording">
//             <video
//               ref={videoRef}
//               autoPlay
//               playsInline
//               className="recording-video"
//             ></video>
//             <p>Recording... {isRecordingCounter} seconds remaining</p>
//           </div>
//         ) : null}
//         {isPreparingDataLoading && (
//           <div>
//             <Spin />
//             <p>Preparing data...</p>
//           </div>
//         )}
//         <button onClick={onSubmit} className="submit-button">
//           Submit
//         </button>
//       </div>
//     </>
//   );
// };

// export default VideoCaptureComponent;

import React, { useEffect, useState, useRef } from 'react';
import { Spin } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Click from '../../../Assets/Images/click.svg';
import Header from '../../Shared/Header';

const VideoCaptureComponent = ({ formData }) => {
  const [countdown, setCountdown] = useState(5);
  const [isRecording, setIsRecording] = useState(false);
  const [stream, setStream] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [hasCameraAccess, setHasCameraAccess] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState('unknown');
  const videoRef = useRef(null);
  const [isRecordingCounter, setIsRecordingCounter] = useState(30);
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const history = useHistory();
  const [isPreparingDataLoading, setIsPreparingDataLoading] = useState(false);

  // Log the form data when the component mounts or when formData changes
  useEffect(() => {
    console.log('Form Data:', formData); // Console the form data
  }, [formData]);

  useEffect(() => {
    const checkCameraPermission = async () => {
      try {
        const permissionResult = await navigator.permissions.query({ name: 'camera' });
        setPermissionStatus(permissionResult.state);

        if (permissionResult.state === 'granted') {
          try {
            setHasCameraAccess(true);
            requestCameraPermission();
          } catch (error) {
            console.error('Error accessing camera:', error);
            setHasCameraAccess(false);
          }
        } else if (permissionResult.state === 'denied') {
          setHasCameraAccess(false);
        } else if (permissionResult.state === 'prompt') {
          requestCameraPermission();
        }

        permissionResult.onchange = () => {
          setPermissionStatus(permissionResult.state);
          if (permissionResult.state === 'granted') {
            setHasCameraAccess(true);
            requestCameraPermission();
          } else {
            setHasCameraAccess(false);
          }
        };
      } catch (error) {
        requestCameraPermission();
        console.error('Permission API or getUserMedia is not supported:', error);
      }
    };

    checkCameraPermission();
  }, []);

  useEffect(() => {
    if (videoRef.current && mediaStream.current) {
      videoRef.current.srcObject = mediaStream.current;
      videoRef.current.play();
    }
  }, [videoRef.current, mediaStream.current]);

  useEffect(() => {
    let timerId;
    if (countdown <= 0) {
      startRecording(mediaStream.current);
      return;
    }
    if (mediaStream?.current) {
      timerId = setInterval(() => {
        setCountdown((prevCounter) => prevCounter - 1);
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [countdown, mediaStream?.current]);

  async function requestCameraPermission() {
    try {
      const userStream = await navigator.mediaDevices.getUserMedia({ video: true });
      mediaStream.current = userStream;
      setStream(userStream);

      if (videoRef.current) {
          videoRef.current.srcObject = mediaStream.current; // Set video source to the camera stream
          videoRef.current.play();
        }
        setHasCameraAccess(true);
      // Start the countdown timer
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  }

  const startRecording = (stream) => {
    setIsRecording(true);
    mediaRecorder.current = new MediaRecorder(stream);

    let chunks = [];

    mediaRecorder.current.ondataavailable = (e) => {
      chunks.push(e.data);
    };

    mediaRecorder.current.onstop = async () => {
      const blob = new Blob(chunks, { type: 'video/webm' });
      setVideoUrl(URL.createObjectURL(blob));

      const base64 = await convertBlobToBase64(blob);
      setIsPreparingDataLoading(true);
      handleSubmit(base64);
    };

    mediaRecorder.current.start();
  };

  useEffect(() => {
    let recordingInterval;
    if (isRecordingCounter <= 0) {
      mediaRecorder.current.stop();
      if (mediaStream.current) {
        mediaStream.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
      return;
    }
    if (mediaRecorder?.current?.state === 'recording') {
      recordingInterval = window.setInterval(() => {
        setIsRecordingCounter((prevValue) => prevValue - 1);
      }, 1000);
    }
    return () => clearInterval(recordingInterval);
  }, [isRecordingCounter, mediaRecorder?.current?.state]);

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleSubmit = async (base64) => {
    try {
      let payload = {
        video_base64: base64,
        name:formData?.name,
        age: formData?.age,
        gender:formData?.gender,
        height: parseInt(formData?.height),
        height_unit:formData?.heightUnit,
        weight: parseInt(formData?.weight),
        weight_unit: formData?.weightUnit,
        smoke:formData?.smoke,
        diabetes:formData?.diabetes,

      };
      let response = await axios.post('https://contactlessvitals-stage.curebay.in/process-video/', payload);
      setIsPreparingDataLoading(false);
      if (response.status === 200) {
        const queryParams = new URLSearchParams({
          name: response.data.name,
          age: response.data.age,
          gender: response.data.gender,
          height: response.data.height,
          height_unit: response.data.height_unit,
          weight: response.data.weight,
          weight_unit: response.data.weight_unit,
          smoke: response.data.smoke,
          diabetes: response.data.diabetes,
          heart_rate: response.data.data.heart_rate,
          hrv: response.data.data.hrv_metrics.HRV,
          pnn50: response.data.data.hrv_metrics.PNN50,
          rmssd: response.data.data.hrv_metrics.RMSSD,
          sdnn: response.data.data.hrv_metrics.SDNN,
          breathing_rate: response.data.data.breathing_rate,
          spo2: response.data.data.spo2,
        }).toString();
        history.push(`/smart-vital-report?${queryParams}`);
      }
    } catch (err) {
      setIsPreparingDataLoading(false);
    }
  };

//   console.log(mediaRecorder?.current?.state ,"statattaacs")

console.log(permissionStatus, "dsvoisdhiodhsuvdsvds")

if(permissionStatus == 'denied' || (permissionStatus == 'prompt' && !hasCameraAccess) ){
    return <div className='flex items-center justify-centerpx-4' style={{height:"90vh"}} >
        <p className='text-center' >Camera access is denied. Please enable camera access in your browser settings.</p>
    </div>
}


  return (
    <><Header /><div style={{ textAlign: 'center', padding: '20px' }}>
      {!isRecording && countdown > 0 ? (
        <div className="flex items-center justify-center h-screen bg-white">
          <div className="text-center flex flex-col justify-center items-center">
            <div className="bg-[#f3f8ff] rounded-full w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
              <img src={Click} alt="Finger Icon" className="w-12 h-12" />
            </div>
            <h2 className="text-lg font-semibold text-primaryColor mb-2">Please keep your finger on the camera</h2>
            <div className="flex items-center justify-center space-x-2">
              <div className="w-4 h-4 border-2 border-[#004171] rounded-full animate-pulse"></div>
              <p className="text-sm text-textGray">
                Scanning starting in{' '}
                <span className="text-secondaryColor font-semibold">{countdown} sec</span>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {isRecordingCounter > 0 ? (
            <>
              <div className="flex flex-col items-center justify-center h-screen">
                <div className="relative w-[300px] h-[300px] rounded-lg overflow-hidden">
                  <video ref={videoRef} autoPlay playsInline className="w-full h-full object-cover"></video>
                </div>

                <div className="mt-4 text-center">
                  <div className="flex items-center justify-center space-x-2">
                    <Spin />
                    <p className="text-primaryColor font-semibold">Please wait, we are scanning your finger</p>
                  </div>
                  <p className="text-textGray mt-2">
                    Scanning completes in{' '}
                    <span className="text-secondaryColor font-semibold">{isRecordingCounter} seconds</span>
                  </p>
                </div>
              </div>
            </>
          ) : null}
          {isPreparingDataLoading && (
            <div className="flex items-center justify-center h-screen">
              <p>Please wait preparing your vitals report</p> &nbsp; &nbsp; <Spin />
            </div>
          )}
        </div>
      )}
    </div></>
  );
};

export default VideoCaptureComponent;
