import React, { useEffect, useRef, useState } from 'react';
// import close from '../../Assets/Images/closeee.svg';
// import Dob from '../../Assets/Images/calendar.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import PDF from '../../Assets/Images/PDF.png'
import moment from "moment";
import { DatePicker, message } from "antd";
import { encodeBase64File } from '../../../../Helper/filebase64';
//import FamilyDropdown from "./Familydropdown";
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { getfamilyhistory, patientaddfamilyhistory, getPatientfamilymembers as getpatientfamilymemberslist } from "../../../../../Redux/Action/UserprofileActions";
import { Form, Input } from 'antd';

function Addfamilyhistory(props) {



    const goBack = () => {
        props.closePopup();
    }
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const [memberList, setMemberList] = useState([]);
    const location = useLocation();
    const [FamilymemberName, setFamilymemberName] = useState("");
    const [givenDate,setGivenDate] = useState("");

    const userData = useSelector((state) => state.authReducer.userData);
    console.log("userData", userData);
    const patientCode = useSelector(state => state.authReducer.patientCode);
    // useEffect(() => {
    //     // let patient = localStorage.getItem("patientprofile");
    //     // console.log("patient", patient);
    //     dispatch(getpatientfamilymemberslist(props.patient ? props.patient : patientCode))
    //         .then((result) => {
    //             console.log(result);
    //             setMemberList(result);
    //         })
    //         .catch((error) => {
    //             // setLoader(false)
    //             // redirectTo();
    //         });
    // }, []);
    const [memberCode, setMemberCode] = useState("");
    const handleChange = (e) => {
        setaddfamilyhistory({
            ...addfamilyhistory,
            [e.target.name]: e.target.value,
        });
    };
    const handleChangeFamilymem = (e) => {
        setFamilymemberName(e)
    }
    const changeDate = (e) => {
        setaddfamilyhistory({
            ...addfamilyhistory,
            givenDate: moment(e).format("yyyy-MM-DD HH:mm:ss"),
        });
    };

    const handleDateChange = (date, dateString) => {
        const Currdate = new Date();
        const hours = String(Currdate.getHours()).padStart(2, '0');
        const minutes = String(Currdate.getMinutes()).padStart(2, '0');
        const seconds = String(Currdate.getSeconds()).padStart(2, '0');
        const constructedDate = dateString + ` ${hours}:${minutes}:${seconds}`;
        console.log(constructedDate)
        const formattedDate = moment(constructedDate).format('YYYY-MM-DD HH:mm:ss');
        console.log(formattedDate);
        setGivenDate(formattedDate);
    };

    // const patientdata = localStorage.getItem("patientprofile")
    // console.log("patient", patientdata)



    const [addfamilyhistory, setaddfamilyhistory] = useState({});

    const savefamilyhistorys = (e) => {
        e.preventDefault();
        const formValues = formRef.current.getFieldsValue();
        console.log('Form values:', formValues);
        const {description} = formValues;
        if (!givenDate) {
            message.error("Please select a date.");
            return;
        } else if (!description) {
            message.error("Please enter description.");
            return;
        } else {
            if (FamilymemberName === "" || FamilymemberName === undefined) {
                let patient = localStorage.getItem("patientprofile")
                const givenDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
                const data = {
                    "patientId": props.patCode,
                    "patientCode": props.patCode,
                    "givenDate": givenDate,
                    "status": 1,
                    "createdBy": props.patCode,
                    "modifiedBy": props.patCode,
                    // "recordFor": addfamilyhistory.recordFor,
                    "description": description ||  addfamilyhistory.description
                    //-- -> P means patient and U means User
                }
                dispatch(patientaddfamilyhistory(data)).then((result) => {
                    dispatch(getfamilyhistory(props.patCode));
                    props.loadData();
                    formRef.current.resetFields()
                    message.success("Added Successfully");
                    setaddfamilyhistory({});
                    props.closePopup()
                })
            } else {
                let patient = localStorage.getItem("patientprofile")
                // const givenDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
                const data = {
                    "patientId": FamilymemberName,
                    "patientCode": FamilymemberName,
                    "givenDate": givenDate,
                    "status": 1,
                    "createdBy": props.patCode,
                    "modifiedBy": props.patCode,
                    // "recordFor": addfamilyhistory.recordFor,
                    "description": addfamilyhistory.description
                    //-- -> P means patient and U means User
                }
                dispatch(patientaddfamilyhistory(data)).then((result) => {
                    dispatch(getfamilyhistory(props.patCode));
                    props.loadData();
                    formRef.current.resetFields();
                    message.success("Added Successfully");
                    setaddfamilyhistory({});
                    props.closePopup()
                });
            }
        }
    };

    const disableFutureDates = (current) => {
        return current && current > moment().endOf('day'); 
      };


    return (
        <>
            <Form ref={formRef} className="CommonForm" layout="vertical" autoComplete="off">
                <Form.Item label="Record on" name="date">
                    <div className="flex ">
                        <DatePicker className="w-full h-[50px]" onChange={handleDateChange}  disabledDate={disableFutureDates} />
                    </div>
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <Input placeholder="Enter Description" />
                </Form.Item>
                <Form.Item>
                    <button
                        onClick={savefamilyhistorys}
                        className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
                        type="primary"
                        htmlType="submit"
                    >
                        Save details
                    </button>
                </Form.Item>
            </Form>
        </>
    );
}

export default Addfamilyhistory;
