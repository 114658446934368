import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
// import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
import {
  sendOtp,
  verifyLogOtp,
  setLoginModal,
  loginWithPassword,
  getToken,
  mobileExist,
  verifyRegOtp,
} from "../../Redux/Action/userActions";
import { Button, Modal, message } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import { Base64 } from "js-base64";
import useGAEventsTrackers from "../../config/useGAEventsTrackers";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { EnglishText } from "../PageRouting/EnglishText";
import login from "../../Assets/Images/loginImg.jpg";
import Regiterform from "./Regisisterform";
import logoimage from "../../Assets/Images/Logo.svg";
import ForgotPassword from "./ForgotPassword";
import http from "../../Redux/Services/http-common";
import { Logout } from "../../Redux/Action/userActions";
import TermsAndConditions from "../../Assets/privacyPolicy.pdf";
import PrivacyPolicy from "../../Assets/Terms&conditions.pdf";
import Loader from "../Shared/loader";
import { encryptData, secureStorage } from "../../Redux/Reducer/authReducer";
import { trackLoginEvent } from "../utils/analytics/events";
import CurebayButton from "../Shared/Button/PrimaryButton";

const Seperateor = () => {
  return (
    <div className="flex items-center my-5 mx-auto w-3/4">
      <div className="border border-gray-6 flex flex-1"></div>
      <div className="text-xs font-medium text-gray-secondary mx-2">OR</div>
      <div className="border border-gray-6 flex flex-1"></div>
    </div>
  );
};

const FormFooter = (props) => {
  return (
    <div>
      <div className="flex justify-center lg:mx-10 mx-2 my-5 text-center  text-gray-primary">
        <span className="text-gray-primary text-sm font-normal inline flex  ">
          {" "}
          {EnglishText.SIGNUP_OPTION}&nbsp;{" "}
          <p
            className="font-medium text-brand-primary cursor-pointer"
            onClick={(e) => {
              props.setLoginModal(false);
            }}
          >
            {"Sign Up"}
          </p>
        </span>
      </div>
      <div className="text-center  text-xs text-gray-tertiary">
        By continuing, you agree to our{" "}
        <Link
          to={APP_ROUTES.TERMS_AND_CONDITION}
          className="text-brand-secondary"
        >
          Terms of Use
          <br />
        </Link>
        and{" "}
        <Link to={APP_ROUTES.PRIVACY_POLICY} className="text-brand-secondary">
          Privacy Policy
        </Link>
        .
      </div>
      <div className="text-center text-brand-secondary mt-5 font-medium">
        <Link
          to={APP_ROUTES.CONTACTWITHUS}
          className="text-center text-brand-secondary mt-5 font-medium"
        >
          Need Help? Get In Touch
        </Link>
      </div>
    </div>
  );
};

const Login = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [mode, setMode] = useState(props.mode ? props.mode : "Login");
  const [loading, setLoading] = useState(true);
  const [looding, setLooding] = useState({
    isButtonLoading: false,
    isVersionLoading: false,
  });
  const [isSent, setSent] = useState(false);
  const [isOTPType, setType] = useState("");
  const [err, setErr] = useState("");
  const [showPass, setShowPass] = useState(false);
  const history = useHistory();
  const [showLoader, setLoader] = useState(false);
  const [loginVersion, setloginVersion] = useState([""]);
  const [isModalOpen, setIsModalOpen] = useState(props.isModalOpen);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(true);
  const [activeTab, setActiveTab] = useState("terms");
  const [showConsent, setShowConsent] = useState(false);
  const userData = useSelector((state) => state.authReducer.patientData);
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [showLoginContent, setShowLoginContent] = useState(true); // State to toggle between login and sign up content
  const [isregModalOpen, setisregModalOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const GAEventsTrackers = useGAEventsTrackers("LoginUser");
  const [forgotModal, setForgotModal] = useState(false);
  const [timer, setTimer] = useState(60); // Initial countdown timer
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Button initially disabled
  const { patientSession } = userData;
  const [consentInfo, setConsentInfo] = useState({
    privacyPdf: "",
    privacyVersion: "",
    tcPdf: "",
    tcVersion: "",
  });

  const myRef = useRef(null);
  const [user, setuser] = useState({
    userName: "",
    email: "",
    otp: "",
    password: "",
  });
  const [signUser, setsignUser] = useState({
    userName: "",
    email: "",
    otp: "",
    password: "",
    mobileNo: "",
  });
  const location = useLocation();
  console.log("asvhasgvasigvasiuvas", location);

  const success = (text) => {
    messageApi.open({
      type: "success",
      content: text,
    });
  };
  const error = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };
  const warning = (text) => {
    messageApi.open({
      type: "warning",
      content: text,
    });
  };

  useEffect(() =>{
    if(props.isModalOpen){
      setIsModalOpen(true)
    }else{
      setIsModalOpen(false)
    }
  },[props.isModalOpen])

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsButtonDisabled(false); // Enable button after 60 seconds
    }

    // Cleanup interval when component unmounts or timer reaches 0
    return () => clearInterval(countdown);
  }, [timer]);

  const handleCancel = () => {
    setIsModalOpen(false);
    // props.handleClose();
    setsignUser({
      userName: "",
      email: "",
      otp: "",
      password: "",
      mobileNo: "",
    });
    setErr("");
    history.push(APP_ROUTES.HOME , {isLoginModalOpen: false});
  };

  const showForgotModal = () => {
    setIsModalOpen(false);
    setForgotModal(true);
  };

  const forgotModalClose = () => {
    setForgotModal(false);
    setIsModalOpen(true);
  };

  const getVersion = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=PATIENT_B2C`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setloginVersion(data?.data);
      console.log(data?.data, "consent");
      let info = {
        privacyPdf: data?.data[0].consentFile,
        privacyVersion: data?.data[0].versionNumber,
        tcPdf: data?.data[1].consentFile,
        tcVersion: data?.data[1].versionNumber,
      };
      setConsentInfo(info);
      setLoading(false);
    } catch (e) {
      console.log("Error fetching data:", e);
      setLoading(false);
    }
  };
  const handleSignUpClick = () => {
    setMode("Signup");
    setHide(false);
    setsignUser({
      userName: "",
      email: "",
      otp: "",
      password: "",
      mobileNo: "",
    });
    setuser({
      userName: "",
      email: "",
      otp: "",
      password: "",
    });
    setErr("");
    setShowLoginContent(false);
    setIsModalOpen(true);
    // props.handleClose();
  };
  useEffect(() => {
    getVersion();
  }, []);

  useEffect(() => {
    if (loginVersion) {
      console.log("Fetched data:", loginVersion);

      localStorage.setItem("loginVersion", JSON.stringify(loginVersion));
    }
  }, [loginVersion]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    const phonePattern = /^(\+?\d{1,4}[-\s]?)?\d{10,15}$/;

    if (value.length === 0) {
      setErr("");
    }

    if (name === "email") {
      if (!emailPattern.test(value) && !phonePattern.test(value)) {
        setErr("Please enter a valid email address or phone number");
      } else if (value.length > 40) {
        setErr("Input exceeds the maximum length of 40 characters");
      } else {
        setErr("");
      }
    }

    // Update the user state
    setuser({ ...user, [name]: value });
  };

  const handleChange1 = (e) => {
    let inputValue = e.target.value;

    if (e.target.name === "otp") {
      inputValue = inputValue.slice(0, 6);
    } else {
      inputValue = inputValue.replace(/\D/g, "").slice(0, 10);
      const regx = /^[6-9]\d{9}$/;
      if (!regx.test(inputValue)) {
        setErr(EnglishText.LOGIN_ERROR);
      } else {
        setErr("");
      }
    }

    setsignUser({ ...signUser, [e.target.name]: inputValue });
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  };
  const handleChangeOtp = (e) => {
    // alert('lll')
    console.log(e.target.value, "dsvosdvoisdhivpds");

    const re = /^[0-9]*$/; //rules
    if (e.target.value === "" || re.test(e.target.value)) {
      e.target.value.length <= 6 &&
        setuser({ ...user, [e.target.name]: e.target.value });
    }

    // setuser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    window.addEventListener("keydown", LoginWithPassword);

    return () => {
      window.removeEventListener("keydown", LoginWithPassword);
    };
  }, [user]);

  const onVerifyingOtp = async (e) => {
    e.preventDefault();
    handleValidations();
    let data = {
      patientMobileNumber: user.email,
      patientOTP: parseInt(user.otp),
      channel: "W",
    };
    setLooding({
      isButtonLoading: true,
    });
    let encryptedPayload = encryptData(data); // Encrypt the payload
    await props
      .verifyOTP(encryptedPayload)
      .then((result) => {
        setLooding({
          isButtonLoading: false,
        });
        if (result) {
          let status = "true";
          localStorage.setItem("loginstatus", status);
          secureStorage.setItem("token", result?.accessToken);
          localStorage.setItem("loginObj", JSON.stringify(result));
          trackLoginEvent();
          setIsModalOpen(false);
          if(props?.from){
            history.push(props.from);
          }else{
            history.push(APP_ROUTES.HOME);
          }
        }
      })
      .catch((error) => {
        setLooding({
          isButtonLoading: false,
        });
        if (error?.message) {
          setErr(error?.response?.data?.details[0]);
        }
      });
  };

  const handleValidations = () => {
    if (user.email.length == 0) {
      setErr(EnglishText.ENTER_PHONE);
      return 0;
    }
    if (isNaN(user.email)) {
      setErr(EnglishText.LOGIN_ERROR);
      return 0;
    }
    return 1;
  };

  const sendOTPMobile = async (e) => {
    e.preventDefault();
    setErr("");
    if (!handleValidations()) return;

    setLooding({
      isButtonLoading: true,
    });
    let dataObj = {
      mobileNo: user.email,
      mobileCheck: 2,
    };

    await props
      .sendOTP(dataObj)
      .then((result) => {
        console.log("result", result);
        setSent(true);
        setErr("");
        setLooding({
          isButtonLoading: false,
        });
      }).catch((error) => {
        setLooding({
          isButtonLoading: false,
        });
        console.log("ERRORrr", error);
        if (error?.message) {
          console.log("qwerty", error);
          setLooding({
            isButtonLoading: false,
          });
          setErr(error?.response?.data?.details[0]);
        }
      });
  };
  const modalClose = () => {
    window.location.reload();
  };
  const LoginWithPassword = async (e) => {
    console.log(e, "idsjviodovisdipsdv", user);
    if ((e.key != "Enter" && e.type == "keydown") || !user.password.length) {
      return;
    }
    // e.preventDefault();
    const data = {
      channel: "W",
      passWord: Base64.encode(user?.password),
      patientEmail: user?.email,
    };

    console.log("user Data", user);
    setLooding({
      isButtonLoading: true,
    });
    let encryptedPayload = encryptData(data); // Encrypt the payload
    await props.getToken().then((result) => {
      props
        .loginWithPassword(encryptedPayload)
        .then((result) => {
          setLooding({
            isButtonLoading: false,
          });
          if (result) {
            setIsModalOpen(false);
            // props.handleClose();
            console.log(result?.accessToken, "Dvoisdgviusdgvusdvdsvsd");
            secureStorage.setItem("token", result?.accessToken);
            let status = "true";
            localStorage.setItem("loginstatus", status);
            trackLoginEvent();
            if(props?.from){
              history.push(props.from);
            }else{
              history.push(APP_ROUTES.HOME);
            }
          }
        })
        .catch((error) => {
          setLooding({
            isButtonLoading: false,
          });
          console.log("EROOR", error);
          if (error?.message) {
            setErr(error.response.data.details[0]);
          }
        });
    });
    // GAEventsTrackers("Login", "logged into CureBay Application", "Login-In");
  };

  console.log(isModalOpen, "sdfisdfohidh");

  const reEnterMobile = () => {
    console.log("hello");
    setSent(false);
    setLoader(false);
    setType("");
    setErr("");
  };
  const signupOTP = async (e) => {
    e.preventDefault();
    const regx = /^[6-9]\d{9}$/;
    if (!regx.test(signUser.mobileNo)) {
      setErr(EnglishText.LOGIN_ERROR);
      return;
    }
    if (signUser.mobileNo?.length !== 10) {
      return setErr("Please Enter a 10-digit Mobile Number");
    }

    setLooding({
      isButtonLoading: true,
    });
    setErr("");

    let dataObj = {
      mobileNo: signUser.mobileNo,
      mobileCheck: 1,
      otp: signUser.otp,
    };

    if (!hide) {
      await props
        .mobileExist(dataObj)
        .then(async (result) => {
          console.log("dsfnsdj", JSON.stringify());
          if (!result.message) {
            await props
              .sendOTP(dataObj)
              .then(() => {
                setHide(!hide);
                setLooding({
                  isButtonLoading: false
                })
                setTimer(60);
                setIsButtonDisabled(true);
              })
              .catch((error) => {
                setLooding({
                  isButtonLoading: false,
                });
                setErr(error.response.data.details[0]);
              });
          } else {
            setErr("The mobile number already exists");
            setLooding({
              isButtonLoading: false,
            });
          }
        })
        .catch((error) => {
          setLooding({
            isButtonLoading: false,
          });
          if (error?.message) {
            setErr(error.response.data.details[0]);
          }
        });
    } else {
      await props.getToken().then(() => {
        props
          .verifyRegOtp(dataObj)
          .then(() => {
            setLooding({
              isButtonLoading: false,
            });
            setisregModalOpen(true);
          })
          .catch((error) => {
            setLooding({
              isButtonLoading: false,
            });
            if (error?.message) {
              setErr(error.response.data.details[0]);
            }
          });
      });
    }
  };

  const showHidePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleLoginClick = () => {
    // Update state to show login content
    setMode("Login");
    setSent(false);
    setShowLoginContent(true);
    setIsModalOpen(true);
    setForgotModal(false);
  };
  const loginValidation = () => {
    if (user?.email.length == 0) {
      setErr(EnglishText.ENTER_EMAIL);
    } else if (user?.email.length > 0) {
      var re = /\S+@\S+\.\S+/;
      if (!re.test(user.email)) {
        setErr(EnglishText.VALID_EMAIL);
        // setuser({...user, email : ""});
      } else {
        setType(isOTPType == "" ? "password" : "");
        setErr("");
      }
    } else {
      setType(isOTPType == "" ? "password" : "");
      setErr("");
    }

    //  else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email)) {
    //     setErr(EnglishText.VALID_EMAIL);
    //     // setuser({...user, email : ""});
    //   } else {
    //     setType(isOTPType == "" ? "password" : "");
    //     setErr("");
    //   }
  };
  useEffect(() => {
    if (props.mode == "Login") {
      setMode(props.mode);
      setIsModalOpen(true);
      setLoginModal(true);
    } else {
      setMode(props.mode);

      setIsModalOpen(true);
      setLoginModal(false);
    }
  }, [props.mode, props.click]);
  const loginfalse = () => {
    setHide(false);
    setisregModalOpen(false);
  };

  const contactUs = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    setShowConsent(!showConsent);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const agree = async () => {
    const payload = {
      createdBy: "CUREBAYADMIN",
      privacyPolicyConsent: 1,
      sourceChannel: "B2C",
      status: 1,
      termsAndConditionConsent: 1,
      type: "P",
      patientId: userData.code,
      privacyVersionNumber: consentInfo.privacyVersion,
      tcVersionNumber: consentInfo.tcVersion,
    };
    setLoading(true);

    console.log("Payload:", payload);
    const postResponse = await http.post(
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
      payload
    );
    setIsModalOpen2(false);

    console.log("POST Request Response for test", postResponse);
    if (postResponse.status === 200) {
      if (showConsent) {
        handleClick();
      }
      setIsVisible(false);
      setLoading(false);
    }
  };

  const disAgree = async () => {
    const payload = {
      createdBy: "CUREBAYADMIN",
      privacyPolicyConsent: 0,
      sourceChannel: "B2C",
      status: 1,
      termsAndConditionConsent: 0,
      type: "P",
      patientId: userData.code,
      privacyVersionNumber: consentInfo.privacyVersion,
      tcVersionNumber: consentInfo.tcVersion,
    };
    setLoading(true);

    console.log("Payload:", payload);
    const postResponse = await http.post(
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
      payload
    );

    console.log("POST Request Response for test", postResponse);
    if (postResponse.status === 200) {
      setLoading(false);

      if (showConsent) {
        handleClick();
      }
      setIsVisible(false);
      logout();
    }
  };

  const logout = (e) => {
    setLoading(true);

    localStorage.clear();
    let dataObj = {
      sessionId: patientSession?.sessionid,
      userCode: patientSession?.code,
    };
    dispatch(Logout(dataObj));
    setLoading(false);

    history.push("/");
  };

  

  return (
    <>
      {contextHolder}
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={isregModalOpen ? "50%" : "40%"}
        footer={[]}
        className="loginmodal"
      >
        {loading && <Loader />}
        {!isregModalOpen && (
          <div>
            {showLoginContent && mode == "Login" ? (
              // Login content
              <div className="items-center bg-white md:px-6 md:py-6 bg-lightBlue">
                <div className="logo flex justify-center">
                  <img src={logoimage} alt="images" className="w-[140px]" />
                </div>
                <p className="font-semibold text-brand-primary text-2xl text-primaryColor mt-5">
                  Login
                </p>
                <div className="mt-5">
                  {isSent && (
                    <div>
                      <div>
                        <label className="text-primaryColor mb-3 inline-block">
                          OTP
                        </label>
                        <input
                          type="text"
                          name="otp"
                          maxLength="6"
                          className="w-full h-[50px] rounded-lg bg-White px-5 border border-gray"
                          placeholder="Enter OTP"
                          onChange={handleChange}
                        />
                      </div>
                      {err && (
                        <span
                          className="text-red-600 font-normal text-xs tracking-widest"
                          style={{ color: "red" }}
                        >
                          {err}
                        </span>
                      )}
                      <div className="flex w-full mt-6">
                        <CurebayButton
                          loading={looding.isButtonLoading}
                          type="primary"
                          onClick={onVerifyingOtp}
                          className="w-full"
                          size="large"
                        >
                          Verify OTP
                        </CurebayButton>
                      </div>
                      {/* <div> <button onClick={sendOTPMobile} disabled={isButtonDisabled}>
        Resend OTP {isButtonDisabled && `in ${timer} sec`}
      </button> </div> */}
                    </div>
                  )}
                  {!isSent && isOTPType == "" && (
                    <div>
                      <div className="text-textGray">
                        <label className="text-primaryColor mb-3 inline-block">
                          Email/Mobile Number
                        </label>
                        <input
                          type="text"
                          name="email"
                          maxLength="40"
                          className="w-full h-[50px] rounded-lg bg-White px-5 border border-gray"
                          placeholder="Please enter email address or phone no."
                          onChange={handleChange}
                        />
                      </div>
                      {err && (
                        <span
                          className="text-red-600 font-normal text-xs tracking-widest ml-2"
                          style={{ color: "red" }}
                        >
                          {err}
                        </span>
                      )}
                      <div className="flex w-full mt-6">
                        <CurebayButton
                          loading={looding.isButtonLoading}
                          type="primary"
                          onClick={sendOTPMobile}
                          className="w-full"
                          size="large"
                        >
                          Send OTP
                        </CurebayButton>
                        {/* <button
                          class="p-3 bg-primaryColor  text-White w-full  rounded-lg text-center text-base font-normal}"
                          onClick={sendOTPMobile}
                        >
                          Send OTP
                        </button> */}
                      </div>
                    </div>
                  )}
                  {isOTPType === "password" && (
                    <div>
                      <div>
                        <label className="text-primaryColor mb-3 inline-block">
                          {" "}
                          Password
                        </label>
                        <div className="relative">
                          <input
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            maxLength="40"
                            className="w-full h-[50px] rounded-lg bg-White px-5 border border-gray"
                            placeholder="Enter Password"
                            onChange={handleChange}
                          />
                          <div className="absolute top-[10%] right-[0]">
                            {passwordShown ? (
                              <EyeIcon
                                className=" cursor-pointer mt-2 relative text-gray right-4 h-5"
                                onClick={showHidePassword}
                              />
                            ) : (
                              <EyeOffIcon
                                className="absolute cursor-pointer mt-2 text-gray relative right-4 h-5"
                                onClick={showHidePassword}
                              />
                            )}
                          </div>
                        </div>
                        <p
                          className="text-end mt-2 cursor-pointer text-primaryColor font-semibold"
                          onClick={showForgotModal}
                        >
                          Forgot Password?
                        </p>
                      </div>
                      {err && (
                        <span
                          className="text-red-600 font-normal text-xs tracking-widest"
                          style={{ color: "red" }}
                        >
                          {err}
                        </span>
                      )}
                      <div className="flex w-full mt-6">
                        <CurebayButton
                          loading={looding.isButtonLoading}
                          type="primary"
                          onClick={LoginWithPassword}
                          className="w-full"
                          size="large"
                        >
                          Login
                        </CurebayButton>
                      </div>
                    </div>
                  )}
                  {/* <div className="flex items-center my-5 mx-auto w-3/4">
                  <div className="border border-lightGray flex flex-1"></div>
                  <div className="text-xs font-medium text-gray-secondary mx-2">
                    OR
                  </div>
                  <div className="border border-lightGray flex flex-1"></div>
                </div> */}
                  <div className="flex w-full mt-3 justify-end">
                    <button
                      class=" text-primaryColor text-sm underline"
                      onClick={loginValidation}
                    >
                      {isOTPType == "" ? "Use Password" : "Login with OTP"}
                    </button>
                  </div>
                  <div>
                    <div className=" lg:mx-0 my-3  text-primaryColor">
                      <span className="text-textGray text-base font-medium in-line flex ">
                        Don't have an account?&nbsp;{" "}
                        <p
                          class="font-medium text-base text-primaryColor cursor-pointer underline"
                          onClick={handleSignUpClick}
                        >
                          Sign Up
                        </p>
                      </span>
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                  <div className="text-sm text-textGray flex flex-wrap gap-1">
                    By continuing, you agree to our{" "}
                    <a
                      class="text-textGray underline"
                      // href="/terms-and-condition"
                      onClick={showModal2}
                    >
                      Terms of Use{" "}
                    </a>
                    <div>
                      &&nbsp;{" "}
                      <a
                        class="text-textGray underline"
                        // href="/terms-and-condition"
                        onClick={showModal2}
                      >
                        Privacy Policy.{" "}
                      </a>
                    </div>
                  </div>
                  <div className="text-sm text-primaryColor mt-3 font-medium underline">
                    <a
                      className=" text-primaryColor mt-5 font-medium"
                      // href="/contact-with-us"
                      href="#Contactus"
                      onClick={contactUs}
                    >
                      Need Help? Get In Touch
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              // Sign up content

              <div className="items-center bg-white md:px-6 md:py-6">
                <div className="logo flex justify-center">
                  <img src={logoimage} alt="images" className="w-[140px]" />
                </div>
                <p className="font-semibold text-brand-primary text-2xl text-primaryColor mt-5">
                  Sign up
                </p>
                <div className="mt-5">
                  {!hide && (
                    <div>
                      <div>
                        <label className="text-primaryColor mb-3 inline-block">
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          name="mobileNo"
                          maxLength="10"
                          value={signUser.mobileNo}
                          className="w-full h-[50px] rounded-lg bg-White px-5 border border-gray"
                          placeholder="Enter Your Phone Number "
                          onChange={handleChange1}
                          onKeyDown={handleKeyDown}
                          onKeyUp={handleKeyUp}
                        />
                      </div>
                      {err && (
                        <span
                          className="text-red-600 font-normal text-xs tracking-widest ml-2"
                          style={{ color: "red" }}
                        >
                          {err}
                        </span>
                      )}
                      <div className="flex w-full mt-6">
                        <CurebayButton
                          loading={looding.isButtonLoading}
                          type="primary"
                          onClick={signupOTP}
                          className="w-full"
                          size="large"
                        >
                          Send OTP
                        </CurebayButton>
                      </div>
                    </div>
                  )}
                  {hide && (
                    <div>
                      <div>
                        <label className="text-primaryColor mb-3 inline-block">
                          OTP
                        </label>
                        <input
                          type="text"
                          name="otp"
                          maxLength="6"
                          className="w-full h-[50px] rounded-lg bg-White px-5 border border-gray"
                          placeholder="Enter OTP"
                          onChange={handleChange1}
                          onKeyDown={handleKeyDown}
                          onKeyUp={handleKeyUp}
                        />
                      </div>
                      {err && (
                        <span
                          className="text-red-600 font-normal text-xs tracking-widest ml-2"
                          style={{ color: "red" }}
                        >
                          {err}
                        </span>
                      )}
                      <div className="flex w-full mt-6">
                        <CurebayButton
                          loading={looding.isButtonLoading}
                          type="primary"
                          onClick={signupOTP}
                          className="w-full"
                          size="large"
                        >
                          Verify OTP
                        </CurebayButton>
                      </div>
                      {/* <div className="flex justify-end underline mt-2" > <button onClick={signupOTP} disabled={isButtonDisabled}>
        Resend OTP {isButtonDisabled && `in ${timer} sec`}
      </button> </div> */}
                    </div>
                  )}

                  <br></br>
                  <br></br>
                  <div>
                    <div className=" lg:mx-0 my-3  text-primaryColor">
                      <span className="text-textGray text-base font-medium in-line flex ">
                        Already have an account?&nbsp;{" "}
                        <p
                          class="font-medium text-base text-primaryColor cursor-pointer underline"
                          onClick={handleLoginClick}
                        >
                          Login
                        </p>
                      </span>
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                  <div className="text-sm text-textGray flex flex-wrap gap-1">
                    By continuing, you agree to our{" "}
                    <a
                      class="text-textGray underline"
                      onClick={showModal2}
                      // href="/terms-and-condition"
                    >
                      Terms of use{" "}
                    </a>
                    <div>
                      &&nbsp;{" "}
                      <a
                        class="text-textGray underline"
                        // href="/terms-and-condition"
                        onClick={showModal2}
                      >
                        Privacy Policy.{" "}
                      </a>
                    </div>
                  </div>
                  <div className="text-sm text-primaryColor mt-3 font-medium underline">
                    <a
                      className=" text-primaryColor mt-5 font-medium"
                      // href="/contact-with-us"
                      href="#Contactus"
                      onClick={contactUs}
                    >
                      Need Help? Get In Touch
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {isregModalOpen && (
          <div className="items-center bg-white md:px-6 md:py-6 bg-lightBlue">
            <div className="logo flex justify-center">
              <img src={logoimage} alt="images" className="w-[140px]" />
            </div>
            {/* First Column */}
            <div className=" hidden p-3 bg-brand-alabaster">
              <img
                width="0"
                height="0"
                className="w-full hidden object-cover sm:h-full sm:w-3/4 md:w-full md:h-3/4 lg:mb-5"
                src={login}
                alt="banner"
              />
              <div className="font-medium text-brand-primary text-xl text-center">
                {EnglishText.REGISTERFORM_BANNER_MSG1}
              </div>
              <div className="text-xs p-1 font-normal text-brand-manatee text-center">
                {EnglishText.REGISTERFORM_BANNER_MSG2}
              </div>
            </div>

            {/* Second Column */}
            <div className="items-center bg-white">
              <p className="font-semibold text-brand-primary text-2xl text-primaryColor mt-5">
                {"Register"}
              </p>

              <div className="">
                <div className="text-sm mt-3 text-textGray">
                  {EnglishText.REGISTERFORM_PARA}
                </div>
                <div className="py-8">
                  {/* Additional content */}
                  <Regiterform
                    modalClose={modalClose}
                    loginfalse={loginfalse}
                    mobile={signUser?.mobileNo}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title={<p className="text-xl font-bold">Consent</p>}
        open={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        width={1000}
        style={{
          top: 20,
        }}
        footer={[
          <div className="w-full flex justify-end items-center gap-2">
            <button
              onClick={agree}
              className="bg-secondaryColor p-2 w-40 rounded-md text-White font-bold"
            >
              Agree
            </button>
            <button
              className="bg-[red] p-2 w-40 rounded-md text-White font-bold"
              onClick={disAgree}
            >
              Disagree
            </button>
          </div>,
        ]}
      >
        <div className="flex text-xl font-semibold gap-6 p-2 text-primaryColor w-full">
          <div
            onClick={() => handleTabClick("terms")}
            className={`text-xl p-1  cursor-pointer transition-shadow duration-1000 ${
              activeTab === "terms"
                ? "border-b-4 border-primaryColor rounded-t-lg"
                : "text-textGray"
            }`}
          >
            Terms and Conditions
          </div>
          <div
            onClick={() => handleTabClick("privacy")}
            className={`cursor-pointer p-1 transition-shadow duration-1000 ${
              activeTab === "privacy"
                ? "border-b-4 border-primaryColor rounded-t-lg"
                : "text-textGray"
            }`}
          >
            Privacy Policy
          </div>
        </div>
        <div className="mt-4 h-96">
          {activeTab === "terms" && (
            <div className="h-full">
              <iframe
                // height="95px"
                // width="68px"
                className="border rounded cursor-pointer h-96 w-full"
                src={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.tcPdf}`}
              />
              {/* <embed
                src={PrivacyPolicy}
                // src={TermsAndConditions}
                type="application/pdf"
                className="w-full h-full"
              /> */}
            </div>
          )}
          {activeTab === "privacy" && (
            <div className="h-full">
              <iframe
                // height="95px"
                // width="68px"
                className="border rounded cursor-pointer h-96 w-full"
                src={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.privacyPdf}`}
              />
              {/* <embed
                // src={PrivacyPolicy}
                src={TermsAndConditions}
                type="application/pdf"
                className="w-full h-full"
              /> */}
            </div>
          )}
        </div>
      </Modal>

      <Modal
        title={
          <p className="text-xl text-primaryColor bg-lightGray my-4">
            Forgot Password
          </p>
        }
        open={forgotModal}
        width={"40%"}
        height={"40%"}
        footer={[]}
        className="loginmodal"
        onCancel={forgotModalClose}
      >
        <ForgotPassword
          forgotModal={forgotModal}
          handleLoginClick={handleLoginClick}
          setForgotModal={setForgotModal}
          showForgotModal={showForgotModal}
          forgotModalClose={forgotModalClose}
        />
      </Modal>
      {/* <Modal  width={"60%"} open={isregModalOpen} onCancel={handleCancel }footer={[]}>
     
      </Modal> */}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  sendOTP: (data) => dispatch(sendOtp(data)),
  verifyOTP: (data) => dispatch(verifyLogOtp(data)),
  loginWithPassword: (data) => dispatch(loginWithPassword(data)),
  setLoginModal: (data) => dispatch(setLoginModal(data)),
  getToken: () => dispatch(getToken()),
  mobileExist: (data) => dispatch(mobileExist(data)),

  verifyRegOtp: (data) => dispatch(verifyRegOtp(data)),
});

export default connect(null, mapDispatchToProps)(Login);
