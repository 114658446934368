import DiagnosticService from "../../Redux/Services/CartPlaceService";

async function UploadPrescriptionHelper(cart, doc, type){
    console.log(doc, "dsvdsjvgdsgvdisugvuids")
    if(type === "key"){
        console.log(doc,"Dfdfhdisgfiysdfs")
        let data = doc.prescriptionDocArray.join()
        cart.patientMedicineOrder[0].savedImagesKeys = data.toString()
        cart.patientMedicineOrder[0].prescriptionDocCollection = null
    }
    if(type === "base64"){
        let docCollection = []
        docCollection.push({
            prescriptionDoc : doc,
            prescriptionDocType: "pdf"
          })
          cart.patientMedicineOrder[0].prescriptionDocCollection = docCollection
          cart.patientMedicineOrder[0].savedImagesKeys = null
    }
    await DiagnosticService.uploadPrescriptionDoc(
        cart.patientMedicineOrder[0].orderId,
        cart
      )
}

export default UploadPrescriptionHelper




