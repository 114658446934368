import MemberCard from "../../../../Assets/Images/Membership/singlePlan.jpg";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMymembershipList } from "../../../../Redux/Action/packages";
import moment from "moment";

function SummaryMembership(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  const [list, setList] = useState([]);

  useEffect(() => {
    dispatch(getMymembershipList(props.patCode)).then((res) => {
      setList(res);
    });
  }, [props.patCode]);

  return (
    <div className="bg-White rounded-md ">
      <div className="bg-[#e5d0ff] px-5 py-3 rounded-md flex justify-between items-center">
        <h5 className="text-lg font-medium">My Membership</h5>
      </div>
      <div className="p-5 h-[300px] overflow-auto">
        {list.length > 0 ? (
          list?.map((user, i) => (
            <div className="bg-White common-shadow p-5" key={i}>
              <div className="flex gap-5">
                <div className="w-[120px] md:w-[150px] h-[100px] ">
                  <img
                    src={MemberCard}
                    alt="img"
                    className="w-full h-full object-contain rounded-md "
                  />
                </div>
                <div className="w-full h-full ">
                  <div className="">
                    <div className="flex flex-wrap md:gap-3 items-center">
                      <p className="text-textGray font-light text-sm">
                        Member Name :
                      </p>
                      <h3 className="text-textGray text-sm font-medium ">
                        {user.patientName}
                      </h3>
                    </div>
                    <div className="flex flex-wrap md:gap-3 items-center mt-2">
                      <p className="text-textGray font-light text-sm">
                        Membership Card Number :
                      </p>
                      <h3 className="text-textGray text-sm font-medium">
                        {user?.membershipCard}
                      </h3>
                    </div>
                  </div>
                  <div className="hidden md:block justify-between mt-2">
                    <div className="flex gap-2 items-center">
                      <p className="text-textGray font-light text-sm">
                        Start Date :
                      </p>
                      <h3 className="text-textGray text-sm font-medium">
                        {moment(user?.fromDate).format("DD/MM/YYYY")}
                      </h3>
                    </div>
                    <div className="flex gap-2 items-center mt-2">
                      <p className="text-textGray font-light text-sm">
                        Expiry Date :
                      </p>
                      <h3 className="text-textGray text-sm font-medium">
                        {moment(user?.toDate).format("DD/MM/YYYY")}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex md:hidden justify-between mt-2">
                <div className="flex flex-wrap md:gap-2 items-center">
                  <p className="text-textGray font-light text-sm">
                    Start Date :
                  </p>
                  <h3 className="text-textGray text-sm font-medium">
                    {moment(user?.fromDate).format("MM/DD/YYYY")}
                  </h3>
                </div>
                <div className="flex flex-wrap md:gap-2 items-center md:mt-2">
                  <p className="text-textGray font-light text-sm">
                    Expiry Date :
                  </p>
                  <h3 className="text-textGray text-sm font-medium">
                    {moment(user?.fromDate).format("MM/DD/YYYY")}
                  </h3>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="w-full align-center text-center">
            <p className="text-center item-center mt-40 mb-40 text-gray-400">
              No Membership available
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SummaryMembership;
