import React from "react";
import LogoWhite from "../../Assets/Images/logo-white.svg";
import CallImages from "../../Assets/Images/Icons/call.svg";
import MailImages from "../../Assets/Images/Icons/mail.svg";
import Instagram from "../../Assets/Images/Icons/instagram.svg";
import Youtube from "../../Assets/Images/Icons/youtube.svg";
import Facebook from "../../Assets/Images/Icons/facebook.svg";
import Linkedin from "../../Assets/Images/Icons/linkedin.svg";
import iso from "../../Assets/Images/iso.png";
import sprinto from "../../Assets/Images/Seal_HIPAA.svg";

import { useHistory } from "react-router-dom";

function Footer() {
  const history = useHistory();

  const handleNavigation = (path) => {
    history.push(path);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    }else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <footer className="bg-lightGray">
      <div className="bg-primaryColor  md:rounded-t-[40px]">
        <div className="container mx-auto pt-[50px] md:pt-[90px]">
          <div className="md:grid grid-cols-2 gap-5 mx-4">
            <div className="">
              <img
                src={LogoWhite}
                alt="images"
                className="cursor-pointer w-[150px] md:w-[200px] mb-5"
                onClick={() => handleNavigation("/")}
              />
              <p className="text-secondaryColor md:text-lg font-semibold mt-5">
              CureBay Pharmacy Pvt. Ltd.
              </p>
              <p className="text-White text-sm md:text-base mb-5">
                D19, 4th floor, OCAC Tower, Doordarshan Colony <br /> Acharya
                Vihar, Bhubaneswar, Odisha 751022
              </p>
              <div
                onClick={() => window.open("tel:+918335000999")}
                className="flex gap-3 mb-3 cursor-pointer"
              >
                <img src={CallImages} alt="images" />
                <div className="text-White">+91-8335 000 999</div>
              </div>
              <div
                onClick={() => window.open("mailto:contact@curebay.com")}
                className="flex gap-3 mb-3 cursor-pointer"
              >
                <img src={MailImages} alt="images" />
                <div className="text-White text-sm">contact@curebay.com</div>
              </div>
              <div
                onClick={() => window.open("mailto:support@curebay.com")}
                className="flex gap-3 mb-3 cursor-pointer"
              >
                <img src={MailImages} alt="images" />
                <div className="text-White text-sm">support@curebay.com</div>
              </div>
       
              <div className="md:flex gap-5 my-10 hidden">
                <div
                onClick={() =>
                  window.open(
                    "https://facebook.com/CureBay-106822021819382/"
                  )
                }
                  className="cursor-pointer"
                >
                  <img src={Facebook} alt="images" />
                </div>
                <div
                  onClick={() =>
                    window.open(
                      "https://instagram.com/cure_bay?utm_medium=copy_link"
                    )
                  }
                  className="cursor-pointer"
                >
                  <img src={Instagram} alt="images" />
                </div>
                <div
                  onClick={() =>
                    window.open(
                      "https://youtube.com/@curebayofficial?si=RnJBidaNnFV49cnt"
                    )
                  }
                  className="cursor-pointer"
                >
                  <img src={Youtube} alt="images" />
                </div>
                <div
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/80637697/admin/"
                    )
                  }
                  className="cursor-pointer"
                >
                  <img src={Linkedin} alt="images" />
                </div>
              </div>

              <div className="md:flex gap-5 my-10 hidden">
                <div className="flex"
                 
                >
                  <img src={iso} alt="images" className="h-[100px] w-[160px]"  />
                  <img src={sprinto} alt="images" className="h-[120px] w-[160px]"  />
                </div>
              
            
              </div>
              {/* <div className="md:flex hidden">
                <div className="cursor-pointer">
                  <img src={Googleplay} alt="images" />
                </div>
                <div className="cursor-pointer">
                  <img src={Appstore} alt="images" />
                </div>
              </div> */}
            </div>
            <div className="grid grid-cols-2 mt-7">
              <div>
                <div className="text-White text-lg mb-4">Services</div>
                <ul className="text-White text-sm md:text-base font-light grid gap-3">
                  <li className="cursor-pointer hover:text-secondaryColor">
                    <div
                      onClick={() => handleNavigation("/services/membership")}
                    >
                      Membership
                    </div>
                  </li>
                  <li className="cursor-pointer hover:text-secondaryColor">
                    <div
                       onClick={() => handleNavigation("/services/totalcare")
                      }
                    >
                      Totalcare
                    </div>
                  </li>
                  <li className="cursor-pointer hover:text-secondaryColor">
                    <div
                      onClick={() =>
                        handleNavigation("/services/book-an-appointment")
                      }
                    >
                      Consult a Doctor
                    </div>
                  </li>
                  <li className="cursor-pointer hover:text-secondaryColor">
                    <div
                      onClick={() =>
                        handleNavigation("/services/order-medicines")
                      }
                    >
                      Order Medicine
                    </div>
                  </li>
                  <li className="cursor-pointer hover:text-secondaryColor">
                    <div onClick={() => handleNavigation("/services/lab")}>
                      Book a Lab Test
                    </div>
                  </li>
                  <li className="cursor-pointer hover:text-secondaryColor">
                    <div onClick={() => handleNavigation("/services/surgery")}>
                      Consult for Surgery
                    </div>
                  </li>
                  <li className="cursor-pointer hover:text-secondaryColor">
                    <div
                      onClick={() => handleNavigation("/services/concierge")}
                    >
                      {" "}
                      Concierge Services
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <div className="text-White text-lg mb-4">About</div>
                <ul className="text-White text-sm md:text-base font-light grid gap-3">
                  <li
                    className="cursor-pointer hover:text-secondaryColor"
                    onClick={() => handleNavigation("/why-curebay")}
                  >
                    <div>Why CureBay</div>
                  </li>
                  <li
                    className="cursor-pointer hover:text-secondaryColor"
                    onClick={() => handleNavigation("/our-team")}
                  >
                    <div>Our Team</div>
                  </li>
                  <li
                    className="cursor-pointer hover:text-secondaryColor"
                    onClick={() => handleNavigation("/blogs")}
                  >
                    <div>Blogs</div>
                  </li>
                  <li
                    className="cursor-pointer hover:text-secondaryColor"
                    onClick={() => handleNavigation("/media-news")}
                  >
                    <div>Media & News</div>
                  </li>
                  <li
                    className="cursor-pointer hover:text-secondaryColor"
                    onClick={() => handleNavigation("/awards")}
                  >
                    <div>Awards</div>
                  </li>
                  <li
                    className="cursor-pointer hover:text-secondaryColor"
                    onClick={() => handleNavigation("/privacy-policy")}
                  >
                    <div>Privacy Policy</div>
                  </li>
                  <li
                    className="cursor-pointer hover:text-secondaryColor"
                    onClick={() => handleNavigation("/terms-conditions")}
                  >
                    <div>Terms of Use</div>
                  </li>
                  <li
                    className="cursor-pointer hover:text-secondaryColor"
                    onClick={() => handleNavigation("/refund-policy")}
                  >
                    <div> Return and Refund Policy</div>
                  </li>
                  <li
                    className="cursor-pointer hover:text-secondaryColor"
                    onClick={() => handleNavigation("/Medicine-Delivery-Policy")}
                  >
                    <div>Medicine Delivery Policy</div>
                  </li>
                  <li
                    className="cursor-pointer hover:text-secondaryColor"
                    onClick={() => handleNavigation("/curebay-career")}
                  >
                    <div>Careers</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="block md:hidden mt-10   ">
              {/* <div className="flex">
                <div className="cursor-pointer">
                  <img src={Googleplay} alt="images" />
                </div>
                <div className="cursor-pointer">
                  <img src={Appstore} alt="images" />
                </div>
              </div> */}
              <div className="flex justify-center gap-8 mt-10 md:hidden border-t-[#ffffff5e] border-t pt-5">
                <div
                  onClick={() =>
                    window.open("https://facebook.com/CureBay-106822021819382/")
                  }
                  className="cursor-pointer"
                >
                  <img src={Facebook} alt="images" />
                </div>
                <div
                  onClick={() =>
                    window.open(
                      "https://instagram.com/cure_bay?utm_medium=copy_link"
                    )
                  }
                  className="cursor-pointer"
                >
                  <img src={Instagram} alt="images" />
                </div>
                <div
                  onClick={() =>
                    window.open(
                      "https://youtube.com/@curebayofficial?si=RnJBidaNnFV49cnt"
                    )
                  }
                  className="cursor-pointer"
                >
                  <img src={Youtube} alt="images" />
                </div>
                <div
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/80637697/admin/"
                    )
                  }
                  className="cursor-pointer"
                >
                  <img src={Linkedin} alt="images" />
                </div>
              </div>
              <div className="flex justify-center gap-8 mt-10 md:hidden border-t-[#ffffff5e] border-t pt-5">
                <div className="flex"
                 
                >
                  <img src={iso} alt="images" className="h-[80px] w-[120px]" />
                  <img src={sprinto} alt="images" className="h-[100px] w-[160px]"  />
                </div>
              
                 
              
               
              </div>
            </div>
          </div>
          <div className="border-t-[#ffffff5e] border-t mt-5 py-8">
            <p className="text-White text-xs md:text-sm text-center">
              Copyright © 2024 <br />
              All Rights Reserved by CureBay Pharmacy Pvt. Ltd.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
