export const VITALS = {
  REQUEST_ALLERGYLIST: "REQUEST_ALLERGYLIST",
  SUCCESS_ALLERGYLIST: "SUCCESS_ALLERGYLIST",
  FAILED_ALLERGYLIST: "FAILED_ALLERGYLIST",

  REQUEST_MEDICATIONHISTORYLIST: "REQUEST_MEDICATIONHISTORYLIST",
  SUCCESS_MEDICATIONHISTORYLIST: "SUCCESS_MEDICATIONHISTORYLIST",
  FAILED_MEDICATIONHISTORYLIST: "FAILED_MEDICATIONHISTORYLIST",

  REQUEST_MEDICALHISTORYLIST: "REQUEST_MEDICALHISTORYLIST",
  SUCCESS_MEDICALHISTORYLIST: "SUCCESS_MEDICALHISTORYLIST",
  FAILED_MEDICALHISTORYLIST: "FAILED_MEDICALHISTORYLIST",

  REQUEST_VITALSLIST: "REQUEST_VITALSLIST",
  SUCCESS_VITALSLIST: "SUCCESS_VITALSLIST",
  FAILED_VITALSLIST: "FAILED_VITALSLIST",

  REQUEST_ADDVITALS: "REQUEST_ADDVITALS",
  SUCCESS_ADDVITALS: "SUCCESS_ADDVITALS",
  FAILED_ADDVITALS: "FAILED_ADDVITALS",
};
