import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, message, Form, Input } from "antd";
import moment from "moment";
import { patientaddallergy, patientEditallergy, getPatientallergylist } from "../../../../../Redux/Action/UserprofileActions";

function AddAllergy(props) {
  const dispatch = useDispatch();
  const [addallergies, setAddallergy] = useState([]);
  const [givenDate, setGivenDate] = useState("");
  const [givenDateShow, setGivenDateShow] = useState("");
  const formRef = useRef();

  useEffect(() => {
    if (props?.editallergy) {
      setAddallergy(props?.editallergy);
      setGivenDateShow(moment(props?.editallergy?.givenDate).format("MM/DD/yyyy"));
    }
  }, [props?.editallergy]);

  const patientCode = useSelector((state) => state.authReducer.patientCode);

  const handleChange = (e) => {
    setAddallergy({ ...addallergies, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date, dateString) => {
    const Currdate = new Date();
    const hours = String(Currdate.getHours()).padStart(2, "0");
    const minutes = String(Currdate.getMinutes()).padStart(2, "0");
    const seconds = String(Currdate.getSeconds()).padStart(2, "0");
    const constructedDate = dateString + ` ${hours}:${minutes}:${seconds}`;
    const formattedDate = moment(constructedDate).format("YYYY-MM-DD HH:mm:ss");
    setGivenDate(formattedDate);
    setGivenDateShow(formattedDate);
  };

  const saveallergy = (e) => {
    e.preventDefault();
    formRef.current
      .validateFields()
      .then((formValues) => {
        const { chemicalAllergy, drugAllergy, foodAllergy } = formValues;
        setAddallergy({ ...addallergies, chemicalAllergy, drugAllergy, foodAllergy });

        if (!givenDateShow) {
          message.error("Please select a date");
          return;
        }

        const data = [
          {
            chemicalAllergy: chemicalAllergy || addallergies.chemicalAllergy,
            createdBy: props.patCode,
            createdDate: "",
            drugAllergy: drugAllergy || addallergies.drugAllergy,
            foodAllergy: foodAllergy || addallergies.foodAllergy,
            fromDate: "",
            givenDate: givenDate,
            modifiedBy: props.patCode,
            modifiedDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            patientCode: props.patCode,
            status: 1,
            toDate: "",
          },
        ];

        if (props?.editallergy !== undefined) {
          data[0].id = props?.editallergy?.id;
          dispatch(patientEditallergy(data)).then(() => {
            dispatch(getPatientallergylist(props.patCode));
            props.loadData();
            props.closePopup();
            formRef.current.resetFields();
            message.success("Edited Successfully");
          });
        } else {
          dispatch(patientaddallergy(data)).then(() => {
            dispatch(getPatientallergylist(props.patCode));
            props.loadData();
            formRef.current.resetFields();
            message.success("Added Successfully");
            props.closePopup();
          });
        }
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const disableFutureDates = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <Form
        ref={formRef}
        className="CommonForm"
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Record on"
          name="givenDate"
          rules={[{ required: true, message: "Please select a date" }]}
        >
          <DatePicker
            className="w-full h-[50px]"
            onChange={handleDateChange}
            disabledDate={disableFutureDates}
          />
        </Form.Item>
        <Form.Item
          label="Drug Allergies"
          name="drugAllergy"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  value ||
                  getFieldValue("chemicalAllergy") ||
                  getFieldValue("foodAllergy")
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please fill at least one allergy field")
                );
              },
            }),
          ]}
        >
          <Input placeholder="Enter Drug Allergies" />
        </Form.Item>
        <Form.Item
          label="Food Allergies"
          name="foodAllergy"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  value ||
                  getFieldValue("chemicalAllergy") ||
                  getFieldValue("drugAllergy")
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please fill at least one allergy field")
                );
              },
            }),
          ]}
        >
          <Input placeholder="Enter Food Allergies" />
        </Form.Item>
        <Form.Item
          label="Chemical Allergies"
          name="chemicalAllergy"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  value ||
                  getFieldValue("foodAllergy") ||
                  getFieldValue("drugAllergy")
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please fill at least one allergy field")
                );
              },
            }),
          ]}
        >
          <Input placeholder="Enter Chemical Allergies" />
        </Form.Item>
        <Form.Item>
          <button
            onClick={saveallergy}
            className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center"
            type="primary"
            htmlType="submit"
          >
            Save details
          </button>
        </Form.Item>
      </Form>
    </>
  );
}

export default AddAllergy;
