import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import Slider from "react-slick";
const CurebayResponses = ({data}) =>{

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        prevArrow: <img src={PrevArrow} alt="icons" />,
        nextArrow: <img src={NextArrow} alt="icons" />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    const splitHeading = (sentence) => {
        // Trim sentence and extract words
        const trimmedSentence = sentence.trim();
        const words = trimmedSentence.split(' ');
      
        // Get the last word and the rest of the sentence
        const lastWord = words.pop(); // Removes the last word from the array
        const restOfSentence = words.join(' '); // Joins the remaining words
      
        return (
          <p >
            <span className="text-xl md:text-2xl font-bold text-primaryColor leading-normal mb-3" >{restOfSentence}</span>{' '}
            <span className="text-xl md:text-2xl text-secondaryColor">{lastWord}</span>
          </p>
        );
      };

    return(
        <section className="bg-primaryColor">
        <div className="container mx-auto py-[50px] md:py-[90px] ">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[50px]">
            Healthcare's Call <br />{" "}
            <span className="text-secondaryColor">Our Response</span>
          </h2>
          <Slider {...settings}>
            <div>
              <div className="md:flex w-full relative sm:rounded-l-2xl rounded-r-2xl">
                <div className="md:absolute md:w-[60%] h-full sm:rounded-l-2xl rounded-r-2xl">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/our_eclinic.png"
                    alt="images"
                    className="w-full h-full object-cover sm:rounded-l-2xl rounded-r-2xl"
                  />
                </div>
                <div className="w-full  flex justify-end z-10">
                  <div className="bg-White md:w-[45%] md:min-h-[350px]  px-[30px] py-[30px] md:py-[50px] sm:rounded-r-2xl rounded-b-2xl">
                   {splitHeading(data?.fifthComp[0].title)}
                    <p className="text-textGray text-sm md:text-lg">
                      {data?.fifthComp[0].description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="md:flex w-full relative">
                <div className="md:absolute md:w-[60%] h-full sm:rounded-l-2xl rounded-r-2xl">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Network_healthcare.png"
                    alt="images"
                    className="w-full h-full object-cover sm:rounded-l-2xl rounded-r-2xl"
                  />
                </div>
                <div className="w-full  flex justify-end z-10">
                  <div className="bg-White md:w-[45%] md:min-h-[350px] px-[30px] py-[30px] md:py-[50px] sm:rounded-r-2xl rounded-b-2xl">
                  {splitHeading(data?.fifthComp[1].title)}
                    <p className="text-textGray text-sm md:text-lg ">
                    {data?.fifthComp[1].description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="md:flex w-full relative">
                <div className="md:absolute md:w-[60%] h-full sm:rounded-l-2xl rounded-r-2xl">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Swasthya_Mitra.png"
                    alt="images"
                    className="w-full h-full object-cover sm:rounded-l-2xl rounded-r-2xl"
                  />
                </div>
                <div className="w-full  flex justify-end z-10">
                  <div className="bg-White md:w-[45%] md:min-h-[350px] px-[30px] py-[30px] md:py-[50px] sm:rounded-r-2xl rounded-b-2xl">
                  {splitHeading(data?.fifthComp[2].title)}
                    <p className="text-textGray text-sm md:text-lg " id={window.innerWidth < 768? "allservice" : "normalId"}>
                    {data?.fifthComp[1].description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
          {/* {window.innerWidth > 768 && <div  id="allservice"></div>} */}
        </div>
      </section>
    )
}

export default CurebayResponses