import React from "react";
import { Collapse } from "antd";
import Arrowimage from "../../Assets/Images/Icons/linearrow.png";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const CustomPanelHeader = ({ headerText }) => {
  return (
    <div className="flex gap-4 items-center">
      <img src={Arrowimage} alt="images" className="h-[20px]" />
      <span>{headerText}</span>
    </div>
  );
};
const CustomPanelHeader1 = ({ headerText }) => {
  return (
    <div className="flex gap-4 items-center">
      <img src={Arrowimage} alt="images" className="h-[15px]" />
      <span>{headerText}</span>
    </div>
  );
};
function FAQ() {
  return (
    <Collapse accordion className="faqCollapse" expandIconPosition="end">
      <Panel header={<CustomPanelHeader headerText="General" />} key="1">
        <Collapse
          accordion
          className="faqCollapse faqinner"
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? <CloseOutlined /> : <PlusOutlined />
          }
        >
          <Panel
            header={<CustomPanelHeader1 headerText="Is CureBay a Pharmacy?" />}
            key="1"
          >
            <p className="text-White font-extralight">
              No. CureBay is a platform which works with pharmacies to deliver
              your medicines quickly.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Will my medicine order be confirmed once I upload my prescription?" />
            }
            key="2"
          >
            <p className="text-White font-extralight">
              It takes a little more time. We will validate your prescription
              once you upload it and share with our pharmacy partners to get the
              best possible price with shortest delivery time. We will confirm
              the order once we choose the right pharmacy to deliver to you.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Can anyone see patients’ data?" />
            }
            key="3"
          >
            <p className="text-White font-extralight">
              CureBay has strict policies to protect user data and ensure
              privacy- only the patient and authorised medical professionals can
              see the health data. Medical professionals and partners too will
              have access only to portions of data that is necessary to deliver
              the services requested by patient.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Do you provide online doctor consultation for emergencies?" />
            }
            key="4"
          >
            <p className="text-White font-extralight">
              We have special provisions to provide audio consultations for
              emergencies. Additionally, CureBay offers around-the-clock
              emergency medical service, which you can access by calling on +91
              - 8335 000 999.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="How do I pay for CureBay services?" />
            }
            key="5"
          >
            <p className="text-White font-extralight">
              You have multiple options to pay including Wallet, UPI,
              Debit/Credit card, and Netbanking.
            </p>
          </Panel>
        </Collapse>
      </Panel>
      <Panel header={<CustomPanelHeader headerText="Membership" />} key="2">
        <Collapse
          accordion
          className="faqCollapse faqinner"
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? <CloseOutlined /> : <PlusOutlined />
          }
        >
          <Panel
            header={
              <CustomPanelHeader1 headerText="What does CureBay's Annual Health Membership include?" />
            }
            key="1"
          >
            <p className="text-White font-extralight">
              CureBay's Annual Health Membership offers comprehensive healthcare
              benefits, insurance benefits including wellness services,
              unlimited doctor consultations, discounts on medicines, tests, and
              ambulances.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="How does the membership differ from regular insurance products?" />
            }
            key="2"
          >
            <p className="text-White font-extralight">
              While insurance primarily covers emergencies, CureBay's membership
              focuses on holistic healthcare, offering wellness benefits,
              preventive care, and discounts on healthcare services beyond
              insurance coverage.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Why should I get a CureBay Membership if I already have health insurance?" />
            }
            key="3"
          >
            <p className="text-White font-extralight">
              Absolutely! Getting a CureBay Membership is essential even if
              you're already covered by health insurance. Our Membership Plans
              provide comprehensive healthcare, including wellness benefits,
              preventive care, and discounts on healthcare services. This goes
              beyond what typical insurance covers, helping you manage
              additional expenses like loss of pay and travel costs.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="What are the benefits of the Wellness Membership?" />
            }
            key="4"
          >
            <p className="text-White font-extralight">
              The Wellness Membership includes unlimited quick consultations,
              discounts on specialist consultations, medicines, tests, and
              ambulances. Members can avail these benefits from CureBay's
              eClinics or online at https://www.curebay.com.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="What services are covered under the PrimeCure Membership?" />
            }
            key="5"
          >
            <p className="text-White font-extralight">
              PrimeCure Membership covers all the benefits of the Wellness
              Membership, along with additional benefits such as daily hospital
              cash, coverage for critical illnesses, and personal accidental
              insurance.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="How do I purchase a membership?" />
            }
            key="6"
          >
            <p className="text-White font-extralight">
              Customers can avail membership from CureBay's eClinics or online
              at https://www.curebay.com Simply select the desired membership
              type (Single, Twin, or Family) and complete the registration
              process.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="What extra benefits come with the Wellness Pro Membership?" />
            }
            key="7"
          >
            <p className="text-White font-extralight">
              Wellness Pro Membership includes all the benefits of the Wellness
              Membership, with additional benefits such as daily hospital cash,
              coverage for critical illnesses, and personal accidental
              insurance.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="What is the difference between Single, Twin, and Family Memberships?" />
            }
            key="8"
          >
            <p className="text-White font-extralight">
              Single Membership covers healthcare benefits for one individual,
              while Twin Membership includes benefits for two members. Family
              Membership extends coverage to four members, typically including
              spouses and children.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Are there any additional charges or hidden fees associated with the memberships?" />
            }
            key="9"
          >
            <p className="text-White font-extralight">
              No, there are no additional charges or hidden fees associated with
              the memberships. The membership fee covers all specified benefits
              for the duration of one year.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Are there any additional charges or hidden fees associated with the memberships?" />
            }
            key="10"
          >
            <p className="text-White font-extralight">
              No, there are no additional charges or hidden fees associated with
              the memberships. The membership fee covers all specified benefits
              for the duration of one year.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="How many parameters are covered under Free Full Body Check-up?" />
            }
            key="11"
          >
            <p className="text-White font-extralight">
              34 parameters are covered under Free Full Body Check-up.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="When & how can I avail the Free Full Body Check-up?" />
            }
            key="12"
          >
            <p className="text-White font-extralight">
              You can avail the Free Full Body Check-up within 12 months from
              plan purchase. You can contact your nearest CureBay eClinic or
              call us on our call centre on +91 8335 000 999 for the Free Full
              Body Check-up.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="What are the 9 Critical Illnesses covered under Wellness Pro and PrimeCure Memberships?" />
            }
            key="13"
          >
            <p className="text-White font-extralight">
              The 9 Critical Illnesses covered include major medical conditions
              such as cancer, heart attack, stroke, and kidney failure, among
              others. Members receive financial coverage for treatment expenses
              related to these critical illnesses. For details, please see the
              Membership plan benefits.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Does the Insurance plan under Wellness Pro and PrimeCure offer coverage for pre-existing diseases?" />
            }
            key="14"
          >
            <p className="text-White font-extralight">
              Yes, there is no waiting period for pre-existing diseases under
              the Insurance plan included in Wellness Pro and PrimeCure
              Memberships. Members can avail of coverage for pre-existing
              conditions immediately upon enrolment.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Is there a waiting period for pregnancy coverage under the Insurance plan?" />
            }
            key="15"
          >
            <p className="text-White font-extralight">
              Yes, there is a waiting period of 9 months for pregnancy coverage
              under the Insurance plan included in Wellness Pro and PrimeCure
              Memberships. Members can avail of pregnancy-related benefits after
              the completion of the waiting period.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="What are the benefits of DiabetesCare Membership?" />
            }
            key="16"
          >
            <p className="text-White font-extralight">
              DiabetesCare Membership offers personalized diabetes management
              solutions, including consultations, discounts, and monthly online
              sessions on nutrition and exercise.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="How does CureBay's DiabetesCare plan assist in diabetes management?" />
            }
            key="17"
          >
            <p className="text-White font-extralight">
              CureBay's DiabetesCare plan provides personalized solutions for
              effective diabetes management, including consultations, discounts
              on medicines and tests, and educational resources to empower
              individuals to manage their condition effectively.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Are there any age restrictions for purchasing a membership?" />
            }
            key="18"
          >
            <p className="text-White font-extralight">
              There are no age limitations for obtaining Wellness memberships,
              as they are accessible to individuals of all age groups. However,
              for Wellness Pro and PrimeCure Plans, the minimum age requirement
              is 18 years, while the maximum age limit is 65 years.{" "}
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Can I use my membership at any healthcare facility?" />
            }
            key="19"
          >
            <p className="text-White font-extralight">
              Yes, members can avail of their membership benefits at any CureBay
              healthcare facility, including eClinics and partner hospitals and
              diagnostic centers.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Are the memberships available nationwide?" />
            }
            key="20"
          >
            <p className="text-White font-extralight">
              No, CureBay's Annual Membership plans are presently accessible in
              specific Pin codes covered by CureBay's services. You can verify
              availability on our product check-out page.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="How do I renew my membership?" />
            }
            key="21"
          >
            <p className="text-White font-extralight">
              Members can renew their membership online through CureBay's
              website or by visiting any CureBay eClinic. Renewals are processed
              for another year from the expiration date of the current
              membership.{" "}
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Is there a waiting period after purchasing a membership before I can avail of the benefits?" />
            }
            key="22"
          >
            <p className="text-White font-extralight">
              No, there is no waiting period after purchasing a membership.
              Members can avail of the benefits immediately upon purchase.
              However, 9 months waiting period for pregnancy allies & 90 days
              waiting period to avail hospi-cash benefits under Wellness Pro &
              PrimeCure plans.{" "}
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Can I cancel my membership and receive a refund?" />
            }
            key="23"
          >
            <p className="text-White font-extralight">
              CureBay's Annual Membership plans are non-refundable once
              purchased. However, members can choose not to renew their
              membership upon expiration.{" "}
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="How does CureBay ensure the confidentiality of my personal information?" />
            }
            key="24"
          >
            <p className="text-White font-extralight">
              CureBay adheres to strict privacy policies and regulations to
              ensure the confidentiality and security of members' personal
              information. All data is stored and processed in compliance with
              applicable laws and regulations.{" "}
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Who can I contact if I have further questions or concerns about my membership?" />
            }
            key="25"
          >
            <p className="text-White font-extralight">
              For any further questions or concerns about your membership, you
              can contact CureBay's customer support at +91 8335 000 999 or
              visit any CureBay eClinic for assistance.{" "}
            </p>
          </Panel>
        </Collapse>
      </Panel>
      <Panel
        header={<CustomPanelHeader headerText="Doctor Consultation" />}
        key="3"
      >
        <Collapse
          accordion
          className="faqCollapse faqinner"
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? <CloseOutlined /> : <PlusOutlined />
          }
        >
          <Panel
            header={
              <CustomPanelHeader1 headerText="What is an online consultation in CureBay?" />
            }
            key="1"
          >
            <p className="text-White font-extralight">
              Online consultation involves speaking to a doctor via audio/video/ chat about your health concerns.
              You get a prescription at the end of online consultation which you could use buy medicines or for ordering tests.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="How to choose a doctor for online consultation?" />
            }
            key="2"
          >
            <p className="text-White font-extralight">
              Doctor can be chosen from list of available doctors, by searching
              based on Specialties or searching based on common health concerns.
              Book an appointment with selected doctor for the desired slot and
              join for the video consultation at booked time.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Is the prescription from online consultation valid?" />
            }
            key="3"
          >
            <p className="text-White font-extralight">
              All CureBay doctors are registered in National Medical Register
              and authorised to issue prescriptions. Prescription they generate
              in online consultation is valid and equivalent to one provided
              after a physical visit.
            </p>
          </Panel>
          <Panel
            header={<CustomPanelHeader1 headerText="Who is a specialist?" />}
            key="4"
          >
            <p className="text-White font-extralight">
              Specialists undertake extra training in a specific area of
              medicine after completing their MBBS degree. Specialists can
              better diagnose complex health problems and suggest more
              appropriate treatment options than non-specialists.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Can I show my test reports to the doctor on the video call?" />
            }
            key="5"
          >
            <p className="text-White font-extralight">
              You can share prescriptions/test reports with the doctors during
              an online consultation. All you need to do is to click on the plus
              sign (+) at the bottom left of the screen to send the documents to
              the doctor.
            </p>
          </Panel>
        </Collapse>
      </Panel>
      <Panel header={<CustomPanelHeader headerText="Pharmacy" />} key="4">
        <Collapse
          accordion
          className="faqCollapse faqinner"
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? <CloseOutlined /> : <PlusOutlined />
          }
        >
          <Panel
            header={
              <CustomPanelHeader1 headerText="How do I order medicines from CureBay?" />
            }
            key="1"
          >
            <p className="text-White font-extralight">
              You can order medicines quickly & easily from CureBay’s online
              Medical Store or from our eClinics& CureBay offline
              pharmacies(CureBay MediShops).
            </p>
            <p className="text-White font-extralight">
              To order medicines online, simply navigate to the ‘Order
              Medicines’ section on the CureBay website, search for the desired
              product, add it to your cart, and proceed to checkout. Once your
              order is confirmed, we'll prepare it for home delivery.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Are the medicines delivered by CureBay genuine and of high quality?" />
            }
            key="2"
          >
            <p className="text-White font-extralight">
              Absolutely! All medicines ordered through CureBay are authentic
              and of excellent quality. We uphold strict standards to ensure the
              authenticity and quality of every medicine and product sold on our
              platform. You can trust that the medicines you receive from
              CureBay meet the highest quality standards.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="How will I know if there is a delay in delivery of my order?" />
            }
            key="3"
          >
            <p className="text-White font-extralight">
              If your order is delayed for any reason, our team will notify you
              via SMS, call, or email. You can also track the status of your
              order on the CureBay website by clicking on the ‘Order Status’
              button.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Does CureBay offer express delivery for online medicine orders?" />
            }
            key="4"
          >
            <p className="text-White font-extralight">
              Yes, depending on your location. In select cities, CureBay
              provides express delivery, with orders delivered in as little as 3
              hours. Please note that express delivery availability may vary
              based on factors such as medicine availability and staff
              availability.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="How can I track the delivery status of my order?" />
            }
            key="5"
          >
            <p className="text-White font-extralight">
              To track your order, log in to your CureBay account and navigate
              to the ‘My Orders’ section under ‘My Account.’ Select the specific
              order to view its current status and expected delivery time.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Does CureBay provide home delivery of medicines?" />
            }
            key="6"
          >
            <p className="text-White font-extralight">
              Yes, CureBay provides fast and reliable home delivery of medicines
              to your doorstep for your convenience.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Does CureBay deliver medicines nationwide?" />
            }
            key="6"
          >
            <p className="text-White font-extralight">
              No, CureBay's presently delivers medicines in specific Pin codes
              covered by CureBay's services. You can verify availability on our
              product check-out page.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Does CureBay offer same-day delivery for online medicine orders?" />
            }
            key="6"
          >
            <p className="text-White font-extralight">
              Yes, CureBay provides same-day delivery for online medicine
              orders, ensuring prompt access to essential healthcare products
              when you need them most.
            </p>
          </Panel>
        </Collapse>
      </Panel>
      <Panel header={<CustomPanelHeader headerText="Diagnostics" />} key="5">
        <Collapse
          accordion
          className="faqCollapse faqinner"
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? <CloseOutlined /> : <PlusOutlined />
          }
        >
          <Panel
            header={
              <CustomPanelHeader1 headerText="Can I get a diagnostic test done at home?" />
            }
            key="1"
          >
            <p className="text-White font-extralight">
              You can get sample collected at home for tests where it is not
              mandatory to visit the lab. A health worker will visit your
              location to collect the sample and deliver to chosen labs to
              complete the test. You will have to visit the lab for radiology
              tests since it is not allowed to be conducted at home.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Should I not eat before taking the test?" />
            }
            key="2"
          >
            <p className="text-White font-extralight">
              Our support team will notify you about the tests that need
              fasting.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Will you deliver my reports?" />
            }
            key="3"
          >
            <p className="text-White font-extralight">
              You can access the test results from the “Health Records” section
              on the CureBay app within 24-48 hours in most cases. All you need
              to do is to sign in to your account and check the “Health Records”
              tab . Please note that some of your test results may take longer
              than 48 hours depending on the time taken to complete the test. We
              will deliver the reports to your address, where it is not possible
              to get a digital copy.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Can I see a doctor after getting my reports?" />
            }
            key="4"
          >
            <p className="text-White font-extralight">
              Yes. Your doctor can see the test results under “Health Records”
              section if you are consulting doctor in CureBay platform.
              Alternatively you can download the reports from “Health Records”
              section and share with doctor too.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Do you accept credit cards for payment?" />
            }
            key="5"
          >
            <p className="text-White font-extralight">
              You have multiple options to pay including Wallet, UPI,
              Debit/Credit card, and Net Banking.
            </p>
          </Panel>
        </Collapse>
      </Panel>
      <Panel
        header={<CustomPanelHeader headerText="Hospitals & Surgery" />}
        key="6"
      >
        <Collapse
          accordion
          className="faqCollapse faqinner"
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? <CloseOutlined /> : <PlusOutlined />
          }
        >
          <Panel
            header={
              <CustomPanelHeader1 headerText="What information do I need to provide to enquire about treatment at CureBay’s partner hospitals?" />
            }
            key="1"
          >
            <p className="text-White font-extralight">
              We will request you to provide as much information as possible
              about your condition including medical history, past scans and
              reports, current medication etc. We will also request you to bring
              details of doctor who has referred you.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Who will guide me about the cost and duration of stay at the hospital?" />
            }
            key="2"
          >
            <p className="text-White font-extralight">
              Once you submit the enquiry form with all details, our support
              team will get back to you with a selection of hospitals you can
              get the treatment along with cost, time line and care plan before
              and after treatment.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="How do I make payments for my hospitalization?" />
            }
            key="3"
          >
            <p className="text-White font-extralight">
              You have multiple options to pay including Wallet., UPI,
              Debit/Credit card, and Netbanking. Our support team will advise
              you payment details once treatment plan is agreed.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Is my booking confirmed once I pay at CureBay?" />
            }
            key="4"
          >
            <p className="text-White font-extralight">
              Our support team will guide you at each step and they will confirm
              the appointment as soon as payment is done.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Will CureBay help to avail cashless treatments?" />
            }
            key="5"
          >
            <p className="text-White font-extralight">
            We will talk to the chosen hospital regarding your insurance scheme and will try and facilitate cashless treatment.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="What is online surgery appointment?" />
            }
            key="6"
          >
            <p className="text-White font-extralight">
              Online surgery appointment is the process of booking a surgery
              appointment online. To book an appointment, you must provide
              personal information, mention illness and select a date and time
              for the surgery.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Is it safe to book surgery online?" />
            }
            key="7"
          >
            <p className="text-White font-extralight">
              Yes, online surgery booking with CureBay is safe and secure.
              CureBay is a reputed healthcare platform and follows best
              practices for online security and privacy.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="How to book surgery online with CureBay?" />
            }
            key="8"
          >
            <p className="text-White font-extralight">
              You simply need to fill up personal details to book an appointment
              after which a care coordinator will get in touch with you. Then a
              thorough evaluation will be done by our doctor and your surgery
              will be scheduled at the earliest possible date.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="Can all types of surgeries be booked online?" />
            }
            key="9"
          >
            <p className="text-White font-extralight">
              Not all types of surgeries can be booked online, as some may
              require in-person consultations or evaluations before scheduling
              the surgery. However, most minor surgeries can be booked online
              with CureBay.
            </p>
          </Panel>
          <Panel
            header={
              <CustomPanelHeader1 headerText="What should I do if I have questions or concerns about my surgery booking?" />
            }
            key="10"
          >
            <p className="text-White font-extralight">
              Our 24*7 hospital inquiry team is available to answer any
              questions or concerns about the surgery.
            </p>
          </Panel>
        </Collapse>
      </Panel>
    </Collapse>
  );
}

export default FAQ;
