import React from "react";
import { Form, Input, InputNumber, Select } from "antd";

function AddFamilyMember() {
  return (
    <div>
      <Form
        layout="vertical"
        autoComplete="off"
        className="CommonForm grid md:grid-cols-2 md:gap-5"
      >
        <Form.Item className="mb-0" name="name" label="Salutation">
          <Input placeholder="Please enter your name" />
        </Form.Item>
        <Form.Item className="mb-0" name="name" label="First Name">
          <Input placeholder="Please enter your first name" />
        </Form.Item>
        <Form.Item className="mb-0" name="name" label="Last Name">
          <Input placeholder="Please enter your last name" />
        </Form.Item>
        <Form.Item className="mb-0" name="state" label="Relation">
          <Select
            placeholder="Please select relationship with member"
            optionFilterProp="children"
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item className="mb-0" name="state" label="Gender">
          <Select
            placeholder="Select Gender"
            optionFilterProp="children"
            options={[
              {
                value: "male",
                label: "Male",
              },
              {
                value: "female",
                label: "Female",
              },
              {
                value: "other",
                label: "Other",
              },
            ]}
          />
        </Form.Item>
        <Form.Item className="mb-0" name="email" label="DOB">
          <InputNumber placeholder="Please enter your date of birth" />
        </Form.Item>
        <Form.Item className="mb-0" name="state" label="Blood Group">
          <Select
            placeholder="Please select your blood group"
            optionFilterProp="children"
            options={[
              {
                value: "male",
                label: "Male",
              },
              {
                value: "female",
                label: "Female",
              },
              {
                value: "other",
                label: "Other",
              },
            ]}
          />
        </Form.Item>
        <Form.Item className="mb-0" name="email" label="Phone Number">
          <InputNumber placeholder="Please enter your number" />
        </Form.Item>
        <Form.Item
          className="mb-0"
          name="email"
          label="Aadhar Id (last 4 digit)"
        >
          <InputNumber placeholder="Please enter your number" />
        </Form.Item>
        <Form.Item
          className="mb-0"
          name="email"
          label="House Number / Street Name"
        >
          <Input placeholder="Please enter address line 1" />
        </Form.Item>
        <Form.Item className="mb-0" name="email" label="Address">
          <Input placeholder="Please enter address line 2" />
        </Form.Item>
        <Form.Item className="mb-0" name="state" label="State">
          <Select
            placeholder="Select your state"
            optionFilterProp="children"
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item className="mb-0" name="name" label="City">
          <Input placeholder="Please enter your city" />
        </Form.Item>
        <Form.Item className="mb-0" name="name" label="PIN Code">
          <Input placeholder="Please enter your pincode" />
        </Form.Item>
      </Form>
    </div>
  );
}

export default AddFamilyMember;
