import { useHistory } from "react-router-dom";

const TotalCareSection = ({data}) =>{
    const history = useHistory()
    const handleNavigation = (path) => {
        history.push(path);
        window.scrollTo(0, 0);
      };
    return(
        <section className="bg-White">
          <div className="container mx-auto py-[50px] md:py-[90px] relative">
            <div className="md:flex w-full gap-5">
              <div className="bg-lightGreen md:w-[60%] p-5 md:p-10 rounded-xl">
                <h6 className="text-secondaryColor text-base md:text-xl">
                  CureBay Totalcare
                </h6>
                <h2 className="text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-5">
                  Take care from anywhere
                </h2>
                <p className="text-sm md:text-lg text-textGray">
                  {data?.seventh[0]?.description}
                </p>
                <button
                  onClick={() => handleNavigation("/services/totalcare")}
                  className="hover:bg-primaryColor bg-secondaryColor text-White w-[130px] h-10 rounded-md mt-10 hidden md:block"
                >
                  Know more
                </button>
                <button
                  onClick={() => handleNavigation("/services/totalcare")}
                  className="hover:bg-primaryColor bg-secondaryColor text-White w-[130px] h-8 text-sm rounded-md mt-10 block md:hidden"
                >
                  Know more
                </button>
              </div>
              <div className="md:w-[40%]">
                <img
                  src={data?.seventh[0]?.image_url}
                  alt="images"
                  className="image-boxshadow "
                />
              </div>
            </div>
          </div>
        </section>
    )
}

export default TotalCareSection