import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const Lipoma = ({data}) =>{
    console.log(data, "sdvkjdsiuvdsbgvdsvds")
    if(!data?.sixthComp?.points?.length){
        return null
    }
    return(
        <>
          <div className=" mb-4 md:my-20" >
                <h2 className="text-center" style={{color:"#004171", fontSize: 36, fontWeight: 600}} >{data?.sixthComp?.points[0].mainTitle}</h2>
            <div className="grid md:grid-cols-2 px-2 md:px-12" >
            {
                data?.sixthComp?.points.map((res,index) => (
                    <div className="p-8" style={{borderBottom: "dashed"}} >
                        <div className="flex items-start relative p-4" > 
                            <img className="absolute top-4" src= {CheckIcon} height={20} width={20} />
                    <p className="pl-8 text-xl md:text-2xl font-semibold" style={{color:"#005D8D"}} >{res.title}</p>
                        </div>
                        <p className="pl-8 text-base" >{res.description}</p>
                    </div>
                ))
            }
                </div>
                {data?.eight?.points?.length ? (
        <div className="bg-primaryColor text-white py-10 px-4 md:px-16 mb-10">
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row md:items-center">
              <div className="md:w-1/2 mb-6 md:mb-0">
                <h2 className="text-xl md:text-2xl font-semibold mb-4 text-White">
                  {data?.eight.points[0]?.title}
                </h2>
              </div>

              <div className="md:w-1/2">
                <p className="text-base md:text-lg text-White mb-8">
                {data?.eight.points[0]?.description}
                </p>
                {data.eight.points[0].sub_points?.length &&
                  <ul style={{listStyleType: "disc"}} >
                  {data.eight.points[0].sub_points.map(res =>{
                    return <li className="text-base font-medium" style={{color:"#ffff"}}>{res?.title} {res.description}</li>
                  })}
                </ul>
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}
                <div className="grid md:grid-cols-2 px-4 md:px-8 justify-between items-start my-20">
            <div className="text-xl md:text-2xl font-semibold md:px-12" style={{color: "#004171"}} >
                {data?.seventhComp?.title}
            </div>
            <div  >
            {data?.seventhComp?.description}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-8" >
            {
                data?.seventhComp?.points.map(res => (
                    <div className="p-4" style={{backgroundColor:"#EBF8FF", borderRadius: 8}}>
                        <img src= {res.icon_url} />
                    <p style={{color:"#004171", fontSize: 12, fontWeight: 400}} >{res.title}</p>
                    </div>
                ))
            }
            </div>
                </div>
        </div>
       
               {
                data.ninthComp?.points?.length ? <div className="py-12" >
                    <h4 style={{color:"#004171" , fontSize: 36, fontWeight: 600}} className="text-center mb-4" >{data.ninthComp.title}</h4>
                    <div className="grid md:grid-cols-2" >
                    {
                        data.ninthComp?.points.map((res , index) =>{
                            return( <div className="p-8"> 
                             <div className="flex items-start relative" > 
                            <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                            <p className="text-base" style={{color:"#676C80"}} >{res.description}</p>
                        </div>
                            </div>
                   
                            )
                        })
                    }
                    </div>
                    <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.ninth?.points[0].mainnotes}</h4>
                </div> : null
              }
              </div>
              </>
    )
}

export default Lipoma