import React, { useState, useEffect } from "react";
import PrevBrowsedMedicine from "./PrevBrowsedMedicine";
import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
// import delet from "../Assets/Images/delete.svg";
// import minus from "../Assets/Images/minus.svg";
// import plus from "../Assets/Images/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
// import { APP_ROUTES } from "../../application/Router/constants/AppRoutes";
import SectionContainer from "./SectionContainer";
import CartPrice from "./cartPrice";
import LabCart from "./LabList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog } from "primereact/dialog";
import FamilyDropdownCart from "./cartModels/FamilyDropdownCart";
// import "primeicons/primeicons.css";
import {
  patientaddresslists,
  getPatientDetails,
} from "../../../Redux/Action/UserprofileActions";
import moment from "moment";
import http from "../../../Redux/Services/http-common";
import {
  AddtoCart,
  getCartDetails,
} from "../../../Redux/Action/cartPlaceOrderAction";
import EmptyCart from "./EmptyCart";
// import { useCookies } from "react-cookie";
// import Loader from "../../components/Loader.jsx";
import MedicineList from "./medicineList";
import PreferedDateAndTime from "./PreferedDateAndTime";
import CartTimeLine from "./CartTimeLine";
import { getMedicarePurchaseData } from "./medicareToB2c";
import { getAllProductByIds } from "../../../Redux/Action/pharmacyAction";
import Layouts from "./../../Layouts";
import { Checkbox } from "antd";
import RXicon from "../../../Assets/Images/Cart/rxicon.png";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Loader from "../../Shared/loader";
import axios from "axios";
import _ from "lodash";
import { CaretRightOutlined , EditOutlined } from "@ant-design/icons";
import useDetectDevice from "../../utils/detectDeviceType";
import PreviouslyBoughtMedicine from "./PreviouslyBoughtMedicine";
import LabTestList from "./LabTestList";
function MedicineCart() {
  const familymemberinfo = useSelector((state) => state.familymembers);
  const search_params = useLocation().search;
  const purchaseToken = new URLSearchParams(search_params).get(
    "purchase_token"
  );
  const purchaseToken_source = new URLSearchParams(search_params).get("source");
  // const [cookies, setCookie] = useCookies(["__gtm_campaign_url"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openDialog, setDialog] = useState(false);
  const location = useLocation();
  const [isFirstMount, setIsFirstMount] = useState(true);
  const userData = useSelector((state) => state.authReducer.patientData);
  const cartList = useSelector((state) => state.cartReducer.cartList);
  // const isLoading = cartList?.patientLabTestsOrder;
  const patientLabTestsOrder = cartList?.patientLabTestsOrder;
  const patientMedicineOrder = cartList?.patientMedicineOrder;
  const [patient, setPatient] = useState(userData);
  const deviceType = useDetectDevice()
  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    }else {
      window.scrollTo(0, 0);
    }
  };
  const [openDateAndTimeDrawer, setOpenDateAndTimeDrawer] = useState(false)
  const [isMed, setMedOpen] = useState(true);
  const [isDia, setDiaOpen] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [emptycart, setemptycart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPreferredDate, setSelectedPreferredDate] = useState();
  const [selectedHomeSamplePreferredDate, setSelectedHomeSamplePreferredDate] =
    useState();
  const [isCouponApply, setCouponApply] = useState(false);

  const [preferDate, setPreferDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [preferTime, setPreferTime] = useState("");
  const [showaddresspopup, setshowaddresspopup] = useState(false);

  /////////memebership///

  const loadPaymentFor = (patient) => {
    console.log(patient, "patientdata");
    let data = cartList;
    if (data.patientMedicineOrder?.length) {
      data.patientMedicineOrder[0].membershipId = 0;
      if (!data.patientMedicineOrder[0].voucheryCode) {
        data.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(
          (res) => {
            res.discountAmount = 0;
            res.totalAmount = 0;
          }
        );
      }
    }
    if (data.patientLabTestsOrder?.length) {
      data.patientLabTestsOrder[0].membershipId = 0;
      if (!data.patientLabTestsOrder[0].voucherCode) {
        data.patientLabTestsOrder[0].patientLabTestsOrderDetailsList.map(
          (res) => {
            res.discountAmount = 0;
            res.totalAmount = 0;
          }
        );
      }
    }

    dispatch(AddtoCart(data)).then(() => {
      dispatch(getCartDetails(userData.code))
        .then((res) => {
          // setLabMembershipError({
          //   loading: false,
          //   error: ""
          // })
        })
        .catch((err) => {
          // setLabMembershipError({
          //   loading: false,
          //   error: err?.response?.data?.message
          // })
        });
    });
    let params = {
      code: patient,
    };
    localStorage.setItem("patient", JSON.stringify(params));
    setPatient(params);
  };

  useEffect(() => {
    dispatch(getPatientDetails(userData?.code));
    // window.location.reload();
  }, [showaddresspopup]);

  useEffect(() => {
    if (userData?.code){
      getCartData();
    } 
  }, [userData?.code]);

  useEffect(() => {
    console.log(JSON.stringify(localStorage.getItem("preferTime")));
    if (JSON.stringify(localStorage.getItem("preferTime"))) {
      setPreferTime(JSON.stringify(localStorage.getItem("preferTime")));
    }
  }, []);

  useEffect(() => {
    if (purchaseToken) {
      computeMedicareToken();
    }
    console.log(purchaseToken_source, "Sdvdsugviudsgiuvsgivudsgvsd");
  }, [purchaseToken, purchaseToken_source]);

  // useEffect(() => {
  //   // If it's not the first mount and there are search parameters
  //   // if (!isFirstMount && location.search) {
  //   //   history.replace('/cart');
  //   // }
  //   // // Set the first mount to false after the first render
  //   // setIsFirstMount(false);

  // }, [location, history, isFirstMount]);

  const computeMedicareToken = async () => {
    await getMedicarePurchaseData(purchaseToken, dispatch, cartList, "med");
    history.replace("/cart");
  };

  const onChangeMed = () => {
    setMedOpen(!isMed);
  };

  const onChangeDia = () => {
    setDiaOpen(!isDia);
  };

  const setPreferredDate = (id, date) => {
    // date = moment(date).format("yyyy-MM-DD HH:mm:ss");
    // setSelectedPreferredDate(date);
    // patientLabTestsOrder.forEach((element) => {
    //   if (element.id == id) {
    //     element.preferDateTime = moment(date).format("yyyy-MM-DD HH:mm:ss");
    //   }
    // });
  };

  const setPreferredLabDate = (id, date) => {
    // date = moment(date).format("yyyy-MM-DD HH:mm:ss");
    // setSelectedPreferredDate(date);
    // patientLabTestsOrder.forEach((element) => {
    //   if (element.id == id) {
    //     element.preferDateTime = moment(date).format("yyyy-MM-DD HH:mm:ss");
    //   }
    // });
  };

  /**
   * Delete Test/Medicine from Cart
   */

  const deleteCartItem = async (e, res, { medicineId = -1, testId = -1 }) => {
    console.log(medicineId,res.id, "dsivhsoduvsdouv", testId);
    // setTotalDiscountAmount(0);

    e.preventDefault();
    let cart = { ...cartList };
    let patientDrugOrder = cart.patientMedicineOrder?.map((orderData) => {
      orderData.patientMedicineOrderDetailsList.map((medicineData) => {
        if (medicineData.id == medicineId) {
          medicineData.status = 0;
        }
        //return medicine;
      });
      orderData.txnId = "";
      return orderData;
    });
    cart.patientMedicineOrder = patientDrugOrder;

    if (cart && cart.patientMedicineOrder) {
      cart.patientMedicineOrder.forEach((element) => {
        if (element.patientMedicineOrderDetailsList) {
          let pharmaadded = false;
          element.patientMedicineOrderDetailsList.forEach((element2) => {
            if (element2.status == 1) {
              pharmaadded = true;
            }
          });
          if (pharmaadded) {
            element.status = 1;
          } else {
            element.status = 0;
          }
        }
      });
    }

    let patientTestOrder = cart.patientLabTestsOrder?.map((orderData) => {
      console.log("Test Id : " + testId);
      orderData.patientLabTestsOrderDetailsList.map((labTestData) => {
        console.log("Lab Test Id : " + labTestData.id);
        if (labTestData.id == testId) {
          console.log("Deleted Item : " + labTestData);
          labTestData.status = 0;
        }
      });
      /*let order = orderData;
      if (order.id === testId) {
        order.status = 0;
      }*/
      orderData.txnId = "";
      return orderData;
    });
    cart.patientLabTestsOrder = patientTestOrder;

    if (cart && cart.patientLabTestsOrder) {
      cart.patientLabTestsOrder.forEach((element) => {
        if (element.patientLabTestsOrderDetailsList) {
          let diaadded = false;
          element.patientLabTestsOrderDetailsList.forEach((element2) => {
            if (element2.status == 1) {
              diaadded = true;
            }
          });
          if (diaadded) {
            element.status = 1;
          } else {
            element.status = 0;
          }
        }
      });
    }

    let open = false;
    if (cart && cart.patientLabTestsOrder) {
      cart.patientLabTestsOrder.forEach((order) => {
        order.patientLabTestsOrderDetailsList.forEach((tests) => {
          if (tests.status == 1) {
            open = true;
          }
        });
      });
    }
    if (cart && cart.patientMedicineOrder) {
      cart.patientMedicineOrder.forEach((order) => {
        order.patientMedicineOrderDetailsList.forEach((medicines) => {
          if (medicines.status == 1) {
            open = true;
          }
        });
      });
    }
    if (!open) {
      cart.status = 0;
      setemptycart(true);
    }
    console.log(JSON.stringify(cart), "updated cart Items");
    if (testId != -1) {
      cart.totalAmount -= parseFloat(res.amount);
    } else if (medicineId != -1) {
      cart.totalAmount -= parseFloat(res.unitPrice);
    }

    setIsLoading(true);

    if (medicineId != -1) {
      try {
          await axios.get(`${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?status=0&Id=${res.id}`);
          console.log(`Updated item with ID ${res.id}`);
          
          dispatch(getCartDetails(userData.code));
      } catch (error) {
          console.error(`Failed to update item with ID ${res.id}:`, error);
      }
  }
  if (testId != -1) {
    // dispatch(AddtoCart(cart)).then(() => {
      try {
        await axios.delete(`${process.env.REACT_APP_BASEURL}cart/deleteLabtest?Id=${res.id}`);
        console.log(`Updated item with ID ${res.id}`);
       
        dispatch(getCartDetails(userData.code));
    } catch (error) {
        console.error(`Failed to update item with ID ${res.id}:`, error);
    }
     
    
  }
    setIsLoading(false);
  };
  

  /**
   * Update Quantity
   */
  // const quantityUpdate = async (e, res, quantity) => {
  //   console.log(res, "dsvsduvugsd", e.target.name);
  //   e.preventDefault();

  //   let cart = { ...cartList };

  //   const patientDrugOrder = cart.patientMedicineOrder?.map((orderData) => {
  //     orderData.patientMedicineOrderDetailsList.map((medicineData) => {
  //       if (medicineData.id == res.id) {
  //         medicineData.quantity = quantity;
  //         if (medicineData.voucherCode == null) {
  //           medicineData.discountAmount = 0;
  //           medicineData.totalAmount = 0;
  //         }

  //         // let realAmount = medicineData?.discountAmount
  //         //   ? medicineData?.discountAmount
  //         //   : medicineData.unitPrice;
  //         // medicineData.totalAmount = realAmount * quantity;
  //         // sdfsdfsdf33222,..
  //       }
  //       // }
  //     });
  //     return orderData;
  //   });
  //   cart.patientMedicineOrder = patientDrugOrder;
  //   let patientTestsOrder = cart.patientLabTestsOrder?.map((orderData) => {
  //     let order = orderData;
  //     order.txnId = "";
  //     return order;
  //   });
  //   cart.patientLabTestsOrder = patientTestsOrder;
  //   console.log(cart,"gvggbhhbhb")

  //   try {
  //     await axios.get(
  //       `${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?quantity=${res.quantity}&Id=${res.id}`
  //     );
  //     console.log(`Updated item ${res.id} with quantity ${quantity}`);
  //     getCartData();
  //   } catch (error) {
  //     console.error(`Failed to update item ${res.id}:`, error);
  //   }
  // };
  const [loading, setLoading] = useState(false);

  const debouncedQuantityUpdate = _.debounce((e, res, quantity) => {
    quantityUpdate(e, res, quantity);
  }, 500);

  const quantityUpdate = async (e, res, quantity) => {
    e.preventDefault();
    if (loading) return; // Prevent running if already loading

    setLoading(true); // Set loading to true when the update starts

    let cart = { ...cartList };

    const patientDrugOrder = cart.patientMedicineOrder?.map((orderData) => {
      orderData.patientMedicineOrderDetailsList.map((medicineData) => {
        if (medicineData.id === res.id) {
          medicineData.quantity = quantity;
          if (medicineData.voucherCode == null) {
            medicineData.discountAmount = 0;
            medicineData.totalAmount = 0;
          }
        }
      });
      return orderData;
    });

    cart.patientMedicineOrder = patientDrugOrder;

    let patientTestsOrder = cart.patientLabTestsOrder?.map((orderData) => {
      let order = orderData;
      order.txnId = "";
      return order;
    });
    
    cart.patientLabTestsOrder = patientTestsOrder;

    try {
      await axios.get(
        `${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?quantity=${res.quantity}&Id=${res.id}`
      );
      dispatch(getCartDetails(userData.code)); // Fetch updated cart data
    } catch (error) {
      console.error(`Failed to update item ${res.id}:`, error);
    } finally {
      setLoading(false); // Reset loading after API call
    }
  };

  /**
   * Payment API Code
   */

  //#region Payment Process Start

  if (!isLoading && !cartList?.cartId) {
    localStorage.removeItem("couponCode");
    return (
      <>
        <EmptyCart />
      </>
    );
  }

  const changeDate = (date) => {
    setPreferDate(moment(date, 'DD-MM-YYYY').format("YYYY-MM-DD"));

    date = moment(date, 'DD-MM-YYYY').format("YYYY-MM-DD HH:mm:ss");
    setSelectedPreferredDate(date);
    // patientLabTestsOrder.forEach((element) => {
    //     element.preferDateTime = moment(date).format("yyyy-MM-DD HH:mm:ss");
    // });
  };

  const handleChange = (e) => {
    let value = e?.target?.value;
    setPreferTime(value);

    let time = convertTime(value.substring(0, 5));
    localStorage.setItem("preferTime", JSON.stringify(time));
    localStorage.setItem("preferValue", JSON.stringify(value));
    localStorage.setItem("preferDate", JSON.stringify(preferDate));
  };

  const limitTimeForLab = (timeParam) => {
    console.log(timeParam, "sdpfdibdhpisdhpb");
    if (moment(preferDate).isSame(moment().format("YYYY-MM-DD"))) {
      let current_time_in_hour = moment().hour();
    
      let am_or_pm = timeParam.value.substring(0, 5).split(" ")[1];
      let time_in_hours = Number(timeParam.value.substring(0, 5).split(" ")[0]);
      if (am_or_pm == "PM" && time_in_hours != 12) {
        time_in_hours += 12;
      }
      console.log("current_time_in_hour:" , current_time_in_hour + 4 , "time_in_hours: ", time_in_hours)
      if (current_time_in_hour + 4 > time_in_hours) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const convertTime = (timeStr) => {
    const [time, modifier] = timeStr.split(" ");
    let [hours] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}`;
  };

  async function checkConsent() {
    let response = await http.get(
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/list/filter?patientId=${userData.code}&status=1`
    );
    console.log(response, "dsvldsbvsdbvoubdsv");
    if (response.data.data.length) {
      if (
        response.data.data[0].privacyPolicyConsent == 0 ||
        response.data.data[0].termsAndConditionConsent == 0
      ) {
        return false;
      }
    }
    if (response.data.data.length == 0) {
      return false;
    }
    return true;
  }

  const goToCheckoutPage = async (flag) => {
   
    let permission = await checkConsent();
    if (!permission) {
      toast("Please accept terms and condition first. Check in my details");
      return
    }
    // if (cartList?.patientLabTestsOrder?.length) {
    //   if (!JSON.parse(localStorage.getItem("preferTime"))) {
    //     toast("Please select preferred date and time.");
    //     return;
    //   } else if (!JSON.parse(localStorage.getItem("preferValue"))) {
    //     toast("Please select preferred date and time.");
    //     return;
    //   } else if (!JSON.parse(localStorage.getItem("preferDate"))) {
    //     toast("Please select preferred date and time.");
    //     return;
    //   }
    // }
    localStorage.setItem("selectpatientdata", patient.code);
    console.log(patient, "patientname");
    if (flag == "withoutcoupon") {
      history.push("/cart/checkout");
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        window.scrollTo(0, 0);
      }else {
        window.scrollTo(0, 0);
      }
      // history.push({
      //   pathname: '/cart/checkout',
      //   state:{ patient:patient}

      // });

      console.log(patient.code, "patientname");
    }
    let medicineMembership =
      cartList?.patientMedicineOrder ?? [0]?.membershipId;
    let labMembership = cartList?.patientLabTestsOrder ?? [0]?.membershipId;
    let medicineMembershipApplied =
      medicineMembership == 0 ||
        medicineMembership == "0" ||
        medicineMembership == null
        ? 0
        : 1;
    let labMembershipApplied =
      labMembership == 0 || labMembership == "0" || labMembership == null
        ? 0
        : 1;
    if (!isCouponApply && !medicineMembershipApplied && !labMembershipApplied) {
      setDialog(true);
      return;
    }
    history.push("/cart/checkout");
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    }else {
      window.scrollTo(0, 0);
    }
    // history.push({
    //   pathname: '/cart/checkout',
    //   state:{ patient:patient}

    // });
    console.log(patient.code, "patientname");
  };

  function getCartData() {
    dispatch(getCartDetails(userData.code));
  }

  const getMedicineDiscountPrice = () =>{
    let result = parseFloat(cartList.patientMedicineOrder[0].amount) - parseFloat(cartList.patientMedicineOrder[0].totalAmount)
    return result.toFixed(2)
  }

  const getLabDiscountPrice = () =>{
    let result = parseFloat(cartList.patientLabTestsOrder[0].totalAmount) - parseFloat(cartList.patientLabTestsOrder[0].paidAmount)
    return result.toFixed(2)
  }

  const toggleDrawer = (newOpen) => () => {
    setOpenDateAndTimeDrawer(newOpen);
  };

  return (
    <>
      {/* breadcrumbs */}
      <Layouts>
        <ToastContainer />
        <section className="bg-lightGray md:py-8 py-6 md:px-28">
          {/* {showLoader && <Loader />} */}
          <div className="flex justify-center mb-5">
            <CartTimeLine />
          </div>
          <div className="md:flex gap-5">
            <div className="md:w-[65%] bg-White md:min-h-[60vh]  h-full p-3 md:p-8 rounded-lg">
              <SectionContainer
                link={""}
                // title={"Items in your cart"}
                subtitle={""}
                seeAll={"hide"}
              />
              {isDia && (
                  <div className="flex justify-between  items-center">
                    <span className="text-lg font-bold  text-primaryColor text-md">
                      Placing order for
                    </span>

                    <FamilyDropdownCart title={""}
                      onSelect={loadPaymentFor}
                    />
                  </div>
              )}
              <div className="mt-5 border border-gray  rounded-lg p-3 md:p-5" style={{border: deviceType == "Mobile" ? "0.5px solid #E8E8E8" : ""}} >
                <div className="hidden md:flex justify-between">
                  <div className="" >
                    <p className=" text-base font-semibold  text-primaryColor">
                      MEDICINES IN YOUR CART{" "}
                      {/* {isMed && patientMedicineOrder?.length &&
                        <span className="text-secondaryColor">({patientMedicineOrder?.length + 1})</span>} */}
                    </p>
                    <p className="font-medium text-xs text-textGray mt-1 flex">
                      ( Item(s) marked{" "}
                      <img src={RXicon} className=" h-4 mx-1" /> need a
                      prescription )
                    </p>
                  </div>
                  {isMed ? (
                    <MinusCircleOutlined
                      onClick={onChangeMed}
                      className="text-2xl text-textGray"
                    />
                  ) : (
                    <PlusCircleOutlined
                      onClick={onChangeMed}
                      className="text-2xl text-textGray"
                    />
                  )}
                </div>
                {isMed && patientMedicineOrder?.length ? (
                  <MedicineList
                    patientMedicineOrder={patientMedicineOrder}
                    deleteMedicine={deleteCartItem}
                    quantityUpdate={quantityUpdate}
                    loading={loading}
                    debouncedQuantityUpdate={debouncedQuantityUpdate}
                  />
                ) : null}
              </div>
              {cartList?.patientMedicineOrder && cartList?.patientMedicineOrder?.length && cartList?.patientMedicineOrder[0]?.membershipId !== '0' ? <div className="block md:hidden px-4 py-3 mt-4" style={{background: "radial-gradient(64.4% 108.13% at 50% 50%, #007ADB 0%, #004175 100%)", borderRadius: 8}} >
                <p className="text-xs font-medium" style={{color: "#ffff"}} >Yayy 🎉 !! You get an extra ₹{getMedicineDiscountPrice()} discount on your medicines with CureBay Membership Plan.</p>
              </div> : null}
              { cartList?.patientLabTestsOrder && cartList?.patientLabTestsOrder?.length  ? <div className="mt-5 border border-gray  rounded-lg p-3 md:p-5" style={{border: deviceType == "Mobile" ? "0.5px solid #E8E8E8" : ""}}>
                <div className="hidden md:flex justify-between">
                  <div>
                    <p className=" text-base font-semibold mb-5 text-primaryColor">
                      LAB TEST{" "}
                    </p>
                  </div>
                </div>
                {!isDia && (
                  <div className="my-5 mx-3 flex justify-between">
                    <p className="font-medium fonr-rubik text-gray-primary text-md ">
                      Diagnostics Prescribed
                    </p>
                    {isDia ? (
                      <MinusIcon onClick={onChangeDia} className="w-6 h-6" />
                    ) : (
                      <PlusIcon onClick={onChangeDia} className="w-6 h-6" />
                    )}
                  </div>
                )}
                {isDia && (
                  <div className="">
                    {patientLabTestsOrder &&
                      patientLabTestsOrder?.length > 0 &&
                      patientLabTestsOrder.map((res, i) => (
                        <LabCart
                          labDetails={res}
                          data={res?.patientLabTestsOrderDetailsList}
                          id={res.id}
                          patientLabTestsOrder={patientLabTestsOrder}
                          deleteTest={deleteCartItem}
                          setPreferredDate={setPreferredDate}
                          setPreferredLabDate={setPreferredLabDate}
                        />
                      ))}
                  </div>
                )}

                {/* {cartList?.patientLabTestsOrder && cartList?.patientLabTestsOrder?.length ? (
                  <div className="flex flex-col w-full  justify-between items-center">
                    <div className="text-xs w-full text-textGray">
                      Select your prefered Date and Time for {cartList?.patientLabTestsOrder[0]?.patientLabTestsOrderDetailsList[0]?.labTestType == "Radiology" ? "your radiology appointment" : "sample collection"}
                    </div>
                    <PreferedDateAndTime
                      preferDate={preferDate}
                      preferTime={preferTime}
                      changeDate={changeDate}
                      labStartTime = {cartList?.patientLabTestsOrder[0].labStartTime}
                      labEndTime = {cartList?.patientLabTestsOrder[0].labEndTime}
                      labType = {cartList?.patientLabTestsOrder[0]?.patientLabTestsOrderDetailsList[0]?.labTestType}
                      handleChange={handleChange}
                      limitTimeForLab={limitTimeForLab}
                    />
                  </div>
                ) : null} */}
              </div> : null}
              {cartList?.patientLabTestsOrder && cartList?.patientLabTestsOrder?.length && (cartList?.patientLabTestsOrder[0]?.membershipId !== '0' && cartList?.patientLabTestsOrder[0]?.membershipId !== null) ? <div className="block md:hidden px-4 py-3 mt-4" style={{background: "radial-gradient(64.4% 108.13% at 50% 50%, #007ADB 0%, #004175 100%)", borderRadius: 8}} >
                <p className="text-xs font-medium" style={{color: "#ffff"}} >Yayy 🎉 !! You get an extra ₹{getLabDiscountPrice()} discount on your lab tests with CureBay Membership Plan </p>
              </div> : <></>}
              <div className="block md:hidden" ><PreviouslyBoughtMedicine /></div>
              <LabTestList />
              <div className="md:hidden px-2 mt-4" >
                <p className="text-lg font-semibold text-primaryColor mb-2" >Booking <span className="text-secondaryColor" >Details</span></p>
                <div className="flex justify-between items-center mb-1" >
                  <p className="text-sm font-normal" style={{color:"#676C80"}} >Booking for: {patient?.firstName}</p>
                 <button onClick={() => toggleDrawer(true)} ><EditOutlined /></button> 
                </div>
                {cartList?.patientLabTestsOrder &&
                  cartList?.patientLabTestsOrder?.length ? (
                    <div className="flex justify-between items-center" >
                    <p className="text-sm font-normal" style={{color:"#676C80"}}>Date & Time: {preferDate} | {preferTime.length ? preferTime : " "}</p>
                  <EditOutlined />
                  </div>
                  ) : null}
              </div>
            </div>
          
            <div className="md:w-[35%] bg-White md:min-h-[60vh] h-full p-8 rounded-lg">
              <CartPrice
                handleProceed={goToCheckoutPage}
                source={purchaseToken_source}
                cartData={cartList}
                getCartData={getCartData}
                patient={patient}
                buttonText="Checkout"
              />
            </div>
          </div>
          <div className="hidden md:block" ><PreviouslyBoughtMedicine /></div>
        </section>
      </Layouts>
      {/* <Drawer anchor= "bottom" open={openDateAndTimeDrawer} onClose={toggleDrawer(false)}>
        <div className="h-96 w-10 bg-secondaryColor" >s
          dvksdvsd
          </div>
      </Drawer> */}
      <Dialog
        visible={openDialog}
        showHeader={true}
        draggable={false}
        resizable={false}
        modal={true}
        header={
          <div className="font-normal text-center text-md">
            Please select coupon for better discount
          </div>
        }
        className="w-11/12 md:w-auto h-auto shadow-lg"
        onHide={() => setDialog(false)}
      >
        <>
          <div className="text-center">
            <div className="md:flex gap-3 justify-around mt-5">
              <button
                className="my-2 w-40 bg-brand-primary text-sm h-6 rounded-md text-white font-normal "
                onClick={() => setDialog(false)}
              >
                Select Coupon
              </button>

              <button
                className="my-2 w-60 bg-brand-primary text-sm h-6 rounded-md text-white font-normal "
                onClick={() => goToCheckoutPage("withoutcoupon")}
              >
                Proceed without Coupon
              </button>
            </div>
          </div>
        </>
      </Dialog>
      <PrevBrowsedMedicine />
    </>
  );
}
export default MedicineCart;