import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layouts from "../../../Layouts";
import { Col, DatePicker, Form, Input, Modal, Row, Select ,message} from "antd";
import FilterIcon from "../../../../Assets/Images/ReportsFilter.svg";
import LabImage from "../../../../Assets/Images/LabImage.jpg";
import NodataImage from"../../../../Assets/Images/Icons/nodata.svg";
import {
  getPatientLabTestsList,
  getPatientLabTestsListFilter,
} from "../../../../Redux/Action/UserprofileActions";
import { DICOM_URL } from "../../../../config/constant";
import moment from "moment";
import FamilyDropdown from "../HealthRecords/FamilyDropDown";
import LabServices from "../../../../Redux/Services/labServices";
import doctorService from "../../../../Redux/Services/doctorService";
function LabOrders() {
  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/', {isLoginModalOpen : true});
    }
  }, [])

  const userData = useSelector((state) => state.authReducer.patientData);
  const [list, setList] = useState([]);
  const [addlist, setaddlist] = useState({});
  const [labReport, setLabReport] = useState([])
  const [filePopup, setfilePopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [dicomToken, setDicomToken] = useState("");
  const [dicomURL, setdicomURL] = useState("");
  const [dicomPopup, setdicomPopup] = useState(false);
// const fetchLabOrderReports = (code) => {
//   let payload = {
//     patientId: code, //localStorage.getItem("patientprofile"),
//     docmentRequired: "Y",
//   }
//   LabServices.getLabReportDetails(payload).then(
//     (res) => {
//       console.log(res.data,"ffggghhhuhu")
//       if (res.data) {
//         let importantData = res.data.map(res => {
//           return{
//             orderId : res.orderId,
//             photoName: res.photoName,
//             type: res.type,
//             id:res.id,
//           }
//         })
//         setLabReport(importantData);
//       }
//     },
//     (err) => {
//       console.log(err);
//     }
//   );
// }

const fetchLabOrderReports = (code) => {
  let payload = {
    patientId: code,
    docmentRequired: "Y",
  };
  LabServices.getLabReportDetails(payload).then(
    (res) => {
      console.log(res.data, "Fetched lab reports");
      if (res.data) {
        // Group documents by orderId
        const groupedData = res.data.reduce((acc, curr) => {
          const { orderId, photoName, type, id, labTestName } = curr; // Add `testName` or similar field
          if (!acc[orderId]) {
            acc[orderId] = [];
          }
          acc[orderId].push({ photoName, type, id, labTestName });
          return acc;
        }, {});
        setLabReport(groupedData); // Set grouped data
      }
    },
    (err) => {
      console.error(err);
    }
  );
};
console.log(labReport,"fgvhbj")
  useEffect(() => {
    setaddlist({})
    dispatch(getPatientLabTestsList(userData.code))
      .then((res) => {
        let data = res?.filter((item) => item.procedureStatus !== -1);
        setList(data);
        fetchLabOrderReports(userData.code)
      })
      .catch((err) => {
        setList([]);
      });
  }, [dispatch]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
if(addlist.fromDate&& !addlist.toDate){
 return  message.error('Please select ToDate')
}
if(!addlist.fromDate&& addlist.toDate){
  return message.error('Please select FromDate')
}
    let payload = addlist;
    payload.patientId = userData.code;
    dispatch(getPatientLabTestsListFilter(payload)).then((result) => {
      let data = result?.filter((item) => item.procedureStatus !== -1);
      setList(data);
      setaddlist({});
      setIsModalOpen(false);
    });
  };
  const handleFamilyMemberChange = (value) =>{
    dispatch(getPatientLabTestsList(value))
    .then((res) => {
      let data = res?.filter((item) => item.procedureStatus !== -1);
      setList(data);
      fetchLabOrderReports(value)
    })
    .catch((err) => {
      setList([]);
    });
  }
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleChange = (e) => {
    setaddlist({ ...addlist, [e.target.name]: e.target.value });
  };
  const handleDateChange = (date, dateString) => {
    setaddlist({
      ...addlist,
      ["fromDate"]: dateString,
    });
  };
  const handleDateChangeTo = (date, dateString) => {
    setaddlist({
      ...addlist,
      ["toDate"]: dateString,
    });
  };
  // const viewOrder = (labOrder) => {
  //   let pickLabReport = labReport.find(res => res.orderId == labOrder.orderId)
  //   console.log(pickLabReport, labOrder,labReport, "dvsdvsdvsd")
  //   setSelectedRow(pickLabReport);
  //   pickLabReport.type =
  //     pickLabReport.type.toLowerCase() === "pdf"
  //       ? "application/pdf"
  //       : pickLabReport.type.toLowerCase() === "jpg"
  //       ? "image/jpeg"
  //       : pickLabReport.type.toLowerCase() === "jpeg"
  //       ? "image/jpeg"
  //       : pickLabReport.type.toLowerCase() === "png"
  //       ? "image/png"
  //       : pickLabReport.type;
  //   if (pickLabReport.type.toLowerCase() == "dicom") {
  //     viewDicomFile(pickLabReport.dicomId);
  //   } else {
  //     window.open(process.env.REACT_APP_IMG_BASEURL+pickLabReport.photoName, '_blank').focus();
  //     // setfilePopup(true);
  //   }
  // };


  const viewOrder = (doc) => {
    const fileType =
      doc.type.toLowerCase() === "pdf"
        ? "application/pdf"
        : doc.type.toLowerCase() === "jpg"
        ? "image/jpeg"
        : doc.type.toLowerCase() === "jpeg"
        ? "image/jpeg"
        : doc.type.toLowerCase() === "png"
        ? "image/png"
        : doc.type;
  
    if (fileType === "dicom") {
      viewDicomFile(doc.dicomId);
    } else {
      window.open(process.env.REACT_APP_IMG_BASEURL + doc.photoName, "_blank").focus();
    }
  };
  
  const viewDicomFile = (payload) => {
    const requestOptions = {
      method: "POST",
      headers: { Authorization: "Bearer " + dicomToken },
      // body: JSON.stringify({
      //   username: 'integration',
      //   password: 'integration'
      // })
    };
    fetch(DICOM_URL + "viewer/" + payload, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setdicomURL(data.details);
        setdicomPopup(true);
        // setDicomToken(data.token);
      });
  };

  const onDownloadDocument = async (name) => {
    let url = selectedRow.photoName
      ? `${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
      : "";
    let filename = selectedRow.type;

    doctorService.imagetoData(selectedRow.photoName).then((res) => {
      var base64;
      if (filename === "application/pdf") {
        base64 = "data:application/pdf;base64,";
      } else {
        base64 = "data:image/png;base64,";
      }
      //alert(JSON.stringify(res.data.fileData))
      var FileSaver = require("file-saver");
      FileSaver.saveAs(base64 + res.data.fileData, selectedRow.photoName);
    });
    console.log(selectedRow.photoName, "selectedRow.photoName");
    setfilePopup(false);
  };

  const onCancelDocument = () => {
    setfilePopup(false);
  };

  const renderDocumentFooter = (name) => {
    return (
      <div>
        <button
          onClick={() => onDownloadDocument(name)}
          className="bg-primaryColor  text-sm text-White font-normal rounded-md py-3 px-3 mr-2"
        >
          Download
        </button>

        <button
          onClick={() => onCancelDocument(name)}
          className="bg-primaryColor  text-sm text-White font-normal rounded-md py-3 px-3 mr-2"
        >
          Cancel
        </button>
      </div>
    );
  };
  return (
    <Layouts>
      <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
            <div className=" md:flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-White">
                My Lab Orders
              </div>
              <div className="hidden md:flex gap-2">
                <div
                  className="bg-lightBlue rounded-md md:w-12 w-10 md:h-12 h-10 flex items-center cursor-pointer"
                  onClick={showModal}
                >
                  <img src={FilterIcon} alt="Filter Icon" className="mx-auto" />
                </div>
                {/* <Select
                  placeholder="Get lab orders for"
                  className="md:h-12 h-10 w-[200px] text-base"
                >
                  <option value="medication">Medication</option>
                  <option value="condition">Condition</option>
                  <option value="prescriptionType">Prescription Type</option>
                </Select> */}
                <FamilyDropdown title={"Get lab orders for"}  onSelect={handleFamilyMemberChange}/>
              </div>
            </div>
          </div>
          <div className="md:hidden flex gap-2 mt-5">
            {/* <Select
              placeholder="Get lab orders for"
              className="h-12 w-full border border-gray rounded-md"
            >
              <option value="medication">Medication</option>
              <option value="condition">Condition</option>
              <option value="prescriptionType">Prescription Type</option>
            </Select> */}
            <FamilyDropdown title={"Get lab orders for"} onSelect={handleFamilyMemberChange}/>
            <div
              className="bg-White rounded-md w-12 h-12 flex items-center cursor-pointer"
              onClick={showModal}
            >
              <img src={FilterIcon} alt="Filter Icon" className="mx-auto" />
            </div>
          </div>
          <div className="mt-5">
            <Row gutter={24}>
              {/* {list?.map((user, i) => (
                <Col xs={24} sm={24} md={12}>
                  <div className="w-full rounded-md p-4 bg-White mb-5">
                    <div className=" flex gap-5">
                      <div className="w-[20%] h-full hidden md:block">
                        <img
                          src={
                            user.hospitalPhoto
                              ? process.env.REACT_APP_IMG_BASEURL +
                                user.hospitalPhoto
                              : LabImage
                          }
                          alt="img"
                          className="h-[100px] w-full rounded-sm shadow-lg"
                        />
                      </div>
                      <div className="md:w-[80%]">
                        <div className="flex justify-between items-center">
                          <div className="text-primaryColor font-semibold text-base  md:text-lg w-full">
                            {
                              user?.patientLabTestsOrderDetailsList[0]
                                ?.hospitalName
                            }
                          </div>
                          <div className="text-secondaryColor bg-lightGreen px-3 py-1.5 rounded-full text-xs cursor-pointer">
                            {user.procedureStatusName}
                          </div>
                        </div>
                        <div className="flex text-textGray w-full justify-between gap-3 mt-4">
                          <div className="text-textGray text-xs md:text-sm">
                            <label className="font-light">Order ID</label>
                            <p className="font-medium mt-1">{user.orderId}</p>
                          </div>
                          <div className="text-textGray text-xs md:text-sm">
                            <label className="font-light">Placed On</label>
                            <p className="font-medium mt-1">
                              {moment(user?.collectionSampleDate).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </div>
                          {user?.preferDateTime && <div className="text-textGray text-xs md:text-sm">
                            <label className="font-light">Preferred Date</label>
                            <p className="font-medium mt-1">
                              {moment(user?.preferDateTime).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </div>}
                        </div>
                      </div>
                     
                    </div>
                    {labReport.find(res => res.orderId == user.orderId) && <div
                        className="text-secondaryColor underline text-sm cursor-pointer flex justify-end py-2"
                        onClick={() => viewOrder(user)}
                      >
                        View Document
                      </div>}
                    {user.patientLabTestsOrderDetailsList.map((user1, i) => (
                      <div className="flex justify-between gap-5 mt-3">
                        <div className="md:w-[75%] text-textGray text-xs md:text-sm font-medium mt-1">
                          <span>{user1?.labTestName}</span>
                        </div>
                        <div className="w-[40%] md:w-[25%] text-right text-textGray text-sm font-light mt-1">
                          <span className="text-secondaryColor font-medium text-base md:text-lg">
                            ₹ {" "}
                          {parseFloat(user1?.discountAmount) 
  ? parseFloat(user1?.discountAmount).toFixed(2) 
  : parseFloat(user1?.amount)?.toFixed(2)}

                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              ))} */}

{/* {list?.map((order, i) => (
  <Col xs={24} sm={24} md={12} key={order.orderId}>
    <div className="w-full rounded-md p-4 bg-White mb-5">
      <div className="flex gap-5">
        <div className="w-[20%] hidden md:block">
          <img
            src={
              order.hospitalPhoto
                ? process.env.REACT_APP_IMG_BASEURL + order.hospitalPhoto
                : LabImage
            }
            alt="Hospital"
            className="h-[100px] w-full rounded-sm shadow-lg"
          />
        </div>
        <div className="md:w-[80%]">
          <div className="flex justify-between items-center">
            <div className="text-primaryColor font-semibold text-lg">
              {order.patientLabTestsOrderDetailsList[0]?.hospitalName}
            </div>
            <div className="text-secondaryColor bg-lightGreen px-3 py-1.5 rounded-full text-xs cursor-pointer">
              {order.procedureStatusName}
            </div>
          </div>
          <div className="flex text-textGray w-full justify-between gap-3 mt-4">
            <div>
              <label>Order ID</label>
              <p>{order.orderId}</p>
            </div>
            <div>
              <label>Placed On</label>
              <p>{moment(order.collectionSampleDate).format("DD-MM-YYYY")}</p>
            </div>
          </div>
        </div>
      </div>
      {order.patientLabTestsOrderDetailsList.map((user1, i) => (
                      <div className="flex justify-between gap-5 mt-3">
                        <div className="md:w-[75%] text-textGray text-xs md:text-sm font-medium mt-1">
                          <span>{user1?.labTestName}</span>
                        </div>
                        <div className="w-[40%] md:w-[25%] text-right text-textGray text-sm font-light mt-1">
                          <span className="text-secondaryColor font-medium text-base md:text-lg">
                            ₹ {" "}
                          {parseFloat(user1?.discountAmount) 
  ? parseFloat(user1?.discountAmount).toFixed(2) 
  : parseFloat(user1?.amount)?.toFixed(2)}

                          </span>
                        </div>
                      </div>
                    ))}
     
      {labReport[order.orderId]?.map((doc, index) => (
    
  <div key={doc.id} className="flex justify-between items-center py-2 mt-2">
   
    <div className="text-sm text-primaryColor font-medium">
      {doc.labTestName}
    </div>
   
    <div
      className="text-secondaryColor underline text-sm cursor-pointer"
      onClick={() => viewOrder(doc)}
    >
      View Document
    </div>
  </div>
))}


      
    </div>
  </Col>
))} */}

{list?.map((order, i) => (
  <Col xs={24} sm={24} md={12} key={order.orderId}>
    <div className="w-full rounded-md p-4 bg-White mb-5">
      <div className="flex gap-5">
        <div className="w-[20%] hidden md:block">
          <img
            src={
              order.hospitalPhoto
                ? process.env.REACT_APP_IMG_BASEURL + order.hospitalPhoto
                : LabImage
            }
            alt="Hospital"
            className="h-[100px] w-full rounded-sm shadow-lg"
          />
        </div>
        <div className="md:w-[80%]">
          <div className="flex justify-between items-center">
            <div className="text-primaryColor font-semibold text-lg">
              {order.patientLabTestsOrderDetailsList[0]?.hospitalName}
            </div>
            <div className="text-secondaryColor bg-lightGreen px-3 py-1.5 rounded-full text-xs cursor-pointer">
              {order.procedureStatusName}
            </div>
          </div>
          <div className="flex text-textGray w-full justify-between gap-3 mt-4">
            <div>
              <label>Order ID</label>
              <p>{order.orderId}</p>
            </div>
            <div>
              <label>Placed On</label>
              <p>{moment(order.collectionSampleDate).format("DD-MM-YYYY")}</p>
            </div>
          </div>
        </div>
      </div>

     
      <div className="mt-5">
        {order.patientLabTestsOrderDetailsList.map((user1, i) => {
         
          const doc = labReport[order.orderId]?.find(
            (d) => d.labTestName === user1.labTestName
          );

          return (
            <div
              key={i}
              className="grid grid-cols-3 gap-4 items-center py-2 "
            >
             
              <div className="text-sm font-medium text-primaryColor">
                {user1?.labTestName}
              </div>

             
              <div className="text-sm text-right font-medium text-secondaryColor">
                ₹{" "}
                {parseFloat(user1?.discountAmount)
                  ? parseFloat(user1?.discountAmount).toFixed(2)
                  : parseFloat(user1?.amount)?.toFixed(2)}
              </div>

             
              <div className="text-right">
                {doc ? (
                  <div
                    className="text-secondaryColor underline text-sm cursor-pointer"
                    onClick={() => viewOrder(doc)}
                  >
                    View Document
                  </div>
                ) : (
                  <span className="text-textGray text-xs">No Document</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </Col>
))}

               {list && list.length === 0 && (
                <div class=" w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
                  <div className="">
                    <img src={NodataImage} alt="image" className="w-[60px]"/>
                  </div>
                  <p className="text-center font-light item-center text-base text-textGray">
                    No lab orders available!
                  </p>
                </div>
              )}
            </Row>
          </div>
        </div>
      </section>
      <Modal
        title="Filter Orders"
        className="commonModal"
        centered={true}
        footer={false}
        open={isModalOpen}
        onCancel={handleCancel}
        width="50%"
      >
        <Form
          layout="vertical"
          autoComplete="off"
          className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
        >
          <Form.Item className="mb-0" label="Order ID" name="orderId">
            <Input
              id="orderId"
              name="orderId"
              onChange={handleChange}
              value={addlist.orderId}
              placeholder="Enter Order Id"
            />
          </Form.Item>
          <Form.Item className="mb-0" label="From Date" name="fromDate">
            <DatePicker
              onChange={handleDateChange}
              format="MM/DD/YYYY"  
              placeholder="fromDate "
              className="w-full"
            />
          </Form.Item>
          <Form.Item className="mb-0" label="To Date" name="toDate">
            <DatePicker
              onChange={handleDateChangeTo}
              format="MM/DD/YYYY"  
              placeholder="toDate"
              className="w-full"
            />
          </Form.Item>
          <Form.Item className="mb-0" label="Lab Name" name="labName">
            <Input
              id="labName"
              name="labName"
              onChange={handleChange}
              placeholder="Lab Name"
            />
          </Form.Item>
          <Form.Item
            className="mb-0"
            label="Procedure Status"
            name="procedureStatus"
          >
            <Select
              id="procedureStatus"
              name="procedureStatus"
              options={[
                { value: "1", label: "Ordered" },
                { value: "2", label: "Completed" },
                { value: "0", label: "Pending" },
                { value: "5", label: "Cancelled By Patient" },
                { value: "6", label: "Cancelled By Diagnostics" },
              ]}
              onChange={(e) =>
                setaddlist({ ...addlist, ["procedureStatus"]: e })
              }
              placeholder="Select Procedure Status"
            />
          </Form.Item>
          <Form.Item
            className="mb-0"
            label="Report Uploaded Status"
            name="Report status"
          >
            <Select
              id="status"
              name="status"
              options={[
                { value: "1", label: "Pending" },
                { value: "2", label: "Uploaded" },
              ]}
              onChange={(e) => setaddlist({ ...addlist, ["status"]: e })}
              placeholder="select Uploaded Status"
            />
          </Form.Item>
        </Form>
        <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
          <button
            onClick={handleCancel}
            className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] md:text-lg"
          >
            Cancel
          </button>
          <button
            className="bg-primaryColor w-full h-[50px] md:text-lg rounded-lg text-White"
            onClick={handleOk}
            >
            Filter
          </button>
        </div>
      </Modal>
      <Modal
        title={
          <p className="font-bold text-primaryColor text-xl">Lab Report</p>
        }
        visible={filePopup}
        modal={false}
        width="60%"
        onCancel={() => setfilePopup(false)}
        // style={{ width: "98vw", height: "100vh" }}

        footer={renderDocumentFooter("displayModal")}
        // onHide={() => onCancelDocument("displayModal")}
      >
        <p className="p-m-0">
          <div className="lg:flex pt-2 ">
            <div className="w-full">
              <div className="lg:flex lg:pt-1 g:space-x-10 ">
                {selectedRow?.type !== "application/pdf" ? (
                  <img
                    style={{ width: "100%", height: "500px" }}
                    className="box target object-contain"
                    src={
                      selectedRow.photoName
                        ? `${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
                        : ""
                    }
                    title="Dicom Viewer"
                    type={selectedRow.type}
                  />
                ) : (
                  <iframe
                    id="abc"
                    className="box target w-full h-auto"
                    style={{ width: "100%", height: "500px" }}
                    src={
                      selectedRow.photoName
                        ? `${process.env.REACT_APP_IMG_BASEURL}${selectedRow.photoName}`
                        : ""
                    }
                    title="Dicom Viewer"
                    type={selectedRow.type}

                    // style={iframeStyleLab}
                  />
                )}
              </div>
            </div>
          </div>
        </p>
      </Modal>
    </Layouts>
  );
}

export default LabOrders;
