import React from "react";
// import "../../components/CareSathi/chatLoading.css"
const ChatLoading = () => {
  return (
    <div className="chat-loading-container">
      <div className="dot1"></div>
      <div className="dot2"></div>
      <div className="dot3"></div>
    </div>
  );
};

export default ChatLoading;