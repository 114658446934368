import React from "react";
import Layouts from "../Layouts";
import Missionone from "../../Assets/Images/Icons/mission1.svg";
import Missiontwo from "../../Assets/Images/Icons/mission2.svg";
import Missionthree from "../../Assets/Images/Icons/mission3.svg";
import Missionfour from "../../Assets/Images/Icons/mission4.svg";
import visionone from "../../Assets/Images/Icons/vision1.svg";
import visiontwo from "../../Assets/Images/Icons/vision2.svg";
import visionthree from "../../Assets/Images/Icons/vision3.svg";
import visionfour from "../../Assets/Images/Icons/vision4.svg";
import visionfive from "../../Assets/Images/Icons/vision5.svg";
import SubHeader from "../Shared/SearchBar";

function AboutUs() {
  return (
    <Layouts>
      <SubHeader />
      <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                Get to know <span className="text-secondaryColor">CureBay</span>
                !
              </h1>
              <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
                We're making healthcare accessible and affordable for
                underserved communities through technology and innovation.
              </p>
            </div>
            <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%] md:h-[400px] rounded ">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/About%20Us.webp"
                  alt="images"
                  className="w-full h-full object-cover image-boxshadow rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-[50px]">
            <span className="text-secondaryColor"> About</span> Us
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
            <div className="md:h-[350px] block md:hidden">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/About4.webp"
                alt="images"
                className="rounded-xl w-full h-full object-cover"
              />
            </div>
            <p className="md:text-xl md:leading-loose text-textGray font-normal">
              CureBay is on a mission to revolutionize healthcare delivery in
              India by bridging the gaps that exist in accessing quality medical
              services, especially in remote and underserved areas. With a focus
              on closing the healthcare quality gap and ensuring accessibility
              and affordability for all, CureBay offers a range of innovative
              healthcare solutions tailored to the unique needs of patients.
            </p>
            <div className="md:h-[350px] hidden md:block">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/About4.webp"
                alt="images"
                className="rounded-xl w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primaryColor">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
            <div className="md:h-[350px]">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/About1.webp"
                alt="images"
                className="rounded-xl w-full h-full object-cover"
              />
            </div>
            <p className="md:text-xl md:leading-loose text-White">
              Our comprehensive services include online doctor consultations,
              pharmacy services delivering prescribed medications to your
              doorstep, state-of-the-art diagnostic facilities, seamless
              coordination for surgeries and hospitalizations, and swift
              ambulance services for emergency response.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
            <div className="md:h-[350px] block md:hidden">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/About2.webp"
                alt="images"
                className="rounded-xl w-full h-full object-cover"
              />
            </div>
            <p className="md:text-xl md:leading-loose text-textGray">
              Through our network of eClinics strategically located in remote
              areas, Swasthya Mitras acting as crucial intermediaries, and a
              network of skilled healthcare providers, we ensure that every
              patient receives optimal medical attention and support throughout
              their healthcare journey.
            </p>
            <div className="md:h-[350px] hidden md:block">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/About2.webp"
                alt="images"
                className="rounded-xl w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primaryColor">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
            <div className="md:h-[350px]">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/About3.webp"
                alt="images"
                className="rounded-xl w-full h-full object-cover"
              />
            </div>
            <p className="text-xl leading-loose text-White">
              With 100+ operational eClinics covering a vast network of villages
              and towns in Odisha and Chhattisgarh, CureBay has already made
              significant strides in providing accessible healthcare to over
              1,00,000 patients. Our innovative hybrid model combines
              technology, skilled personnel, and strategic partnerships to
              streamline healthcare delivery, making it more affordable and
              accessible for all.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-5 md:mb-[50px]">
            Our <span className="text-secondaryColor"> Mission</span>
          </h2>
          <div className="md:w-[70%] mx-auto grid md:grid-cols-2 gap-5">
            <div className="flex md:block bg-White hover:bg-primaryColor rounded-xl px-5 gap-5 md:px-8 py-5 common-shadow  hoverWhite">
              <div className="w-[25%] md:w-full md:mb-5">
                <img src={Missionone} alt="images" />
              </div>
              <p className="w-[75%] md:w-full text-textGray md:leading-loose text-sm md:text-lg">
                To bridge the gap in the Indian healthcare system for
                underserved communities
              </p>
            </div>
            <div className="flex md:block bg-White hover:bg-primaryColor rounded-xl px-5 gap-5 md:px-8 py-5 common-shadow hoverWhite">
              <div className="w-[25%] md:w-full md:mb-5">
                <img src={Missiontwo} alt="images" />
              </div>
              <p className="w-[75%] md:w-full text-textGray md:leading-loose text-sm md:text-lg">
                To digitally connect individuals from underserved regions to top
                cities for quality medical services
              </p>
            </div>
            <div className="flex md:block bg-White hover:bg-primaryColor rounded-xl px-5 gap-5 md:px-8 py-5 common-shadow hoverWhite">
              <div className="w-[25%] md:w-full md:mb-5">
                <img src={Missionthree} alt="images" />
              </div>
              <p className="w-[75%] md:w-full text-textGray md:leading-loose text-sm md:text-lg">
                To leverage technology, people, and innovation to address
                healthcare challenges
              </p>
            </div>
            <div className="flex md:block bg-White hover:bg-primaryColor rounded-xl px-5 gap-5 md:px-8 py-5 common-shadow hoverWhite">
              <div className="w-[25%] md:w-full md:mb-5">
                <img src={Missionfour} alt="images" />
              </div>
              <p className="w-[75%] md:w-full text-textGray md:leading-loose text-sm md:text-lg">
                To make healthcare accessible to everyone, regardless of
                location or background
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primaryColor md:bg-lightGray mb-[50px] md:mb-0">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-White md:text-primaryColor leading-normal mb-5 md:mb-[50px]">
            Our <span className="text-secondaryColor">Vision</span>
          </h2>
          <div className="md:flex gap-5 justify-center flex-wrap">
            <div className="flex md:block gap-5 mb-5 md:mb-0 items-center bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow  w-full md:w-[32%] hoverWhite">
              <div className="w-[25%] md:w-full md:mb-5 mb-0">
                <img src={visionone} alt="images" />
              </div>
              <p className="w-[75%] md:w-full text-textGray md:leading-loose text-sm md:text-lg">
                To revolutionize the patient experience in underserved markets
              </p>
            </div>
            <div className="flex md:block gap-5 mb-5 md:mb-0 items-center bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow w-full md:w-[32%] hoverWhite">
              <div className="w-[25%] md:w-full md:mb-5 mb-0">
                <img src={visiontwo} alt="images" />
              </div>
              <p className="w-[75%] md:w-full text-textGray md:leading-loose text-sm md:text-lg">
                To seamlessly connect patients with healthcare providers and
                essential ecosystem players digitally
              </p>
            </div>
            <div className="flex md:block gap-5 mb-5 md:mb-0 items-center bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow w-full md:w-[32%] hoverWhite">
              <div className="w-[25%] md:w-full md:mb-5 mb-0">
                <img src={visionthree} alt="images" />
              </div>
              <p className="w-[75%] md:w-full text-textGray md:leading-loose text-sm md:text-lg">
                To eliminate the gap in primary healthcare access
              </p>
            </div>
            <div className="flex md:block gap-5 mb-5 md:mb-0 items-center bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow w-full md:w-[32%] hoverWhite">
              <div className="w-[25%] md:w-full md:mb-5 mb-0">
                <img src={visionfour} alt="images" />
              </div>
              <p className="w-[75%] md:w-full text-textGray md:leading-loose text-sm md:text-lg">
                To transform healthcare delivery through our robust hybrid
                3-tier model
              </p>
            </div>
            <div className="flex md:block gap-5 mb-5 md:mb-0 items-center bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow w-full md:w-[32%] hoverWhite">
              <div className="w-[25%] md:w-full md:mb-5 mb-0">
                <img src={visionfive} alt="images" />
              </div>
              <p className="w-[75%] md:w-full text-textGray md:leading-loose text-sm md:text-lg">
                To introduce a hybrid healthcare and fulfilment model
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default AboutUs;
