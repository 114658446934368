import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const ApiDataContext = createContext();

export const ApiDataProvider = ({ children }) => {
    const [apiData, setApiData] = useState(null);
    const [home, setHome] = useState(null);
    const [caresathi, setCaresathi] = useState(null);
    const [medicinePage,setMedicinepage]=useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await fetch(
                //     `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=PATIENT_B2C`,
                //     {
                //       method: "GET",
                //     }
                //   );
                const response = await axios.get( `https://cms-stage.curebay.in/api/b2cimgs-and-vdos/?populate=headerComp,firstComp,firstComp.images,secondComp,secondComp,thirdComp,forthComp,forthComp.image_url,forthComp.videoUrl`);
                console.log(response.data.data,"cmsbase")
                setApiData(response.data);
                if (response.data.data && response.data.data.length > 0) {
                    setHome(response.data.data[0]);
                }
               
                if (response.data.data && response.data.data.length > 8) {
                    setCaresathi(response.data.data[8]);
                }
                if(response.data.data && response.data.data.length > 9 ){
                     setMedicinepage(response?.data?.data[9]);
                 
                }
                console.log(response?.data?.data[9],"BHHHH")
            } catch (error) {
                console.error("Failed to fetch data", error);
            }
        };

        fetchData();
    }, []);

    console.log(home,"ddftff")

    return (
        <ApiDataContext.Provider value={{ apiData, home,caresathi,medicinePage }}>
            {children}
        </ApiDataContext.Provider>
    );
};

export const useApiData = () => useContext(ApiDataContext);
