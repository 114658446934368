import React, { useState } from "react";
import Layouts from "../Layouts";
import Clockimage from "../../Assets/Images/Icons/clock.svg";
import Calendarimage from "../../Assets/Images/Icons/calendar.svg";
import Backarrow from "../../Assets/Images/Icons/backarrow.svg";
import HoverBackarrow from "../../Assets/Images/Icons/backarrow-white.svg";
import { useHistory } from "react-router-dom";
import Blog5 from "../../Assets/Images/Blog/Blog5.jpg"

function BlogDetails5() {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  const handleNavigation = (path) => {
    history.push(path);
  };
  return (
    <Layouts>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px] md:w-[80%]">
          <div
            className="text-primaryColor hover:bg-primaryColor hover:text-White py-2 px-5 w-fit rounded-full mx-auto text-lg font-medium cursor-pointer flex gap-3 justify-center items-center mb-10"
            onClick={() => handleNavigation("/blogs")}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img src={isHovered ? HoverBackarrow : Backarrow} alt="images" />
            Back to blogs
          </div>
          <div>
            <h2 className="md:w-[60%] mx-auto text-center text-2xl md:text-4xl font-bold text-primaryColor !leading-normal mb-5">
              <span className="text-secondaryColor">
                Transforming Rural Healthcare:{" "}
              </span>{" "}
              From Quacks to Quality Care through Virtual Consultations
            </h2>
            <p className="md:w-[50%] mx-auto text-center text-textGray ">
              In the heart of rural landscapes, where the verdant fields stretch
              endlessly and the sky meets the horizon, healthcare has long been
              a narrative of scarcity and shadows.
            </p>
            <div className="flex gap-4 justify-center mt-5">
              <div className="flex items-center gap-2">
                <img src={Clockimage} alt="images" />
                <p className="text-textGray text-sm ">6 minute read</p>
              </div>
              <div className="flex items-center gap-2">
                <img src={Calendarimage} alt="images" />
                <p className="text-textGray text-sm ">25th February, 2024</p>
              </div>
            </div>
          </div>
          <div className="w-full md:h-[500px] my-5 md:my-10">
            <img
              src={Blog5}
              alt="images"
              className="w-full  h-full object-cover rounded-lg"
            />
          </div>
          <div className="bg-White rounded-xl p-5 md:p-10">
            <h6 className=" text-primaryColor text-xl font-semibold my-5">
              Introduction:
            </h6>
            <p className="text-textGray mb-4 md:leading-loose">
              In the heart of rural landscapes, where the verdant fields stretch
              endlessly and the sky meets the horizon, healthcare has long been
              a narrative of scarcity and shadows. For years, these underserved
              communities placed their trust in the hands of quacks—individuals
              masquerading as medical professionals—owing to the acute shortage
              of qualified healthcare providers. This reliance on unqualified
              practitioners not only endangered lives but also perpetuated a
              cycle of misinformation and mistrust towards genuine healthcare
              services. However, the dawn of telemedicine and the advent of
              small clinics staffed by real healthcare professionals like nurses
              and pharmacists have sparked a significant transformation,
              offering a beacon of hope and quality care to rural inhabitants.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              The Quack Dilemma:
            </h6>
            <p className="text-textGray md:leading-loose  mb-4">
              The term "quack" conjures images of fraudulent or ignorant
              pretenders to medical skill—a stark reality for many rural areas
              across the globe. These charlatans, often the only available
              option, provided treatments based on unproven and potentially
              harmful practices. The dangers of such care were manifold, ranging
              from incorrect diagnoses to ineffective or hazardous treatments,
              exacerbating the health issues facing rural populations.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              A New Chapter in Rural Health:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              The narrative, however, is shifting. Imagine stepping into a
              small, welcoming clinic in your village, staffed by a
              knowledgeable nurse and a skilled pharmacist. Here, the warmth of
              genuine care replaces the uncertainty of quackery. The most
              revolutionary aspect of this clinic isn't just the physical
              presence of these healthcare professionals—it's the digital
              connection to a virtual doctor, available for consultation within
              minutes.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              The Role of Telemedicine:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Telemedicine has emerged as a powerful tool in dismantling the
              barriers to quality healthcare in rural areas. Through virtual
              consultations, patients can now access the expertise of qualified
              doctors without the need for long, arduous journeys to distant
              hospitals. This not only saves time and resources but also ensures
              timely medical advice, which can be critical in treating acute
              conditions or managing chronic diseases.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              The Impact:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              The impact of these small clinics and virtual consultations is
              profound: <br /> Accessibility: Patients have direct access to
              qualified healthcare professionals, reducing the reliance on
              untrained quacks. <br /> Quality Care: Virtual consultations
              provide accurate diagnoses and effective treatment plans,
              improving overall health outcomes. <br /> Education and Awareness:
              Interaction with real healthcare professionals raises awareness
              about preventive care and dispels myths surrounding diseases and
              treatment.
              <br /> Empowerment: Communities feel empowered by having control
              over their health choices and access to reliable healthcare
              services.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Looking Forward:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              This transformation is just the beginning. The integration of
              technology in rural healthcare promises a future where quality
              medical advice is a right, not a luxury, for every individual,
              regardless of their geographic location. However, challenges such
              as improving digital infrastructure and ensuring the
              sustainability of these clinics remain. Overcoming these obstacles
              requires collaborative efforts from governments, healthcare
              providers, and the community.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Conclusion:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              The shift from quacks to quality care through virtual
              consultations in rural areas is a testament to the resilience and
              adaptability of these communities. It highlights a crucial
              evolution in healthcare delivery, where technology bridges the gap
              between remote areas and the medical mainstream. As we continue to
              build on this foundation, the dream of universal healthcare access
              becomes increasingly tangible, ensuring a healthier future for
              rural populations worldwide.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
            <span className="text-secondaryColor">Recommended</span> Reads
          </h2>
          <div className="grid md:grid-cols-2 gap-5">
            <div
              className="bg-White common-shadow rounded-xl p-5 cursor-pointer w-[80%] mx-auto"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div className="w-full mb-3">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog1.jpg"
                  alt="images"
                  className="w-full"
                />
              </div>
              <h6 className="text-primaryColor font-semibold text-lg mb-2">
                Beat the Heat: 10 Tips for Staying Cool and Hydrated This Summer
              </h6>
              <p className="text-textGray text-sm mb-4">
                As temperatures rise and the sun shines brighter, it's essential
                to prioritize staying cool and hydrated to beat the summer heat.
              </p>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
            <div
              className="bg-White common-shadow rounded-xl p-5 cursor-pointer w-[80%] mx-auto"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div className="w-full mb-3">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog3.jpg"
                  alt="images"
                  className="w-full"
                />
              </div>
              <h6 className="text-primaryColor font-semibold text-lg mb-2">
                10 Tips for Managing Chronic Conditions with Telehealth
              </h6>
              <p className="text-textGray text-sm mb-4">
                In recent years, telehealth has emerged as a groundbreaking
                solution for managing chronic health conditions, offering
                convenience, accessibility, and personalized care from the
                comfort of one's home.
              </p>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default BlogDetails5;
