import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Header from "../../Shared/Header";
import defaultMed from "../../../Assets/pharamacyImages/Medicines.jpg";
import CurebayButton from "../../Shared/Button/PrimaryButton";
import { FaSearch } from "react-icons/fa";
import { trackAddToCartEvent } from "../../utils/analytics/events";
import { useDispatch } from "react-redux";
import { getCartDetails } from "../../../Redux/Action/cartPlaceOrderAction";
import { useSelector } from "react-redux";
import searchIcon from "../../../Assets/pharamacyImages/Search Icon.svg";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

export default function PastOrders() {
  const [pastOrders, setPastOrders] = useState([]);
  const [orderBy, setOrderBy] = useState("ORDER_DATE_AESC");
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const { cartList } = useSelector((state) => state.cartReducer);
  const history = useHistory();

  useEffect(() => {
    const profileData = localStorage.getItem("patientInfo");
    const storedData = JSON.parse(profileData);

    const fetchPastOrders = async () => {
      try {
        let patient_id = storedData?.data?.code;
        if (!patient_id) {
          console.error("Patient ID is not defined.");
          return;
        }

        const res = await axios.get(
          `${process.env.REACT_APP_BASEURL}MedicineOrder/getDistinctMedicines?patientId=${patient_id}` +
            `${orderBy ? `&orderBy=${orderBy}` : ""}` +
            `${searchTerm ? `&drugName=${searchTerm}` : ""}`
        );

        if (res?.data?.data) {
          setPastOrders(res.data.data);
        } else {
          console.warn("No data received from the API.");
        }
      } catch (error) {
        console.error("Error fetching past medicine orders:", error.message);
      }
    };

    if (storedData) {
      fetchPastOrders();
    } else {
      console.error("No patient information found in local storage.");
    }
  }, [orderBy, searchTerm]);

  const handleOrderChange = (e) => {
    setOrderBy(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const getProductsIDs = () => {
    let ids = [];
    if (cartList) {
      if (cartList?.patientMedicineOrder?.length) {
        cartList?.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(
          (res) => {
            ids.push(res.drugCode);
          }
        );
      }
    }
    return ids;
  };

  const drugIDsInCart = getProductsIDs();

  const handleAddToCart = async (item) => {
    let payload = {
      patientId: item?.patientId,
      drugCode: item?.drugCode,
      drugName: item?.drugName,
      drugCategory: item?.drugCategory,
      medicineTypeOfSell: item?.drugsInfoTypeofSell,
      medicineType: "",
      unitPrice: Number(item?.mdmDrugDetails?.medicineRate),
      quantity: 1,
      prescriptionRequired: item?.mdmDrugDetails?.prescriptionRequired,
      vendorId: item?.vendorId,
    };
    console.log(payload, "Payload");
    fetch(`${process.env.REACT_APP_BASEURL}newPatientMedicine/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res, "vghsdvhcvdsg");
        trackAddToCartEvent(item?.mdmDrugDetails?.medicineRate, item?.drugName);
        // setIsLoading(false);
        dispatch(getCartDetails(item?.patientId));
      })
      .catch((err) => {
        console.error("Error adding to cart:", err);
        toast("Something Went Wrong");
      });
  };

  const redirectToMedicinePage = (item) => {
    // history.push('/medicine');
    history.push(`/medicine/${item}`);
  };

  return (
    <>
      <Header />
      <ToastContainer />

      <div className="p-5 bg-lightBlue mt-20">
        <h2 className="text-3xl font-semibold text-center mb-6">
          Purchased <span className="text-secondaryColor">Medicine List</span>
        </h2>

        <div className="flex flex-wrap justify-between items-center mb-6 bg-gray-50 rounded-lg p-4 shadow-sm bg-White">
          {/* Search Input Container */}
          <div className="flex items-center w-full md:max-w-lg relative mb-4 md:mb-0 bg-white border border-lightBlue rounded-md shadow-sm">
            <img
              src={searchIcon}
              alt="Search Icon"
              className="absolute left-3 w-5 h-5 text-primaryColor"
            />
            <input
              type="text"
              placeholder="Search medicine by name"
              className="w-full p-3 pl-10 border-lightBlue focus:outline-none focus:ring-2 focus:ring-lightBlue focus:border-lightBlue rounded-md"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          {/* Dropdown Container */}
          <div className="w-full md:w-auto bg-white border border-lightBlue rounded-md shadow-sm">
            <select
              className="w-full md:w-auto p-3 text-gray-700 border-lightBlue focus:outline-none focus:ring-2 focus:ring-lightBlue focus:border-lightBlue rounded-md"
              onChange={handleOrderChange}
              value={orderBy}
            >
              <option value="ORDER_DATE_DESC">Sort by: Date New to Old</option>

              <option value="ORDER_DATE_AESC">Sort by: Old to New</option>

              <option value="PRICE_HIGH">Sort by: Price High to Low</option>

              <option value="PRICE_LOW">Sort by: Price Low to High</option>
            </select>
          </div>
        </div>

        <div className="flex justify-center">
          <div className="w-full max-w-screen-xl px-4">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-6 ">
              {pastOrders.length > 0 ? (
                pastOrders.map((order, index) => (
                  <div
                    key={index}
                    className="bg-White rounded-lg shadow-sm overflow-hidden text-center p-4"
                  >
                    <img
                      src={
                        order?.mdmDrugDetails?.medicineImages &&
                        order?.mdmDrugDetails?.medicineImages.length > 0
                          ? order?.mdmDrugDetails?.medicineImages[0]
                          : defaultMed
                      }
                      alt={order.drugName || "Medicine Image"}
                      className="w-full h-28 object-contain mb-2 cursor-pointer"
                      onClick={() =>
                        redirectToMedicinePage(order?.mdmDrugDetails?.seoNameId)
                      }
                    />
                    <div className="text-center">
                      <h4 className="text-sm font-medium mb-1 text-primaryColor truncate">
                        {order.drugName}
                      </h4>
                      <p className="text-secondaryColor text-sm mb-1">
                        Rs.{" "}
                        {order?.mdmDrugDetails?.medicineRate ||
                          order?.unitPrice}
                      </p>
                      <p className="text-textGray text-xs mb-3 truncate">
                        {order.drugsInfoTypeofSell}
                      </p>
                      <button
                        className={`px-4 py-2 text-sm rounded-md font-semibold transition h-10 ${
                          drugIDsInCart.includes(order.drugCode)
                            ? "bg-secondaryColor text-White cursor-not-allowed opacity-50"
                            : "bg-primaryColor text-White hover:bg-secondaryColor"
                        }`}
                        onClick={() => handleAddToCart(order)}
                        disabled={drugIDsInCart.includes(order.drugCode)}
                      >
                        {drugIDsInCart.includes(order.drugCode)
                          ? "Added"
                          : "Add to cart"}
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center col-span-full text-gray-500">
                  No past orders available.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
