import { useHistory } from "react-router-dom";
import Linkarrow from "../../../Assets/Images/Icons/linkarrow.svg";
import BgRectImage from "../../../Assets/Images/bg-rect.png";
const MembershipSection = ({data}) =>{
    const history = useHistory()
    const splitHeading = (sentence) => {
        // Trim sentence and extract words
        const trimmedSentence = sentence.trim();
        const words = trimmedSentence.split(' ');
      
        // Get the last word and the rest of the sentence
        const lastWord = words.pop(); // Removes the last word from the array
        const restOfSentence = words.join(' '); // Joins the remaining words
      
        return (
          <p className="text-center" >
            <span className="text-2xl md:text-4xl font-bold" style={{ color: '#ffff' }}>{restOfSentence}</span>{' '}
            <span className="text-2xl md:text-4xl font-bold" style={{ color: '#42BA85' }}>{lastWord}</span>
          </p>
        );
      };

      const handleNavigation = (path) => {
        history.push(path);
        window.scrollTo(0, 0);
      };

      const handleCheckout = () => {
        handleNavigation("/services/membership");
        // setTimeout(scrollToMembershipCart, 500); // Scroll after navigating to ensure the section is loaded
      };
    return(
        <section className="bg-primaryColor relative">
          <div className="container mx-auto py-[50px] md:py-[90px] relative z-10">
            
            {splitHeading(data?.sixthComp[0]?.heading)}
            <p className="md:w-[70%] mx-auto text-sm md:text-lg md:leading-loose text-White text-center">
             {data?.sixthComp[0]?.description}
            </p>
            <div className="flex justify-center my-10">
              <img
                src= {data?.sixthComp[0]?.image_url}
                alt="images"
              />
            </div>
            <div className="w-full flex justify-center">
              <div
                onClick={handleCheckout}
                className="group flex justify-center items-center w-fit text-base hover:bg-White bg-secondaryColor rounded p-3 hover:text-secondaryColor text-White gap-3 cursor-pointer"
              >
                Avail Now{" "}
                <img
                  src={Linkarrow}
                  alt="images"
                  className="brightness-200 group-hover:brightness-100"
                />
              </div>
            </div>
          </div>
          <div className="w-full absolute top-[50%] ">
            <img
              src={BgRectImage}
              alt="images"
              className="w-full h-[200px] md:h-full"
            />
          </div>
        </section>
    )
}

export default MembershipSection