import React, { useState, useEffect } from "react";
import Layouts from "../../Layouts";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import DoctorImage from "../../../Assets/Images/Doctor/image 155.png";
import female_icon from "../../../Assets/Images/Female-Vector-icon.svg";
import male_icon from "../../../Assets/Images/Male-Vector-icon.svg";
import Slider from "react-slick";
import { getDoctorsslots, getParticularDoctors, getDoctorsAppointment, gethospitalclinicList} from "../../../Redux/Action/doctorAction";

import doctorImage from "../../../Assets/Images/doctorImage.svg";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import { useDispatch, useSelector } from "react-redux";
import NewDoctorSlots from "./NewDoctorSlots";
import { RightOutlined } from "@ant-design/icons";
import { Segmented } from "antd";
import { Helmet } from "react-helmet";

function DoctorBooking() {
  const dispatch = useDispatch();
  const params = useParams();
  const particulardoctor = useSelector((state) => state.particulardoctor);
  const { particularDoct, hospitalcliniclistData } = particulardoctor;
  const doctorsAppointment = useSelector((state) => state.doctorAppointmentList);
  const userData = useSelector((state) => state.authReducer.patientData);
  const { doctorappointmentList } = doctorsAppointment;
  const { slotData } = useSelector((state) => state.doctorsslot);
  const [appointmentAmount, setAppointmentAmount] = useState(0);
  const [appointmentType, setAppointmentType] = useState("V");
  const [bg, setbgColor] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const { doctor } = useParams();
  const history = useHistory();
  const Doctor = doctor.replaceAll("_", " ");
  const lastIndex = doctor.lastIndexOf("-");
  const DoctorName = lastIndex !== -1 ? doctor.substring(0, lastIndex) : doctor;
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [feeDetails, setFeedetails] = useState({
    videoConsFee: "",
    audioConsFee: "",
  });
 // const [selectedOption, setSelectedOption] = useState("Video");
  const [carouseldata, setCarouselData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let doctorCode = params.doctor.split("-");

        await Promise.all([
          dispatch(getParticularDoctors(params.doctor)),
          dispatch(getDoctorsAppointment(doctorCode[doctorCode.length - 1])),
          dispatch(gethospitalclinicList(params.doctor)),
        ]);

        setLoading1(false);
        setLoading3(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading1(false);
        setLoading3(false);
      }
    };

    fetchData();
  }, [dispatch, params]);

  useEffect(() => {
    let data = [];

    if (
      particularDoct &&
      particularDoct?.userConsultationFees?.length &&
      particularDoct?.userConsultationFees[0]
    ) {
      // Ensure window.dataLayer is initialized as an array
      if (!Array.isArray(window.dataLayer)) {
        window.dataLayer = [];
      }

      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "view_doctor",
        ecommerce: {
          currency: "INR",
          value:
            particularDoct?.userConsultationFees[0].videoConsFee != null
              ? particularDoct?.userConsultationFees[0].videoConsFee
              : 0.0,
          items: [
            {
              item_id: particularDoct?.code,
              item_name: `${particularDoct?.salutation} ${particularDoct?.firstName} ${particularDoct?.lastName}`,
              index: 0,
              item_brand: "CureBay",
              item_category: "doctor",
              item_category2: particularDoct?.speciality,
              location_id: particularDoct?.userConsultationFees[0].locationId,
              price:
                particularDoct?.userConsultationFees[0].videoConsFee != null
                  ? particularDoct?.userConsultationFees[0].videoConsFee
                  : 0.0,
              quantity: 1,
            },
          ],
        },
      });

      setFeedetails({
        videoConsFee:
          particularDoct?.userConsultationFees[0].videoConsFee != null
            ? particularDoct?.userConsultationFees[0].videoConsFee
            : 0.0,
        inPersonConsFee:
          particularDoct?.userConsultationFees[0].inPersonConsFee != null
            ? particularDoct?.userConsultationFees[0].inPersonConsFee
            : 0.0,
      });
      if (particularDoct?.userConsultationFees[0].audioConsFee) {
        data.push({
          fee: particularDoct?.userConsultationFees[0].audioConsFee,
          text: "Audio Consult",
        });
      }
      if (particularDoct?.userConsultationFees[0].inPersonConsFee) {
        data.push({
          fee: particularDoct?.userConsultationFees[0].inPersonConsFee,
          text: "InPerson Consult",
        });
      }
      if (particularDoct?.userConsultationFees[0].quickConsultationFee) {
        data.push({
          fee: particularDoct?.userConsultationFees[0].quickConsultationFee,
          text: "Quick Consult",
        });
      }
      if (particularDoct?.userConsultationFees[0].VideoConsFee) {
        data.push({
          fee: particularDoct?.userConsultationFees[0].VideoConsFee,
          text: "Video Consult",
        });
      }
      setCarouselData(data);
    }
  }, [particularDoct]);

  const handleBooking = () => {
    history.push("/payment");
  };

  const handleDateClick = (dateTime) => {
    setSelectedDate(dateTime);
    setSelectedTime("");
  };

  const handleTimeClick = (time) => {
    setSelectedTime(time);
  };

  const handlePrevClick = () => {
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    setCurrentDate(yesterday);
  };

  const handleNextClick = () => {
    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);
    setCurrentDate(nextDay);
  };

  const dates = [];
  for (let i = 0; i < 4; i++) {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + i);
    dates.push(date);
  }

  const Slots = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        healths: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        healths: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleImgError = (e) => {
    e.target.src = doctorImage;
  };

  return (
    <Layouts>
        <Helmet> 
   <title>{particularDoct?.firstName + " "+particularDoct?.lastName +" "+particularDoct?.speciality+" "+"| Curebay"}</title>
<meta name='description' content={particularDoct?.aboutMe} />
<link rel="canonical" href={window.location.href} />

<meta property="og:title" content={particularDoct?.firstName + " "+particularDoct?.lastName +" "+particularDoct?.speciality +" "+"| Curebay"} />
<meta property="og:description" name='description' content={particularDoct?.aboutMe} />
  </Helmet>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[20px] md:py-[30px]">
          <div className="w-full bg-primaryColor rounded-md px-8 py-4 mb-5">
            <div className=" md:text-xl text-White">
              <Link to="/find-doctors"> Doctors </Link>
              <RightOutlined className="text-base" />
              <span className="cursor-pointer text-secondaryColor">
                {" "}
                {DoctorName}
              </span>
            </div>
          </div>
          <div className="md:flex gap-5 justify-center">
            <div className=" md:w-[40%]  bg-White p-5 rounded-xl">
              <div className="md:flex gap-5">
                <div className="md:w-[40%] mb-5 md:mb-0 h-[150px] bg-lightBlue rounded-lg">
                  <img
                    className="w-full h-full rounded-lg object-contain"
                    src={particularDoct.photoName ? `${process.env.REACT_APP_IMG_BASEURL}${particularDoct.photoName}` : doctorImage}
                    alt={particularDoct?.firstName}
                    onError={handleImgError}
                  />
                </div>
                <div className="md:w-[60%]">
                  <p
                    className="font-semibold text-primaryColor text-xl"
                  >
                    {particularDoct?.salutation} {particularDoct.firstName}{" "}
                    {particularDoct.lastName}
                  </p>
                  <p className="text-textGray text-sm mt-3">
                    {particularDoct.specialities}
                    {/* specialities */}
                  </p>
                  <p className="flex flex-wrap w-full text-sm items-center md:gap-2 py-1 text-textGray">
                    <span className="font-semibold">Qualification :</span>
                    <span className="">
                      {particularDoct?.qualification
                        ? particularDoct?.qualification
                        : ""}
                    </span>
                  </p>
                  <p className="flex flex-wrap  text-sm items-center md:gap-2 py-1 text-textGray">
                    <span className="font-semibold ">Experience :</span>
                    <span className=" text-sm">
                      {particularDoct.yearsOfExperience} Years
                    </span>
                  </p>
                  <p className="flex flex-wrap text-sm items-center md:gap-2 py-1 text-textGray">
                    <span className="font-semibold ">Location :</span>
                    <span className=" text-sm">{particularDoct.city}</span>
                  </p>
                </div>
              </div>
              <div className="border-t border-t-gray pt-5 mt-5">
                <p className="font-semibold text-lg text-primaryColor mb-3">
                  About the Doctor
                </p>
                <p className="font-normal text-sm text-textGray">
                  {particularDoct.aboutMe}
                </p>
              </div>
            </div>
            <div className="md:w-[60%] mt-5 md:mt-0 bg-White p-5 rounded-xl">
              {/* <div className="flex justify-end">
            <Segmented
                className="commonSegment "
                options={["In-Person", "Video"]}
                block
                onChange={(option) => setSelectedOption(option)}
                style={{width:"30%"}}
              />
              </div> */}
              <p className="md:flex  justify-between  py-3 px-5 rounded-xl w-full bg-lightBlue text-primaryColor font-medium mt-5">
                <div className="flex gap-3 items-center">
                  <input
                    type="radio"
                    className=""
                    name="V"
                    value="Video"
                    checked={bg === true}
                    onChange={() => {
                      setAppointmentType("V");
                      setAppointmentAmount(feeDetails?.videoConsFee);
                      setbgColor(true);
                    }}
                  />
                  <span className="text-start">Book an Appointment</span>
                </div>
                <span className="font-semibold text-secondaryColor text-lg">
                  ₹ {feeDetails?.videoConsFee}
                </span>
              </p>
              <div className="mt-5">
                <NewDoctorSlots
                  doctorsAppointment={doctorappointmentList}
                  userData={userData}
                  doctorData={particularDoct}
                  doctid={params.doctor}
                  slots={slotData}
                  appointmentType={appointmentType}
                  appointmentAmount={appointmentAmount}
                  hospitalId={hospitalcliniclistData[0]?.hospitalId}
                  locationId={hospitalcliniclistData[0]?.locationId}
                  fees={
                    particularDoct &&
                    particularDoct?.userConsultationFees?.length > 0 &&
                    particularDoct.userConsultationFees[0]
                  }
                />
                {/* <div className='flex items-center gap-6'>
                                    <img src={PrevArrow} alt='prev' className='w-[25px] h-[25px]' onClick={handlePrevClick} />
                                    <div className='flex gap-1 items-center border border-lightGray'>
                                        {dates.map((date, index) => (
                                            <p key={index} className={`p-[0.25rem] ${selectedDate && date.toDateString() === selectedDate.toDateString() ? 'bg-primaryColor text-White' : ''}`} onClick={() => handleDateClick(date)}>{`${date.toDateString().slice(4).replaceAll(' ',',')}  [${date.toDateString().slice(0,4)}]`}</p>
                                        ))}
                                    </div>
                                    <img src={NextArrow} alt='next' className='w-[25px] h-[25px]' onClick={handleNextClick} />
                                </div>
                                <div className='grid grid-cols-4 gap-2 my-4 '>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='8:00AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('8:00AM')}>8:00AM</p>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='8:15AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('8:15AM')}>8:15AM</p>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='8:30AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('8:30AM')}>8:30AM</p>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='8:45AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('8:45AM')}>8:45AM</p>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='9:00AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('9:00AM')}>9:00AM</p>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='9:15AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('9:15AM')}>9:15AM</p>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='9:30AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('9:30AM')}>9:30AM</p>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='9:45AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('9:45AM')}>9:45AM</p>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='10:00AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('10:00AM')}>10:00AM</p>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='10:15AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('10:15AM')}>10:15AM</p>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='10:30AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('10:30AM')}>10:30AM</p>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='10:45AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('10:45AM')}>10:45AM</p>
                                    <p className={`p-[0.5rem] text-center font-medium border border-lightGray hover:bg-secondaryColor hover:text-White ${selectedTime ==='11:00AM'?'bg-secondaryColor text-White':''}`} onClick={()=> handleTimeClick('11:00AM')}>11:00AM</p>
                                </div>
                                <button className='w-full bg-primaryColor text-White py-2 rounded-lg px-auto text-center font-semibold text-xl' onClick={handleBooking}>Proceed</button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default DoctorBooking;
