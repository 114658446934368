import React, { useEffect, useState } from "react";
import { Collapse } from "antd";

function IndustryLead({data}) {  
  const [displayData, setDisplayData] = useState([])
  useEffect(() =>{
    let items = []
    if(data?.eighthComp?.length){
      items = [
        {
          key: "1",
          label: data?.eighthComp[0]?.subHeading,
          children: (
            <div className="grid grid-cols-3 md:grid-cols-5 gap-4 md:gap-10 mx-5 md:mx-10">
            {data?.eighthComp[0].eightsubcomp.map(res => (<div className="flex justify-center items-center">
              <img src={res.image_url} alt="images" />
            </div>))}
            </div>
          )
        },
        { 
          key: "2",
          label: data?.eighthComp[1]?.subHeading,
          children: (
            <div className="grid grid-cols-3 md:grid-cols-5 gap-4 md:gap-10 mx-5 md:mx-10">
            {data?.eighthComp[1].eightsubcomp.map(res => (<div className="flex justify-center items-center">
              <img src={res.image_url} alt="images" />
            </div>))}
            </div>
          )

        },
        { 
          key: "3",
          label: data?.eighthComp[2]?.subHeading,
          children: (
            <div className="grid grid-cols-3 md:grid-cols-5 gap-4 md:gap-10 mx-5 md:mx-10">
            {data?.eighthComp[2].eightsubcomp.map(res => (<div className="flex justify-center items-center">
              <img src={res.image_url} alt="images" />
            </div>))}
            </div>
          )

        },
        { 
          key: "4",
          label: data?.eighthComp[3]?.subHeading,
          children: (
            <div className="grid grid-cols-3 md:grid-cols-5 gap-4 md:gap-10 mx-5 md:mx-10">
            {data?.eighthComp[3].eightsubcomp.map(res => (<div className="flex justify-center items-center">
              <img src={res.image_url} alt="images" />
            </div>))}
            </div>
          )

        }
      ]
      setDisplayData(items)
    }

  },[data])
  return (
    <Collapse
      accordion
      items={displayData}
      defaultActiveKey={["1"]}
      className="homeCollapse firstchildcollapse"
    />
  );
}

export default IndustryLead;
