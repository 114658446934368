import Mapmuind from "../../Shared/Mapmyindia";
const EclinicMap = () =>{
    return(
       <>
        { process.env.REACT_APP_ENV == 'production' ? <section className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px] relative mb-10">
            <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal  md:mb-[50px]">
              Find your{" "}
              <span className="text-secondaryColor">nearest eClinic</span>
            </h2>
           <div className="relative">
              <Mapmuind />
            </div> 
          </div>
        </section> : null}</>
    )
}

export default EclinicMap