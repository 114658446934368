import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import http from "../../../../Redux/Services/http-common";
import { setLatLong, setAddressString } from "../../../../Redux/Action/userActions";
import { secureStorage } from "../../../../Redux/Reducer/authReducer";

function Mapp(props) {
  const dispatch = useDispatch();
  const { currentLocationcoords = {}, getAddressFromMap = () => {} } = props;
  const [map, setMap] = useState(null);
  let marker = null
  let coords = {
    lat: currentLocationcoords?.lat ? currentLocationcoords?.lat : 20.2961,
    long : currentLocationcoords?.long ? currentLocationcoords?.long : 85.8245
    
  }
  // Load map scripts and initialize the map
  let isMarkerInitated = false
  useEffect(() => {
    let script;
    var apiUrl = "MMI/accesstoken";
    const loadScript = (src) => {
      return new Promise((resolve) => {
        script = document.createElement("script");
        script.src = src;
        script.className = "mmiScript";
        script.onload = () => resolve(true);
        document.head.appendChild(script);
      });
    };

    const initMap = async () => {
      const { data } = await http.get(apiUrl);
      await loadScript(
        `https://apis.mapmyindia.com/advancedmaps/v1/${data.access_token}/map_load?v=1.3`
      );

      // const centre = new window.L.LatLng(
      //   currentLocationcoords?.lat || 20.2961,
      //   currentLocationcoords?.long || 85.8245
      // );

      const newMap = new window.MapmyIndia.Map(document.getElementById("map"), {
        center: coords,
        zoom: 15,
        search: true,
        zoomControl: true,
        hybrid: true,
        location: true,
      });

      // const newMarker = new window.L.marker([centre.lat, centre.lng])

      setMap(newMap); // Ensure map is set before adding event listeners
      // setMarker(newMarker);
    };

    const initMap1 = async () => {
    console.log("Initiallllll")
      const { data } = await http.get(apiUrl);
      await loadScript(
        `https://apis.mappls.com/advancedmaps/api/${data.access_token}/map_sdk?v=3.0&layer=vector`
      );
      
      let map = new window.mappls.Map('map', {
        center: [coords.lat, coords.long],
        zoomControl: true,
        location: true,
      });
      setMap(map)


    };

    // initMap();
      initMap1()

  }, [currentLocationcoords]);

  // Only attach event listeners after the map has been initialized

  useEffect(() =>{
    if(map && !marker){
       marker =  new window.mappls.Marker({
        map: map,
        position: {
          "lat": coords.lat,
          "lng": coords.long
        },
        fitbounds: true,
        icon_url: 'https://apis.mapmyindia.com/map_v3/1.png'
      });
      convertLatLongToAddress(coords.lat, coords.long)
      // setMarker(marker1)
      map.addListener('drag', handleDrag)
      map.addListener('dragend', handleDragEnd)
    }
  },[map])

  useEffect(() =>{

  },[marker])

  // useEffect(() =>{
  //   if(map && marker){
  //   }

  //   // return () =>{
  //   //   marker.removeEventListener('dragend', handleMapDragEnd)
  //   // }
  // },[map])


  const handleDrag = (event) =>{
   const coord = map.getCenter();
   marker.setPosition({lat:coord.lat,lng:coord.lng});
  }

  const handleDragEnd = () =>{
    const coord = marker.getPosition()
    convertLatLongToAddress(coord.lat, coord.lng)
  }

  // useEffect(() => {
  //   if (map) {
  //     map.on("dragend", handleMapDragEnd);
  //     map.on("dragover", handleMapDragOver);
  //   }
  //   return () => {
  //     if (map) {
  //       map.off("dragend", handleMapDragEnd);
  //       map.off("dragover", handleMapDragOver);
  //     }
  //   };
  // }, [map]);

  

  const handleMapDragOver = () => {
    if (map) {
      const center = map.getCenter();
      const lat = center.lat;
      const lng = center.lng;
      marker.setLatLng([lat, lng]);
    }
  };

  // const handleMapDragEnd = () => {
  //   if (map) {
  //     const center = marker.getPosition();
  //     const lat = center.lat;
  //     const lng = center.lng;
  //     console.log(`Map dragged to coordinates: ${lat}, ${lng}`);
  //     new window.L.marker([lat, lng])
  //     handleLocationChange(lat, lng);
  //   }
  // };

  const convertLatLongToAddress = async (lat, lng) => {
    var apiUrl = "MMI/accesstoken";
    const response = await http.get(apiUrl);
    if (response.data?.access_token) {
      const data = await http.get(
        `https://apis.mapmyindia.com/advancedmaps/v1/${response.data?.access_token}/rev_geocode?lat=${lat}&lng=${lng}`
      );
      if (data.data?.results?.length) {
        getAddressFromMap(data.data.results[0]);
      }
    }
  };

  return <div id="map" className="w-full mapContainer" style={{ height: "310px", zIndex: 50, width: "100%" }}></div>;
}

export default Mapp;