export function setSessionStorageWithExpiry(key, value, expiryInMinutes = 30) {
    const now = new Date();
    const expiryTime = now.getTime() + expiryInMinutes * 60 * 1000; // Convert minutes to milliseconds

    const item = {
        value: value,
        expiry: expiryTime
    };

    sessionStorage.setItem(key, JSON.stringify(item));
}

// Get an item from sessionStorage, checking if it has expired
export function getSessionStorageWithExpiry(key) {
    const itemStr = sessionStorage.getItem(key);

    // If the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // Compare the current time with the expiry time
    if (now.getTime() > item.expiry) {
        // Item has expired, remove it from storage and return null
        sessionStorage.removeItem(key);
        return null;
    }

    // Item is still valid, return the stored value
    return item.value;
}
