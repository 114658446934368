import Whychoose from "../WhyChoose"
const WhyCurebay = () =>{
    return(
        <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
            Why Choose <span className="text-secondaryColor">CureBay</span>?
          </h2>
          <div className="md:mt-[5rem]">
            <Whychoose />
          </div>
        </div>
      </section>
    )
}

export default WhyCurebay