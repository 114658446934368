// import { Modal, Select } from "antd";
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import BMI from "../../../../Assets/Images/BMI.png";
// import BMI1 from "../../../../Assets/Images/BMI1.png";
// import BarChart from "../../../../Assets/Images/BarChart.png";
// import BloodPressure from "../../../../Assets/Images/Blood Pressure.png";
// import HeartRate from "../../../../Assets/Images/Heart Rate.png";
// import PulseOx from "../../../../Assets/Images/PulseOx.png";
// import RespirationRate from "../../../../Assets/Images/Respiration Rate.png";
// import Temperature from "../../../../Assets/Images/Temperature.png";
// import Vector from "../../../../Assets/Images/Vector.png";
// import Weight from "../../../../Assets/Images/Weight.png";
// import { getpatientvitaldetails } from "../../../../Redux/Action/patientAction";
// import Layouts from "../../../Layouts";
// // import AddNewVitals from "./AddNewVitals";
// import BloodPressureCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/BloodPressure";
// import TemperatureCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/Temperature";
// import BMICard from "../../../Pages/PostLogin/HealthRecords/VitalCards/BMI";
// import HeartRateCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/HeartRate";
// import PulseRateCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/PulseRate";
// import WeightCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/Weight";
// import HeightCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/Height";
// // import FamilyDropdown from "./FamilyDropDown";
// import NodataImage from "../../../../Assets/Images/Icons/nodata.svg";
// import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import AddNewVitals from "../HealthRecords/AddNewVitals";
// import FamilyDropdown from "../HealthRecords/FamilyDropDown";

// function SummaryVitals(props) {
//   const [isBPoUp, setBPoup] = useState(false);
//   const [showvitals, setvitals] = useState(false);
//   const [hederpop, sethederpop] = useState("");
//   const [find, setFind] = useState("");
//   const [isTextVisible, setIsTextVisible] = useState(false);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isAddVitals, setAddVitals] = useState(false);
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.authReducer.patientData);
//   const patientinfo = useSelector((state) => state.patientinfo);
//   const [isUpdatePopup, setUpdatePopup] = useState(false);
//   const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
//   const [EditVital, setEditvital] = useState();
//   const patientCode = useSelector((state) => state.authReducer.patientCode);
//   const [activePatient, setActivePatient] = useState(patientCode);
//   const [currUser, setCurrUser] = useState();
//   // const patientvitalsdetaillist = useSelector((state) => state);
//   // const { vitalslistData, isLoading } = patientvitalsdetaillist;

//   const history = useHistory();

//   useEffect(() => {
//     if (Object.keys(userData).length === 0) {
//       history.push("/");
//     }
//   }, []);

//   const patientvitalsdetaillist = useSelector(
//     (state) => state.patientvitalsdetail
//   );
//   // const { Patientvitalsdetailsdata, isLoading } = patientvitalsdetaillist;
//   console.log("This is Console", patientvitalsdetaillist);
//   const patientDetails = useSelector((state) => state.particularpatientdetails);

//   const hasData = currUser && Object.keys(currUser).length > 0;
//   console.log(patientCode);
//   console.log(patientvitalsdetaillist);

//   const editvitals = (e, user) => {
//     e.preventDefault();
//     setshowaddmemberpopup(true);
//     setEditvital(user);
//   };
//   const refreshVitals = () => {
//     dispatch(getpatientvitaldetails(props.patCode));
//   };

//   async function fetchAllVitals(patientcode) {
//     const res = await dispatch(getpatientvitaldetails(patientcode));
//     console.log(res);
//     setCurrUser(res[0]);
//   }
//   useEffect(() => {
//     fetchAllVitals(props.patCode);
//     setActivePatient(userData.code);
//   }, [props.patCode]);

//   const openPopup = (e) => {
//     debugger;
//     if (e === "BP") {
//       setFind(e);
//       sethederpop("Blood Pressure");
//     } else if (e === "TEMP") {
//       setFind(e);
//       sethederpop("Temparature");
//     } else if (e === "BMI") {
//       setFind(e);
//       sethederpop("BMI");
//     } else if (e === "HR") {
//       setFind(e);
//       sethederpop("HeartRate");
//     } else if (e === "BM") {
//       setFind(e);
//       sethederpop("BMI");
//     } else if (e === "PL") {
//       setFind(e);
//       sethederpop("PulseOx");
//     } else if (e === "RR") {
//       setFind(e);
//       sethederpop("RespirationRate");
//     } else if (e === "WE") {
//       setFind(e);
//       sethederpop("Weight");
//     }

//     setBPoup(true);
//   };

//   const getVitals = () => {
//     setvitals(false);
//     dispatch(getpatientvitaldetails(activePatient));
//   };

//   const loadVitalsFor = (patient) => {
//     console.log(patient);
//     setActivePatient(patient);
//     fetchAllVitals(patient);
//   };

//   const handleCancel = () => {
//     setBPoup(false);
//   };

//   const handleIconClick = () => {
//     setIsModalVisible(true);
//   };

//   const addvitals = () => {
//     setAddVitals(true);
//   };

//   const AddVitalClose = () => {
//     setAddVitals(false);
//   };

//   const UpdateVitals = () => {
//     setUpdatePopup(true);
//   };

//   const UpdateVitalsClose = () => {
//     setUpdatePopup(false);
//   };
//   return (
//     <>
//       <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
//         <div>
//           <div className="bg-White rounded-md">
//             <div className="bg-[#bee0ff] px-5 py-3 rounded-md flex justify-between items-center">
//               <h5 className=" text-lg font-medium">My Vitals</h5>
//               <p onClick={(e) => editvitals(e, "")} className=" text-sm underline cursor-pointer">Add Vitals</p>
//             </div>
//           </div>
//           {hasData ? (
//             <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5">
//               <BloodPressureCard
//                 saveCallback={getVitals}
//                 data={currUser ? currUser : ""}
//                 activePatient={activePatient}
//                 fetchAllVitals={fetchAllVitals}
//               />
//               <TemperatureCard
//                 saveCallback={getVitals}
//                 data={currUser ? currUser : ""}
//                 activePatient={activePatient}
//                 fetchAllVitals={fetchAllVitals}
//               />
//               <BMICard
//                 saveCallback={getVitals}
//                 data={currUser ? currUser : ""}
//                 activePatient={activePatient}
//                 fetchAllVitals={fetchAllVitals}
//               />
//               <HeartRateCard
//                 saveCallback={getVitals}
//                 data={currUser ? currUser : ""}
//                 activePatient={activePatient}
//                 fetchAllVitals={fetchAllVitals}
//               />
//               <PulseRateCard
//                 saveCallback={getVitals}
//                 data={currUser ? currUser : ""}
//                 activePatient={activePatient}
//                 fetchAllVitals={fetchAllVitals}
//               />
//               {/* <PulseRateCard saveCallback={getVitals} data={currUser? currUser:''} /> */}
//               <WeightCard
//                 saveCallback={getVitals}
//                 data={currUser ? currUser : ""}
//                 activePatient={activePatient}
//                 fetchAllVitals={fetchAllVitals}
//               />
//               <HeightCard
//                 saveCallback={getVitals}
//                 data={currUser ? currUser : ""}
//                 activePatient={activePatient}
//                 fetchAllVitals={fetchAllVitals}
//               />
//             </div>
//           ) : (
//             <div className="flex justify-center items-center text-center mt-5 text-gray-500">
//               <p>No Vitals available.</p>
//             </div>
//           )}
//         </div>
//       </section>

//       <Modal
//         open={isBPoUp}
//         onCancel={handleCancel}
//         footer={[]}
//         title={
//           <span style={{ color: "primaryColor", fontSize: "20px" }}>
//             {hederpop}
//           </span>
//         }
//       >
//         <div className="flex justify-start items-center">
//           {find == "BP" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "TEMP" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "BMI" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "HR" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "BM" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "PL" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "RR" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "WE" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//         </div>
//       </Modal>
//       <Modal
//         title="Add New Vitals"
//         className="commonModal"
//         open={showaddmemberpopup}
//         onOk={() => setshowaddmemberpopup(!showaddmemberpopup)}
//         width={1200}
//         onCancel={() => {
//           setshowaddmemberpopup(!showaddmemberpopup);
//           refreshVitals();
//         }}
//         style={{ top: 20 }}
//         footer={[]}
//       >
//         <AddNewVitals
//           editvitals={EditVital}
//           closePopup={() => {
//             setshowaddmemberpopup(!showaddmemberpopup);
//             refreshVitals();
//           }}
//           saveMessage={(message) => {
//             setshowaddmemberpopup(!showaddmemberpopup);
//             // toast(message);
//             refreshVitals();
//           }}
//           fetchAllVitals={fetchAllVitals}
//           patientCode={activePatient}
//         />
//       </Modal>
//       <Modal
//         open={isUpdatePopup}
//         footer={false}
//         onCancel={UpdateVitalsClose}
//         width={"350px"}
//       >
//         <div className="w-[250px]">
//           <div className="p-5 bg-white rounded-lg shadow-lg">
//             <div className="mb-4">
//               <p className="text-2xl font-medium">Enter Vitals</p>
//             </div>
//             <div className="mb-4">
//               <input
//                 autoComplete="off"
//                 id="systolic"
//                 name="systolic"
//                 type="number"
//                 value={addvitals?.systolic ?? ""}
//                 className="text-sm font-medium w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
//                 placeholder="SYS"
//               />
//             </div>
//             <div className="mb-4">
//               <input
//                 autoComplete="off"
//                 id="diastolic"
//                 name="diastolic"
//                 type="number"
//                 value={addvitals?.diastolic ?? ""}
//                 className="text-sm font-medium w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
//                 placeholder="DIA"
//               />
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// }

// export default SummaryVitals;

import { Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BMI from "../../../../Assets/Images/BMI.png";
import BMI1 from "../../../../Assets/Images/BMI1.png";
import BarChart from "../../../../Assets/Images/BarChart.png";
import BloodPressure from "../../../../Assets/Images/Blood Pressure.png";
import HeartRate from "../../../../Assets/Images/Heart Rate.png";
import PulseOx from "../../../../Assets/Images/PulseOx.png";
import RespirationRate from "../../../../Assets/Images/Respiration Rate.png";
import Temperature from "../../../../Assets/Images/Temperature.png";
import Vector from "../../../../Assets/Images/Vector.png";
import Weight from "../../../../Assets/Images/Weight.png";
import { getpatientvitaldetails } from "../../../../Redux/Action/patientAction";
import Layouts from "../../../Layouts";
// import AddNewVitals from "./AddNewVitals";
import BloodPressureCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/BloodPressure";
import TemperatureCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/Temperature";
import BMICard from "../../../Pages/PostLogin/HealthRecords/VitalCards/BMI";
import HeartRateCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/HeartRate";
import PulseRateCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/PulseRate";
import WeightCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/Weight";
import HeightCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/Height";
// import FamilyDropdown from "./FamilyDropDown";
import NodataImage from "../../../../Assets/Images/Icons/nodata.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AddNewVitals from "../HealthRecords/AddNewVitals";
import FamilyDropdown from "../HealthRecords/FamilyDropDown";

function SummaryVitals(props) {
  const [isBPoUp, setBPoup] = useState(false);
  const [showvitals, setvitals] = useState(false);
  const [hederpop, sethederpop] = useState("");
  const [find, setFind] = useState("");
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddVitals, setAddVitals] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  const patientinfo = useSelector((state) => state.patientinfo);
  const [isUpdatePopup, setUpdatePopup] = useState(false);
  const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
  const [EditVital, setEditvital] = useState();
  const patientCode = useSelector((state) => state.authReducer.patientCode);
  const [activePatient, setActivePatient] = useState(patientCode);
  const [currUser, setCurrUser] = useState();
  // const patientvitalsdetaillist = useSelector((state) => state);
  // const { vitalslistData, isLoading } = patientvitalsdetaillist;

  const history = useHistory();

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push("/", {isLoginModalOpen : true});
    }
  }, []);

  const patientvitalsdetaillist = useSelector(
    (state) => state.patientvitalsdetail
  );
  // const { Patientvitalsdetailsdata, isLoading } = patientvitalsdetaillist;
  console.log("This is Console", patientvitalsdetaillist);
  const patientDetails = useSelector((state) => state.particularpatientdetails);

  const hasData = currUser && Object.keys(currUser).length > 0;
  console.log(patientCode);
  console.log(patientvitalsdetaillist);

  const editvitals = (e, user) => {
    e.preventDefault();
    setshowaddmemberpopup(true);
    setEditvital(user);
  };
  const refreshVitals = () => {
    dispatch(getpatientvitaldetails(props.patCode));
  };

  async function fetchAllVitals(patientcode) {
    const res = await dispatch(getpatientvitaldetails(patientcode));
    console.log(res);
    setCurrUser(res[0]);
  }
  useEffect(() => {
    fetchAllVitals(props.patCode);
    setActivePatient(userData.code);
  }, [props.patCode]);

  const openPopup = (e) => {
    if (e === "BP") {
      setFind(e);
      sethederpop("Blood Pressure");
    } else if (e === "TEMP") {
      setFind(e);
      sethederpop("Temparature");
    } else if (e === "BMI") {
      setFind(e);
      sethederpop("BMI");
    } else if (e === "HR") {
      setFind(e);
      sethederpop("HeartRate");
    } else if (e === "BM") {
      setFind(e);
      sethederpop("BMI");
    } else if (e === "PL") {
      setFind(e);
      sethederpop("PulseOx");
    } else if (e === "RR") {
      setFind(e);
      sethederpop("RespirationRate");
    } else if (e === "WE") {
      setFind(e);
      sethederpop("Weight");
    }

    setBPoup(true);
  };

  const getVitals = () => {
    setvitals(false);
    dispatch(getpatientvitaldetails(activePatient));
  };

  const loadVitalsFor = (patient) => {
    console.log(patient);
    setActivePatient(patient);
    fetchAllVitals(patient);
  };

  const handleCancel = () => {
    setBPoup(false);
  };

  const handleIconClick = () => {
    setIsModalVisible(true);
  };

  const addvitals = () => {
    setAddVitals(true);
  };

  const AddVitalClose = () => {
    setAddVitals(false);
  };

  const UpdateVitals = () => {
    setUpdatePopup(true);
  };

  const UpdateVitalsClose = () => {
    setUpdatePopup(false);
  };
  return (
    <>
      <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
        <div>
          <div className="bg-White rounded-md">
            <div className="bg-[#bee0ff] px-5 py-3 rounded-md flex justify-between items-center">
              <h5 className=" text-lg font-medium">My Vitals</h5>
              <p onClick={(e) => editvitals(e, "")} className=" text-sm underline cursor-pointer">Add Vitals</p>
            </div>
          </div>
          {hasData ? (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5">
              <BloodPressureCard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
              <TemperatureCard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
              <BMICard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
              <HeartRateCard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
              <PulseRateCard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
              {/* <PulseRateCard saveCallback={getVitals} data={currUser? currUser:''} /> */}
              <WeightCard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
              <HeightCard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
            </div>
          ) : (
            <div className="flex justify-center items-center text-center mt-5 text-gray-500">
              <p>No Vitals available.</p>
            </div>
          )}
        </div>
      </section>

      <Modal
        open={isBPoUp}
        onCancel={handleCancel}
        footer={[]}
        title={
          <span style={{ color: "primaryColor", fontSize: "20px" }}>
            {hederpop}
          </span>
        }
      >
        <div className="flex justify-start items-center">
          {find == "BP" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} 
            />
          )}
          {find == "TEMP" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} 
            />
          )}
          {find == "BMI" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
          {find == "HR" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
          {find == "BM" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
          {find == "PL" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
          {find == "RR" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
          {find == "WE" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
        </div>
      </Modal>
      <Modal
        title="Add New Vitals"
        className="commonModal"
        open={showaddmemberpopup}
        onOk={() => setshowaddmemberpopup(!showaddmemberpopup)}
        width={1000}
        onCancel={() => {
          setshowaddmemberpopup(!showaddmemberpopup);
          refreshVitals();
        }}
        style={{ top: 20 }}
        footer={[]}
      >
        <AddNewVitals
          editvitals={EditVital}
          closePopup={() => {
            setshowaddmemberpopup(!showaddmemberpopup);
            refreshVitals();
          }}
          saveMessage={(message) => {
            setshowaddmemberpopup(!showaddmemberpopup);
            // toast(message);
            refreshVitals();
          }}
          fetchAllVitals={fetchAllVitals}
          patientCode={activePatient}
        />
      </Modal>
      <Modal
        open={isUpdatePopup}
        footer={false}
        onCancel={UpdateVitalsClose}
        width={"350px"}
      >
        <div className="w-[250px]">
          <div className="p-5 bg-white rounded-lg shadow-lg">
            <div className="mb-4">
              <p className="text-2xl font-medium">Enter Vitals</p>
            </div>
            <div className="mb-4">
              <input
                autoComplete="off"
                id="systolic"
                name="systolic"
                type="number"
                value={addvitals?.systolic ?? ""}
                className="text-sm font-medium w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                placeholder="SYS"
              />
            </div>
            <div className="mb-4">
              <input
                autoComplete="off"
                id="diastolic"
                name="diastolic"
                type="number"
                value={addvitals?.diastolic ?? ""}
                className="text-sm font-medium w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                placeholder="DIA"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SummaryVitals;
