import { useDispatch, useSelector } from 'react-redux';
// import more from '../../Assets/Images/More .svg';
// import hearts from '../../Assets/Images/heart.svg';
// import analytics from '../../Assets/Images/analytics.png';
// import { Bar } from 'react-chartjs-2'
// import icon from '../../Assets/Images/Icon 24px.png';
import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
import VitalChart from '../VitalChart';
import Weight from "../../../../../Assets/Images/Weight.png"
import { FiMoreVertical } from 'react-icons/fi';
import Vector from "../../../../../Assets/Images/Vector.png";
// import EditVitals from './EditVitals';
import { Dropdown, Modal, Space } from 'antd';
import { addPatientvitals } from '../../../../../Redux/Action/userprofileVitalActions';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';

function Weightcard(props) {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

const [weight,setWeight] = useState()
    const [showviewall, setshowviewall] = useState(false);
    const [showentervitals, setshowentervitals] = useState(false);
    const [showremovevitals, setshowremovevitals] = useState(false);
    const [data, setData] = useState()
    const [graphPop, setGraphPop] = useState(false);

    const viewDetails = (event, det) => {
        event.preventDefault();
        console.log(det)
        setData(det)
        setshowviewall(true)
        setshowentervitals(false)
        setshowremovevitals(false)
    }

    const savevitals = () => {
        if (weight) {
            const bmi = (
                weight /
                ((props.data.height / 100) * (props.data.height / 100))
              ).toFixed(2);

            let newVitals = { ...props.data, weight,bmi };
            newVitals.createdDate = moment().format("yyyy-MM-DD HH:mm:ss");
            newVitals.updatedDate = moment().format("yyyy-MM-DD HH:mm:ss");
            newVitals.givenDate = moment().format("yyyy-MM-DD HH:mm:ss");
            newVitals.patientCode = props?.data?.patientCode;
            newVitals.createdBy = props?.data?.createdBy;
            newVitals.updatedBy = props?.data?.updatedBy;
            dispatch(addPatientvitals(newVitals)).then(res => {
                saveSuccess();
                props.fetchAllVitals(props.activePatient)
                setWeight('');
                setTimeout(() => {
                    toast('Vitals updated successfully');

                }, 300);
            }).catch(err => {
                console.log("Error");
            })
        }
    }


    const addvitals = (event, det) => {
        event.preventDefault();
        console.log(det)
        setData(det)
        setshowviewall(false)
        setshowremovevitals(false)
        setshowentervitals(true)


    }


    const removevitals = (event, det) => {
        event.preventDefault();
        console.log(det)
        setData(det)
        setshowviewall(false)
        setshowentervitals(false)
        setshowremovevitals(true)

    }


    const saveSuccess = (e) => {
        console.log("save success");
        setshowentervitals(false);
        props.saveCallback(e);
    };

    const items = [
        {
            label: <p className='relative' onClick={addvitals}>Update</p>,
            key: 0,
        }]

    return (
        <>
            {
                // props?.data?.slice(0, 1).map((user, i) => (
                <>

                    <div className="w-full bg-White p-5 rounded-md">
                    <ToastContainer />
                        <div className="flex gap-5">
                            <div className="">
                                <img src={Weight} alt="images" />
                            </div>
                            <div className="w-full ">
                                <div className="w-full flex justify-between items-center">
                                    <h5 className="text-primaryColor text-lg font-medium">
                                        Weight
                                    </h5>
                                    <div className="flex gap-3 items-center">
                                        <div
                                            onClick={() => setGraphPop(true)}
                                            className="flex cursor-pointer"
                                        >
                                            <img src={Vector} alt="images" />
                                        </div>
                                        <div className="relative inline-block group">
                                            <Dropdown
                                                menu={{
                                                    items,
                                                }}
                                                trigger={['click']}
                                                placement='bottomRight'
                                            >
                                                <Space>
                                                    <FiMoreVertical />
                                                </Space>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-5 mt-4">
                                    <p className="text-textGray font-light text-xs ">
                                        <span className="text-lg text-secondaryColor font-medium">
                                            {props.data.weight}
                                        </span>{" "}
                                        kg
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        showentervitals ?
                            // <EditVitals data={props.data[0]} title={'Weight'} closePopup={() => saveSuccess()} />
                            <div className='absolute inset-0'>
                                <Modal
                                    centered
                                    open={showentervitals}
                                    width={"25%"}
                                    footer={[]}
                                    onCancel={saveSuccess}
                                >
                                    <div>
                                        <p className="text-xl font-semibold">
                                            Weight
                                        </p>
                                        <div className="h-[5rem] pt-6">
                                            <div className="flex justify-between">
                                                <input
                                                    autoComplete="off"
                                                    id="weight"
                                                    name="weight"
                                                    type="number"
                                                    value={weight ?? ""}
                                                    class=" text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                    placeholder="Kg"
                                                onChange={(e) => setWeight(e.target.value)}
                                                />

                                                <label
                                                    htmlFor="email"
                                                    className=" text-xs font-normal text-gray-secondary"
                                                >
                                                    Kg{" "}
                                                </label>
                                            </div>
                                            <hr className="bg-gray-primary" />
                                            <div className="flex justify-end my-3">
                                                <button
                                                    onClick={savevitals}
                                                    className="bg-primaryColor  text-White py-1 px-4 font-medium rounded-xl mt-4"
                                                >
                                                    Update{" "}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                            : null
                    }
                    {
                        graphPop ? <VitalChart title={'Weight Graph'} data={[props.data]} type="weight" closePopup={() => setGraphPop(false)} /> : null
                    }
                </>
                // ))
            }
        </>
    );
}

export default Weightcard;