import React, { useState, useEffect, useRef } from "react";
import MicIcon from "@mui/icons-material/Mic";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import "./caresathi.css";
import http from '../../../Redux/Services/http-common'
import { Spin } from 'antd';
import {Dropdown } from 'antd';
// Utility function to convert Blob to Base64
const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      // Extract Base64 string without the prefix
      const base64String = reader.result.split(',')[1]; // Split the result by comma and take the second part
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};

// const blobToBase64 = (blob) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onloadend = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//     reader.readAsDataURL(blob);
//   });
// };

const MicRecorder = ({getQuestionFromSpeechToText,sendError, selectedLanguage}) => {
  const [isSpeechToTextLoading, setIsSpeechToTextLoading] = useState(false)
  const [isRecording, setIsRecording] = useState(false);
  const [audioBase64, setAudioBase64] = useState("");
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [dataArray, setDataArray] = useState(new Uint8Array(64));
  const [permissionStatus, setPermissionStatus] = useState('unknown');
  const canvasRef = useRef(null);
  const [hasMicroPhoneAccess, setHasMicroPhoneAccess] = useState(false)
  const offsetRef = useRef(0); // Using useRef to track offset
  const animationFrameRef = useRef(null);
  const [recordedUrl, setRecordedUrl] = useState('');
  // const [selectedLanguage, setSelectedLanguage] = useState("en")
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const selectedLanguageRef = useRef(null)
  const chunks = useRef([]);

  useEffect(() => {
    const checkMicroPhonePermission = async () => {
      try {
        const permissionResult = await navigator.permissions.query({name: "microphone"});
        setPermissionStatus(permissionResult.state);

        if (permissionResult.state === 'granted') {
          try {
            setHasMicroPhoneAccess(true);
          } catch (error) {
            console.error('Error accessing camera:', error);
            setHasMicroPhoneAccess(false);
          }
        } else if (permissionResult.state === 'denied') {
          setHasMicroPhoneAccess(false);
        } else if (permissionResult.state === 'prompt') {
          setHasMicroPhoneAccess(true);
        }

        permissionResult.onchange = () => {
          setPermissionStatus(permissionResult.state);
          if (permissionResult.state === 'granted') {
            setHasMicroPhoneAccess(true);
          } else {
            setHasMicroPhoneAccess(false);
          }
        };
      } catch (error) {
        console.error('Permission API or getUserMedia is not supported:', error);
      }
    };

    checkMicroPhonePermission();
  }, []);

  useEffect(() =>{
    if(permissionStatus == 'denied' || (permissionStatus == 'prompt' && !hasMicroPhoneAccess) ){
      alert("Microphone access is required. Please enable it in your browser or phone settings.")
      return;
  }
  },[permissionStatus, hasMicroPhoneAccess])

  useEffect(() => {
    if (isRecording && analyser) {
      const drawWaveform = () => {
        analyser.getByteTimeDomainData(dataArray);

        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext("2d");
        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

        // Create a gradient for the waveform
        const gradient = canvasCtx.createLinearGradient(0, 0, canvas.width, 0);
        // gradient.addColorStop(0, '#ff4b1f');
        // gradient.addColorStop(0.5, '#ff9068');
        gradient.addColorStop(1, "#005D8E");

        canvasCtx.fillStyle = gradient;

        const barWidth = canvas.width / dataArray.length;
        let barHeight;
        let x = offsetRef.current; // Start drawing from the current offset

        for (let i = 0; i < dataArray.length; i++) {
          // Apply a power factor to amplify low sounds more effectively
          const normalizedValue = dataArray[i] / 255;
          const amplifiedValue = Math.pow(normalizedValue, 0.5); // Use square root for sensitivity
          barHeight = amplifiedValue * canvas.height; // Scale height to canvas height
          // console.log(normalizedValue, "sdvklsdudsouhdsvdsvdsdsvds")
          // Draw a rectangle centered vertically, extending from the center up and down
          //   console.log(amplifiedValue, "sdvlkdsdsvbsdiuvdgsuivds")
          if (amplifiedValue < 0.62) {
            barHeight = 16;
          } else if (amplifiedValue < 0.72) {
            barHeight = 8;
          }
          canvasCtx.fillRect(
            x,
            (canvas.height - barHeight) / 2,
            barWidth - 2,
            barHeight
          );

          x += barWidth;
          if (x >= canvas.width) x -= canvas.width; // Wrap around when reaching canvas edge
        }

        offsetRef.current = (offsetRef.current + 1) % canvas.width; // Increment offset for horizontal movement

        animationFrameRef.current = requestAnimationFrame(drawWaveform);

        // for (let i = 0; i < dataArray.length; i++) {
        //     // Normalize the value to be between 0 and 1
        //     const normalizedValue = dataArray[i] / 255;

        //     // Determine the bar height based on the sound level
        //     if (normalizedValue < 0.1) {
        //       // Very low sound, set minimum bar height
        //       barHeight = 2;
        //     } else if (normalizedValue >= 0.1 && normalizedValue < 0.7) {
        //       // Normal sound, scale height between 20px to 25px
        //       barHeight = 20 + (normalizedValue - 0.1) * (25 - 20) / (0.7 - 0.1);
        //     } else {
        //       // High sound, scale height up to 30px
        //     //   barHeight = 25 + (normalizedValue - 0.7) * (30 - 25) / (1 - 0.7);
        //     barHeight = 2;
        //     }

        //     // Draw a rectangle centered vertically, extending from the center up and down
        //     canvasCtx.fillRect(x, (canvas.height - barHeight) / 2, barWidth - 2, barHeight);

        //     x += barWidth;
        //     if (x >= canvas.width) x -= canvas.width; // Wrap around when reaching canvas edge
        //   }

        //   offsetRef.current = (offsetRef.current - 1) % canvas.width; // Increment offset for horizontal movement

        //   animationFrameRef.current = requestAnimationFrame(drawWaveform);
      };

      drawWaveform(); // Start the drawing loop

      return () => {
        // Cleanup on component unmount or when stopping recording
        if (audioContext) {
          audioContext.close();
        }
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
        }
      };
    }
  }, [isRecording, analyser, dataArray]);


  const handleMicClick = async (recordingStatus) => {
      try {
        selectedLanguageRef.current = selectedLanguage
        const stream = await navigator.mediaDevices.getUserMedia(
          { audio: true }
        );
        const audioCtx = new (window.AudioContext ||
          window.webkitAudioContext)();
        const analyserNode = audioCtx.createAnalyser();
        setHasMicroPhoneAccess(true);
        analyserNode.fftSize = 128;
        setAudioContext(audioCtx);
        setAnalyser(analyserNode);
        const source = audioCtx.createMediaStreamSource(stream);
        source.connect(analyserNode);
        mediaStream.current = stream;
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = (e) => {
          if (e.data.size > 0) {
            chunks.current.push(e.data);
          }
        };
        mediaRecorder.current.onstop = async () => {
          const recordedBlob = new Blob(
            chunks.current, { type: 'audio/webm' }
          );
          const url = URL.createObjectURL(recordedBlob);
          const base64String = await blobToBase64(recordedBlob);
          handleSpeechToText(base64String)
          console.log(base64String, "sdvoisdhvuodsuvsdvds")
          setRecordedUrl(url);
          chunks.current = [];
        };
        mediaRecorder.current.onpause = async () => {
          
          chunks.current = [];
        };
        mediaRecorder.current.start();
        setIsRecording(true)
      } catch (error) {
        setHasMicroPhoneAccess(false)
        console.error('Error accessing microphone:', error);
      }
    }

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
    setIsSpeechToTextLoading(true)
      setIsRecording(false)
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  const cancelRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      setIsRecording(false);
      mediaRecorder.current.pause();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  const generateItems = (arrayReference, type, text, language) =>  [
    {
      label: 'English',
      key: 'en',
      onClick: () => onClickLanguageDropdown('en'),
    },
    {
      label: 'Odia',
      key: 'or',
      onClick: () => onClickLanguageDropdown('or'),
    },
    {
      label: 'Hindi',
      key: 'hi',
      onClick: () => onClickLanguageDropdown('hi'),
    },
  ];
  

  const handleSpeechToText = async (text) =>{
    try{
      let payload = {
        src_lang: selectedLanguageRef.current, 
        audio_file : text
      }
      let response = await http.post(`${process.env.REACT_APP_CARE_SATHI}translation/speech-to-text` ,payload )
      console.log(response, "Sdvodshvousdguvdsvds")
    setIsSpeechToTextLoading(false)
      if(response.data.length){
        if(response.data[1]?.success){
          getQuestionFromSpeechToText(response.data[1].display_text);
          return;
        }
        if(response.data[1]?.status !== 200){
          sendError(response.data[1].message)
          return
        }
        
      }
    }catch(error){
      console.log(error, "dslvdsouvhudsvdsvds")
      setIsSpeechToTextLoading(false)
    }
   
  }


  const onClickLanguageDropdown = async (language) =>{
    // setSelectedLanguage(language)
   handleMicClick()

  }

  const getDropdownTrigger = () => {
    const isMobile = window.innerWidth <= 768; // Define mobile width threshold
    return isMobile ? ['click'] : ['hover'];   // Use 'click' for mobile, 'hover' for desktop
  };

  return (
    <div className="flex">
      {isSpeechToTextLoading ? <div className="flex items-center pr-2" ><Spin /></div> :  isRecording && <div className="waveform-container">
        <button onClick={cancelRecording}>
          <CloseIcon
            fontSize="14"
            color="white"
            className="close-icon-button"
          />
        </button>
        <canvas
          ref={canvasRef}
          width="100"
          height="30"
          className="waveform-canvas"
        />
        <button onClick={stopRecording}>
          <DoneIcon fontSize="14" className="done-icon-button" />
        </button>
      </div>}
     {!isRecording && !isSpeechToTextLoading && <div className="mic-recorder mr-1 sm:mr-2">
      {/* <Dropdown
    menu={{
      items: generateItems(""),
      onClickLanguageDropdown ,
    }}
    trigger={getDropdownTrigger()}
    placement="top"
    arrow
  > */}
    <button
            className={`mic-button ${isRecording ? "recording" : ""}`}
            onClick={handleMicClick}
          >
            <MicIcon />
          </button>
          {/* </Dropdown> */}
      </div>}
    </div>
  );
};

export default MicRecorder;