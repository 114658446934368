import React, { useState } from "react";
import Layouts from "../Layouts";
import Clockimage from "../../Assets/Images/Icons/clock.svg";
import Calendarimage from "../../Assets/Images/Icons/calendar.svg";
import Backarrow from "../../Assets/Images/Icons/backarrow.svg";
import HoverBackarrow from "../../Assets/Images/Icons/backarrow-white.svg";
import { useHistory } from "react-router-dom";
import Blog6 from "../../Assets/Images/Blog/Blog6.jpg"

function BlogDetails6() {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  const handleNavigation = (path) => {
    history.push(path);
  };
  return (
    <Layouts>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px] md:w-[80%]">
          <div
            className="text-primaryColor hover:bg-primaryColor hover:text-White py-2 px-5 w-fit rounded-full mx-auto text-lg font-medium cursor-pointer flex gap-3 justify-center items-center mb-10"
            onClick={() => handleNavigation("/blogs")}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img src={isHovered ? HoverBackarrow : Backarrow} alt="images" />
            Back to blogs
          </div>
          <div>
            <h2 className="md:w-[60%] mx-auto text-center text-2xl md:text-4xl font-bold text-primaryColor !leading-normal mb-5">
              <span className="text-secondaryColor">
                The Importance of Vitamin D:
              </span>{" "}
              How to Safely Get Your Sun Exposure
            </h2>
            <p className="md:w-[50%] mx-auto text-center text-textGray ">
              We live in a country blessed with abundant sunshine, yet a
              majority of our population suffers from Vitamin D deficiency, also
              referred to as ‘the sunshine vitamin’.
            </p>
            <div className="flex gap-4 justify-center mt-5">
              <div className="flex items-center gap-2">
                <img src={Clockimage} alt="images" />
                <p className="text-textGray text-sm ">6 minute read</p>
              </div>
              <div className="flex items-center gap-2">
                <img src={Calendarimage} alt="images" />
                <p className="text-textGray text-sm ">10th February, 2024</p>
              </div>
            </div>
          </div>
          <div className="w-full md:h-[500px] my-5 md:my-10">
            <img
              src={Blog6}
              alt="images"
              className="w-full  h-full object-cover rounded-lg"
            />
          </div>
          <div className="bg-White rounded-xl p-5 md:p-10">
            <h6 className=" text-primaryColor text-xl font-semibold my-5">
              Introduction:
            </h6>
            <p className="text-textGray mb-4 md:leading-loose">
              We live in a country blessed with abundant sunshine, yet a
              majority of our population suffers from Vitamin D deficiency, also
              referred to as ‘the sunshine vitamin’. The primary source to gain
              Vitamin D is sunlight - exposure to which is essential for bone
              health, immune function and mood regulation. It also plays a
              crucial role in calcium absorption and has been linked to reducing
              the risk of heart disease, diabetes and certain types of cancer.
            </p>
            <p className="text-textGray md:leading-loose  mb-4">
              However, it is also important to balance the amount of sun we soak
              to prevent skin damage. In this article, we will share practical
              tips to safely get your dose of Vitamin D from the sun while
              maintaining skin health.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Maximise Morning Sunlight:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Aim to get sun exposure early morning, before 10 am, when sunlight
              is less intense, and UVB rays are more conducive to Vitamin D
              synthesis. Time your sun exposure to not more than 20-25 minutes
              daily. This will reduce the risk of sunburn and skin damage in the
              long run.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Cover Up Smartly:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Direct sun exposure can do more harm than good. Wear lightweight
              and long-sleeved clothes and sunglasses that are comfortable as
              well as protective when you plan to be outdoors for a long time.
              This will shield your skin from harmful UV rays while allowing you
              to get the benefits of sun exposure.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Apply Sunscreen Wisely:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Never step out of your house without applying sunscreen, but don’t
              overuse it either. Remember, balance is key. Choose a sunscreen
              with moderate SPF and apply it 20 minutes before going out in the
              sun, especially on the face and neck. Make this a regular part of
              your routine to protect your skin from sunburn, tans, pigmentation
              and even skin cancer.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Gradually Increase Sun Exposure:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              If you have had limited exposure to the sun for a while now, it is
              best to start by spending a short amount of time in the sun,
              around 5 to 10 minutes. This will allow your body time to build up
              sun tolerance. By doing this, your skin will thank you in the
              future.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Use Supplements When Necessary:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              In case of limited sun exposure due to lifestyle factors or
              persistent Vitamin D deficiency despite sun exposure, we recommend
              consulting our healthcare professional to assess whether you need
              to consider Vitamin D supplements.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Conclusion:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Incorporate these simple tips in your daily life to reap the
              benefits of the sun, increase your Vitamin D intake and safeguard
              your skin from damage. Remember, spending an extended time in the
              sun will not compensate for all the Vitamin D loss in a single
              day. It is a gradual process and requires consistency. It is all
              about striking the right balance between soaking up the sunshine
              and protecting your skin.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
            <span className="text-secondaryColor">Recommended</span> Reads
          </h2>
          <div className="grid md:grid-cols-2 gap-5">
            <div
              className="bg-White common-shadow rounded-xl p-5 cursor-pointer w-[80%] mx-auto"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div className="w-full mb-3">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog1.jpg"
                  alt="images"
                  className="w-full"
                />
              </div>
              <h6 className="text-primaryColor font-semibold text-lg mb-2">
                Beat the Heat: 10 Tips for Staying Cool and Hydrated This Summer
              </h6>
              <p className="text-textGray text-sm mb-4">
                As temperatures rise and the sun shines brighter, it's essential
                to prioritize staying cool and hydrated to beat the summer heat.
              </p>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
            <div
              className="bg-White common-shadow rounded-xl p-5 cursor-pointer w-[80%] mx-auto"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div className="w-full mb-3">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog3.jpg"
                  alt="images"
                  className="w-full"
                />
              </div>
              <h6 className="text-primaryColor font-semibold text-lg mb-2">
                10 Tips for Managing Chronic Conditions with Telehealth
              </h6>
              <p className="text-textGray text-sm mb-4">
                In recent years, telehealth has emerged as a groundbreaking
                solution for managing chronic health conditions, offering
                convenience, accessibility, and personalized care from the
                comfort of one's home.
              </p>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default BlogDetails6;
