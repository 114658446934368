import React, { useState, useEffect } from 'react';
import { Modal, Button, List, Spin, Typography, message } from 'antd';
import './PincodeModal.css'; // Optional: For custom styles

const { Text } = Typography;

const PincodeModal = ({ visible, onClose, onSelectAddress, pincode }) => {
    console.log(pincode,"xdfcgvhbj")
  const [loading, setLoading] = useState(false);
  const [addressDetails, setAddressDetails] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    if (visible) {
      fetchAddressDetails();
    }
  }, [visible]);

  const fetchAddressDetails = async () => {
    setLoading(true);
    setNoData(false);

    try {
      const response = await fetch(`${process.env.REACT_APP_NEWBASEURL}getAllAddressDetails/list?pincode=${pincode}`);
      const data = await response.json();
      if (data.status === 200 && data.data) {
        setAddressDetails(data.data);
        if (data.data.length === 1) {
          setSelectedAddress(data.data[0]);
          onSelectAddress(data.data[0]); // Automatically select
          onClose(); // Automatically close the modal
        }
      } else {
        setNoData(true);
      }
    } catch (error) {
      message.error('An error occurred while fetching address details');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
  };

  const handleConfirm = () => {
    if (selectedAddress) {
      onSelectAddress(selectedAddress);
      onClose();
    } else {
      message.warning('Please select an address');
    }
  };

  return (
    <Modal
      visible={visible}
      title="Select Address"
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          disabled={!selectedAddress}
          onClick={handleConfirm}
        >
          Confirm
        </Button>,
      ]}
    >
      {loading ? (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      ) : noData ? (
        <Text type="secondary">No data found</Text>
      ) : (
        <List
          dataSource={addressDetails}
          renderItem={(item) => (
            <List.Item
              onClick={() => handleSelectAddress(item)}
              className={`address-item ${
                selectedAddress?.area === item.area ? 'selected' : ''
              }`}
            >
              <Text>{`${item.area}, ${item.block}, ${item.district}, ${item.state}`}</Text>
            </List.Item>
          )}
          className="address-list"
        />
      )}
    </Modal>
  );
};

export default PincodeModal;
