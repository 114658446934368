import React, { useState } from "react";
import { Tabs } from "antd";
import DoctorConsultationImage from "../../Assets/Images/Icons/doctor-white.svg";
import PharmacyImage from "../../Assets/Images/Icons/pharmacy-white.svg";
import DiagnosticsImage from "../../Assets/Images/Icons/diagnostice-white.svg";
import SurgeryImage from "../../Assets/Images/Icons/surgery-white.svg";
import ConciergeImage from "../../Assets/Images/Icons/concierge-white.svg";
import DoctorConsultationImageWhite from "../../Assets/Images/Icons/doctor.svg";
import PharmacyImageWhite from "../../Assets/Images/Icons/pharmacy.svg";
import DiagnosticsImageWhite from "../../Assets/Images/Icons/diagnostics.svg";
import SurgeryImageWhite from "../../Assets/Images/Icons/surgery.svg";
import ConciergeImageWhite from "../../Assets/Images/Icons/concierge.svg";
import Linkarrow from "../../Assets/Images/Icons/linkarrow.svg";
import PrevArrow from "../../Assets/Images/left_arrow.png";
import NextArrow from "../../Assets/Images/right_arrow.png";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

function TabContent({ title, content, image, imagePng, LinkContent, linkTo }) {
  const history = useHistory();

  const handleNavigation = (path) => {
    history.push(path);
    window.scrollTo(0, 0);
  };
  return (
    <div className="bg-primaryColor p-10 rounded-xl h-full ">
      <div className="w-[45%] h-full flex justify-between flex-col">
        <div>
          <h6 className="text-2xl font-bold text-White leading-normal mb-3">
            {title}
          </h6>
          <p className="text-White text-base font-extralight">{content}</p>
        </div>
        <div
          onClick={() => handleNavigation(linkTo)}
          className="group flex items-center text-base hover:bg-White bg-secondaryColor w-fit rounded p-3 hover:text-secondaryColor text-White gap-3 cursor-pointer"
        >
          {LinkContent}{" "}
          <img
            src={Linkarrow}
            alt="images"
            className="brightness-200 group-hover:brightness-100"
          />
        </div>
      </div>
      <div className="w-[45%] absolute top-[-3rem] right-[2rem] mx-auto flex justify-center">
        <img src={imagePng} alt="images" />
      </div>
    </div>
  );
}
function TabContentOne({ title, content, image, imagePng, LinkContent, linkTo }) {
  const history = useHistory();

  const handleNavigation = (path) => {
    history.push(path);
    window.scrollTo(0, 0);
  };
  return (
    <div className="bg-primaryColor p-5 md:p-10 rounded-xl h-full relative">
      <div className="md:w-[50%] h-full flex justify-between flex-col">
        <div>
          <h6 className="text-xl md:text-2xl font-bold text-White leading-normal mb-3">
            {title}
          </h6>
          <p className="text-White text-sm md:text-base font-extralight md:leading-loose">
            {content}
          </p>
        </div>
        <div
          onClick={() => handleNavigation(linkTo)}
          className="flex items-center text-sm md:text-base text-secondaryColor gap-3 cursor-pointer mt-5 md:mt-0">
          {LinkContent} <img src={Linkarrow} alt="images" />
        </div>
      </div>
      <div className="md:absolute md:top-[-3rem] md:right-[2rem] mt-5 h-[200px]">
        <img src={imagePng} alt="images" className="h-full w-full object-cover rounded-lg"/>
      </div>
    </div>
  );
}

function Whychoose() {
  const [activeTab, setActiveTab] = useState("1");

  const onChange = (key) => {
    setActiveTab(key);
  };

  const tabData = [
    {
      key: "1",
      label: "Doctor Consultation",
      image: DoctorConsultationImage,
      imageActive: DoctorConsultationImageWhite,
      imagePng: "https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/doctor.png",
      LinkContent: "Consult a Doctor",
      linkTo: "/services/book-an-appointment",
      content:
        "Experience expert medical advice at your doorstep through our online doctor consultation, ensuring timely and accessible healthcare support.",
    },
    {
      key: "2",
      label: "Pharmacy",
      image: PharmacyImage,
      imageActive: PharmacyImageWhite,
      imagePng: "https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/pharmacy.png",
      LinkContent: "Order Medicines",
      linkTo: "/services/order-medicines",
      content:
        "Get genuine medicines at affordable prices, with easy and convenient doorstep delivery of your pharmaceutical products. Trust us for all your pharmaceutical needs, delivering right to your doorstep.",
      // "Get genuine medications at an affordable price, ensuring hassle-free and convenient delivery to your doorstep for the pharmaceutical care you need.",
    },
    {
      key: "3",
      label: "Diagnostics",
      image: DiagnosticsImage,
      imageActive: DiagnosticsImageWhite,
      imagePng: "https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/diagnostics.png",
      LinkContent: "Book a Lab Test",
      linkTo: "/services/lab",
      content:
        "Access state-of-the-art diagnostic services through our extensive range of NABL Accredited fully automated labs, ensuring accurate and swift results.",
    },
    {
      key: "4",
      label: "Surgery & Hospitalization",
      image: SurgeryImage,
      imageActive: SurgeryImageWhite,
      imagePng: "https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/surgery.png",
      LinkContent: "Consult for Surgery",
      linkTo: "/services/surgery",
      content:
        "Experience seamless coordination for surgeries and hospitalization, with our renowned partner Doctors & Hospitals ensuring quality care tailored to your needs.",
      // "Experience seamless coordination for surgeries and hospitalization, with our network of healthcare providers ensuring quality care tailored to your needs.",
    },
    {
      key: "5",
      label: "Concierge Services",
      image: ConciergeImage,
      imageActive: ConciergeImageWhite,
      imagePng: "https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/concierge.png",
      LinkContent: "Check Out",
      linkTo: "/services/concierge",
      content:
        "CureBay’s Concierge Service ensures complete support from admission to discharge, offering assistance in medical procedures, providing emotional support, and continual companionship.",
      // "CureBay’s Concierge Service ensures complete support from admission to discharge, offering assistance in medical procedures, emotional support, and continual companionship.",
    },
  ];

  const tab = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        healths: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        healths: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="hidden md:block">
        <Tabs
          className="homeTab"
          defaultActiveKey="1"
          activeKey={activeTab}
          onChange={onChange}
          tabPosition="left"
        >
          {tabData.map((tab) => (
            <Tabs.TabPane
              className="h-full"
              key={tab.key}
              tab={
                <div className="flex gap-4 items-center">
                  <div className="w-[40px] h-[40px] rounded-full tabimg flex justify-center items-center">
                    <img
                      className={activeTab === tab.key ? "active" : "inactive"}
                      src={activeTab === tab.key ? tab.imageActive : tab.image}
                      alt={tab.label}
                    />
                  </div>
                  {tab.label}
                </div>
              }
            >
              <TabContent
                title={tab.label}
                content={tab.content}
                image={tab.image}
                imagePng={tab.imagePng}
                LinkContent={tab.LinkContent}
                linkTo={tab.linkTo}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
      <div className="block md:hidden">
        <Slider {...tab}>
          {tabData.map((tab) => (
            <div className="h-full" key={tab.key}>
              <TabContentOne
                title={tab.label}
                content={tab.content}
                image={tab.image}
                imagePng={tab.imagePng}
                LinkContent={tab.LinkContent}
                linkTo={tab.linkTo}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Whychoose;
