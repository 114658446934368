
import { Modal } from "antd";
import { PrescriptionTempate } from "./prescriptionTemplate"
const EPrescriptionListModel = (props) =>{
    const {data,handleClose,isOpen,handleEPrescriptionSelectClick,handleSelectPrescriptionClick} = props
    return(
      <Modal
      title="e-Prescription"
      centered={true}
      footer={false}
      className="commonModal"
      open={isOpen}
      width="50%"
      // onOk={handleHideClick}
      onCancel={handleClose}
    >
          <div className="flex relative flex-col justify-center">
          {data.map((res, i) =>(

                <PrescriptionTempate index = {i} cart = {true} handlePrescriptionSelectClick = {handleEPrescriptionSelectClick} prescriptionType = "ePrescription" selectedPrescription = {res} prescribedList = {[]} />
                ))

          }
          <button className="sticky bottom-0 bg-secondaryColor text-center text-White py-3 cursor-pointer rounded-md " onClick={() => handleSelectPrescriptionClick("ePrescription")} >
          <p className="text-md font-semibold text-grey-700" >Upload Prescription</p>
          </button>
          </div>
        </Modal>
    )
}

export default EPrescriptionListModel