import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const LipoSuction = ({data}) =>{
    console.log(data, "sdvkjdsiuvdsbgvdsvds")
    if(!data?.sixthComp?.points?.length){
        return null
    }
    return(
        <>
        {data?.seventhComp ? (
                       <div style={{backgroundColor: "#EBF8FF"}} className="grid md:grid-cols-2 px-8 justify-between items-start my-4 p-8">
                       <div style={{color: "#004171", fontSize: 36, fontWeight: 600}} >
                           {data?.seventhComp?.title}
                       </div>
                       <div  >
                       {data?.seventhComp?.description}
                       <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-8" >
                       {
                           data?.seventhComp?.points.map(res => (
                               <div className="p-4" style={{backgroundColor:"#ffff", borderRadius: 8}}>
                                <img src= {res.icon_url} />
                               <p className="mt-4" style={{color:"#004171", fontSize: 12, fontWeight: 400}} >{res.description}</p>
                               </div>
                           ))
                       }
                       </div>
                           </div>
                   </div>
              ) : (
                <p></p>
              )}
           {
                data.sixthComp?.points?.length ? <div className="py-12" >
                    <h4 style={{color:"#004171"}} className="text-xl md:text-2xl font-semibold text-center mb-4" >{data.sixthComp.points[0].mainTitle}</h4>
                    <h4 style={{color:"#004171" , fontSize: 20, fontWeight: 600}} className="text-center mb-4" >{data.sixthComp.points[0].title}</h4>
                    <h4 style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} className="text-center mb-4 px-4" >{data.sixthComp.points[0].maindescription}</h4>
                    <div  >
                    {
                        data.sixthComp?.points.map((res , index) =>{
                            return(
                                <div>
                                {index!== 0 ? <p style={{color:"#004171" , fontSize: 26, fontWeight: 600}} className="text-center mb-4" >{res.title}</p> : null}
                                <div className="grid md:grid-cols-2">
                                {res.sub_points.map((res , index) => {
                                return( <div className="p-8"> 
                                    <div className="flex items-center relative" > 
                                   <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                                   <p className="text-xl md:text-2xl font-semibold" style={{color:"#005D8D"}} >{res.title}</p>
                               </div>
                                           <p className="ml-10 text-base " style={{color:"#676C80"}} >{res.description}</p>
                                   </div>
                          
                                   )
                            })}
                            </div>
                                </div>
                            )
                            
                          
                          
                        })
                    }
                    </div>
                    <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.sixthComp?.points[0].mainnotes}</h4>
                </div> : null
              }
              </>
    )
}

export default LipoSuction