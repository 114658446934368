import React from "react";
import AbdominalPainImage from "../../../Assets/Images/Icons/Symptom/Abdominal Pain.svg";
import JointPainImage from "../../../Assets/Images/Icons/Symptom/JointPain.svg";
import FatigueImage from "../../../Assets/Images/Icons/Symptom/Fatigue.svg";
import ToothAcheImage from "../../../Assets/Images/Icons/Symptom/ToothAche.svg";
import EarPainImage from "../../../Assets/Images/Icons/Symptom/EarPain.svg";
import ChestPainImage from "../../../Assets/Images/Icons/Symptom/ChestPain.svg";
import GastritisImage from "../../../Assets/Images/Icons/Symptom/Gastritis.svg";
import BackPainImage from "../../../Assets/Images/Icons/Symptom/BackPain.svg";
import ExcessiveSweatingImage from "../../../Assets/Images/Icons/Symptom/ExcessiveSweating.svg";
import IrregularPeriodsImage from "../../../Assets/Images/Icons/Symptom/IrregularPeriods.svg";
import CongestionImage from "../../../Assets/Images/Icons/Symptom/Congestion.svg";
import BreathingDifficultyImage from "../../../Assets/Images/Icons/Symptom/BreathingDifficulty.svg";
import DizzinessImage from "../../../Assets/Images/Icons/Symptom/Dizziness.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";

function BookSymptoms() {
    const history = useHistory();
    
    const CHRdata = [
        { img: AbdominalPainImage, text: 'Abdominal Pain' },
        { img: ToothAcheImage, text: 'Tooth Ache' },
        { img: JointPainImage, text: 'Joint Pain' },
        { img: EarPainImage, text: 'Ear Pain' },
        { img: CongestionImage, text: 'Congestion' },
        { img: ExcessiveSweatingImage, text: 'Excessive Sweating' },
        // { img: diaheria, text: 'Diarrhea' },
        { img: BreathingDifficultyImage, text: 'Breathing Difficulty' },
        { img: IrregularPeriodsImage, text: 'Irregular Periods' },
        { img: DizzinessImage, text: 'Dizziness' },
        { img: FatigueImage, text: 'Fatigue' },
        { img: BackPainImage, text: 'Back Pain' },
        { img: ChestPainImage, text: 'Chest Pain' },
        { img: GastritisImage, text: 'Gastritis' },
        // { img: UTL, text: 'UTI' },
        // { img: cold, text: 'Cold and Fever' },
    ]

  return (
    <div>
      <div className="mt-10">
        <div className="grid md:grid-cols-4 grid-cols-1 gap-5 justify-center flex-wrap">
        {CHRdata.map((data, i) => (

          <div key={i} onClick={() => history.push({ pathname: APP_ROUTES.FINDDOCTORS, healthConcerns: data.text, text: data.text})} className="bg-White cursor-pointer hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow hoverWhite">
            <div className="flex items-center gap-5">
              <div className="md:w-[25%]">
                <img src={data?.img} alt= {data?.text}
                onClick={() => history.push({ pathname: APP_ROUTES.FINDDOCTORS, healthConcerns: data.text, text: data.text})}
                 className="w-full" />
              </div>
              <div className="w-[75%]">
                <h5 className="text-primaryColor text-lg md:text-xl font-semibold ">
                  {data?.text}
                </h5>
              </div>
            </div>
          </div>
        ))}
        </div>
        {/* <div className="mt-5 md:mt-10 cursor-pointer underline text-secondaryColor text-lg md:text-xl font-semibold text-center">
          View All
        </div> */}
      </div>
    </div>
  );
}

export default BookSymptoms;
