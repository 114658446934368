
const CurebayNumbers = ({data}) =>{
    return(
        <section className="bg-White">
          <div className="container mx-auto py-[50px] md:py-[90px] relative">
            <div className="hidden md:block">
              <div className="tech-grid">
                <div
                  className=" bg-lightGray px-7 py-10 rounded-2xl flex flex-col justify-between"
                  id="techOne"
                >
                  <p className="uppercase text-primaryColor font-semibold text-base">
                    {data?.NinthComp[0]?.subHeading}
                  </p>
                  <span className="text-primaryColor text-7xl font-medium">
                  {data?.NinthComp[0]?.numbers}
                  </span>
                </div>
                <div className="w-full" id="techTwo">
                  <img
                    src={data?.NinthComp[2]?.image_url}
                    alt="images"
                    className="w-full"
                  />
                </div>
                <div
                  className=" bg-lightGray px-7 py-10 rounded-2xl flex flex-col justify-between"
                  id="techThree"
                >
                  <p className="uppercase text-primaryColor font-semibold text-base">
                  {data?.NinthComp[1]?.subHeading}
                  </p>
                  <span className="text-primaryColor text-6xl font-medium">
                  {data?.NinthComp[1]?.numbers}
                  </span>
                </div>
                <div className="w-full" id="techFour">
                  <img
                    src={data?.NinthComp[0]?.image_url}
                    alt="images"
                    className="w-full"
                  />
                </div>
                <div
                  className=" bg-lightGray px-7 py-10 rounded-2xl flex flex-col justify-between"
                  id="techFive"
                >
                  <p className="uppercase text-primaryColor font-semibold text-base">
                  {data?.NinthComp[3]?.subHeading}
                  </p>
                  <span className="text-primaryColor text-6xl font-medium">
                  {data?.NinthComp[3]?.numbers}
                  </span>
                </div>
                <div className="w-full" id="techSix">
                  <img
                    src={data?.NinthComp[1]?.image_url}
                    alt="images"
                    className="w-full"
                  />
                </div>
                <div
                  className=" bg-primaryColor px-7 py-10 rounded-2xl flex flex-col justify-between"
                  id="techSeven"
                >
                  <p className="uppercase text-White font-semibold text-base">
                  {data?.NinthComp[2]?.subHeading}
                  </p>
                  <span className="text-White text-6xl font-medium"> {data?.NinthComp[2]?.numbers}</span>
                </div>
              </div>
            </div>
            <div className="block md:hidden">
              <div className="flex gap-3">
                <div className="w-[40%]">
                  <div
                    className="mb-3 bg-primaryColor p-3 md:px-7 h-[150px] md:py-10 rounded-2xl flex flex-col justify-between"
                    id="techSeven"
                  >
                    <p className="capitalize text-White font-medium text-xs md:text-base">
                    {data?.NinthComp[0]?.subHeading}
                    </p>
                    <span className="text-White text-2xl md:text-6xl font-medium">
                    {data?.NinthComp[0]?.numbers}
                    </span>
                  </div>
                  <div className="w-full mb-3" id="techFour">
                    <img
                      src={data?.NinthComp[0]?.image_url}
                      alt="images"
                      className="w-full"
                    />
                  </div>
                  <div
                    className="mb-3 bg-primaryColor p-3 md:px-7 h-[100px] md:py-10 rounded-2xl flex flex-col justify-between"
                    id="techFive"
                  >
                    <p className="capitalize text-White font-medium text-xs md:text-base">
                    {data?.NinthComp[1]?.subHeading}
                    </p>
                    <span className="text-White text-2xl md:text-6xl font-medium">
                    {data?.NinthComp[1]?.numbers}
                    </span>
                  </div>
                  <div className="w-full" id="techSix">
                    <img
                      src={data?.NinthComp[1]?.image_url}
                      alt="images"
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="w-[60%]">
                  <div className="w-full mb-3" id="techTwo">
                    <img
                      src={data?.NinthComp[2]?.image_url}
                      alt="images"
                      className="w-full"
                    />
                  </div>
                  <div
                    className="mb-3 bg-lightGray p-3 md:px-7 h-[225px] md:py-10 rounded-2xl flex flex-col justify-between"
                    id="techSeven"
                  >
                    <p className="capitalize text-primaryColor font-medium text-base md:text-base">
                    {data?.NinthComp[3]?.subHeading}
                    </p>
                    <span className="text-primaryColor text-4xl md:text-6xl font-medium">
                    {data?.NinthComp[3]?.numbers}
                    </span>
                  </div>
                  <div
                    className="mb-3 bg-lightGray p-3 md:px-7 h-[120px]  md:py-10 rounded-2xl flex flex-col justify-between"
                    id="techSeven"
                  >
                    <p className="capitalize text-primaryColor font-medium text-xs md:text-base">
                    {data?.NinthComp[2]?.subHeading}
                    </p>
                    <span className="text-primaryColor text-2xl md:text-6xl font-medium">
                    {data?.NinthComp[2]?.numbers}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
}

export default CurebayNumbers