

const Cataract = ({data}) =>{
    console.log(data, "sdvkjdsiuvdsbgvdsvds")
    if(!data?.sixthComp?.points?.length){
        return null
    }
    return(
        <>
        <div className="grid md:grid-cols-2 px-8 justify-between items-start my-20 md:px-16">
            <div className="text-xl md:text-2xl font-semibold" style={{color: "#004171"}} >
                {data?.sixthComp?.points[0].mainTitle}
            </div>
            <div  >
            {data?.sixthComp?.points[0].maindescription}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-8" >
            {
                data?.sixthComp?.points.map(res => (
                    <div className="p-4" style={{backgroundColor:"#EBF8FF", borderRadius: 8}}>
                        <img src = {res.icon_url} />
                    <p className="break-words" style={{color:"#004171", fontSize: 12, fontWeight: 400}} >{res.title}</p>
                    </div>
                ))
            }
            </div>
                </div>
        </div>
              {data?.seventhComp ? (
                <div className="bg-primaryColor text-white py-20 px-4 md:px-16 mb-10">
                  <div className="container mx-auto">
                    <div className="flex flex-col md:flex-row md:items-start">
                      <div className="md:w-1/2 mb-6 md:mb-0">
                        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-White">
                          {data?.seventhComp.title}
                        </h2>
                      </div>
        
                      <div className="md:w-1/2">
                        <p className="text-sm md:text-base text-White ">
                        {data?.seventhComp.description}
                        </p>
                        {/* {surgeryData?.attributes?.fifthComp.points &&
                          <ul style={{listStyleType: "disc"}} >
                          {surgeryData?.attributes?.fifthComp.points.map(res =>{
                            return <li className="text-base font-medium" style={{color:"#676C80"}}> {res.description}</li>
                          })}
                        </ul>
                        } */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p></p>
              )}
              </>
    )
}

export default Cataract