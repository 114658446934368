const DrugTable = ({items}) =>{
  console.log(items,"gdhfbvhdfvbfdh")
    return(
        <table className=" border-collapse border border-gray w-full h-full mx-auto ">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-400 p-2">ITEM NAME</th>
            <th className="border border-gray-400 p-2">QTY</th>
            <th className="border border-gray-400 p-2">UNIT PRICE</th>
            <th className="border border-gray-400 p-2">
              UNIT DISCOUNTED PRICE
            </th>
            <th className="border border-gray-400 p-2">
              TOTAL AMOUNT
            </th>
          </tr>
        </thead>
        <tbody>
          {/* {currSelectedOrder?.orderDetails[0]?.refundableAmountForItems?.map(
            (drug, idx) => (
              <tr key={idx}>
                <td className="border border-gray-400 p-2">
                  {drug?.drugName}
                </td>
                <td className="border border-gray-400 p-2">
                  {drug?.quantity}
                </td>
                <td className="border border-gray-400 p-2">
                  ₹ {drug?.unitPrice}
                </td>
                <td className="border border-gray-400 p-2">
                  ₹ {drug?.discountAmount}
                </td>
                <td className="border border-gray-400 p-2">
                  ₹ {drug?.totalAmount}
                </td>
                <td className="border border-gray-400 p-2 bg-lightGreen text-secondaryColor">
                {drug?.consignmentstatusname ? drug?.consignmentstatusname : "Yet to Create"}
                </td>
              </tr>
            )
          )} */}
          {items.map(
            (drug, idx) => (
              <tr key={idx}>
                <td className="border border-gray-400 p-2">
                  {drug?.drugName}
                </td>
                <td className="border border-gray-400 p-2">
                  {drug?.quantity}
                </td>
                <td className="border border-gray-400 p-2">
                  ₹ {drug?.unitPrice}
                </td>
                <td className="border border-gray-400 p-2">
                  ₹ {drug?.discountAmount}
                </td>
                <td className="border border-gray-400 p-2">
                  ₹ {drug?.totalAmount}
                </td>
              
              </tr>
            )
          )}
        </tbody>
      </table>
    )
}

export default DrugTable