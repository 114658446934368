import React, {useState, useEffect} from 'react';
import axios from "axios";
import { useSelector ,useDispatch } from 'react-redux';
import defaultMed from "../../../Assets/pharamacyImages/Medicines.jpg";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
    trackAddToCartEvent,
    trackPageEvent,
  } from "../../utils/analytics/events";
  import {
    AddtoCart,
    getCartDetails,
  } from "../../../Redux/Action/cartPlaceOrderAction";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";

const PreviouslyBoughtMedicine = () => {
  const [pastMedOrders, setpastMedOrders] = useState(null);
  const userData = useSelector((state) => state.authReducer.patientData);
  const { cartList } = useSelector((state) => state.cartReducer);
  const [loading, setLoading] = useState(false)
  const isMobileView = window.innerWidth <= 768;
  const history = useHistory()
  const dispatch = useDispatch();
      // let result = data.headerComp[0]
      const settings = {
        dots: false,
        // infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        // centerMode: true,
        // autoplay: true,
        autoplaySpeed: 3500,
        // centerPadding: "100px",
        prevArrow: <img src={PrevArrow} alt="icons" />,
        nextArrow: <img src={NextArrow} alt="icons" />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
        // centerMode: true,
        // centerPadding: "100px"
            //   dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: true,
              arrows: false
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: true,
              adaptiveHeight: true,
              arrows: false
            },
          },
        ],
      };
  useEffect(() => {
    fetchPastOrders();
  }, []);

  const redirectToMedicinePage = (item) => {
    // history.push('/medicine');
    history.push(`/medicine/${item}`);
  };

  const fetchPastOrders = async () => {
    try {

      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}MedicineOrder/getDistinctMedicines?patientId=${userData.code}&orderBy=ORDER_DATE_AESC`
      );

      if (res?.data?.data) {
        setpastMedOrders(res.data.data);
      } else {
        console.warn("No data received from the API.");
      }
    } catch (error) {
      console.error("Error fetching past medicine orders:", error.message);
    }
  };

  const getProductsIDs = () => {
    let ids = [];
    if (cartList) {
      if (cartList?.patientMedicineOrder?.length) {
        cartList?.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(
          (res) => {
            ids.push(res.drugCode);
          }
        );
      }
    }
    return ids;
  };
  const drugIDsInCart = getProductsIDs();

  const handleAddToCart = async (item) => {
    let payload = {
      patientId: item?.patientId,
      drugCode: item?.drugCode,
      drugName: item?.drugName,
      drugCategory: item?.drugCategory,
      medicineTypeOfSell: item?.drugsInfoTypeofSell,
      medicineType: "",
      unitPrice: Number(item?.mdmDrugDetails?.medicineRate),
      quantity: 1,
      prescriptionRequired: item?.mdmDrugDetails?.prescriptionRequired == "Yes" ? "Yes" : "No",
      vendorId:item?.vendorId
    };
    setLoading(false)
    fetch(`${process.env.REACT_APP_BASEURL}newPatientMedicine/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res, "vghsdvhcvdsg");
        trackAddToCartEvent(item?.mdmDrugDetails?.medicineRate, item?.drugName);
        // setIsLoading(false);
        dispatch(getCartDetails(item?.patientId));
        setLoading(false)
      })
      .catch((err) => {
        console.error("Error adding to cart:", err);
        setLoading(false)
        // setIsLoading(false);
      });
  };

  if(!pastMedOrders?.length ){
    return <></>
  }

    return(
        <div className='mt-4 mb-12' style={{backgroundColor: "#F3F8FF"}}>
        <p className='text-xl font-bold text-primaryColor pl-4 pt-2'>Previously <span className='text-secondaryColor' >Bought</span></p>
        <Slider {...settings}>
                {pastMedOrders?.length > 0 &&
                  pastMedOrders.map((item, idx) => (
                    <div key={idx} style={{backgroundColor: "#F3F8FF"}} className="p-5 flex flex-col justify-between cursor-pointer w-11/12">
                       <img src={item?.mdmDrugDetails?.medicineImages && item?.mdmDrugDetails?.medicineImages?.length > 0 ? item?.mdmDrugDetails?.medicineImages[0] : defaultMed} className="w-full h-20 md:h-32 object-contain md:object-cover rounded-md mb-5" onClick={()=>redirectToMedicinePage(item?.mdmDrugDetails?.seoNameId)}/>
                      <h3 className="text-left text-xs md:text-lg font-semibold text-primaryColor mb-1 truncate">
                        {item?.drugName}
                      </h3>
                      {/* {item?.mdmDrugDetails?.medicineRate ? (
                        <p className="text-secondaryColor text-base font-semibold mb-1 text-left">
                          Rs. {item?.mdmDrugDetails?.medicineRate}  
                        </p>
                      ) : <p>{" "}</p>} */}
                      {item?.drugsInfoTypeofSell && (
                        <p className="text-gray-500 text-xs mb-2 truncate text-left">
                          {item?.drugsInfoTypeofSell}
                        </p>
                      )}
                      <div className='flex justify-center' >
                      <button
                      className={`px-4 py-1 md:px-6 md:py-2 text-xs rounded-md font-semibold transition flex items-center ${
                        drugIDsInCart.includes(item.drugCode)
                          ? "bg-secondaryColor text-White cursor-not-allowed opacity-50"
                          : "bg-primaryColor text-White hover:bg-secondaryColor"
                      }`}
                        onClick={() => handleAddToCart(item)}
                        // className={`w-full border border-primaryColor text-primaryColor px-6 py-3 rounded-md font-semibold hover:bg-primaryColor hover:text-White transition`}
                        disabled={drugIDsInCart.includes(item.drugCode)}
                        load
                      >
                        {drugIDsInCart.includes(item.drugCode)
                        ? "Added"
                        : "Add"}
                        {loading ? <Spin className='pl-1' indicator={<LoadingOutlined spin />} /> : null}
                      </button>
                      </div>
                  </div>
                  ))}
              </Slider>
              </div>
    )
}

export default PreviouslyBoughtMedicine