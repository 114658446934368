import React from "react";
import Layouts from "../../Layouts";
import Search from "antd/es/input/Search";
import DoctorImage from "../../../Assets/Images/Doctor/image 155.png";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Speciality() {
    const {speciality} = useParams();
    const history = useHistory();
    const Speciality = speciality?.charAt(0).toUpperCase() + speciality?.slice(1);


    const handleClick = (name) => {
      
    
     history.push(`/doctors/${name}`);
    }

  return (
    <Layouts>
    <ToastContainer/>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <div className="flex md:w-[80%] mb-[50px] md:mb-[90px] mx-auto bg-White rounded-full">
            <div className="w-full md:w-[80%]">
              <Search
                className="apptSearch apptWhite bg-White"
                placeholder="Search Doctors"
              />
            </div>
            <button className="hidden md:block bg-primaryColor rounded-full text-White text-xs md:text-lg w-[20%]">
              Look for Doctors
            </button>
          </div>
          <h1 className="text-primaryColor text-center text-xl md:text-3xl font-bold mb-10 !leading-snug">
            <span className="text-secondaryColor">{Speciality}</span> Doctors
            {/* (132) */}
          </h1>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-5">
            <div className="bg-White rounded-lg p-5">
              <div className="">
                <img src={DoctorImage} alt="images" className="w-full" />
              </div>
              <h6 className="text-primaryColor text-lg mt-3 font-medium mb-2">
                Sarthak Kumar Das
              </h6>
              <p className="text-textGray text-sm font-light mb-2">
                Cardiology | 1 years experience | Bhubaneswar
              </p>
              <div className="flex gap-5">
                <p className="text-textGray text-sm underline cursor-pointer font-light">
                  View Profile
                </p>
                <p className="text-textGray text-sm underline cursor-pointer font-light">
                  Video Consultation: Rs 500.0
                </p>
              </div>
              <button className="bg-primaryColor rounded-lg text-White text-base w-full py-3 mt-4 " onClick={() =>handleClick('Sarthak_Kumar_Das')}>
                Book Consultation
              </button>
            </div>
            <div className="bg-White rounded-lg p-5">
              <div className="">
                <img src={DoctorImage} alt="images" className="w-full" />
              </div>
              <h6 className="text-primaryColor text-lg mt-3 font-medium mb-2">
                Sarthak Kumar Das
              </h6>
              <p className="text-textGray text-sm font-light mb-2">
                Cardiology | 1 years experience | Bhubaneswar
              </p>
              <div className="flex gap-5">
                <p className="text-textGray text-sm underline cursor-pointer font-light">
                  View Profile
                </p>
                <p className="text-textGray text-sm underline cursor-pointer font-light">
                  Video Consultation: Rs 500.0
                </p>
              </div>
              <button className="bg-primaryColor rounded-lg text-White text-base w-full py-3 mt-4 " onClick={() =>handleClick('Sarthak_Kumar_Das')}>
                Book Consultation
              </button>
            </div>
            <div className="bg-White rounded-lg p-5">
              <div className="">
                <img src={DoctorImage} alt="images" className="w-full" />
              </div>
              <h6 className="text-primaryColor text-lg mt-3 font-medium mb-2">
                Sarthak Kumar Das
              </h6>
              <p className="text-textGray text-sm font-light mb-2">
                Cardiology | 1 years experience | Bhubaneswar
              </p>
              <div className="flex gap-5">
                <p className="text-textGray text-sm underline cursor-pointer font-light">
                  View Profile
                </p>
                <p className="text-textGray text-sm underline cursor-pointer font-light">
                  Video Consultation: Rs 500.0
                </p>
              </div>
              <button className="bg-primaryColor rounded-lg text-White text-base w-full py-3 mt-4 " onClick={() =>handleClick('Sarthak_Kumar_Das')}>
                Book Consultation
              </button>
            </div>
            <div className="bg-White rounded-lg p-5">
              <div className="">
                <img src={DoctorImage} alt="images" className="w-full" />
              </div>
              <h6 className="text-primaryColor text-lg mt-3 font-medium mb-2">
                Sarthak Kumar Das
              </h6>
              <p className="text-textGray text-sm font-light mb-2">
                Cardiology | 1 years experience | Bhubaneswar
              </p>
              <div className="flex gap-5">
                <p className="text-textGray text-sm underline cursor-pointer font-light">
                  View Profile
                </p>
                <p className="text-textGray text-sm underline cursor-pointer font-light">
                  Video Consultation: Rs 500.0
                </p>
              </div>
              <button className="bg-primaryColor rounded-lg text-White text-base w-full py-3 mt-4 " onClick={() =>handleClick('Sarthak_Kumar_Das')}>
                Book Consultation
              </button>
            </div>
            <div className="bg-White rounded-lg p-5">
              <div className="">
                <img src={DoctorImage} alt="images" className="w-full" />
              </div>
              <h6 className="text-primaryColor text-lg mt-3 font-medium mb-2">
                Sarthak Kumar Das
              </h6>
              <p className="text-textGray text-sm font-light mb-2">
                Cardiology | 1 years experience | Bhubaneswar
              </p>
              <div className="flex gap-5">
                <p className="text-textGray text-sm underline cursor-pointer font-light">
                  View Profile
                </p>
                <p className="text-textGray text-sm underline cursor-pointer font-light">
                  Video Consultation: Rs 500.0
                </p>
              </div>
              <button className="bg-primaryColor rounded-lg text-White text-base w-full py-3 mt-4 " onClick={() =>handleClick('Sarthak_Kumar_Das')}>
                Book Consultation
              </button>
            </div>
            <div className="bg-White rounded-lg p-5">
              <div className="">
                <img src={DoctorImage} alt="images" className="w-full" />
              </div>
              <h6 className="text-primaryColor text-lg mt-3 font-medium mb-2">
                Sarthak Kumar Das
              </h6>
              <p className="text-textGray text-sm font-light mb-2">
                Cardiology | 1 years experience | Bhubaneswar
              </p>
              <div className="flex gap-5">
                <p className="text-textGray text-sm underline cursor-pointer font-light">
                  View Profile
                </p>
                <p className="text-textGray text-sm underline cursor-pointer font-light">
                  Video Consultation: Rs 500.0
                </p>
              </div>
              <button className="bg-primaryColor rounded-lg text-White text-base w-full py-3 mt-4 " onClick={() =>handleClick('Sarthak_Kumar_Das')}>
                Book Consultation
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default Speciality;