import React, { useState } from "react";
import Layouts from "../Layouts";
import Clockimage from "../../Assets/Images/Icons/clock.svg";
import Calendarimage from "../../Assets/Images/Icons/calendar.svg";
import Backarrow from "../../Assets/Images/Icons/backarrow.svg";
import HoverBackarrow from "../../Assets/Images/Icons/backarrow-white.svg";
import { useHistory } from "react-router-dom";
import Blog4 from "../../Assets/Images/Blog/Blog4.jpg"

function BlogDetails4() {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  const handleNavigation = (path) => {
    history.push(path);
  };
  return (
    <Layouts>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px] md:w-[80%]">
          <div
            className="text-primaryColor hover:bg-primaryColor hover:text-White py-2 px-5 w-fit rounded-full mx-auto text-lg font-medium cursor-pointer flex gap-3 justify-center items-center mb-10"
            onClick={() => handleNavigation("/blogs")}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img src={isHovered ? HoverBackarrow : Backarrow} alt="images" />
            Back to blogs
          </div>
          <div>
            <h2 className="md:w-[60%] mx-auto text-center text-2xl md:text-4xl font-bold text-primaryColor !leading-normal mb-5">
              <span className="text-secondaryColor">5 Simple</span> Yet
              Effective Ways To Reduce Digital Eye Strain
            </h2>
            <p className="md:w-[50%] mx-auto text-center text-textGray ">
              Do you spend a significant amount of time looking at the screen?
              You are not alone. In this digital era, it is almost impossible to
              escape screen time.
            </p>
            <div className="flex gap-4 justify-center mt-5">
              <div className="flex items-center gap-2">
                <img src={Clockimage} alt="images" />
                <p className="text-textGray text-sm ">6 minute read</p>
              </div>
              <div className="flex items-center gap-2">
                <img src={Calendarimage} alt="images" />
                <p className="text-textGray text-sm ">23rd February, 2024</p>
              </div>
            </div>
          </div>
          <div className="w-full md:h-[500px] my-5 md:my-10">
            <img
              src={Blog4}
              alt="images"
              className="w-full  h-full object-cover rounded-lg"
            />
          </div>
          <div className="bg-White rounded-xl p-5 md:p-10">
            <h6 className=" text-primaryColor text-xl font-semibold my-5">
              Introduction:
            </h6>
            <p className="text-textGray mb-4 md:leading-loose">
              Do you spend a significant amount of time looking at the screen?
              You are not alone. In this digital era, it is almost impossible to
              escape screen time. Whether for studies, work or entertainment, we
              all end up spending a lot of time with our gadgets, causing
              digital eye strain and discomfort.
            </p>
            <p className="text-textGray md:leading-loose  mb-4">
              Symptoms of digital eye strain include tiredness, irritation and
              dryness of the eyes, blurry vision as well as headaches.
              Fortunately, with the right care and attention, these ailments can
              be effectively managed. Here are five simple tips to keep your
              eyes healthy and reduce strain:
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              1. Blink, Blink and Blink:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              It might sound strange, but many people tend to blink less than
              usual while staring at the screen. Blinking helps spread tears
              across the surface of the eye, keeping it moist and healthy. Try
              making a conscious effort to blink when looking at the screen. It
              is a simple yet effective way to protect your eye.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              2. Follow The 20-20-20 Rule:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Practice this formula regularly. Every 20 minutes, look at an
              object 20 feet away for 20 seconds. And repeat. By doing so, you
              will give your eyes a much-needed break from your screen.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              3. Adjust Your Screen Display Settings:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Increase font size across your gadgets - be it laptop, tablet or
              mobile so that you don’t strain your eyes while reading. Also,
              adjust the brightness of your screen to keep it the same as the
              surroundings. Make sure it’s neither too bright nor too dark.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              4. Reduce Glares:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Glare makes it difficult to view characters or objects on the
              screen, especially with bright lights behind you or above you. Try
              to avoid those by either switching them off, changing the position
              of your screen or minimising them through curtains or blinds. The
              ideal way to position your computer is to the side of the light
              source.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              5. Get An Eye Examination:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Eye health is crucial for overall well-being. Hence, to keep your
              eyes healthy, schedule an appointment with an ophthalmologist and
              let them know about your eye condition so that they can provide
              appropriate solutions.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Conclusion:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Although digital eye strain is not a serious condition, and will
              not lead to permanent eye damage, it can have a direct impact on
              your productivity and health. With these tips, you can manage eye
              strain and improve your health.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
            <span className="text-secondaryColor">Recommended</span> Reads
          </h2>
          <div className="grid md:grid-cols-2 gap-5">
            <div
              className="bg-White common-shadow rounded-xl p-5 cursor-pointer w-[80%] mx-auto"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div className="w-full mb-3">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog1.jpg"
                  alt="images"
                  className="w-full"
                />
              </div>
              <h6 className="text-primaryColor font-semibold text-lg mb-2">
                Beat the Heat: 10 Tips for Staying Cool and Hydrated This Summer
              </h6>
              <p className="text-textGray text-sm mb-4">
                As temperatures rise and the sun shines brighter, it's essential
                to prioritize staying cool and hydrated to beat the summer heat.
              </p>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
            <div
              className="bg-White common-shadow rounded-xl p-5 cursor-pointer w-[80%] mx-auto"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div className="w-full mb-3">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog3.jpg"
                  alt="images"
                  className="w-full"
                />
              </div>
              <h6 className="text-primaryColor font-semibold text-lg mb-2">
                10 Tips for Managing Chronic Conditions with Telehealth
              </h6>
              <p className="text-textGray text-sm mb-4">
                In recent years, telehealth has emerged as a groundbreaking
                solution for managing chronic health conditions, offering
                convenience, accessibility, and personalized care from the
                comfort of one's home.
              </p>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default BlogDetails4;
