import React, { useEffect, useRef, useState } from "react";
import Layouts from "../../Layouts";
import ExtensiveCoverage from "../../../Assets/Images/Icons/ExtensiveCoverage.svg";
import EnhancedVisibility from "../../../Assets/Images/Icons/EnhancedVisibility.svg";
import SkilledManpower from "../../../Assets/Images/Icons/SkilledManpower.svg";
import MutualGrowth from "../../../Assets/Images/Icons/MutualGrowth.svg";
import AdvancedTechnology from "../../../Assets/Images/Icons/AdvancedTechnology.svg";
import { Form, Input, InputNumber, Modal, Select, message } from "antd";
import IndustryLead from "../IndustryLead";
import IndustryLeadA from "../IndustryLeadA";
import http from "../../../Redux/Services/http-common";
import { useDispatch, useSelector } from "react-redux";
import { getAllStates } from "../../../Redux/Action/hospitalpageActions";
import { ToastContainer, toast } from "react-toastify";
import SubHeader from "../../Shared/SearchBar";

function AlliedService() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [oldProfile, setOldProfile] = useState();
  const patientinfo = useSelector((state) => state.patientinfo);
  const userData = useSelector((state) => state.authReducer.patientData);
  const [profile, setProfile] = useState(userData);
  const { patientinfoData, isLoading, isSuccess } = patientinfo;

  useEffect(() => {
    async function fetchStates() {
      const res = await dispatch(getAllStates());
      setStates(res);
    }
    fetchStates();
  }, []);
  

  const handleChange = (value) => {
    setSelectedState(value);
  };

  console.log("profile value", profile);

  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      if (/^[A-Za-z]$/.test(e.key)) {
        message.error("Alphabetic characters are not allowed.");
      }
      e.preventDefault();
    }
  };
  const filterOption = (input, option) =>
    option?.label?.toLowerCase().includes(input.toLowerCase());

  const handleSubmit = async () => {
    const formValues = formRef.current.getFieldsValue();
    console.log("Form values:", formValues);
    const { city, company, contact, pincode, service,email,phone } = formValues;

    if(company === undefined || company === ""){
      message.error("Please fill in all fields");
        return;
    }

    if(service === undefined || service === ""){
      message.error("Please fill in all fields");
        return;
    }

    if (profile.firstName === undefined || profile.firstName === "" || profile.firstName === null) {
      if (contact === undefined || contact === "") {
        message.error("Please fill in all fields");
        return;
      }
    }
    
    if (profile.state === undefined || profile.state === "" || profile.state === null) {
      if (selectedState === undefined || selectedState === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.city === undefined || profile.city === "" || profile.city === null) {
      if (city === undefined || city === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.pinCode === undefined || profile.pinCode === "" || profile.pinCode === null) {
      if (pincode === undefined || pincode === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    let payload;

    if(Object.keys(userData).length === 0){
      if(email === undefined || email === ""){
        message.error("Please fill in all fields");
        return;
      }
      if(phone === undefined || phone === ""){
        message.error("Please fill in all fields");
        return;
      }
      payload = {
        city: city ? city : profile.city,
        companyName: company,
        contactPerson: contact ? contact : profile.firstName,
        email: email,
        phoneNumber: phone,
        pincode:pincode?pincode.toString():profile.pinCode,
        serviceType: service,
        state: selectedState?selectedState:profile.state === "AP"?"Andhra Pradesh":
        profile.state === "AS"?"Assam":
        profile.state === "BR"?"Bihar":
        profile.state === "CT"?"Chhattisgarh":
        profile.state === "DL"?"Delhi":
        profile.state === "GA"?"Goa":
        profile.state === "GJ"?"Gujarat":
        profile.state === "HR"?"Haryana":
        profile.state === "HP"?"Himachal Pradesh":
        profile.state === "JK"?"Jammu and Kashmir":
        profile.state === "JH"?"Jharkhand":
        profile.state === "KA"?"Karnataka":
        profile.state === "KL"?"Kerala":
        profile.state === "MP"?"Madhya Pradesh":
        profile.state === "MH"?"Maharashtra":
        profile.state === "MN"?"Manipur":
        profile.state === "ML"?"Meghalaya":
        profile.state === "MZ"?"Mizoram":
        profile.state === "NL"?"Nagaland":
        profile.state === "OD"?"Odisha":
        profile.state === "PY"?"Puducherry":
        profile.state === "PB"?"Punjab":
        profile.state === "PJ"?"Rajasthan":
        profile.state === "TN"?"Tamil Nadu":
        profile.state === "TS"?"Telangana":
        profile.state === "TR"?"Tripura":
        profile.state === "UP"?"Uttar Pradesh":
        profile.state === "UK"?"Uttarakhand":
        profile.state === "WB"?"West Bengal":
        profile.state,
        type: "Allied",
      };
  
    }
    else{
       payload = {
        city: city ? city : profile.city,
        companyName: company,
        contactPerson: contact ? contact : profile.firstName,
        email: profile.email,
        phoneNumber: profile.mobile,
        pincode:pincode?pincode.toString():profile.pinCode,
        serviceType: service,
        state: selectedState?selectedState:profile.state === "AP"?"Andhra Pradesh":
        profile.state === "AS"?"Assam":
        profile.state === "BR"?"Bihar":
        profile.state === "CT"?"Chhattisgarh":
        profile.state === "DL"?"Delhi":
        profile.state === "GA"?"Goa":
        profile.state === "GJ"?"Gujarat":
        profile.state === "HR"?"Haryana":
        profile.state === "HP"?"Himachal Pradesh":
        profile.state === "JK"?"Jammu and Kashmir":
        profile.state === "JH"?"Jharkhand":
        profile.state === "KA"?"Karnataka":
        profile.state === "KL"?"Kerala":
        profile.state === "MP"?"Madhya Pradesh":
        profile.state === "MH"?"Maharashtra":
        profile.state === "MN"?"Manipur":
        profile.state === "ML"?"Meghalaya":
        profile.state === "MZ"?"Mizoram":
        profile.state === "NL"?"Nagaland":
        profile.state === "OD"?"Odisha":
        profile.state === "PY"?"Puducherry":
        profile.state === "PB"?"Punjab":
        profile.state === "PJ"?"Rajasthan":
        profile.state === "TN"?"Tamil Nadu":
        profile.state === "TS"?"Telangana":
        profile.state === "TR"?"Tripura":
        profile.state === "UP"?"Uttar Pradesh":
        profile.state === "UK"?"Uttarakhand":
        profile.state === "WB"?"West Bengal":
        profile.state,
        type: "Allied",
      };
    }


   

    try {
      const res = await http.post("partnerEnquiryForm/partner",payload);
      console.log(res);
      setSelectedState('');
      formRef.current.resetFields();
      message.success("Thank You! Our support team will contact you shortly.");
    } catch (error) {
      console.log(error);
      message.error("Error Occurred");
    }
  };


  return (
    <>
      <ToastContainer />
      <Layouts>
        <SubHeader />
        <div className="bg-lightGray pb-16">
          <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
            <div className="container mx-auto">
              <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
              <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
                <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
                  <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                    Join us as a{" "}
                    <span className="text-secondaryColor">Service Partner</span>
                  </h1>
                  <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
                    We are seeking collaborators in Nursing Services,
                    Physiotherapy, and Doorstep X-ray services to join us in our
                    endeavor to deliver inclusive healthcare solutions
                    nationwide, ensuring accessibility and support for all
                    individuals, regardless of location.
                  </p>
                </div>
                <div className="relative z-1 flex justify-end mt-5 md:mt-0">
                  <div className="w-full md:w-[90%] md:h-[400px] rounded ">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Allied%20Service%20partner.webp"
                      alt="images"
                      className="w-full h-full image-boxshadow rounded-xl"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-lightGray">
            <div className="container mx-auto py-[50px] py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-[50px]">
                Why Partner with CureBay <br /> as a{" "}
                <span className="text-secondaryColor"> Service Partner</span>?
              </h2>
              <div className="md:flex gap-5 justify-center flex-wrap">
                <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow md:mb-0 mb-5 md:w-[32%] hoverWhite">
                  <div className="mb-5">
                    <img src={ExtensiveCoverage} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Extensive Coverage
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Gain access to a vast network of 100+ eClinics, extending
                    your services to remote areas.
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow md:mb-0 mb-5 md:w-[32%] hoverWhite">
                  <div className="mb-5">
                    <img src={EnhancedVisibility} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Enhanced Visibility
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Increased exposure and recognition within the healthcare
                    community.
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow md:mb-0 mb-5 md:w-[32%] hoverWhite">
                  <div className="mb-5">
                    <img src={SkilledManpower} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Skilled Manpower
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Tap into a pool of 500+ skilled healthcare professionals for
                    collaborative care.
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow md:mb-0 mb-5 md:w-[32%] hoverWhite">
                  <div className="mb-5">
                    <img src={AdvancedTechnology} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Advanced Technology
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Harness advanced tech to elevate service, streamline
                    operations, and better patient outcomes.
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow md:mb-0 mb-5 md:w-[32%] hoverWhite">
                  <div className="mb-5">
                    <img src={MutualGrowth} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Mutual Growth
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Benefit from a mutually beneficial partnership focused on
                    delivering accessible and high-quality healthcare solutions
                    to patients.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-White md:bg-lightGray">
            <div className="container mx-auto py-[50px] md:pt-0  pb-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-5 md:mb-[50px]">
                Join us as a <br />
                <span className="text-secondaryColor">Service Partner</span>
              </h2>
              <div className="bg-White rounded-xl md:w-[90%] mx-auto md:p-10 md:common-shadow">
                <Form
                  ref={formRef}
                  layout="vertical"
                  autoComplete="off"
                  className="CommonForm grid md:grid-cols-2 md:gap-5"
                >
                  <Form.Item
                    name="company"
                    label={
                      <span>
                        Company Name <span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Input
                      name="companyname"
                      placeholder="Enter company name"
                    />
                  </Form.Item>
                  <Form.Item
                    name="service"
                    label={
                      <span>
                        Service Type<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Input
                      name="servicetype"
                      placeholder="Enter service type"
                    />
                  </Form.Item>
                  <Form.Item
                    name="contact"
                    label={
                      <span>
                        Contact Person<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Input
                      defaultValue={profile.firstName}
                      name="contactperson"
                      placeholder="Enter your name"
                    />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    label={
                      <span>
                        Phone No<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <InputNumber
                     disabled={Object.keys(userData).length === 0?false:true}
                      defaultValue={profile.mobile}
                      name="phone"
                      placeholder=" number"
                      onKeyPress={handleKeyPress}
                      maxLength={10}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={
                      <span>
                        Email Address<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Input  disabled={Object.keys(userData).length === 0?false:true} defaultValue={profile.email} name="email" placeholder="Enter email" />
                  </Form.Item>
                  <Form.Item
                    name="state"
                    label={
                      <span>
                        State<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Select
                    onChange={handleChange}
                    defaultValue={profile.state === "AP"?"Andhra Pradesh":
                    profile.state === "AS"?"Assam":
                    profile.state === "BR"?"Bihar":
                    profile.state === "CT"?"Chhattisgarh":
                    profile.state === "DL"?"Delhi":
                    profile.state === "GA"?"Goa":
                    profile.state === "GJ"?"Gujarat":
                    profile.state === "HR"?"Haryana":
                    profile.state === "HP"?"Himachal Pradesh":
                    profile.state === "JK"?"Jammu and Kashmir":
                    profile.state === "JH"?"Jharkhand":
                    profile.state === "KA"?"Karnataka":
                    profile.state === "KL"?"Kerala":
                    profile.state === "MP"?"Madhya Pradesh":
                    profile.state === "MH"?"Maharashtra":
                    profile.state === "MN"?"Manipur":
                    profile.state === "ML"?"Meghalaya":
                    profile.state === "MZ"?"Mizoram":
                    profile.state === "NL"?"Nagaland":
                    profile.state === "OD"?"Odisha":
                    profile.state === "PY"?"Puducherry":
                    profile.state === "PB"?"Punjab":
                    profile.state === "PJ"?"Rajasthan":
                    profile.state === "TN"?"Tamil Nadu":
                    profile.state === "TS"?"Telangana":
                    profile.state === "TR"?"Tripura":
                    profile.state === "UP"?"Uttar Pradesh":
                    profile.state === "UK"?"Uttarakhand":
                    profile.state === "WB"?"West Bengal":
                    profile.state
                  }
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select your state"
                      optionFilterProp="children"
                      options={states.map((state) => {
                        return {
                          label: state.description,
                          value: state.description,
                        };
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="city"
                    label={
                      <span>
                        City<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Input name="city" defaultValue={profile.city} placeholder="Enter city" />
                  </Form.Item>
                  <Form.Item
                    name="pincode"
                    label={
                      <span>
                        PIN Code<span className="text-danger">*</span>
                      </span>
                    }
                    onKeyPress={handleKeyPress}
                    maxLength={6}
                  >
                    <Input
                      name="pincode"
                       defaultValue={profile.pinCode}
                      placeholder="Enter pincode"
                     
                    />
                  </Form.Item>
                </Form>
                <button
                  className="primary_btn !w-full mt-8 !h-[50px] !text-lg"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </section>
          <section className="bg-primaryColor">
            <div className="container mx-auto py-[90px] relative">
              <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[50px]">
                Allied Service{" "}
                <span className="text-secondaryColor">Partners</span>
              </h2>
              <div className="md:w-[80%] mx-auto">
                <IndustryLeadA />
              </div>
            </div>
          </section>
        </div>
      </Layouts>
    </>
  );
}

export default AlliedService;