import { Button, Form, Input } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BMI from "../../../../Assets/Images/BMI.png";
import BloodPressure from "../../../../Assets/Images/Blood Pressure.png";
import HeartRate from "../../../../Assets/Images/Heart Rate.png";
import PulseOx from "../../../../Assets/Images/PulseOx.png";
import RespirationRate from "../../../../Assets/Images/Respiration Rate.png";
import Temperature from "../../../../Assets/Images/Temperature.png";
import Weight from "../../../../Assets/Images/Weight.png";
import { addPatientvitals } from "../../../../Redux/Action/userprofileVitalActions";

function AddNewVitals({ closePopup, patientCode, fetchAllVitals }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef();
  const [form] = Form.useForm();
  const userData = useSelector((state) => state.authReducer.patientData);

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push("/");
    }
  }, []);

  const [addvitals, setAddVitals] = useState({
    patientCode: patientCode,
    givenDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    createdBy: patientCode,
    updatedBy: patientCode,
    status: 1,
    height: "",
    weight: "",
    bloodGroup: "",
    temperature: "",
    systolic: "",
    diastolic: "",
    spo2: "",
    heartRate: "",
    pulseRate: "",
    respirationRate: "",
    bloodGlucose: "",
    bmi: 0,
    bp: 0,
    hdl: 0,
    ldl: 0,
    medication: "string",
    notes: "string",
    notes1: "string",
    notes2: "string",
    totalCholesterol: 0,
    triglycerides: 0,
  });
  useEffect(() => {}, []);

  const handleChange = (e) => {
    setAddVitals({ ...addvitals, [e.target.name]: e.target.value });
  };
  const savevitals = (e) => {
    // e.preventDefault();
    const bmi = (
      addvitals.weight /
      ((addvitals.height / 100) * (addvitals.height / 100))
    ).toFixed(2);
    if (
      addvitals?.height === undefined ||
      addvitals?.height === "" ||
      addvitals?.height === null ||
      addvitals?.height === "0"
    ) {
      toast("Please Enter Height");
      return;
    } else if (
      addvitals?.weight === undefined ||
      addvitals?.weight === "" ||
      addvitals?.weight === null ||
      addvitals?.weight === "0"
    ) {
      toast("Please Enter Weight");
      return;
    } else if (
      addvitals?.temperature === undefined ||
      addvitals?.temperature === "" ||
      addvitals?.temperature === null ||
      addvitals?.temperature === "0"
    ) {
      toast("Please Enter Temperature");
      return;
    } else if (
      addvitals?.respirationRate === undefined ||
      addvitals?.respirationRate === "" ||
      addvitals?.respirationRate === null ||
      addvitals?.respirationRate === "0"
    ) {
      toast("Please Enter Respiration Rate");
      return;
    } else if (
      addvitals?.systolic === undefined ||
      addvitals?.systolic === "" ||
      addvitals?.systolic === null ||
      addvitals?.systolic === "0"
    ) {
      toast("Please Enter Systolic");
      return;
    } else if (
      addvitals?.diastolic === undefined ||
      addvitals?.diastolic === "" ||
      addvitals?.diastolic === null ||
      addvitals?.diastolic === "0"
    ) {
      toast("Please Enter Diastolic");
      return;
    } else if (
      addvitals?.spo2 === undefined ||
      addvitals?.spo2 === "" ||
      addvitals?.spo2 === null ||
      addvitals?.spo2 === "0"
    ) {
      toast("Please Enter Pulse Ox");
      return;
    } else if (
      addvitals?.heartRate === undefined ||
      addvitals?.heartRate === "" ||
      addvitals?.heartRate === null ||
      addvitals?.heartRate === "0"
    ) {
      toast("Please Enter Heart Rate");
      return;
    } else if (
      addvitals?.bloodGlucose === undefined ||
      addvitals?.bloodGlucose === "" ||
      addvitals?.bloodGlucose === null ||
      addvitals?.bloodGlucose === "0"
    ) {
      toast("Please Enter Blood Glucose");
      return;
    }

    const payload = {
      patientCode: addvitals.patientCode,
      givenDate: addvitals.givenDate,
      createdBy: addvitals.createdBy,
      updatedBy: addvitals.updatedBy,
      status: addvitals.status,
      height: addvitals.height,
      weight: addvitals.weight,
      bloodGroup: addvitals.bloodGroup,
      temperature: addvitals.temperature,
      systolic: addvitals.systolic,
      diastolic: addvitals.diastolic,
      spo2: addvitals.spo2,
      heartRate: addvitals.heartRate,
      pulseRate: addvitals.pulseRate,
      respirationRate: addvitals.respirationRate,
      bloodGlucose: addvitals.bloodGlucose,
      bmi: addvitals.weight && addvitals.height ? bmi : "",
      bp: addvitals.bp,
      hdl: addvitals.hdl,
      ldl: addvitals.ldl,
      medication: addvitals.medication,
      notes: addvitals.notes,
      notes1: addvitals.notes1,
      notes2: addvitals.notes2,
      totalCholesterol: addvitals.totalCholesterol,
      triglycerides: addvitals.triglycerides,
    };
    console.log("Payload:", payload);
    dispatch(addPatientvitals(payload))
      .then((result) => {
        if (result) {
          toast("Vitals Added successfully", {
            autoClose: 1000,
            onClose: () => {
              closePopup();
              fetchAllVitals(patientCode);
            },
          });

          formRef.current.resetFields();
        }
      })
      .catch((error) => {
        console.error("Error saving vitals:", error);
      });
  };

  function handleSaveVitals() {}

  return (
    <>
      <ToastContainer />
      <div>
        <div className="h-auto px-2">
          <Form
            ref={formRef}
            layout="vertical"
            autoComplete="off"
            className="CommonForm grid md:grid-cols-3 md:gap-5"
          >
            <Form.Item
              className="mb-0"
              name="height"
              label={
                <span className="text-primaryColor">
                  <img src={BMI} alt="BMI" className="inline-block mr-2" />
                  Height <span className="text-[red] ">*</span>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Height is required.",
                },
                {
                  validator: (_, value) => {
                    if (!value || (value > 0 && value <= 213)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Height must be between 1 and 213 cm.")
                    );
                  },
                },
              ]}
            >
              <Input
                placeholder="Enter Height"
                name="height"
                onChange={handleChange}
                value={addvitals.height}
                addonAfter={<span style={{ marginLeft: "8px" }}>cm</span>}
              />
            </Form.Item>
            <Form.Item
              className="mb-0"
              name="weight"
              label={
                <span className="text-primaryColor">
                  <img
                    src={Weight}
                    alt="images"
                    className="inline-block mr-2"
                  />
                  Weight <span className="text-[red] ">*</span>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Weight is required.",
                },
                {
                  validator: (_, value) => {
                    if (!value || (value > 0 && value <= 200)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Weight must be between 1 and 200 kg.")
                    );
                  },
                },
              ]}
            >
              <Input
                placeholder="Enter Weight"
                name="weight"
                onChange={handleChange}
                value={addvitals.weight}
                addonAfter={<span style={{ marginLeft: "8px" }}>kg</span>}
              />
            </Form.Item>
            <Form.Item
              className="mb-0"
              name="temperature"
              label={
                <span className="text-primaryColor">
                  <img
                    src={Temperature}
                    alt="images"
                    className="inline-block mr-2"
                  />
                  Temperature <span className="text-[red] ">*</span>
                  {addvitals.temperature && (
                        <span class="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                          {addvitals.temperature >= 97 &&
                          addvitals.temperature <= 99 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              (Normal)
                            </span>
                          ) : (addvitals.temperature < 97 &&
                              addvitals.temperature >= 94) ||
                            (addvitals.temperature >= 100 &&
                              addvitals.temperature <= 106) ? (
                            <span
                              style={{ color: "orange", fontWeight: "bold" }}
                            >
                              (Abnormal)
                            </span>
                          ) : addvitals.temperature > 106 ||
                            addvitals.temperature < 94 ? (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              (Critical)
                            </span>
                          ) : null}
                        </span>
                      )}
                </span>
              }
            >
              <Input
                placeholder="Enter Temperature"
                name="temperature"
                onChange={handleChange}
                value={addvitals.temperature}
                addonAfter={<span style={{ marginLeft: "8px" }}>°F</span>}
              />
            </Form.Item>
            <Form.Item
              className="mb-0"
              name="respirationRate"
              label={
                <span className="text-primaryColor">
                  <img
                    src={RespirationRate}
                    alt="images"
                    className="inline-block mr-2"
                  />
                  Respiration Rate <span className="text-[red] ">*</span>
                  {addvitals.respirationRate && (
                        <span class="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                          {addvitals.respirationRate >= 12 &&
                          addvitals.respirationRate <= 18 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              (Normal)
                            </span>
                          ) : addvitals.respirationRate >= 19 &&
                            addvitals.respirationRate <= 25 ? (
                            <span
                              style={{ color: "orange", fontWeight: "bold" }}
                            >
                              (Abnormal)
                            </span>
                          ) : addvitals.respirationRate > 25 ? (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              (Critical)
                            </span>
                          ) : null}
                        </span>
                      )}
                </span>
              }
            >
              <Input
                placeholder="Enter Respiration rate"
                name="respirationRate"
                onChange={handleChange}
                value={addvitals.respirationRate}
                addonAfter={
                  <span style={{ marginLeft: "8px" }}>breaths/min</span>
                }
              />
            </Form.Item>
            <Form.Item
              className="mb-0"
              name="systolic"
              label={
                <span className="text-primaryColor">
                  <img
                    src={BloodPressure}
                    alt="images"
                    className="inline-block mr-2"
                  />
                  Blood Pressure (SYS) <span className="text-[red] ">*</span>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Systolic blood pressure is required",
                },
                {
                  type: "number",
                  min: 50,
                  max: 300,
                  message: "Systolic value must be between 50 and 300 mmHg",
                  transform: (value) => (value ? Number(value) : null),
                },
              ]}
            >
              <Input
                placeholder="Enter systolic"
                name="systolic"
                onChange={handleChange}
                value={addvitals.systolic}
                addonAfter={<span style={{ marginLeft: "8px" }}>mmHg</span>}
              />
            </Form.Item>
            <Form.Item
              className="mb-0"
              name="diastolic"
              label={
                <span className="text-primaryColor">
                  <img
                    src={BloodPressure}
                    alt="images"
                    className="inline-block mr-2"
                  />
                  Blood Pressure (DIA) <span className="text-[red] ">*</span>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Diastolic blood pressure is required",
                },
                {
                  type: "number",
                  min: 20,
                  max: 200,
                  message: "Diastolic value must be between 20 and 200 mmHg",
                  transform: (value) => (value ? Number(value) : null),
                },
              ]}
            >
              <Input
                placeholder="Enter diastolic"
                name="diastolic"
                onChange={handleChange}
                value={addvitals.diastolic}
                addonAfter={<span style={{ marginLeft: "8px" }}>mmHg</span>}
              />
            </Form.Item>

            <Form.Item
              className="mb-0"
              name="spo2"
              label={
                <span className="text-primaryColor">
                  <img
                    src={PulseOx}
                    alt="images"
                    className="inline-block mr-2"
                  />
                  Pulse Ox.<span className="text-[red] ">*</span>
                  {addvitals.spo2 && (
                        <span class="font-montserrat text-xs font-normal ml-1">
                          (
                          {addvitals.spo2 >= 95 && addvitals.spo2 <= 100 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Normal
                            </span>
                          ) : addvitals.spo2 >= 85 && addvitals.spo2 <= 94 ? (
                            <span
                              style={{ color: "orange", fontWeight: "bold" }}
                            >
                              Abnormal
                            </span>
                          ) : addvitals.spo2 < 85 ? (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Critical
                            </span>
                          ) : null}
                          )
                        </span>
                      )}
                </span>
              }
            >
              <Input
                placeholder="Enter Pulse"
                name="spo2"
                onChange={handleChange}
                value={addvitals.spo2}
                addonAfter={<span style={{ marginLeft: "8px" }}> %</span>}
              />
            </Form.Item>
            <Form.Item
              className="mb-0"
              name="heartRate"
              label={
                <span className="text-primaryColor">
                  <img
                    src={HeartRate}
                    alt="images"
                    className="inline-block mr-2"
                  />
                  Heart Rate <span className="text-[red] ">*</span>
                  {addvitals.heartRate && (
  <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
    (
    {addvitals.heartRate >= 60 && addvitals.heartRate <= 100 ? (
      <span style={{ color: "green", fontWeight: "bold" }}>Normal</span>
    ) : addvitals.heartRate >= 101 && addvitals.heartRate <= 119 ? (
      <span style={{ color: "orange", fontWeight: "bold" }}>Abnormal</span>
    ) : addvitals.heartRate >= 120 ? (
      <span style={{ color: "red", fontWeight: "bold" }}>Critical</span>
    ) : null}
    )
  </span>
)}
                </span>
              }
              
            >
            
              <Input
                placeholder="Enter heart rate"
                name="heartRate"
                onChange={handleChange}
                value={addvitals.heartRate}
                addonAfter={
                  <span style={{ marginLeft: "8px" }}>Beats/min</span>
                }
              />
            </Form.Item>
            <Form.Item
              className="mb-0"
              name="bloodGlucose"
              label={
                <span className="text-primaryColor">
                  <img
                    src={BloodPressure}
                    alt="images"
                    className="inline-block mr-2"
                  />
                  Blood Glucose<span className="text-[red] ">*</span>
                </span>
              }
            >
              <Input
                placeholder="Please enter your Glucose"
                name="bloodGlucose"
                onChange={handleChange}
                value={addvitals.bloodGlucose}
                addonAfter={<span style={{ marginLeft: "8px" }}>mg/dL</span>}
              />
            </Form.Item>
          </Form>
          <div className="flex justify-end">
            <button
              onClick={savevitals}
              className="border bg-primaryColor text-White w-[30%] py-3 mt-8 rounded-lg text-base"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewVitals;
