import { Modal } from "antd";
const ViewUploadedPrescriptionModel = (props) => {
  const { isOpen, prescriptionUrl, handleClose } = props;
  return (
    <Modal
      title="Prescription"
      centered={true}
      footer={false}
      className="commonModal"
      open={isOpen}
      width="50%"
      onOk={handleClose}
      onCancel={handleClose}
    >
      <div className="flex justify-center">
        {prescriptionUrl.split(".")[1].toUpperCase() == "PDF" ? (
          <iframe
            style={{ width: "100%", height: "500px" }}
            src={`${process.env.REACT_APP_IMG_BASEURL}${prescriptionUrl}`}
            alt="profile image"
            class="w-12"
          />
        ) : (
          <img
            style={{ width: "80%", height: "500px" }}
            src={`${process.env.REACT_APP_IMG_BASEURL}${prescriptionUrl}`}
            alt="profile image"
            class="w-12"
          />
        )}
      </div>
    </Modal>
  );
};

export default ViewUploadedPrescriptionModel;
