import http from '../../../../Redux/Services/http-common'

export const getCartData = async (param) =>{
    // var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    let response =  await http.get(`PatientCart/list?patientId=${param}&status=1`);
    try {
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const getAddressDetails = async (patientCode) =>{
    let response =  await http.get(`AddressBook/list/filter?patientId=${patientCode}`);
    try {
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const getUploadedPrescription = async (query) =>{
    let queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
    let response = await http.get(`PatientCart/PrescriptionDoc?${queryString}`);
    try {
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const getEPrescrptionList = async (query) => {
    let queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
    let response = await http.get(`Visit/list?${queryString}`);
    try {
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  export const uploadPrescriptionDoc = async (orderId,data) => {
    let response = await http.put(`PatientCart/UploadPrescriptionDoc/${orderId}`, data);
    try {
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
  }
  
  export const deleteUploadedPrescripiton = async (query) => {
    let queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
    let response = await http.get(`Prescription/deletePrescription?${queryString}`);
    try {
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
  }  