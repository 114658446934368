import IndustryLead from "../IndustryLead"

const Partners = ({data}) =>{
    return(
        <section className="bg-primaryColor">
        <div className="container mx-auto py-[50px] md:py-[90px] relative">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[50px]">
            Industry Leading{" "}
            <span className="text-secondaryColor">Partners</span>
          </h2>
          <div className="md:w-[80%] mx-auto">
            <IndustryLead data = {data} />
          </div>
        </div>
      </section>
    )
}

export default Partners