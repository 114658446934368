import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Modal, Select, message } from 'antd';
import TextArea from "antd/es/input/TextArea";
import { Option } from "antd/es/mentions";
import http from "../../Redux/Services/http-common";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";


function Contactus() {


    const formRef = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("India");
    const patientinfo = useSelector((state) => state.patientinfo);
    const userData = useSelector((state) => state.authReducer.patientData);
    const [profile, setProfile] = useState(userData);
    const { patientinfoData, isLoading, isSuccess } = patientinfo;
    const [dialingCode, setDialingCode] = useState("");
    console.log('dddf', profile);

    const handleSubmit = async () => {
        const formValues = formRef.current.getFieldsValue();
        console.log('Form values:', formValues);
        const { name, subject, city, country,email,phoneNumber } = formValues;

        if (country === undefined || country === "") {
            if (selectedCountry === undefined || selectedCountry === "") {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (subject === undefined || subject === "") {
            message.error("Please fill in all fields");
            return;
        }

        if (profile.firstName === undefined || profile.firstName === "" || profile.firstName === null) {
            if (name === undefined || name === "") {
                message.error("Please fill in all fields");
                return;
            }
        }

        if (profile.city === undefined || profile.city === "" || profile.city === null) {
            if (city === undefined || city === "") {
                message.error("Please fill in all fields");
                return;
            }
        }

        let payload;

        if(Object.keys(userData).length === 0){
            if (email === undefined || email === "") {
                message.error("Please fill in all fields");
                return;
              }
              if (phoneNumber === undefined || phoneNumber === "") {
                message.error("Please fill in all fields");
                return;
              }
              payload = {
                subject,
                contactPerson: name ? name : profile.firstName,
                email,
                phoneNumber,
                city: city ? city : profile.city,
                state: selectedCountry,
                type:"Touch"
            }
        }
        else{
            payload = {
                subject,
                contactPerson: name ? name : profile.firstName,
                email: profile.email,
                phoneNumber: profile.mobile,
                city: city ? city : profile.city,
                state: selectedCountry,
                type:"Touch"
            }
        }


       
        try {
            const res = await http.post('partnerEnquiryForm/helpUs',payload);
            console.log(res);
            formRef.current.resetFields();
            message.success("Thank You! Our support team will contact you shortly.");

        } catch (error) {
            console.log(error);
            message.error("Error Occurred");
        }

    }



    useEffect(() => {
        fetchCountryList();
    }, []);

    const defaultCountry = countryList.find(country => country.description === "India");

    const fetchCountryList = async () => {
        try {
            const response = await http.get('country/list');
            setCountryList(response.data);
        } catch (error) {
            console.error('Error fetching country list:', error);
        }
    };

    // const handleCountryChange = (value) => {
    //     setSelectedCountry(value);
    // };

    const handleCountryChange = (value) => {
        const selectedCountry = countryList.find(country => country.description === value);
        setSelectedCountry(value);
        setDialingCode(selectedCountry.dialingCode);
    };



    // const PrescriptionModal = () => {

    //     return (
    //         <div className="rounded-xl bg-lightGray p-3">
    //             <Modal
    //                 centered
    //                 width={'25%'}
    //                 open={isModalOpen}
    //                 onOk={handleModal}
    //                 footer={[]}

    //             >
    //                 <div className="flex justify-center items-center">
    //                     <p className="text-lg font-normal"><strong>Thank You!</strong><br /> Our support team will contact you shortly.</p>
    //                 </div>
    //             </Modal>
    //         </div>
    //     )
    // }




    return (
        <>
            <ToastContainer />
            <div>
                <Form ref={formRef} layout="vertical" autoComplete="off" className="homeForm">
                    <Form.Item name="name" label={<span>Full Name<span className="text-danger">*</span></span>}>
                        <Input placeholder="Please enter your name" defaultValue={profile.firstName} />

                    </Form.Item>
                    {/* <Form.Item name="country" label="Country" >
                        <Select placeholder="Please select your country" style={{ width: '100%', height: '50px' }}>
                            <Option value="India">India</Option>
                        </Select>
                    </Form.Item> */}

                    <Form.Item name="country" label="Country">
                        <Select
                            placeholder="Please select your country"
                            style={{ width: '100%', height: '50px' }}
                            defaultValue="India"
                            onChange={handleCountryChange}
                        >
                            {countryList.map(country => (
                                <Option key={country.id} value={country.description}>{country.description}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="city" label="City">
                        <Input placeholder="Please enter your city" defaultValue={profile.city} />
                    </Form.Item>
                    <Form.Item name="email" label={<span>Email Address<span className="text-danger">*</span></span>}>
                        <Input  disabled={Object.keys(userData).length === 0?false:true} placeholder="Please enter your email" defaultValue={profile.email} />
                    </Form.Item>
                    <Form.Item name="phoneNumber" label={<span>Phone Number<span className="text-danger">*</span></span>}>
                        <Input
                             disabled={Object.keys(userData).length === 0?false:true}
                            // addonBefore={selectedCountry === 'IND' ? '+91' : undefined}
                            addonBefore={dialingCode ? dialingCode : '+91'}
                            placeholder="Please enter your number"
                            defaultValue={profile.mobile}
                            maxLength={10}
                        />
                    </Form.Item>
                    <Form.Item name="subject" label="Query">
                        <TextArea placeholder="Please enter your query" />
                    </Form.Item>
                    <button className="primary_btn !w-full mt-5 !h-[50px] !text-lg" onClick={handleSubmit}>Send</button>
                </Form>
            </div>
        </>

    )
}

export default Contactus;