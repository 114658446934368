import React,{useState,useEffect} from "react";
import moment from "moment"
// import rximg from "../Assets/Images/rx.png"
import { jsPDF } from "jspdf";
import { Checkbox } from 'primereact/checkbox';


async function getbase64 (){
  const input = document.getElementById("abc");
  console.log(input, "Sdvdsiudsgviudsvg")
  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "px",
    format: "A4",
    userUnit: "px",
  });
  let base64
  await pdf.html(input, { html2canvas: { scale: 0.5, allowTaint: false, }, margin: [1, 1, 1, 1], autoPaging: true }).then(() => {
    const fileName =
      "e-Prescription-" + Math.random() + ".pdf";
    console.log(fileName);
    let file =  pdf.output("datauri")
     base64 = file.split(",")[1]
   
})
return base64;
}

const PrescriptionTempate = (props) =>{
  
    const [checked, setChecked] = useState(false);
    const {selectedPrescription, cart , index,handlePrescriptionSelectClick , prescriptionType, originalPrescription} = props
    console.log(selectedPrescription, "sdvdsvkdsgvskdgvhds")

    const [signatureBase64, setSignatureBase64] = useState(null);
    const [stampBase64, setStampBase64] = useState(null);
  
    useEffect(() => {
      // Convert images to base64 on component mount
      if (selectedPrescription[0]?.userSignature) {
        getbase64(`${process.env.REACT_APP_IMG_BASEURL}${selectedPrescription[0]?.userSignature}`).then(dataUrl => {
          setSignatureBase64(dataUrl);
        });
      }
      if (selectedPrescription[0]?.hospitalStamp) {
        getbase64(`${process.env.REACT_APP_IMG_BASEURL}${selectedPrescription[0]?.hospitalStamp}`).then(dataUrl => {
          setStampBase64(dataUrl);
        });
      }
    }, [selectedPrescription]);

     const handleClick = async (checked) =>{
        if(cart){
            setChecked(checked)
            let input = ""
            if(index){
               input = document.getElementById(index);
            } else{
               input = document.getElementById("abc");
            }
            const pdf = new jsPDF({
              orientation: "portrait",
              unit: "px",
              format: "A4",
              userUnit: "px",
            });
            await pdf.html(input, { html2canvas: { scale: 0.5, allowTaint: false, }, margin: [1, 1, 1, 1], autoPaging: true }).then(() => {
              const fileName =
                "e-Prescription-" + selectedPrescription[0]?.patientName + ".pdf";
              console.log(fileName);
              let file =  pdf.output("datauri")
              let base64 = file.split(",")[1]
              handlePrescriptionSelectClick(index, checked, base64)
            //   console.log(base64, "dsvihdsiuvgsiuvysd")
            //   return base64
              // pdf.save(fileName);
  
            });
        }
     } 

     const handleDownload = async () => {
      let input = index ? document.getElementById(index) : document.getElementById("abc");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "A2",
        userUnit: "px",
      });
      await pdf.html(input, { 
        html2canvas: { scale: 1, useCORS: true, allowTaint: false }, 
        margin: [10, 10, 10, 10], 
        autoPaging: true 
      }).then(() => {
        const fileName = "e-Prescription-" + selectedPrescription[0]?.patientName + ".pdf";
        pdf.save(fileName);
      });
    };

     const handleOriginalPrescriptionClick = (checked, prescription) =>{
      setChecked(checked)
      handlePrescriptionSelectClick(index, checked, prescription)
     }
    
     if(originalPrescription){
      let type = originalPrescription.split(".")[1];
      if(type == "pdf"){
        return(
          <div style={checked ? {border: "4px solid rgb(79, 175, 217)"} : cart ?  {height: "30rem"} : {}} className="w-full mb-4 h-full  overflow-x-auto cursor-pointer py-4 px-4 relative">
           {cart ? <Checkbox className= {`absolute z-10 ${checked ? "-top-2  -left-2" : "top-2 left-2"}`} onChange={e => handleOriginalPrescriptionClick(e.checked, originalPrescription)} checked={checked}></Checkbox> : null}
          <iframe                       
          height="100%"
          width="100%"
          className="border rounded cursor-pointer mr-4"
          src={`${process.env.REACT_APP_IMG_BASEURL}${originalPrescription}`} />
          </div>
        )
      } else{
        return(
          <div style={checked ? {border: "4px solid rgb(79, 175, 217)"} : {}} className="w-full mb-4  overflow-x-auto cursor-pointer py-4 px-4 relative border flex justify-center">
           {cart ? <Checkbox className= {`absolute z-10 ${checked ? "-top-2  -left-2" : "top-2 left-2"}`} onChange={e => handleOriginalPrescriptionClick(e.checked, originalPrescription)} checked={checked}></Checkbox> : null}
          <img className="w-3/5" src = {`${process.env.REACT_APP_IMG_BASEURL}${originalPrescription}`} />
        </div>
        )
      }

     
     }

    return(
      <>
        <div id= {index ? index : "abc"} key = {index} className="w-full mb-4  overflow-x-auto cursor-pointer py-4 px-4">
        <div
            style={checked ? {border: "4px solid rgb(79, 175, 217)"} : {}}
          className="p-5 bg-white-100 relative justify-center  border rounded-xl dark:border-slate-700 prescriptionTemplate"
        //   style={{width:"1330px"}}
        >
           {cart ? <Checkbox className= {`absolute z-10 ${checked ? "-top-2  -left-2" : "top-2 left-2"}`} onChange={e => handleClick(e.checked)} checked={checked}></Checkbox> : null}

          {/* <span>{JSON.stringify(selectedPrescription)}</span> */}
          <div className="px-2">
            <div className="flex justify-between mb-4  ml-1">
            </div>

            <div className="lg:flex flex flex-col">
              <h6 className="font-bold doc-font ">
                {selectedPrescription[0]?.userSalutation ? selectedPrescription[0]?.userSalutation :"Dr."}{" "}
                {selectedPrescription[0]?.userName}
              </h6>
              <h6 className="font-bold doc-font">
                {selectedPrescription[0]?.userQualification}
              </h6>
              <h6 className="font-bold ">
                {selectedPrescription[0]?.userSpecialityDept}
              </h6>
              <h6 className="font-bold ">
                Regn No:{selectedPrescription[0]?.userMCIVerification}
              </h6>
              {/* <hr className="my-2" /> */}
              <hr className="hr-line mt-6" />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-6 mb-5 mt-6">
              <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                Name:
              </div>
              <div className="border text-xs  dark:border-slate-700 px-3 py-1">
                {selectedPrescription[0]?.patientsalutation +
                  selectedPrescription[0]?.patientName}
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                Age/Sex:
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1">
                {selectedPrescription[0]?.patientAge +
                  " / " +
                  (selectedPrescription[0]?.patientgender === "M"
                    ? "Male"
                    : "Female")}
              </div>

              {selectedPrescription[0]?.patientHospitalName === null ?" ":

              (
                <>
                <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                Hospital:
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1">
                {selectedPrescription[0]?.patientHospitalName}
              </div>
              </>

              )}
             
              <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                Phone Number:
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1">
                {selectedPrescription[0]?.patientMobileNo}
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                Visit Type:
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1">
                {selectedPrescription[0]?.consultationType === "Q"
                  ? "Quick Consultation"
                  : selectedPrescription[0]?.consultationType === "V"
                    ? "Video Consultation"
                    : selectedPrescription[0]?.consultationType === "I"
                      ? "In-person Consultation"
                      : selectedPrescription[0]?.consultationType === "A"
                        ? "Audio Consultation"
                        : "Quick Consultation"}
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                Visit Date:
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1" v>
                {moment(selectedPrescription[0]?.visitDate).format("DD/MM/yyyy")}
              </div>
            </div>
            {/* <img src={rximg} style={{ width: "25px" }} /> */}

            {selectedPrescription[0]?.consultReason &&
              <div className="grid grid-cols-1 my-2">
                <p>
                  <b className="underline underline-offset-1">
                    Chief Complaint:
                  </b>{" "}
                  {selectedPrescription[0]?.consultReason}
                </p>
              </div>
            }

            {(selectedPrescription[0]?.primarySymptoms || selectedPrescription[0]?.primaryDiagnosis) &&
              <div className="grid grid-cols-1 my-2">
                <p className="">
                  <b className="underline underline-offset-1">
                    Primary Diagnosis :
                  </b>{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  ICD-10 : {selectedPrescription[0]?.primarySymptoms}{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  Description : {selectedPrescription[0]?.primaryDiagnosis}{" "}
                </p>
              </div>
            }
            {(selectedPrescription[0]?.secondarySymptoms || selectedPrescription[0]?.secondaryDiagnosis) &&
              <div className="grid grid-cols-1 my-2">
                <p className="">
                  <b className="underline underline-offset-1">
                    Secondary Diagnosis :
                  </b>{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  ICD-10 : {selectedPrescription[0]?.secondarySymptoms}{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  Description : {selectedPrescription[0]?.secondaryDiagnosis}{" "}
                </p>
              </div>
            }
            {selectedPrescription[0]?.symptoms &&
              <div className="mb-5">
                <p className="underline underline-offset-1">
                  <b className="underline underline-offset-1">Symptoms :</b>{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  {selectedPrescription[0]?.symptoms}
                </p>
              </div>
            }
            {selectedPrescription[0]?.patientVitalList && selectedPrescription[0]?.patientVitalList.length && (<>
              <div className="grid grid-cols-1 my-2">
                <p>
                  <b className="underline underline-offset-1">
                    Vitals :
                  </b>{" "}
                  {selectedPrescription[0]?.patientVitalList[0].height && (<> Height   - {selectedPrescription[0]?.patientVitalList[0].height} CM  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedPrescription[0]?.patientVitalList[0].weight && (<> Weight   -  {selectedPrescription[0]?.patientVitalList[0].weight}  KG  </>)}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedPrescription[0]?.patientVitalList[0].bmi && (<> BMI   -  {selectedPrescription[0]?.patientVitalList[0].bmi}  KG/M2  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedPrescription[0]?.patientVitalList[0].systolic && (<> Blood Pressure (SYS)  &nbsp;&nbsp; -  {selectedPrescription[0]?.patientVitalList[0].systolic}  mmHg  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedPrescription[0]?.patientVitalList[0].diastolic && (<> Blood Pressure (DIA)   -  {selectedPrescription[0]?.patientVitalList[0].diastolic}  mmHg  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedPrescription[0]?.patientVitalList[0].spo2 && (<> Pulse Ox.   -  {selectedPrescription[0]?.patientVitalList[0].spo2}  %  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedPrescription[0]?.patientVitalList[0].heartRate && (<> Heart Rate   -  {selectedPrescription[0]?.patientVitalList[0].heartRate}  Beats/min  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedPrescription[0]?.patientVitalList[0].respirationRate && (<> Respiration Rate   -  {selectedPrescription[0]?.patientVitalList[0].respirationRate}  Breaths/min  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {selectedPrescription[0]?.patientVitalList[0].temperature && (<> Temperature   -  {selectedPrescription[0]?.patientVitalList[0].temperature}  °F  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
              </div>
            </>)}


            <div className="mb-2 overflow-scroll">
               {selectedPrescription[0]?.patientDrugPrescriptionList?.length &&
              // selectedPrescription?.length &&   
              //  selectedPrescription[0]?.patientDrugPrescriptionList.map((res, i)=> (
              //   {selectedPrescription[0]?.patientDrugPrescriptionList?.length &&
                <>
                  <p className="  mb-2 underline underline-offset-1">
                    <b className="underline underline-offset-1">
                      Medicines Prescribed :
                    </b>{" "}
                  </p>


                  <table className="table-auto w-full mb-5">
                    <thead>
                      <tr>
                        <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left"></th>
                        <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                          Medication
                        </th>
                        <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                          Dosage
                        </th>
                        <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                          Qty
                        </th>
                        <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                          Instruction
                        </th>
                        {/* <th className="border-2 border-inherit">Periodically</th> */}
                        <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                          Duration
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        // selectedPrescription?.map((res, i) 
                        selectedPrescription[0]?.patientDrugPrescriptionList.map((res, i)=> (
                          <>
                            <tr>
                              <td className="border dark:border-slate-700 px-3 py-1">
                                {i + 1}
                              </td>
                              <td className="border dark:border-slate-700 px-3 py-1">
                                {res?.drugName}
                                <br></br>
                                <span className="text-xs">{res?.composition}</span>
                              </td>
                              <td className="border dark:border-slate-700 px-3 py-1">
                                {res?.dosage}
                              </td>
                              <td className="border dark:border-slate-700 px-3 py-1">
                                {res?.quantity}
                              </td>
                              <td className="border dark:border-slate-700 px-3 py-1">
                                {res.instruction == "0"
                                  ? "After Meal"
                                  : "Before Meal"}
                              </td>
                              <td className="border dark:border-slate-700 px-3 py-1">
                                {" "}
                                {res?.duration} days
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                </>
              }
              <div className="mt-2  mb-5">
                {selectedPrescription[0]?.patientLabTestsList?.length &&
                  <p className="  underline underline-offset-1  mb-2">
                    <b className="underline underline-offset-1">
                      Lab Test Prescribed :
                    </b>{" "}
                  </p>
                }
                {selectedPrescription[0]?.patientLabTestsList?.length &&
                 
                    <div>
                      <div className="">
                        <div className="text-md font-bold font-rubik text-left border dark:border-slate-700 px-3 py-1">
                         Test Name
                        </div>
                        {/* <div className="text-md font-bold font-rubik  text-left border dark:border-slate-700 px-3 py-1">
                          Test Type
                        </div> */}
                      </div>
                         { selectedPrescription[0]?.patientLabTestsList.map((labres, i) => ( <div
                            className=" "
                            key={i}
                          >
                            <div className="text-sm  border dark:border-slate-700 px-3 py-1">
                              {labres?.labTestName}
                            </div>
                            {/* <div className="text-sm  text-left border dark:border-slate-700 px-3 py-1">
                              {labres?.testType}
                            </div> */}
                          </div>))}
                        {/* ))} */}
                    </div>
                  }
              </div>
            </div>
            <div className="my-2">
              <div className="w-6/6  my-2">
                {/* <div>
                  <p className="">Cheif Complaint</p>
                  <p className="text-base font-montserrat  font-thin ">
                    {selectedPrescription?.consultReason}
                  </p>
                </div> */}

                {selectedPrescription[0]?.recommendation && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">
                      <b className="underline underline-offset-1">
                        Recommendation :
                      </b>{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {selectedPrescription[0]?.recommendation}
                    </p>
                  </div>
                )}

                {selectedPrescription[0]?.followUpVisitDate && (
                  <div>
                    <p className=" my-2">
                      <b className="underline underline-offset-1">
                        Scheduled follow up visit date :
                      </b>{" "}
                      {moment(selectedPrescription[0]?.followUpVisitDate).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex  justify-between">


              <div className="mt-6">
                {selectedPrescription[0]?.hospitalStamp &&
                  <img
                    // onClick={download}
                    // src={`https://storage.googleapis.com/curebay-nonprod-application-data/${selectedPrescription[0].hospitalStamp}`}
                    src = {`${process.env.REACT_APP_IMG_BASEURL}${selectedPrescription[0].hospitalStamp}`}
                    alt="Stamp"
                    width={150}
                  />
                }
              </div>

              <div className="mt-6">
                {selectedPrescription[0]?.userSignature && (
                  <>
                    <img
                      // onClick={download}
                     // src={`https://storage.googleapis.com/curebay-nonprod-application-data/${selectedPrescription[0].userSignature}`}
                      src = {`${process.env.REACT_APP_IMG_BASEURL}${selectedPrescription[0].userSignature}`}
                      alt="Signature"
                      width={150}
                    />
                    <p className="font-rubik text-gray-primary text-sm">
                      Dr.{selectedPrescription[0]?.userName}
                    </p>
                    <p className="font-rubik text-gray-primary text-sm">
                      {selectedPrescription[0]?.userQualification}{" "}
                      {selectedPrescription[0]?.userSpecialityDept}
                    </p>
                    <p className="font-rubik text-gray-primary text-sm">
                      Regn No. {selectedPrescription[0]?.userMCIVerification}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 ">
            <div className="px-1 py-1">

              {
                selectedPrescription[0]?.consultationType === "I" ?
                  (
                    <span> </span>

                  ) :
                  (
                    <p className="text-gray-500 text-sm ">
                      <b>Disclaimer :</b>
                      {prescriptionType == "ePrescription" ? <ul className="list-disc ml-8">
                        <li>The information and advice provided here is provisional in nature as it is based on the limited information made available by the patient</li>
                        <li>The patient is advised to visit in person for thorough examination at the earliest</li>
                        <li>The information is confidential in nature and for recipient's use only</li>
                        <li>The Prescription is generated on a Teleconsultation</li>
                        <li>Not valid for medico - legal purpose</li>
                        {" "}
                      </ul> :  <li className="font-sans text-xs  marker:text-sky-400">
          Our pharmacist has reviewed the prescription and has identified the
          medicine/ lab test based on our understanding of the uploaded
          prescription. You need to confirm medical accuracy with your doctor
          and CureBay does not take responsibility for the translation.
        </li>}
                    </p>
                  )
              }


            </div>
          </div>
          <div className="grid grid-cols-1 ">
            <div className="px-1 py-1">
              <p className="text-gray-500 text-sm ">

              </p>
            </div>
          </div>
        </div>
        </div>
        <button onClick={handleDownload} style={{ backgroundColor: "#66B889",color:"#ffff", borderRadius: "5px", maxWidth: "fit-content" }} className="text-white p-2 font-semibold text-xs mx-4 mb-8">Download</button>
         </>
    )
}

export {PrescriptionTempate , getbase64}