import { useState, useEffect } from "react";

const useDetectDevice = () => {
const [deviceType, setDeviceType] = useState(''); // Holds the current device type
const detectDevice = () => {
    const width = window.innerWidth;
    if (width < 768) {
      setDeviceType('Mobile');
    } else if (width >= 768 && width < 1200) {
      setDeviceType('Tablet');
    } else {
      setDeviceType('Desktop');
    }
  };

  useEffect(() => {
    // Run on initial render
    detectDevice();

    // Add event listener to detect changes on resize
    window.addEventListener('resize', detectDevice);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', detectDevice);
  }, []);
  return deviceType
};

export default useDetectDevice

