import React, { useState } from "react";
import { useSelector } from "react-redux";
const useAddressInput = () => {
  const userData = useSelector((state) => state.authReducer.patientData);
  const [addressString, setAddressString] = useState({});

  const onAddressInputChange = (e) => {
    console.log(e, "woihvoiwevoievoigewovgoiegvew");
    if (e.target.type == "checkbox") {
      setAddressString({
        ...addressString,
        ["isDefault"]: e.target.checked ? 1 : 0,
      });
    } else {
      setAddressString({
        ...addressString,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onAddressTypeSelect = (type) => {
    setAddressString({
      ...addressString,
      ["type"]: type,
    });
  };

  const savePreFetchedValues = (data) => {
    console.log(data, "sdviosdhvoisdhvds");
    setAddressString({
      patientId: userData.code,
      createdBy: userData.code,
      modifiedBy: userData.code,
      status: 1,
      houseNo: data.houseNumber,
      city: data.city,
      landmark: data?.landmark,
      formatted_address: data?.formatted_address,
      lattitude: data.lat,
      longitude: data.lng,
      pincode: data.pincode,
      state: data.state,
      address1: data.street,
      address2: data.subLocality,
      address: data?.street + " " + data?.subLocality + " " + data?.locality,
    });
  };

  const editSelectedAddress = (data) => {
    console.log(data, "sdviosdhvoisdhvds");
    setAddressString({
      patientId: userData.code,
      createdBy: userData.code,
      modifiedBy: userData.code,
      status: 1,
      houseNo: data.address1,
      city: data.city,
      landmark: data?.landmark,
      formatted_address: data?.address1 + " " + data?.address2,
      lattitude: data?.lattitude,
      longitude: data?.longitude,
      pincode: data.pinCode,
      state: data.state,
      address1: data.address1,
      address2: data.address2,
      address: data?.address2,
      isDefault: data.isDefault,
      type: data.type

    });
  };

  return [
    addressString,
    onAddressInputChange,
    onAddressTypeSelect,
    savePreFetchedValues,
    editSelectedAddress
  ];
};

export default useAddressInput;
