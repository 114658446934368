function cookieFinder(str){
    if(str == undefined){
        return false
    }
    let splitData = str.split("&")
    if(splitData){
        let furtherSplit = splitData.filter(res => !res.search("utm_"))
        if(furtherSplit.length){
            let data = furtherSplit.map(res => res.split("="))
    
    let compaginurl = {}
     data.map((res , index) =>{
        compaginurl[res[0]] = res[1]
    })
    return compaginurl
    }
}
}

export default cookieFinder
