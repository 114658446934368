import http from '../../../../Redux/Services/http-common'

// export const getCouponList = async (patientId, pageRef) =>{
//     // var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
//     let response =  await http.get(`Vouchery/campaign/vouchers/${pageRef}?patientId=${patientId}`)
//     try {
//         return Promise.resolve(response);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// }

export const applyCouponCode = async (data) =>{
    // var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    let response =  await http.post(`Vouchery/redemptions/voucher`, data)
    try {
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
}