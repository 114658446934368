import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import Tablet from "../../../Assets/Images/tablets.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation, useParams } from "react-router-dom";
import Bottles from "../../../Assets/pharamacyImages/Bottles.png";
import Capsules from "../../../Assets/pharamacyImages/Capsules.png";
import Creams from "../../../Assets/pharamacyImages/Creams.png";
import Curebay from "../../../Assets/pharamacyImages/Curebay.png";
import Devices from "../../../Assets/pharamacyImages/Devices.png";
import Dispensers from "../../../Assets/pharamacyImages/Dispensers.png";
import Droplets from "../../../Assets/pharamacyImages/Droplets.png";
import Emulsions from "../../../Assets/pharamacyImages/Emulsions.png";
import Injections from "../../../Assets/pharamacyImages/Injections.png";
import Lotions from "../../../Assets/pharamacyImages/Lotions.png";
import Satchels from "../../../Assets/pharamacyImages/Satchels.png";
import defaultMed from "../../../Assets/pharamacyImages/Medicines.jpg";
import Noteimage from "../../../Assets/Images/Icons/note.svg";
import { useDispatch, useSelector } from "react-redux";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import ReliableImage from "../../../Assets/Images/Cart/reliable.svg";
import SecureImage from "../../../Assets/Images/Cart/safe-secure.svg";
import GenuineImage from "../../../Assets/Images/Cart/geniun-products.svg";
import http from "../../../Redux/Services/http-common";
import {
  AddtoCart,
  getCartDetails,
} from "../../../Redux/Action/cartPlaceOrderAction";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getAllProductByIds } from "../../../Redux/Action/pharmacyAction";
import Slider from "react-slick";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import Loader from "../../Shared/loader";
import { Helmet } from "react-helmet";
import { EnglishText } from "../../PageRouting/EnglishText";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { trackAddToCartEvent } from "../../utils/analytics/events";
import { Button, Skeleton } from "antd";
import CurebayButton from "../../Shared/Button/PrimaryButton";
import "react-toastify/dist/ReactToastify.css";

function ProductInfoPage(props) {
  const userData = useSelector((state) => state.authReducer.patientData);
  const [quantity, setQuantity] = useState(1);
  const { medicine: medicineseoId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [recentlyOrderMedicine, setRecentlyOrderMedicine] = useState([]);
  const [medicineDetail, setMedicineDetail] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [isAdding, setIsAdding] = useState(-1);
  const { cartList } = useSelector((state) => state.cartReducer);
  const [expectedDelivery, setExpectedDelivery] = useState();
  const [grandTotal, setGrandTotal] = useState();
  const [currIdx, setCurrIdx] = useState(0);
  const [alternateMedicine, setAlternativeMedicineData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [medicineImages, setMedicineImages] = useState();
  const [selectedImage, setSelectedImage] = useState(true);
  const [big, setBig] = useState(null);
  const handleScroll = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  useEffect(() => {
    handleScroll();
    const medicineTotal =
      cartList?.patientMedicineOrder?.length > 0 &&
      cartList?.patientMedicineOrder[0]?.totalAmount;
    const labTotal =
      cartList?.patientLabTestsOrder?.length > 0 &&
      cartList.patientLabTestsOrder[0]?.totalAmount;
    const grandTotal = medicineTotal + labTotal;
    setGrandTotal(grandTotal);
  }, []);

  useEffect(() => {
    const hour = new Date().getHours();
    console.log(typeof hour);
    if (hour > 20 || hour < 8) {
      setExpectedDelivery("11 AM");
    } else {
      if (hour > 12) {
        const time = hour - 12;
        setExpectedDelivery(`${time + 3} PM`);
      } else {
        const time = hour + 3;
        if (time > 12) {
          setExpectedDelivery(`${time - 12} PM`);
        } else {
          setExpectedDelivery(`${time} AM`);
        }
      }
    }
  }, []);

  const cartCount = (list) => {
    let count = 0;
    list?.patientMedicineOrder?.forEach((item) => {
      count = count + item.patientMedicineOrderDetailsList.length;
    });

    list?.patientLabTestsOrder?.forEach((item) => {
      count = count + item.patientLabTestsOrderDetailsList.length;
    });
    //  list?.patientLabTestsOrder.forEach(item => {
    //   count
    //  });
    return count;
  };

  useEffect(() => {
    if (medicineseoId) {
      getMedicineDetail();
    }
  }, [medicineseoId]);

  const getMedicineDetail = () => {
    http
      .get(`${process.env.REACT_APP_ELASTIC_BASEURL}medicine/${medicineseoId}`)
      .then((res) => {
        if (res.status == 200) {
          setMedicineDetail(res.data);
          getAlternativeMedicine(res.data);
        }
        console.log("MedicineDetail: ", res);
      });
  };

  const getAlternativeMedicine = async ({ id, composition }) => {
    const alternateMedicine = await http.get(
      `${process.env.REACT_APP_ELASTIC_BASEURL}medicine/alternativeMedicine?medicineId=${id}&content=${composition} `
    );
    if (alternateMedicine.data) {
      setAlternativeMedicineData(alternateMedicine.data);
    } else {
      setAlternativeMedicineData([]);
    }

    window.scrollTo(0, 0);
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const addTocart = (e, data, index = 0, quantity = 1) => {
    console.log(data, "Data");

    let payload = {
      patientId: userData?.code,
      drugCode: data?.id,
      drugName: data?.medicineName,
      drugCategory: data?.drugCategory,
      medicineTypeOfSell: data?.medicineTypeOfSell,
      medicineType: "",
      unitPrice: Number(data?.medicineRate),
      quantity: quantity,
      prescriptionRequired: data?.prescriptionRequired,
      vendorId: data?.vendorId,
    };
    console.log("Payload", payload);

    fetch(`${process.env.REACT_APP_BASEURL}newPatientMedicine/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res, "vghsdvhcvdsg");
        trackAddToCartEvent(data?.medicineRate, data?.medicineName);
        // setIsLoading(false);
        dispatch(getCartDetails(userData?.code));
        toast.success("Item added to cart successfully!", {
          // position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        console.error("Error adding to cart:", err);
        // Show error toast
        toast.error("Something went wrong. Couldn't add to cart.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        // setIsLoading(false);
      });
  };

  console.log(medicineseoId, "Sdvdsihousdguvdsvds");

  useEffect(() => {
    dispatch(getCartDetails(userData.code));
  }, [medicineDetail?.seoNameId]);

  const openModal = () => {
    setIsOpen(true);
  };

  const resolvePharamcyImageq = (Image) => {
    let PharmacyImage;
    if (Image.toUpperCase().includes("BOTTLE")) {
      PharmacyImage = Bottles;
    } else if (
      Image.toUpperCase().includes("CAPSULE") ||
      Image.toUpperCase().includes("CAPSULES")
    ) {
      PharmacyImage = Capsules;
    } else if (
      Image.toUpperCase().includes("CREAM") ||
      Image.toUpperCase().includes("CREAMS")
    ) {
      PharmacyImage = Creams;
    } else if (Image.toUpperCase().includes("CUREBAY")) {
      PharmacyImage = Curebay;
    } else if (Image.toUpperCase().includes("DEVICES")) {
      PharmacyImage = Devices;
    } else if (Image.toUpperCase().includes("DISPENSERS")) {
      PharmacyImage = Dispensers;
    } else if (Image.toUpperCase().includes("DROPLETS")) {
      PharmacyImage = Droplets;
    } else if (Image.toUpperCase().includes("EMULSIONS")) {
      PharmacyImage = Emulsions;
    } else if (
      Image.toUpperCase().includes("INJECTION") ||
      Image.toUpperCase().includes("INJECTIONS")
    ) {
      PharmacyImage = Injections;
    } else if (Image.toUpperCase().includes("LOTIONS")) {
      PharmacyImage = Lotions;
    } else if (Image.toUpperCase().includes("SATCHELS")) {
      PharmacyImage = Satchels;
    } else if (
      Image.toUpperCase().includes("TABLET") ||
      Image.toUpperCase().includes("TABLETS")
    ) {
      PharmacyImage = Tablet;
    } else {
      PharmacyImage = defaultMed;
    }
    return PharmacyImage;
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleNavigation = (path) => {
    history.push(path);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const getProductsIDs = () => {
    let ids = [];
    if (cartList) {
      if (cartList?.patientMedicineOrder?.length) {
        cartList?.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(
          (res) => {
            ids.push(res.drugCode);
          }
        );
      }
    }
    return ids;
  };

  const drugIDsInCart = getProductsIDs();

  useEffect(() => {
    if (!medicineDetail?.id) {
      return;
    }
    if (typeof medicineDetail?.medicineImages === "string") {
      let result = medicineDetail?.medicineImages.replace(/[[\]]/g, "");
      let medicineImage = result.split(",");
      setMedicineImages(medicineImage);
      console.log(medicineImage);
      if (medicineImage?.length !== 0) {
        if (medicineImage.length === 1 && medicineImage[0] === "") {
          const medicineImg = resolvePharamcyImageq(
            medicineDetail.medicineTypeOfSell
          );
          setBig(medicineImg);
          setMedicineImages("");
        } else {
          setCurrIdx(0);
          setBig(medicineImage[0]);
        }
      }
      // else{
      //   console.log(medicineDetail.medicineTypeOfSell)
      //   const medicineImg = resolvePharamcyImageq(medicineDetail.medicineTypeOfSell);
      //   console.log(medicineImg);
      //   setBig(medicineImg);
      // }
    } else if (Array.isArray(medicineDetail.medicineImages)) {
      const imageArr = medicineDetail?.medicineImages;
      if (imageArr?.length > 0) {
        setCurrIdx(0);
        setBig(imageArr[0]);
      } else {
        const medicineImg = resolvePharamcyImageq(
          medicineDetail.medicineTypeOfSell
        );
        setBig(medicineImg);
      }
    } else {
      const medicineImg = resolvePharamcyImageq(
        medicineDetail.medicineTypeOfSell
      );
      setBig(medicineImg);
    }
  }, [medicineDetail?.id]);

  const Silder = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const handlePrevClick = () => {
  //   const idx = currIdx;
  //   if(currIdx !== 0){
  //     setCurrIdx( idx - 1);
  //   }
  //   const imageUrl = medicineImages.find((item,idx)=> idx === (idx !== 0? idx-1:idx));
  //   setBig(imageUrl);
  // };

  const handlePrevClick = () => {
    const newIdx = currIdx - 1;
    if (newIdx >= 0) {
      setCurrIdx(newIdx);
      setBig(medicineImages[newIdx]);
    }
  };

  const handleNextClick = () => {
    const newIdx = currIdx + 1;
    if (newIdx < medicineImages.length) {
      setCurrIdx(newIdx);
      setBig(medicineImages[newIdx]);
    }
  };

  // const handleNextClick = () => {
  //   if (startIndex + 3 < medicineImages?.length) {
  //     setCurrIdx(startIndex + 1);
  //   }
  // };

  const handleImageClick = (imageUrl) => {
    setBig(imageUrl);
    setSelectedImage(false);
  };

  const redirectToMedicinePage = (item) => {
    // history.push('/medicine');
    history.push(`/medicine/${item}`);
  };

  async function getMedicineData(medicineCode, type) {
    if (medicineCode?.length) {
      var raw = JSON.stringify(medicineCode);

      dispatch(getAllProductByIds(raw))
        .then((res) => {
          if (type == "orderMedicines") {
            setRecentlyOrderMedicine(res);
          }
        })
        .catch((err) => {});
    } else {
    }
  }

  useEffect(() => {
    http
      .get(
        `${process.env.REACT_APP_BASEURL}PatientRecent/Search?patientId=${userData?.code}&category=1`
      )
      .then((res) => {
        let drugCode = res.data.data.map((item) => item.itemCode);
        getMedicineData(drugCode, "recentMedicine");
        // setPreviouslyBrowsedMedicine(res.data.data)
      });
  }, [userData]);

  useEffect(() => {
    http.get(`${process.env.REACT_APP_BASEURL}Drug/commonDrug`).then((res) => {
      getMedicineData(res.data.data, "orderMedicines");
    });
  }, [userData]);

  if (!medicineDetail?.id) {
    return (
      <Layouts>
        <section className="bg-lightGray py-8 ">
          <div className=" w-[90%] mx-auto">
            <div className="w-full rounded-md px-8 py-5">
              <Skeleton active paragraph={{ rows: 1 }} />
            </div>
            <div className="w-full flex md:flex relative gap-4 mt-5">
              <div className="md:w-[70%]">
                <div className="bg-White w-full  rounded-lg md:flex p-7 gap-5">
                  <div className="md:w-[40%] border border-gray h-[250px]  rounded-lg md:h-[250px] md:p-7">
                    <div className="flex justify-center">
                      <Skeleton.Image active />
                    </div>
                  </div>
                  <div className="md:w-[70%]">
                    <Skeleton active />
                    <div className="md:w-[60%] flex justify-between my-5">
                      {/* <Skeleton active/> */}
                      <div className="flex items-center justify-center w-[150px] h-[40px] ">
                        {/* <Skeleton /> */}
                      </div>
                    </div>
                    <div className="flex gap-3 items-center mb-3">
                      <Skeleton active />
                    </div>
                  </div>
                </div>
                <div className="bg-White w-full my-3 rounded-lg">
                  <div className="p-7">
                    <Skeleton active />
                  </div>
                </div>
              </div>

              <div className="md:w-[30%] ">
                <div className="bg-[#e1edff] w-full rounded-lg p-5 sticky top-0 mb-3">
                  <div className="bg-White rounded-md p-5">
                    <Skeleton active />
                  </div>
                  <div className="flex justify-between gap-3 mt-8">
                    <Skeleton active />
                  </div>
                </div>
              </div>
            </div>
            <section className="py-[50px] md:py-[100px]">
              <Skeleton active />
            </section>
          </div>
        </section>
      </Layouts>
    );
  }

  return (
    <Layouts>
      <ToastContainer />
      <Helmet>
        <title>
          {medicineDetail?.medicineName.length > 30
            ? `${medicineDetail?.medicineName.substring(0, 43)}…`
            : medicineDetail?.medicineName}
        </title>
        <meta
          name="description"
          content={
            medicineDetail?.composition?.charAt(0)?.toUpperCase() +
            medicineDetail?.composition?.slice(1) +
            medicineDetail?.medicineName +
            " " +
            medicineDetail?.medicineType?.charAt(0)?.toUpperCase() +
            medicineDetail?.medicineType?.slice(1) +
            " " +
            medicineDetail?.drugForm?.charAt(0)?.toUpperCase() +
            medicineDetail?.drugForm?.slice(1)
          }
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <section className="bg-lightGray py-8 ">
        <div className=" w-[90%] mx-auto">
          <div className="w-full bg-primaryColor rounded-md px-8 py-5">
            <div className="font-medium md:text-2xl text-White">
              Medicine Details
            </div>
          </div>
          <div className="w-full md:flex relative gap-4 mt-5">
            <div className="md:w-[70%]">
              <div className="bg-White w-full  rounded-lg md:flex p-7 gap-5">
                <div className="md:w-[40%] border border-gray h-[250px]  rounded-lg md:h-[250px] md:p-7 bg-lightBlue">
                  <div className="flex justify-center">
                    <img
                      src={big}
                      alt="Tablet"
                      className="w-[150px] h-[150px] rounded-lg object-fill bg-[transparent]"
                    />
                  </div>

                  {/* )} */}
                  <div className="flex items-center justify-center gap-2 mt-2 w-full overflow-x-auto scrollWidth">
                    {medicineImages?.length > 0 && (
                      <button
                        onClick={handlePrevClick}
                        className={`disabled:opacity-50 text-primaryColor font-bold bg-lightBlue w-[40px] h-[40px] rounded-full ${
                          currIdx === 0 ? "text-gray-400" : ""
                        }`}
                        disabled={currIdx === 0}
                      >
                        <LeftOutlined />
                      </button>
                    )}
                    {medicineImages?.length > 0 &&
                      medicineImages
                        ?.slice(currIdx, currIdx + 3)
                        .map((imageUrl, index) => (
                          <img
                            key={index}
                            src={imageUrl}
                            alt={`img${index}`}
                            onClick={() => handleImageClick(imageUrl)}
                            className="w-14 h-14 border border-lightGray rounded-sm p-2 "
                          />
                        ))}
                    {medicineImages?.length > 0 && (
                      <button
                        onClick={handleNextClick}
                        className={` disabled:opacity-50 text-primaryColor font-bold bg-lightBlue w-[40px] h-[40px] rounded-full ${
                          currIdx + 3 >= medicineImages?.length
                            ? "text-gray-400"
                            : ""
                        }`}
                        disabled={currIdx + 1 >= medicineImages?.length}
                      >
                        <RightOutlined />
                      </button>
                    )}
                  </div>
                </div>
                <div className="md:w-[70%]">
                  <p className="font-semibold text-xl md:text-2xl text-primaryColor mb-2">
                    {medicineDetail.medicineName}
                  </p>
                  <p className="text-textGray font-light text-sm md:text-base">
                    {medicineDetail.manufacturer}
                  </p>
                  <div className="md:w-[60%] flex justify-between my-5">
                    <div>
                      <p className="font-semibold text-secondaryColor text-2xl">
                        ₹ {medicineDetail.medicineRate}
                      </p>
                      <p className="text-textGray text-xs">
                        Inclusive of all taxes
                      </p>
                    </div>
                    <div className="flex items-center justify-center w-[150px] h-[40px]">
                      <button
                        disabled={drugIDsInCart.includes(medicineDetail.id)}
                        className="w-[35%] h-full text-3xl font-light border border-textGray rounded-s-md"
                        onClick={(e) => {
                          if (quantity > 1) setQuantity(quantity - 1);
                        }}
                      >
                        -
                      </button>
                      <div className="w-[50%] flex justify-center items-center h-full border-x-0 border-y border-textGray text-xl text-primaryColor font-medium">
                        {quantity}
                      </div>
                      <button
                        className="w-[35%] h-full text-3xl font-light border border-textGray rounded-e-md"
                        disabled={drugIDsInCart.includes(medicineDetail.id)}
                        onClick={(e) =>
                          setQuantity((prevState) => prevState + 1)
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex gap-3 items-center mb-3">
                    <p className="text-textGray font-medium text-xs mb-0">
                      Select Pack Size :
                    </p>
                    <div className="flex items-center gap-3">
                      <button className="bg-lightBlue text-xs font-medium text-primaryColor border-none rounded-full px-3 py-1.5">
                        {medicineDetail.medicineTypeOfSell}
                      </button>
                    </div>
                  </div>
                  {/* <button
                    // className={`${
                    //   !drugIDsInCart.includes(medicineDetail.id)
                    //     ? "bg-primaryColor text-White hover:bg-primaryColor"
                    //     : "border border-secondaryColor bg-secondaryColor disabled:opacity-75 mt-3"
                    // }  w-[30%] rounded-lg text-md`}
                    className={`px-6 py-3 rounded-md font-semibold transition h-12 ${
                      drugIDsInCart.includes(medicineDetail.drugCode)
                        ? "bg-secondaryColor text-White cursor-not-allowed opacity-50"
                        : "bg-primaryColor text-White hover:bg-secondaryColor"
                    }`}
                    onClick={(e) => addTocart(e, medicineDetail, 0, quantity)}
                    loading={isAdding !== -1}
                    disabled={drugIDsInCart.includes(medicineDetail.id)}
                    size="large"
                  >
                    {drugIDsInCart.includes(medicineDetail.drugCode)
                      ? "Added"
                      : "Add to cart"}
                  </button> */}
                  <button
                      className={`px-6 py-3 rounded-md font-semibold transition h-12 ${
                        drugIDsInCart.includes(medicineDetail.id)
                          ? "bg-secondaryColor text-White cursor-not-allowed opacity-50"
                          : "bg-primaryColor text-White hover:bg-secondaryColor"
                      }`}
                      onClick={(e) => addTocart(e, medicineDetail, 0, quantity)}
                        // className={`w-full border border-primaryColor text-primaryColor px-6 py-3 rounded-md font-semibold hover:bg-primaryColor hover:text-White transition`}
                        disabled={drugIDsInCart.includes(medicineDetail.id)}
                      >
                       {drugIDsInCart.includes(medicineDetail.id)
                      ? "Added"
                      : "Add to cart"}
                      </button>
                </div>
              </div>
              <div className="bg-White w-full my-3 rounded-lg">
                <div className="p-7">
                  <p className="font-semibold text-base md:text-lg text-primaryColor">
                    Product Information
                  </p>
                  <div className="">
                    <div className="flex gap-4 my-3">
                      <p className="font-semibold text-textGray text-sm  flex justify-between w-[25%]">
                        Contains <div>:</div>
                      </p>
                      <p className=" text-textGray text-sm w-[75%]">
                        {medicineDetail?.composition}
                      </p>
                    </div>
                    <div className="flex gap-4 my-3">
                      <p className="font-semibold text-textGray text-sm flex justify-between w-[25%]">
                        Medicine Type <div>:</div>
                      </p>
                      <p className="text-textGray text-sm  w-[75%]">
                        {medicineDetail?.medicineType}
                      </p>
                    </div>
                    {medicineDetail?.drugForm && (
                      <div className="flex gap-4 my-3">
                        <p className="font-semibold text-textGray text-sm  flex justify-between w-[25%]">
                          Drug Form <div>:</div>
                        </p>
                        <p className="capitalize text-textGray text-sm w-[75%]">
                          {medicineDetail?.drugForm}
                        </p>
                      </div>
                    )}
                    <div className="flex gap-4 my-3">
                      <p className="font-semibold text-textGray text-sm  flex justify-between w-[25%]">
                        Prescription<div>:</div>
                      </p>
                      <p className="capitalize text-textGray text-sm w-[75%]">
                        {medicineDetail?.prescriptionRequired}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3 mt-5">
                    <img src={Noteimage} alt="images" />
                    <p className="text-sm md:text-sm font-light text-textGray">
                      All the products are packed and stored safely and delivery
                      will be on time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-lightBlue w-full rounded-lg mb-5 md:mb-0 p-5">
                {alternateMedicine?.length > 0 && (
                  <div>
                    {/* Header Section */}
                    <div className="flex items-center justify-between px-10 py-5 w-full bg-White rounded-t-lg mb-5">
                      <h3 className="text-primaryColor text-2xl font-semibold">
                        Alternate{" "}
                        <span className="text-secondaryColor">Medicines</span>
                      </h3>
                    </div>
                    {/* Slider Section */}
                    <div className="p-3">
                      {alternateMedicine?.length > 4 ? (
                        <Slider {...Silder}>
                          {alternateMedicine.map((product, i) => (
                            <div
                              key={i}
                              className="bg-white rounded-lg common-shadow p-5 flex flex-col items-center justify-between w-[230px] h-[350px] mx-auto"
                            >
                              {/* Image Section */}
                              <div className="flex justify-center items-center mb-4 w-full h-[150px]">
                                <img
                                  src={
                                    product.medicineImages?.length > 0
                                      ? product.medicineImages[0]
                                      : defaultMed
                                  }
                                  alt="Alternate Medicine"
                                  className="object-contain max-w-full max-h-full cursor-pointer"
                                  onClick={() =>
                                    redirectToMedicinePage(product?.seoNameId)
                                  }
                                />
                              </div>
                              {/* Text Section */}
                              <div className="text-center w-full flex-grow flex flex-col justify-start">
                                <h5 className="text-primaryColor font-bold text-lg mb-2 truncate w-full">
                                  {product?.medicineName}
                                </h5>
                                <h6 className="text-secondaryColor font-bold text-lg">
                                  ₹{product?.medicineRate}
                                </h6>
                                <p className="text-textGray text-sm font-medium mt-2 truncate">
                                  {product?.medicineTypeOfSell}
                                </p>
                              </div>
                              {/* Button Section */}
                              <div className="mt-4 w-full flex justify-center">
                                <button
                                  className={`px-6 py-3 rounded-md font-semibold transition h-12 ${
                                    drugIDsInCart.includes(product.drugCode)
                                      ? "bg-secondaryColor text-White cursor-not-allowed opacity-50"
                                      : "bg-primaryColor text-White hover:bg-secondaryColor"
                                  }`}
                                  onClick={(e) => addTocart(e, product, i, 1)}
                                  disabled={drugIDsInCart.includes(
                                    product.drugCode
                                  )}
                                >
                                  {drugIDsInCart.includes(product.drugCode)
                                    ? "Added"
                                    : "Add to cart"}
                                </button>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <div className="flex flex-wrap justify-start gap-5 p-3">
                          {alternateMedicine?.map((product, i) => (
                            <div
                              key={i}
                              className="bg-white rounded-lg common-shadow p-5 flex flex-col items-center justify-between w-[230px] h-[350px] mx-auto"
                            >
                              {/* Image Section */}
                              <div className="flex justify-center items-center mb-4 w-full h-[150px]">
                                <img
                                  src={
                                    product.medicineImages?.length > 0
                                      ? product.medicineImages[0]
                                      : defaultMed
                                  }
                                  alt="Alternate Medicine"
                                  className="object-contain max-w-full max-h-full cursor-pointer"
                                  onClick={() =>
                                    redirectToMedicinePage(product?.seoNameId)
                                  }
                                />
                              </div>
                              {/* Text Section */}
                              <div className="text-center w-full flex-grow flex flex-col justify-start">
                                <h5 className="text-primaryColor font-bold text-lg mb-2 truncate w-full">
                                  {product?.medicineName}
                                </h5>
                                <h6 className="text-secondaryColor font-bold text-lg">
                                  ₹{product?.medicineRate}
                                </h6>
                                <p className="text-textGray text-sm font-medium mt-2">
                                  {product?.medicineTypeOfSell}
                                </p>
                              </div>
                              {/* Button Section */}
                              <div className="mt-4 w-full flex justify-center">
                                <button
                                  className={`px-6 py-3 rounded-md font-semibold transition h-12 ${
                                    drugIDsInCart.includes(product.drugCode)
                                      ? "bg-secondaryColor text-White cursor-not-allowed opacity-50"
                                      : "bg-primaryColor text-White hover:bg-secondaryColor"
                                  }`}
                                  onClick={(e) => addTocart(e, product, i, 1)}
                                  disabled={drugIDsInCart.includes(
                                    product.drugCode
                                  )}
                                >
                                  {drugIDsInCart.includes(product.drugCode)
                                    ? "Added"
                                    : "Add to cart"}
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="md:w-[30%] ">
              <div className="bg-White w-full rounded-lg p-5 sticky top-0 mb-3">
                <div className="bg-White rounded-md p-5">
                  <div className="flex flex-wrap items-center justify-between">
                    <div className="text-primaryColor font-medium text-lg ">
                      {cartCount(cartList) <= 9 ? cartCount(cartList) : "9+"}{" "}
                      item<span>s</span> in the cart
                    </div>
                    <div className="text-primaryColor font-medium text-lg ">
                      ₹{" "}
                      {cartList?.patientMedicineOrder?.length > 0 &&
                      cartList?.patientLabTestsOrder?.length > 0
                        ? (
                            cartList?.patientMedicineOrder[0]?.totalAmount +
                            cartList.patientLabTestsOrder[0]?.totalAmount
                          ).toFixed(2)
                        : cartList?.patientMedicineOrder?.length > 0
                        ? cartList?.patientMedicineOrder[0]?.totalAmount.toFixed(
                            2
                          )
                        : cartList?.patientLabTestsOrder?.length > 0
                        ? cartList.patientLabTestsOrder[0]?.totalAmount.toFixed(
                            2
                          )
                        : 0}
                    </div>
                  </div>
                  <button
                    onClick={() => handleNavigation("/cart")}
                    className="text-center w-full rounded-lg text-base py-2.5 mt-3 bg-secondaryColor text-White"
                  >
                    View Cart
                  </button>
                </div>
                <div className="mt-5 font-medium text-textGray text-sm">
                  Earliest Delivery By{" "}
                  <span className="text-secondaryColor">
                    {expectedDelivery}
                  </span>
                </div>
                {/* <div className="flex justify-between gap-3 mt-8">
                    <div className="text-center flex flex-col justify-center items-center gap-3 text-primaryColor text-xs ">
                      <img src={SecureImage} alt="images" className="w-[65px]" />
                      Secure <br /> Payment
                    </div>
                    <div className="text-center flex flex-col justify-center items-center gap-3  text-primaryColor text-xs ">
                      <img
                        src={ReliableImage}
                        alt="images"
                        className="w-[65px]"
                      />
                      Trusted by 1 <br />
                      Lakh Users
                    </div>
                    <div className="text-center flex flex-col justify-center items-center gap-3  text-primaryColor text-xs ">
                      <img src={GenuineImage} alt="images" className="w-[65px]" />
                      Genuine <br /> Products
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default ProductInfoPage;
