export const DIAG = {
    REQUEST_LABLIST: "REQUEST_LABLIST",
    SUCCESS_LABLIST: "SUCCESS_LABLIST",
    FAILED_LABLIST: "FAILED_LABLIST",


    REQUEST_LABLIST_MOSTCOMMON: "REQUEST_LABLIST_MOSTCOMMON",
    SUCCESS_LABLIST_MOSTCOMMON: "SUCCESS_LABLIST_MOSTCOMMON",
    FAILED_LABLIST_MOSTCOMMON: "FAILED_LABLIST_MOSTCOMMON",

    REQUEST_ELASTICLABLIST: "REQUEST_ELASTICLABLIST",
    SUCCESS_ELASTICLABLIST: "SUCCESS_ELASTICLABLIST",
    FAILED_ELASTICLABLIST: "FAILED_ELASTICLABLIST",

    REQUEST_LABAPPOINTMENTLIST: "REQUEST_LABAPPOINTMENTLIST",
    SUCCESS_LABAPPOINTMENTLIST: "SUCCESS_LABAPPOINTMENTLIST",
    FAILED_LABAPPOINTMENTLIST: "FAILED_LABAPPOINTMENTLIST",

    REQUEST_PATHOLOGYTEST: "REQUEST_PATHOLOGYTESTLIST",
    SUCCESS_PATHOLOGYTESTLIST: "SUCCESS_PATHOLOGYTESTLIST",
    FAILED_PATHOLOGYTESTLIST: "FAILED_PATHOLOGYTESTLIST",

    REQUEST_GETLABTESTLIST: "REQUEST_GETLABTESTLIST",
    SUCCESS_GETLABTESTLIST: "SUCCESS_GETLABTESTLIST",
    FAILED_GETLABTESTLIST: "FAILED_GETLABTESTLIST",

    REMOVE_LAB_LIST: "REMOVE_LAB_LIST"
}