import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatientDetails } from "../../../../Redux/Action/UserprofileActions";

import http from "../../../../Redux/Services/http-common"
import singlecardodia from "../../../../Assets/Images/CureBayCard/ODIA/SINGLE CARD_Odia.png";
import twincardodia from "../../../../Assets/Images/CureBayCard/ODIA/TWIN CARD_Odia.png";
import familycardodia from "../../../../Assets/Images/CureBayCard/ODIA/FAMILY CARD_Odia.png";

import singlecardhindi from "../../../../Assets/Images/CureBayCard/HINDI/SINGLE CARD _Hindi.png";
import twincardhindi from "../../../../Assets/Images/CureBayCard/HINDI/TWIN CARD_HINDI.png";
import familycardhindi from "../../../../Assets/Images/CureBayCard/HINDI/FAMILY CARD_ Hindi.png";

import singlecardenglish from "../../../../Assets/Images/CureBayCard/ENGLISH/SINGLE CARD _ Eng.png";
import twincardenglish from "../../../../Assets/Images/CureBayCard/ENGLISH/TWIN CARD_Eng.png";
import familycardenglish from "../../../../Assets/Images/CureBayCard/ENGLISH/FAMILY CARD_ENG.png";

function Membershipcarddownload(props) {
  const dispatch = useDispatch();
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData } = patientinfo;
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    console.log(
      props.data,
      props.data.membership,
      "sdvsdgvuodsgvuodsgudsvdsvds"
    );
    if (props.data) {
      setisLoading(true);
      dispatch(getPatientDetails(props.data.patientCode)).then((result) => {
        setisLoading(false);
      });
    }
  }, [props.data]);

  const download = (membership, patientState) => {
    console.log("Membership Type:", membership);
    console.log("Patient State:", patientState);

    let imageUrl;
    switch (membership) {
      case "Twin":
        imageUrl =
          patientState === "OD"
            ? twincardodia
            : patientState === "CG"
            ? twincardhindi
            : twincardenglish;
        break;
      case "Single":
        imageUrl =
          patientState === "OD"
            ? singlecardodia
            : patientState === "CG"
            ? singlecardhindi
            : singlecardenglish;
        break;
      case "Family":
        imageUrl =
          patientState === "OD"
            ? familycardodia
            : patientState === "CG"
            ? familycardhindi
            : familycardenglish;
        break;
      default:
        console.error("Invalid Membership Type or Patient State");
        return;
    }

    const sanitizedPatientName = props.data.patientName
      .replace(/\s+/g, "_")
      .replace(/[^a-zA-Z0-9_]/g, "");

    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = `${sanitizedPatientName}-membership-Card.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <>
          {props.data && props.data.membership === "Twin" ? (
            <div id="abc">
              <div className="text-center m text-2xl text-secondaryColor font-bold pl-4 uppercase">
                Twin Membership
              </div>
              <div className="flex justify-center">
                <img
                  className="w-3/4 ml-10"
                  src={
                    props.data.patientState === "OD"
                      ? twincardodia
                      : props.data.patientState === "CG"
                      ? twincardhindi
                      : twincardenglish
                  }
                  alt="logo"
                />
              </div>
            </div>
          ) : props.data && props.data.membership === "Single" ? (
            <div id="abc">
              <div className="text-center m text-2xl text-secondaryColor font-bold pl-4 uppercase">
                Single Membership
              </div>
              <div className="flex justify-center">
                <img
                  className="w-3/4 ml-10"
                  src={
                    props.data.patientState === "OD"
                      ? singlecardodia
                      : props.data.patientState === "CG"
                      ? singlecardhindi
                      : singlecardenglish
                  }
                  alt="logo"
                />
              </div>
            </div>
          ) : props.data && props.data.membership === "Family" ? (
            <div id="abc">
              <div className="text-center m text-2xl text-secondaryColor font-bold pl-4 uppercase">
                Family Membership
              </div>
              <div className="flex justify-center">
                <img
                  className="w-3/4 ml-10"
                  src={
                    props.data.patientState === "OD"
                      ? familycardodia
                      : props.data.patientState === "CG"
                      ? familycardhindi
                      : familycardenglish
                  }
                  alt="logo"
                />
              </div>
            </div>
          ) : null}
  
          <div className="flex justify-center my-10">
            <button
              onClick={() =>
                download(props.data.membership, props.data.patientState)
              }
              className="cursor-pointer text-sm font-normal font-rubik rounded-lg bg-secondaryColor text-White py-3 px-8"
            >
              Download Card
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default Membershipcarddownload;
