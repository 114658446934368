import Contactus from "../Contactus"

const GetInTouch = () =>{
    return(
        <section className="bg-White" id="Contactus">
        <div
          className="container mx-auto py-[50px] md:py-[90px]"
        >
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
            Get in <span className="text-secondaryColor">Touch</span>
          </h2>
          <div className="md:grid grid-cols-2 gap-5">
            <div className="flex justify-center">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/contactus.png"
                alt="images"
                className="h-full hidden md:block"
              />
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/contactus1.png"
                alt="images"
                className="h-full block md:hidden"
              />
            </div>
            <div className="bg-lightGray rounded-2xl p-5 md:p-10">
              <Contactus />
            </div>
          </div>
        </div>
      </section>
    )
}

export default GetInTouch