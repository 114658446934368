export const PKGCONSTANT = {
    REQUEST_MEMBERSHIP: "REQUEST_MEMBERSHIP",
    RESPONSE_MEMBERSHIP: "RESPONSE_MEMBERSHIP",
    FAILURE_MEMBERSHOP: "FAILURE_MEMBERSHOP",


    REQUEST_PACKAGE: "REQUEST_PACKAGE",
    RESPONSE_PACKAGE: "RESPONSE_PACKAGE",
    FAILURE_PACKAGE: "FAILURE_PACKAGE",


    REQUEST_MEMBERSHIP_SUBS: "REQUEST_MEMBERSHIP_SUBS",
    RESPONSE_MEMBERSHIP_SUBS: "RESPONSE_MEMBERSHIP_SUBS",
    FAILURE_MEMBERSHOP_SUBS: "FAILURE_MEMBERSHOP_SUBS",

    REQUEST_HEALTHCAREPACKAGE: "REQUEST_HEALTHCAREPACKAGE",
    RESPONSE_HEALTHCAREPACKAGE: "RESPONSE_HEALTHCAREPACKAGE",
    FAILURE_HEALTHCAREPACKAGE: "FAILURE_HEALTHCAREPACKAGE",


    REQUEST_ALLSEARCH: "REQUEST_ALLSEARCH",
    RESPONSE_ALLSEARCH: "RESPONSE_ALLSEARCH",
    FAILURE_ALLSEARCH: "FAILURE_ALLSEARCH",

    REQUEST_PROMOTION: "REQUEST_PROMOTION",
    RESPONSE_PROMOTION: "RESPONSE_PROMOTION",
    FAILURE_PROMOTION: "FAILURE_PROMOTION",



}