import { useDispatch, useSelector } from "react-redux";
import female_icon from "../../../Assets/Images/Female-Vector-icon.svg";
import male_icon from "../../../Assets/Images/Male-Vector-icon.svg";
import { useEffect, useState } from "react";
import { getPatientfamilymembers } from "../../../Redux/Action/UserprofileActions";
import {Modal } from "antd";
import AddFamilymember from "../PostLogin/AddFamilyMember";
const Family = () =>{
  const [Editmember, setEditmember] = useState();
  const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
  const userData = useSelector((state) => state.authReducer.patientData);
  const familymemberinfo = useSelector((state) => state.familymembers);
  const { FamilymembersData } = familymemberinfo;
  const dispatch = useDispatch()

  useEffect(() =>{
    dispatch(getPatientfamilymembers(userData.code));
  },[userData.code])

  const editmembers = (e, user) => {
    e.preventDefault();
    setshowaddmemberpopup(true);
    setEditmember(user);
  };

  const refreshFamilyMembers = () => {
    dispatch(getPatientfamilymembers(userData.code));
  };

    return(
        <>
        {Object.keys(userData).length !== 0 ? (
            <section className= "" style={{backgroundColor:"#004171"}} >
              <div className="container mx-auto py-[50px] md:py-[90px]">
                <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[30px] md:mb-[50px]">
                  My <span className="text-secondaryColor">Family</span>
                </h2>
                <div className="flex flex-wrap justify-center gap-4">
                  {FamilymembersData?.map((user, i) => (
                    <div className="bg-White rounded-lg p-4">
                      <div className="flex gap-3 items-center justify-between">
                        <div className="flex gap-3 items-center">
                          <img
                            src={
                              user.photoName
                                ? process.env.REACT_APP_IMG_BASEURL +
                                  user.photoName
                                : user.gender === "M"
                                ? male_icon
                                : female_icon
                            }
                            alt={user.name}
                            className="rounded-full w-[50px] "
                          />
                          <div className="">
                            <h5 className="text-primaryColor text-base font-medium">
                              {user.name}
                            </h5>
                            <p className="text-textGray text-sm font-light">
                              Blood Group: <span>{user.bloodGroup}</span>
                            </p>
                          </div>
                        </div>
                        <div className="border bg-lightGreen px-2 py-1 rounded-full border-secondaryColor text-secondaryColor text-xs">
                          {user.relation}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  onClick={(e) => editmembers(e, "")}
                  style={{color:"#004171", backgroundColor: "#ffff"}}
                  className=" text-sm rounded-lg py-3 px-6 flex justify-center mx-auto mt-10"
                >
                  + Add a family member
                </button>
              </div>
            </section>
          ) : null}
           <Modal
        title="Add a Family Member"
        className="commonModal"
        open={showaddmemberpopup}
        onOk={() => setshowaddmemberpopup(!showaddmemberpopup)}
        onCancel={() => {
          setshowaddmemberpopup(!showaddmemberpopup);
          refreshFamilyMembers();
        }}
        width="65%"
        style={{ top: 20 }}
        footer={[]}
      >
        <AddFamilymember
          editmembers={Editmember}
          closePopup={() => {
            setshowaddmemberpopup(!showaddmemberpopup);
            refreshFamilyMembers();
          }}
          saveMessage={(message) => {
            setshowaddmemberpopup(!showaddmemberpopup);
            // toast(message);
            refreshFamilyMembers();
          }}
        />
      </Modal>
          </>
    )
}

export default Family