import React from "react";
import Layouts from "../Layouts";
import AwardOne from "../../Assets/Images/Awards/Start-up Utsav Award.webp";
import AwardTwo from "../../Assets/Images/Awards/Times Business Award.webp";
import AwardThree from "../../Assets/Images/Awards/Times Health Icon Award.webp";
import AwardFour from "../../Assets/Images/Awards/Rajdhani Gaurav Sanman.webp";
import SubHeader from "../Shared/SearchBar";

function Awards() {
  return (
    <Layouts>
      <SubHeader />
      <section className="home-banner md:min-h-[60vh] py-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                Celebrating{" "}
                <span className="text-secondaryColor">Excellence</span>
              </h1>
              <p className="text-primaryColor md:text-White font-extralight text-sm md:md:text-lg text-sm">
                Take a look at the journey of CureBay’s awards & recognitions
              </p>
            </div>
            <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%] md:h-[400px]  rounded ">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Awards%20%26%20Recognition.webp"
                  alt="images"
                  className="w-full h-full object-cover image-boxshadow rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-primaryColor  text-center text-2xl md:text-4xl font-bold mb-10 !leading-snug">
            Our Journey of <br />
            <span className="text-secondaryColor">Awards & Recognitions</span>
          </h2>
          <div className="award-grid grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-10">
            <div>
              <h4 className="md:text-xl text-primaryColor text-xl font-semibold mb-5">
                Start-up Utsav Award
              </h4>
              <div className="image-container mb-10" id="awardone">
                <img
                  src={AwardOne}
                  alt="images"
                  className="block w-full h-auto"
                />
                <div className="award-overlay">
                  <div class="overlay-content h-full p-7">
                    <p className="font-light md:text-lg text-sm">
                      Mr. Priyadarshi Mohapatra, Founder & CEO, CureBay was
                      honoured by Government of Odisha for the achievement in
                      securing external investment during the 2023-24.
                    </p>
                  </div>
                </div>
              </div>
              <h4 className="md:text-xl text-primaryColor text-xl font-semibold mb-5">
              Rajdhani Gaurav Sanmman
              </h4>
              <div className="image-container mb-10" id="awardfour">
                <img
                  src={AwardFour}
                  alt="images"
                  className="block w-full h-auto"
                />
                <div className="award-overlay">
                  <div class="overlay-content h-full p-7">
                    <p className="font-light md:text-lg text-sm">
                      Mr. Priyadarshi Mohapatra, Founder & CEO, CureBay,
                      received recognition from Government of Odisha for his
                      outstanding contribution to the success of the startup
                      during the 75th Capital (Bhubaneswar) Foundation Day
                      celebration
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
            <h4 className="md:text-xl text-primaryColor text-xl font-semibold mb-5">
            Times Business Awards{" "}
              </h4>
              <div className="image-container mb-10" id="awardtwo">
                <img
                  src={AwardTwo}
                  alt="images"
                  className="block w-full h-auto"
                />
                <div className="award-overlay">
                  <div class="overlay-content h-full p-7">
                    <p className="font-light md:text-lg text-sm">
                      Most Promising Start-up to Ensure High Quality Healthcare
                      Accessible to every Indian through Innovation, by The
                      Times Group in the year 2022
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
            <h4 className="md:text-xl text-primaryColor text-xl font-semibold mb-5">
            Times Health Icons-Odisha 2023{" "}
              </h4>
              <div className="image-container mb-10" id="awardthree">
                <img
                  src={AwardThree}
                  alt="images"
                  className="block w-full h-auto"
                />
                <div className="award-overlay">
                  <div class="overlay-content h-full p-7">
                    <p className="font-light md:text-lg text-sm">
                      Best Healthcare Start-up of the Year-2023
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default Awards;
