import { Col, DatePicker, Form, Modal, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import plus from "../../../../Assets/Images/plus.png";
import calender from "../../../../Assets/Images/calender.png";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import {
  getmedicalhistory,
  getPatientallergylist,
  getPatientmedicationlist,
  getsurgicalhistory,
  getfamilyhistory,
  getsocialhistory,
  getchronicconditions,
  getpatientchronicconditions,
  patientEditallergy,
  patientaddallergy,
} from "../../../../Redux/Action/UserprofileActions";
import { USERPROFILE_ROUTES } from "../../../PageRouting/UserProfileRoutes";
import userprofileservice from "../../../../Redux/Services/userprofileservice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddChronicalHistory from "../HealthRecords/MedicalHistoryForms/AddChronicHistory";
import Addmedication from "../HealthRecords/MedicalHistoryForms/AddMedication";
import Addsocialhistory from "../HealthRecords/MedicalHistoryForms/AddSocialHistory";
import Addfamilyhistory from "../HealthRecords/MedicalHistoryForms/AddFamilyHistory";
import AddAllergy from "../HealthRecords/MedicalHistoryForms/AddAllergy";
import AddSurgery from "../HealthRecords/MedicalHistoryForms/AddSurgery";
import AddMedicalHistory from "../HealthRecords/MedicalHistoryForms/AddMedicalHistory";

function SummaryMedicalHistory(props) {
  // const dispatch = useDispatch();
  // const history = useHistory();
  // const userData = useSelector((state) => state.authReducer.patientData);
  // const patientallergylist = useSelector((state) => state.allergylist);
  // const { allergyData } = patientallergylist;
  // const [check, setCheck] = useState(true);
  // const medicationlist = useSelector((state) => state.allmedicationlist);
  // const { medicationData } = medicationlist;
  // const medicalhitorylist = useSelector((state) => state.allmedicalhistorylist);
  // const { medicalhistoryData } = medicalhitorylist;
  // const surgicalhistorylist = useSelector(
  //   (state) => state.allsurgicalhistorylist
  // );
  // const { surgicalhistoryDataList } = surgicalhistorylist;
  // const familyhistorylist = useSelector((state) => state.allfamilyHistoryList);
  // const { familyhistoryDataList } = familyhistorylist;
  // const socialhistoryData = useSelector((state) => state.allsocialHistoryList);
  // const { socialhistoryDataList } = socialhistoryData;
  // const chronicconditionsData = useSelector(
  //   (state) => state.allchronicConditionsList
  // );
  // const { chronicconditionsDataList } = chronicconditionsData;
  // const [saveChronicConditionsLoading, setsaveChronicConditionsLoading] =
  //   useState(false);
  // const [activePatient, setActivePatient] = useState();
  // const [selectedChronicConditions, setSelectedChronicConditions] = useState(
  //   []
  // );
  // const [selectedChronics, setSelectedChronics] = useState([]);

  // const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getMedicalHistoryFunc();
  }, [props.patCode]);
  const getMedicalHistoryFunc = () => {
    let patient = props.patCode;
    dispatch(getPatientallergylist(patient));
    dispatch(getPatientmedicationlist(patient));
    dispatch(getmedicalhistory(patient));
    dispatch(getsurgicalhistory(patient));
    dispatch(getfamilyhistory(patient));
    dispatch(getsocialhistory(patient));
    dispatch(getchronicconditions());
    loadPatientChronicConditions(patient);
  };
  const loadPatientChronicConditions = (patient) => {
    userprofileservice.getpatientchronicconditionslist(patient).then(
      (res) => {
        if (!res.message) {
          console.log(res.data);
          let array = [];
          res.data.forEach((element) => {
            if (element.status == 1) {
              array.push(element);
            }
          });
          setSelectedChronicConditions([...array]);
          console.log(selectedChronicConditions);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.authReducer.patientData);
  const patientallergylist = useSelector((state) => state.allergylist);
  const { allergyData } = patientallergylist;
  const [check, setCheck] = useState(true);
  const medicationlist = useSelector((state) => state.allmedicationlist);
  const { medicationData } = medicationlist;
  const medicalhitorylist = useSelector((state) => state.allmedicalhistorylist);
  const { medicalhistoryData } = medicalhitorylist;
  const surgicalhistorylist = useSelector(
    (state) => state.allsurgicalhistorylist
  );
  const { surgicalhistoryDataList } = surgicalhistorylist;
  const familyhistorylist = useSelector((state) => state.allfamilyHistoryList);
  const { familyhistoryDataList } = familyhistorylist;
  const socialhistoryData = useSelector((state) => state.allsocialHistoryList);
  const { socialhistoryDataList } = socialhistoryData;
  const chronicconditionsData = useSelector((state) => state.allchronicConditionsList);
  const { chronicconditionsDataList } = chronicconditionsData;
  const [saveChronicConditionsLoading, setsaveChronicConditionsLoading] = useState(false);
  const [activePatient, setActivePatient] = useState();
  const [selectedChronicConditions, setSelectedChronicConditions] = useState([]);
  const [selectedChronics, setSelectedChronics] = useState([]);
  const patientCode = useSelector(state => state.authReducer.patientCode);
  const patientDetails = useSelector((state) => state.particularpatientdetails);
  const { Option } = Select;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const [isModalOpen6, setIsModalOpen6] = useState(false);
  const [givenDateShow, setGivenDateShow] = useState("");
  const [addallergies, setaddallergy] = useState([]);
  const [givenDate, setGivenDate] = useState("");
  const [editallergy, seteditallergy] = useState();
  const formRef = useRef()
  const [allergyDataArr, setAllergyDataArr] = useState([]);

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/', {isLoginModalOpen : true});
    }
  }, [])

  useEffect(() => {
    setActivePatient(props.patCode);
  }, [dispatch]);


  useEffect(() => {
    console.log(givenDate, givenDateShow)
  }, [givenDate]);

  useEffect(() => { console.log(selectedChronicConditions) }, [selectedChronicConditions])

  const changeDate = (e) => {
    setGivenDate(moment(e).format("YYYY-MM-DD hh:mm:ss"))
    setGivenDateShow(moment(e).format("MM/DD/yyyy"))
    console.log(givenDate)
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(givenDate);
    // You can perform further actions with the form values here
  };

  const saveallergy = (e) => {
    e.preventDefault();
    const formValues = formRef.current.getFieldsValue();
    const { chemicalAllergy, drugAllergy, foodAllergy } = formValues;
    if (editallergy) {
      let patient = localStorage.getItem("patientprofile");
      const data = [{
        "chemicalAllergy": chemicalAllergy,
        "createdBy": props.patCode,
        "createdDate": "",
        "drugAllergy": drugAllergy,
        "foodAllergy": foodAllergy,
        "fromDate": "",
        "givenDate": givenDate,
        "id": editallergy?.id,
        "modifiedBy": props.patCode,
        "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "patientCode": props.patCode,
        "status": 1,
        "toDate": ""
      }]

      dispatch(patientEditallergy(data)).then((result) => {
        dispatch(getPatientallergylist(props.patCode));

      }, [patientCode, dispatch]);
    }
    else {
      let patient = localStorage.getItem("patientprofile");
      const data = [{
        "chemicalAllergy": chemicalAllergy,
        "createdBy": props.patCode,
        "createdDate": "",
        "drugAllergy": drugAllergy,
        "foodAllergy": foodAllergy,
        "fromDate": "",
        "givenDate": givenDate,
        "modifiedBy": props.patCode,
        "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "patientCode": props.patCode,
        "status": 1,
        "toDate": ""
      }]
      console.log("lol3", data)
      dispatch(patientaddallergy(data)).then((result) => {
        dispatch(getPatientallergylist(props.patCode));
        handleCancel();
      })
    }
  };

  async function fetchAllList(patient) {
    try {
      const allergeyData = await dispatch(getPatientallergylist(props.patCode));
      setAllergyDataArr(allergeyData);
      console.log(allergeyData);
    }
    catch (e) {
      console.log(e);
    }
  }




  // useEffect(() => {
  //     //getMedicalHistoryFunc();
  //     // loadSocialHistory();
  // }, [patientDetails.memberCode, localStorage.getItem("patientprofile")]);


  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/', {isLoginModalOpen : true});
    }
  }, [])

  const refresh = () => {
    //getMedicalHistoryFunc();
    if (props.patCode) {
      loadMedicalHistoryFor(props.patCode)
    } else {
      getMedicalHistoryFunc();
    }
  }

  // const getMedicalHistoryFunc = () => {
  //   let patient = patientCode;
  //   dispatch(getPatientallergylist(patient));
  //   dispatch(getPatientmedicationlist(patient));
  //   dispatch(getmedicalhistory(patient));
  //   dispatch(getsurgicalhistory(patient));
  //   dispatch(getfamilyhistory(patient));
  //   dispatch(getsocialhistory(patient));
  //   dispatch(getchronicconditions());
  //   loadPatientChronicConditions(patient);
  //   //dispatch(getpatientchronicconditions(patient));
  // };


  const loadMedicalHistoryFor = (patient) => {
    setActivePatient(patient);
    dispatch(getPatientallergylist(patient));
    dispatch(getPatientmedicationlist(patient));
    dispatch(getmedicalhistory(patient));
    dispatch(getsurgicalhistory(patient));
    dispatch(getfamilyhistory(patient));
    dispatch(getsocialhistory(patient));
    dispatch(getchronicconditions());
    loadPatientChronicConditions(patient);
  }

  // const loadPatientChronicConditions = (patient) => {
  //   userprofileservice.getpatientchronicconditionslist(patient).then((res) => {
  //     if (!res.message) {
  //       console.log(res.data);
  //       let array = [];
  //       res.data.forEach(element => {
  //         if (element.status == 1) {
  //           array.push(element);
  //         }
  //       });
  //       setSelectedChronicConditions([...array]);
  //       console.log(selectedChronicConditions);
  //     }
  //   }, (err) => {
  //     console.log(err);
  //   })
  // }

  useEffect(() => { console.log(selectedChronicConditions) }, [selectedChronicConditions])

  useEffect(() => {
    setActivePatient(props.patCode);
  }, [dispatch]);

  // useEffect(() => {
  //     //getMedicalHistoryFunc();
  //     // loadSocialHistory();
  // }, [patientDetails.memberCode, localStorage.getItem("patientprofile")]);

  useEffect(() => {
    getMedicalHistoryFunc();
  }, []);

  // const saveChronicConditions = () => {
  //   setsaveChronicConditionsLoading(true);
  //   let payload = selectedChronicConditions;
  //   console.log(JSON.stringify(payload));
  //   HealthRecordService.addpatientchronicconditionslist(payload).then((res) => {
  //     setsaveChronicConditionsLoading(false)
  //     if (res.data == 1) {
  //       toast("Chronic Conditions Added Successfully");
  //     }
  //   }, (err) => {
  //     setsaveChronicConditionsLoading(false)
  //     console.log(err);
  //   })
  // }

  const changePatient = (code) => {
    //loadPatientChronicConditions
  }

  const gotoHistory = (e) => {
    e.preventDefault();
    history.push(USERPROFILE_ROUTES.MYDETAILS)
  }
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };



  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  }



  const showModal3 = () => {
    setIsModalOpen3(true);
  };
  const handleOk3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };


  const showModal4 = () => {
    setIsModalOpen4(true);
  };
  const handleOk4 = () => {
    setIsModalOpen4(false);
  };
  const handleCancel4 = () => {
    setIsModalOpen4(false);
  };



  const showModal5 = () => {
    setIsModalOpen5(true);
  };
  const handleOk5 = () => {
    setIsModalOpen5(false);
  };
  const handleCancel5 = () => {
    setIsModalOpen5(false);
  };

  const showModal6 = () => {
    setIsModalOpen6(true);
  };
  const handleOk6 = () => {
    setIsModalOpen6(false);
  };
  const handleCancel6 = () => {
    setIsModalOpen6(false);
  };
  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={12} md={8}>
          <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
            <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
              <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                Allergy History
              </h3>
              <div
                onClick={showModal}
                className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
              >
                <img
                  src={plus}
                  alt="plus"
                  className="w-3 h-3 cursor-pointer"
                />
              </div>
            </div>
            <div className="h-56 overflow-auto scrollWidth p-5">
              {allergyData.map((user, i) => (
                <div>
                  <div className="grid grid-cols-2 gap-3 w-full">
                    <div className="text-sm text-textGray">
                      <label className=" font-light">Drug Allergy</label>
                      <p className="font-semibold">{user.drugAllergy}</p>
                    </div>
                    <div className="text-sm text-textGray">
                      <label className=" font-ligh">Food Allergy</label>
                      <p className="font-semibold">{user.foodAllergy}</p>
                    </div>
                    <div className="text-sm text-textGray">
                      <label className=" font-ligh">Chemical Allergy</label>
                      <p className="font-semibold">
                        {user.chemicalAllergy}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full items-center gap-3 mt-5">
                    <h3 className="text-primaryColor text-sm font-medium">
                      Check Up Date
                    </h3>
                    <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                      <img src={calender} alt="image" className="w-3" />
                      <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                    </div>
                  </div>
                  <div className="w-full border text-lightBlue my-3"></div>
                </div>
              ))}
            </div>
          </div>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
            <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
              <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                Medical History
              </h3>
              <div
                onClick={showModal1}
                className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
              >
                <img
                  src={plus}
                  alt="plus"
                  className="w-3 h-3 cursor-pointer"
                />
              </div>
            </div>

            <div className="h-56 overflow-scroll scrollWidth p-5">
              {medicalhistoryData.map((user, i) => (
                <div>
                  <h3 className="text-sm text-textGray font-medium">
                    {user.description}
                  </h3>

                  <div className="flex w-full items-center gap-3 mt-5">
                    <h3 className="text-primaryColor text-sm font-medium">
                      Check Up Date
                    </h3>
                    <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                      <img src={calender} alt="image" className="w-3" />
                      <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                    </div>
                  </div>
                  <div className="w-full border text-lightBlue my-3"></div>
                </div>
              ))}
            </div>
          </div>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
            <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
              <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                Surgical History
              </h3>
              <div
                onClick={showModal2}
                className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
              >
                <img
                  src={plus}
                  alt="plus"
                  className="w-3 h-3 cursor-pointer"
                />
              </div>
            </div>

            <div className="h-56 overflow-scroll scrollWidth p-5">
              {surgicalhistoryDataList.map((user, i) => (
                <div>
                  <h3 className="text-sm text-textGray font-medium">
                    {user.description}
                  </h3>

                  <div className="flex w-full items-center gap-3 mt-5">
                    <h3 className="text-primaryColor text-sm font-medium">
                      Check Up Date
                    </h3>
                    <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                      <img src={calender} alt="image" className="w-3" />
                      <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                    </div>
                  </div>
                  <div className="w-full border text-lightBlue my-3"></div>
                </div>
              ))}
            </div>
          </div>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
            <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
              <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                Family History
              </h3>
              <div
                onClick={showModal3}
                className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
              >
                <img
                  src={plus}
                  alt="plus"
                  className="w-3 h-3 cursor-pointer"
                />
              </div>
            </div>

            <div className="h-56 overflow-scroll scrollWidth p-5">
              {familyhistoryDataList.map((user, i) => (
                <div>
                  <h3 className="text-sm text-textGray font-medium">
                    {user.description}
                  </h3>

                  <div className="flex w-full items-center gap-3 mt-5">
                    <h3 className="text-primaryColor text-sm font-medium">
                      Check Up Date
                    </h3>
                    <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                      <img src={calender} alt="image" className="w-3" />
                      <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                    </div>
                  </div>
                  <div className="w-full border text-lightBlue my-3"></div>
                </div>
              ))}
            </div>
          </div>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
            <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
              <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                Social History
              </h3>
              <div
                onClick={showModal4}
                className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
              >
                <img
                  src={plus}
                  alt="plus"
                  className="w-3 h-3 cursor-pointer"
                />
              </div>
            </div>

            <div className="h-56 overflow-scroll scrollWidth p-5">
              {socialhistoryDataList.map((user, i) => (
                <div>
                  <h3 className="text-sm text-textGray font-medium">
                    {user.description}
                  </h3>
                  <div className="flex w-full items-center gap-3 mt-5">
                    <h3 className="text-primaryColor text-sm font-medium">
                      Check Up Date
                    </h3>
                    <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                      <img src={calender} alt="image" className="w-3" />
                      <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                    </div>
                  </div>
                  <div className="w-full border text-lightBlue my-3"></div>
                </div>
              ))}
            </div>
          </div>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
            <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
              <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                Medication
              </h3>
              <div
                onClick={showModal5}
                className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
              >
                <img
                  src={plus}
                  alt="plus"
                  className="w-3 h-3 cursor-pointer"
                />
              </div>
            </div>

            <div className="h-56 overflow-scroll scrollWidth p-5">
              {medicationData.map((user, i) => (
                <div>
                  <div className="flex justify-between gap-5 w-full">
                    <h3 className="text-sm text-textGray font-medium">
                      {user.drugName}
                    </h3>
                    <div className="flex gap-2">
                      <span className="text-sm text-textGray font-medium">
                        Dosage Level:
                      </span>
                      <span className="text-sm text-textGray font-light">
                        {user.dosage ? user.dosage : `Morning & Noon`}
                      </span>
                    </div>
                  </div>

                  <div className="flex w-full items-center gap-3 mt-5">
                    <h3 className="text-primaryColor text-sm font-medium">
                      Check Up Date
                    </h3>
                    <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                      <img src={calender} alt="image" className="w-3" />
                      <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                    </div>
                  </div>
                  <div className="w-full border text-lightBlue my-3"></div>
                </div>
              ))}
            </div>
          </div>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <div className="md:mt-5 mt-3 bg-White rounded-md common-shadow scrollable-container">
            <div className="flex justify-between items-center py-3 px-6 bg-[#D9F1E7] rounded-md">
              <h3 className="text-base md:text-lg text-secondaryColor font-medium">
                Chronic Conditions
              </h3>
              <div
                onClick={showModal6}
                className="w-8 h-8 rounded-full bg-White flex justify-center items-center cursor-pointer"
              >
                <img
                  src={plus}
                  alt="plus"
                  className="w-3 h-3 cursor-pointer"
                />
              </div>
            </div>

            <div className="h-56 overflow-scroll scrollWidth p-5">
              {selectedChronicConditions.map((user, i) => (
                  <div>
                  <h3 className="text-sm text-textGray font-medium">
                    {user.chronicDescription}
                  </h3>

                  <div className="flex w-full items-center gap-3 mt-5">
                    <h3 className="text-primaryColor text-sm font-medium">
                      Check Up Date
                    </h3>
                    <div className="text-primaryColor flex gap-2 py-1.5 px-4 text-sm items-center rounded-full  bg-lightBlue">
                      <img src={calender} alt="calender" className="w-3" />
                      <p>{moment(user.givenDate).format("MM/DD/YYYY")}</p>
                    </div>
                  </div>
                  <div className="w-full border text-lightBlue my-3"></div>
                </div>
              ))}
            </div>
          </div>
        </Col>

      </Row>
      
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Allergy Details
          </p>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        onFinish={saveallergy}
      >
        <AddAllergy closePopup={handleCancel} loadData={() => refresh()} patCode={props.patCode}/>
      </Modal>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Medical History
          </p>
        }
        open={isModalOpen1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        footer={[]}
      >
        {/* <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Record on" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="Select date"
            />
          </Form.Item>
          <Form.Item label="Description" name="email">
            <Input placeholder="Enter Description" />
          </Form.Item>
          <Form.Item>
            <button
              onClick={saveallergy}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <AddMedicalHistory closePopup={handleCancel1} loadData={() => refresh()} patCode={props.patCode}/>
      </Modal>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Surgical History
          </p>
        }
        open={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={[]}
      >
        {/* <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Record on" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="Select date"
            />
          </Form.Item>
          <Form.Item label="Description" name="email">
            <Input placeholder="Enter Description" />
          </Form.Item>
          <Form.Item>
            <button
              onClick={handleCancel2}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <AddSurgery closePopup={handleCancel2} loadData={() => refresh()} patCode={props.patCode}/>
      </Modal>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Family History
          </p>
        }
        open={isModalOpen3}
        onOk={handleOk3}
        onCancel={handleCancel3}
        footer={[]}
      >
        {/* <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Record on" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="Select date"
            />
          </Form.Item>
          <Form.Item label="Description" name="email">
            <Input placeholder="Enter Description" />
          </Form.Item>
          <Form.Item>
            <button
              onClick={handleCancel3}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <Addfamilyhistory closePopup={handleCancel3} loadData={() => refresh()} patCode={props.patCode}/>
      </Modal>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Social History
          </p>
        }
        open={isModalOpen4}
        onOk={handleOk4}
        onCancel={handleCancel4}
        footer={[]}
      >
        {/* <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Record on" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="Select date"
            />
          </Form.Item>
          <Form.Item label="Description" name="email">
            <Input placeholder="Enter Description" />
          </Form.Item>
          <Form.Item>
            <button
              onClick={handleCancel4}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <Addsocialhistory closePopup={handleCancel4} loadData={() => refresh()} patCode={props.patCode}/>
      </Modal>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-2xl">
            Add Medications
          </p>
        }
        open={isModalOpen5}
        onOk={handleOk5}
        onCancel={handleCancel5}
        footer={[]}
      >
        {/* <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Drug Name" name="Drug name">
            <Input placeholder="Enter Drug Name" />
          </Form.Item>
          <Form.Item label="Dosage" name="dosage">
            <Input placeholder="Enter Dosage" />
          </Form.Item>
          <Form.Item label="From Date" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="From Date"
            />
          </Form.Item>
          <Form.Item label="To Date" name="date">
            <DatePicker
              style={{ width: "100%", height: "50px" }}
              placeholder="To Date"
            />
          </Form.Item>
          <Form.Item>
            <button
              onClick={handleCancel5}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <Addmedication closePopup={handleCancel5} loadData={() => refresh()} patCode={props.patCode}/>
      </Modal>
      <Modal
        title={<p className="font-semibold text-primaryColor text-2xl">Add Chronic Condition</p>}
        visible={isModalOpen6}
        onOk={handleOk6}
        onCancel={handleCancel6}
        footer={[]}
      >
        {/* <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Chronic Condition" name="chronicCondition">
            <Select
              placeholder="Select Chronic Condition"
              style={{ width: "100%", height: "50px" }}
            >
              <Option value="CC01">COPD</Option>
              <Option value="CC02">Asthma</Option>
              <Option value="CC03">Diabetes Mellitus</Option>
              <Option value="CC04">Hypertension</Option>
              <Option value="CC05">Congestive Heart Failure</Option>
              <Option value="CC06">Cardiac Disease</Option>
              <Option value="CC07">Renal Disease</Option>
              <Option value="CC08">Chronic Renal Failure</Option>
              <Option value="CC09">Rheumatoid Arthritis</Option>
              <Option value="CC10">Organ Transplant</Option>
              <Option value="CC11">High Cholesterol</Option>
              <Option value="CC12">Psychiatric Disorder</Option>
              <Option value="CC13">Neurological Disorder</Option>
              <Option value="CC14">Oncological Disorders</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <button
              onClick={handleCancel6}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center"
              type="primary"
              htmlType="submit"
            >
              Save details
            </button>
          </Form.Item>
        </Form> */}
        <AddChronicalHistory closePopup={handleCancel6} loadData = {() => refresh()} patCode={props.patCode}/>
      </Modal>

    </>
  );
}

export default SummaryMedicalHistory;
