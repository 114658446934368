import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Layouts from "../../../Layouts";
import { Col, Form, Input, Modal, Row, Select } from "antd";
import Image from "../../../../Assets/Images/Rectangle4812104.svg";
import videoIcon from "../../../../Assets/Images/videoIcon.svg";
import calender from "../../../../Assets/Images/calender.png";
import FilterIcon from "../../../../Assets/Images/ReportsFilter.svg";
import { getReport, uploadReport } from "../../../../Redux/Action/reportAction";
import { getPatientAppointmentList } from "../../../../Redux/Action/patientAction";
import moment from "moment";
import EprescriptionsView from "../../Services/EprescriptionsView";
import Doctor from "../../../../Assets/Images/Doctor.png";
import FamilyDropdown from "./FamilyDropDown";
import NodataImage from "../../../../Assets/Images/Icons/nodata.svg";
import userprofileservice from "../../../../Redux/Services/userprofileservice";
import { CalendarOutlined } from "@ant-design/icons";
import EPrescription from "../../../../Assets/Images/EPrescription.svg";
import http from "../../../../Redux/Services/http-common";
import jsPDF from "jspdf";
import doctorService from "../../../../Redux/Services/doctorService";
import { getPostConsultation } from "../../../../Redux/Action/doctorAction";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import noDataFound from "../../../../Assets/Images/No data-found.svg"
import { Sidebar } from "primereact/sidebar";
import { getAllProductByIds } from "../../../../Redux/Action/pharmacyAction"
import { useHistory,useLocation } from "react-router-dom";
import { PrescriptionTempate } from "../../../Helper/prescriptionTemplate";

async function getbase64 (){
  const input = document.getElementById("abc");
 
  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "px",
    format: "A4",
    userUnit: "px",
  });
  let base64
  await pdf.html(input, { html2canvas: { scale: 0.5, allowTaint: false, }, margin: [1, 1, 1, 1], autoPaging: true }).then(() => {
    const fileName =
      "e-Prescription-" + Math.random() + ".pdf";
    console.log(fileName);
    let file =  pdf.output("datauri")
     base64 = file.split(",")[1]
   
})
return base64;
}

function MyPrescription() {
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = useSelector((state) => state.authReducer.patientData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prescriptionView, setPrescriptionView] = useState(false);
  const [userID, setUserId] = useState(null);
  const [activePatient, setActivePatient] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [patientCode, setPatientCode] = useState(0);
  const [prescriptionsList, setPrescriptionsList] = useState([]);
  const [currUser,setCurrUser] = useState(null);
  const [reading1RE, setReReading1] = useState();
  const [reading2RE, setReReading2] = useState();
  const [reading3RE, setReReading3] = useState();
  const [readingFinalRE, setReReadingFinal] = useState();
  const [reading1LE, setLeReading1] = useState();
  const [reading2LE, setLeReading2] = useState();
  const [reading3LE, setLeReading3] = useState();
  const [readingFinalLE, setLeReadingFinal] = useState();
  const [eyeWearUser, setEyeWearUser] = useState(false);
  const [labTestList, setLabTestList] = useState([]);
  const [savedPrescription, setSavedPrescription] = useState([]);
  const history = useHistory();
  const [currSelectedList, setCurrSelectedList] = useState([]);
  const [selectlist,setSelectlist]= useState()
  const [prescriptionName, setPrescriptionName] = useState("");
  const [currObject, setCurrObject] = useState();
  const search = useLocation().search;
  const visitidParam = new URLSearchParams(search).get("visitId");
  
  const [originalPrescription, setOriginalPrescription] = useState("");
  const [selectedPrescription, setSelectedPrescription] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [prescribedList, setPrescribedList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [screen, setscreen] = useState(window.innerWidth);
  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push("/");
    }
  }, []);
  useEffect(() => {
    loadPrescriptions();
}, []);
  async function loadPrescriptions(selectPrevDate = 0) {
    let now = new Date();
    let backdate = 0;
    if (selectPrevDate === 1) {
        backdate = new Date(now.setDate(now.getDate() - 30));
    } else if (selectPrevDate === 2) {
        backdate = new Date(now.setDate(now.getDate() - 60));
    } else if (selectPrevDate === 3) {
        backdate = new Date(now.setDate(now.getDate() - 90));
    }
    try {
        if (selectPrevDate === 0) {
            const res = await http.get(`${process.env.REACT_APP_BASEURL}PatientDocument/list/filter?status=1&patientCode=${userData.code}&documentRequired=Y`);
            setSavedPrescription(res.data);
            defaultPresList(res.data)
        } else {
            const res = await http.get(`${process.env.REACT_APP_BASEURL}PatientDocument/list/filter?status=1&patientCode=${userData.code}&documentRequired=Y&fromDate=${moment(backdate).format("MM/DD/yyyy")}&toDate=${moment(Date.now()).format("MM/DD/yyyy")}`);
            setSavedPrescription(res.data);
            defaultPresList(res.data)
        }
    } catch (error) {
        console.log(error)
    }
}
const defaultPresList = (savedPrescription) => {
  console.log(savedPrescription)
  const result = savedPrescription.filter(res => res.conversionNeeded === true);
  console.log(result);
  setCurrSelectedList(result);
}
  const [fromDate, setFromDate] = useState(
    moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
  );
  const [toDate, setToDate] = useState(
    moment(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
  );
  const [dateFormat, setDateFormat] = useState(
    moment(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
  );
  useEffect(() => {
    loadPrescriptionFor(userData.code, 1);
    setActivePatient(userData.code);
  }, [userData.code]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setPrescriptionView(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setPrescriptionView(false);
  };
  const handleModal = () => {
    setIsModalOpen(false);
  }
  const getPrescriptionDetailReport = (id) =>{
    dispatch(getPostConsultation(id || ""))
    .then((result) => {
      console.log(result , "sdvoisdhvousdgvosudgvsduv");
      // console.log(result[0].patientLabTestsList); //labParentName
      setCurrUser(result[0])
      if (
        result[0].patientLabTestsList &&
        result[0].patientLabTestsList.length
      ) {
        const labData = result[0].patientLabTestsList;
        const unique = [
          ...new Set(labData.map((item) => item.labParentName)),
        ];
        console.log(unique);
        const labArray = [];
        for (let s = 0; s < unique.length; s++) {
          const labFilter = labData.filter(
            (x) => x.labParentName == unique[s]
          );
          const payload = {
            parent: unique[s],
            data: labFilter,
          };
          labArray.push(payload);
        }
        console.log(labArray);
        setLabTestList(labArray);
      }
      if (result[0].eyeReadingMain) {
        setEyeWearUser(true);
        const leftEyeData =
          result[0].eyeReadingMain && result[0].eyeReadingMain.leftEye;
        const rightEyeData =
          result[0].eyeReadingMain && result[0].eyeReadingMain.rightEye;
        setLeReadingFinal(leftEyeData.finalReading);
        setLeReading1(leftEyeData.readingList[0]);
        setLeReading2(leftEyeData.readingList[1]);
        setLeReading3(leftEyeData.readingList[2]);
        setReReadingFinal(rightEyeData.finalReading);
        setReReading1(rightEyeData.readingList[0]);
        setReReading2(rightEyeData.readingList[1]);
        setReReading3(rightEyeData.readingList[2]);
       
      }else{
        setEyeWearUser(false);
      }
      setPrescriptionView(true);
    })
    .catch((error) => { });
  }
  const handlePrescription = (currObj,userId) => {
    getPrescriptionDetailReport(currObj.appointmentId)
   
    setCurrUser(currObj);
    setUserId(userId);
  };
  const handleSavedPrescription = async (user) =>{
    const res = await http.get(`${process.env.REACT_APP_BASEURL}Visit/list?id=${user.id}&visitSummary=Y`)
    if(res.data){
      setCurrUser(res.data)
      setCurrUser(user);
      setUserId(user.id);
      setPrescriptionView(true);
    }
  }
  const handleTypeChange = (value) => {
    setSelectlist(value)
    if (value === "Converted Prescription") {
        const result = savedPrescription.filter(res => res.conversionNeeded === true);
        console.log(result);
        setCurrSelectedList(result);
    }
    // else {
    //     const result = savedPrescription.filter(res => res.conversionNeeded === false);
    //     console.log(result);
    //     setCurrSelectedList(result);
    // }
}
  const loadPrescriptionFor = async (patient, selectPrevDate = 1) => {
    setIsLoading(true);
    setPatientCode(patient);
    const date = new Date();
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let now = new Date();
    let backdate = 0;
    let payload = {};
    if (selectPrevDate === 1) {
      backdate = new Date(now.setDate(now.getDate() - 120));
    } else if (selectPrevDate === 2) {
      backdate = new Date(now.setDate(now.getDate() - 60));
    } else if (selectPrevDate === 3) {
      backdate = new Date(now.setDate(now.getDate() - 90));
    }
    if (selectPrevDate === 0) {
      payload = {
        patientId: patient,
        visitSummary: "Y",
        status: 1,
        documentRequired: "Y",
      };
    } else {
      payload = {
        patientId: patient,
        visitSummary: "Y",
        status: 1,
        documentRequired: "Y",
      };
    }
    userprofileservice.getmypriscription(payload).then(
      (res) => {
        if (res.data) {
          console.log(res.data);
          setPrescriptionsList(res.data);
        }
        setIsLoading(false);
      },
      (err) => {
        console.log("Error : " + JSON.stringify(err));
        setIsLoading(false);
      }
    );
  };
  console.log(currSelectedList,"currSelectedList")

  const [signatureBase64, setSignatureBase64] = useState(null);
  const [stampBase64, setStampBase64] = useState(null);

  useEffect(() => {
    
    if (currUser?.userSignature) {
      getbase64(`${process.env.REACT_APP_IMG_BASEURL}${currUser?.userSignature}`).then(dataUrl => {
        setSignatureBase64(dataUrl);
      });
    }
    if (currUser?.hospitalStamp) {
      getbase64(`${process.env.REACT_APP_IMG_BASEURL}${currUser?.hospitalStamp}`).then(dataUrl => {
        setStampBase64(dataUrl);
      });
    }
  }, [currUser]);

  const download = async() => {
    if (
      currUser?.patientDocumentList &&
      currUser?.patientDocumentList?.docName?.split(".")[1] !=
        "string" &&
      currUser?.patientDocumentList.docName && currUser?.conversionNeeded !== 1
    ) {
      doctorService
        .imagetoData(currUser?.patientDocumentList.docName)
        .then((res) => {
          var base64;
          if (currUser?.patientDocumentList.documentType == "pdf") {
            base64 = "data:application/pdf;base64,";
          } else {
            base64 = "data:image/png;base64,";
          }
          //alert(JSON.stringify(res.data.fileData))
          var FileSaver = require("file-saver");
          FileSaver.saveAs(
            base64 + res.data.fileData,
            currUser.patientDocumentList.docName
          );
          // history.goBack();
        });
    } else {
      let input =  document.getElementById("abc");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "A2",
        userUnit: "px",
      });
      await pdf.html(input, { 
        html2canvas: { scale: 1, useCORS: true, allowTaint: false }, 
        margin: [10, 10, 10, 10],
        autoPaging: true 
      }).then(() => {
        const fileName = "e-Prescription-" + currUser?.patientName + ".pdf";
        pdf.save(fileName);
      });
    }
  };
  console.log(window.innerWidth, "DSvsdvsdihvsoudgvsiduv")
  const handleModalOpen = (e,type, obj) => {
    e.stopPropagation()
    console.log(type);
    if (type === 'Ready to View') {
        setIsModalOpen(true);
    }
    setCurrObject(obj);
}
function handlePrescriptionNameChange(e, id) {
  console.log(e.target, "sdvsdhvosdhvos", id)
  if (e.target.id === id) {
      setPrescriptionName({
          name: e.target.value,
          id: id
      })
  }
}
function handleInputClick(e) {
  e.stopPropagation();
  
}
async function handlePrescriptionNameSubmit(e) {
  e.stopPropagation();
  try {
      await http.get(`${process.env.REACT_APP_BASEURL}PatientDocument/updatePrescName?id=${prescriptionName.id}&prescriptionName=${prescriptionName.name}&patientCode=${userData.code}`);
  } catch (error) {
      console.log(error);
  }
}
useEffect(() =>{
  if(currSelectedList.length && visitidParam){
    let selectedPrescription = currSelectedList.filter(res => res.visitId == visitidParam)
    handleClick(...selectedPrescription)
  }
},[visitidParam,currSelectedList])
async function handleClick(res1) {
  if (res1.visitId !== 0) {
      setOriginalPrescription("");
      try {
          const res = await http.get(`${process.env.REACT_APP_BASEURL}Visit/list?id=${res1.visitId}&visitSummary=Y`)
          setSelectedPrescription(res.data)
          let prescribedItems = {
              medicines: [],
              labtest: [],
          };
          if (res.data[0].patientDrugPrescriptionList?.length) {
              const temp = JSON.stringify(res.data)
              const mData = JSON.parse(temp)
              let medicineCode = mData[0].patientDrugPrescriptionList.map((drug) => {
                  return drug.drugCode;
              });
              console.log(medicineCode, "doihsodhvsdv");
              var raw = JSON.stringify(medicineCode);
              dispatch(getAllProductByIds(raw))
                  .then((res) => {
                      if (res?.length) {
                          prescribedItems.medicines = res;
                          setPrescribedList(prescribedItems);
                      }
                  })
                  .catch((err) => {
                  });
          }
          else {
              setPrescribedList([]);
          }
          if (screen <= 650) {
              if (res1.id === isActive) {
                  setIsActive(false);
              } else {
                  setIsActive(res1.id);
              }
              setVisible(true);
          } else {
              setVisible(true);
          }
      } catch (error) {
          console.log(error)
      }
  } else if (!res1.conversionNeeded) {
      setOriginalPrescription(res1.docName);
      if (screen <= 650) {
          if (res1.id === isActive) {
              setIsActive(false);
          } else {
              setIsActive(res1.id);
          }
      } else {
          setVisible(true);
      }
  }
}
  
  return (
    <Layouts>
      <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
            <div className=" md:flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-White">
                My Prescription
              </div>
              <div className="hidden md:flex items-center">
                <FamilyDropdown
                  title={" Get prescriptions for"}
                  height={"3rem"}
                  onSelect={loadPrescriptionFor}
                />
                  <Select
                               onChange={handleTypeChange}
                                defaultValue="Select on option"
                               
                                style={{
                                    width: 200,
                                    height: 40,
                                }}
                                options={[
                                    {
                                        value: "Converted Prescription",
                                        label: "Converted Prescription",
                                    },
                                    {
                                        value: "Original Prescription",
                                        label: "Original Prescription",
                                    },
                                ]}
                            />
              </div>
            </div>
          </div>
          <div className="md:hidden flex items-center mt-5">
        
           
            <FamilyDropdown
              title={" Get prescriptions for"}
              height={"3rem"}
              onSelect={loadPrescriptionFor}
            />
          </div>
          {/* </Row> */}
          <Row gutter={24} className="mt-3">
            {selectlist === 'Original Prescription' ?
            <>
            {prescriptionsList?.length !== 0 ? (
              prescriptionsList.map((user, idx) => (
              
                <Col xs={24} sm={24} md={8} key={idx}>
                  <div className="w-full rounded-md my-2 p-5 bg-White">
                    <div className="flex items-center gap-4">
                      <div className="h-[80px] w-[80px] rounded-full">
                        <img
                          src={EPrescription}
                          alt="img"
                          className="w-full h-full"
                        />
                      </div>
                      <div className="w-full">
                        <h6 className="font-medium text-primaryColor text-lg mb-1">
                          Dr. {user.userName}
                        </h6>
                        <div className="flex gap-5 items-center">
                          <p className="text-textGray text-sm">
                            Reason :{" "}
                            <span>
                              {user.consultReason?.charAt(0).toUpperCase()}
                              {user.consultReason?.slice(1)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="flex gap-2 items-center text-primaryColor text-sm ">
                        <img src={calender} alt="img" className="h-3" />
                        {moment(user.createdDate).format("MM/DD/YYYY")}
                      </div>
                      <div
                        onClick={() => user.conversionNeeded ? handleSavedPrescription(user) : handlePrescription(user,user.id)}
                        className="text-secondaryColor underline text-sm cursor-pointer"
                      >
                        View Prescription
                      </div>
                    </div>
                  </div>
               </Col>
              
              ))
            ) : (
              <div class=" w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
                <div className="">
                  <img src={NodataImage} alt="image" className="w-[60px]" />
                </div>
                <p className="text-center font-light item-center text-base text-textGray">
                  No prescription available!
                </p>  
              </div>
            )}
            </> :
            <>
             {savedPrescription?.length > 0 ?
                            (<div className="grid sm:grid-cols-3 grid-cols-1 gap-5 mt-10">
                                {
                                    currSelectedList.map((res) => {
                                        console.log(res);
                                        return (
                                            <div className="bg-White rounded-lg p-5 md:p-5 common-shadow " onClick={(e) => handleModalOpen(e,'Ready to View', res)}>
                                                <div className="md:flex gap-5">
                                                    <div className="sm:w-full w-3/4 sm:h-full h-3/4">
                                                      {res?.documentType ==="png" ||   res?.documentType ==="jpg" || res?.documentType ==="jpeg"  ?<img
                                                            src={process.env.REACT_APP_IMG_BASEURL + res.docName}
                                                            alt="images"
                                                            className="w-full h-[200px] object-contain"
                                                        /> : res?.documentType ==="pdf" ? 
                                                        <embed
                                                        // height="95px"
                                                        // width="68px"
                                                        className="object-contain w-full"
                                                        src={`${process.env.REACT_APP_IMG_BASEURL}${res.docName}`}
                                                      />: "" }
                                                    </div>
                                                    <div className="space-y-3 sm:mt-0 mt-4 ">
                                                        <div className="flex items-center gap-2">
                                                            <CalendarOutlined />
                                                            <h4 className="text-primaryColor sm:text-lg text-sm font-medium">
                                                                {moment(res.createdDate).format("DD/MM/YYYY")}
                                                            </h4>
                                                        </div>
                                                        <div className="sm:text-base text-sm break-words  ">{res?.docName}</div>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter prescription name"
                                                            
                                                            className="border rounded-md h-10 sm:w-[100%] w-[97%] p-1"
                                                            value={prescriptionName.id === res.id ? prescriptionName.name : res.prescriptionName} 
                                                            onChange={(e) => handlePrescriptionNameChange(e, res.id)} 
                                                            onClick={handleInputClick}
                                                        />
                                                    </div>
                                                </div>
                                                {prescriptionName.id == res.id ? <ArrowCircleRightIcon
                                            className="mr-1 text-brand-lightgreen absolute right-0 bottom-1 "
                                            onClick={handlePrescriptionNameSubmit}
                                            sx={{ fontSize: 20 }}
                                        /> : null} 
                                                <div className="md:flex justify-end mb-6  md:mt-3 mt-3 text-secondaryColor text-sm cursor-pointer">
                                                    {res.visitId !== 0 ?
                                                        (
                                                            <p className="sm:text-base text-sm border border-sm px-2 py-2 sm:py-0 rounded w-max" onClick={(e) => { e.stopPropagation(); handleClick(res)}}>
                                                                Click to View
                                                            </p>
                                                        ) : res.visitId === 0 && !res.conversionNeeded ? (
                                                            <p className="sm:text-base text-sm border border-sm px-2 py-2 sm:py-0 rounded w-max" onClick={(e) => { e.stopPropagation();handleClick(res)} }>
                                                                Click to View
                                                            </p>
                                                        ) : <p className="text-base text-danger">
                                                            Not Ready
                                                        </p>}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>) :
                            (
                                <div className="w-full h-full flex items-center justify-center my-6">
                                    <div className="flex flex-col">
                                        <img
                                            className="h-24 lg:h-28 mt-5 lg:mt-0 "
                                            src={noDataFound}
                                            alt="No Diagnostics Appointments Available"
                                        />
                                        <h4 className="font-medium  text-gray-400 text-md">
                                            No E-Prescription Found
                                        </h4>
                                    </div>
                                </div>
                            )
                        }
                        </>
                      }
          </Row>
        </div>
      </section>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-xl">
            Filter Prescription
          </p>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: 20 }}
        footer={[]}
      >
        <Form className="CommonForm" layout="vertical" autoComplete="off">
          <Form.Item label="Date" name="date">
            <Input placeholder="Record on" />
          </Form.Item>
          <Form.Item label="Title *" name="email">
            <Input placeholder="Input placeholder" />
          </Form.Item>
          <Form.Item label="Document Type *" name="phone">
            <Input placeholder="Choose document type" />
          </Form.Item>
          <Form.Item>
            <button
              onClick={handleCancel}
              className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
              type="primary"
              htmlType="submit"
            >
              Submit
            </button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={prescriptionView}
        //  open={prescriptionView}
        title="E-Prescription"
        className="commonModal"
        width={900}
        centered={true}
        footer={false}
        onOk={handleOk}
        onCancel={handleCancel}
      >
         <>
         <div id= "abc"  className="w-full mb-4  overflow-x-auto cursor-pointer py-4 px-4">
        <div
            // style={checked ? {border: "4px solid rgb(79, 175, 217)"} : {}}
          className="p-5 bg-white-100 relative justify-center  border rounded-xl dark:border-slate-700 prescriptionTemplate"
       
        >
           {/* {cart ? <Checkbox className= {`absolute z-10 ${checked ? "-top-2  -left-2" : "top-2 left-2"}`} onChange={e => handleClick(e.checked)} checked={checked}></Checkbox> : null} */}

          {/* <span>{JSON.stringify(currUser)}</span> */}
          <div className="px-2">
            <div className="flex justify-between mb-4  ml-1">
            </div>

            <div className="lg:flex flex flex-col">
              <h6 className="font-bold doc-font ">
                {currUser?.userSalutation ? currUser?.userSalutation :"Dr."}{" "}
                {currUser?.userName}
              </h6>
              <h6 className="font-bold doc-font">
                {currUser?.userQualification}
              </h6>
              <h6 className="font-bold ">
                {currUser?.userSpecialityDept}
              </h6>
              <h6 className="font-bold ">
                Regn No:{currUser?.userMCIVerification}
              </h6>
              {/* <hr className="my-2" /> */}
              <hr className="hr-line mt-6" />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-6 mb-5 mt-6">
              <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                Name:
              </div>
              <div className="border text-xs  dark:border-slate-700 px-3 py-1">
                {currUser?.patientsalutation +
                  currUser?.patientName}
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                Age/Sex:
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1">
                {currUser?.patientAge +
                  " / " +
                  (currUser?.patientgender === "M"
                    ? "Male"
                    : "Female")}
              </div>

              {currUser?.patientHospitalName === null ?" ":

              (
                <>
                <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                Hospital:
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1">
                {currUser?.patientHospitalName}
              </div>
              </>

              )}
             
              <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                Phone Number:
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1">
                {currUser?.patientMobileNo}
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                Visit Type:
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1">
                {currUser?.consultationType === "Q"
                  ? "Quick Consultation"
                  : currUser?.consultationType === "V"
                    ? "Video Consultation"
                    : currUser?.consultationType === "I"
                      ? "In-person Consultation"
                      : currUser?.consultationType === "A"
                        ? "Audio Consultation"
                        : "Quick Consultation"}
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1 font-bold">
                Visit Date:
              </div>
              <div className="border text-xs dark:border-slate-700 px-3 py-1" v>
                {moment(currUser?.visitDate).format("DD/MM/yyyy")}
              </div>
            </div>
            {/* <img src={rximg} style={{ width: "25px" }} /> */}

            {currUser?.consultReason &&
              <div className="grid grid-cols-1 my-2">
                <p>
                  <b className="underline underline-offset-1">
                    Chief Complaint:
                  </b>{" "}
                  {currUser?.consultReason}
                </p>
              </div>
            }

            {(currUser?.primarySymptoms || currUser?.primaryDiagnosis) &&
              <div className="grid grid-cols-1 my-2">
                <p className="">
                  <b className="underline underline-offset-1">
                    Primary Diagnosis :
                  </b>{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  ICD-10 : {currUser?.primarySymptoms}{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  Description : {currUser?.primaryDiagnosis}{" "}
                </p>
              </div>
            }
            {(currUser?.secondarySymptoms || currUser?.secondaryDiagnosis) &&
              <div className="grid grid-cols-1 my-2">
                <p className="">
                  <b className="underline underline-offset-1">
                    Secondary Diagnosis :
                  </b>{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  ICD-10 : {currUser?.secondarySymptoms}{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  Description : {currUser?.secondaryDiagnosis}{" "}
                </p>
              </div>
            }
            {currUser?.symptoms &&
              <div className="mb-5">
                <p className="underline underline-offset-1">
                  <b className="underline underline-offset-1">Symptoms :</b>{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  {currUser?.symptoms}
                </p>
              </div>
            }
            {currUser?.patientVitalList && currUser?.patientVitalList.length && (<>
              <div className="grid grid-cols-1 my-2">
                <p>
                  <b className="underline underline-offset-1">
                    Vitals :
                  </b>{" "}
                  {currUser?.patientVitalList.height && (<> Height   - {currUser?.patientVitalList.height} CM  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {currUser?.patientVitalList.weight && (<> Weight   -  {currUser?.patientVitalList.weight}  KG  </>)}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {currUser?.patientVitalList.bmi && (<> BMI   -  {currUser?.patientVitalList.bmi}  KG/M2  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {currUser?.patientVitalList.systolic && (<> Blood Pressure (SYS)  &nbsp;&nbsp; -  {currUser?.patientVitalList.systolic}  mmHg  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {currUser?.patientVitalList.diastolic && (<> Blood Pressure (DIA)   -  {currUser?.patientVitalList.diastolic}  mmHg  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {currUser?.patientVitalList.spo2 && (<> Pulse Ox.   -  {currUser?.patientVitalList.spo2}  %  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {currUser?.patientVitalList.heartRate && (<> Heart Rate   -  {currUser?.patientVitalList.heartRate}  Beats/min  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {currUser?.patientVitalList.respirationRate && (<> Respiration Rate   -  {currUser?.patientVitalList.respirationRate}  Breaths/min  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {currUser?.patientVitalList.temperature && (<> Temperature   -  {currUser?.patientVitalList.temperature}  °F  </>)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
              </div>
            </>)}


            <div className="mb-2 overflow-scroll">
              
               {currUser?.patientDrugPrescriptionList?.length &&
              // currUser?.length &&   
              //  currUser?.patientDrugPrescriptionList.map((res, i)=> (
              //   {currUser?.patientDrugPrescriptionList?.length &&
                <>
                  <p className="  mb-2 underline underline-offset-1">
                    <b className="underline underline-offset-1">
                      Medicines Prescribed :
                    </b>{" "}
                  </p>


                  <table className="table-auto w-full mb-5">
                    <thead>
                      <tr>
                        <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left"></th>
                        <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                          Medication
                        </th>
                        <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                          Dosage
                        </th>
                        <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                          Qty
                        </th>
                        <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                          Instruction
                        </th>
                        {/* <th className="border-2 border-inherit">Periodically</th> */}
                        <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                          Duration
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        // currUser?.map((res, i) 
                        currUser?.patientDrugPrescriptionList?.map((res, i)=> (
                          <>
                            <tr>
                              <td className="border dark:border-slate-700 px-3 py-1">
                                {i + 1}
                              </td>
                              <td className="border dark:border-slate-700 px-3 py-1">
                                {res?.drugName}
                                <br></br>
                                <span className="text-xs">{res?.composition}</span>
                              </td>
                              <td className="border dark:border-slate-700 px-3 py-1">
                                {res?.dosage}
                              </td>
                              <td className="border dark:border-slate-700 px-3 py-1">
                                {res?.quantity}
                              </td>
                              <td className="border dark:border-slate-700 px-3 py-1">
                                {res.instruction == "0"
                                  ? "After Meal"
                                  : "Before Meal"}
                              </td>
                              <td className="border dark:border-slate-700 px-3 py-1">
                                {" "}
                                {res?.duration} days
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                </>
              }
              <div className="mt-2  mb-5">
                {currUser?.patientLabTestsList?.length &&
                  <p className="  underline underline-offset-1  mb-2">
                    <b className="underline underline-offset-1">
                      Lab Test Prescribed :
                    </b>{" "}
                  </p>
                }
                {currUser?.patientLabTestsList?.length &&
                 
                    <div>
                      <div className="">
                        <div className="text-md font-bold font-rubik text-left border dark:border-slate-700 px-3 py-1">
                         Test Name
                        </div>
                        {/* <div className="text-md font-bold font-rubik  text-left border dark:border-slate-700 px-3 py-1">
                          Test Type
                        </div> */}
                      </div>
                         { currUser?.patientLabTestsList.map((labres, i) => ( <div
                            className=" "
                            key={i}
                          >
                            <div className="text-sm  border dark:border-slate-700 px-3 py-1">
                              {labres?.labTestName}
                            </div>
                            {/* <div className="text-sm  text-left border dark:border-slate-700 px-3 py-1">
                              {labres?.testType}
                            </div> */}
                          </div>))}
                        {/* ))} */}
                    </div>
                  }
              </div>
            </div>
            <div className="my-2">
              <div className="w-6/6  my-2">
                {/* <div>
                  <p className="">Cheif Complaint</p>
                  <p className="text-base font-montserrat  font-thin ">
                    {currUser?.consultReason}
                  </p>
                </div> */}

                {currUser?.recommendation && (
                  <div className="mb-5">
                    <p className="underline underline-offset-1">
                      <b className="underline underline-offset-1">
                        Recommendation :
                      </b>{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {currUser?.recommendation}
                    </p>
                  </div>
                )}

                {currUser?.followUpVisitDate && (
                  <div>
                    <p className=" my-2">
                      <b className="underline underline-offset-1">
                        Scheduled follow up visit date :
                      </b>{" "}
                      {moment(currUser?.followUpVisitDate).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex  justify-between">


              <div className="mt-6">
                {currUser?.hospitalStamp &&
                  <img
                    // onClick={download}
                    // src={`https://storage.googleapis.com/curebay-nonprod-application-data/${currUser.hospitalStamp}`}
                    src = {`${process.env.REACT_APP_IMG_BASEURL}${currUser.hospitalStamp}`}
                    alt="Stamp"
                    width={150}
                  />
                }
              </div>

              <div className="mt-6">
                {currUser?.userSignature && (
                  <>
                    <img
                      // onClick={download}
                     // src={`https://storage.googleapis.com/curebay-nonprod-application-data/${currUser.userSignature}`}
                      src = {`${process.env.REACT_APP_IMG_BASEURL}${currUser.userSignature}`}
                      alt="Signature"
                      width={150}
                    />
                    <p className="font-rubik text-gray-primary text-sm">
                      Dr.{currUser?.userName}
                    </p>
                    <p className="font-rubik text-gray-primary text-sm">
                      {currUser?.userQualification}{" "}
                      {currUser?.userSpecialityDept}
                    </p>
                    <p className="font-rubik text-gray-primary text-sm">
                      Regn No. {currUser?.userMCIVerification}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 ">
            <div className="px-1 py-1">

              {
                currUser?.consultationType === "I" ?
                  (
                    <span> </span>

                  ) :
                  (
                    <p className="text-gray-500 text-sm ">
                               <b>Disclaimer :</b>
                               <ul className="list-disc ml-8">
                                 <li>The information and advice provided here is provisional in nature as it is based on the limited information made available by the patient</li>
                                 <li>The patient is advised to visit in person for thorough examination at the earliest</li>
                                 <li>The information is confidential in nature and for recipient's use only</li>
                                 <li>The Prescription is generated on a Teleconsultation</li>
                                 <li>Not valid for medico - legal purpose</li>
                                 {" "}
                               </ul> 
                             </p>
                  )
              }


            </div>
          </div>
          <div className="grid grid-cols-1 ">
            <div className="px-1 py-1">
              <p className="text-gray-500 text-sm ">

              </p>
            </div>
          </div>
        </div>
        </div>
          <div className="flex justify-end mb-5 w-full">
            <button
              onClick={() => download()}
              className="font-medium text-rubik text-White border bg-primaryColor border-brand-secondary mt-4 rounded-md text-sm px-2 py-2"
            >
              Download E- prescription
            </button>
          </div>
        </>
      </Modal>
      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
        className="w-full sm:w-1/2"
      >
        <h2>Saved Prescription</h2>
        <div className="h-4/5" >
          
          <div class="flex flex-col justify-center mt-11 h-full relative">
          <PrescriptionTempate prescriptionType = "myprescription" originalPrescription = {originalPrescription} selectedPrescription = {selectedPrescription} prescribedList = {prescribedList} />
          </div>
          {
            originalPrescription ? <>
            <div className="flex items-center font-semibold " >When did you consulted with doctor last time?
              <button class="w-56 sm:h-10 btn-52 text-white font-semibold align-center bg-blue-700 ml-2"
              onClick={() => history.push('/services/book-an-appointment')}
              >
            Book Appoinment
          </button>
               </div>
            </> : null}
        
        </div>
      </Sidebar>
    </Layouts>
  );
}
export default MyPrescription;