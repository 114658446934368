import http from "../../../Redux/Services/http-common";
import { AddtoCart, getCartDetails } from "../../../Redux/Action/cartPlaceOrderAction";
import { getAllProductByIds } from "../../../Redux/Action/pharmacyAction";
import { LoginWithMedicare, getToken } from "../../../Redux/Action/userActions";
import { secureStorage } from "../../../Redux/Reducer/authReducer";
import moment from "moment";
const getMedicarePurchaseData = async (token, dispatch, cartList = {} , type) => {
  let url = `${process.env.REACT_APP_TOTALCARE_BASEURL}medicare/order/getOrderDetails`
  await dispatch(getToken())
  let access_token = secureStorage.getItem('token')
  let payload = {
    purchaseToken: token
  }
const config = {
  headers: {
    'Authorization': `Bearer ${access_token}` // Including the token in the Authorization header
  }
};
let flag = false
  let response = await http.post(url , payload, config)
  let cartData = {}
  let medicineData = []
  if(response?.data?.succesObject){
    let patientData = {
      patientSession: response.data?.succesObject?.patientSession,
      patient: response.data?.succesObject?.patient
    };
    if(type == 'med'){
      let medicineCodes  = response.data?.succesObject?.patientDrugsOrderList?.map(res => res.drugCode)

  
      let productStringfy = JSON.stringify(medicineCodes);
      let medicineIdresponse = await dispatch(getAllProductByIds(productStringfy))
       medicineData = medicineIdresponse.map(res =>{
        let data = response.data?.succesObject?.patientDrugsOrderList?.find(res1 => res1.drugCode == res.id)
        return{
          ...res , 
          quantity: data.quantity
        }
      })
    }

    if (response.data.responseCode == "200") {
      await dispatch(LoginWithMedicare(patientData)).then( async (res) => {
        let status = "true";
        localStorage.setItem("loginstatus", status);
        if(type == 'med'){
          let cartDetail = await dispatch(getCartDetails(patientData.patient.code))
          let cartData = {}
          if(cartDetail?.length){
          cartData =  addtoCart(response?.data?.succesObject?.patient,  medicineData, cartDetail[0], dispatch)
          }else{
          cartData =  addtoCart(response?.data?.succesObject?.patient,  medicineData, cartDetail = {}, dispatch)
          }
          await  dispatch(AddtoCart(cartData)).then((res) => {
           
              })
        }
        flag = true
      });
  
    }
  }

  if(flag){
    return true
  } else{
    return false
  }
};

export { getMedicarePurchaseData };


const addtoCart =  (patient, medicineData, cartList, dispatch) => {
  // setIsAdding(true);
 console.log(cartList , "sdvhdsovgdsuogvdousgvosdv")
  let prescriptionRequired = "N";
  let ePrescriptionRequired = "N";

  // if (state?.detail?.prescriptionDoc) {
  //   prescriptionRequired = "Y";
  //   ePrescriptionRequired = "Y";
  // } else {
  //   prescriptionRequired = "N";
  //   ePrescriptionRequired = "N";
  // }
  let dataObj = {};
  let productDetail = medicineData.map(res =>{
    return  {
      drugCode: res.id,
      drugName: res.medicineName,
      unitPrice: res.medicineRate,
drugCategory: res?.drugCategory,
      discountAmount: res?.drugsInfoDiscountedRate ? res?.drugsInfoDiscountedRate : 0.00,
      totalAmount: res.medicineRate * res.quantity ,
      quantity: res.quantity,
      cartId: cartList && cartList.id ? cartList.cartId : "",
      createdBy: patient.code,
      createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
      modifiedBy: patient.code,
      modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
      orderId: null,
      patientId: patient.code,
      prescriptionRequired: res.prescriptionRequired == "Yes" ? "Y" : "N",
      ePrescriptionRequired: res.ePrescriptionRequired == "Yes" ? "Y" : "N",
      medicineTypeOfSell: res?.medicineTypeOfSell,
      membershipCode:null,
      membershipName:null,
      membershipCard :null,
      membershipDiscountPercentage: 0.00
    }
  })

  if (cartList && cartList.patientMedicineOrder) {
    cartList.patientMedicineOrder.forEach((element) => {
      productDetail.map(res =>{
        element.patientMedicineOrderDetailsList.push(res);
      })
    });
    dataObj = cartList;
  } else {
    dataObj = {
      cartId: cartList && cartList.id ? cartList.cartId : "",
      createdBy: patient.code,
      createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
      modifiedBy: patient.code,
      modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
      orderId: null,
      patientId: patient.code,
      status: 1,
      labOrdersYN: false,
      drugsOrdersYN: true,
      totalAmount: 0,
      patientMedicineOrder: [
        {
          orderId: "",
          patientId: patient.code,
          prescriptionId: null,
          cartId: cartList && cartList.id ? cartList.cartId : "",
          txnId: "",
          totalAmount: medicineData[0].medicineRate,
          address1: null,
          address2: null,
          address3: null,
          city: null,
          state: null,
          country: null,
          pincode: null,
          deliveryAddress1: null,
          deliveryAddress2: null,
          deliveryAddress3: null,
          deliveryCity: null,
          deliveryState: null,
          deliveryCountry: null,
          deliveryZipcode: null,
          createdBy: patient.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: patient.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          status: 1,
          procedureStatus: -1,
          payMode: "E",
          collectionStatus: null,
          paymentLinkForPatient: "N",
          discountCouponCode: null,
          patientName: patient.firstName,
          patientGender: patient?.gender,
          patientMobile: patient?.mobile,
          docName: null,
          // docName: state?.detail?.prescriptionDoc,
          patientMedicineOrderDetailsList: productDetail
        },
      ],
    };
  }

  console.log(dataObj, "dvcsdhovihdsou")
  if(dataObj?.patientMedicineOrder?.length && dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.length > 0){
    let id = dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(item => item.drugCode);
    id.map((item , index , self) =>{
      if(self.indexOf(item) === index){
     } else{
      dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[self.indexOf(item)].quantity += dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[index].quantity
      dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[self.indexOf(item)].totalAmount = dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[self.indexOf(item)].quantity * dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[self.indexOf(item)].unitPrice
       }

 })

    let res = dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.filter((item , i , self) =>{
      if(id.indexOf(item.drugCode) == i){
        return item
      }
    })

    dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList = res
  }
  return dataObj
};
