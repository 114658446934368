import { useLocation } from "react-router-dom";
import { Steps } from "antd";
import React, { useState, useEffect } from "react";

const CartTimeLine = () => {
  const location = useLocation();
  const [id, setId] = useState()
  const events = [
    {
      key: 1,
      title: "My Cart",
      color: location.pathname == "/cart" ? "#66B889" : "#B0B0B0",
      image: "game-controller.jpg",
    },
    {
      key: 2,
      title: "Address/Prescription",
      color: location.pathname == "/cart/checkout" ? "#66B889" : "#B0B0B0",
    },
    {
      key: 3,
      title: "Summary",
      color: location.pathname == "/cart/review" ? "#66B889" : "#B0B0B0",
    },
  ];
  useEffect(() => {
    if (location.pathname == "/cart") {
      setId(1)
    } else if (location.pathname == "/cart/checkout") {
      setId(2)
    } else if (location.pathname == "/cart/review") {
      setId(3)
    }
  }, [location.pathname])

  return (
    <div className="hidden sm:block w-full bg-primaryColor rounded-md px-8 py-3">
      <Steps
        current={id ? id : ""}
        className="commonSteps w-[50%] mx-auto justify-center "
        items={events}
      />
    </div>
  );
};

export default CartTimeLine;
