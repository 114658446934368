// import { Card, Form, Select } from "antd";
// import Input from "antd/es/input/Input";
// import React, { useState } from "react";
// import BloodPressure from "../../../Assets/Images/Blood Pressure.png";
// import HeartRate from "../../../Assets/Images/Heart Rate.png";
// import Temperature from "../../../Assets/Images/Temperature.png";
// import BMI1 from "../../../Assets/Images/BMI1.png";
// import BMI from "../../../Assets/Images/BMI.png";
// import PulseOx from "../../../Assets/Images/PulseOx.png";
// import RespirationRate from "../../../Assets/Images/Respiration Rate.png";
// import Weight from "../../../Assets/Images/Weight.png";
// import Vector from "../../../Assets/Images/Vector.png";
// import Arrow from "../../../Assets/Images/Arrow.png";
// import { Button, Modal } from "antd";
// import Layouts from "../../Layouts";
// import BarChart from "../../../Assets/Images/BarChart.png";
// import AddVital from './AddVital';
// import { PlusOutlined } from "@ant-design/icons";

// function MyVitals() {
//   const [isBPoUp, setBPoup] = useState(false);
//   const [showvitals, setvitals] = useState(false);
//   const [hederpop, sethederpop] = useState("");
//   const [find, setFind] = useState("");
//   const [isTextVisible, setIsTextVisible] = useState(false);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isAddVitals, setAddVitals] = useState(false);
//   const [isUpdatePopup, setUpdatePopup] = useState(false);

//   const openPopup = (e) => {
//     debugger;
//     if (e === "BP") {
//       setFind(e);
//       sethederpop("Blood Pressure");
//     } else if (e === "TEMP") {
//       setFind(e);
//       sethederpop("Temparature");
//     } else if (e === "BMI") {
//       setFind(e);
//       sethederpop("BMI");
//     } else if (e === "HR") {
//       setFind(e);
//       sethederpop("HeartRate");
//     } else if (e === "BM") {
//       setFind(e);
//       sethederpop("BMI");
//     } else if (e === "PL") {
//       setFind(e);
//       sethederpop("PulseOx");
//     } else if (e === "RR") {
//       setFind(e);
//       sethederpop("RespirationRate");
//     } else if (e === "WE") {
//       setFind(e);
//       sethederpop("Weight");
//     }

//     setBPoup(true);
//   };
//   const handleCancel = () => {
//     setBPoup(false);
//   };

//   const handleIconClick = () => {
//     setIsModalVisible(true);
//   };

//   const addvitals = () => {
//     setAddVitals(true);
//   };

//   const AddVitalClose = () => {
//     setAddVitals(false);
//   };

//   const UpdateVitals = () => {
//     setUpdatePopup(true);
//   };

//   const UpdateVitalsClose = () => {
//     setUpdatePopup(false);
//   };

//   return (
//     <Layouts>
//       <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
//         <div className="w-[80%] mx-auto ">
//           <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
//             <div className=" md:flex justify-between items-center ">
//               <div className="font-medium md:text-2xl text-White">
//                 My Vitals
//               </div>
//               <div className="flex gap-5">
//                 <button
//                   onClick={addvitals}
//                   className="bg-White text-primaryColor text-xs md:text-sm font-semibold md:py-3 px-3 md:px-7 rounded"
//                 >
//                   Add New Vitals
//                 </button>
//                 <Select
//                   placeholder="Get vitals for"
//                   className="md:h-12 h-10  w-[200px]"
//                 ></Select>
//               </div>
//             </div>
//           </div>
//           <div className="md:grid grid-cols-3 gap-4 md:mt-5 mt-3 ">
//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={BloodPressure} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Blood Pressure
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs">
//                       SYS{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         140
//                       </span>{" "}
//                       mmHg
//                     </p>
//                     <p className="text-textGray font-light text-xs">
//                       DIA{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         80
//                       </span>{" "}
//                       mmHg
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="w-full bg-White p-5 mb-4 md:mb-0  rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={Temperature} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Temperature
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       High{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         98
//                       </span>{" "}
//                       degree
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={BMI1} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       BMI
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         22.49
//                       </span>{" "}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={HeartRate} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Heart Rate
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         80
//                       </span>{" "}
//                       beats/min
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={BMI} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       BMI
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         22.78
//                       </span>{" "}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={PulseOx} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Pulse Ox.
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       SpO2 Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         95%
//                       </span>{" "}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={RespirationRate} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Respiration Rate
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         17
//                       </span>{" "}
//                       breath/min
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={Weight} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Weight
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         65
//                       </span>{" "}
//                       kg
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <Modal
//         open={isBPoUp}
//         onCancel={handleCancel}
//         footer={[]}
//         title={
//           <span style={{ color: "primaryColor", fontSize: "20px" }}>
//             {hederpop}
//           </span>
//         }
//       >
//         <div className="flex justify-start items-center">
//           {find == "BP" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "TEMP" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "BMI" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "HR" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "BM" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "PL" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "RR" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "WE" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//         </div>
//       </Modal>
//       <Modal
//         title="Add Vitals"
//         open={isAddVitals}
//         footer={false}
//         onCancel={AddVitalClose}
//       >
//         <>
//           <hr class="my-2" />
//           <div class="grid grid-cols-1 md:grid-cols-2 gap-8 pt-4">
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-1 items-center">
//                 <img src={BloodPressure} alt="sort" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   Blood Pressure <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between">
//                   <input
//                     id="height"
//                     name="height"
//                     type="text"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Blood Pressure"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-4">
//                     breaths
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={Temperature} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   Temperature <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Temperature"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     *F
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={BMI1} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   BMI <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Weight"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     Kg
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={HeartRate} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   HeartRate <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Weight"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     Beats
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={BMI} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   BMI <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Weight"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     Kg
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={PulseOx} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   PulseOx <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Weight"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     %
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={Weight} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   Weight <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Weight"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     Kg
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={RespirationRate} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   RespirationRate <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Weight"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     breat
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//           </div>
//           <div class="flex items-end">
//             <button class="bg-primaryColor  text-white font-bold py-2 px-4 rounded mt-5">
//               <span className="text-White">Save Data</span>
//             </button>
//           </div>
//         </>
//       </Modal>
//       <Modal
//         open={isUpdatePopup}
//         footer={false}
//         onCancel={UpdateVitalsClose}
//         width={"350px"}
//       >
//         <div className="w-[250px]">
//           <div className="p-5 bg-white rounded-lg shadow-lg">
//             <div className="mb-4">
//               <p className="text-2xl font-medium">Enter Vitals</p>
//             </div>
//             <div className="mb-4">
//               <input
//                 autoComplete="off"
//                 id="systolic"
//                 name="systolic"
//                 type="number"
//                 value={addvitals?.systolic ?? ""}
//                 className="text-sm font-medium w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
//                 placeholder="SYS"
//               />
//             </div>
//             <div className="mb-4">
//               <input
//                 autoComplete="off"
//                 id="diastolic"
//                 name="diastolic"
//                 type="number"
//                 value={addvitals?.diastolic ?? ""}
//                 className="text-sm font-medium w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
//                 placeholder="DIA"
//               />
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </Layouts>
//   );
// }

// export default MyVitals;

import { Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BMI from "../../../../Assets/Images/BMI.png";
import BMI1 from "../../../../Assets/Images/BMI1.png";
import BarChart from "../../../../Assets/Images/BarChart.png";
import BloodPressure from "../../../../Assets/Images/Blood Pressure.png";
import HeartRate from "../../../../Assets/Images/Heart Rate.png";
import PulseOx from "../../../../Assets/Images/PulseOx.png";
import RespirationRate from "../../../../Assets/Images/Respiration Rate.png";
import Temperature from "../../../../Assets/Images/Temperature.png";
import Vector from "../../../../Assets/Images/Vector.png";
import Weight from "../../../../Assets/Images/Weight.png";
import { getpatientvitaldetails } from "../../../../Redux/Action/patientAction";
import Layouts from "../../../Layouts";
import AddNewVitals from "./AddNewVitals";
import BloodPressureCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/BloodPressure";
import TemperatureCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/Temperature";
import BMICard from "../../../Pages/PostLogin/HealthRecords/VitalCards/BMI";
import HeartRateCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/HeartRate";
import PulseRateCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/PulseRate";
import WeightCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/Weight";
import HeightCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/Height";
import FamilyDropdown from "./FamilyDropDown";
import NodataImage from "../../../../Assets/Images/Icons/nodata.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";


function MyVitals() {
  const [isBPoUp, setBPoup] = useState(false);
  const [showvitals, setvitals] = useState(false);
  const [hederpop, sethederpop] = useState("");
  const [find, setFind] = useState("");
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddVitals, setAddVitals] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  const patientinfo = useSelector((state) => state.patientinfo);
  const [isUpdatePopup, setUpdatePopup] = useState(false);
  const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
  const [EditVital, setEditvital] = useState();
  const patientCode = useSelector((state) => state.authReducer.patientCode);
  const [activePatient, setActivePatient] = useState(patientCode);
  const [currUser, setCurrUser] = useState();
  // const patientvitalsdetaillist = useSelector((state) => state);
  // const { vitalslistData, isLoading } = patientvitalsdetaillist;

  const history = useHistory();

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/', {isLoginModalOpen : true});
    }
  }, [])

  const patientvitalsdetaillist = useSelector(
    (state) => state.patientvitalsdetail
  );
  // const { Patientvitalsdetailsdata, isLoading } = patientvitalsdetaillist;
  console.log("This is Console", patientvitalsdetaillist);
  const patientDetails = useSelector((state) => state.particularpatientdetails);

  const hasData = currUser && Object.keys(currUser).length > 0;
  console.log(patientCode);
  console.log(patientvitalsdetaillist);

  const editvitals = (e, user) => {
    e.preventDefault();
    setshowaddmemberpopup(true);
    setEditvital(user);
  };
  const refreshVitals = () => {
    dispatch(getpatientvitaldetails(userData.code));
  };

  async function fetchAllVitals(patientcode) {
    const res = await dispatch(getpatientvitaldetails(patientcode));
    console.log(res);
    setCurrUser(res[0]);
  }
  useEffect(() => {
    fetchAllVitals(userData.code);
    setActivePatient(userData.code);
  }, []);

  const openPopup = (e) => {
    if (e === "BP") {
      setFind(e);
      sethederpop("Blood Pressure");
    } else if (e === "TEMP") {
      setFind(e);
      sethederpop("Temparature");
    } else if (e === "BMI") {
      setFind(e);
      sethederpop("BMI");
    } else if (e === "HR") {
      setFind(e);
      sethederpop("HeartRate");
    } else if (e === "BM") {
      setFind(e);
      sethederpop("BMI");
    } else if (e === "PL") {
      setFind(e);
      sethederpop("PulseOx");
    } else if (e === "RR") {
      setFind(e);
      sethederpop("RespirationRate");
    } else if (e === "WE") {
      setFind(e);
      sethederpop("Weight");
    }

    setBPoup(true);
  };

  const getVitals = () => {
    setvitals(false);
    dispatch(getpatientvitaldetails(activePatient));
  };

  const loadVitalsFor = (patient) => {
    console.log(patient);
    setActivePatient(patient);
    fetchAllVitals(patient);
  };

  const handleCancel = () => {
    setBPoup(false);
  };

  const handleIconClick = () => {
    setIsModalVisible(true);
  };

  const addvitals = () => {
    setAddVitals(true);
  };

  const AddVitalClose = () => {
    setAddVitals(false);
  };

  const UpdateVitals = () => {
    setUpdatePopup(true);
  };

  const UpdateVitalsClose = () => {
    setUpdatePopup(false);
  };
  return (
    <Layouts>
      <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
            <div className=" md:flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-lg text-White">
                My Vitals
              </div>
              <div className="md:flex gap-2 hidden">
                <div
                  className="bg-lightBlue rounded-md md:w-12 w-4 md:h-12 h-8 flex justify-center items-center cursor-pointer text-primaryColor font-medium text-3xl"
                  onClick={(e) => editvitals(e, "")}
                >
                  +
                </div>
                <FamilyDropdown
                  title={"Get vitals for"}
                  className="w-full"
                  onSelect={loadVitalsFor}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-2 md:hidden mt-5 w-full">
            <FamilyDropdown
              title={"Get vitals for"}
              className="w-full"
              onSelect={loadVitalsFor}
            />
            <div
              className="bg-White rounded-md w-12 h-12 flex justify-center items-center cursor-pointer text-primaryColor font-medium text-3xl"
              onClick={(e) => editvitals(e, "")}
            >
              +
            </div>
          </div>

          {hasData ? (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5">
              <BloodPressureCard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
              <TemperatureCard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
              <BMICard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
              <HeartRateCard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
              <PulseRateCard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
              {/* <PulseRateCard saveCallback={getVitals} data={currUser? currUser:''} /> */}
              <WeightCard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
              <HeightCard
                saveCallback={getVitals}
                data={currUser ? currUser : ""}
                activePatient={activePatient}
                fetchAllVitals={fetchAllVitals}
              />
            </div>) : (
            <div className="flex justify-center items-center text-center mt-5 text-gray-500">
              <p>No Vitals available.</p>
            </div>
          )}
        </div>
      </section>

      <Modal
        open={isBPoUp}
        onCancel={handleCancel}
        footer={[]}
        title={
          <span style={{ color: "primaryColor", fontSize: "20px" }}>
            {hederpop}
          </span>
        }
      >
        <div className="flex justify-start items-center">
          {find == "BP" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
          {find == "TEMP" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
          {find == "BMI" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
          {find == "HR" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
          {find == "BM" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
          {find == "PL" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
          {find == "RR" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
          {find == "WE" && (
            <img
              src={BarChart}
              alt="Bar Chart"
              style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
            />
          )}
        </div>
      </Modal>
      <Modal
        title="Add New Vitals"
        className="commonModal"
        open={showaddmemberpopup}
        onOk={() => setshowaddmemberpopup(!showaddmemberpopup)}
        width={1200}
        onCancel={() => {
          setshowaddmemberpopup(!showaddmemberpopup);
          refreshVitals();
        }}
        style={{ top: 20 }}
        footer={[]}
      >
        <AddNewVitals
          editvitals={EditVital}
          closePopup={() => {
            setshowaddmemberpopup(!showaddmemberpopup);
            refreshVitals();
          }}
          saveMessage={(message) => {
            setshowaddmemberpopup(!showaddmemberpopup);
            // toast(message);
            refreshVitals();
          }}
          fetchAllVitals={fetchAllVitals}
          patientCode={activePatient}
        />
      </Modal>
      <Modal
        open={isUpdatePopup}
        footer={false}
        onCancel={UpdateVitalsClose}
        width={"350px"}
      >
        <div className="w-[250px]">
          <div className="p-5 bg-white rounded-lg shadow-lg">
            <div className="mb-4">
              <p className="text-2xl font-medium">Enter Vitals</p>
            </div>
            <div className="mb-4">
              <input
                autoComplete="off"
                id="systolic"
                name="systolic"
                type="number"
                value={addvitals?.systolic ?? ""}
                className="text-sm font-medium w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                placeholder="SYS"
              />
            </div>
            <div className="mb-4">
              <input
                autoComplete="off"
                id="diastolic"
                name="diastolic"
                type="number"
                value={addvitals?.diastolic ?? ""}
                className="text-sm font-medium w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                placeholder="DIA"
              />
            </div>
          </div>
        </div>
      </Modal>
    </Layouts>
  );
}

export default MyVitals;

// import { Card, Form, Select } from "antd";
// import Input from "antd/es/input/Input";
// import React, { useState } from "react";
// import BloodPressure from "../../../Assets/Images/Blood Pressure.png";
// import HeartRate from "../../../Assets/Images/Heart Rate.png";
// import Temperature from "../../../Assets/Images/Temperature.png";
// import BMI1 from "../../../Assets/Images/BMI1.png";
// import BMI from "../../../Assets/Images/BMI.png";
// import PulseOx from "../../../Assets/Images/PulseOx.png";
// import RespirationRate from "../../../Assets/Images/Respiration Rate.png";
// import Weight from "../../../Assets/Images/Weight.png";
// import Vector from "../../../Assets/Images/Vector.png";
// import Arrow from "../../../Assets/Images/Arrow.png";
// import { Button, Modal } from "antd";
// import Layouts from "../../Layouts";
// import BarChart from "../../../Assets/Images/BarChart.png";
// // import AddVital from './AddVital';
// import { PlusOutlined } from "@ant-design/icons";

// function MyVitals() {
//   const [isBPoUp, setBPoup] = useState(false);
//   const [showvitals, setvitals] = useState(false);
//   const [hederpop, sethederpop] = useState("");
//   const [find, setFind] = useState("");
//   const [isTextVisible, setIsTextVisible] = useState(false);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isAddVitals, setAddVitals] = useState(false);
//   const [isUpdatePopup, setUpdatePopup] = useState(false);

//   const openPopup = (e) => {
//     debugger;
//     if (e === "BP") {
//       setFind(e);
//       sethederpop("Blood Pressure");
//     } else if (e === "TEMP") {
//       setFind(e);
//       sethederpop("Temparature");
//     } else if (e === "BMI") {
//       setFind(e);
//       sethederpop("BMI");
//     } else if (e === "HR") {
//       setFind(e);
//       sethederpop("HeartRate");
//     } else if (e === "BM") {
//       setFind(e);
//       sethederpop("BMI");
//     } else if (e === "PL") {
//       setFind(e);
//       sethederpop("PulseOx");
//     } else if (e === "RR") {
//       setFind(e);
//       sethederpop("RespirationRate");
//     } else if (e === "WE") {
//       setFind(e);
//       sethederpop("Weight");
//     }

//     setBPoup(true);
//   };
//   const handleCancel = () => {
//     setBPoup(false);
//   };

//   const handleIconClick = () => {
//     setIsModalVisible(true);
//   };

//   const addvitals = () => {
//     setAddVitals(true);
//   };

//   const AddVitalClose = () => {
//     setAddVitals(false);
//   };

//   const UpdateVitals = () => {
//     setUpdatePopup(true);
//   };

//   const UpdateVitalsClose = () => {
//     setUpdatePopup(false);
//   };

//   return (
//     <Layouts>
//       <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
//         <div className="w-[80%] mx-auto ">
//           <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
//             <div className=" md:flex justify-between items-center ">
//               <div className="font-medium md:text-2xl text-White">
//                 My Vitals
//               </div>
//               <div className="flex gap-5">
//                 <button
//                   onClick={addvitals}
//                   className="bg-White text-primaryColor text-xs md:text-sm font-semibold md:py-3 px-3 md:px-7 rounded"
//                 >
//                   Add New Vitals
//                 </button>
//                 <Select
//                   placeholder="Get vitals for"
//                   className="md:h-12 h-10  w-[200px]"
//                 ></Select>
//               </div>
//             </div>
//           </div>
//           <div className="md:grid grid-cols-3 gap-4 md:mt-5 mt-3 ">
//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={BloodPressure} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Blood Pressure
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs">
//                       SYS{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         140
//                       </span>{" "}
//                       mmHg
//                     </p>
//                     <p className="text-textGray font-light text-xs">
//                       DIA{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         80
//                       </span>{" "}
//                       mmHg
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="w-full bg-White p-5 mb-4 md:mb-0  rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={Temperature} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Temperature
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       High{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         98
//                       </span>{" "}
//                       degree
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={BMI1} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       BMI
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         22.49
//                       </span>{" "}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={HeartRate} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Heart Rate
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         80
//                       </span>{" "}
//                       beats/min
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={BMI} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       BMI
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         22.78
//                       </span>{" "}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={PulseOx} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Pulse Ox.
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       SpO2 Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         95%
//                       </span>{" "}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={RespirationRate} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Respiration Rate
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         17
//                       </span>{" "}
//                       breath/min
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="w-full bg-White p-5 mb-4 md:mb-0 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={Weight} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Weight
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         65
//                       </span>{" "}
//                       kg
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <Modal
//         open={isBPoUp}
//         onCancel={handleCancel}
//         footer={[]}
//         title={
//           <span style={{ color: "primaryColor", fontSize: "20px" }}>
//             {hederpop}
//           </span>
//         }
//       >
//         <div className="flex justify-start items-center">
//           {find == "BP" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "TEMP" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "BMI" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "HR" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "BM" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "PL" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "RR" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "WE" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//         </div>
//       </Modal>
//       <Modal
//         title="Add Vitals"
//         open={isAddVitals}
//         footer={false}
//         onCancel={AddVitalClose}
//       >
//         <>
//           <hr class="my-2" />
//           <div class="grid grid-cols-1 md:grid-cols-2 gap-8 pt-4">
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-1 items-center">
//                 <img src={BloodPressure} alt="sort" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   Blood Pressure <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between">
//                   <input
//                     id="height"
//                     name="height"
//                     type="text"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Blood Pressure"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-4">
//                     breaths
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={Temperature} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   Temperature <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Temperature"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     *F
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={BMI1} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   BMI <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Weight"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     Kg
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={HeartRate} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   HeartRate <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Weight"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     Beats
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={BMI} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   BMI <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Weight"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     Kg
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={PulseOx} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   PulseOx <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Weight"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     %
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={Weight} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   Weight <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Weight"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     Kg
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//             <div class="w-11/12 md:w-52">
//               <div class="flex space-x-3 items-center">
//                 <img src={RespirationRate} alt="weight" class="w-8 h-8 mt-1" />
//                 <p class="text-sm font-medium">
//                   RespirationRate <span className="text-red-600">*</span>
//                 </p>
//               </div>
//               <div class="pt-4">
//                 <div class="flex justify-between ">
//                   <input
//                     id="weight"
//                     name="weight"
//                     type="number"
//                     class="text-xs font-medium peer focus:outline-none focus:border-rose-600 w-full"
//                     placeholder="Enter Weight"
//                     maxLength="6"
//                   />
//                   <p class="text-xs font-normal text-gray-secondary w-20 pl-12">
//                     breat
//                   </p>
//                 </div>
//                 <hr class="bg-gray-primary" />
//               </div>
//             </div>
//           </div>
//           <div class="flex items-end">
//             <button class="bg-primaryColor  text-white font-bold py-2 px-4 rounded mt-5">
//               <span className="text-White">Save Data</span>
//             </button>
//           </div>
//         </>
//       </Modal>
//       <Modal
//         open={isUpdatePopup}
//         footer={false}
//         onCancel={UpdateVitalsClose}
//         width={"350px"}
//       >
//         <div className="w-[250px]">
//           <div className="p-5 bg-white rounded-lg shadow-lg">
//             <div className="mb-4">
//               <p className="text-2xl font-medium">Enter Vitals</p>
//             </div>
//             <div className="mb-4">
//               <input
//                 autoComplete="off"
//                 id="systolic"
//                 name="systolic"
//                 type="number"
//                 value={addvitals?.systolic ?? ""}
//                 className="text-sm font-medium w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
//                 placeholder="SYS"
//               />
//             </div>
//             <div className="mb-4">
//               <input
//                 autoComplete="off"
//                 id="diastolic"
//                 name="diastolic"
//                 type="number"
//                 value={addvitals?.diastolic ?? ""}
//                 className="text-sm font-medium w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
//                 placeholder="DIA"
//               />
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </Layouts>
//   );
// }

// export default MyVitals;

// import { Modal, Select } from "antd";
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import BMI from "../../../../Assets/Images/BMI.png";
// import BMI1 from "../../../../Assets/Images/BMI1.png";
// import BarChart from "../../../../Assets/Images/BarChart.png";
// import BloodPressure from "../../../../Assets/Images/Blood Pressure.png";
// import HeartRate from "../../../../Assets/Images/Heart Rate.png";
// import PulseOx from "../../../../Assets/Images/PulseOx.png";
// import RespirationRate from "../../../../Assets/Images/Respiration Rate.png";
// import Temperature from "../../../../Assets/Images/Temperature.png";
// import Vector from "../../../../Assets/Images/Vector.png";
// import Weight from "../../../../Assets/Images/Weight.png";
// import { getpatientvitaldetails } from "../../../../Redux/Action/patientAction"
// import Layouts from "../../../Layouts";
// import AddNewVitals from "./AddNewVitals";
// import BloodPressureCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/BloodPressure";
// import TemperatureCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/Temperature";
// import BMICard from "../../../Pages/PostLogin/HealthRecords/VitalCards/BMI";
// import HeartRateCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/HeartRate";
// import PulseRateCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/PulseRate";
// import WeightCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/Weight";
// import HeightCard from "../../../Pages/PostLogin/HealthRecords/VitalCards/Height";
// import FamilyDropdown from "./FamilyDropDown";


// function MyVitals() {
//   const [isBPoUp, setBPoup] = useState(false);
//   const [showvitals, setvitals] = useState(false);
//   const [hederpop, sethederpop] = useState("");
//   const [find, setFind] = useState("");
//   const [isTextVisible, setIsTextVisible] = useState(false);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isAddVitals, setAddVitals] = useState(false);
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.authReducer.patientData);
//   const patientinfo = useSelector((state) => state.patientinfo);
//   const [isUpdatePopup, setUpdatePopup] = useState(false);
//   const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
//   const [EditVital, setEditvital] = useState();
//   const patientCode = useSelector(state => state.authReducer.patientCode);
//   const [activePatient, setActivePatient] = useState(patientCode);
//   const [currUser, setCurrUser] = useState();
//   // const patientvitalsdetaillist = useSelector((state) => state);
//   // const { vitalslistData, isLoading } = patientvitalsdetaillist;
//   const hasData = currUser && Object.keys(currUser).length > 0;
//   const patientvitalsdetaillist = useSelector((state) => state.patientvitalsdetail);
//   // const { Patientvitalsdetailsdata, isLoading } = patientvitalsdetaillist;
//   console.log('This is Console', patientvitalsdetaillist);
//   const patientDetails = useSelector((state) => state.particularpatientdetails);

//   console.log(patientCode)
//   console.log(patientvitalsdetaillist);

//   const editvitals = (e, user) => {
//     e.preventDefault();
//     setshowaddmemberpopup(true);
//     setEditvital(user);
//   };
//   const refreshVitals = () => {
//     dispatch(getpatientvitaldetails(userData.code));
//   };

//   async function fetchAllVitals(patientcode) {
//     const res = await dispatch(getpatientvitaldetails(patientcode));
//     console.log(res);
//     setCurrUser(res[0]);
//   }
//   useEffect(() => {
//     fetchAllVitals(userData.code);
//     setActivePatient(userData.code);
//   }, []);



//   const openPopup = (e) => {
//     debugger;
//     if (e === "BP") {
//       setFind(e);
//       sethederpop("Blood Pressure");
//     } else if (e === "TEMP") {
//       setFind(e);
//       sethederpop("Temparature");
//     } else if (e === "BMI") {
//       setFind(e);
//       sethederpop("BMI");
//     } else if (e === "HR") {
//       setFind(e);
//       sethederpop("HeartRate");
//     } else if (e === "BM") {
//       setFind(e);
//       sethederpop("BMI");
//     } else if (e === "PL") {
//       setFind(e);
//       sethederpop("PulseOx");
//     } else if (e === "RR") {
//       setFind(e);
//       sethederpop("RespirationRate");
//     } else if (e === "WE") {
//       setFind(e);
//       sethederpop("Weight");
//     }

//     setBPoup(true);
//   };

//   const getVitals = () => {
//     setvitals(false)
//     dispatch(getpatientvitaldetails(activePatient));
//   }

//   const loadVitalsFor = (patient) => {
//     console.log(patient);
//     setActivePatient(patient);
//     fetchAllVitals(patient)
//   }


//   const handleCancel = () => {
//     setBPoup(false);
//   };

//   const handleIconClick = () => {
//     setIsModalVisible(true);
//   };

//   const addvitals = () => {
//     setAddVitals(true);
//   };

//   const AddVitalClose = () => {
//     setAddVitals(false);
//   };

//   const UpdateVitals = () => {
//     setUpdatePopup(true);
//   };

//   const UpdateVitalsClose = () => {
//     setUpdatePopup(false);
//   };
//   return (
//     <Layouts>
//       <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
//         <div className="w-[80%] mx-auto ">
//           <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
//             <div className=" md:flex justify-between items-center ">
//               <div className="font-medium md:text-2xl text-White">
//                 My Vitals
//               </div>
//               <div className="flex gap-5">
//                 <button
//                   onClick={(e) => editvitals(e, "")}
//                   className="bg-White text-primaryColor text-xs md:text-sm font-semibold py-3 px-3 md:px-7 rounded"
//                 >
//                   Add New Vitals
//                 </button>
//                 <FamilyDropdown title={'Vitals'} onSelect={loadVitalsFor} />
//               </div>
//             </div>
//           </div>
//           {hasData ? (
//             <div className="grid grid-cols-3 gap-4 mt-5">
//               <BloodPressureCard saveCallback={getVitals} data={currUser ? currUser : ''} />
//               <TemperatureCard saveCallback={getVitals} data={currUser ? currUser : ''} />
//               <BMICard saveCallback={getVitals} data={currUser ? currUser : ''} />
//               <HeartRateCard saveCallback={getVitals} data={currUser ? currUser : ''} />
//               <PulseRateCard saveCallback={getVitals} data={currUser ? currUser : ''} />
//               {/* <PulseRateCard saveCallback={getVitals} data={currUser? currUser:''} /> */}
//               <WeightCard saveCallback={getVitals} data={currUser ? currUser : ''} />
//               <HeightCard saveCallback={getVitals} data={currUser ? currUser : ''} />
//               {/* <div className="w-full bg-White p-5 rounded-md">
//               <div className="flex gap-5">
//                 <div className="">
//                   <img src={RespirationRate} alt="images" />
//                 </div>
//                 <div className="w-full ">
//                   <div className="w-full flex justify-between items-center">
//                     <h5 className="text-primaryColor text-lg font-medium">
//                       Respiration Rate
//                     </h5>
//                     <div className="flex gap-3 items-center">
//                       <div
//                         onClick={() => openPopup("BP")}
//                         className="flex cursor-pointer"
//                       >
//                         <img src={Vector} alt="images" />
//                       </div>
//                       <div class="relative inline-block group">
//                         <div
//                           onClick={UpdateVitals}
//                           class="ml-[10%] text-xl w-6 h-6 cursor-pointer"
//                         >
//                           <FiMoreVertical />
//                         </div>
//                         <div class="hidden group-hover:block absolute bg-gray-200 text-gray-800 text-sm py-1 px-2 rounded-md mt-1">
//                           Update
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 mt-4">
//                     <p className="text-textGray font-light text-xs ">
//                       Latest{" "}
//                       <span className="text-lg text-secondaryColor font-medium">
//                         17
//                       </span>{" "}
//                       breath/min
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div> */}
//             </div>
//           ) : (
//             // Display message when no data is available
//             <div className="text-center mt-5 text-gray-500">
//               No data available.
//             </div>
//           )}
//         </div>
//       </section>

//       <Modal
//         open={isBPoUp}
//         onCancel={handleCancel}
//         footer={[]}
//         title={
//           <span style={{ color: "primaryColor", fontSize: "20px" }}>
//             {hederpop}
//           </span>
//         }
//       >
//         <div className="flex justify-start items-center">
//           {find == "BP" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "TEMP" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "BMI" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "HR" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "BM" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "PL" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "RR" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//           {find == "WE" && (
//             <img
//               src={BarChart}
//               alt="Bar Chart"
//               style={{ width: "452px", height: "370px" }} // Adjust the width and height as needed
//             />
//           )}
//         </div>
//       </Modal>
//       <Modal
//         title="Add New Vitals"
//         className="commonModal"
//         open={showaddmemberpopup}
//         onOk={() => setshowaddmemberpopup(!showaddmemberpopup)}
//         width={1200}
//         onCancel={() => {
//           setshowaddmemberpopup(!showaddmemberpopup);
//           refreshVitals();
//         }}
//         style={{ top: 20 }}
//         footer={[]}
//       >
//         <AddNewVitals
//           editvitals={EditVital}
//           closePopup={() => {
//             setshowaddmemberpopup(!showaddmemberpopup);
//             refreshVitals();
//           }}
//           saveMessage={(message) => {
//             setshowaddmemberpopup(!showaddmemberpopup);
//             // toast(message);
//             refreshVitals();
//           }}
//           fetchAllVitals={fetchAllVitals}
//           patientCode={activePatient}
//         />
//       </Modal>
//       <Modal
//         open={isUpdatePopup}
//         footer={false}
//         onCancel={UpdateVitalsClose}
//         width={"350px"}
//       >
//         <div className="w-[250px]">
//           <div className="p-5 bg-white rounded-lg shadow-lg">
//             <div className="mb-4">
//               <p className="text-2xl font-medium">Enter Vitals</p>
//             </div>
//             <div className="mb-4">
//               <input
//                 autoComplete="off"
//                 id="systolic"
//                 name="systolic"
//                 type="number"
//                 value={addvitals?.systolic ?? ""}
//                 className="text-sm font-medium w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
//                 placeholder="SYS"
//               />
//             </div>
//             <div className="mb-4">
//               <input
//                 autoComplete="off"
//                 id="diastolic"
//                 name="diastolic"
//                 type="number"
//                 value={addvitals?.diastolic ?? ""}
//                 className="text-sm font-medium w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
//                 placeholder="DIA"
//               />
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </Layouts>
//   );
// }

// export default MyVitals;


