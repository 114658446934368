import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layouts from "../../Layouts";
import { Col, Row, Select } from "antd";
import moment from "moment";
import { getPaymentListDetails } from "../../../Redux/Action/payment";
import NodataImage from "../../../Assets/Images/Icons/nodata.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import FamilyDropdown from "./HealthRecords/FamilyDropDown";

const Mypayments = () => {
  const dispatch = useDispatch();
  const [paymentList, setPaymentList] = useState([]);
  const userData = useSelector((state) => state.authReducer.patientData);

  const history = useHistory();

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/', {isLoginModalOpen : true});
    }
  }, [])

  const patientpaymenthistorylist = useSelector(
    (state) => state.paymentListReducer
  );
  let { isLoading, paymentData, isError } = patientpaymenthistorylist;
  useEffect(() => {
    if (Array.isArray(paymentData)) {
      let data = paymentData.map((item) => {
        if (item.totalAmount) {
          item.paymentDateTime = moment(item.paymentDateTime).format(
            "DD-MM-YYYY hh:mm A"
          );
          return { ...item, totalAmount: item.totalAmount.toFixed(1) };
        } else {
          item.paymentDateTime = moment(item.paymentDateTime).format(
            "DD-MM-YYYY hh:mm A"
          );
          return item;
        }
      });
      setPaymentList(data);
    }
  }, [paymentData]);

  useEffect(() => {
    let params = {
      status: 1,
      patientId: userData.code,
    };
    dispatch(getPaymentListDetails(params));
  }, []);
 const handleChange=(code)=>{
    let params = {
      status: 1,
      patientId: code,
    };
    dispatch(getPaymentListDetails(params));
  }
  return (
    <Layouts>
      <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto">
          <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
            <div className=" flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-White">
                My Payments
              </div>
              <div className="hidden md:block">
                {/* <Select
                  placeholder="Get payment for"
                  className="md:h-12  md:w-96"
                ></Select> */}
                <FamilyDropdown
              title={"Get payments for"}
              onSelect={handleChange}
            />
              </div>
            </div>
          </div>
          <div className="md:hidden block mt-5">
            <FamilyDropdown
              title={"Get payments for"}
              onSelect={handleChange} 
            />
          </div>
          <div className="mt-3">
            <Row gutter={[16, 16]}>
              {paymentList.map((lab, i) => (
                <Col xs={24} sm={24} md={8}>
                  <div className="w-full rounded-md p-5 bg-White">
                    <div className="flex justify-between items-center text-primaryColor mb-3">
                      <div className=" text-base ">Amount paid</div>
                      <div className="font-medium text-lg">
                        ₹ {lab.totalAmount}
                      </div>
                    </div>
                    <div className="flex justify-between items-center gap-5">
                      <div className="text-textGray text-xs">
                        <label className="font-light mb-1 block">
                          Patient Name
                        </label>
                        <p className="text-xs font-medium">{lab.patientName}</p>
                      </div>
                      <div className="text-textGray text-xs">
                        <label className="font-light mb-1 block">
                          Transaction Type
                        </label>
                        <p className="text-xs font-medium">{lab.serviceName}</p>
                      </div>
                      <div className="text-textGray text-xs">
                        <label className="font-light mb-1 block">
                          Date of payment
                        </label>
                        <p className="text-xs font-medium">
                          {moment(lab.createdDate).format("MM/DD/YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between items-end gap-5 mt-3">
                      <div className="text-textGray text-xs">
                        <label className="font-light mb-1 block">
                          Transaction ID
                        </label>
                        <p className=" text-xs  font-medium">{lab.txnId}</p>
                      </div>
                      {/* <div className="underline text-secondaryColor text-base">
                        Download Reciept
                      </div> */}
                    </div>
                  </div>
                </Col>
              ))}
              {paymentList && paymentList.length === 0 && (
                <div class=" w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
                  <div className="">
                    <img src={NodataImage} alt="image" className="w-[60px]" />
                  </div>
                  <p className="text-center font-light item-center text-base text-textGray">
                    No payment available!
                  </p>
                </div>
              )}
            </Row>
          </div>
        </div>
      </section>
    </Layouts>
  );
};

export default Mypayments;
