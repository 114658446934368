import React from "react";
import Layouts from "../Layouts";

function PrivacyPolicy() {
  return (
    <Layouts>
      <section className="home-banner md:min-h-[60vh] py-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                Privacy Policy
              </h1>
              <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
                CureBay is committed to protecting customer privacy with strict
                measures to ensure data confidentiality and security. Our
                privacy policy highlights transparency in data collection,
                usage, and protection, offering customers peace of mind and
                trust in our services.
              </p>
            </div>
            <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%] md:h-[400px] rounded ">
                <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Privacy%20Policy.webp" alt="images" className="w-full h-full object-cover image-boxshadow rounded-xl" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto py-[50px]">
          {/* <p className="font-medium mb-3">Effect From: 13th October 2023</p> */}
          <p className="text-textGray pb-3">
          The terms of use read with this Privacy Policy (the “Privacy Policy”) are an electronic record
under the Information Technology Act, 2000 and the rules made thereunder as amended and
is enforceable against You (as hereinafter defined) under law by way of your acceptance
hereof. The domain name www.curebay.com / www.curebay.in and applications for mobile,
tablet and other smart devices and application program interfaces, if any (collectively referred
to as the “Website”), operated by Curebay Pharmacy Private Limited, a private limited company
duly incorporated under the provisions of the Companies Act, 2013 (hereinafter referred to as
“CureBay” or ‘Curebay” or “We” or “Our” or “Us” or “Company”).

          </p>
          <p className="text-textGray pb-3">
          Your access or use of the Website, transaction on the Website, are governed by the following
terms and conditions along with any amendments / modifications made by CureBay at its sole
discretion and posted on the Website (hereinafter referred to as the “Terms of Use”), including
by way of imposing an additional charge for access to, or use of, the Service(s) (as hereinafter
defined). These Terms of Use and Privacy Policy constitutes a legal and binding contract
between You on one part and CureBay on the other part. This Website is an aggregation
platform that (i) connects Users to the healthcare providers and other key healthcare
ecosystem players, (ii) facilitates diagnostic services being offered by various third party
diagnostic centers, and (iii) facilitates online medical consultancy services/ second opinion
being offered by third party independent doctors (collectively the “Healthcare Providers”) to
enable You to avail their services provided by the Healthcare Providers under the terms
agreed between You and the Healthcare Providers (the “Services”). Further, the Website also
serves as an information platform providing health and wellness related information
(“Information Services”) to the Users accessing the Website. It is clarified that CureBay and
its affiliates do not provide any Services which is the domain of the Healthcare Provider.
Notwithstanding anything to the contrary contained herein, the Healthcare Providers alone
shall be liable for their dealings and interaction with the Users who avail the services of the
Healthcare Providers contacted or managed through the Website and CureBay is acting only
as an aggregator and shall have no liability or responsibility in this regard.
          </p>
          <p className="text-textGray pb-3">
          The arrangement between the User and CureBay shall be governed in accordance with the
Terms of Use and Privacy Policy. For the purpose of this Privacy Policy, wherever the context
so requires “You” or “Your” or “Users” shall mean any natural person (which expression shall
include legal heirs, representative, executors, administrators, successors and permitted
assigns) who has agreed to become a user on the Website by registering on the Website as a
registered User using the computer systems, or just visiting and browsing the Website as a
non-registered User, or a patient, his or her representative searching for Services through the
Website/platform.
          </p>
          <p className="text-textGray pb-3">
          The User and CureBay shall hereinafter be referred to individually, as “Party” and collectively,
as “Parties”. By accessing, browsing or in any way transacting on the Website, or availing any
of the Services, You signify Your agreement to be bound by this Privacy Policy. For transaction
via our Website, by clicking on the “Accept” or “Agree” button you agree to the Privacy Policy
that will bind you. If you do not agree to these terms and conditions you must cease to transact
on the Website. Further, by impliedly or expressly accepting this Privacy Policy, You also
accept and agree to be bound by Our policies and such other rules, guidelines, policies, terms
and conditions as are relevant under the applicable law(s) in India and other jurisdictions for the purposes of accessing,
browsing or transacting on the Website, and such rules, guidelines, policies, terms and
conditions shall be deemed to be incorporated into and considered as part and parcel of these
Terms of Use. Furthermore, we reserve the right to change, modify, add or delete portions of the terms
of this privacy policy, at our sole discretion, at any time. This Privacy Policy is an electronic record in the
form of an electronic contract formed under the Information Technology Act, 2000 and the relevant rules
made thereunder, and any other applicable statutes, as amended from time to time. This Privacy
Policy does not require any physical, electronic or digital signature(s).
          </p>
          <h6 className="uppercase text-primaryColor text-xl font-semibold my-5">
            WHY THIS PRIVACY POLICY?
          </h6>
          <p className="text-textGray pb-1">
            This Privacy Policy is published in compliance with, inter alia:
          </p>
          <p className="text-textGray pb-1">
            1. Information Technology Act, 2000;
          </p>
          <p className="text-textGray pb-1">
            2. Information Technology (Reasonable Security Practices and
            Procedures and Sensitive Personal Information) Rules, 2011 (the “SPI
            Rules”);
          </p>
          <p className="text-textGray pb-1">
            3. Information Technology (Intermediaries Guidelines) Rules, 2011.
          </p>
          <p className="text-textGray pb-1 mt-5">
            This Privacy Policy states the following:
          </p>
          <p className="text-textGray pb-1">
            1. The type of information collected from the Users, including
            Personal Information (as defined in paragraph 2 below) and Sensitive
            Personal Data or Information (as defined in paragraph 2below)
            relating to an individual;
          </p>
          <p className="text-textGray pb-1">
            2. The purpose, means and modes of collection, usage, processing,
            retention and destruction of such information; and
          </p>
          <p className="text-textGray pb-1">
            3. How and to whom CureBay will disclose such information.
          </p>
          <h6 className="uppercase text-primaryColor text-xl font-semibold my-5">
            COLLECTION OF PERSONAL INFORMATION
          </h6>
          <h5 className=" text-primaryColor text-lg font-semibold mb-3">
            2.1 Personal Data
          </h5>
          <p className="text-textGray pb-3">
          While using our service, we may ask you to provide certain personally identifiable information
thatcan be used to contact or identify you (“Personal Data”). This may include, but is not limited
to:Email address, First name and last name, Phone number, Address, State, Province,
PIN/Zipcode, City, Gender and other demographics, Date of birth and age, Location data and
Cookies and usage dataSuch Personal Data may be collected in various ways including during
the course of your: (i) registration as a user on the Website; (ii) availing services offered on the
Website; (iii) granting permission to share credentials of your online accounts maintained with
third parties.
          </p>
          <h5 className=" text-primaryColor text-lg font-semibold mb-3">
            2.2 Cookies and Usage Data
          </h5>

          <p className="text-textGray pb-3">
          We may use your Personal Data to contact you with newsletters, marketing or promotional
materials, and other information, only if you have explicitly consented to receive such
communications. You can opt-out of receiving these communications by following the
unsubscribe link or instructions provided in any email we send.{" "}
            <br />
            To access certain areas of the Website, you may need to complete a registration form. During
registration, you will be required to provide information such as your name, age, email address,
preferred username, password, and preferred subscriptions. Please note that the username
you choose will be publicly visible on the Website.
            <br />
            To store a medication list, you need to create a user account. You have control over who can
access or edit your medication list. Your account will only be accessible to authorized support
staff or service providers who require access to perform tasks on our behalf. These service
providers are obligated not to disclose or use your Personal Data for any other purpose.
            <br />
            If you choose to create a public profile and add Personal Data about yourself or others, this
information will be publicly accessible. If you wish to request the removal of your information
from our directory, please contact us using the contact details provided at the bottom of this
Policy.
            <br /> If you choose to participate in any community-oriented parts of our Website, such as forums,
blogs, chat rooms, Q&A, reviews, or comments, please be aware that any Personal Data you
submit is public and can be read, collected, and used by others. It may also appear in search
engines. To request the removal of your Personal Data from our blog or community forum,
please contact us using the contact details provided at the bottom of this Policy. In some cases,
we may not be able to remove your Personal Data, and we will inform you if we are unable to
do so and provide the reason.
          </p>
          <h5 className=" text-primaryColor text-lg font-semibold mb-3">
            2.3 Sensitive Personal Data
          </h5>
          <p className="text-textGray pb-3">
          We may collect health data or other sensitive personal data, including your biometric
information for the purpose of providing You our services. You can use our contact form to
withdraw your consent at any time. In such an event, the Company reserves the right to not
allow you further usage of the Website, without any obligations or liability, whatsoever, whether
in contract, tort, law, equity or otherwise, in this regard.
          </p>
          <h5 className=" text-primaryColor text-lg font-semibold mb-3">
            2.4 Usage Data
          </h5>
          <p className="text-textGray pb-3">
          We may collect information that your browser or mobile device sends whenever you use our
          Website (“Usage Data”). This may include but not limited to:
            <br />
            Internet Protocol (IP) address <br />
            Browser type and version <br />
            Pages visited within the Website
            <br />
            Time and date of visits <br />
            Time spent on those pages <br />
            Search terms <br />
            Page content
            <br />
            Unique device identifiers <br />
            Other diagnostic data <br />
            When you access our Website through a mobile device, the Usage Data may include
information such as the type of mobile device you use, your mobile device's unique ID, the IP
address of your mobile device, your mobile operating system, the type of mobile Internet
browser you use, and other diagnostic data.
          </p>
          <h5 className=" text-primaryColor text-lg font-semibold mb-3">
            2.5 Tracking and Cookies Data
          </h5>
          <p className="text-textGray pb-3">
          We and our partners use cookies or similar technologies to analyze trends, administer the
Website, track users' movements around the Website, and gather demographic information
about our user base as a whole. You can control the use of cookies through your browser
settings and other tools. However, please note that disabling cookies may affect your ability to
access and use certain features of the Website. Cookies are small files 
stored on your device (computer or mobile device). They may include an anonymous unique
identifier. Tracking technologies also used are beacons, tags, and scripts to collect and track
information and to improve and analyze our service
          </p>
          <h6 className="uppercase text-primaryColor text-xl font-semibold my-5">
            DATA SECURITY
          </h6>
          <p className="text-textGray pb-3">
            a. We implement robust security measures to protect user medical data against
            unauthorized access, disclosure, alteration, or destruction. <br />
            b. These measures include encryption, access controls, regular security assessments, staff
            training, and compliance with industry best practices. <br />
            c. .We regularly review and update our security practices to address emerging threats and
            ensure the ongoing protection of user data.{" "}
            <br />
            4.1 Indian Data Protection Laws: <br />
            a. We comply with the applicable data protection laws and regulations in India to safeguard
            your personal information. <br />
            b. We take reasonable steps to ensure that your data is treated securely and in accordance
            with this Privacy Policy. <br />
            c. We ensure that any transfers of data outside India comply with the requirements under
            Indian law, including obtaining your explicit consent if necessary.
          </p>
          <h6 className="uppercase text-primaryColor text-xl font-semibold my-5">
            USER RIGHTS
          </h6>
          <p className="text-textGray pb-3">
            a. Users have the right to access, review, and correct their medical information, as permitted
            by Indian law.
            <br />
            b. Users can request the deletion or restriction of their medical data, subject to legal
            and regulatory requirements.
            <br />
            c. We provide mechanisms for users to exercise their rights and handle such requests
            promptly and transparently. <br />
            <h6 className="uppercase text-primaryColor text-xl font-semibold my-5">
            Third-Party Providers:
          </h6>
           
            <br />
            a. In some cases, we may engage third-party service providers or business associates to
            assist in processing or handling user medical data.
            <br />
            b. These entities are contractually bound to maintain the privacy and security of the data and
            comply with applicable laws and regulations.
          </p>
          <h6 className="uppercase text-primaryColor text-xl font-semibold my-5">
            DATA RETENTION
          </h6>
          <p className="text-textGray pb-3">
            a. We retain user medical data for the duration required by law, regulations, or as necessary
            for the provision of healthcare services.
            <br />
            b. When data is no longer needed, we securely dispose of or de-identify it to ensure
            continued privacy and protection.
          </p>
          <h6 className="uppercase text-primaryColor text-xl font-semibold my-5">
            USE OF DATA
          </h6>
          <p className="text-textGray pb-3">
          CureBay uses the collected data for various purposes, including:
            <br />
            a. To provide and maintain the Website
            <br />
            b. To notify you about changes to our Website
            <br />
            c. To allow you to participate in interactive features of our Website when you choose to do so
            <br />
            d. To provide customer support
            <br />
            e. To gather analysis or valuable information to improve our Website
            <br />
            f. To monitor the usage of our Website
            <br />
            g. To detect, prevent, and address technical issues
            <br />
            h. To provide you with news, special offers, and general information about other goods,
services, and events we offer that are similar to those you have already purchased or inquired
about, unless you have opted not to receive such information
            <br />
            i. To either by itself or in collaboration with third parties, carry out research, analysis of the
collected data, either independently or in combination with other information collected from the
other customers of CureBay or otherwise, and derive insights on patients’ health from such
analysis and design/incorporate process to improve healthcare related products and services;
            <br />
            Transfer of Data:
            <br />
            Your information, including Personal Data, may be transferred to and maintained on
computers located outside of India, where data protection laws may differ from those of your
jurisdiction.
            <br />
            If you are located outside India and choose to provide information to us, please note that we
transfer the data, including Personal Data, to India and process it there. Your consent to this
Privacy Policy, followed by your submission of such information, represents your agreement
to that transfer. CureBay will take all the steps reasonably necessary to ensure that your data
is treated securely and in accordance with this Privacy Policy, and no transfer of your Personal
Data will take lace to an organization or a country unless there are adequate controls in place,
including the security of your data and other personal information.
          </p>
          <h6 className="uppercase text-primaryColor text-xl font-semibold my-5">
            DISCLOSURE OF DATA
          </h6>
          <p className="text-textGray pb-3">
          Legal Requirements
            <br /> CureBay may disclose your Personal Data in good faith belief that such action is necessary to:
            <br />
            a. Comply with a legal obligation or an order of any governmental authority
            <br />
            b. Protect and defend the rights or property of CureBay
            <br />
            c. Prevent or investigate possible wrongdoing in connection with the
            website
            <br />
            d. Protect the personal safety of users of the Website or the public
            <br />
            e. Protect against legal liability in the event of CureBay being party to a merger, acquisition, or
asset transfer, your personal data may be subject to transfer. Prior notice will be provided
before the transfer of personal data, at which point it will become subject to an alternative
Privacy Policy.
            <br />
            Under specific circumstances, CureBay may be compelled to disclose an individual’s personal
data as mandated by law or in response to valid requests from public authorities, such as a
court or government agency.
            <br />
            In certain instances, to facilitate the provision of services, CureBay may receive information
pertaining to individuals from third-party entities, such as NSDL and payment gateway
providers, concerning the validation
of documents and repayment status. The acquisition and sharing of this information shall only
occur when strictly necessary for service provision. CureBay does not retain data obtained
from these third parties. In fulfilment of our outsourcing obligations to our partners, this
information is collected and directly transferred to them upon acquisition.
            <br />
            Security of Data:
            <br />
            The security of your data is important to us, but remember that no method of transmission
over the Internet or method of electronic storage is 100% secure. While we strive to use
commercially acceptable means to protect your Personal Data, we cannot guarantee its
absolute security.

          </p>
          <h6 className="uppercase text-primaryColor text-xl font-semibold my-5">
            SERVICE PROVIDERS
          </h6>
          <p className="text-textGray pb-3">
          We may employ third-party companies and individuals to facilitate our Website (“Service
Providers”), provide the Website on our behalf, perform website-related services, or assist us
in analysing how our Website is used.

            <br />
            These third parties have access to your Personal Data only to perform these tasks on our
            behalf and are obligated not to disclose or use it for any other purpose.

          </p>
          <h6 className="uppercase text-primaryColor text-xl font-semibold my-5">
            LINKS TO OTHER SITES
          </h6>
          <p className="text-textGray pb-3">
          Our Website may contain links to other sites that are not operated by us. If you click on a thirdparty link, you will be directed to that third party's site. We strongly advise you to review the
          Privacy Policy of every site you visit.
            <br />
            We have no control over and assume no responsibility for the content, privacy policies, or
practices of any third-party sites or services.

            <br />
            Children’s Privacy:
            <br />
            Our Website does not address anyone under the age of 18 (“Children”). We do not knowingly
collect personally identifiable information from anyone under the age of 18. If you are a parent
or guardian and you are aware that your Children have provided us with Personal

            <br />
            Data, please contact us. If we become aware that we have collected Personal Data from
children without verification of parental consent, we take steps to remove that information from
our servers
          </p>
          <h6 className="uppercase text-primaryColor text-xl font-semibold my-5">
            CHANGES TO THIS PRIVACY POLICY
          </h6>
          <p className="text-textGray pb-3">
          We may update our Privacy Policy from time to time. We will notify you of any changes by
          posting the new Privacy Policy on this page.
            <br />
            We will let you know via email and/or a prominent notice on our Website, prior to the
change becoming effective and update the “effective date” at the top of this Privacy 
Policy

            <br />
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
            <br />
            Policy are effective when they are posted on this page.
            <br />
            12. GRIEVANCE REDRESSAL
            <br />
            In accordance with the Information Technology Act, 2000 and rules made thereunder,
CureBay has designated a grievance officer (“Grievance Officer”). Any requests, questions,
clarifications or grievances with respect to this Privacy Policy or about your Personal Data can
be sent to our Grievance Officer at:

          </p>
          <p className="text-textGray pb-3">
            Name: SHOBHAN MAHAPATRA, Grievance Officer
            <br />
            Postal address: Curebay Pharmacy Pvt. Ltd. , Fourth Floor, OCAC Tower, Acharya Vihar, Bhubaneswar,
Odisha - 751013
            <br />
            Email address: governance@curebay.com
            <br />
           
            If you wish to receive a response by email or mail, please be sure to include your name and postal address
or email address, as the case may be.
            <br />
            All grievances shall be redressed in an expeditious manner. Subject to complete details in relation to the
grievance being provided, the Grievance Officer shall redress all grievances within a maximum period of 30
days from the date of receipt of the grievance.
            <br />
            Contact Us:
            <br />
            If you have any questions about this Privacy Policy, please contact us:

            <br />
            By email: support@curebay.com
            <br />
           
            By registered post: Curebay Pharmacy Pvt. Ltd., Fourth Floor, OCAC Tower, Acharya Vihar, Bhubaneswar,
Odisha - 751013
          </p>
        </div>
      </section>
    </Layouts>
  );
}

export default PrivacyPolicy;
