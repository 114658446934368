import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import hospitalservice from "../../../Redux/Services/hospitalservice";
import { useSelector } from "react-redux";
import bed from "../../../Assets/Images/iv_no_of_beds.svg";
import placeholder_m from "../../../Assets/Images/placeholder_m.svg";
import SurgeryEnquiry from "./SurgeryEnquiry";
import { Form, Modal } from "antd";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { RightOutlined } from "@ant-design/icons";

function HospitalEnquire() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const hospital_code = params.enquire;
  const parts = hospital_code.split("--");
  const extractedCode = parts[0];
  const userData = useSelector((state) => state.authReducer.patientData);
  const patientData = useSelector((state) => state.authReducer.patientData);
  const [particularhospitalData, setHospitalsList] = useState([]);
  const [dobValue, setDOBValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [form] = Form.useForm();
  const [hospitaldata, setHospitaldata] = useState();

  useEffect(() => {
    if (hospital_code) {
      hospitalservice.getparticularhospital(hospital_code).then(
        (res) => {
          if (res.data) {
            for (let s = 0; s < res.data.length; s++) {
              res.data[s]["hospitalLocationNames"] =
                res.data[s].hospitalName + " (" + res.data[s].name + ")";
            }
            setHospitalsList(res.data);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
    setDOBValue(patientData.dob != null ? patientData?.dob : "");
  }, [hospital_code]);

  const handleImgError = (e) => {
    e.target.src = placeholder_m;
  };

  const handleEnquireClick = (hosp) => {
    if (userData?.sessionid) {
      setOpenModal(true);
      setHospitaldata(hosp);
    } else {
      history.push({
        pathname: APP_ROUTES.HOME,
        state: { background: location, login: true },
      });
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setOpenModal(false);
  };
  console.log(particularhospitalData, "particularhospitalData");
  return (
    <Layouts>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[20px] md:py-[30px]">
          <div className="w-full bg-primaryColor rounded-md px-8 py-4 mb-5">
            <div className=" md:text-xl text-White">
              <Link to="/"> Hospital </Link>
              <RightOutlined className="text-base" />
              <span className="cursor-pointer text-secondaryColor pl-1">
                {extractedCode}
              </span>
            </div>
          </div>
          <div>
            {particularhospitalData.map((hosp, i) => (
              <div className="mt-5 ">
                <div key={i} className="md:flex gap-5">
                  <div className="md:w-[40%] h-full bg-White rounded-lg">
                    <div className="h-[250px]">
                      <img
                        className="w-full h-full p-5"
                        src={process.env.REACT_APP_IMG_BASEURL + hosp.photoName}
                        alt="ProductImage"
                        referrerPolicy="no-referrer"
                        onError={handleImgError}
                      />
                    </div>
                  </div>
                  <div className="md:w-[60%] p-8 bg-White rounded-lg ">
                    <div>
                      <p className="text-2xl font-semibold  leading-5  not-italic text-secondaryColor">
                        {hosp.hospitalName}
                      </p>
                      <hr className="mt-5 border-gray mb-5" />
                      <div className="text-xs">
                        <div className="flex items-center mt-3">
                          <h2 className="text-textGray text-sm w-36 md:w-48 lg:w-36 font-semibold h-5  leading-5">
                            Location
                          </h2>
                          <div className="flex">
                            <p className="mr-5 font-bold h-5  leading-5  text-black not-italic w-1">
                              :
                            </p>
                            <h3 className="text-sm text-textGray font-normal ">
                              {hosp.address1},{hosp.city}{" "}
                            </h3>
                          </div>
                        </div>

                        <div className="flex mt-5">
                          <h2 className="text-textGray text-sm w-36 md:w-48 lg:w-36 font-semibold h-5  leading-5">
                            Hours
                          </h2>
                          <div className="flex">
                            <p className="mr-5 font-bold h-5  leading-5  text-black not-italic w-1">
                              :
                            </p>
                            <h3 className="text-sm text-textGray font-normal ">
                              Open 24 hours{" "}
                            </h3>
                          </div>
                        </div>
                        {/* <div className="flex  mt-5">
                          <div>
                            <h2 className="text-textGray text-sm w-36 md:w-48 lg:w-36 font-semibold h-5  leading-5">
                              Phone
                            </h2>
                          </div>
                          <div className="flex">
                            <p className="mr-5 font-bold h-5  leading-5  text-black not-italic w-1">
                              :
                            </p>
                            <h3 className="text-sm text-textGray font-normal">
                              {hosp.contactNumber}
                            </h3>
                          </div>
                        </div> */}

                        <div className="flex lg:pb-0 pb-1 mt-5">
                          <div className="flex  ">
                            <h2 className="text-textGray text-sm w-36 md:w-48 lg:w-36 font-semibold h-5  leading-5">
                              Beds Available
                            </h2>
                            <div className="flex">
                              <p className="mr-5 font-bold h-5  leading-5  text-black not-italic w-1">
                                :
                              </p>

                              <p className="text-sm text-textGray font-normal">
                                {hosp.noOfBed}
                              </p>
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={() => handleEnquireClick(hosp)}
                          className="mt-6 p-2 bg-primaryColor w-1/2 mt-3 text-White  rounded-lg text-center text-base font-normal"
                        >
                          Enquire
                        </button>
                        <hr className="border-gray my-8" />
                        <div key={i} className="">
                          <p className="text-lg text-primaryColor font-semibold h-5 mb-5">
                            About
                          </p>
                          <p className="text-textGray text-sm">
                            {hosp.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Modal
        title="Surgery Enquiry Form"
        className="commonModal"
        visible={openModal}
        onOk={() => {
          setOpenModal(false);
        }}
        centered={true}
        footer={null}
        onCancel={() => {
          handleCancel();
        }}
        okText="Submit"
        cancelText="Cancel"
        width="60%"
        maskClosable={false}
      >
        <SurgeryEnquiry
          onClose={handleCancel}
          form={form}
          hospitaldata={hospitaldata}
        />
      </Modal>
    </Layouts>
  );
}

export default HospitalEnquire;
