// import React, { useEffect, useState } from "react";
// import { PieChart, Pie, Cell } from "recharts";
// import Header from "../../Shared/Header";
// // Constants for shared colors
// const COLORS = [
//   "#FFB300", // Yellow for caution
//   "#FF9800", // Orange for average
//   "#8BC34A", // Light Green for good health
//   "#4CAF50", // Green for optimal
//   "#FFC107", // Yellow for elevated values
//   "#F44336", // Red for high-risk
//   "#D32F2F", // Dark Red for critical
// ];

// function getColorForHeartRate(bpm) {
//   if (bpm < 50 || bpm > 120) {
//     return COLORS[0]; // Red - Risky
//   } else if ((bpm >= 50 && bpm <= 59) || (bpm >= 101 && bpm <= 120)) {
//     return COLORS[1]; // Coral - Concerning
//   } else if (bpm >= 60 && bpm <= 100) {
//     return COLORS[5]; // Green - Healthy
//   }
// }

// function getAngleForHeartRate(bpm) {
//   if (bpm < 50) {
//     return -90;
//   } else if (bpm > 120) {
//     return 90;
//   } else {
//     return ((bpm - 50) / 70) * 180 - 90;
//   }
// }

// // Reusable Semi-Circular Vitals Card
// const VitalsCard = ({
//   title,
//   value,
//   range,
//   needleValue,
//   minValue,
//   maxValue,
//   color,
//   angle,
// }) => {
//   const [currentAngle, setCurrentAngle] = useState(-90);

//   useEffect(() => {
//     setCurrentAngle(angle);
//   }, [angle]);
//   const renderNeedle = (cx, cy, radius, angle) => {
//     // Reduce the needle length slightly to stay within the gauge
//     const needleLength = radius * 0.75;
//     const x = cx + needleLength * Math.cos((Math.PI * angle) / 180);
//     const y = cy + needleLength * Math.sin((Math.PI * angle) / 180);

//     // Calculate arrowhead coordinates
//     const arrowLength = 8;
//     const arrowAngle1 = angle + 150;
//     const arrowAngle2 = angle - 150;
//     const arrowX1 = x + arrowLength * Math.cos((Math.PI * arrowAngle1) / 180);
//     const arrowY1 = y + arrowLength * Math.sin((Math.PI * arrowAngle1) / 180);
//     const arrowX2 = x + arrowLength * Math.cos((Math.PI * arrowAngle2) / 180);
//     const arrowY2 = y + arrowLength * Math.sin((Math.PI * arrowAngle2) / 180);

//     return (
//       <g
//         transform={`rotate(${angle} ${cx} ${cy})`}
//         style={{ transition: "transform 1s ease-out" }}
//       >
//         {/* Needle */}
//         <line
//           x1={cx}
//           y1={cy}
//           x2={x}
//           y2={y}
//           stroke="black"
//           strokeWidth={3}
//           strokeLinecap="round"
//         />
//         {/* Arrowhead */}
//         <line
//           x1={x}
//           y1={y}
//           x2={arrowX1}
//           y2={arrowY1}
//           stroke="black"
//           strokeWidth={2}
//           strokeLinecap="round"
//         />
//         <line
//           x1={x}
//           y1={y}
//           x2={arrowX2}
//           y2={arrowY2}
//           stroke="black"
//           strokeWidth={2}
//           strokeLinecap="round"
//         />
//         {/* Needle base circle */}
//         <circle cx={cx} cy={cy} r={5} fill={"black"} />
//       </g>
//     );
//   };

//   const getSegmentedPieData = () => {
//     return [
//       { value: 20, color: COLORS[2] }, // Light Green for good health
//       { value: 20, color: COLORS[3] }, // Green for optimal
//       { value: 15, color: COLORS[0] }, // Yellow for caution
//       { value: 15, color: COLORS[4] }, // Yellow for elevated values
//       { value: 10, color: COLORS[5] }, // Red for high-risk
//       { value: 10, color: COLORS[6] }, // Dark Red for critical
//     ];
//   };

//   return (
//     <>
//       <Header />
//       <div className="bg-white shadow-lg rounded-lg p-3 text-center border-none">
//         <h3 className="text-lg font-semibold mb-2 text-white text-primaryColor border-b border-gray-300 pb-2 rounded-t-md">
//           {title}
//         </h3>
//         <div style={{ width: 300, height: 180, margin: "0 auto" }}>
//           <PieChart width={260} height={160}>
//             {renderNeedle(130, 130, 90, currentAngle)}
//             <Pie
//               data={getSegmentedPieData()}
//               dataKey="value"
//               cx={130}
//               cy={130}
//               startAngle={180}
//               endAngle={0}
//               innerRadius={60}
//               outerRadius={90}
//               stroke="none"
//               fill="#fff"
//             >
//               {getSegmentedPieData().map((entry, index) => (
//                 <Cell key={`cell-${index}`} fill={entry.color} />
//               ))}
//             </Pie>
//             {
//               <g
//                 transform="rotate(${angle} 130 130)"
//                 style={{
//                   transformOrigin: "130px 130px",
//                   transition: "transform 1s ease-out",
//                 }}
//               >
//                 {renderNeedle(130, 130, 90, angle)}
//               </g>
//             }
//           </PieChart>
//         </div>
//         <p className="text-3xl font-bold mt-2 text-primaryColor">{value}</p>
//         <p className="text-sm text-primaryColor">{range}</p>
//         <button className="mt-3 text-lightblue-700 px-5 py-3 rounded-full hover:bg-lightblue-100 underline shadow-lg">
//           View Details <span class="ml-1">&rarr;</span>
//         </button>
//       </div>
//     </>
//   );
// };

// const SmartVitalsReport = () => {
//   const [vitalsData, setVitalsData] = useState(null);

//   const fetchVitalsData = async () => {
//     const mockData = {
//       status: 200,
//       message: "Smart Vitals predicted successfully",
//       data: {
//         predicted_heart_rate: 60,
//         breathing_rate: 0,
//         oxygen_level: 70,
//         systolic_bp: 60,
//         diastolic_bp: 50,
//         curebay_stress_index: 2,
//         bmi_score: 19,
//       },
//     };
//     setVitalsData(mockData.data);
//   };

//   useEffect(() => {
//     fetchVitalsData();
//   }, []);

//   if (!vitalsData) {
//     return <div className="text-center p-6">Loading vitals data...</div>;
//   }

//   return (
//     <div className="p-4 bg-gray-50 min-h-screen mt-28">
//       <header className="text-center text-2xl font-bold mb-6 text-gray-900">
//         My <span className="text-secondaryColor">Vitals</span>
//       </header>
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
//         <VitalsCard
//           title="Systolic Blood Pressure"
//           value={vitalsData.systolic_bp}
//           range="Range: 90-120 mmHg"
//           needleValue={vitalsData.systolic_bp}
//           minValue={90}
//           maxValue={140}
//           color={getColorForHeartRate(vitalsData.systolic_bp)}
//           angle={getAngleForHeartRate(vitalsData.systolic_bp)}
//         />
//         <VitalsCard
//           title="Diastolic Blood Pressure"
//           value={vitalsData.diastolic_bp}
//           range="Range: 60-80 mmHg"
//           needleValue={vitalsData.diastolic_bp}
//           minValue={50}
//           maxValue={100}
//           color={getColorForHeartRate(vitalsData.diastolic_bp)}
//           angle={getAngleForHeartRate(vitalsData.diastolic_bp)}
//         />
//         <VitalsCard
//           title="Heartbeat"
//           value={vitalsData.predicted_heart_rate}
//           range="Range: 60-100 bpm"
//           needleValue={vitalsData.predicted_heart_rate}
//           minValue={50}
//           maxValue={120}
//           color={getColorForHeartRate(vitalsData.predicted_heart_rate)}
//           angle={getAngleForHeartRate(vitalsData.predicted_heart_rate)}
//         />
//         <VitalsCard
//           title="Breathing"
//           value={vitalsData.breathing_rate}
//           range="Range: 12-20 bpm"
//           needleValue={vitalsData.breathing_rate}
//           minValue={10}
//           maxValue={25}
//           color={getColorForHeartRate(vitalsData.breathing_rate)}
//           angle={getAngleForHeartRate(vitalsData.breathing_rate)}
//         />
//         <VitalsCard
//           title="Oximeter"
//           value={`${vitalsData.oxygen_level}%`}
//           range="Range: 95-100%"
//           needleValue={vitalsData.oxygen_level}
//           minValue={90}
//           maxValue={100}
//           color={getColorForHeartRate(vitalsData.oxygen_level)}
//           angle={getAngleForHeartRate(vitalsData.oxygen_level)}
//         />
//         <VitalsCard
//           title="Stress"
//           value={vitalsData.curebay_stress_index}
//           range="Range: 0-10"
//           needleValue={vitalsData.curebay_stress_index}
//           minValue={0}
//           maxValue={10}
//           color={getColorForHeartRate(vitalsData.curebay_stress_index)}
//           angle={getAngleForHeartRate(vitalsData.curebay_stress_index)}
//         />
//       </div>
//     </div>
//   );
// };

// export default SmartVitalsReport;

import React, { useEffect, useState } from "react";
import { Card, Row, Col, Tabs, Alert } from "antd";
import {
  FileTextOutlined,
  CheckCircleOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import "tailwindcss/tailwind.css";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import "./vitals.css";
import VITALSINFOVECTOR from "../../../Assets/Images/smartVitals/vitals-info-vector.png";
import HUMANSKELETON from "../../../Assets/Images/smartVitals/human-skeleton.png";
import Healthassmnt from "../../../Assets/Images/healthass_ai.svg";
import Stetho from "../../../Assets/Images/stetho.svg";
import Consideration from "../../../Assets/Images/consideration.svg";
import Dodont from "../../../Assets/Images/dodonts.svg";
import Alerticon from "../../../Assets/Images/alert.svg";
import Recommend from "../../../Assets/Images/recommendation.svg";
import { Button } from "antd";
import axios from "axios";
import { secureStorage } from "../../../Redux/Reducer/authReducer";
import { useSelector } from "react-redux";
import Header from "../../Shared/Header";
const { TabPane } = Tabs;

const SmartVitalsReport = () => {
  const location = useLocation(); // Get the location object from the router
  const queryParams = new URLSearchParams(location.search); // Create a URLSearchParams object with the query string
  let s_token = secureStorage.getItem("token");
  const [healthAssesment, sethealthAssesment] = useState(" ");
  const userData = useSelector((state) => state.authReducer.patientData);
  const [parsedData, setParsedData] = useState({
    vitalsInterpretation: [],
    recommendations: [],
  });
  const breathingRate = queryParams.get("breathing_rate");
  const heartRate = queryParams.get("heart_rate");
  const hrv = queryParams.get("hrv");
  const pnn50 = queryParams.get("pnn50");
  const rmssd = queryParams.get("rmssd");
  const sdnn = queryParams.get("sdnn");
  const spo2 = queryParams.get("spo2");
  const name = queryParams.get("name");
  const age = queryParams.get("age");
  const gender = queryParams.get("gender");
  const height = queryParams.get("height");
  const height_unit = queryParams.get("height_unit");
  const weight = queryParams.get("weight");
  const weight_unit = queryParams.get("weight_unit");
  const smoke = queryParams.get("smoke");
  const diabetes = queryParams.get("diabetes");
  

  useEffect(() => {
    if(userData.code){
      // fetchHealthAssessment();
    }
  },[])
  // const healthQuestion = `Here are my Vitals , Please a generate a intial health assessment in points for me  My name is ${name}, I am ${age} years old, and my gender is ${gender}. My height is ${height} ${height_unit}, and my weight is ${weight} ${weight_unit}. I ${smoke === 'yes' ? 'do' : "don't"} smoke, and I ${diabetes === 'yes' ? 'have' : "don't have"} a history of diabetes. My heart rate is ${heartRate}, my HRV is ${hrv}, my PNN50 is ${pnn50}, my RMSSD is ${rmssd}, my SDNN is ${sdnn}, my breathing rate is ${breathingRate}, and my SpO2 level is ${spo2}.`;
  const HealthAssessmentQuestion = `Here are my vitals. Please create a health Report of minimum 2000 words include, dos and donts, doctor visit or not, precautions. My age is ${age} years. My gender is ${gender}, My Height and weight is ${height} ${height_unit} and ${weight} ${weight_unit} respectively. I ${smoke === "yes" ? "do" : "don't"} smoke and ${
    diabetes === "yes" ? "have" : "have no"
  } history of diabetes. My Heart Rate is ${heartRate}, HRV is ${hrv}, PN50) is ${pnn50}, RMSSD is ${rmssd}, SDNN is ${rmssd}, breating rate is ${breathingRate} and SPO2 is ${spo2}.`;

  const formatAnswer = (response) => {
    return response
      .split("\n")
      .map((line) => {
        let result = "";
        let inBold = false;

        // Handle the cases for list items and bold formatting
        if (line.startsWith("* **")) {
          const parts = line.split("**");
          return `<li><strong>${parts[1]}</strong>${parts[2]}</li>`;
        }

        // if (line.startsWith("    * **")) {
        //   const parts = line.split("**");
        //   return `<li>&nbsp;&nbsp;&nbsp;&nbsp;<strong>${parts[1]}</strong>${parts[2]}</li>`;
        // }
        if (line.startsWith("    * **")) {
          line = "  • " + line.slice(2);
        }

        // Process bold text within the line
        for (let i = 0; i < line.length; i++) {
          if (line[i] === "*" && line[i + 1] === "*") {
            if (inBold) {
              result += "</strong>";
              inBold = false;
            } else {
              result += "<strong>";
              inBold = true;
            }
            i++; // Skip the next '*'
          } else {
            result += line[i];
          }
        }

        // Close any remaining bold tags
        if (inBold) {
          result += "</strong>";
        }

        // Wrap the result in list item tags if it starts with '• '
        if (line.startsWith("• ")) {
          return `<li className="pb-2" >${result}</li>`;
        } else {
          return `<p className="py-2 >${result}</p>`;
        }
      })
      .join("");
  };
  
  const fetchHealthAssessment = async () => {
    try {
      const payload = {
        user_prompt: HealthAssessmentQuestion,
        classify: 1,
        identifier: userData.code
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${secureStorage.getItem("token")}`,
      };

      const response = await axios.post(
        "https://caresathi.curebay.in/all_in_one/get_answer_v1",
        payload,
        { headers }
      );
      const contentType = response.headers.get("Content-Type");

        let responseText;
        if (contentType && contentType.includes("application/json")) {
          // const data = await response.json();
            responseText = response.data.answer;
        } else {
          responseText = await response.text();

        }

        const formattedAnswer = formatAnswer(responseText);
        sethealthAssesment(formattedAnswer)
    } catch (err) {
      console.error("Error:", err);
    }
  };

  console.log(healthAssesment, "Assessment");
  const parseHealthAssessment = (assessmentText) => {
    if (!assessmentText)
      return { vitalsInterpretation: [], recommendations: [] };

    const vitalsInterpretation = [];
    const recommendations = [];
    let currentSection = "vitals"; // Start with vitals interpretation

    assessmentText.split("\n").forEach((line) => {
      if (line.includes("Recommendations:")) {
        currentSection = "recommendations";
        return; // Skip this line
      }

      if (currentSection === "vitals") {
        vitalsInterpretation.push(line.trim());
      } else if (currentSection === "recommendations") {
        recommendations.push(line.trim());
      }
    });

    return { vitalsInterpretation, recommendations };
  };

  const data = [
    {
      name: "breathing_rate",
      displayName: "Breathing Rate",
      unit: "breaths/min",
      normalRange: { min: 12, max: 20 },
      abnormalRange: { min: 21, max: 30 },
      criticalRange: { min: 31, max: 60 },
      value: breathingRate,
      minValue: 0, // Minimum possible value
      maxValue: 60,
    },
    {
      name: "heart_rate",
      displayName: "Heart Rate",
      unit: "BPM",
      normalRange: { min: 60, max: 100 },
      abnormalRange: { min: 101, max: 130 },
      criticalRange: { min: 131, max: 200 },
      value: heartRate,
      minValue: 0, // Minimum possible value
      maxValue: 200,
    },
    {
      name: "hrv",
      displayName: "Heart Rate Variability (HRV)",
      unit: "ms",
      normalRange: { min: 20, max: 50 },
      abnormalRange: { min: 51, max: 80 },
      criticalRange: { min: 81, max: 200 },
      value: hrv,
      minValue: 0, // Minimum possible value
      maxValue: 200,
    },
    {
      name: "pnn50",
      displayName: "pNN50",
      unit: "%",
      normalRange: { min: 10, max: 30 },
      abnormalRange: { min: 31, max: 50 },
      criticalRange: { min: 51, max: 100 },
      value: pnn50,
      minValue: 0, // Minimum possible value
      maxValue: 100,
    },
    {
      name: "rmssd",
      displayName: "Root Mean Square of Successive Differences (RMSSD)",
      unit: "ms",
      normalRange: { min: 20, max: 50 },
      abnormalRange: { min: 51, max: 100 },
      criticalRange: { min: 101, max: 250 },
      value: rmssd,
      minValue: 0, // Minimum possible value
      maxValue: 250,
    },
    {
      name: "sdnn",
      displayName: "Standard Deviation of NN intervals (SDNN)",
      unit: "ms",
      normalRange: { min: 50, max: 100 },
      abnormalRange: { min: 101, max: 150 },
      criticalRange: { min: 151, max: 300 },
      value: sdnn,
      minValue: 0, // Minimum possible value
      maxValue: 300,
    },
    {
      name: "spo2",
      displayName: "Oxygen Saturation (SpO2)",
      unit: "%",
      normalRange: { min: 95, max: 100 },
      abnormalRange: { min: 91, max: 94 },
      criticalRange: { min: 0, max: 90 },
      value: spo2,
      minValue: 0, // Minimum possible value
      maxValue: 100,
    },
  ];

  console.log(data, "DataValue");
  console.log(parsedData.vitalsInterpretation, "Assessment");
  return (
    <><Header /><div className="min-h-screen bg-gray-100">
      {/* Header Section */}
      <div
        className="bg-blue-900 text-white p-14 flex flex-col md:flex-row justify-around items-center"
        style={{ backgroundColor: "#004171" , marginTop:"10px" }}
      >
        <div className="mb-6 md:mb-0 text-center md:text-left">
          <h1
            className="text-2xl md:text-3xl font-bold text-white"
            style={{ color: "#ffff" }}
          >
            Hey {name}!
          </h1>
          <p className="mt-2 text-lg md:text-xl" style={{ color: "#ffff" }}>
            Here is your health metrics
          </p>
          <div
            className="mt-4 flex flex-col items-center md:items-start justify-center w-full md:w-max relative"
            style={{
              backgroundColor: "#005D8D",
              boxShadow: "0px 1px 12.8px 0px #00000040",
              borderRadius: 10,
            }}
          >
            <div
              className="absolute left-0 hidden md:block"
              style={{
                backgroundImage: `url(${VITALSINFOVECTOR})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "280px",
                height: "130px",
              }} />
            <div
              className="grid grid-cols-2 md:grid-cols-3 px-4 py-4 gap-4"
              style={{ zIndex: 1 }}
            >
              <div
                className="bg-blue-800 text-white p-2 rounded-lg"
                style={{ color: "#ffff" }}
              >
                Age: {age}
              </div>
              <div
                className="bg-blue-800 text-white p-2 rounded-lg"
                style={{ color: "#ffff" }}
              >
                Gender: {gender}
              </div>
              <div
                className="bg-blue-800 text-white p-2 rounded-lg"
                style={{ color: "#ffff" }}
              >
                Height: {height} {height_unit}
              </div>
              <div
                className="bg-blue-800 text-white p-2 rounded-lg"
                style={{ color: "#ffff" }}
              >
                Weight: {weight} {weight_unit}
              </div>
              <div
                className="bg-blue-800 text-white p-2 rounded-lg"
                style={{ color: "#ffff" }}
              >
                Smoke: {smoke}
              </div>
              <div
                className="bg-blue-800 text-white p-2 rounded-lg"
                style={{ color: "#ffff" }}
              >
                Diabetes: {smoke}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-auto">
          <img src={HUMANSKELETON} className="w-full md:w-auto max-w-xs" />
        </div>
      </div>

      {/* Health Matrix Section */}
      <div className="p-10">
        <h2 className="text-2xl text-[#004171] font-bold text-center mb-6">
          Your Health Matrix
        </h2>
        <Row gutter={[16, 16]}>
          {data.map((item, index) => (
            <Col xs={24} sm={12} md={8} lg={6} key={index}>
              <Card className="shadow-lg">
                <ResponsiveContainer width="100%" height={300}>
                  <CircularHeartBeatMeter
                    vitalValue={item.value}
                    maxValue={item.maxValue}
                    normalRange={item.normalRange}
                    abnormalRange={item.abnormalRange}
                    criticalRange={item.criticalRange}
                    unit={item.unit}
                    displayName={item.displayName} />
                </ResponsiveContainer>
                <h3 className="text-center mt-4">{item.displayName}</h3>
                <p className="text-center text-sm text-gray-500">
                  Range: {item.minValue} - {item.maxValue}
                </p>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

     {/* <div className="flex justify-center" >
     <div className="flex flex-col w-full sm:w-1/2 border-2 rounded px-2 sm:px-8 py-2 sm:py-8" style={{borderColor: "grey"}}
                            dangerouslySetInnerHTML={{ __html:  healthAssesment }}
                          ></div>
      </div>      */}

      {/* <div className="bg-white p-6 rounded-lg shadow-md mb-10 flex flex-col items-center">
        <h2 className="text-2xl font-bold flex items-center mb-6">
          <FileTextOutlined className="mr-2 text-blue-600" />
          Health Assessment
        </h2>
        <div className="space-y-4 w-full sm:w-1/2">
        {healthAssesment.length && healthAssesment.map((item, index) => (
              
              <Alert
              message= {item}
              type="info"
              showIcon
              icon={<FileDoneOutlined className="text-red-500" />}
            />
            ))}
        </div>
      </div> */}


      {/* <div
        style={{
          background: "var(--Blue-Accent-1, #F3F8FF)",
          marginTop: "50px",
        }}
      >
        <div className="p-6 rounded-lg mx-auto flex flex-col justify-center items-center text-center mb-10 ">
          <div className="flex justify-center mt-5 mb-5 gap-4">
            <div className="bg-White rounded-full p-1  flex items-center justify-center ml-2">
              <img src={Stetho} alt="Icon" className="w-10 h-10" />
            </div>
            <h2 className="text-lg font-bold text-[#004171] mt-2">
              Need To Visit Doctor
            </h2>
          </div>
          <div className="sm:w-[70%] w-full">
            <span>
            While there are no urgent concerns, it's recommended to consult a healthcare professional for routine check-ups,
            </span>
          </div>
        </div>
      </div> */}

      {/* Tabs Section */}
      {/* <div
        style={{
          background: "var(--Blue-Accent-1, #F3F8FF)",
          marginTop: "50px",
        }}
        className="bg-white p-2 rounded-lg shadow-md mb-10"
      >
        <Tabs defaultActiveKey="1" centered>
          <TabPane
            tab={<div className="flex justify-center  gap-2">
              <div className="bg-White rounded-full p-1  flex items-center justify-center hidden sm:block ">
                <img src={Consideration} alt="Icon" className="w-8 h-8 " />
              </div>
              <h2 className="text-sm font-bold text-[#004171] mt-1 sm:text-lg ">
                {" "}
                Considerations
              </h2>
            </div>}
            key="1"
          >
            <div className=" grid sm:grid-cols-2 grid-cols-1 gap-4 bg-blue-900 text-white px-10 py-10 bg-primaryColor text-White">
              {healthAssesment?.considerations.map((rec, index) => (
                <div
                  key={index}
                  className="flex items-start space-x-2 text-White"
                >
                  <span className="font-bold text-White">{index + 1}.</span>
                  <p>{rec}</p>
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane
            tab={<div className="flex justify-center  gap-2">
              <div className="bg-White rounded-full p-1  flex items-center justify-center hidden sm:block ">
                <img src={Dodont} alt="Icon" className="w-8 h-8 " />
              </div>
              <h2 className="text-sm sm:text-lg font-bold text-[#004171] mt-1">
                Do's & Don'ts
              </h2>
            </div>}
            key="2"
          >
          </TabPane>
        </Tabs>
      </div> */}

      {/* Footer Disclaimer */}
      <div className="bg-blue-900 text-white p-6 rounded-lg shadow-md">
        <div className="flex items-center justify-center">
          <img src={Alerticon} alt="Icon" className="w-8 h-8 mr-4" />
          <p>
            This report is generated by AI and should not be viewed as a final
            or definitive medical assessment. Always consult a healthcare
            professional for personalized medical advice and treatment.
          </p>
        </div>
        {/* <div className="flex justify-center">
      <Button
        type="primary"
        htmlType="submit"
        className="sm:w-[70%] w-[107%] h-12 bg-blue-900 hover:bg-blue-800 text-white rounded-3xl  mt-24"
      >
        Next
      </Button>
    </div> */}
      </div>
    </div></>
  );
};

export default SmartVitalsReport;

// Utility function to calculate stroke offset for circle
const calculateStrokeDashoffset = (value, radius, max) => {
  const circumference = 2 * Math.PI * radius;
  const percent = value / max;
  return circumference * (1 - percent);
};

// Utility function to determine the color based on the heartbeat range
const getColor = (vitalValue, normalRange, abnormalRange, criticalRange) => {
  if (vitalValue >= normalRange.min && vitalValue <= normalRange.max)
    return "#008000"; // Green for normal range
  if (vitalValue >= abnormalRange.min && vitalValue <= abnormalRange.max)
    return "#ffa500"; // Orange for abnormal range
  if (vitalValue >= criticalRange.min && vitalValue <= criticalRange.max)
    return "#ff0000"; // Red for critical range
};

const CircularHeartBeatMeter = ({
  vitalValue,
  maxValue,
  normalRange,
  abnormalRange,
  criticalRange,
  unit,
  displayName,
}) => {
  console.log(
    vitalValue,
    normalRange,
    abnormalRange,
    criticalRange,
    "ukgigigiyiyvv"
  );
  const radius = 80;
  const stroke = 10;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const maxHeartbeat = maxValue; // Maximum value for the meter

  const strokeDashoffset = calculateStrokeDashoffset(
    vitalValue,
    normalizedRadius,
    maxHeartbeat
  );
  const strokeColor = getColor(
    vitalValue,
    normalRange,
    abnormalRange,
    criticalRange
  );

  const getVitalStatus = () => {
    if (vitalValue >= normalRange.min && vitalValue <= normalRange.max) {
      return "Normal";
    } else if (
      vitalValue >= abnormalRange.min &&
      vitalValue <= abnormalRange.max
    ) {
      return "Abnormal";
    } else if (
      vitalValue >= criticalRange.min &&
      vitalValue <= criticalRange.max
    ) {
      return "Critical";
    } else {
      return "Out of Range"; // Handle cases that do not fit in any defined range
    }
  };

  return (
    <div style={{ textAlign: "center", padding: "20px",display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
   
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke="#e6e6e6"
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={strokeColor}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashoffset}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          style={{
            transition: "stroke-dashoffset 0.35s",
            transform: "rotate(-90deg)",
            transformOrigin: "50% 50%",
          }}
        />
        <text
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle"
          fontSize="15px"
          fill={strokeColor}
        >
          {vitalValue} {unit}
        </text>
      </svg>
      <p style={{ fontWeight: "bold", marginTop: "10px" }}>
        {displayName} Status: {getVitalStatus()}
      </p>
    </div>
  );
};
