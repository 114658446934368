/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPatientfamilymembers } from "../../../../Redux/Action/UserprofileActions";
import {
  setPatientCode,
  setDropDownPatientCode,
} from "../../../../Redux/Action/userActions";
import { Select } from "antd";

function FamilyDropdown(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  const patientCode = useSelector((state) => state.authReducer.patientCode);
  const familymemberinfo = useSelector((state) => state.familymembers);
  const [currMemberCode, setCurrMemberCode] = useState(patientCode);

  const memberCode = useSelector((state) => state.particularpatientdetails);
  console.log(memberCode);

  const { FamilymembersData } = familymemberinfo;
  const [memberList, setMemberList] = useState([]);

  const addSelf = () => {
    let data = {};
    data.name = userData.firstName;
    data.code = userData.code;
    let members = FamilymembersData;
    members.unshift(data);
    let uniqueObjArray = [
      ...new Map(members.map((item) => [item["firstName"], item])).values(),
    ];
    setMemberList(uniqueObjArray);
  };

  useEffect(() => {
    if (FamilymembersData) {
      addSelf();
    }
  }, [FamilymembersData]);

  const onChange = (code) => {
    props.onSelect(code);
  };

  useEffect(() => {
    dispatch(getPatientfamilymembers(userData.code));
  }, []);


  return (
      <div className="w-full">
        <Select
          placeholder={props.title}
          onChange={onChange}
          options={memberList.map((res, i) => ({
            value: res.code,
            label: res.name,
          }))}
          className={`${props.height?props.height:'h-12'} w-full md:w-[200px] text-base`}
        >
        </Select>
      </div>
  );
}
export default FamilyDropdown;
