import React, { useState, useEffect } from "react";
import PrevBrowsedMedicine from "./PrevBrowsedMedicine";
import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
// import delet from "../Assets/Images/delete.svg";
// import minus from "../Assets/Images/minus.svg";
// import plus from "../Assets/Images/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import SectionContainer from "./SectionContainer";
import CartPrice from "./cartPrice";
import LabCart from "./LabList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { walletdetails } from "../../../Redux/Action/UserprofileActions";
import cookieFinder from "./hooks/cookieFinder.jsx";
// import "primeicons/primeicons.css";
import {
  clearPrescription,
  patientaddresslists,
  getPatientDetails,
  onApplyCoupon,
  getCouponList,
} from "../../../Redux/Action/UserprofileActions";
import Loader from "../../Shared/loader";
import {
  getCartData,
  getUploadedPrescription,
  getEPrescrptionList,
  uploadPrescriptionDoc,
  deleteUploadedPrescripiton,
} from "./cartApi/cartapis";
import moment from "moment";
import http from "../../../Redux/Services/http-common";
import {
  AddtoCart,
  getCartDetails,
} from "../../../Redux/Action/cartPlaceOrderAction";
import { useCookies } from "react-cookie";
// import Loader from "../../components/Loader.jsx";
import MedicineList from "./medicineList";
import CartTimeLine from "./CartTimeLine";
import Layouts from "./../../Layouts";
import RXicon from "../../../Assets/Images/Cart/rxicon.png";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import useDetectDevice from "../../utils/detectDeviceType.js";

function CartReview() {
  const familymemberinfo = useSelector((state) => state.familymembers);
  const [cookies, setCookie] = useCookies(["__gtm_campaign_url"]);
  const location = useLocation();
  const deviceType = useDetectDevice()
  // const selectpatientdata = location?.state?.selectpatientdata;
  // localStorage.setItem('selectpatientdata', JSON.stringify(selectpatientdata));
  // console.log(selectpatientdata,"zxn")
  let selectpatientdata = localStorage.getItem("selectpatientdata");
  console.log(selectpatientdata, "selectpatientdatanew");
  const dispatch = useDispatch();
  const history = useHistory();
  const walletinfo = useSelector((state) => state.patientwallet);

  const patientinfo = useSelector((state) => state.patientinfo);

  const userData = useSelector((state) => state.authReducer.patientData);
  // const cartList = useSelector((state) => state.cartReducer.cartList);
  // const isLoading = cartList?.patientLabTestsOrder;
  const [cartList, setCartList] = useState({});
  const patientLabTestsOrder = cartList?.patientLabTestsOrder;
  const patientMedicineOrder = cartList?.patientMedicineOrder;
  const [patient, setPatient] = useState(userData);
  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    }else {
      window.scrollTo(0, 0);
    }
  };

  const [isMed, setMedOpen] = useState(true);
  const [isDia, setDiaOpen] = useState(true);
  const [isAdd, setAddress] = useState(false);
  const [medmembershipError, setMedMembershipError] = useState({
    error: "",
    loading: false,
  });
  const [labmembershipError, setLabMembershipError] = useState({
    error: "",
    loading: false,
  });
  const [showLoader, setShowLoader] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountFinal, setTotalAmountFinal] = useState("0");
  const [medicineTotalAmount, setMedicineTotalAmount] = useState("0");
  const [medicineTotalAmountFinal, setMedicineTotalAmountFinal] = useState("0");
  const [medicineDiscountAmount, setMedicineTotalDiscountAmount] =
    useState("0");
  const [code, setCode] = useState("");
  const [loadingDuringPayment, setLoadingDuringPayment] = useState(false);
  const [selectedSavedPrescription, setSelectedSavedPrescription] = useState(
    []
  );
  const [selectedEPrescription, setSelectedEPrescription] = useState([]);
  const [errorDuringPay, setErrorDuringPay] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedPayMode, setSelectedPayMode] = useState({
    wallet: false,
    card: false,
  });
  const [medMembershipData, setMedMembershipData] = useState({});
  const [labMembershipData, setLabMembershipData] = useState({});
  const [isUserReadyToPay, setIsUserReadyToPay] = useState(false);
  const [isPrescriptionUploaded, setisPrescriptionUploaded] = useState({
    prescriptionUploaded: false,
    prescriptionDoc: [],
    docType: "",
    loading: false,
  });
  const [emptycart, setemptycart] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPreferredDate, setSelectedPreferredDate] = useState();
  const [selectedHomeSamplePreferredDate, setSelectedHomeSamplePreferredDate] =
    useState();
  const [prescriptionUrl, setPrescriptionUrl] = useState(null);
  const [VisitId, setVisitId] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [isCouponApply, setCouponApply] = useState(false);
  const [loadingForCoupon, setLoadingForCoupon] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [savedPrescriptionList, setSavedPrescriptionList] = useState([]);
  const [isEPrescriptionDialogOpen, setIsEPrescriptionDialogOpen] =
    useState(false);
  const [ePrescriptionList, setEPrescriptionsList] = useState([]);

  const [prescriptionUploadedDoc, setPrescriptionUploadedDoc] = useState("");
  const [preferDate, setPreferDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [preferTime, setPreferTime] = useState("");
  const hiddenFileInput = React.useRef(null);
  const [showaddresspopup, setshowaddresspopup] = useState(false);
  const [filterPopup, setFilterPopup] = useState({
    flag: false,
    data: [],
  });
  const [showAddress, setShowAddress] = useState([]);
  const [isAddressChecked, setAddressChecked] = useState("");
  const [loading, setLoading] = useState(1);
  const [deliveryCoordinates, setdeliveryCordinates] = useState({
    lat: "",
    long: "",
  });
  const [isSavedPrescriptionDialogOpen, setIsSavedPrescriptionDialogOpen] =
    useState(false);
  const [loadingnew, setLoadingnew] = useState(true);
  /////////memebership///

  console.log(savedPrescriptionList, "sdvsdhvuisdgviudsv");

  // const selectpatientdata = JSON.parse(localStorage.getItem('selectpatientdata'));

  useEffect(() => {
    getCartData(userData.code).then((res) => {
      if (res.data?.length) {
        // setCartList(res.data[0]);

        const updatedCartList = res.data[0];

        updatedCartList.createdBy = selectpatientdata;
        updatedCartList.modifiedBy = selectpatientdata;
        updatedCartList.patientId = selectpatientdata;
        setTimeout(() => {
          //const fetchedCartData = { price: 100 }; // Replace with actual cart data
          setCartList(updatedCartList);
          setLoadingnew(false);
        }, 100);
        // setCartList(updatedCartList);
        console.log(
          updatedCartList,
          "resultdata",
          userData.code,
          selectpatientdata
        );
      } else {
      }
    });
    dispatch(walletdetails(userData.code));
  }, []);

  useEffect(() => {
    console.log(
      localStorage.getItem("paymentStatus"),
      localStorage.getItem("trancationid"),
      cartList
    );

    if (
      localStorage.getItem("paymentStatus") &&
      localStorage.getItem("trancationid") &&
      cartList &&
      (cartList?.patientMedicineOrder?.length ||
        cartList?.patientLabTestsOrder?.length)
    ) {
      console.log(
        localStorage.getItem("paymentStatus"),
        localStorage.getItem("trancationid"),
        cartList
      );
      // localStorage.removeItem("paymentStatus");
      console.log(
        JSON.parse(localStorage.getItem("totalAmount")),
        localStorage.getItem("totalAmount"),
        "totalamountnew"
      );
      trackPayment(
        userData.code,
        cartList,
        localStorage.getItem("trancationid"),
        "",
        "Payment done successfully",
        JSON.parse(localStorage.getItem("totalAmount")),
        "cart",
        1,
        localStorage.getItem("patient"),
        "PayU"
      );
      redirectAfterTxn(localStorage.getItem("trancationid"));
      console.log(
        localStorage.getItem("paymentStatus"),
        localStorage.getItem("trancationid"),
        cartList
      );
    } else if (
      localStorage.getItem("paymentStatus") === "failure" &&
      localStorage.getItem("paymentRemarks")
    ) {
      // toast(localStorage.getItem("paymentRemarks"));
      localStorage.removeItem("paymentStatus");
      localStorage.removeItem("paymentRemarks");
    }

    console.log("cart data ha", JSON.stringify(cartList));
  }, [cartList]);

  useEffect(() => {
    dispatch(getPatientDetails(userData?.code));
    dispatch(patientaddresslists(userData?.code));
    // window.location.reload();
  }, [showaddresspopup]);

  const onChangeMed = () => {
    setMedOpen(!isMed);
  };

  const onChangeDia = () => {
    setDiaOpen(!isDia);
  };

  /**
   * Payment API Code
   */

  const trackPayment = async (
    patientId,
    cartList,
    transactionId,
    payUId,
    payURemarks,
    amount,
    productInfo,
    status,
    payloadResponse,
    payMode
  ) => {
    let orderId = "";
    if (
      cartList.patientLabTestsOrder?.length &&
      cartList.patientMedicineOrder?.length
    ) {
      orderId = `${cartList.patientLabTestsOrder[0].orderId},${cartList.patientMedicineOrder[0].orderId}`;
    } else if (cartList.patientLabTestsOrder?.length) {
      orderId = `${cartList.patientLabTestsOrder[0].orderId}`;
    } else if (cartList.patientMedicineOrder?.length) {
      orderId = `${cartList.patientMedicineOrder[0].orderId}`;
    }
    const payloadForTrackPayment = {
      patientId: patientId,
      orderId: orderId,
      transactionId: transactionId,
      payUId: payUId,
      payURemarks: payURemarks,
      amount: parseFloat(amount),
      productInfo: productInfo,
      status: status,
      createdBy: patientId,
      payloadResponse: payloadResponse,
      payMode: payMode,
    };
    return await http.post(
      `${process.env.REACT_APP_BASEURL}api/v1/payments/payUStatus`,
      payloadForTrackPayment
    );
  };

  const redirectAfterTxn = async (txnid) => {
    let labrocedurestatuscode;
    let labstatus;
    // let drugprocedureStatus ;
    // let drugStatus ;
    console.log(selectedPayMode, "sdvisdgovsdgugsduigvsiudguidsgv");
    let patient = JSON.parse(localStorage.getItem("patient"));
    let cartData = JSON.parse(localStorage.getItem("saveObj"));
    try {
      
      setIsLoading(true);
      let ttlAmt = 0;
      let amountFromPayU = JSON.parse(localStorage.getItem("totalAmount"));
      let medicineTotalAmount = JSON.parse(
        localStorage.getItem("medicineTotalAmount")
      );
      let txnid = localStorage.getItem("trancationid");
      let address = JSON.parse(localStorage.getItem("selectedAddress"));
      console.log(address, "sdvdssdvsdvuihsduivhsduvgsd");
      if (
        cartData.patientLabTestsOrder &&
        cartData.patientLabTestsOrder?.length > 0
      ) {
        // cartData.preferDateTime = selectedPreferredDate
        //   ? selectedPreferredDate
        //   : selectedHomeSamplePreferredDate
        //   ? null
        //   : moment().format("yyyy-MM-DD HH:mm:ss");
        cartData.collectionSampleDate = selectedHomeSamplePreferredDate
          ? selectedHomeSamplePreferredDate
          : selectedPreferredDate
            ? null
            : moment().format("yyyy-MM-DD HH:mm:ss");
        cartData.patientLabTestsOrder = cartData.patientLabTestsOrder.map(
          (labTestData) => {
            let totalamount = 0;
            if (
              labTestData.patientLabTestsOrderDetailsList &&
              labTestData.patientLabTestsOrderDetailsList?.length > 0
            ) {
              let array = [];

              labTestData.patientLabTestsOrderDetailsList.forEach((data) => {
                if (data.status == 1) {
                  // data.patientId = patient.code ?? userData.code;
                  data.patientId = selectpatientdata;
                  data.createdBy = selectpatientdata;
                  data.modifiedBy = selectpatientdata;
                  array.push(data);
                }
              });
              labTestData.patientLabTestsOrderDetailsList = array;
              labTestData.patientLabTestsOrderDetailsList.forEach((data) => {
                console.log(data, "dsfiohaofhwpie");
                totalamount += parseFloat(data.discountAmount)
                  ? parseFloat(data.discountAmount)
                  : data.amount;
              });
            }
            // labTestData.preferDateTime = selectedPreferredDate
            //   ? selectedPreferredDate
            //   : selectedHomeSamplePreferredDate
            //   ? null
            //   : moment().format("yyyy-MM-DD HH:mm:ss");
            labTestData.collectionSampleDate = selectedHomeSamplePreferredDate
              ? selectedHomeSamplePreferredDate
              : selectedPreferredDate
                ? null
                : moment().format("yyyy-MM-DD HH:mm:ss");

            labTestData.address1 = address.address1 ?? "";
            labTestData.address2 = address.address2 ?? "";
            labTestData.address3 = address.address3 ?? "";
            labTestData.payMode = localStorage.getItem("payMode");
            labTestData.deliveryAddress1 = address.address1 ?? "";
            labTestData.deliveryAddress2 = address.address2 ?? "";
            labTestData.deliveryAddress3 = address.address3 ?? "";
            labTestData.deliveryCity = address.city ?? "";
            labTestData.deliveryState = address.state ?? "";
            labTestData.deliveryZipcode = address.pinCode ?? "";
            labTestData.deliveryCountry = address.country ?? "";
            labTestData.patientId = patient.code ?? userData.code;
            labTestData.preferTime = JSON.parse(
              localStorage.getItem("preferValue")
            );
            labTestData.preferDateTime = `${JSON.parse(
              localStorage.getItem("preferDate")
            )} ${JSON.parse(localStorage.getItem("preferTime"))}:00:00`;
            labTestData.latitude = address.lattitude;
            labTestData.longitude = address.longitude;
            labTestData.txnId = txnid;
            labTestData.paidAmount = totalamount.toFixed(2);
            labTestData.totalAmount = totalamount.toFixed(2);
            ttlAmt += totalamount;

            if (localStorage.getItem("paymentStatus") === "success") {
              labrocedurestatuscode = 0;
              labstatus = 1;
            } else if (localStorage.getItem("paymentStatus") === "pending") {
              labrocedurestatuscode = 0;
              labstatus = 30;
            } else if (localStorage.getItem("paymentStatus") === "failure") {
              labrocedurestatuscode = 0;
              labstatus = 31;
            } else if (localStorage.getItem("walletstatus") === "success") {
              labrocedurestatuscode = 0;
              labstatus = 1;
            }

            labTestData.procedureStatus = labrocedurestatuscode;
            labTestData.status = labstatus;
            if (labTestData.locationCurebayCenter == "Y") {
              // labTestData.procedureStatus = 1;
              labTestData.curebayCenter = "Y";
            }
            labTestData.patientId = selectpatientdata;
            labTestData.createdBy = selectpatientdata;
            labTestData.modifiedBy = selectpatientdata;
            labTestData.modifiedBy = selectpatientdata;
            labTestData.pinCode = address.pinCode ?? "";
            return labTestData;
          }
        );
      }

      if (
        cartData.patientMedicineOrder &&
        cartData.patientMedicineOrder?.length > 0
      ) {
        let totalAmount = 0;

        let drugPayload = { ...cartData.patientMedicineOrder[0] };

        drugPayload["patientMedicineOrderDetailsList"] = [];
        cartData.patientMedicineOrder = cartData.patientMedicineOrder.map(
          (product) => {
            // }
            if (
              product.patientMedicineOrderDetailsList &&
              product.patientMedicineOrderDetailsList?.length > 0
            ) {
              product.patientMedicineOrderDetailsList.forEach((data) => {
                totalAmount += data.totalAmount;
                // data.patientId = patient.code ?? userData.code;
                data.patientId = selectpatientdata;
                data.modifiedBy = selectpatientdata;
                data.createdBy = selectpatientdata;
                if (data.status == 1) {
                  drugPayload.patientId = patient.code ?? userData.code;

                  drugPayload.patientMedicineOrderDetailsList.push(data);
                  drugPayload.procedureStatus = 0;
                }
              });
            }
            let drugprocedureStatus;
            let drugStatus;
            /** Addresses Setup */
            if (localStorage.getItem("paymentStatus") === "success") {
              drugprocedureStatus = 1;

              drugStatus = 1;
            } else if (localStorage.getItem("paymentStatus") === "pending") {
              drugprocedureStatus = 30;
              drugStatus = 30;
            } else if (localStorage.getItem("paymentStatus") === "failure") {
              drugprocedureStatus = 31;
              drugStatus = 31;
            } else if (localStorage.getItem("walletstatus") === "success") {
              drugprocedureStatus = 1;
              drugStatus = 1;
            }
            product.procedureStatus = drugprocedureStatus;
            product.status = drugStatus;
            product.patientId = selectpatientdata;
            product.createdBy = selectpatientdata;
            product.modifiedBy = selectpatientdata;
            product.address1 = address.address1 ?? "";
            product.address2 = address.address2 ?? "";
            product.address3 = address.address3 ?? "";
            product.payMode = localStorage.getItem("payMode");
            product.deliveryAddress1 = address.address1 ?? "";
            product.deliveryAddress2 = address.address2 ?? "";
            product.deliveryAddress3 = address.address3 ?? "";
            product.deliveryCity = address.city ?? "";
            // product.patientId = patient.code ?? userData.code;
            product.amount = parseFloat(medicineTotalAmount);

            product.deliveryState = address.state ?? "";
            product.latitude = address.lattitude;
            product.longitude = address.longitude;
            product.deliveryZipcode = address.pinCode ?? "";
            product.deliveryCountry = address.country ?? "";
            product.txnId = txnid;
            product.docName = localStorage.getItem("prescriptionDoc")
              ? localStorage.getItem("prescriptionDoc")
              : null;
            product.paidAmount = totalAmount.toFixed(2);
            product.totalAmount = totalAmount.toFixed(2);
            ttlAmt += totalAmount;

            return product;
          }
        );

        drugPayload.totalAmount = totalAmount;
        drugPayload.txnId = txnid;
        drugPayload.docName = localStorage.getItem("prescriptionDoc")
          ? localStorage.getItem("prescriptionDoc")
          : null;
        drugPayload.paidAmount = totalAmount.toFixed(2);
        drugPayload.address1 = address.address1 ?? "";
        drugPayload.address2 = address.address2 ?? "";
        drugPayload.address3 = address.address3 ?? "";
        cartData.totalAmount = parseFloat(amountFromPayU).toFixed(2);
        drugPayload.deliveryAddress1 = address.address1 ?? "";
        drugPayload.deliveryAddress2 = address.address2 ?? "";
        drugPayload.deliveryAddress3 = address.address3 ?? "";
        drugPayload.deliveryCity = address.city ?? "";
        drugPayload.deliveryState = address.state ?? "";
        drugPayload.latitude = address.lattitude;
        drugPayload.longitude = address.longitude;
        drugPayload.deliveryZipcode = address.pinCode ?? "";
        drugPayload.deliveryCountry = address.country ?? "";
      }

      console.log("is cart is hai", cartData);
      if (code) {
      }
      let link;
      let remark = "";
      let amount = JSON.parse(localStorage.getItem("totalAmount"));
      if (
        cartData?.patientLabTestsOrder?.length &&
        cartData?.patientLabTestsOrder[0]?.patientLabTestsOrderDetailsList
          .length
      ) {
        link = "MY_ORDERS";
        remark = `Thank You. Your order status is success. Your Transaction ID for this transaction is ${txnid}. We have received a payment of ₹ ${amount}. ${cartData?.patientLabTestsOrder[0]?.patientLabTestsOrderDetailsList[0]
            ?.labTestType === "Radiology"
            ? "CureBay will call you shortly to schedule a convenient slot for appointment."
            : "CureBay will call you shortly to schedule a convenient slot for sample collection."
          }`;
      } else if (
        cartData?.patientMedicineOrder?.length &&
        cartData?.patientMedicineOrder[0]?.patientMedicineOrderDetailsList
          .length
      ) {
        link = "PATIENTMEDICINEORDERS";
        remark = `Thank You. Your order status is success. Your Transaction ID for this transaction is ${txnid}. We have received a payment of ₹ ${amount}. Your order will soon be shipped.`;
      } else {
        link = "PATIENTMEDICINEORDERS";
        remark = `Thank You. Your order status is success. Your Transaction ID for this transaction is ${txnid}. We have received a payment of ₹ ${amount}. Your order will soon be shipped.`;
      }
      console.log(cartData, "sdbvosdgogsdoudsocuhsdocds");
      await http.post("PatientCart/", { ...cartData, status: 0 });
      let compagincookies = cookieFinder(cookies?.__gtm_campaign_url);
      console.log(compagincookies, "compagincookies");
      if (compagincookies && process.env.NODE_ENV == "production") {
        console.log(compagincookies, "compagincookies");
        let compaignData = new FormData();
        compaignData.append("mobile", patient?.mobile);
        compaignData.append("utm_source", compagincookies?.utm_source);
        compaignData.append("utm_medium", compagincookies?.utm_medium);
        compaignData.append("utm_campaign", compagincookies?.utm_campaign);
        compaignData.append("utm_content", compagincookies?.utm_content);
        compaignData.append("user_name", patient?.firstName);
        compaignData.append("user_id", patient?.code);
        compaignData.append("purchase", amount);
        http.post(
          "https://script.google.com/macros/s/AKfycbxQIT912J0-Wl9X31WE9UjnynP4Q8dXV45m5gHjtAiE372dJbpdrjWez6TmdDyhFfkX/exec",
          compaignData
        );
      }
      console.log(cartData, "sdvisdhvudsovusd");
      trackPayment(
        patient.code,
        cartData,
        txnid,
        "",
        remark,
        amountFromPayU,
        "cart",
        3,
        localStorage.getItem("patient"),
        localStorage.getItem("payMode")
      );
      localStorage.removeItem("paymentStatus");
      localStorage.removeItem("saveObj");
      localStorage.removeItem("selectedAddress");
      localStorage.removeItem("payMode");
      localStorage.removeItem("patient");
      localStorage.removeItem("prescriptionDoc");
      localStorage.removeItem("couponCode");
      localStorage.removeItem("preferValue");
      localStorage.removeItem("preferDate");
      localStorage.removeItem("preferTime");
      dispatch(getCartDetails(userData?.code)).finally((res) => {
        dispatch(clearPrescription());
      });
      let orderTimer = setTimeout(() => {
        localStorage.removeItem("trancationid");
        localStorage.removeItem("totalAmount");
        clearTimeout(orderTimer);
      }, 1000);

      history.push({
        pathname: APP_ROUTES.CART_ORDER_SUCESS,
        state: {
          remark: remark,
          link: link,
          cartData: cartData,
          amount: amount,
          txnid: txnid,
        },
      });
    } catch (err) {
      console.log(err, "sdbvosdgogsdoudsocuhsdocds");
      trackPayment(
        patient.code,
        cartData,
        "",
        "",
        "Payment done but order not placed",
        JSON.parse(localStorage.getItem("totalAmount")),
        "cart",
        4,
        localStorage.getItem("patient"),
        localStorage.getItem("payMode")
      );
      localStorage.removeItem("paymentStatus");
      localStorage.removeItem("trancationid");
      localStorage.removeItem("saveObj");
      localStorage.removeItem("totalAmount");
      localStorage.removeItem("trancationid");
      localStorage.removeItem("selectedAddress");
      localStorage.removeItem("payMode");
      localStorage.removeItem("patient");
      localStorage.removeItem("prescriptionDoc");
      localStorage.removeItem("couponCode");
      localStorage.removeItem("preferValue");
      localStorage.removeItem("preferDate");
      localStorage.removeItem("preferTime");
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  //#region Payment Process Start

  const DoPayment = async (from) => {
    //   console.log(cartList, "sdfiusbuivsdvshd");
      // if (cartList?.patientLabTestsOrder?.length && !preferTime) {
      //   toast("Please select prefered Time for Lab Test sample.");
      //   return;
      // }

    //   if (from == "") {
    //     let medicineMembershipApplied = cartList?.patientMedicineOrder?.length ? (cartList?.patientMedicineOrder[0]?.membershipId != (0 || "0") ? 1 : 0) : 0
    //     let labMembershipApplied = cartList?.patientLabTestsOrder?.length ? (cartList?.patientLabTestsOrder[0]?.membershipId != (0 || "0") ? 1 : 0) : 0
    //     console.log(medicineMembershipApplied, labMembershipApplied, "sdvisdhvidshuiv")
    //     if (!isCouponApply && !medicineMembershipApplied && !labMembershipApplied) {
    //       setDialog(true);
    //       return;
    //     }
    //   }

    //   if (showAddress[0].pinCode.length != 6) {
    //     toast("We can't deliver this order to selected address");
    //     return;
    //   }

    //   let uploadPres = [];

    //   if (patientMedicineOrder?.length) {
    //     patientMedicineOrder.map((res) => {
    //       uploadPres = res.patientMedicineOrderDetailsList.filter(
    //         (element) => element.prescriptionRequired == "Y"
    //       );
    //     });
    //   }

    //   if (uploadPres.length > 0 && !isPrescriptionUploaded.prescriptionUploaded) {
    //     toast("Upload prescription to order prescribed medicines.");
    //     return;
    //   }

    //   const pincodePackage = require("pincode-lat-long");
    //   let coords = pincodePackage.getlatlong(showAddress[0].pinCode);
    //   console.log(showAddress, "dsofjdsiohfdusgfids");

    //   let authPayload = new URLSearchParams({
    //     grant_type: "client_credentials",
    //     client_id: process.env.REACT_APP_MMI_CLIENT_ID,
    //     client_secret: process.env.REACT_APP_MMI_CLIENT_SECRET_ID,
    //   });

    //   let auth = await http.post(
    //     `${process.env.REACT_APP_BASEURL}security/oauth/token`,
    //     authPayload,
    //     {
    //       headers: {
    //         "Content-type": "application/x-www-form-urlencoded",
    //       },
    //     }
    //   );
    //   console.log(auth, "eifhohaoiehfipahpofahfpao");
    //   if (auth.data.access_token) {
    //     let eloc = await http.get(
    //       `${process.env.REACT_APP_BASEURL}places/geocode?address=${showAddress[0]?.address1} ${showAddress[0]?.address2} ${showAddress[0]?.city} ${showAddress[0]?.state} ${showAddress[0]?.pinCode}&itemCount=1`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${auth.data.access_token}`,
    //         },
    //       }
    //     );
    //     console.log(eloc, "wdfieuiheuwfuoewobcoecwou");
    //     if (!eloc.data.copResults.pincode) {
    //       toast("Please enter a vaild Address");
    //       return;
    //     }
    //     if (eloc.data.copResults.eLoc) {
    //       let coords = await http.get(
    //         `${process.env.REACT_APP_BASEURL}O2O/entity/${eloc.data.copResults.eLoc}`,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${auth.data.access_token}`,
    //           },
    //         }
    //       );
    //       if (coords.data.latitude && coords.data.longitude) {
    //         if (
    //           patientMedicineOrder &&
    //           patientMedicineOrder.length &&
    //           patientMedicineOrder[0]?.patientMedicineOrderDetailsList?.length
    //         ) {
    //           const data = await http.get(
    //             `${process.env.REACT_APP_ELASTIC_BASEURL}pharmacies/count?latitude=${coords.data.latitude}&longitude=${coords.data.longitude}`
    //           );
    //           if (!data.data) {
    //             toast("We can't deliver medicine order to selected address.");
    //             return;
    //           }
    //         }
    //         if (
    //           patientLabTestsOrder &&
    //           patientLabTestsOrder.length &&
    //           patientLabTestsOrder[0]?.patientLabTestsOrderDetailsList?.length
    //         ) {
    //           const data = await http.get(
    //             `${process.env.REACT_APP_ELASTIC_BASEURL}labs/count?latitude=${coords.data.latitude}&longitude=${coords.data.longitude}`
    //           );
    //           if (!data.data) {
    //             toast("We don't have Lab partner nearby.");
    //             return;
    //           }
    //         }
    //         setdeliveryCordinates({
    //           lat: coords.data.latitude,
    //           long: coords.data.longitude,
    //         });
    //       } else {
    //         toast("Not able to use eLoc api");
    //         return;
    //       }
    //     } else {
    //       toast("Not able to use Geocode");
    //       return;
    //     }
    //   } else {
    //     toast("Not able to access MMI");
    //     return;
    //   }

    //   setDialog(false);
    setIsUserReadyToPay(true);
  };

  const DoPay = async () => {
    console.log(selectedPayMode, "sdoivhsdogvosdgvuidsgiuvgdsuivgsdiuvds");
    setErrorDuringPay("");
    let totalAmount = JSON.parse(localStorage.getItem("totalAmount"));
    let medicineTotalAmount = JSON.parse(
      localStorage.getItem("medicineTotalAmount")
    );
    if (!selectedPayMode.wallet && !selectedPayMode.card) {
      toast("Please select payment mode.");
      return;
    }

    if (selectedPayMode.wallet) {
      if (totalAmount > walletinfo?.patientwalletinfoData?.balance) {
        toast("Balance is not sufficient.");
        return;
      } else {
        setLoadingDuringPayment(true);
        trackPayment(
          userData.code,
          cartList,
          "",
          "",
          "Payment Initiated",
          totalAmount,
          "cart",
          0,
          JSON.stringify(userData),
          "Wallet"
        );
        let data = await http.put(
          `${process.env.REACT_APP_BASEURL}api/v1/payments/wallet/cart/${cartList?.cartId}`
        );
        console.log(data, "dsjfsduhfoshfoisd");
        if (data.status == 200) {
          setLoadingDuringPayment(false);
          localStorage.setItem("patient", JSON.stringify(patient));
          localStorage.setItem("saveObj", JSON.stringify(cartList));
          localStorage.setItem("payMode", "CureBay wallet");
          localStorage.setItem("walletstatus", "success");
          localStorage.setItem(
            "paymentRemarks",
            "Your Order placed successfully"
          );
          localStorage.setItem(
            "trancationid",data.data.data.transcationId
          );
          trackPayment(
            userData.code,
            cartList,
            data.data.data.transcationId,
            "",
            "Payment done successfully",
            totalAmount,
            "cart",
            1,
            JSON.stringify(userData),
            "Wallet"
          );
          redirectAfterTxn(data.data.data.transcationId);
        } else {
          setLoadingDuringPayment(false);
          trackPayment(
            userData.code,
            cartList,
            "",
            "",
            "Payment failed",
            totalAmount,
            "cart",
            2,
            JSON.stringify(userData),
            "Wallet"
          );
          setErrorDuringPay("Something went wrong try again.");
        }
      }
    }

    if (selectedPayMode.card) {
      localStorage.setItem("redirectUrl", APP_ROUTES.CARTREVIEW);

      localStorage.setItem("patient", JSON.stringify(patient));
      let firstName = userData.firstName.split(/\s/).join("");
      localStorage.setItem("payMode", "CC");
      localStorage.setItem("saveObj", JSON.stringify(cartList));
      trackPayment(
        userData.code,
        cartList,
        "",
        "",
        "Payment Initiated",
        totalAmount,
        "cart",
        0,
        JSON.stringify(userData),
        "Payu"
      );
      // redirectAfterTxn("data.data.data.transcationId");
      const url =
        process.env.REACT_APP_PAYU_BASEURL +
        `patientId=${userData.code}&amount=${totalAmount}&firstname=${firstName}&email=${userData.email}&phone=${userData.mobile}&productinfo=cartpayment&udf1=Patient&service_provider=payu_paisa`;
      window.location.replace(url);
    }
  };

  const setTcode = (val) => {
    setCode(val);
  };

  // if (localStorage.getItem("trancationid")) {
  //   return (
  //     <div className="flex flex-wrap justify-center">
  //       <div className="loader float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-20 w-20" />
  //     </div>
  //   );
  // }

  // if (localStorage.getItem("trancationid")) {
  //   return (
  //     <div className="flex flex-wrap justify-center">
  //       <div className="loader float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-20 w-20" />
  //     </div>
  //   );
  // }

  console.log(selectedSavedPrescription, "oiashfouasgucgasivgasiiasbgvuauvsa");


  const getMedicineDiscountPrice = () =>{
    let result = parseFloat(cartList.patientMedicineOrder[0].amount) - parseFloat(cartList.patientMedicineOrder[0].totalAmount)
    return result.toFixed(2)
  }

  const getLabDiscountPrice = () =>{
    let result = parseFloat(cartList.patientLabTestsOrder[0].totalAmount) - parseFloat(cartList.patientLabTestsOrder[0].paidAmount)
    return result.toFixed(2)
  }

  return (
    <>
      {isLoading && (
         <Loader />
         )}
      {/* breadcrumbs */}
      <Layouts>
        <ToastContainer />
        <section className="bg-lightGray md:py-8 py-6 md:px-28">
          {/* {showLoader && <Loader />} */}
          <div className="flex justify-center mb-5">
            <CartTimeLine />
          </div>
          <div className="md:flex gap-5">
            <div className="md:w-[65%] md:min-h-[70vh]  h-full bg-White p-8 rounded-lg">
              <SectionContainer
                link={""}
                // title={"Items in your cart"}
                subtitle={""}
                seeAll={"hide"}
              />
              <div className="mt-5 border border-gray  rounded-lg p-3 md:p-5" style={{border: deviceType == "Mobile" ? "0.5px solid #E8E8E8" : ""}}>
                <div className="hidden md:flex justify-between">
                  <div>
                    <p className=" text-base font-semibold  text-primaryColor">
                      MEDICINES IN YOUR CART{" "}
                      {/* <span className="text-secondaryColor">(2)</span> */}
                    </p>
                    <p className="font-medium text-xs text-textGray mt-1 flex">
                      ( Item(s) marked{" "}
                      <img src={RXicon} className=" h-4 mx-1" /> need a
                      prescription )
                    </p>
                  </div>
                  {isMed ? (
                    <MinusCircleOutlined
                      onClick={onChangeMed}
                      className="text-2xl text-textGray"
                    />
                  ) : (
                    <PlusCircleOutlined
                      onClick={onChangeMed}
                      className="text-2xl text-textGray"
                    />
                  )}
                </div>
                {isMed && (
                  <div>
                    {patientMedicineOrder?.length && (
                      <MedicineList
                        patientMedicineOrder={patientMedicineOrder}
                        // deleteMedicine={deleteCartItem}
                        // quantityUpdate={quantityUpdate}
                        readOnly={true}
                      />
                    )}
                  </div>
                )}
              </div>
              {cartList?.patientMedicineOrder && cartList?.patientMedicineOrder?.length && cartList?.patientMedicineOrder[0]?.membershipId !== '0' ? <div className="block md:hidden px-4 py-3 mt-4" style={{background: "radial-gradient(64.4% 108.13% at 50% 50%, #007ADB 0%, #004175 100%)", borderRadius: 8}} >
                <p className="text-xs font-medium" style={{color: "#ffff"}} >Yayy 🎉 !! You get an extra ₹{getMedicineDiscountPrice()} discount on your medicines with CureBay Membership Plan.</p>
              </div> : null}
              { cartList?.patientLabTestsOrder && cartList?.patientLabTestsOrder?.length  ? <div className="mt-5 border border-gray  rounded-lg p-3 md:p-5" style={{border: deviceType == "Mobile" ? "0.5px solid #E8E8E8" : ""}}>
              <div className="hidden md:flex justify-between">
                  <div>
                    <p className=" text-base font-semibold mb-5 text-primaryColor">
                      LAB TEST 
                      {/* <span className="text-secondaryColor">(2)</span> */}
                    </p>
                  </div>
                </div>
                {!isDia && (
                  <div className="my-5 mx-3 flex justify-between">
                    <p className="font-medium fonr-rubik text-gray-primary text-md ">
                      Diagnostics Prescribed
                    </p>
                    {isDia ? (
                      <MinusIcon onClick={onChangeDia} className="w-6 h-6" />
                    ) : (
                      <PlusIcon onClick={onChangeDia} className="w-6 h-6" />
                    )}
                  </div>
                )}
                {isDia && (
                  <div className="mb-5">
                    {patientLabTestsOrder &&
                      patientLabTestsOrder?.length > 0 &&
                      patientLabTestsOrder.map((res, i) => (
                        <LabCart
                          labDetails={res}
                          data={res?.patientLabTestsOrderDetailsList}
                          id={res.id}
                          patientLabTestsOrder={patientLabTestsOrder}
                          // deleteTest={deleteCartItem}
                          // setPreferredDate={setPreferredDate}
                          // setPreferredLabDate={setPreferredLabDate}
                          readOnly={true}
                        />
                      ))}
                  </div>
                )}
              </div> : null }
              {cartList?.patientLabTestsOrder && cartList?.patientLabTestsOrder?.length && cartList?.patientLabTestsOrder[0]?.membershipId !== '0' ? <div className="block md:hidden px-4 py-3 mt-4" style={{background: "radial-gradient(64.4% 108.13% at 50% 50%, #007ADB 0%, #004175 100%)", borderRadius: 8}} >
                <p className="text-xs font-medium" style={{color: "#ffff"}} >Yayy 🎉 !! You get an extra ₹{getLabDiscountPrice()} discount on your lab tests with CureBay Membership Plan </p>
              </div> : <></>}
            </div>
           
            <div className="md:w-[35%] md:min-h-[70vh]  h-full bg-White p-8 rounded-lg">
              {/* <CartPrice
            handleProceed = {DoPayment}
            cartData = {cartList}
            // getCartData = {handlegetCartData}
            patient = {patient}
            readOnly = {true}
            buttonText = "Payment"
          /> */}
              {loadingnew ? null : (
                <CartPrice
                  handleProceed={DoPayment}
                  cartData={cartList}
                  // getCartData = {handlegetCartData}
                  patient={patient}
                  readOnly={true}
                  buttonText="Payment"
                />
              )}
            </div>
          </div>
          <div className="lg:flex mb-20 mt-8 md:pl-4 md:mr-5">
            <div className="lg:w-8/12"></div>
          </div>
        </section>
      </Layouts>
      {isUserReadyToPay ? (
        <Modal
          title="Select Payment Mode"
          centered={true}
          footer={false}
          className="commonModal"
          open={isUserReadyToPay}
          width="30%"
          // onOk={handleHideClick}
          onCancel={() => setIsUserReadyToPay(false)}

        >
          <div>
            <div>
              <div
                className="flex bg-lightBlue text-textGray font-medium text-sm justify-between items-center py-3 px-6 rounded mb-2 cursor-pointer"
                style={{
                  background: selectedPayMode.wallet ? "#EEFFF7" : "",
                  border: selectedPayMode.wallet ? "2px solid #66B889" : "",
                }}
                onClick={() =>
                  setSelectedPayMode({ wallet: true, card: false })
                }
              >
                CureBay wallet - Rs{" "}
                {walletinfo?.patientwalletinfoData?.balance &&
                  walletinfo?.patientwalletinfoData?.balance.toFixed(2)}{" "}
                <i className="pi pi-angle-right"></i>
              </div>
            </div>
            <div
              className="flex bg-lightBlue text-textGray font-medium text-sm justify-between items-center py-3 px-6 rounded mb-2 cursor-pointer"
              style={{
                background: selectedPayMode.card ? "#EEFFF7" : "",
                border: selectedPayMode.card ? "2px solid #66B889" : "",
              }}
              onClick={() => setSelectedPayMode({ wallet: false, card: true })}
            >
              Debit/Credit Cards/UPI & Others{" "}
              <i className="pi pi-angle-right"></i>
            </div>
            <div className="text-center">
              <button
                disabled={loadingDuringPayment}
                className=" font-normal bg-secondaryColor text-White text-sm py-3 px-6 rounded mt-4 w-full"
                onClick={DoPay}
              >
                Pay
                {/* {loadingDuringPayment && (
                  <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                )} */}
              </button>
            </div>
            {errorDuringPay.length > 0 ? (
              <div style={{ color: "red" }} className="font-normal text-sm">
                {" "}
                {errorDuringPay}{" "}
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal>
      ) : (
        ""
      )}
      <PrevBrowsedMedicine />
    </>
  );
}
export default CartReview;
