import React, { useState, useEffect, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import sampleSurgery from "../../../Assets/Images/Cataract.svg";
import sampleSymptoms from "../../../Assets/Images/symptomsSample.svg";
import { Form, InputNumber, Select, message } from "antd";
import http from "../../../Redux/Services/http-common";
import Layouts from "../../Layouts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Vericocele from "./SurgeryDetail/varicocele.jsx";
import Cataract from "./SurgeryDetail/Cataract.jsx";
import Cystoscopy from "./SurgeryDetail/Cystoscopy.jsx";
import Phimosis from "./SurgeryDetail/Phimosis.jsx";
import Fissures from "./SurgeryDetail/Fissures.jsx";
import Hydrocele from "./SurgeryDetail/Hydrocele.jsx";
import Lasik from "./SurgeryDetail/Lasik.jsx";
import Lipoma from "./SurgeryDetail/Lipoma.jsx";
import LipoSuction from "./SurgeryDetail/LipoSuction.jsx";
import Fistula from "./SurgeryDetail/Fistula.jsx";
import Hernia from "./SurgeryDetail/Hernia.jsx";
import Gynecomastia from "./SurgeryDetail/Gynecomastia.jsx";
import KidneyStones from "./SurgeryDetail/KidneyStones.jsx";
import RhinoPlasty from "./SurgeryDetail/Rhinoplasty.jsx";
import VaricoseVeins from "./SurgeryDetail/VaricoseVeins.jsx";
import Piles from "./SurgeryDetail/Piles.jsx";
import Appendicitis from "./SurgeryDetail/Appendicitis.jsx";
import HipReplacement from "./SurgeryDetail/HipReplacement.jsx";
import GallStone from "./SurgeryDetail/GallStone.jsx";
import KneeReplacement from "./SurgeryDetail/KneeReplacement.jsx";
function SurgeryDetails() {
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [surgeryData, setSurgeryData] = useState({});
  const [surgeryObj, setSurgeryObj] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const searchParam = params.search;
  const extractedNumber = searchParam.split("--")[1];
  console.log(extractedNumber);
  const uniqueID = params.search.split("--")[0];

  console.log(params, "params");

  const getsurgeryDetail = async () => {
    try {
      const config = {
        method: "get", // HTTP method
        url: `${process.env.REACT_APP_CMS_BASEURL}surgeries/${extractedNumber}?populate=fourthComp,fourthComp.points,symptoms.subdata,firstComp,thirdComp,thirdComp.points,thirdComp.points.sub_points,typesSubPoint,secondComp,secondComp.points,procedure,benefits.benefitsSub,Preventcomp.title,Preventcomp.title2,develop,develop.icons,doctor,typesofsur.doctor,doctor.doctor,DiagnoseComp,DiagnoseComp.title,DiagnoseComp.title2,fifthComp,fifthComp.points,sixthComp.points,sixthComp.points.sub_points,seventhComp,seventhComp.points,eightComp,eightComp.points.sub_points,ninthComp,ninthComp.points,tenthcomp,tenthcomp.points,tenthcomp.points.subpoints,elevencomp,elevencomp.sp,twelthcomp`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      // Axios request with manual configuration
      let response = await axios(config);

      setSurgeryData(response?.data?.data); // Setting surgery data in state
      console.log(response?.data?.data, "SurgerDetails");
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    getsurgeryDetail();
  }, []);

  const renderHeadingWithSurgeryName = (heading, surgeryName) => {
    if (heading && surgeryName) {
      // Split the heading text based on the surgery name
      const parts = heading.split(surgeryName);
      return (
        <>
          {parts[0]}
          <span className="text-secondaryColor">{surgeryName}</span>
          {parts[1]}
        </>
      );
    }
    return heading;
  };

  const renderHeadingWithHighlightedText = (heading, termToHighlight) => {
    if (heading && termToHighlight) {
      const parts = heading.split(termToHighlight);
      return (
        <>
          {parts[0]}
          <span className="text-secondaryColor">{termToHighlight}</span>
          {parts[1]}
        </>
      );
    }
    return heading;
  };

  const renderSurgeries = (data) =>{
    if(data?.surgery_id === '21'){
      return <Cataract data = {data} />
    }else if(data?.surgery_id === '9'){
      return <Vericocele data = {data} />
    }else if(data?.surgery_id === '16'){
      return <Cystoscopy data = {data} />
    } else if (data?.surgery_id === '20'){
      return <Phimosis data={data} />
    } else if (data?.surgery_id === '3'){
      return <Fissures data={data} />
    } else if (data?.surgery_id === '6'){
      return <Hydrocele data={data} />
    } else if (data?.surgery_id === '19'){
      return <Lasik data={data} />
    }else if (data?.surgery_id === '8'){
      return <Lipoma data={data} />
    } else if (data?.surgery_id === '18'){
      return <LipoSuction data={data} />
    }  else if (data?.surgery_id === '2'){
      return <Fistula data={data} />
    } else if (data?.surgery_id === '13'){
      return <Hernia data={data} />
    } else if (data?.surgery_id === '12'){
      return <Gynecomastia data={data} />
    } else if (data?.surgery_id === '5'){
      return <KidneyStones data={data} />
    } else if (data?.surgery_id === '17'){
      return <RhinoPlasty data={data} />
    } else if (data?.surgery_id === '10'){
      return <VaricoseVeins data={data} />
    } else if (data?.surgery_id === '1'){
      return <Piles data={data} />
    } else if (data?.surgery_id === '11'){
      return <Appendicitis data={data} />
    } else if (data?.surgery_id === '14'){
      return <HipReplacement data={data} />
    }  else if (data?.surgery_id === '7'){
      return <GallStone data={data} />
    }  else if (data?.surgery_id === '15'){
      return <KneeReplacement data={data} />
    }
  }

  return (
    <Layouts>
      <ToastContainer />

      <div className="bg-primaryColor text-white py-10 px-4 md:px-16">
        <div className="container mx-auto flex flex-col md:flex-row items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-3xl md:text-4xl font-bold mb-4 text-White">
                {surgeryData?.attributes?.firstComp.heading}
            </h1>
            <p className="text-base md:text-lg mb-6 text-White text-left">
              {surgeryData?.attributes?.firstComp?.description}
            </p>
          </div>
          <div className="w-full md:w-1/2 flex justify-center md:justify-end md:ml-4">
            <img
              src={surgeryData?.attributes?.firstComp?.image_url}
              alt="Cataract patient"
              className="rounded-lg shadow-md max-w-full"
            />
          </div>
        </div>
      </div>

      {/* Symptoms of Cataract Section */}
      {surgeryData?.attributes?.secondComp ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#EBF8FF" }}
        >
          <div className="container mx-auto">
            <h2
              className="text-2xl md:text-3xl font-bold mb-8 text-center"
              style={{ color: "rgb(0, 93, 141)" }}
            >
              {/* {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.secondComp?.heading,
                surgeryData?.attributes?.surgeryName
              )} */}
              {surgeryData?.attributes?.secondComp?.heading}
            </h2>
            <h6 className=" mb-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}} >{surgeryData?.attributes?.secondComp?.subtitle}</h6>
            <div className={`grid ${surgeryData?.attributes?.secondComp?.points.length ? "grid-cols-1" : "grid-cols-2"} md:grid-cols-${surgeryData?.attributes?.secondComp?.points?.length < 3 ? surgeryData?.attributes?.secondComp?.points?.length : 5 } gap-4 md:gap-8 justify-center`}>
              {surgeryData?.attributes?.secondComp?.points.map(
                (symptom, index) => (
                  <div
                    className="p-4 rounded-lg "
                    key={index}
                    style={{backgroundColor:"#ffff"}}
                  >
                    <img
                      src={symptom?.icon_url}
                      // alt={symptom?.description}
                      className="mx-auto mb-4"
                    />
                    <p
                      className="text-sm md:text-base font-medium text-center"
                      style={{ color: "rgba(0, 65, 113, 1)" }}
                    >
                      {symptom.heading ? (
  <>
    {symptom.heading}
    <br />
    {symptom.description || " "}
  </>
) : (
  symptom.description
)}
                    </p>
                    <ul className="pl-4 mt-4" style={{listStyleType: "disc",color:"#004171"}}> 
                      {symptom?.pnt1 ? <li className="py-2" >{symptom?.pnt1}</li> : null}
                      {symptom?.pnt2 ? <li className="py-2" >{symptom?.pnt2}</li> : null}
                      {symptom?.pnt3 ? <li className="py-2" >{symptom?.pnt3}</li> : null}
                      {symptom?.pnt4 ? <li className="py-2" >{symptom?.pnt4}</li> : null}
                    </ul>
                  </div>
                  
                )
              )}
            </div>
            {surgeryData?.attributes?.secondComp?.note ? <h6 className=" mb-4 text-center mt-8" style={{color: "#676C80", fontSize: 16, fontWeight: 500}} >{surgeryData?.attributes?.secondComp?.note}</h6> : null}
          </div>
        </div>
      ) : (
        <p>No symptoms data available</p>
      )}

      {/* Types of Cataract Section */}
      {surgeryData?.attributes?.thirdComp?.points?.length ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div
            className="container mx-auto"
            style={{ color: "rgb(0, 93, 141)" }}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-12 text-center">
              {/* {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.thirdComp?.heading,
                surgeryData?.attributes?.surgeryName
              )} */}
              {surgeryData?.attributes?.thirdComp.points[0].mainTitle}
            </h2>
            {surgeryData?.attributes?.thirdComp.points[0]?.mainSubTitle && <h6 className=" mb-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}} >{surgeryData?.attributes?.thirdComp.points[0]?.mainSubTitle}</h6>}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-0 md:mx-16">
              {surgeryData?.attributes?.thirdComp?.points.map((type, index) => {
                return (<div className="rounded-xl py-4 px-8" style={{border: "0.5px solid #E3E3E3"}} >
                <p className="text-xl font-semibold mb-2" style={{color:"#005D8D"}} >{type.title}</p>
                <p className="text-base font-medium" style={{color:"#676C80"}}>{type.description}</p>
                <ul style={{listStyleType: "disc"}} >
                {type?.sub_points?.length ? type.sub_points.map(res =>{
                  return <li className="text-base font-normal py-2" style={{color:"#676C80"}}> {res.description}</li>
                }) : null}
              </ul>
                </div>)
              })}
            </div>
          </div>
          <h4 className=" my-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}}>{surgeryData?.attributes?.thirdComp?.points[0]?.mainnotes}</h4>
        </div>
      ) : (
        <p></p>
      )}

      {surgeryData?.attributes?.fourthComp ? (
        <div
          className="py-10 px-4 md:px-16 pb-24"
          style={{ backgroundColor: "#F0F7FF" }}
        >
          <div className="container mx-auto text-center">
            <h2
              className="text-2xl md:text-3xl font-bold mb-8"
              style={{ color: "rgb(0, 93, 141)" }}
            >
              {surgeryData?.attributes?.fourthComp?.title}
            </h2>
            <h6 className=" mb-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}} >{surgeryData?.attributes?.fourthComp.subTitle}</h6>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8">
              {surgeryData?.attributes?.fourthComp?.points.map(
                (cause, index) => (
                  <div
                    className="p-4 rounded-lg"
                    key={index}
                    style={{backgroundColor: "#ffff"}}
                  >
                    <img
                      src={cause.icon_url} // You can dynamically change the icon if icon_url is available
                      // alt={cause.description}
                      className="mx-auto mb-4"
                    />
                    {cause?.title && <p
                      className="text-sm md:text-base font-medium"
                      style={{ color: "rgba(0, 65, 113, 1)" }}
                    >
                      {cause.title}
                    </p>}
                    <p
                      className="text-sm md:text-base font-medium text-left text-center"
                      style={{ color: "rgba(0, 65, 113, 1)" }}
                    >
                      {cause.description}
                    </p>
                    <ul className="pl-4 mt-4 text-left" style={{listStyleType: "disc",color:"#004171"}}> 
                      {cause?.subPoint1_title ? <li className="py-2 font-normal" ><span className="font-semibold" style={{color:"#004171"}} >{cause?.subPoint1_title}</span>{cause?.subPoint1_description}</li> : null}
                      {cause?.subPoint2_title ? <li className="py-2" ><span className="font-semibold" style={{color:"#004171"}} >{cause?.subPoint2_title}</span>{cause?.subPoint2_description}</li> : null}
                      {cause?.subPoint3_title ? <li className="py-2" ><span className="font-semibold" style={{color:"#004171"}} >{cause?.subPoint3_title}</span>{cause?.subPoint3_description}</li> : null}
                      {cause?.subPoint4_title ? <li className="py-2" ><span className="font-semibold" style={{color:"#004171"}} >{cause?.subPoint4_title}</span>{cause?.subPoint4_description}</li> : null}
                      {cause?.subPoint5_title ? <li className="py-2" ><span className="font-semibold" style={{color:"#004171"}} >{cause?.subPoint5_title}</span>{cause?.subPoint5_description}</li> : null}
                      {cause?.subPoint6_title ? <li className="py-2" ><span className="font-semibold" style={{color:"#004171"}} >{cause?.subPoint6_title}</span>{cause?.subPoint6_description}</li> : null}
                  
                    </ul>
                  </div>
                )
              )}
            </div>
            {surgeryData?.attributes?.fourthComp?.note ? <p className="text-center mt-8" style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} >{surgeryData?.attributes?.fourthComp?.note}</p> : null}
          </div>
        </div>
      ) : (
        <p></p>
      )}
      {surgeryData?.attributes?.fifthComp ? (
        <div className="bg-primaryColor text-white py-10 px-4 md:px-16 md:pb-24">
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row md:items-start">
              <div className="md:w-1/2 mb-6 md:mb-0">
                <h2 className="text-xl md:text-2xl font-semibold md:mb-4 text-White mr-4">
                  {surgeryData?.attributes?.fifthComp.title}
                </h2>
              </div>

              <div className="md:w-1/2">
                <p className="text-sm md:text-base text-White mb-8 font-light">
                {surgeryData?.attributes?.fifthComp.description}
                </p>
                {surgeryData?.attributes?.fifthComp.points &&
                  <ul style={{listStyleType: "disc"}} >
                  {surgeryData?.attributes?.fifthComp.points.map(res =>{
                    return <li className="text-base font-light mb-4" style={{color:"#ffff"}}><span className="font-semibold" >{res?.title}</span> {res.description}</li>
                  })}
                </ul>
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}
      {surgeryData?.attributes  ? renderSurgeries(surgeryData?.attributes) : null}
{/* 

      {surgeryData?.attributes?.benefits && (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div className="container mx-auto flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 text-left">
              <h2
                className="text-2xl md:text-3xl font-bold mb-8"
                style={{ color: "rgb(0, 93, 141)" }}
              >
                {surgeryData?.attributes?.benefits?.heading}
              </h2>

              <p
                className="text-lg text-gray-600 mb-6 mr-4"
                style={{ color: "rgb(0, 93, 141)" }}
              >
                {surgeryData?.attributes?.benefits?.description}
              </p>
            </div>

            <div className="w-full md:w-1/2 grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8 md:mt-0">
              {surgeryData?.attributes?.benefits?.benefitsSub?.map(
                (benefit, index) => (
                  <div
                    key={index}
                    className="bg-blue-50 p-4 rounded-lg shadow-md text-center"
                  >
                    {benefit.icon_url && (
                      <img
                        src={benefit.icon_url}
                        alt={benefit.label}
                        className="mx-auto mb-4"
                      />
                    )}
                    <p
                      className="text-sm md:text-base font-medium text-gray-700"
                      style={{ color: "rgba(0, 65, 113, 1)" }}
                    >
                      {benefit.label}
                    </p>
                    {benefit.Description && (
                      <p className="text-xs md:text-sm text-gray-600 mt-2">
                        {benefit.Description}
                      </p>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}

      {surgeryData?.attributes?.followUp ? (
        <div className="bg-primaryColor text-white py-10 px-4 md:px-16 mb-10">
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row md:items-center">
              <div className="md:w-1/2 mb-6 md:mb-0">
                <h2 className="text-2xl md:text-3xl font-bold mb-4 text-White">
                  {renderHeadingWithHighlightedText(
                    surgeryData?.attributes?.followUp?.heading,
                    "Follow-Up Exams"
                  )}
                </h2>
              </div>

              <div className="md:w-1/2">
                <p className="text-base md:text-lg text-White">
                  {surgeryData?.attributes?.followUp?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}
      {surgeryData?.attributes?.develop ? (
        <div className=" py-10 px-4 md:px-16" style={{ background: "#EBF8FF" }}>
          <div className="container mx-auto">
            <h2
              className="text-2xl md:text-3xl font-bold mb-8 text-blue-900"
              style={{ color: "rgba(0, 65, 113, 1)" }}
            >
              {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.develop[0]?.heading,
                surgeryData?.attributes?.surgeryName
              )}
            </h2>

            <p
              className="text-gray-700 text-base md:text-lg mb-6"
              style={{ color: "rgb(0, 93, 141)" }}
            >
              {surgeryData?.attributes?.develop[0]?.description}
            </p>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {surgeryData?.attributes?.develop[0]?.icons?.map(
                (item, index) => (
                  <div
                    key={index}
                    className="bg-white p-4 rounded-lg shadow-md text-center"
                  >
                    <img
                      src={item.icon_url}
                      alt={`Icon ${index + 1}`}
                      className="mx-auto mb-4"
                    />
                    <p className="text-sm md:text-base font-medium text-gray-700">
                      Icon {index + 1} description here
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}

      {surgeryData?.attributes?.doctor ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div
            className="container mx-auto text-center"
            style={{ color: "rgb(0, 93, 141)" }}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-8">
              {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.doctor[0]?.Heading,
                surgeryData?.attributes?.surgeryName
              )}
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {surgeryData?.attributes?.doctor[0]?.doctor?.map(
                (doctor, index) => (
                  <div
                    key={doctor.id}
                    className="bg-white p-6 rounded-lg border-2 border-gray-300 shadow-lg"
                  >
                    <img
                      src={doctor.icon_url}
                      alt={`${doctor.title} icon`}
                      className="mb-4 w-16 h-16 object-cover mx-auto"
                    />
                    <h3
                      className="text-lg font-bold mb-4 text-center"
                      style={{ color: "rgb(0, 93, 141)" }}
                    >
                      {doctor.title}
                    </h3>
                    <p
                      className="text-left text-gray-700"
                      style={{ color: "#676C80" }}
                    >
                      {doctor.description}{" "}
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}
      {surgeryData?.attributes?.Preventcomp ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div
            className="container mx-auto text-center"
            style={{ color: "rgb(0, 93, 141)" }}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-8">
              {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.Preventcomp?.title,
                surgeryData?.attributes?.surgeryName
              )}
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {surgeryData.attributes.Preventcomp.title2.map((doctor) => (
                <div
                  key={doctor.id}
                  className="bg-white p-6 rounded-lg border-2 border-gray-300 shadow-lg"
                >
                  <img
                    src={doctor.name}
                    alt="Prevention Icon"
                    className="w-10 h-10 mx-auto"
                  />
                  <h3
                    className="text-lg  mb-4"
                    style={{ color: "rgba(0, 65, 113, 1)" }}
                  >
                    {doctor.descriptn}
                  </h3>

                  <p
                    className="text-left text-gray-700"
                    style={{ color: "#676C80" }}
                  >
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}

      {surgeryData?.attributes?.typesofsur &&
      surgeryData.attributes.typesofsur.length > 0 ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#F0F7FF" }}
        >
          <div className="container mx-auto">
            <h2
              className="text-2xl md:text-3xl font-bold mb-8"
              style={{ color: "rgb(0, 93, 141)" }}
            >
              {renderHeadingWithHighlightedText(
                surgeryData?.attributes?.typesofsur[0].Heading,
                surgeryData?.attributes?.surgeryName
              )}
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-12">
              {surgeryData.attributes.typesofsur[0].doctor?.map(
                (doc, index) => (
                  <div
                    className="flex items-start bg-white p-4 rounded-lg shadow-md"
                    key={index}
                  >
                    {doc.icon_url && (
                      <img
                        src={doc.icon_url}
                        alt={doc.title || "Doctor icon"}
                        className="w-8 h-8 mr-4 mt-1"
                      />
                    )}
                    <div>
                      <p
                        className="text-lg font-light text-gray-800"
                        style={{ color: "rgba(0, 65, 113, 1)" }}
                      >
                        {doc.title}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center text-gray-500 mt-4"></p>
      )} */}
    </Layouts>
  );
}

export default SurgeryDetails;