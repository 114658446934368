// import React, { useState } from 'react';
// import SmartVitalCheckerHeroIcon from '../../../Assets/Images/smartVitals/smart-vitals-checker-hero-icon.png';
// import { Form, Input, Select, Radio, Button, Row, Col, Typography, Divider, Modal } from 'antd';
// import { InfoCircleOutlined } from '@ant-design/icons';
// import FormComponent from './FormComponent';
// import VideoCaptureComponent from './VideoCapture';
// import Requirementimg from '../../../Assets/Images/requirements_ai.svg';
// import { verifyLogOtp } from '../../../Redux/Action/userActions';

// const { Title, Text } = Typography;
// const { Option } = Select;

// const SmartVitalsChecker = () => {

//   const [form] = Form.useForm();
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [captureVideo, setCaptureVideo] = useState(false);
//   const [formData, setFormData] = useState(null); // New state to hold form data

//   const onFinish = (values) => {
//     setFormData(values); // Save form data to state
//     showModal();
//   };

//   const showModal = () => {
//     setIsModalVisible(true);
//   };

//   const handleOk = () => {
//     setIsModalVisible(false);
//     setCaptureVideo(true);
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   return (
//     <>
//       {!captureVideo ? (
//         <FormComponent onFinish={onFinish} />
//       ) : (
//         <VideoCaptureComponent formData={formData} /> // Pass form data as props
//       )}

//       <Modal
//         title={null}
//         visible={isModalVisible}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         footer={null}
//         centered
//         bodyStyle={{ padding: '24px', borderRadius: '12px' }}
//         width={400}
//       >
//         <div className="">
//           <div className="flex flex-col items-start mb-4">
//             <img src={Requirementimg} alt="Icon" className="w-10 h-10 mr-2" />
//             <h2 className="text-lg font-semibold text-[#004171]">Requirements</h2>
//           </div>
//           <div className="border-b-2 border-dotted border-[#004171] mb-4"></div>
//           <div className="mb-4">
//             <h3 className="font-bold">Recording Requirements</h3>
//             <p className="text-sm text-gray-600">You'll need to record yourself for 30 seconds</p>
//           </div>
//           <div className="mb-4">
//             <h3 className="font-bold">Internet Requirements</h3>
//             <p className="text-sm text-gray-600">
//               Make sure you have access to an internet-connected smart device with a webcam
//             </p>
//           </div>
//           <button
//             className="primary_btn !w-full mt-5 !h-[50px] !text-lg"
//             onClick={() => {
//               handleOk();
//             }}
//           >
//             Ok, got it
//           </button>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default SmartVitalsChecker;

import React, { useState } from 'react';
import SmartVitalCheckerHeroIcon from '../../../Assets/Images/smartVitals/smart-vitals-checker-hero-icon.png';
import { Form, Input, Select, Radio, Button, Row, Col, Typography, Divider, Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import FormComponent from './FormComponent';
import VideoCaptureComponent from './VideoCapture';
import Requirementimg from '../../../Assets/Images/requirements_ai.svg';

const { Title, Text } = Typography;
const { Option } = Select;

const SmartVitalsChecker = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [captureVideo, setCaptureVideo] = useState(false);
  const [formData, setFormData] = useState(null); // New state to hold form data

  const onFinish = (values) => {
    setFormData(values); // Save form data to state
    showModal();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setCaptureVideo(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {!captureVideo ? (
        <FormComponent onFinish={onFinish} />
      ) : (
        <VideoCaptureComponent formData={formData} /> // Pass form data as props
      )}

      <Modal
        title={null}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        bodyStyle={{ padding: '24px', borderRadius: '12px' }}
        width={400}
      >
        <div className="">
          <div className="flex flex-col items-start mb-4">
            <img src={Requirementimg} alt="Icon" className="w-10 h-10 mr-2" />
            <h2 className="text-lg font-semibold text-[#004171]">Requirements</h2>
          </div>
          <div className="border-b-2 border-dotted border-[#004171] mb-4"></div>
          <div className="mb-4">
            <h3 className="font-bold">Recording Requirements</h3>
            <p className="text-sm text-gray-600">You'll need to record yourself for 30 seconds</p>
          </div>
          <div className="mb-4">
            <h3 className="font-bold">Internet Requirements</h3>
            <p className="text-sm text-gray-600">
              Make sure you have access to an internet-connected smart device with a webcam
            </p>
          </div>
          <button
            className="primary_btn !w-full mt-5 !h-[50px] !text-lg"
            onClick={() => {
              handleOk();
            }}
          >
            Ok, got it
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SmartVitalsChecker;
