import FAQ from "../FAQ"

const Faq = ({data}) =>{
    return(
        <section className="bg-primaryColor sm:mt-0 mt-20">
        <div className="container mx-auto sm:py-[90px] py-[10px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[50px]">
            Frequently Asked{" "}
            <span className="text-secondaryColor">Questions </span>
          </h2>
          <div className="md:w-[90%] mx-auto">
            <FAQ />
          </div>
        </div>
      </section>
    )

}

export default Faq