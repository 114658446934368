import React, { useEffect, useState } from "react";
import Layouts from "../Layouts";
import {
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  message,
} from "antd";
import prescriptionIcon from "../../Assets/Images/PrescriptionIcon.svg";
import CartDocImg from "../../Assets/Images/CarDocImg.svg";
import TextArea from "antd/es/input/TextArea";
import DiscountIcon from "../../Assets/Images/Icons/coupon.svg";
import medicines from "../../Assets/pharamacyImages/Medicines.jpg";
import TestTube from "../../Assets/Images/testTube.svg";
import EKO from "../../Assets/Images/EKO.jpg";
import { MdDelete } from "react-icons/md";
import {
  CloudUploadOutlined,
  RightOutlined,
  CloseOutlined,
  ShoppingCartOutlined,
  FileDoneOutlined,
  IdcardOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import Locationimg from "../../Assets/Images/Icons/locationblue.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  AddtoCart,
  getCartDetails,
} from "../../Redux/Action/cartPlaceOrderAction";
import Bottles from "../../Assets/pharamacyImages/Bottles.png";
import Capsules from "../../Assets/pharamacyImages/Capsules.png";
import Creams from "../../Assets/pharamacyImages/Creams.png";
import Curebay from "../../Assets/pharamacyImages/Curebay.png";
import Devices from "../../Assets/pharamacyImages/Devices.png";
import Dispensers from "../../Assets/pharamacyImages/Dispensers.png";
import Droplets from "../../Assets/pharamacyImages/Droplets.png";
import Emulsions from "../../Assets/pharamacyImages/Emulsions.png";
import Injections from "../../Assets/pharamacyImages/Injections.png";
import Lotions from "../../Assets/pharamacyImages/Lotions.png";
import Satchels from "../../Assets/pharamacyImages/Satchels.png";
import Tablet from "../../Assets/pharamacyImages/Tablets.png";
import defaultMed from "../../Assets/pharamacyImages/Medicines.jpg";
import { Dialog } from "primereact/dialog";
import http from "../../Redux/Services/http-common";
import moment from "moment";

// Redux/Action/cartPlaceOrderAction";
const { Search } = Input;

function CartPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [couponOpen, setCouponOpen] = useState(false);
  const [isMedicinesOpened, setIsMedicinesOpened] = useState(false);
  const arr = [0, 0, 0, 0];
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.authReducer.patientData);
  const cartList = useSelector((state) => state.cartReducer.cartList);
  // const isLoading = cartList?.patientLabTestsOrder;

  // cartList?.patientLabTestsOrder;
  // const patientMedicineOrder = []
  // cartList?.patientMedicineOrder;
  // const patientLabTestsOrder  =[]
  console.log(cartList);
  let labTests = [];
  let labTests1 = [];
  let medicines = [];
  let medicines1 = [];
  cartList?.forEach((element) => {
    if (element?.patientLabTestsOrder) {
      element.patientLabTestsOrder.forEach((element1) => {
        labTests1.push(element1);
      });
    }
    if (element?.patientMedicineOrder) {
      element.patientMedicineOrder.forEach((element1) => {
        medicines1.push(element1);
      });
    }
  });

  labTests1?.forEach((element) => {
    labTests.push(element.patientLabTestsOrderDetailsList);
  });
  medicines1?.forEach((element) => {
    medicines.push(element.patientMedicineOrderDetailsList);
  });
  // const patientLabTestsOrder  =labTests
  console.log(labTests);
  const patientLabTestsOrder = labTests.reduce(
    (acc, val) => acc.concat(val),
    []
  );
  const patientMedicineOrder = medicines.reduce(
    (acc, val) => acc.concat(val),
    []
  );

  console.log(patientMedicineOrder);

  const handleScroll = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const resolvePharamcyImageq = (Image) => {
    let PharamncyImage;
    if (Image.toUpperCase().includes("BOTTLE")) {
      PharamncyImage = Bottles;
    } else if (
      Image.toUpperCase().includes("CAPSULE") ||
      Image.toUpperCase().includes("CAPSULES")
    ) {
      PharamncyImage = Capsules;
    } else if (
      Image.toUpperCase().includes("CREAM") ||
      Image.toUpperCase().includes("CREAMS")
    ) {
      PharamncyImage = Creams;
    } else if (Image.toUpperCase().includes("CUREBAY")) {
      PharamncyImage = Curebay;
    } else if (Image.toUpperCase().includes("DEVICES")) {
      PharamncyImage = Devices;
    } else if (Image.toUpperCase().includes("DISPENSERS")) {
      PharamncyImage = Dispensers;
    } else if (Image.toUpperCase().includes("DROPLETS")) {
      PharamncyImage = Droplets;
    } else if (Image.toUpperCase().includes("EMULSIONS")) {
      PharamncyImage = Emulsions;
    } else if (
      Image.toUpperCase().includes("INJECTION") ||
      Image.toUpperCase().includes("INJECTIONS")
    ) {
      PharamncyImage = Injections;
    } else if (Image.toUpperCase().includes("LOTIONS")) {
      PharamncyImage = Lotions;
    } else if (Image.toUpperCase().includes("SATCHELS")) {
      PharamncyImage = Satchels;
    } else if (
      Image.toUpperCase().includes("TABLET") ||
      Image.toUpperCase().includes("TABLETS")
    ) {
      PharamncyImage = Tablet;
    } else {
      PharamncyImage = defaultMed;
    }
    console.log(PharamncyImage);
    return PharamncyImage;
  };
  useEffect(() => {
    dispatch(getCartDetails(userData.code));
    handleScroll();
  }, []);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {}, []);
  const [isUserReadyToPay, setIsUserReadyToPay] = useState(false);
  const [selectedPayMode, setSelectedPayMode] = useState({
    wallet: false,
    card: false,
  });
  const [errorDuringPay, setErrorDuringPay] = useState("");
  const [loadingDuringPayment, setLoadingDuringPayment] = useState(false);

  const walletinfo = useSelector((state) => state.patientwallet);

  const handleProceed = () => {
    setIsUserReadyToPay(true);
  };
  const DoPay = async () => {
    console.log(selectedPayMode, "sdoivhsdogvosdgvuidsgiuvgdsuivgsdiuvds");
    setErrorDuringPay("");
    let totalAmount = JSON.parse(localStorage.getItem("totalAmount"));
    let medicineTotalAmount = JSON.parse(
      localStorage.getItem("medicineTotalAmount")
    );
    if (!selectedPayMode.wallet && !selectedPayMode.card) {
      message.error("Please select payment mode.");
      return;
    }

    if (selectedPayMode.wallet) {
      if (totalAmount > walletinfo?.patientwalletinfoData?.balance) {
        message.error("Balance is not sufficient.");
        return;
      } else {
        setLoadingDuringPayment(true);
        trackPayment(
          userData.code,
          cartList,
          "",
          "",
          "Payment Initiated",
          totalAmount,
          "cart",
          0,
          JSON.stringify(userData),
          "Wallet"
        );
        let data = await http.put(
          `${process.env.REACT_APP_BASEURL}api/v1/payments/wallet/cart/${cartList?.cartId}`
        );
        console.log(data, "dsjfsduhfoshfoisd");
        if (data.status == 200) {
          setLoadingDuringPayment(false);
          localStorage.setItem("patient", JSON.stringify(userData.code));
          localStorage.setItem("saveObj", JSON.stringify(cartList));
          localStorage.setItem("payMode", "CureBay wallet");
          localStorage.setItem("walletstatus", "success");
          localStorage.setItem(
            "paymentRemarks",
            "Your Order placed successfully"
          );
          localStorage.setItem(
            "trancationid",
            JSON.stringify(data.data.data.transcationId)
          );
          trackPayment(
            userData.code,
            cartList,
            data.data.data.transcationId,
            "",
            "Payment done successfully",
            totalAmount,
            "cart",
            1,
            JSON.stringify(userData),
            "Wallet"
          );
          // redirectAfterTxn(data.data.data.transcationId);
        } else {
          setLoadingDuringPayment(false);
          trackPayment(
            userData.code,
            cartList,
            "",
            "",
            "Payment failed",
            totalAmount,
            "cart",
            2,
            JSON.stringify(userData),
            "Wallet"
          );
          setErrorDuringPay("Something went wrong try again.");
        }
      }
    }

    if (selectedPayMode.card) {
      // localStorage.setItem("redirectUrl", APP_ROUTES.CARTREVIEW);

      localStorage.setItem("patient", JSON.stringify(userData.code));
      let firstName = userData.firstName.split(/\s/).join("");
      localStorage.setItem("payMode", "CC");
      localStorage.setItem("saveObj", JSON.stringify(cartList));
      trackPayment(
        userData.code,
        cartList,
        "",
        "",
        "Payment Initiated",
        totalAmount,
        "cart",
        0,
        JSON.stringify(userData),
        "Payu"
      );
      // redirectAfterTxn("data.data.data.transcationId");
      const url =
        process.env.REACT_APP_PAYU_BASEURL +
        `patientId=${userData.code}&amount=${totalAmount}&firstname=${firstName}&email=${userData.email}&phone=${userData.mobile}&productinfo=cartpayment&udf1=Patient&service_provider=payu_paisa`;
      window.location.replace(url);
    }
  };
  const trackPayment = async (
    patientId,
    cartList,
    transactionId,
    payUId,
    payURemarks,
    amount,
    productInfo,
    status,
    payloadResponse,
    payMode
  ) => {
    let orderId = "";
    if (
      cartList.patientLabTestsOrder?.length &&
      cartList.patientMedicineOrder?.length
    ) {
      orderId = `${cartList.patientLabTestsOrder[0].orderId},${cartList.patientMedicineOrder[0].orderId}`;
    } else if (cartList.patientLabTestsOrder?.length) {
      orderId = `${cartList.patientLabTestsOrder[0].orderId}`;
    } else if (cartList.patientMedicineOrder?.length) {
      orderId = `${cartList.patientMedicineOrder[0].orderId}`;
    }
    const payloadForTrackPayment = {
      patientId: patientId,
      orderId: orderId,
      transactionId: transactionId,
      payUId: payUId,
      payURemarks: payURemarks,
      amount: parseFloat(amount),
      productInfo: productInfo,
      status: status,
      createdBy: patientId,
      payloadResponse: payloadResponse,
      payMode: payMode,
    };
    return await http.post(
      `${process.env.REACT_APP_BASEURL}api/v1/payments/payUStatus`,
      payloadForTrackPayment
    );
  };
  // const redirectAfterTxn = async (txnid) => {
  //   let labrocedurestatuscode;
  //   let labstatus;
  //   // let drugprocedureStatus ;
  //   // let drugStatus ;
  //     console.log(selectedPayMode, "sdvisdgovsdgugsduigvsiudguidsgv")
  //   let patient = JSON.parse(localStorage.getItem("patient"));
  //   let cartData = JSON.parse(localStorage.getItem("saveObj"));
  //   try {
  //     setIsLoading(true);
  //     let ttlAmt = 0;
  //     let amountFromPayU = JSON.parse(localStorage.getItem("totalAmount"))
  //     let medicineTotalAmount = JSON.parse(localStorage.getItem("medicineTotalAmount"))
  //     let txnid = localStorage.getItem("trancationid");
  //     let address = JSON.parse(localStorage.getItem("selectedAddress"))
  //     console.log(address, "sdvdssdvsdvuihsduivhsduvgsd")
  //     if (
  //       cartData.patientLabTestsOrder &&
  //       cartData.patientLabTestsOrder?.length > 0
  //     ) {
  //       // cartData.preferDateTime = selectedPreferredDate
  //       //   ? selectedPreferredDate
  //       //   : selectedHomeSamplePreferredDate
  //       //   ? null
  //       //   : moment().format("yyyy-MM-DD HH:mm:ss");
  //       cartData.collectionSampleDate = selectedHomeSamplePreferredDate
  //         ? selectedHomeSamplePreferredDate
  //         : selectedPreferredDate
  //           ? null
  //           : moment().format("yyyy-MM-DD HH:mm:ss");
  //       cartData.patientLabTestsOrder = cartData.patientLabTestsOrder.map(
  //         (labTestData) => {
  //           let totalamount = 0;
  //           if (
  //             labTestData.patientLabTestsOrderDetailsList &&
  //             labTestData.patientLabTestsOrderDetailsList?.length > 0
  //           ) {
  //             let array = [];

  //             labTestData.patientLabTestsOrderDetailsList.forEach((data) => {
  //               if (data.status == 1) {
  //                // data.patientId = patient.code ?? userData.code;
  //                 data.patientId =  selectpatientdata;
  //                 data.createdBy = selectpatientdata;
  //                 data.modifiedBy = selectpatientdata;
  //                 array.push(data);
  //               }
  //             });
  //             labTestData.patientLabTestsOrderDetailsList = array;
  //             labTestData.patientLabTestsOrderDetailsList.forEach((data) => {
  //               console.log(data, "dsfiohaofhwpie");
  //               totalamount += parseFloat(data.discountAmount)
  //                 ? parseFloat(data.discountAmount)
  //                 : data.amount;
  //             });
  //           }
  //           // labTestData.preferDateTime = selectedPreferredDate
  //           //   ? selectedPreferredDate
  //           //   : selectedHomeSamplePreferredDate
  //           //   ? null
  //           //   : moment().format("yyyy-MM-DD HH:mm:ss");
  //           labTestData.collectionSampleDate = selectedHomeSamplePreferredDate
  //             ? selectedHomeSamplePreferredDate
  //             : selectedPreferredDate
  //               ? null
  //               : moment().format("yyyy-MM-DD HH:mm:ss");

  //           labTestData.address1 = address.address1 ?? "";
  //           labTestData.address2 = address.address2 ?? "";
  //           labTestData.address3 = address.address3 ?? "";
  //           labTestData.payMode = localStorage.getItem("payMode");
  //           labTestData.deliveryAddress1 = address.address1 ?? "";
  //           labTestData.deliveryAddress2 = address.address2 ?? "";
  //           labTestData.deliveryAddress3 = address.address3 ?? "";
  //           labTestData.deliveryCity = address.city ?? "";
  //           labTestData.deliveryState = address.state ?? "";
  //           labTestData.deliveryZipcode = address.pinCode ?? "";
  //           labTestData.deliveryCountry = address.country ?? "";
  //           labTestData.patientId = patient.code ?? userData.code;
  //           labTestData.preferTime = JSON.parse(localStorage.getItem("preferValue"))
  //           labTestData.preferDateTime = `${JSON.parse(localStorage.getItem("preferDate"))} ${JSON.parse(localStorage.getItem("preferTime"))}:00:00`
  //           labTestData.latitude = address.lattitude;
  //           labTestData.longitude = address.longitude;
  //           labTestData.txnId = txnid;
  //           labTestData.paidAmount = totalamount.toFixed(2);
  //           labTestData.totalAmount = totalamount.toFixed(2);
  //           ttlAmt += totalamount;

  //           if(localStorage.getItem("paymentStatus") === "success")
  //           {

  //             labrocedurestatuscode=0
  //             labstatus=1
  //           }else if(localStorage.getItem("paymentStatus") === "pending")
  //           {
  //             labrocedurestatuscode=0
  //             labstatus=30
  //           }
  //           else if(localStorage.getItem("paymentStatus") === "failure")
  //           {
  //             labrocedurestatuscode=0
  //             labstatus=31
  //           }
  //           else if(localStorage.getItem("walletstatus") === "success")
  //           {
  //             labrocedurestatuscode = 0;
  //             labstatus = 1;
  //           }

  //           labTestData.procedureStatus = labrocedurestatuscode;
  //           labTestData.status = labstatus;
  //           if (labTestData.locationCurebayCenter == "Y") {
  //             // labTestData.procedureStatus = 1;
  //             labTestData.curebayCenter = "Y";
  //           }
  //           labTestData.patientId = selectpatientdata;
  //           labTestData.createdBy = selectpatientdata;
  //           labTestData.modifiedBy = selectpatientdata;
  //           labTestData.modifiedBy = selectpatientdata;
  //           labTestData.pinCode = address.pinCode ?? "";
  //           return labTestData;
  //         }
  //       );
  //     }

  //     if (
  //       cartData.patientMedicineOrder &&
  //       cartData.patientMedicineOrder?.length > 0
  //     ) {
  //       let totalAmount = 0;

  //       let drugPayload = { ...cartData.patientMedicineOrder[0] };

  //       drugPayload["patientMedicineOrderDetailsList"] = [];
  //       cartData.patientMedicineOrder = cartData.patientMedicineOrder.map(
  //         (product) => {
  //           // }
  //           if (
  //             product.patientMedicineOrderDetailsList &&
  //             product.patientMedicineOrderDetailsList?.length > 0
  //           ) {
  //             product.patientMedicineOrderDetailsList.forEach((data) => {
  //               totalAmount += data.totalAmount;
  //               // data.patientId = patient.code ?? userData.code;
  //               data.patientId = selectpatientdata;
  //               data.modifiedBy = selectpatientdata;
  //               data.createdBy = selectpatientdata;
  //               if (data.status == 1) {
  //                 drugPayload.patientId = patient.code ?? userData.code;

  //                 drugPayload.patientMedicineOrderDetailsList.push(data);
  //                 drugPayload.procedureStatus = 0;
  //               }
  //             });
  //           }
  //           let drugprocedureStatus ;
  //           let drugStatus ;
  //           /** Addresses Setup */
  //           if(localStorage.getItem("paymentStatus") === "success")
  //           {
  //             drugprocedureStatus = 1;

  //             drugStatus = 1;
  //           }else if(localStorage.getItem("paymentStatus") === "pending")
  //           {
  //             drugprocedureStatus = 30;
  //             drugStatus = 30;
  //           }
  //           else if(localStorage.getItem("paymentStatus") === "failure")
  //           {
  //             drugprocedureStatus = 31;
  //             drugStatus = 31;
  //           }
  //           else if(localStorage.getItem("walletstatus") === "success")
  //           {
  //             drugprocedureStatus = 1;
  //             drugStatus = 1;
  //           }
  //            product.procedureStatus = drugprocedureStatus;
  //            product.status = drugStatus;
  //            product.patientId = selectpatientdata;
  //            product.createdBy = selectpatientdata;
  //            product.modifiedBy = selectpatientdata;
  //           product.address1 = address.address1 ?? "";
  //           product.address2 = address.address2 ?? "";
  //           product.address3 = address.address3 ?? "";
  //           product.payMode = localStorage.getItem("payMode");
  //           product.deliveryAddress1 = address.address1 ?? "";
  //           product.deliveryAddress2 = address.address2 ?? "";
  //           product.deliveryAddress3 = address.address3 ?? "";
  //           product.deliveryCity = address.city ?? "";
  //           // product.patientId = patient.code ?? userData.code;
  //           product.amount = parseFloat(medicineTotalAmount);

  //           product.deliveryState = address.state ?? "";
  //           product.latitude = address.lattitude;
  //           product.longitude = address.longitude;
  //           product.deliveryZipcode = address.pinCode ?? "";
  //           product.deliveryCountry = address.country ?? "";
  //           product.txnId = txnid;
  //           product.docName = localStorage.getItem("prescriptionDoc")
  //             ? localStorage.getItem("prescriptionDoc")
  //             : null;
  //           product.paidAmount = totalAmount.toFixed(2);
  //           product.totalAmount = totalAmount.toFixed(2);
  //           ttlAmt += totalAmount;

  //           return product;
  //         }
  //       );

  //       drugPayload.totalAmount = totalAmount;
  //       drugPayload.txnId = txnid;
  //       drugPayload.docName = localStorage.getItem("prescriptionDoc")
  //         ? localStorage.getItem("prescriptionDoc")
  //         : null;
  //       drugPayload.paidAmount = totalAmount.toFixed(2);
  //       drugPayload.address1 = address.address1 ?? "";
  //       drugPayload.address2 = address.address2 ?? "";
  //       drugPayload.address3 = address.address3 ?? "";
  //       cartData.totalAmount = parseFloat(amountFromPayU).toFixed(2);
  //       drugPayload.deliveryAddress1 = address.address1 ?? "";
  //       drugPayload.deliveryAddress2 = address.address2 ?? "";
  //       drugPayload.deliveryAddress3 = address.address3 ?? "";
  //       drugPayload.deliveryCity = address.city ?? "";
  //       drugPayload.deliveryState = address.state ?? "";
  //       drugPayload.latitude = address.lattitude;
  //       drugPayload.longitude = address.longitude;
  //       drugPayload.deliveryZipcode = address.pinCode ?? "";
  //       drugPayload.deliveryCountry = address.country ?? "";
  //     }

  //     console.log("is cart is hai", cartData);
  //     if (code) {
  //     }
  //     let link;
  //     let remark = "";
  //     let amount = JSON.parse(localStorage.getItem("totalAmount"))
  //     if (
  //       cartData?.patientLabTestsOrder?.length &&
  //       cartData?.patientLabTestsOrder[0]?.patientLabTestsOrderDetailsList
  //         .length
  //     ) {
  //       link = "MY_ORDERS";
  //       remark = `Thank You. Your order status is success. Your Transaction ID for this transaction is ${txnid}. We have received a payment of Rs. ${amount}. ${cartData?.patientLabTestsOrder[0]?.patientLabTestsOrderDetailsList[0]
  //           ?.labTestType === "Radiology"
  //           ? "CureBay will call you shortly to schedule a convenient slot for appointment."
  //           : "CureBay will call you shortly to schedule a convenient slot for sample collection."
  //         }`;
  //     } else if (
  //       cartData?.patientMedicineOrder?.length &&
  //       cartData?.patientMedicineOrder[0]?.patientMedicineOrderDetailsList
  //         .length
  //     ) {
  //       link = "PATIENTMEDICINEORDERS";
  //       remark = `Thank You. Your order status is success. Your Transaction ID for this transaction is ${txnid}. We have received a payment of Rs. ${amount}. Your order will soon be shipped.`;
  //     } else {
  //       link = "PATIENTMEDICINEORDERS";
  //       remark = `Thank You. Your order status is success. Your Transaction ID for this transaction is ${txnid}. We have received a payment of Rs. ${amount}. Your order will soon be shipped.`;
  //     }
  //     console.log(cartData, "sdbvosdgogsdoudsocuhsdocds")
  //     await http.post("PatientCart/", { ...cartData, status: 0 })
  //     let compagincookies = cookieFinder(cookies?.__gtm_campaign_url)
  //     console.log(compagincookies, "compagincookies")
  //     if (compagincookies && process.env.NODE_ENV == "production") {
  //       console.log(compagincookies, "compagincookies")
  //       let compaignData = new FormData();
  //       compaignData.append('mobile', patient?.mobile);
  //       compaignData.append('utm_source', compagincookies?.utm_source)
  //       compaignData.append('utm_medium', compagincookies?.utm_medium)
  //       compaignData.append('utm_campaign', compagincookies?.utm_campaign)
  //       compaignData.append('utm_content', compagincookies?.utm_content)
  //       compaignData.append('user_name', patient?.firstName)
  //       compaignData.append('user_id', patient?.code)
  //       compaignData.append('purchase', amount)
  //       http.post('https://script.google.com/macros/s/AKfycbxQIT912J0-Wl9X31WE9UjnynP4Q8dXV45m5gHjtAiE372dJbpdrjWez6TmdDyhFfkX/exec', compaignData)
  //     }
  //     console.log(cartData, "sdvisdhvudsovusd");
  //     trackPayment(patient.code, cartData, txnid, "", remark, amountFromPayU, "cart", 3, localStorage.getItem("patient"), localStorage.getItem("payMode"))
  //     localStorage.removeItem("paymentStatus");
  //     localStorage.removeItem("saveObj");
  //     localStorage.removeItem("selectedAddress");
  //     localStorage.removeItem("payMode");
  //     localStorage.removeItem("patient");
  //     localStorage.removeItem("prescriptionDoc");
  //     localStorage.removeItem("couponCode");
  //     localStorage.removeItem("preferValue");
  //     localStorage.removeItem("preferDate");
  //     localStorage.removeItem("preferTime");
  //     dispatch(getCartDetails(userData?.code)).finally((res) => {
  //       dispatch(clearPrescription());
  //     });
  //     let orderTimer = setTimeout(() => {
  //       localStorage.removeItem("trancationid");
  //       localStorage.removeItem("totalAmount");
  //       clearTimeout(orderTimer);
  //     }, 1000);
  //     // history.push({
  //     //   pathname: APP_ROUTES.CART_ORDER_SUCESS,
  //     //   state: { remark: remark, link: link, cartData: cartData, amount: amount, txnid: txnid },
  //     // });
  //   } catch (err) {
  //     console.log(err, "sdbvosdgogsdoudsocuhsdocds")
  //     trackPayment(patient.code, cartData, "", "", "Payment done but order not placed", JSON.parse(localStorage.getItem("totalAmount")), "cart", 4, localStorage.getItem("patient"), localStorage.getItem("payMode"))
  //     localStorage.removeItem("paymentStatus");
  //     localStorage.removeItem("trancationid");
  //     localStorage.removeItem("saveObj");
  //     localStorage.removeItem("totalAmount");
  //     localStorage.removeItem("trancationid");
  //     localStorage.removeItem("selectedAddress");
  //     localStorage.removeItem("payMode");
  //     localStorage.removeItem("patient");
  //     localStorage.removeItem("prescriptionDoc");
  //     localStorage.removeItem("couponCode");
  //     localStorage.removeItem("preferValue");
  //     localStorage.removeItem("preferDate");
  //     localStorage.removeItem("preferTime");
  //   } finally {
  //     // setTimeout(() => {
  //     //   setIsLoading(false);
  //     // }, 1000);
  //   }
  // };
  return (
    <>
      <Layouts>
        <section className="bg-lightGray md:py-8 py-6 md:px-28">
          <div className="w-full w-82 bg-primaryColor rounded-md px-8 py-5">
            <div className="font-medium md:text-2xl text-White">Cart</div>
          </div>
          <div className="md:flex w-full gap-5 mt-5">
            <div className="md:w-[60%]  bg-White p-7 rounded-md">
              <Select
                placeholder="Get reports for"
                className="md:h-12 md:w-full text-base"
              >
                <option value="medication">Medication</option>
                <option value="condition">Condition</option>
                <option value="prescriptionType">Prescription Type</option>
              </Select>
              <div className="flex justify-between md:my-6 my-4">
                <div>
                  <div className="flex items-center gap-3 mb-2">
                    <img src={Locationimg} alt="images" />
                    <h4 className="text-lg underline text-primaryColor font-medium">
                      Parru Jagannathan
                    </h4>
                  </div>
                  <p className="text-textGray font-light text-sm">
                    117, B2 Jvl Towers, Nelson Manickam Road,
                    Aminjikarai,Chennai,Tamil Nadu, 600029
                  </p>
                </div>
                <div className="text-textGray text-sm underline cursor-pointer text-right">
                  Change
                </div>
              </div>
              <h5 className="text-primaryColor text-xl mb-5">
                Items in the cart
              </h5>
              {/* medicines */}
              {patientMedicineOrder &&
                patientMedicineOrder.map((medicine, idx) => (
                  <div className="bg-lightBlue w-full rounded-lg mb-3">
                    <div className="w-full flex justify-between items-center p-3">
                      <div className="flex items-center gap-8">
                        <img
                          src={resolvePharamcyImageq(
                            medicine.medicineTypeOfSell
                          )}
                          alt="tablet"
                          className="w-24 h-24"
                        />
                        <div>
                          <p className="text-base text-textGray font-medium">
                            {medicine.drugName}
                          </p>
                          <p className="text-xs mt-1 text-textGray mb-2">
                            {medicine.medicineTypeOfSell}
                          </p>
                          <p className="text-primaryColor font-medium text-base">
                            {medicine.unitPrice}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-center w-[120px] h-[35px] ">
                        <button className="w-[35%] h-full flex justify-center items-center text-2xl font-light border border-textGray rounded-s-md">
                          -
                        </button>
                        <div className="w-[50%] flex justify-center items-center h-full border-x-0 border-y border-textGray text-xl text-primaryColor font-medium">
                          1
                        </div>
                        <button className="w-[35%] h-full flex justify-center items-center  text-2xl font-light border border-textGray rounded-e-md">
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              {/* ----- */}
              {/* lab order */}
              {patientLabTestsOrder &&
                patientLabTestsOrder.map((lab, idx) => (
                  <div className="bg-lightBlue my-3 md:w-full rounded-lg">
                    <div className=" p-3">
                      <div className="flex items-center gap-8">
                        <div>
                          <img src={EKO} alt="avatar" className="w-24 h-24" />
                        </div>
                        <div>
                          <p className="lg:text-sm text-md  text-gray-primary font-medium">
                            EKO Imaging
                          </p>
                          <p className=" text-xs  text-gray-primary">Chennai</p>
                        </div>
                      </div>
                      <div>
                        <div className="md:w-full flex justify-between my-3 bg-white md:py-6 py-4 md:px-20">
                          <div className="flex items-center justify-between gap-5">
                            <div>
                              <img
                                src={TestTube}
                                alt="tablet"
                                className="h-12 w-12"
                              />
                            </div>
                            <div>
                              <p className="text-sm text-gray-800 flex items-center font-medium">
                                {lab?.labTestName}
                              </p>
                              <p class="text-sm text-gray-800 flex items-center font-medium mt-2">
                                EKO Imaging
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="flex items-center md:gap-6 justify-between">
                              <div className="w-1/7 flex mt-1">
                                <p className="text-textGray">Price : </p>
                                <p class="font-medium text-[17px]">
                                  ₹ {lab?.amount}
                                </p>
                              </div>
                              <div>
                                <MdDelete className="text-danger text-[30px]" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:flex items-center justify-evenly my-3">
                        <p className="text-primaryColor md:w-[30%] my-3">
                          Select your prefered Date and Time for sample
                          collection
                        </p>
                        <div className="md:w-[30%] border border-textGray py-2 px-3 rounded-lg flex items-center">
                          <p className="text-sm font-light text-textGray">
                            Select Date: <span className="text-danger">*</span>
                          </p>
                          <div>
                            <DatePicker />
                          </div>
                        </div>
                        <div className="md:w-[30%] border border-textGray py-2 px-3 rounded-lg flex items-center gap-1">
                          <p className="text-sm font-light text-textGray">
                            Select time: <span className="text-danger">*</span>
                          </p>
                          <Select
                            className="w-[8rem] text-secondaryColor font-medium text-[21px]"
                            options={[
                              {
                                value: "07 AM - 08 AM",
                                label: "07 AM - 08 AM",
                              },
                              {
                                value: "08 AM - 09 AM",
                                label: "08 AM - 09 AM",
                              },
                              {
                                value: "09 AM - 10 AM",
                                label: "09 AM - 10 AM",
                              },
                              {
                                value: "10 AM - 11 PM",
                                label: "10 AM - 11 PM",
                              },
                              {
                                value: "11 AM - 12 PM",
                                label: "11 AM - 12 PM",
                              },
                              {
                                value: "12 PM - 01 PM",
                                label: "12 PM - 01 PM",
                              },
                              {
                                value: "01 PM - 02 PM",
                                label: "01 PM - 02 PM",
                              },
                              {
                                value: "02 PM - 03 PM",
                                label: "02 PM - 03 PM",
                              },
                              {
                                value: "03 PM - 04 PM",
                                label: "03 PM - 04 PM",
                              },
                              {
                                value: "04 PM - 05 PM",
                                label: "04 PM - 05 PM",
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {/* ------ */}
              <button className="w-full bg-White text-center border border-primaryColor text-primaryColor text font-medium rounded-lg text-base py-2.5 my-3">
                Add more items
              </button>
            </div>
            <div className="md:w-[40%] bg-White rounded-md md:flex flex-col justify-between">
              <div className="p-7 ">
                <div className="flex items-center gap-3">
                  <img src={DiscountIcon} alt="discount" />
                  <p className="text-primaryColor font-medium text-lg">
                    Avail Coupons
                  </p>
                </div>
                <Search
                  className="mt-3 applycoupon"
                  placeholder="input search text"
                  allowClear
                  enterButton="Apply"
                  size="large"
                />
              </div>
              <div className="bg-primaryColor px-7 py-5 cursor-pointer flex justify-between items-center rounded-md">
                <p onClick={() => handleProceed("")} className="text-White ">
                  Payable Amount
                </p>
                <p className="text-White">₹ 234.89 /-</p>
              </div>
            </div>
          </div>
        </section>
      </Layouts>
      <Drawer
        title={
          <h2 className=" font-bold text-secondaryColor text-xl mt-4 p-2">
            upload prescricption
          </h2>
        }
        extra={
          <Space>
            <button onClick={onClose}>
              <CloseOutlined />
            </button>
          </Space>
        }
        closeIcon={false}
        onClose={onClose}
        open={open}
      >
        <div className="h-40">
          <Dragger className="h-10 p-2">
            <p className="ant-upload-drag-icon ">
              <CloudUploadOutlined />
            </p>
            <p className="ant-upload-text">
              Browse Files to Upload Prescription
            </p>
          </Dragger>
        </div>
        <h2 className=" font-bold text-secondaryColor text-xl mt-10 p-2 ">
          Guide for a valid prescription
        </h2>

        <div className="flex flex-col gap-4 mt-5">
          <p>
            <RightOutlined />
            <span className="m-2">
              Don't crop out the any part of the image
            </span>
          </p>
          <p>
            <RightOutlined />
            <span className="m-2">Avoid blurred image</span>
          </p>
          <p>
            <RightOutlined />
            <span className="m-2">Supported files : jpeg, jpg, png, pdf</span>
          </p>
          <p>
            <RightOutlined />
            <span className="m-2">Maximum allowed file size : 5MB</span>
          </p>
        </div>
      </Drawer>
      {isUserReadyToPay ? (
        <Dialog
          header="Select Payment Mode"
          visible={isUserReadyToPay}
          modal={true}
          resizable={false}
          draggable={false}
          className="w-11/12 md:w-96"
          onHide={() => setIsUserReadyToPay(false)}
        >
          <div>
            <div>
              <div
                className="flex  font-medium text-sm justify-between items-center py-2 px-6 rounded mb-2 cursor-pointer"
                style={{
                  backgroundColor: "#e5e6e7",
                  border: selectedPayMode.wallet ? "2px solid #66B889" : "",
                }}
                // onClick={() =>
                // setSelectedPayMode({ wallet: true, card: false })
                // }
              >
                CureBay wallet - Rs{" "}
                {/* {walletinfo?.patientwalletinfoData?.balance &&
                  walletinfo?.patientwalletinfoData?.balance.toFixed(2)}{" "} */}
                <i className="pi pi-angle-right"></i>
              </div>
            </div>
            <div
              className="flex justify-between  font-medium text-sm items-center py-2 px-6 rounded cursor-pointer"
              style={{
                backgroundColor: "#e5e6e7",
                border: selectedPayMode.card ? "2px solid #66B889" : "",
              }}
              // onClick={() => setSelectedPayMode({ wallet: false, card: true })}
            >
              Debit/Credit Cards/UPI & Others{" "}
              <i className="pi pi-angle-right"></i>
            </div>
            <div className="text-center">
              <button
                className=" font-normal text-xs py-2 px-6 rounded mt-4"
                style={{ backgroundColor: "#66B889", color: "#ffff" }}
                // onClick={DoPay}
              >
                Pay
                {/* {loadingDuringPayment && (
                  <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                )} */}
              </button>
            </div>
            {/* {errorDuringPay.length > 0 ? (
              <div style={{ color: "red" }} className="font-normal text-sm">
                {" "}
                {errorDuringPay}{" "}
              </div>
            ) : (
              ""
            )} */}
          </div>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
}

export default CartPage;
