import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
// import EditVitals from "../../../../Pages/PostLogin/HealthRecords/VitalCards/EditVitals";
import VitalChart from '../VitalChart';
import BloodPressure from "../../../../../Assets/Images/Blood Pressure.png";
import Vector from "../../../../../Assets/Images/Vector.png";
import { FiMoreVertical } from 'react-icons/fi';
import { Dropdown, Modal, Space } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { addPatientvitals } from '../../../../../Redux/Action/userprofileVitalActions';
import { ToastContainer, toast } from 'react-toastify';




function Patientprofilebloodpressurecard(props) {

    const history = useHistory();
    const location = useLocation();

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [showentervitals, setshowentervitals] = useState(false);
    const [showremovevitals, setshowremovevitals] = useState(false);
    const [data, setData] = useState()
    const [graphPop, setGraphPop] = useState(false);
    const [sys, setSys] = useState();
    const [dia, setDia] = useState();
    const dispatch = useDispatch();



    const viewDetails = (event, det) => {
        event.preventDefault();
        console.log(det)
        setData(det)
        // setshowviewall(true)
        setshowentervitals(false)
        setshowremovevitals(false)
    }


    const addvitals = (event, det) => {
        event.preventDefault();
        console.log(det)
        setData(det)
        // setshowviewall(false)
        setshowremovevitals(false)
        setshowentervitals(true)


    }


    const removevitals = (event, det) => {
        event.preventDefault();
        console.log(det)
        setData(det)
        // setshowviewall(false)
        setshowentervitals(false)
        setshowremovevitals(true)

    }

    const savevitals = () => {
        console.log(sys, dia);
        if (sys && dia) {
            let newVitals = { ...props.data, systolic: sys, diastolic: dia, };
            newVitals.createdDate = moment().format("yyyy-MM-DD HH:mm:ss");
            newVitals.updatedDate = moment().format("yyyy-MM-DD HH:mm:ss");
            newVitals.givenDate = moment().format("yyyy-MM-DD HH:mm:ss");
            newVitals.patientCode = props?.data?.patientCode;
            newVitals.createdBy = props?.data?.createdBy;
            newVitals.updatedBy = props?.data?.updatedBy;
            dispatch(addPatientvitals(newVitals)).then(res => {
                saveSuccess();
                props.fetchAllVitals(props.activePatient)
                setDia('');
                setSys('');
                setTimeout(() => {
                    toast('Vitals updated successfully');

                }, 300);

            }).catch(err => {
                console.log("Error");
            })
        }
    }


    const saveSuccess = (e) => {
        console.log("save success");
        setshowentervitals(false);
        props.saveCallback(e);
    };

    const items = [
        {
            label: <p className='relative' onClick={addvitals}>Update</p>,
            key: 0,
        }]



    return (
        <>

            {/* {props?.data?.slice(0, 2).map((user, i) => ( */}
            <>
                <div className="w-full bg-White p-5 rounded-md">
                    <ToastContainer />
                    <div className="flex gap-5">
                        <div className="" onClick={() => setGraphPop(true)}>
                            <img src={BloodPressure} alt="images" />
                        </div>
                        <div className="w-full ">
                            <div className="w-full flex justify-between items-center">
                                <h5 className="text-primaryColor text-lg font-medium">
                                    Blood Pressure
                                </h5>
                                <div className="flex gap-3 items-center">
                                    <div onClick={() => setGraphPop(true)}
                                        className="flex cursor-pointer"
                                    >
                                        <img src={Vector} alt="images" />
                                    </div>
                                    <div class="relative inline-block group">
                                        <Dropdown
                                            menu={{
                                                items,
                                            }}
                                            trigger={['click']}
                                            placement='bottomRight'
                                        >
                                            <Space>
                                                <FiMoreVertical />
                                            </Space>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-5 mt-4">
                                <p className="text-textGray font-light text-xs">
                                    SYS{" "}
                                    <span className="text-lg text-secondaryColor font-medium">
                                        {props.data.systolic}
                                    </span>{" "}
                                    mmHg
                                </p>
                                <p className="text-textGray font-light text-xs">
                                    DIA{" "}
                                    <span className="text-lg text-secondaryColor font-medium">
                                        {props.data.diastolic}
                                    </span>{" "}
                                    mmHg
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            {/* ))
            } */}

            {
                showentervitals ?
                    // <EditVitals title={'Blood Pressure'} data={props.data} closePopup={saveSuccess} />
                    <div className='absolute'>
                        <div className='absolute inset-0'>
                            <Modal
                                centered
                                open={showentervitals}
                                width={"25%"}
                                footer={[]}
                                onCancel={saveSuccess}
                            >
                                <div>
                                    <p className='font-semibold text-xl'>Enter Vitals</p>
                                    <p className=" text-xs font-normal text-gray-secondary pt-5">
                                        SYS
                                    </p>
                                    <div className="pt-4">
                                        <div className="flex justify-between">
                                            <input
                                                autoComplete="off"
                                                id="systolic"
                                                name="systolic"
                                                type="number"
                                                value={sys ?? ""}
                                                className=" text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                placeholder="SYS"
                                                onChange={(e) => setSys(e.target.value)}
                                            />

                                            <label
                                                htmlFor="email"
                                                className=" text-xs font-normal text-gray-secondary"
                                            >
                                                mmHg
                                            </label>
                                        </div>
                                        <hr className="bg-gray-primary" />
                                    </div>
                                </div>
                                <div>
                                    <div className="flex space-x-3 pt-6">
                                        {/* <img src={weight} alt="weight"/> */}
                                        <p className="text-sm  font-medium"></p>
                                    </div>
                                    <p className=" text-xs font-normal text-gray-secondary pt-2">
                                        DIA
                                    </p>
                                    <div className="pt-4">
                                        <div className="flex justify-between">
                                            <input
                                                autoComplete="off"
                                                id="diastolic"
                                                name="diastolic"
                                                type="number"
                                                value={dia ?? ""}
                                                className=" text-sm font-medium peer  h-4 w-full border-b-1 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                                placeholder="DIA"
                                                onChange={(e) => setDia(e.target.value)}
                                            />
                                            <label
                                                htmlFor="email"
                                                className=" text-xs font-normal text-gray-secondary"
                                            >
                                                mmHg
                                            </label>
                                        </div>
                                        <hr className="bg-gray-primary" />
                                        <div className="flex justify-end my-3">
                                            <button
                                                onClick={savevitals}
                                                className="bg-primaryColor  text-White py-1 px-4 font-medium rounded-xl"
                                            >
                                                Update{" "}
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    : null
            }
            {
                graphPop ? <VitalChart title={'Blood Pressure Graph'} data={[props.data]} types={["diastolic", "systolic"]} closePopup={() => setGraphPop(false)} /> : null
            }
        </>

    )
}
export default Patientprofilebloodpressurecard;
