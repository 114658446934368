  import React, { useEffect, useRef, useState } from "react";
  import Layouts from "../../Layouts";
  import Lab111 from "../../../Assets/Images/Icons/lab.svg";
  import Lab222 from "../../../Assets/Images/Icons/testreport.svg";
  import Lab333 from "../../../Assets/Images/Icons/ontime.svg";
  import Lab444 from "../../../Assets/Images/Icons/doorstep.svg";
  import OneImage from "../../../Assets/Images/Icons/one.svg";
  import TwoImage from "../../../Assets/Images/Icons/Two.svg";
  import ThreeImage from "../../../Assets/Images/Icons/Three.svg";
  import CartDocImg from "../../../Assets/Images/medicalSummary.png";
  import PrevArrow from "../../../Assets/Images/left_arrow.png";
  import NextArrow from "../../../Assets/Images/right_arrow.png";
 
  import { Carousel, Form, Input, InputNumber, Select, message } from "antd";
  import Slider from "react-slick";
  import Search from "antd/es/input/Search";
  import axios from "axios";
  import { useHistory } from "react-router-dom";
  import { APP_ROUTES } from "../../PageRouting/AppRoutes";
  import diagonstics from "../../../Assets/Images/diagnostics.png";
  import { useDispatch, useSelector } from "react-redux";
  import { getlabPartnerslist } from "../../../Redux/Action/DiagnosticsActions";
  import { connect } from "react-redux";
  import { LocationMarkerIcon } from "@heroicons/react/solid";
  import {
    AddtoCart,
    getCartDetails,
  } from "../../../Redux/Action/cartPlaceOrderAction";
  import TestCapsule from "../../../Assets/Images/testTube.svg";
  import {
    setAddressString,
    setLatLong,
    actioncustomPinCode,
    //actioncustomRadius
  } from "../../../Redux/Action/userActions";
  import { secureStorage } from "../../../Redux/Reducer/authReducer";
  import LocateIcon from "../../../Assets/Images/locate.svg";
  import moment from "moment";
  import http from "../../../Redux/Services/http-common";
  import { encodeBase64File } from "../../Helper/filebase64";
  import { ToastContainer, toast } from "react-toastify";
  import { CloudUploadOutlined, DeleteOutlined, ExperimentOutlined } from "@ant-design/icons";
  import { debounce } from "lodash";
  import NodataImage from "../../../Assets/Images/Icons/nodata.svg";
  import Loader from "../../Shared/loader";
  import { EnglishText } from "../../PageRouting/EnglishText";
  import { Helmet } from "react-helmet";
  import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
  import { Toast } from 'primereact/toast';
  import "react-toastify/dist/ReactToastify.css";
  import {
    trackAddToCartEvent,
    trackPageEvent,
  } from "../../utils/analytics/events";
  import SearchBar from "../../Shared/SearchBar";
  import CurebayButton from "../../Shared/Button/PrimaryButton";
  import LabTestCard from "../../Shared/labtest-card";
import { Alert } from "@mui/material";
import { useApiData } from "../../../Context/ApidtaContext";

  function Lab() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [addtocartLoading, setAddtocartLoading] = useState(false);
    const userData = useSelector((state) => state.authReducer.patientData);
    const dispatch = useDispatch();
    const { cartList } = useSelector((state) => state.cartReducer);
    const patientinfo = useSelector((state) => state.patientinfo);
    const history = useHistory();
    const [labSearched, setlabSearched] = useState([]);
    const [choosetoCart, setchoosetoCart] = useState([]);
    const [labPartners, setLabPartners] = useState([]);
    const [mostcommonlabtest, setMostcommonlabtest] = useState([]);
    const [isLabAvaiable, setIsLabAvaiable] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [currPage, setCurrPage] = useState(0);
    const [currPagePartners, setCurrPagePartners] = useState([]);
    const [labNewName, setLabNewName] = useState("");
    const formRef = useRef();
    const secondFormRef = useRef();
    const [image, setImage] = useState(null);
    const [secImage, setSecImage] = useState(null);
    const [lastSearchText, setLastSearchText] = useState("");
    const [profile, setProfile] = useState(userData);
    const [open, setOpen] = useState(false);
    const [toastShown, setToastShown] = useState(false);
    const [searchedLabTest, setSearchedLabTest] = useState("");
     const drugIDsInCart = getProductsIDs();
     const { medicinePage } = useApiData();
     let whyCurebay = medicinePage?.attributes?.firstComp[0]?.images[0]?.image_url;
     console.log(whyCurebay,"fghbj")
    const {
      radius,
      customPinCode: pincode,
      coords,
    } = useSelector((state) => state.authReducer);
    const handleClose = async () => {
      setOpen(false);
    };
    useEffect(() => {
      SlicedProducts();

      console.log(cartList);
    }, [currPage]);

    console.log(pincode, "pincode");
    const showInfo = () => {
      if (toast.current && !toastShown) {
        toast.current.show({
          severity: 'info',
          summary: 'Info',
          detail: 'We are sorry! the service is not available in your location.',
          life: 3000,
        });
        setToastShown(true); // Ensure toast is only shown once
      }
    };
    const handleNumberPress = (e) => {
      const allowedKeys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
      ];
      if (!allowedKeys.includes(e.key)) {
        if (/^[A-Za-z]$/.test(e.key)) {
          message.error("Alphabetic characters are not allowed.");
        }
        e.preventDefault();
      }
    };

    const getTheAge = (date) => {
      return moment().diff(date, "years", false);
    };

    const handleFormOne = async () => {
      const formValues = formRef.current.getFieldsValue();

      let { name, gender, age, email, phone } = formValues;

      // Check if age is provided or calculate it from dob, if applicable
      if (profile.dob === undefined || profile.dob === "") {
        if (age === undefined || age === "" || age === null || age <= 0) {
          message.error("Please enter a valid age greater than 0.");
          return;
        }
      } else {
        if (age === undefined || age === "" || age === null || age <= 0) {
          age = getTheAge(profile.dob);
          if (age <= 0) {
            message.error("Calculated age must be greater than 0.");
            return;
          }
        }
      }

      // Check if gender is provided or use the profile gender
      if (profile.gender === undefined || profile.gender === "") {
        if (gender === undefined || gender === "" || gender === null) {
          message.error("Please select your gender.");
          return;
        }
      } else {
        if (gender === undefined || gender === "") {
          gender = profile.gender === "M" ? "Male" : "Female";
        }
      }

      // Check if the name is provided or use the profile name
      if (
        profile.firstName === undefined ||
        profile.firstName === "" ||
        profile.firstName === null
      ) {
        if (name === undefined || name === "" || name === null) {
          message.error("Please enter your name.");
          return;
        }
      }

      // Check email and phone if not already in the profile
      if (Object.keys(userData).length === 0) {
        if (email === undefined || email === "" || email === null) {
          message.error("Please enter your email.");
          return;
        }
        if (phone === undefined || phone === "" || phone === null) {
          message.error("Please enter your phone number.");
          return;
        }
      }

      let payload;

      // Prepare payload depending on userData
      if (Object.keys(userData).length === 0) {
        payload = {
          contactPerson: name,
          email,
          phoneNumber: phone,
          age,
          gender,
          type: "UploadPrescription",
        };
      } else {
        payload = {
          contactPerson: name ? name : profile.firstName,
          email: profile.email,
          phoneNumber: profile.mobile,
          age,
          gender,
          type: "UploadPrescription",
        };
      }

      // Check if a prescription image is uploaded
      if (image) {
        const res = await encodeBase64File(image);
        const imageType = image.type.split("/")[1];

        // Set the payload's photo type based on file type
        payload.photoType = imageType;
        payload.photo = res;
      } else {
        message.error("Please upload a Prescription.");
        return;
      }

      try {
        setLoading(true);
        const res = await http.post("partnerEnquiryForm/helpUs", payload);
        console.log(res);
        formRef.current.resetFields();
        setLoading(false);

        message.success("Thank You! Our support team will contact you shortly.");
        setImage(null);
      } catch (error) {
        console.log(error);
        message.error("Error occurred while submitting the form.");
        setLoading(false);
      }
    };

    const handleFormTwo = async () => {
      const formValues = secondFormRef.current.getFieldsValue();
      console.log("Form values:", formValues);
      let { name, gender, town, email, phone } = formValues;

      if (profile.city === undefined || profile.city === "") {
        if (town === undefined || town === "") {
          message.error("Please fill in all fields");
          return;
        }
      }
      // else{
      //   if(town === undefined || town === ""){
      //     town = profile.city;
      //   }
      // }

      if (profile.gender === undefined || profile.gender === "") {
        if (gender === undefined || gender === "") {
          message.error("Please fill in all fields2");
          return;
        }
      } else {
        if (gender === undefined || gender === "") {
          gender = profile.gender === "M" ? "MALE" : "FEMALE";
        }
      }

      if (
        profile.firstName === undefined ||
        profile.firstName === "" ||
        profile.firstName === null
      ) {
        if (name === undefined || name === "") {
          message.error("Please fill in all fields");
          return;
        }
      }

      let payload;

      if (Object.keys(userData).length === 0) {
        if (email === undefined || email === "") {
          message.error("Please fill in all fields");
          return;
        }
        if (phone === undefined || phone === "") {
          message.error("Please fill in all fields");
          return;
        }
        payload = {
          contactPerson: name ? name : profile.firstName,
          email,
          phoneNumber: phone,
          address: town ? town : profile.city,
          gender,
          type: "UploadTestReport",
        };
      } else {
        payload = {
          contactPerson: name ? name : profile.firstName,
          email: profile.email,
          phoneNumber: profile.mobile,
          address: town ? town : profile.city,
          gender,
          type: "UploadTestReport",
        };
      }

      if (secImage) {
        const res = await encodeBase64File(secImage);
        if (secImage.type.includes("pdf")) {
          payload.photoType = "pdf";
          payload.photo = res;
        } else if (secImage.type.includes("png")) {
          payload.photoType = "png";
          payload.photo = res;
        } else if (secImage.type.includes("jpg")) {
          payload.photoType = "jpg";
          payload.photo = res;
        } else if (secImage.type.includes("jpeg")) {
          payload.photoType = "jpeg";
          payload.photo = res;
        }
      } else {
        message.error("Please upload a report");
        return;
      }

      try {
        setLoading(true);

        const res = await http.post("partnerEnquiryForm/helpUs", payload);
        console.log(res);
        secondFormRef.current.resetFields();
        setLoading(false);

        message.success("Thank You! Our support team will contact you shortly.");
        setSecImage(null);
      } catch (error) {
        console.log(error);
        message.error("Error Occurred");
      }
    };

    const SlicedProducts = () => {
      const labArray = labPartners;
      const itemsPerPage = 4;
      const startIdx = currPage;
      const lastIdx = currPage + itemsPerPage;
      const Labs = labArray.slice(startIdx, lastIdx);
      setCurrPagePartners(Labs);
    };

    const handleClick = (labName) => {
      console.log(labName.seoNameId);
      history.push({
        pathname: `${APP_ROUTES.DIAGNOSTICDETAILS}/${labName.seoNameId}`,
        state: { labName: labName.labName }, // Passing labName.labName as state
      });
    };
    

    useEffect(() => {
      if (coords.lat && coords.long && pincode) {
        fetchPartners();
      }
    }, [coords.lat, coords.long, pincode]);

    const fetchPartners = async () => {
      setLoading(true);
      try {
        const serviceAvailabilityResponse = await http.get(
          `${process.env.REACT_APP_BASEURL}ServiceAvailability/checkDiagnoServiceAvailabilityCircle?pinCode=${pincode}`
        );
  
        const circle = serviceAvailabilityResponse.data;
  
        if (circle) {
          const response = await http.get(
            `${process.env.REACT_APP_ELASTIC_BASEURL}labs?source=b2c&circle=${circle}`
          );
  
          const filteredData = response.data.filter(
            (lab) => lab.labName !== "MobiLab"
          );
  
          const uniquePartners = Array.from(
            new Map(filteredData.map((lab) => [lab.seoNameId, lab])).values()
          );
  
          setLabPartners(uniquePartners);
          setIsLabAvaiable(true)
          // Check if no partners found, and show toast only once
          if (uniquePartners.length === 0 && !toastShown) {
            // showInfo();
            setToastShown(true); // Set toastShown to true to prevent multiple displays
          }
        } else {
          setLabPartners([]);
          setIsLabAvaiable(false)
          if (!toastShown) {
            // showInfo();
            setToastShown(true); // Set toastShown to true
          }
        }
      } catch (e) {
        console.log("Error fetching lab partners:", e);
        setLabPartners([]);
        setIsLabAvaiable(false)
      } finally {
        setLoading(false);
      }
    };

    
  function getProductsIDs() {
    let ids = [];
    if (cartList) {
      if (cartList.patientLabTestsOrder) {
        for (const product of cartList.patientLabTestsOrder) {
          // if (product && product.patientLabTestsOrderDetailsList) {
          for (const drug of product.patientLabTestsOrderDetailsList) {
            ids.push(drug.labTestCode);
          }
          // }
        }
      }
    }
    return ids;
  }

    
    const Mostcommonlabtest = async () => {
      setLoading(true);
      try {
        // Fetch both Pathology and Radiology data concurrently
        const [pathologyResponse, radiologyResponse] = await Promise.all([
          http.get(
            `${process.env.REACT_APP_BASEURL}PatientLabTestsOrderDetails/mostCommonLabtest?labType=Pathology`
          ),
          http.get(
            `${process.env.REACT_APP_BASEURL}PatientLabTestsOrderDetails/mostCommonLabtest?labType=Radiology`
          ),
        ]);
    
        // Merge the responses
        const combinedData = [
          ...(pathologyResponse?.data || []),
          ...(radiologyResponse?.data || []),
        ];
    console.log(combinedData,"rftgyhuji")
        // Set the combined data to state
        setMostcommonlabtest(combinedData);
      } catch (e) {
        console.log("Error fetching lab partners:", e);
      } finally {
        setLoading(false);
      }
    };
    
  
  
    useEffect(() => {
      Mostcommonlabtest();
      if ((coords.lat && coords.long && pincode) && !toastShown) {
        fetchPartners();
      }
    }, [coords.lat, coords.long, pincode, toastShown]);
    
    

    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        // Handle the Enter keypress here
        setIsOpen(false);
        window.scrollTo({
          top: 530,
          behavior: "smooth",
        });
      }
    };

    useEffect(() => {
      trackPageEvent("lab_page_view");
    }, []);

    useEffect(() => {
      fetchPartners();
    }, [coords.lat, coords.long]);

    const [isaddedincart,setIsaddedincart] = useState();

    useEffect(() => {
      setLoading(true);

      dispatch(getCartDetails(userData.code)).then((response) => {
       
        setLoading(false);
      });
    }, []);

    useEffect(() => {
      if (searchedLabTest.length) {
        fetchLabtest();
      } else {
        setlabSearched([]);
      }
    }, [searchedLabTest]);

    const fetchLabtest = async () => {
      const circle = JSON.parse(secureStorage.getItem("circle"))
      try {
        let res = await http.get(
          `${process.env.REACT_APP_ELASTIC_BASEURL}labtest?freeTextSearch=${searchedLabTest}&source=b2c&labCircle=${circle}`
        );
        window.scrollTo({
          top: 530,
          behavior: "smooth",
        });
        setlabSearched(res.data);
      } catch (err) {}
    };

    const lab = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: <img src={PrevArrow} alt="icons" />,
      nextArrow: <img src={NextArrow} alt="icons" />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const handleImageChange = (e) => {
      setImage(e.target.files[0]);
    };

    const handleSecImageChange = (e) => {
      setSecImage(e.target.files[0]);
    };

    const handleUploadDelete1 = () => {
      setImage(null);
    };

    const handleUploadDelete2 = () => {
      setSecImage(null);
    };

    const [viewdetails, setAddviewdetails] = useState({}); 
    const [cartaddedlab, setcartaddedlab] = useState();


    const handleNavigation = async (e, data, labTestCode) => {
      console.log(e, data, "fff");
      e.preventDefault();
      try {
        const res2 = await http.get(
          `${process.env.REACT_APP_ELASTIC_BASEURL}labs/${data?.locationId}/labtest?pageNo=1&pageSize=20`
        );
    
        console.log(res2.data, "Fetched lab details");
    
        const filteredLabData = res2.data?.find(
          (item) => item.labTestCode === data.labTestCode
        );
        setAddviewdetails(filteredLabData);
    
        if (filteredLabData) {
          console.log(filteredLabData, "Filtered lab data");
          history.push({
            pathname: APP_ROUTES.LABVIEW,
            state: { labdata: filteredLabData, labTestCode: labTestCode },
            login: false,
          });
        } else {
          console.warn("Lab test code not found in the response data");
        }
    
        // Scroll handling
        if (/Mobi|Android/i.test(navigator.userAgent)) {
          window.scrollTo(0, 0);
        } else {
          window.scrollTo(0, 0);
        }
      } catch (error) {
        console.error("Error fetching lab details:", error);
      }
    };
    
   
    const addTocart = (e, data, index, quantity = 1) => {
      setcartaddedlab(data.labTestCode);
    
      if (!userData.code) {
        history.push({
          pathname: APP_ROUTES.HOME,
        });
        return;
      }
    
      setLoading(true);
    console.log(userData.code,"sdfghjk")
      dispatch(getCartDetails(userData.code))
        .then((response) => {
          console.log(response, "Cart details response");
    
          // Ensure response is an array and has at least one element
          if (Array.isArray(response) && response.length > 0) {
            const cartData = response[0];
    
            // Check if patientLabTestsOrder exists and is an array
            if (Array.isArray(cartData.patientLabTestsOrder)) {
              const availableItem = cartData.patientLabTestsOrder.find((item) => {
                console.log(item.locationId, data.locationId, "Debugging values");
                return item.locationId === data.locationId;
              });
    
              if (!availableItem) {
                message.warning(
                  "Select this test from the same lab or replace the tests in your cart"
                );
                setLoading(false);
                return;
              }
            } else {
              console.warn("patientLabTestsOrder is not available");
              
            }
          } else {
            console.warn("Cart response is empty");
           
          }
    
          const payload = {
            patientId: userData.code,
            labTestCode: data.labTestCode,
            amount: data.amount,
            hospitalId: data.labId,
            locationId: data.locationId,
            labTestName: data.labTestName,
            labTestType: data.labTestType,
            sourceChannel: "B2C",
          };
    
          fetch(`${process.env.REACT_APP_BASEURL}cart/addToCart/labtest`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          })
            .then((response) => response.json())
            .then((res) => {
              console.log(res, "Item added to cart response");
    
              trackAddToCartEvent(data.amount, data.labTestName);
              
              dispatch(getCartDetails(userData.code));
              setLoading(false);
            })
            .catch((err) => {
              console.error("Error adding to cart:", err);
              message.error("Failed to add item to cart. Please try again.");
              setLoading(false);
            });
        })
        .catch((err) => {
          console.error("Error fetching cart details:", err);
          message.error("Failed to fetch cart details. Please try again.");
          setLoading(false);
        });
    };
    
    
    

    return (
      <>
        <ToastContainer />
        {loading && <Loader />}
        {/* <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message="We are sorry! the service is not available in your location."
        /> */}
        <Toast ref={toast} />
        <div onClick={() => handleKeyPress("Enter")}>
          <Layouts>
            <SearchBar searchedText={(text) => setSearchedLabTest(text)} />
            {!isLabAvaiable && <Alert sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                color: "#ff0000"
            }} severity="warning" color="warning">We do not service your area yet. Coming soon!</Alert>}
            <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
              <div className="container mx-auto md:pt-[70px]">
                <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[63vh] rounded-e-xl"></div>
                <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
                  <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
                    <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                      Unlock{" "}
                      <span className="text-secondaryColor">
                        {" "}
                        cutting edge diagnostics
                      </span>{" "}
                      for timely medical insights
                    </h1>
                    <p className="text-white md:text-White font-light text-sm md:md:text-lg text-basemb-5 mb-5">
                      Explore CureBay's Comprehensive Range of Pathology &
                      Radiology Tests. Our extensive selection offers accurate
                      diagnostics and insights, supporting your health journey
                      with precision and care.
                    </p>
                    {/* {!showTopSearch && (
                      <div className={!showTopSearch ? 'primary-search-bar' : 'invisible primary-search-bar' }>
                      <Search
                    className= " apptSearch apptWhite bg-White"
                    placeholder="Search Lab Tests"
                    onFocus={handleInputSearchClick}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress} 
                  />
                        {labSearched.length > 0 && isOpen ? (
                          <div className="relative w-full h-[10rem] mt-2">
                            <div className=" flex  absolute inset-0 w-full bg-White overflow-y-scroll rounded-2xl scrollWidth">
                              <div className="w-full flex flex-col gap-1">
                                {labSearched.map((lab, idx) => (
                                  <div className="w-full p-1 rounded-lg ">
                                    <p
                                      onClick={() => seletedSearch(lab)}
                                      className="py-1 px-16 hover:bg-lightGray hover:text-primaryColor rounded-lg  w-full"
                                    >
                                      {lab.labTestName}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                  )}  */}
                  </div>
                  <div className="relative z-1 flex justify-end mt-5 md:mt-0">
                    <div className="w-full md:w-[90%] md:h-[400px]  rounded ">
                      <img
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Lab%20test1.webp"
                        alt="images"
                        className="w-full h-full object-cover image-boxshadow rounded-xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="bg-White">
              {labSearched.length !== 0 ? (
                <div className="container mx-auto py-[50px] md:py-[90px]">
                  <div>
                    <div>
                      <div className="flex flex-col">
                        {labSearched.length !== 0 && (
                          <h2 className="text-center text-xl md:text-4xl font-semibold text-primaryColor leading-normal mb-8 md:mb-[50px]">
                            Available Lab test(s)
                          </h2>
                        )}
                        {labSearched ? (
                          labSearched.map((item, index) => (
                            <LabTestCard labs={item} index={index} />
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : labSearched.length === 0 && lastSearchText !== "" ? (
                <div class=" w-full h-[inherit] flex gap-5 flex-col justify-center my-12 items-center text-center ">
                  <div>
                    <img src={NodataImage} alt="emptyData" className="w-[60px]" />
                  </div>
                  <p className="text-center font-light item-center text-base text-textGray">
                    No results found
                  </p>
                </div>
              ) : (
                ""
              )}
            </section>

            <section className="bg-White">
              <div className="container mx-auto py-[50px] md:py-[90px]">
                <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[50px]">
                  Lab <span className="text-secondaryColor">Partners</span>
                </h2>
                {labPartners?.length ?  (
                  <>
                    <Helmet>
                      <title>{"Lab Tests | Curebay"}</title>
                      <meta
                        name="description"
                        content={EnglishText.LAB_META_DESCRIPTION}
                      />
                      <meta name="title" content={"Lab Tests | Curebay"} />
                      <link rel="canonical" href={window.location.href} />{" "}
                    </Helmet>
                    <Slider {...lab}>
                      {labPartners.map((lab, idx) => (
                        <div class="bg-lightBlue  gap-5 labwidth p-5 rounded-lg mb-5 md:mb-0 ">
                          <div className="flex flex-col justify-between md:min-h-[100px]">
                            <div class="flex gap-4 ">
                              <div class="w-[80px] h-[80px]">
                                <img
                                  src={
                                    process.env.REACT_APP_IMG_BASEURL + lab.logo
                                  }
                                  alt={lab?.locationName}
                                  className="rounded-full bg-White text-xs  w-[65px] h-[65px] object-contain"
                                />
                              </div>
                              <div class="w-full">
                                <p class="text-primaryColor text-sm font-semibold uppercase ">
                                  {lab.labName}
                                </p>
                                <p class="text-textGray mb-5 text-xs font-normal mt-1  ">
                                  {lab.city}
                                </p>
                              </div>
                            </div>
                          </div>
                          <button
                            className="w-full text-center bg-primaryColor px-2 py-2.5 text-White font-medium text-sm rounded-md"
                            onClick={() => handleClick(lab)}
                          >
                            View Details
                          </button>
                        </div>
                      ))}
                    </Slider>
                  </>
                ): <div className="text-md font-medium flex justify-center" >Lab Partners not available at your location.</div>}
              </div>
            </section>


       
            {/* <section className="bg-lightGray">
              <div className="container mx-auto py[50px] md:py-[90px] px-8 sm:px-0">
                <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-8 md:mb-[50px]">
                  Why <span className="text-secondaryColor">CureBay</span>?
                </h2>
                <div className="md:w-[60%] mx-auto grid md:grid-cols-2 gap-5">
                  <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow  hoverWhite">
                    <div className="mb-5">
                      <img src={Lab111} alt="images" />
                    </div>
                    <p className="text-textGray md:leading-loose text-base">
                      NABL Accredited Fully Automated Labs
                    </p>
                  </div>
                  <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                    <div className="mb-5">
                      <img src={Lab222} alt="images" />
                    </div>
                    <p className="text-textGray md:leading-loose text-base">
                      Accurate & Efficient Test Reports
                    </p>
                  </div>
                  <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                    <div className="mb-5">
                      <img src={Lab333} alt="images" />
                    </div>
                    <p className="text-textGray md:leading-loose text-base">
                      On-time report delivery
                    </p>
                  </div>
                  <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                    <div className="mb-5">
                      <img src={Lab444} alt="images" />
                    </div>
                    <p className="text-textGray md:leading-loose text-base">
                      Sample collection at your doorstep
                    </p>
                  </div>
                </div>
              </div>
            </section> */}

<section className="bg-lightBlue py-16">
            <div className="container mx-auto px-4 text-center">
            <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-8 md:mb-[50px]">
                  Why <span className="text-secondaryColor">CureBay</span>?
                </h2>

              {/* Image Section */}
              <div className="flex justify-center">
                <img
                  src={whyCurebay} // Use the parameter containing the image URL for the "Why CureBay" section
                  alt="Why Choose CureBay for Medicine Order"
                  className="max-w-full h-auto"
                />
              </div>
            </div>
          </section>

            <section className="bg-primaryColor">
              <div className="container mx-auto py-[50px] md:py-[90px]">
                <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[50px]">
                  How it <span className="text-secondaryColor">works</span>?
                </h2>
                <div className="grid grid-cols-1 w-[80%] mx-auto md:w-full md:grid-cols-3 gap-10">
                  <div className="md:block flex justify-center flex-col items-center">
                    <img src={OneImage} alt="images" className="h-[50px] md:h-[70px]" />
                    <p className="text-White text-center md:text-left mt-3 text-sm md:text-base">
                      Select from our extensive range of diagnostic tests for your
                      convenience at home.
                    </p>
                  </div>
                  <div className="md:block flex justify-center flex-col items-center">
                    <img src={TwoImage} alt="images" className="h-[50px] md:h-[70px]" />
                    <p className="text-White text-center md:text-left mt-3 text-sm md:text-base">
                      Benefit from our partnerships with top-notch diagnostic
                      partners nearby, ensuring accurate and rapid results.
                    </p>
                  </div>
                  <div className="md:block flex justify-center flex-col items-center">
                    <img src={ThreeImage} alt="images" className="h-[50px] md:h-[70px]" />
                    <p className="text-White text-center md:text-left mt-3 text-sm md:text-base">
                      Pick a date and time that suits you best. A phlebotomist
                      will then arrive at your location to securely collect the
                      sample, following strict sanitary protocols.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="bg-White">
  <div className="container mx-auto py-[50px] md:py-[90px]">
    <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[50px]">
      Most Common <span className="text-secondaryColor">Tests</span>
    </h2>
  
    {mostcommonlabtest?.length ? (
      <Slider {...lab}>
    {mostcommonlabtest.map((lab, idx) => (
  <div
    key={lab.id}
    className="bg-lightBlue gap-5 labwidth p-5 rounded-lg mb-5 md:mb-0" 
  >

<div className="flex items-center justify-between mb-4">
    {/* Left Section: Image and Info */}
    <div className="flex items-center gap-8">
      {/* Image */}
      {/* <div>
        <img
          src={TestCapsule} 
          alt={lab.labName}
          className="w-[30px] h-[30px] object-contain"
        />
      </div> */}
        <div className="text-primaryColor text-2xl">
        <ExperimentOutlined />
      </div>
      {/* Lab Info */}
      <div className="flex flex-col">
        <p className="text-primaryColor text-base font-semibold">
          {lab.labName}
        </p>
        <p className="text-secondaryColor text-sm">
          {lab.labTestName}
        </p>
        <p className="text-green-500 text-lg font-bold mt-2">
          ₹{lab.amount}
        </p>
      </div>
    </div>
    {/* Right Section: Lab Test Type */}
    <div className="text-right">
      <p className="text- text-sm font-medium italic">
       ({lab.labTestType}) 
      </p>
    </div>
  </div>

  <div className="flex justify-end items-center  pt-4">
    {/* View Details */}
    {/* <button
      className="text-primaryColor text-sm font-medium hover:underline flex items-center gap-1"
      onClick={(e) => handleNavigation(e, lab, cartaddedlab)}
    >
      View Details &rarr;
    </button> */}

    {/* Add to Cart Button */}
    <button
      className={`bg-primaryColor text-White hover:bg-secondaryColor px-4 py-2 text-sm font-medium rounded-md ${
        drugIDsInCart.indexOf(lab.labTestCode) === -1
          ? "hover:bg-opacity-90"
          : "opacity-50 cursor-not-allowed"
      }`}
      onClick={(e) => addTocart(e, lab, idx, 1)}
      disabled={drugIDsInCart.indexOf(lab.labTestCode) !== -1}
    >
      {drugIDsInCart.indexOf(lab.labTestCode) === -1 ? "Add to Cart" : "ADDED"}
    </button>
  </div>
  </div>
))}



      </Slider>
    ) : (
      <div className="text-md font-medium flex justify-center">
        Lab Partners not available at your location.
      </div>
    )}
  </div>
</section>;

            <section className="bg-White md:bg-primaryColor w-full overflow-x-hidden ">
              <div className="container mx-auto py-[50px] md:py-[90px] px-8 sm:px-0">
                <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor md:text-White leading-normal mb-[50px]">
                  Upload <span className="text-secondaryColor">prescription</span>{" "}
                  <br /> & book test
                </h2>
                <div className="bg-White rounded-xl md:w-[90%] mx-auto md:p-10 md:common-shadow">
                  <Form
                    ref={formRef}
                    layout="vertical"
                    autoComplete="off"
                    className="CommonForm grid grid-cols-1 md:grid-cols-2 md:gap-5"
                  >
                    <Form.Item
                      name="name"
                      label={
                        <span>
                          Patient Name<span className="text-danger">*</span>
                        </span>
                      }
                    >
                      <Input
                        placeholder="Enter your name"
                        defaultValue={profile.firstName}
                      />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label={
                        <span>
                          Phone Number<span className="text-danger">*</span>
                        </span>
                      }
                    >
                      <InputNumber
                        disabled={
                          Object.keys(userData).length === 0 ? false : true
                        }
                        defaultValue={profile.mobile}
                        placeholder="Enter number"
                        onKeyPress={handleNumberPress}
                        maxLength={10}
                      />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label={
                        <span>
                          Email Address<span className="text-danger">*</span>
                        </span>
                      }
                    >
                      <Input
                        placeholder="Enter email"
                        defaultValue={profile.email}
                        disabled={
                          Object.keys(userData).length === 0 ? false : true
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="age"
                      label={<span>Age</span>}
                      rules={[{ required: true, message: "Please Enter Age" }]}
                    >
                      <InputNumber
                        placeholder="Enter age"
                        onKeyPress={handleNumberPress}
                        maxLength={3}
                        defaultValue={
                          Object.keys(userData).length !== 0
                            ? getTheAge(profile.dob)
                            : ""
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="gender"
                      label={
                        <span>
                          Gender<span className="text-danger">*</span>
                        </span>
                      }
                    >
                      <Select
                        placeholder="Select your Gender"
                        defaultValue={
                          profile.gender
                            ? profile.gender === "M"
                              ? "Male"
                              : "Female"
                            : ""
                        }
                        optionFilterProp="children"
                        options={[
                          {
                            value: "Male",
                            label: "Male",
                          },
                          {
                            value: "Female",
                            label: "Female",
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      name="upload"
                      label={
                        <span>
                          Upload Prescription
                          <span className="text-danger">*</span>
                        </span>
                      }
                    >
                      <div className="h-[50px] border border-gray w-full rounded-md">
                        <input
                          className=" px-3 py-2 w-96 h-full flex items-center cursor-pointer relative z-[5]"
                          placeholder="Upload Prescription"
                          type="file"
                          onChange={handleImageChange}
                        />
                        {!image && (
                          <div className="absolute inset-0 flex justify-center items-center cursor-pointer">
                            <div className="text-textGray text-sm ">
                              {" "}
                              <CloudUploadOutlined className="pr-3" />
                              Upload Prescription
                            </div>
                          </div>
                        )}
                        {image && (
                          <div className="absolute flex justify-between top-[23%] left-[3%] w-[90%]">
                            <p>{image.name}</p>
                            <DeleteOutlined
                              className="text-danger"
                              role="button"
                              onClick={handleUploadDelete1}
                            />
                          </div>
                        )}
                      </div>
                    </Form.Item>
                  </Form>
                  <button
                    className="primary_btn !w-full mt-5 !h-[50px] !text-lg"
                    onClick={handleFormOne}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </section>
            <section className="bg-lightGray md:bg-White w-full overflow-x-hidden">
              <div className="container mx-auto py-[50px] md:py-[90px] px-8 sm:px-0">
                <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-[50px]">
                  View <span className="text-secondaryColor">test report</span>{" "}
                  <br /> & request a{" "}
                  <span className="text-secondaryColor">callback</span>
                </h2>
                <div className="bg-[#F5F6FA] rounded-xl md:w-[90%] mx-auto md:p-10 md:common-shadow">
                  <Form
                    ref={secondFormRef}
                    layout="vertical"
                    autoComplete="off"
                    className="CommonForm grid grid-cols-1 md:grid-cols-2 md:gap-5"
                  >
                    <Form.Item
                      name="name"
                      label={
                        <span>
                          Name<span className="text-danger">*</span>
                        </span>
                      }
                    >
                      <Input
                        placeholder="Enter your name"
                        defaultValue={profile.firstName}
                      />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label={
                        <span>
                          Phone Number<span className="text-danger">*</span>
                        </span>
                      }
                    >
                      <InputNumber
                        disabled={
                          Object.keys(userData).length === 0 ? false : true
                        }
                        placeholder="Enter number"
                        onKeyPress={handleNumberPress}
                        maxLength={10}
                        defaultValue={profile.mobile}
                      />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label={
                        <span>
                          Email Address<span className="text-danger">*</span>
                        </span>
                      }
                    >
                      <Input
                        placeholder="Enter email"
                        defaultValue={profile.email}
                        disabled={
                          Object.keys(userData).length === 0 ? false : true
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="town"
                      label={
                        <span>
                          Town/Village<span className="text-danger">*</span>
                        </span>
                      }
                    >
                      <Input
                        defaultValue={profile.city}
                        placeholder="Enter village name"
                      />
                    </Form.Item>
                    <Form.Item
                      name="gender"
                      label={
                        <span>
                          Gender<span className="text-danger">*</span>
                        </span>
                      }
                    >
                      <Select
                        defaultValue={
                          profile.gender
                            ? profile.gender === "M"
                              ? "Male"
                              : "Female"
                            : ""
                        }
                        placeholder="Select your Gender"
                        optionFilterProp="children"
                        options={[
                          {
                            value: "Male",
                            label: "Male",
                          },
                          {
                            value: "Female",
                            label: "Female",
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      name="upload"
                      label={
                        <span>
                          Upload Test Report<span className="text-danger">*</span>
                        </span>
                      }
                    >
                      <div className="h-[50px] border border-gray w-full rounded-md">
                        <input
                          className="  px-3 py-2 w-96 h-full flex items-center cursor-pointer relative z-[5]"
                          placeholder="Upload Prescription"
                          type="file"
                          onChange={handleSecImageChange}
                        />
                        {!secImage && (
                          <div className="absolute bg-White inset-0 flex justify-center items-center cursor-pointer">
                            <div className="text-textGray text-sm ">
                              <CloudUploadOutlined className="pr-3" />
                              Upload Test Report
                            </div>
                          </div>
                        )}
                        {secImage && (
                          <div className="absolute flex justify-between top-[23%] left-[3%] w-[90%]">
                            <p>{secImage.name}</p>
                            <DeleteOutlined
                              className="text-danger"
                              role="button"
                              onClick={handleUploadDelete2}
                            />
                          </div>
                        )}
                      </div>
                    </Form.Item>
                  </Form>
                  <button
                    className="primary_btn !w-full mt-5 !h-[50px] !text-lg"
                    onClick={handleFormTwo}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </section>
            {/* <sectiion className="bg-White">
              <div className="w-[90%] md:w-[60%] mx-auto pb-[50px] md:pb-[90px] ">
                <div className="bg-secondaryColor mt-5 rounded-md md:flex justify-between relative">
                  <div className="px-7 py-5">
                    <p className="font-medium text-xl mb-2 text-White capitalize">
                      Do you need to book lab test?
                    </p>
                    <p className="text-White font-light text-sm ">
                      Call us, we will book appointment for you.
                    </p>
                    <div className="md:flex w-full mt-6 gap-5">
                      <button
                        className="w-fit bg-primaryColor mb-3 md:mb-0 py-2 px-5 rounded-md text-White text-sm font-medium"
                        onClick={() => window.open("tel:+918335000999")}
                      >
                        +91-8335000999
                      </button>
                    </div>
                  </div>
                  <div className="md:absolute right-0 bottom-0">
                    <img
                      src={CartDocImg}
                      alt="img"
                      className="w-full h-[200px]"
                    />
                  </div>
                </div>
              </div>
            </sectiion> */}
          </Layouts>
        </div>
      </>
    );
  }
  const mapDispatchToProps = (dispatch) => ({
    // getToken: () => dispatch(getToken()),
    getlabPartnerslist: () => dispatch(getlabPartnerslist()),
  });

  // export default Lab;
  export default connect(null, mapDispatchToProps)(Lab);
