import React from "react";
import { TailSpin } from "react-loader-spinner";

export default function Loader() {
  return (
    <div className="loader-overlay">
    <div className="loader-container">
      <TailSpin
        visible={true}
        color="#18406E"
        ariaLabel="tail-spin-loading"
        wrapperStyle={{}}
      />
      </div>
    </div>
  );
}
