export const PHARMACY = {
  REQUEST_PHARMALIST: "REQUEST_PHARMALIST",
  SUCCESS_PHARMALIST: "SUCCESS_PHARMALIST",
  FAILED_PHARMALIST: "FAILED_PHARMALIST",

  REQUEST_PARTICULARPHARMA: "REQUEST_PARTICULARPHARMA",
  SUCCESS_PARTICULARPHARMA: "SUCCESS_PARTICULARPHARMA",
  FAILED_PARTICULARPHARMA: "FAILED_PARTICULARPHARMA",

  REQUEST_SEARCH: "REQUEST_SEARCH",
  SUCCESS_SEARCH: "SUCCESS_SEARCH",
  FAILED_SEARCH: "FAILED_SEARCH",

  REQUEST_BRANDFILTER: "REQUEST_BRANDFILTER",
  SUCCESS_BRANDFILTER: "SUCCESS_BRANDFILTER",
  FAILED_BRANDFILTER: "FAILED_BRANDFILTER"

};
