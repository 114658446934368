import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const RhinoPlasty = ({data}) =>{
    return(
        <>
              <div className="grid md:grid-cols-2 px-8 justify-between items-start my-4 py-4 md:py-16 px-4 md:px-8">
            <div className="text-xl md:text-2xl font-semibold" style={{color: "#004171"}} >
                {data?.seventhComp?.title}
            </div>
            <div  >
            {data?.seventhComp?.description}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-8" >
            {
                data?.seventhComp?.points.map(res => (
                    <div className="p-4" style={{backgroundColor:"#EBF8FF", borderRadius: 8}}>
                        <img src= {res.icon_url} />
                    <p style={{color:"#004171", fontSize: 12, fontWeight: 400}} >{res.title}</p>
                    </div>
                ))
            }
            </div>
                </div>
        </div>
        {data?.elevencomp?.length ? (
        <div className="bg-primaryColor text-white py-10 px-4 md:px-16 pb-10">
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row md:items-center">
              <div className="md:w-1/2 mb-6 md:mb-0 md:mx-4">
                <h2 className="text-xl md:text-2xl font-bold mb-4 text-White">
                  {data?.elevencomp[0].title}
                </h2>
              </div>

              <div className="md:w-1/2">
                {data?.elevencomp[0]?.sp?.length &&
                  <ul style={{listStyleType: "disc"}} >
                  {data?.elevencomp[0].sp.map(res =>{
                    return <li className="text-base font-regular mb-4" style={{color:"#ffff"}}><span className="font-semibold" >{res?.desc}</span> {res.subtitle}</li>
                  })}
                </ul>
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}
        {
                data.sixthComp?.points?.length ? <div style={{backgroundColor:"#EBF8FF"}} >
                        <div className="grid md:grid-cols-2 py-4 md:py-8" style={{borderBottom: "dashed"}} >
                                    <div> <p className="p-8 text-xl md:text-2xl font-semibold" style={{color:"#004171"}} >{data.sixthComp?.points[0].mainTitle}</p> </div>
                                    <div>
                                    {data.sixthComp?.points[0].maindescription && <p className="p-8 text-lg md:text-xl font-normal" style={{color:"#004171"}} >{data.sixthComp?.points[0].maindescription}</p>}
                         </div>
                   
                    </div>
                    {
                        data.sixthComp?.points.map(res =>{
                            return(
                                <div className="grid md:grid-cols-2 py-4 md:py-8" style={{borderBottom: "dashed"}} >
                                    <div> <p className="p-8 text-xl md:text-2xl font-semibold" style={{color:"#004171"}} >{res.title}</p> </div>
                                    <div>
                                    {res.description && <p className="p-8 font-medium" style={{color:"#004171", fontSize: 20, fontWeight: 400}} >{res.description}</p>}
                    <ul className="ml-6 md:ml-12" style={{listStyleType: "disc",color:"#004171"}} >
                          {res?.sub_points?.map(res =>{
                            return <li className="mb-2" ><p className="text-md font-medium" style={{color:"#004171"}}>{res.description}</p></li>
                          })}
                        </ul>
                         </div>
                   
                    </div>
                            )
                        })
                    }
                </div> : null
              }
                  {data.eightComp?.points?.length ? (
        <div
          className="py-10 px-4 md:px-16"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div
            className="container mx-auto"
            style={{ color: "rgb(0, 93, 141)" }}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-8 text-center">
              {/* {renderHeadingWithHighlightedText(
                data.eightComp?.heading,
                data.surgeryName
              )} */}
              {data.eightComp.points[0].mainTitle}
            </h2>
            {data.eightComp.points[0]?.mainSubTitle && <h6 className=" mb-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}} >{data.eightComp.points[0]?.mainSubTitle}</h6>}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-0 md:mx-16">
              {data.eightComp?.points.map((type, index) => {
                return (<div className="rounded-xl py-4 px-8" style={{border: "0.5px solid #E3E3E3"}} >
                <p className="text-xl font-semibold mb-2" style={{color:"#005D8D"}} >{type.title}</p>
                <p className="text-base font-medium" style={{color:"#676C80"}}>{type.description}</p>
                <ul style={{listStyleType: "disc"}} >
                {type?.sub_points?.length ? type.sub_points.map(res =>{
                  return <li className="text-base font-medium" style={{color:"#676C80"}}> {res.description}</li>
                }) : null}
              </ul>
                </div>)
              })}
            </div>
          </div>
          <h4 className=" my-4 text-center" style={{color: "#676C80", fontSize: 16, fontWeight: 500}}>{data.eightComp?.points[0]?.mainnotes}</h4>
        </div>
      ) : (
        <p></p>
      )}

{data?.twelthcomp?.length ? (
        <div className="bg-primaryColor text-white py-8 md:py-16 px-4 md:px-16 pb-10 ">
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row md:items-center">
              <div className="md:w-1/2 mb-6 md:mb-0 md:mx-4">
                <h2 className="text-xl md:text-2xl font-bold mb-4 text-White">
                  {data?.twelthcomp[0].title}
                </h2>
              </div>

              <div className="md:w-1/2">
              <p style ={{fontSize: 18, fontWeight: 400, color: "#ffff"}} >    {data?.twelthcomp[0].description}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}
               {
                data.ninthComp?.points?.length ? <div className="py-12" >
                    <h4 style={{color:"#004171"}} className="text-center mb-4 text-xl md:text-2xl font-semibold" >{data.ninthComp.title}</h4>
                    <h4 style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} className="text-center mb-4" >{data.ninthComp.description}</h4>
                    <div className="grid md:grid-cols-2" >
                    {
                        data.ninthComp?.points?.map((res , index) =>{
                            return( <div className="p-8"> 
                             <div className="flex items-center relative" > 
                            <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                        <p className="mb-2" style={{color:"#005D8D", fontSize: 18, fontWeight: 600}} >{res.title}</p>
                        </div>
                        <p className="pl-8 text-base" style={{color:"#676C80", fontSize: 18, fontWeight: 400}} >{res.description}</p>

                            </div>
                   
                            )
                        })
                    }
                    </div>
                    <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.ninthComp?.points[0].mainnotes}</h4>
                </div> : null
              }
              </>
    )
}

export default RhinoPlasty