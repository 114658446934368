import CheckIcon from "../../../../Assets/Images/Icons/ci_check-all.png"

const Fistula = ({data}) =>{
    console.log(data, "sdvkjdsiuvdsbgvdsvds")
    if(!data?.sixthComp?.points?.length){
        return null
    }
    return(
        <>
        {
                data.sixthComp?.points?.length ? <div className="px-4 md:px-8" style={{backgroundColor:"#EBF8FF"}} >
                    {
                        data.sixthComp?.points.map(res =>{
                            return(
                                <div className="grid md:grid-cols-2 py-4 md:py-8" style={{borderBottom: "dashed"}} >
                                    <div> <p className="p-8 text-xl md:text-2xl font-semibold" style={{color:"#004171"}}>{res.title}</p> </div>
                                    <div>
                                    {res.description && <p className="p-8 text-md font-medium" style={{color:"#004171"}}   >{res.description}</p>}
                    <ul className="ml-6 md:ml-12" style={{listStyleType: "disc",color:"#004171"}} >
                          {res?.sub_points?.map(res =>{
                            return <li className="mb-2" ><p className="text-md font-medium" style={{color:"#004171"}}>{res.description}</p></li>
                          })}
                        </ul>
                         </div>
                   
                    </div>
                            )
                        })
                    }
                </div> : null
              }
             <div className="grid md:grid-cols-2 px-8 justify-between items-start my-8 md:my-20">
            <div style={{color: "#004171"}} className="text-xl md:text-2xl font-semibold" >
                {data?.seventhComp?.title}
            </div>
            <div  >
            {data?.seventhComp?.description}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-8" >
            {
                data?.seventhComp?.points.map(res => (
                    <div className="p-4" style={{backgroundColor:"#EBF8FF", borderRadius: 8}}>
                        <img src= {res.icon_url} />
                    <p style={{color:"#004171", fontSize: 12, fontWeight: 400}} >{res.title}</p>
                    </div>
                ))
            }
            </div>
                </div>
        </div>
        {
                data.eightComp?.points?.length ? <div className="my-8 md:my-16 py-10 px-4 md:px-8" style={{backgroundColor:"#004171"}} >
                    {
                        data.eightComp?.points.map(res =>{
                            return(
                                <div className="grid md:grid-cols-2 py-4 md:py-8">
                                    <div> <p className="p-8 text-xl md:text-2xl font-semibold text-White" style={{color:"#ffff"}} >{res.title}</p> </div>
                                    <div>
                                    {res.description && <p className="p-8 text-sm md:text-base text-White" style={{color:"#ffff"}} >{res.description}</p>}
                    <ul className="ml-6 md:ml-12" style={{listStyleType: "disc",color:"#ffff"}} >
                          {res?.sub_points?.map(res =>{
                            return <li className="mb-4" ><p className="text-sm md:text-base text-White font-normal" style={{color:"#ffff"}}><span className="text-sm md:text-base text-White font-bold" >{res?.title}</span>{res.description}</p></li>
                          })}
                        </ul>
                        <h4 className="text-sm font-regular" style={{color: "#ffff"}} >{data.eightComp?.points[0].mainnotes}</h4>
                         </div>
                          
                    </div>
                            )
                        })
                    }
                </div> : null
              }
               {
                data.ninthComp?.points?.length ? <div className="py-8" >
                    <h4 style={{color:"#004171"}} className="text-center mb-4 text-lg md:text-2xl font-semibold" >{data.ninthComp.title}</h4>
                    <h4 style={{color:"#676C80" , fontSize: 16, fontWeight: 500}} className="text-center mb-4 px-4" >{data.ninthComp.description}</h4>
                    <div className="grid md:grid-cols-2" >
                    {
                        data.ninthComp?.points?.map((res , index) =>{
                            return( <div className="p-8"> 
                             <div className="flex items-center relative" > 
                            <div style={{backgroundColor: "#EBF8FF", color :"#005D8D" , borderRadius: "50%" , fontSize: 14 }} className="px-2 text-center mr-4" >{index+1}</div>
                            <p className="text-base" style={{color:"#005D8D", fontSize: 22, fontWeight: 500}} >{res.description}</p>
                        </div>

                            </div>
                   
                            )
                        })
                    }
                    </div>
                    <h4 className="my-4 text-center mb-8" style={{color:"#676C80", fontWeight: 500, fontSize: 16}}>{data?.ninthComp?.points[0].mainnotes}</h4>
                </div> : null
              }
              </>
    )
}

export default Fistula