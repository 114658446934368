export const trackSignupEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'signup_event',
      category: 'User',
      action: 'Signup',
      label: 'User Signed Up',
    });
  };
  
  export const trackLoginEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'login_event',
      category: 'User',
      action: 'Login',
      label: 'User Logged In',
    });
  };

  export const trackAddToCartEvent = (price, productName) =>{
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        value: price,
        currency: "INR",
        items: [
          {
            productName: productName,
          },
        ],
      },
    });
  }

 export const trackPageEvent = (event) =>{
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({event: event});
 }
 
 export const trackCheckout = (eventName, items = [] , price = 0) =>{
  window.dataLayer.push({
    event: eventName,
    ecommerce: {
      value: price,
      currency: "INR",
      items: items
    }
  });
 }

 export const trackPurchase = (eventName, items = [] , price = 0) =>{
  window.dataLayer.push({
    event: eventName,
    ecommerce: {
      value: price,
      currency: "INR",
      items: items
    }
  });
 } 