import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
import Addvitalspopup from '../AddNewVitals';
import VitalChart from '../VitalChart';
import Vector from "../../../../../Assets/Images/Vector.png";
import BMI1 from "../../../../../Assets/Images/BMI1.png";
import { Dropdown, Modal, Space } from 'antd';

function BMIcard(props) {

    const history = useHistory();
    const location = useLocation();

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    const [showviewall, setshowviewall] = useState(false);
    const [showentervitals, setshowentervitals] = useState(false);
    const [showremovevitals, setshowremovevitals] = useState(false);
    const [data, setData] = useState();
    const [graphPop, setGraphPop] = useState(false);

    const viewDetails = (event, det) => {
        event.preventDefault();
        console.log(det)
        setData(det)
        setshowviewall(true)
        setshowentervitals(false)
        setshowremovevitals(false)
    }


    const addvitals = (event, det) => {
        event.preventDefault();
        console.log(det)
        setData(det)
        setshowviewall(false)
        setshowremovevitals(false)
        setshowentervitals(true)


    }


    const removevitals = (event, det) => {
        event.preventDefault();
        console.log(det)
        setData(det)
        setshowviewall(false)
        setshowentervitals(false)
        setshowremovevitals(true)

    }


    const items = [
        {
            label: <p className='relative' onClick={addvitals}>Update</p>,
            key: 0,
        }]


    const saveSuccess = (e) => {
        console.log("save success");
        setshowentervitals(false);
        props.saveCallback(e);
    };

    return (
        <>
            {
                // props?.data?.slice(0, 1).map((user, i) => (
                <>
                    <div className="w-full bg-White p-5 rounded-md">
                        <div className="flex gap-5">
                            <div className="">
                                <img src={BMI1} alt="images" />
                            </div>
                            <div className="w-full ">
                                <div className="w-full flex justify-between items-center">
                                    <h5 className="text-primaryColor text-lg font-medium">
                                        BMI
                                    </h5>
                                    <div className="flex gap-3 items-center">
                                        <div
                                            onClick={() => setGraphPop(true)}
                                            className="flex cursor-pointer"
                                        >
                                            <img src={Vector} alt="images" />
                                        </div>
                                        {/* <div class="relative inline-block group">
                                            <Dropdown
                                                menu={{
                                                    items,
                                                }}
                                                trigger={['click']}
                                                placement='bottomRight'
                                            >
                                                <Space>
                                                    <FiMoreVertical />
                                                </Space>
                                            </Dropdown>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="flex gap-5 mt-4">
                                    <p className="text-textGray font-light text-xs ">
                                        <span className="text-lg text-secondaryColor font-medium">
                                            {props.data.bmi}
                                        </span>{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        graphPop ? <VitalChart title={'BMI Graph'} data={[props.data]} type="bmi" closePopup={() => setGraphPop(false)} /> : null
                    }
                </>
                // ))
            }
        </>
    );
}

export default BMIcard;