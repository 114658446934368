import { useDispatch, useSelector } from "react-redux";
import CurebayButton from "./Button/PrimaryButton"
import Bottles from "../../Assets/pharamacyImages/Bottles.png";
import Capsules from "../../Assets/pharamacyImages/Capsules.png";
import Creams from "../../Assets/pharamacyImages/Creams.png";
import Curebay from "../../Assets/pharamacyImages/Curebay.png";
import Devices from "../../Assets/pharamacyImages/Devices.png";
import Dispensers from "../../Assets/pharamacyImages/Dispensers.png";
import Droplets from "../../Assets/pharamacyImages/Droplets.png";
import Emulsions from "../../Assets/pharamacyImages/Emulsions.png";
import Injections from "../../Assets/pharamacyImages/Injections.png";
import Lotions from "../../Assets/pharamacyImages/Lotions.png";
import Satchels from "../../Assets/pharamacyImages/Satchels.png";
import Tablet from "../../Assets/pharamacyImages/Tablets.png";
import defaultMed from "../../Assets/pharamacyImages/Medicines.jpg";
import { useHistory } from "react-router-dom";
import { addMedicineToCart } from "../utils/cart-utils";
import { useEffect, useState } from "react";
import { AddtoCart } from "../../Redux/Action/cartPlaceOrderAction";
import { trackAddToCartEvent } from "../utils/analytics/events";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
import { getCartDetails } from "../../Redux/Action/cartPlaceOrderAction";
const MedicineCard = ({index = -1, cardItem, quantity = 1}) =>{
    const { cartList } = useSelector((state) => state.cartReducer);
    const [isLoading, setIsLoading] = useState(false)
    const userData = useSelector((state) => state.authReducer.patientData);
    console.log(userData,cardItem,"ffcgtc")
    const history = useHistory()
    const dispatch = useDispatch();
    const resolvePharamcyImageq = (Image) => {
        let PharamncyImage;
        if (Image.toUpperCase().includes("BOTTLE")) {
          PharamncyImage = Bottles;
        } else if (
          Image.toUpperCase().includes("CAPSULE") ||
          Image.toUpperCase().includes("CAPSULES")
        ) {
          PharamncyImage = Capsules;
        } else if (
          Image.toUpperCase().includes("CREAM") ||
          Image.toUpperCase().includes("CREAMS")
        ) {
          PharamncyImage = Creams;
        } else if (Image.toUpperCase().includes("CUREBAY")) {
          PharamncyImage = Curebay;
        } else if (Image.toUpperCase().includes("DEVICES")) {
          PharamncyImage = Devices;
        } else if (Image.toUpperCase().includes("DISPENSERS")) {
          PharamncyImage = Dispensers;
        } else if (Image.toUpperCase().includes("DROPLETS")) {
          PharamncyImage = Droplets;
        } else if (Image.toUpperCase().includes("EMULSIONS")) {
          PharamncyImage = Emulsions;
        } else if (
          Image.toUpperCase().includes("INJECTION") ||
          Image.toUpperCase().includes("INJECTIONS")
        ) {
          PharamncyImage = Injections;
        } else if (Image.toUpperCase().includes("LOTIONS")) {
          PharamncyImage = Lotions;
        } else if (Image.toUpperCase().includes("SATCHELS")) {
          PharamncyImage = Satchels;
        } else if (
          Image.toUpperCase().includes("TABLET") ||
          Image.toUpperCase().includes("TABLETS")
        ) {
          PharamncyImage = Tablet;
        } else {
          PharamncyImage = defaultMed;
        }
        // console.log(PharamncyImage);
        return PharamncyImage;
      };

    const handleImage = (images, type) => {
        if (typeof images === "string") {
          let result = images.replace(/[[\]]/g, "");
          let medicineImage = result.split(",");
          console.log(medicineImage);
          if (medicineImage.length !== 0) {
            if (medicineImage.length === 1 && medicineImage[0] === "") {
              return resolvePharamcyImageq(type);
            } else {
              return medicineImage[0];
            }
          } else {
            return resolvePharamcyImageq(type);
          }
        } else {
          return resolvePharamcyImageq(type);
        }
      };

      const getProductsIDs = () => {
        let ids = [];
        if (cartList) {
          if (cartList?.patientMedicineOrder?.length) {
            cartList?.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(res => {
              ids.push(res.drugCode)
            })
          }
        }
        return ids;
      };
      const drugIDsInCart = getProductsIDs();

      const redirectToMedicinePage = (e, item) => {
        // history.push('/medicine');
        history.push(`/medicine/${item.seoNameId}`);
      };

      // const handleClick = (e) =>{
      //   e.stopPropagation();
      //   if (Object.keys(userData).length === 0) {
      //       history.push(APP_ROUTES.HOME);
      //       return;
      //     } 
      //   setIsLoading(true)
      //   const dataObj = addMedicineToCart(userData, cartList, cardItem, quantity)
      //   console.log(dataObj, "sdvsdvisdhvousdvdssvd")

      //   dispatch(AddtoCart(dataObj)).then((res) => {
      //       trackAddToCartEvent(cardItem?.medicineRate, cardItem.medicineName)
      //       setIsLoading(false);
      //       dispatch(getCartDetails(userData.code));
      //     }).catch(err =>{
      //       setIsLoading(false)
      //     })
    
      // } 


      const handleClick = (e) => {
        e.stopPropagation();
        if (Object.keys(userData).length === 0) {
            history.push(APP_ROUTES.HOME, {isLoginModalOpen : true});
            return;
        } 
        setIsLoading(true);
        const dataObj = addMedicineToCart(userData, cartList, cardItem, quantity);
       

        const payload= {
          patientId: userData?.code,
          drugCode:cardItem?.id,
          drugName: cardItem?.medicineName,
          drugCategory: cardItem?.drugCategory,
          medicineTypeOfSell: cardItem?.medicineTypeOfSell,
          medicineType: "",
          unitPrice:  Number(cardItem?.medicineRate),
          quantity: 1,
          prescriptionRequired:cardItem?.prescriptionRequired,
          vendorId:cardItem?.vendorId
        
        }
        console.log(payload, "sdvsdvisdhvousdvdssvd");
        // Use fetch or axios to make the API call to "url/newPatientMedicine/"
        fetch(`${process.env.REACT_APP_BASEURL}newPatientMedicine/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        })
        .then(response => response.json())
        .then((res) => {
          console.log(res,"vghsdvhcvdsg")
            trackAddToCartEvent(cardItem?.medicineRate, cardItem.medicineName);
            setIsLoading(false);
            dispatch(getCartDetails(userData.code));
        })
        .catch((err) => {
            console.error("Error adding to cart:", err);
            setIsLoading(false);
        });
    };
    
    return(
       
        <div key={index} className="bg-White rounded-lg p-5 pointer"  onClick={(e) => redirectToMedicinePage(e, cardItem)}>
        <div className="bg-lightBlue h-[150px] flex justify-center items-center rounded-lg cursor-pointer">
          <img
            // src={resolvePharamcyImageq(item.medicineTypeOfSell)}
            src={handleImage(
              cardItem.medicineImages,
              cardItem.medicineTypeOfSell
            )}
            alt="images"
            className="w-[90px] h-[90px] rounded-lg object-fill"
          />
        </div>
        <div className="pt-3">
          <div
            
            className=" cursor-pointer"
          >
            <h5 className="text-primaryColor text-lg mb-2 font-medium text-ellipsis overflow-hidden text-nowrap">
              {cardItem.medicineName}
            </h5>
            <div className="text-secondaryColor text-lg font-semibold flex items-baseline">
              <div className="flex w-fit">
                {" "}
                ₹{cardItem.medicineRate}
              </div>
              <div className="text-textGray text-sm font-light pl-1 text-ellipsis overflow-hidden text-nowrap">
                {cardItem.medicineTypeOfSell}
              </div>
            </div>
          </div>
          <CurebayButton
                    className={`${
                      !drugIDsInCart.includes(cardItem.id)
                        ? "bg-primaryColor text-White hover:bg-primaryColor"
                        : "border border-secondaryColor bg-secondaryColor disabled:opacity-75"
                    }  w-full rounded-lg text-md mt-2`}
                    onClick={handleClick}
                    loading = {isLoading}
                    disabled={drugIDsInCart.includes(cardItem.id)}
                    size="large"
                  >
                    {drugIDsInCart.includes(cardItem.id) ? "Added" : "Add to Cart"}
                  </CurebayButton>
        </div>
      </div>
    )
}

export default MedicineCard