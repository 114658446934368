import moment from "moment";

export const addMedicineToCart = (userData, cartList = {}, data, quantity = 1) =>{
    console.log(userData, cartList, data, quantity , "dvsdusdgvsdouvds")
          let prescriptionRequired = "N";
          let ePrescriptionRequired = "N";
          let dataObj = {};
          if (data?.prescriptionRequired == "No") {
            prescriptionRequired = "N";
          } else if (data?.prescriptionRequired == "Yes") {
            prescriptionRequired = "Y";
          }
          let calculatedAmount = data?.drugsInfoDiscountedRate
            ? data?.drugsInfoDiscountedRate
            : data.medicineRate;
          const totalAmount = calculatedAmount * quantity;
    
          if (cartList && cartList.patientMedicineOrder) {
            cartList.totalAmount = cartList?.totalAmount
              ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
              : parseFloat(totalAmount);
            cartList.patientMedicineOrder.forEach((element) => {
              element.patientMedicineOrderDetailsList.push({
                drugCode: data.id,
                drugName: data.medicineName,
                drugCategory: data.drugCategory,
                unitPrice: data.medicineRate,
                discountAmount: 0.0,
                totalAmount: totalAmount,
                quantity: quantity,
                cartId: cartList && cartList.id ? cartList.cartId : "",
                createdBy: userData.code,
                createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                modifiedBy: userData.code,
                modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                orderId: null,
                patientId: userData.code,
                prescriptionRequired: prescriptionRequired,
                ePrescriptionRequired: ePrescriptionRequired,
                medicineTypeOfSell: data?.medicineTypeOfSell,
                membershipCode: null,
                membershipName: null,
                membershipCard: null,
                membershipDiscountPercentage: 0.0,
              });
            });
            dataObj = cartList;
          } else {
            dataObj = {
              cartId: cartList && cartList.id ? cartList.cartId : "",
              createdBy: userData.code,
              createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              modifiedBy: userData.code,
              modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              orderId: null,
              patientId: userData.code,
              status: 1,
              labOrdersYN: false,
              drugsOrdersYN: true,
              totalAmount: cartList?.totalAmount
                ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
                : parseFloat(totalAmount),
              patientMedicineOrder: [
                {
                  orderId: "",
                  patientId: userData.code,
                  prescriptionId: null,
                  cartId: cartList && cartList.id ? cartList.cartId : "",
                  txnId: "",
                  totalAmount: totalAmount,
                  address1: null,
                  address2: null,
                  address3: null,
                  city: null,
                  state: null,
                  country: null,
                  pincode: null,
                  deliveryAddress1: null,
                  deliveryAddress2: null,
                  deliveryAddress3: null,
                  deliveryCity: null,
                  deliveryState: null,
                  deliveryCountry: null,
                  deliveryZipcode: null,
                  createdBy: userData.code,
                  createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                  modifiedBy: userData.code,
                  modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                  status: 1,
                  procedureStatus: -1,
                  payMode: "E",
                  collectionStatus: null,
                  paymentLinkForPatient: "N",
                  discountCouponCode: null,
                  patientName: userData.firstName,
                  patientGender: userData?.gender,
                  patientMobile: userData?.mobile,
                  patientMedicineOrderDetailsList: [
                    {
                      drugCode: data.id,
                      drugName: data.medicineName,
                      drugCategory: data.drugCategory,
                      unitPrice: data.medicineRate,
                      discountAmount: 0.0,
                      totalAmount: totalAmount,
                      quantity: quantity,
                      cartId: cartList && cartList.id ? cartList.cartId : "",
                      createdBy: userData.code,
                      createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                      modifiedBy: userData.code,
                      modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                      orderId: null,
                      patientId: userData.code,
                      prescriptionRequired: prescriptionRequired,
                      ePrescriptionRequired: ePrescriptionRequired,
                      medicineTypeOfSell: data?.medicineTypeOfSell,
                      membershipCode: null,
                      membershipName: null,
                      membershipCard: null,
                      membershipDiscountPercentage: 0.0,
                    },
                  ],
                },
              ],
            };
          }

          return dataObj
    
}

export const addLabtestTocart = (userData, cartList = {}, data, quantity = 1) =>{

  // setIsAdding(index);
  let prescriptionRequired = "N";
  let ePrescriptionRequired = "N";
  let dataObj = {};
  if (data?.medicinePrescriptionRequired == "N") {
    prescriptionRequired = "N";
    ePrescriptionRequired = "N";
  } else if (data?.medicinePrescriptionRequired == "Y") {
    prescriptionRequired = "Y";
    ePrescriptionRequired = "Y";
  }
  let calculatedAmount = data?.amount;
  const totalAmount = calculatedAmount * quantity;

  if (cartList && cartList.patientLabTestsOrder) {
    cartList.totalAmount = cartList?.totalAmount
      ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
      : parseFloat(totalAmount);
    cartList.patientLabTestsOrder.map((res) => {
      res.patientLabTestsOrderDetailsList.push({
        patientLabTestOrderId: data.id,
        labTestCode: data.labTestCode,
        labTestName: data.labTestName,
        hospitalId: data.labId,
        locationId: data.locationId,
        amount: data.amount,
        discountAmount: data?.discountAmount ? data?.discountAmount : 0,
        totalAmount: totalAmount,
        status: 1,
        labTestType: data.testType,

        quantity: quantity,
        cartId: cartList && cartList.id ? cartList.cartId : "",
        createdBy: userData.code,
        createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifiedBy: userData.code,
        modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        orderId: null,
        patientId: userData.code,
        prescriptionRequired: prescriptionRequired,
        ePrescriptionRequired: ePrescriptionRequired,
        medicineTypeOfSell: null,
        membershipCode: null,
        membershipName: null,
        membershipCard: null,
        membershipDiscountPercentage: 0.0,
      });
    });
    dataObj = cartList;
  } else {
    console.log(cartList, "dsvsoudvdvhsdoivs");
    dataObj = {
      cartId: cartList && cartList.id ? cartList.cartId : "",
      createdBy: userData.code,
      createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
      modifiedBy: userData.code,
      modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
      orderId: null,
      patientId: userData.code,
      status: 1,

      labOrdersYN: true,
      drugsOrdersYN: false,
      totalAmount: cartList?.totalAmount
        ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
        : parseFloat(totalAmount),
      patientLabTestsOrder: [
        {
          hospitalId: data.labId,
          locationId: data.locationId,

          orderId: "",
          patientId: userData.code,
          orderDetailsRequired: "Y",
          prescriptionId: null,
          cartId: cartList && cartList.id ? cartList.cartId : "",
          txnId: "",
          amount: totalAmount,
          address1: data.address1,
          address2: data.address2,
          address3: null,
          city: data.city,
          state: data.state,
          country: null,
          pincode: data.pinCode,
          deliveryAddress1: data.address1,
          deliveryAddress2: data.address2,
          deliveryAddress3: null,
          deliveryCity: data.city,
          deliveryState: data.state,
          deliveryCountry: null,
          deliveryZipcode: data.pinCode,
          createdBy: userData.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: userData.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          status: 1,
          procedureStatus: -1,
          payMode: "E",
          collectionStatus: null,
          paymentLinkForPatient: "N",
          discountCouponCode: null,
          patientName: userData.firstName,
          patientGender: userData?.gender,
          patientMobile: userData?.mobile,
          patientLabTestsOrderDetailsList: [
            {
              patientLabTestOrderId: data.id,
              labTestCode: data.labTestCode,
              labTestName: data.labTestName,
              hospitalId: data.labId,
              locationId: data.locationId,
              amount: data.amount,
              discountAmount: data?.discountAmount ? data?.discountAmount : 0,
              totalAmount: totalAmount,
              status: 1,
              labTestType: data.testType,
              tat: data?.tat,
              labStartTime: data?.labStartTime,
              labEndTime: data?.labEndTime,
              description: data?.description,

              quantity: quantity,
              cartId: cartList && cartList.id ? cartList.cartId : "",
              createdBy: userData.code,
              createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              modifiedBy: userData.code,
              modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              orderId: null,
              patientId: userData.code,
              prescriptionRequired: prescriptionRequired,
              ePrescriptionRequired: ePrescriptionRequired,
              medicineTypeOfSell: null,
              membershipCode: null,
              membershipName: null,
              membershipCard: null,
              membershipDiscountPercentage: 0.0,
            },
          ],
        },
      ],
      // };
    };
  }
  return dataObj;
}