import React from "react";
import { Collapse } from "antd";
import hospitalpartner1 from "../../Assets/Images/HospitalPartners/ApolloHospital.png";
import hospitalpartner2 from "../../Assets/Images/HospitalPartners/UtkalHospital.png";
import hospitalpartner3 from "../../Assets/Images/HospitalPartners/AswiniHospital.png";
import hospitalpartner4 from "../../Assets/Images/HospitalPartners/SparshHospital.png";
import hospitalpartner5 from "../../Assets/Images/HospitalPartners/H20Hospital.png";
import hospitalpartner6 from "../../Assets/Images/HospitalPartners/kIDShospital.png";
import hospitalpartner7 from "../../Assets/Images/HospitalPartners/RMH20Hospitals.png";
import hospitalpartner8 from "../../Assets/Images/HospitalPartners/UsthiHospital.png";
import hospitalpartner9 from "../../Assets/Images/HospitalPartners/SaraGastro.png";
import hospitalpartner10 from "../../Assets/Images/HospitalPartners/SuperSpeciality.png";
import hospitalpartner11 from "../../Assets/Images/HospitalPartners/jeevandeep.png";
import hospitalpartner12 from "../../Assets/Images/HospitalPartners/Sushruta.png";
import hospitalpartner13 from "../../Assets/Images/HospitalPartners/AdorasiaHospital.png";
import hospitalpartner14 from "../../Assets/Images/HospitalPartners/ahospital.jpg";
import hospitalpartner15 from "../../Assets/Images/HospitalPartners/AmirthaHealthCare.jpg";
import hospitalpartner16 from "../../Assets/Images/HospitalPartners/AswiniHospital.jpg";
import hospitalpartner17 from "../../Assets/Images/HospitalPartners/HeritageHealthcare.jpg";
import hospitalpartner18 from "../../Assets/Images/HospitalPartners/VisionCare.png";
import hospitalpartner19 from "../../Assets/Images/HospitalPartners/BerhanpurCardioc.jpg";
import hospitalpartner20 from "../../Assets/Images/HospitalPartners/BNH.jpg";
import hospitalpartner21 from "../../Assets/Images/HospitalPartners/careHospital.jpg";
import hospitalpartner22 from "../../Assets/Images/HospitalPartners/CenterforEye.jpg";
import hospitalpartner23 from "../../Assets/Images/HospitalPartners/CJMHospital.jpg";
import hospitalpartner24 from "../../Assets/Images/HospitalPartners/Gourishankar.jpg";
import hospitalpartner25 from "../../Assets/Images/HospitalPartners/Hospital.jpg";
import hospitalpartner26 from "../../Assets/Images/HospitalPartners/Lepra.jpg";
import hospitalpartner27 from "../../Assets/Images/HospitalPartners/OmmSaiEye.jpg";
import hospitalpartner28 from "../../Assets/Images/HospitalPartners/MMMedicare.jpg";
import hospitalpartner29 from "../../Assets/Images/HospitalPartners/SunflowerNursing.jpg";
import hospitalpartner30 from "../../Assets/Images/HospitalPartners/PandaNursingHome.jpg";
import hospitalpartner31 from "../../Assets/Images/HospitalPartners/SamaleswariNursing.jpg";
import hospitalpartner32 from "../../Assets/Images/HospitalPartners/TrinityNeuro.jpg";
import hospitalpartner33 from "../../Assets/Images/HospitalPartners/MaaTrainingNursing.jpg";
import hospitalpartner34 from "../../Assets/Images/HospitalPartners/AdorasiaHospital.png";
import hospitalpartner35 from "../../Assets/Images/HospitalPartners/HeritageHealthcare.jpg";
import hospitalpartner36 from "../../Assets/Images/HospitalPartners/DibyaDrusty.jpg";
import hospitalpartner37 from "../../Assets/Images/HospitalPartners/LainasEye.jpg";
import hospitalpartner38 from "../../Assets/Images/HospitalPartners/SuramaniNetralaya.jpg";
import hospitalpartner39 from "../../Assets/Images/HospitalPartners/NewTariniHospital.jpg";
import lab1 from "../../Assets/Images/Partners/lab/lab1.png";
import lab2 from "../../Assets/Images/Partners/lab/lab2.png";
import lab3 from "../../Assets/Images/Partners/lab/lab3.png";
import lab4 from "../../Assets/Images/Partners/lab/lab4.png";
import lab5 from "../../Assets/Images/Partners/lab/lab5.png";
import lab6 from "../../Assets/Images/Partners/lab/lap6.png";
import lab7 from "../../Assets/Images/Partners/lab/lab7.png";
import lab8 from "../../Assets/Images/Partners/lab/lab8.png";
import lab9 from "../../Assets/Images/Partners/lab/lab9.png";
import lab10 from "../../Assets/Images/Partners/lab/lab10.png";
import lab11 from "../../Assets/Images/Partners/lab/lab11.png";
import lab12 from "../../Assets/Images/Partners/lab/lab12.png";
import lab13 from "../../Assets/Images/Partners/lab/lab13.png";
import lab14 from "../../Assets/Images/Partners/lab/lab14.png";
import ambulance1 from "../../Assets/Images/Partners/AlliedService/Ambulance1.png";
import allied1 from "../../Assets/Images/Partners/AlliedService/Allied1.png";
import allied2 from "../../Assets/Images/Partners/AlliedService/Allied2.png";


function IndustryLeadA() {   
  const items = [
    {
      key: "1",
      label: "Hospitals service partners",
      children: (
        <div className="grid grid-cols-3 md:grid-cols-5 gap-4 md:gap-10 mx-5 md:mx-10">
          <div className="flex justify-center items-center">
            <img src={hospitalpartner1} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner2} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner3} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner4} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner5} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner6} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner7} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner8} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner9} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner10} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner11} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner12} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner13} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner14} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner15} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner16} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner17} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner18} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner19} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner20} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner22} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner21} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner23} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner24} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner25} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner26} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner27} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner28} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner29} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner30} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner31} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner32} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner33} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner34} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner35} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner36} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner37} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner38} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={hospitalpartner39} alt="images" />
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Diagnostic labs service partners",
      children: (
        <div className="grid grid-cols-3 md:grid-cols-5 gap-4 md:gap-10 mx-5 md:mx-10">
          <div className="flex justify-center items-center">
            <img src={lab1} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab2} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab3} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab4} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab5} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab6} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab7} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab8} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab9} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab10} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab11} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab12} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab13} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={lab14} alt="images" />
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: "Allied Service Partners",
      children: (
        <div className="grid grid-cols-3 md:grid-cols-5 gap-4 md:gap-10 mx-5 md:mx-10">
          <div className="flex justify-center items-center">
            <img src={allied1} alt="images" />
          </div>
          <div className="flex justify-center items-center">
            <img src={allied2} alt="images" />
          </div>
        </div>
      ),
    },
    {
      key: "4",
      label: "Ambulance Service",
      children: (
        <div className="grid grid-cols-3 md:grid-cols-5 gap-4 md:gap-10 mx-5 md:mx-10">
          <div className="flex justify-center items-center">
            <img src={ambulance1} alt="images" />
          </div>
        </div>
      ),
    },
  ];
  return (
    <Collapse
      accordion
      items={items}
      defaultActiveKey={["3"]}
      className="homeCollapse firstchildcollapse"
    />
  );
}

export default IndustryLeadA;
