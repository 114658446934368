import React, { useState } from "react";
import Layouts from "../Layouts";
import Clockimage from "../../Assets/Images/Icons/clock.svg";
import Calendarimage from "../../Assets/Images/Icons/calendar.svg";
import Backarrow from "../../Assets/Images/Icons/backarrow.svg";
import HoverBackarrow from "../../Assets/Images/Icons/backarrow-white.svg";
import { useHistory } from "react-router-dom";

function BlogDetails2() {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  const handleNavigation = (path) => {
    history.push(path);
  };
  return (
    <Layouts>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px] md:w-[80%]">
          <div
            className="text-primaryColor hover:bg-primaryColor hover:text-White py-2 px-5 w-fit rounded-full mx-auto text-lg font-medium cursor-pointer flex gap-3 justify-center items-center mb-10"
            onClick={() => handleNavigation("/blogs")}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img src={isHovered ? HoverBackarrow : Backarrow} alt="images" />
            Back to blogs
          </div>
          <div>
            <h2 className="md:w-[60%] mx-auto text-center text-2xl md:text-4xl font-bold text-primaryColor !leading-normal mb-5">
              <span className="text-secondaryColor">Summer Skincare Essentials:</span>{" "}
              Protecting Your Skin from Sun Damage
            </h2>
            <p className="md:w-[50%] mx-auto text-center text-textGray ">
              With the arrival of summer comes longer days, warmer weather, and plenty of outdoor activities.
              While soaking up the sun can be enjoyable, it's essential to prioritize skin protection to prevent
              sun damage and maintain healthy, radiant skin.
            </p>
            <div className="flex gap-4 justify-center mt-5">
              <div className="flex items-center gap-2">
                <img src={Clockimage} alt="images" />
                <p className="text-textGray text-sm ">6 minute read</p>
              </div>
              <div className="flex items-center gap-2">
                <img src={Calendarimage} alt="images" />
                <p className="text-textGray text-sm ">10th February, 2024</p>
              </div>
            </div>
          </div>
          <div className="w-full md:h-[500px] my-5 md:my-10">
            <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog2.jpg" alt="images" className="w-full  h-full object-cover rounded-lg" />
          </div>
          <div className="bg-White rounded-xl p-5 md:p-10">
            <h6 className=" text-primaryColor text-xl font-semibold my-5">
              Introduction:
            </h6>
            <p className="text-textGray mb-4 md:leading-loose">
              With the arrival of summer comes longer days, warmer weather, and plenty of outdoor activities.
              While soaking up the sun can be enjoyable, it's essential to prioritize skin protection to prevent
              sun damage and maintain healthy, radiant skin. In this blog post, we'll explore the summer
              skincare essentials you need to keep your skin safe and glowing all season long.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Wear Sunscreen Daily:
            </h6>
            <p className="text-textGray md:leading-loose  mb-4">
              Sunscreen is your skin's best defense against harmful UV rays. Choose a broad-spectrum sunscreen
              with SPF 30 or higher and apply it generously to all exposed areas of skin, including your face,
              neck, arms, and legs. Reapply every two hours, especially if you're swimming or sweating.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Opt for Protective Clothing:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              In addition to sunscreen, wearing protective clothing can provide extra defense against sun
              damage. Look for lightweight, long-sleeved shirts, wide-brimmed hats, and sunglasses with UV
              protection to shield your skin and eyes from the sun's rays.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Seek Shade:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              When spending time outdoors, seek shade whenever possible to minimize direct sun exposure.
              Take breaks under umbrellas, trees, or canopies, especially during the peak hours of 10 a.m. to
              4 p.m. when the sun's rays are strongest.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Stay Hydrated:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Proper hydration is essential for maintaining healthy skin, especially during hot summer days.
              Drink plenty of water throughout the day to keep your skin hydrated from the inside out and
              prevent dehydration, which can exacerbate sun damage.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Use a Gentle Cleanser:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Summer heat and humidity can lead to excess oil and sweat on the skin, making it prone to
              breakouts and clogged pores. Use a gentle cleanser twice daily to remove dirt, oil, and
              impurities without stripping your skin's natural moisture barrier.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Exfoliate Regularly:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Exfoliation helps remove dead skin cells, unclog pores, and promote cell turnover, leaving your
              skin smooth and radiant. Choose a gentle exfoliating scrub or chemical exfoliant to use 2-3 times
              a week, depending on your skin type.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Moisturize Daily:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Even in the summer months, it's essential to moisturize your skin to keep it hydrated and healthy.
              Opt for a lightweight, oil-free moisturizer that won't clog pores or feel heavy on the skin.
              Apply moisturizer morning and night after cleansing.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Treat Sunburns Promptly:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              Despite your best efforts, sunburns can still occur. If you do get sunburned, treat your skin
              promptly with cool compresses, aloe vera gel, and moisturizing lotions to soothe irritation and
              promote healing. Avoid further sun exposure until your skin has fully recovered.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Practice Sun-Safe Habits:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              In addition to sunscreen and protective clothing, incorporate other sun-safe habits into your
              daily routine. Avoid tanning beds, limit time spent in direct sunlight, and never skip sun
              protection, even on cloudy days.
            </p>
            <h6 className=" text-primaryColor text-lg font-medium my-2">
              Conclusion:
            </h6>
            <p className="text-textGray md:leading-loose mb-4">
              By incorporating these summer skincare essentials into your daily routine, you can protect your
              skin from sun damage, premature aging, and other harmful effects of UV radiation. Prioritize sun
              protection, hydration, and gentle skincare practices to keep your skin healthy, radiant, and
              glowing all summer long.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
            <span className="text-secondaryColor">Recommended</span> Reads
          </h2>
          <div className="grid md:grid-cols-2 gap-5">
            <div
              className="bg-White common-shadow rounded-xl p-5 cursor-pointer w-[80%] mx-auto"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div className="w-full mb-3">
                <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog1.jpg" alt="images" className="w-full" />
              </div>
              <h6 className="text-primaryColor font-semibold text-lg mb-2">
                Beat the Heat: 10 Tips for Staying Cool and Hydrated This Summer
              </h6>
              <p className="text-textGray text-sm mb-4">
                As temperatures rise and the sun shines brighter, it's essential to prioritize staying cool and
                hydrated to beat the summer heat.
              </p>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
            <div
              className="bg-White common-shadow rounded-xl p-5 cursor-pointer w-[80%] mx-auto"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div className="w-full mb-3">
                <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog3.jpg" alt="images" className="w-full" />
              </div>
              <h6 className="text-primaryColor font-semibold text-lg mb-2">
                10 Tips for Managing Chronic Conditions with Telehealth
              </h6>
              <p className="text-textGray text-sm mb-4">
                In recent years, telehealth has emerged as a groundbreaking solution for managing chronic health
                conditions, offering convenience, accessibility, and personalized care from the comfort of one's
                home.
              </p>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default BlogDetails2;