import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Layouts from "../../Layouts";
import { message } from "antd";
import { patientFeedback, getCustomerList } from "../../../Redux/Action/UserprofileActions";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
const Feedback = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  const successinfo = useSelector((state) => state.feedbackinfo);
  const { issuccess, customercareData } = successinfo;
  const [feedback, setFeedback] = useState({
    content: "",
    createdBy: userData.code,
    createdDate: null,
    fromCode: userData.code,
    fromDate: null,
    modifiedBy: userData.code,
    modifiedDate: null,
    status: 1,
    subject: "",
    toCode: "",
    toDate: null,
  });

  const history = useHistory();

  useEffect(()=> {
    if (Object.keys(userData).length === 0) {
      history.push('/', {isLoginModalOpen : true});
    }
  },[])
  const handleChange = (e) => {
    setFeedback({ ...feedback, [e.target.name]: e.target.value });
  };
  const savefeedback = (e) => {
    e.preventDefault();
    if (feedback.content == "") {
      message.error('Please Enter the feedback');
    } else {
      dispatch(patientFeedback(feedback)).then((res) => {
        setFeedback({ ...feedback, ["content"]: "" });
        message.success("Feedback Submitted Successfully");
      });
    }
  };

  return (
    <Layouts>
      <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] mx-auto">
          <div className="w-full bg-primaryColor rounded-md px-8 py-7">
            <div className="font-medium md:text-2xl text-White">Feedback</div>
          </div>

          <div className="w-full md:mt-3 rounded-md py-7 md:px-16 px-10 common-shadow bg-White">
            <div className="">
              <div className="">
                <span className="font-medium text-lg md:text-xl text-primaryColor">
                  Please enter your feedback
                </span>
              </div>
              <textarea
                className="w-full h-[380px] border border-solid text-lg  border-1 border-[#676C8066] rounded-md md:p-4 p-2 my-3"
                placeholder="Enter feedback"
                value={feedback.content}
                name="content"
                onChange={handleChange}
              ></textarea>
              <button onClick={(e) => savefeedback(e)} className="mb-5 mt-3 font-medium text-lg text-primaryColor w-full h-[58px] border-[1.5px] border-primaryColor flex justify-center items-center rounded-lg cursor-pointer">
                Share Feedback
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};

export default Feedback;
