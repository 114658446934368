import http from '../../../../Redux/Services/http-common'

export const getAuthTokenForMMI = async () =>{
    // var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    let authPayload = new URLSearchParams({
        grant_type: "client_credentials",
        client_id: process.env.REACT_APP_MMI_CLIENT_ID,
        client_secret: process.env.REACT_APP_MMI_CLIENT_SECRET_ID,
      });
    let response =  await http.post(`security/oauth/token`,authPayload,              {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
      });
    try {
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const getLatAndLongFromEloc = async (eloc, token) =>{
    // var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    let response =  await http.get(`O2O/entity/${eloc}`,              {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    try {
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const getLatLongFromAddress = async (address, token) =>{
    // var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    let response =  await http.get(`places/geocode?address=${address}&itemCount=1`,              {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    try {
        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
}