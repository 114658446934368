import React from "react";
import Layouts from "../Layouts";
import Clockimage from "../../Assets/Images/Icons/clock.svg";
import Calendarimage from "../../Assets/Images/Icons/calendar.svg";
import { useHistory } from "react-router-dom";
import Blog4 from "../../Assets/Images/Blog/Blog4.jpg";
import Blog5 from "../../Assets/Images/Blog/Blog5.jpg";
import Blog6 from "../../Assets/Images/Blog/Blog6.jpg";

function Blogs() {
  const history = useHistory();

  const handleNavigation = (path) => {
    history.push(path);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };
  return (
    <Layouts>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[90px]">
          <h1 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
            The <span className="text-secondaryColor">CureBay</span> Blogs
          </h1>
          <div className="w-full grid justify-items-center md:grid-cols-3 3xl:grid-cols-4 gap-5">
            <div
              className="bg-White rounded-xl p-5 cursor-pointer hoverGreen h-full flex flex-col justify-between"
              onClick={() => handleNavigation("/blogs-details")}
            >
              <div>
                <div className="w-full mb-3">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog1.jpg"
                    alt="images"
                    className="w-full rounded-xl"
                  />
                </div>
                <div>
                  <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    Beat the Heat: 10 Tips for Staying Cool and Hydrated This
                    Summer
                  </h6>
                  <p className="text-textGray text-sm mb-4">
                    As temperatures rise and the sun shines brighter, it's
                    essential to prioritize staying cool and hydrated to beat
                    the summer heat. Dehydration and heat-related illnesses can
                    pose serious risks during hot weather, but with the right
                    strategies, you can keep yourself refreshed and comfortable
                    all summer long. Here are 10 valuable tips to help you stay
                    cool and hydrated during the hottest months of the year.
                  </p>
                </div>
                <div className="flex gap-4">
                  <div className="flex items-center gap-2">
                    <img src={Clockimage} alt="images" />
                    <p className="text-textGray text-sm ">6 minute read</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={Calendarimage} alt="images" />
                    <p className="text-textGray text-sm ">
                      10th February, 2024
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-White rounded-xl p-5 cursor-pointer hoverGreen h-full flex flex-col justify-between"
              onClick={() => handleNavigation("/blogs-details2")}
            >
              <div>
                <div className="w-full mb-3">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog2.jpg"
                    alt="images"
                    className="w-full rounded-xl"
                  />
                </div>
                <h6 className="text-primaryColor font-semibold text-lg mb-2">
                  Summer Skincare Essentials: Protecting Your Skin from Sun
                  Damage
                </h6>
                <p className="text-textGray text-sm mb-4">
                  With the arrival of summer comes longer days, warmer weather,
                  and plenty of outdoor activities. While soaking up the sun can
                  be enjoyable, it's essential to prioritize skin protection to
                  prevent sun damage and maintain healthy, radiant skin. In this
                  blog post, we'll explore the summer skincare essentials you
                  need to keep your skin safe and glowing all season long.
                </p>
              </div>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
            <div
              className="bg-White rounded-xl p-5 cursor-pointer hoverGreen h-full flex flex-col justify-between"
              onClick={() => handleNavigation("/blogs-details3")}
            >
              <div>
                <div className="w-full mb-3">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Blog/Blog3.jpg"
                    alt="images"
                    className="w-full rounded-xl"
                  />
                </div>
                <h6 className="text-primaryColor font-semibold text-lg mb-2">
                  10 Tips for Managing Chronic Conditions with Telehealth
                </h6>
                <p className="text-textGray text-sm mb-4">
                  In recent years, telehealth has emerged as a groundbreaking
                  solution for managing chronic health conditions, offering
                  convenience, accessibility, and personalized care from the
                  comfort of one's home. Whether you're living with diabetes,
                  hypertension, or any other chronic condition, telehealth
                  presents numerous opportunities for effective management. Here
                  are 10 valuable tips to help you make the most of telehealth
                  in managing your chronic health condition.
                </p>
              </div>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">10th February, 2024</p>
                </div>
              </div>
            </div>
            <div
              className="bg-White rounded-xl p-5 cursor-pointer hoverGreen h-full flex flex-col justify-between"
              onClick={() => handleNavigation("/blogs-details4")}
            >
              <div>
                <div className="w-full mb-3">
                  <img src={Blog4} alt="images" className="w-full rounded-xl" />
                </div>
                <h6 className="text-primaryColor font-semibold text-lg mb-2">
                  5 Simple Yet Effective Ways To Reduce Digital Eye Strain
                </h6>
                <p className="text-textGray text-sm mb-4">
                  Do you spend a significant amount of time looking at the
                  screen? You are not alone. In this digital era, it is almost
                  impossible to escape screen time. Whether for studies, work or
                  entertainment, we all end up spending a lot of time with our
                  gadgets, causing digital eye strain and discomfort.
                </p>
              </div>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">23rd February, 2024</p>
                </div>
              </div>
            </div>
            <div
              className="bg-White rounded-xl p-5 cursor-pointer hoverGreen h-full flex flex-col justify-between"
              onClick={() => handleNavigation("/blogs-details5")}
            >
              <div>
                <div className="w-full mb-3">
                  <img src={Blog5} alt="images" className="w-full rounded-xl" />
                </div>
                <h6 className="text-primaryColor font-semibold text-lg mb-2">
                  Transforming Rural Healthcare: From Quacks to Quality Care
                  through Virtual Consultations
                </h6>
                <p className="text-textGray text-sm mb-4">
                  In the heart of rural landscapes, where the verdant fields
                  stretch endlessly and the sky meets the horizon, healthcare
                  has long been a narrative of scarcity and shadows. For years,
                  these underserved communities placed their trust in the hands
                  of quacks—individuals masquerading as medical
                  professionals—owing to the acute shortage of qualified
                  healthcare providers.
                </p>
              </div>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">25th February, 2024</p>
                </div>
              </div>
            </div>
            <div
              className="bg-White rounded-xl p-5 cursor-pointer hoverGreen h-full flex flex-col justify-between"
              onClick={() => handleNavigation("/blogs-details6")}
            >
              <div>
                <div className="w-full mb-3">
                  <img src={Blog6} alt="images" className="w-full rounded-xl" />
                </div>
                <h6 className="text-primaryColor font-semibold text-lg mb-2">
                  The Importance of Vitamin D: How to Safely Get Your Sun
                  Exposure
                </h6>
                <p className="text-textGray text-sm mb-4">
                  We live in a country blessed with abundant sunshine, yet a
                  majority of our population suffers from Vitamin D deficiency,
                  also referred to as ‘the sunshine vitamin’. The primary source
                  to gain Vitamin D is sunlight - exposure to which is essential
                  for bone health, immune function and mood regulation. It also
                  plays a crucial role in calcium absorption and has been linked
                  to reducing the risk of heart disease, diabetes and certain
                  types of cancer.
                </p>
              </div>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <img src={Clockimage} alt="images" />
                  <p className="text-textGray text-sm ">6 minute read</p>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Calendarimage} alt="images" />
                  <p className="text-textGray text-sm ">11th February, 2023</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default Blogs;
